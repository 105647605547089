import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const staticticsService = {
    getSales,
    getProducts,
    getEndofStockProducts,
    getPayementMethods,
    getDailyRevenu,
    getMonthlyRevenu,
    getMonthlyDiscounts,
    getDailyDiscounts,
    getMonthlyRevenuEfficieny,
    getMonthlyDiscountEfficieny

};

function getSales() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statictics.sales}`, requestOptions).then(handleResponse);
}

function getProducts() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statictics.products}`, requestOptions).then(handleResponse);
}

function getPayementMethods(period:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statictics.payements}?periodical=${period}`, requestOptions).then(handleResponse);
}

function getEndofStockProducts(warehouse_id:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statictics.products}?warehouse_id=${warehouse_id}`, requestOptions).then(handleResponse);
}

function getDailyRevenu(periodical:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statictics.revenu}?periodical=${periodical}`, requestOptions).then(handleResponse);
}


function getMonthlyRevenu(start_date:any, end_date:any, periodical:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statictics.revenu}?periodical=${periodical}&start=${start_date}&end=${end_date}`, requestOptions).then(handleResponse);
}

function getDailyDiscounts(periodical:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statictics.discount}?periodical=${periodical}`, requestOptions).then(handleResponse);
}

function getMonthlyDiscounts(start_date:any, end_date:any, periodical:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statictics.discount}?periodical=${periodical}&start=${start_date}&end=${end_date}`, requestOptions).then(handleResponse);
}

function getMonthlyRevenuEfficieny() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statictics.revenuEff}`, requestOptions).then(handleResponse);
}

function getMonthlyDiscountEfficieny() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.statictics.discountEff}`, requestOptions).then(handleResponse);
}








function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api

                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
