<template>

  
  <a-layout style="height: 100vh; overflow: hidden;" :class="{ 'is-rtl': textDirection==='rtl'}">
    <LbrxLoaderSpinner :is-loaded="!loading" />

    <a-config-provider  
      :theme="{
      token: {
        colorPrimary: themeTemplate.primaryColor,
        fontFamily: 'Exo 2',
        
      },
      }"
    ></a-config-provider>
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null"
    collapsible
    :width="siderWidth"
    class="layout-sider">
      <div style="height: 100vh ; overflow-y: auto;">
        <div class="logo">

          <img v-if="collapsed" src="@/assets/img/posys-favicon-transparent.png" class="logo-img-collapsed" alt="Posys Image"  @click="navigatePage('/', '0')" />
          <img v-else src="@/assets/img/posys-logo-small.png" class="logo-img" alt="Posys-Image" width="75%" @click="navigatePage('/', '0')" />

        </div>
        <a-menu v-model:selectedKeys="selectedKeys"  mode="inline"  class="sider-menu">

          <a-menu-item key="0">
            <div class="icon-container" @click="navigatePage('/','0')">
              <HomeOutlined />
              <span>{{t('Pos.Dashboard')}}</span>
            </div>
          </a-menu-item>
        
          <a-menu-item key="1">
            <div class="icon-container" @click="navigatePage('/store','1')">
              <ShopOutlined />
              <span>{{t('Pos.Store')}}</span>
            </div>
          </a-menu-item>

          <a-sub-menu key="2">
            <template #title>
              <div class="icon-container">
                <span>
                  <BarcodeOutlined />
                  <span v-show="!collapsed">{{t('Pos.Inventory')}}</span>
                </span>
              </div>
            </template>
            <a-menu-item key="2-1" @click="navigatePage('/warehouse','2-1')">
              <div class="icon-container">{{t('Pos.Warehouse')}}</div>
            </a-menu-item>
            <a-menu-item key="2-2"  @click="navigatePage('/inventory','2-2')">
              <div class="icon-container">{{t('Pos.Transactions')}}</div>
            </a-menu-item>

            <a-menu-item key="2-3"  @click="navigatePage('/stockOverview','2-3')">
              <div class="icon-container">{{t('Pos.StockOverview')}}</div>
            </a-menu-item>

            <!-- New -->
            <!-- <a-menu-item key="2-4"  @click="navigatePage('/transfer-requests','2-4')">
              <div class="icon-container">{{t('Pos.TransferRequests')}}</div>
            </a-menu-item> -->

          </a-sub-menu>

          <a-sub-menu key="3">
            <template #title>
              <span>
                <div class="icon-container">
                  <InboxOutlined />
                  <span>{{t('Pos.Products')}}</span>
                </div>
              </span>
            </template>

            

            <a-menu-item  key="3-1" @click="navigatePage('/products','3-1')"  >
              <div class="icon-container">{{t('Pos.Products')}}</div>
            </a-menu-item>
            <a-menu-item key="3-2"  @click="navigatePage('/category','3-2')"  > <div class="icon-container">{{t('Pos.Categories')}}</div></a-menu-item>

            <a-sub-menu key="3-3">

              <template #title>
                <span>
                  <div class="icon-container">
                    <span>{{t('Pos.Ingredients')}}</span>
                  </div>
                </span>
              </template>

              <a-menu-item key="3-3-1" @click="navigatePage('/ingredients','3-3-1')"  class="submenu-container">
                <div class="icon-container">{{t('Pos.Ingredients')}}</div>
              </a-menu-item>
              <a-menu-item key="3-3-2"  @click="navigatePage('/ingredient-categories','3-3-2')"  class="submenu-container">
                <div class="icon-container">{{t('Pos.Categories')}}</div>
              </a-menu-item>

            </a-sub-menu>

            <a-menu-item key="3-4"  @click="navigatePage('/manufacturer','3-4')">
              <div class="icon-container">{{t('Pos.Manufacturers')}}</div>
            </a-menu-item>

            <a-menu-item key="3-5"  @click="navigatePage('/supplier','3-5')">
              <div class="icon-container">{{t('Pos.Suppliers')}}</div>
            </a-menu-item>

            <a-menu-item  key="3-6" @click="navigatePage('/preferences','3-6')"  >
              <div class="icon-container">{{t('Pos.Preferences')}}</div>
            </a-menu-item>

          </a-sub-menu>

        
          <!-- new -->
          <!-- <a-sub-menu key="4" >


            <template #title>
              <div class="icon-container">
                <span>
                  <BookOutlined/>
                  <span>{{t('Pos.Menu')}}</span>
                </span>
              </div>
            </template>

            
            <a-menu-item key="4-1" @click="navigatePage('/menus','4-1')" class="sub-menu-item">
              <div class="icon-container">{{t('Pos.Menu')}}</div>
            </a-menu-item>

            <a-menu-item key="4-2" @click="navigatePage('/menuConfigV2','4-2')" >
              <div class="icon-container">{{t('Pos.menuConfig')}}</div>
            </a-menu-item>


          </a-sub-menu> -->

          <a-menu-item key="13">
            <div class="icon-container" @click="navigatePage('/customers','13')">
              <UserOutlined />
              <span>{{t('Pos.Customers')}}</span>
            </div>
          </a-menu-item>

          <a-sub-menu key="6" >

            <template #title>
              <div class="icon-container">
                <span>
                  <AppstoreOutlined/>
                  <span>{{t('Pos.Tables')}}</span>
                </span>
              </div>
            </template>

            <a-menu-item key="6-1" @click="navigatePage('/tables','6-1')" class="sub-menu-item">
              <div class="icon-container">{{t('Pos.Tables')}}</div>
            </a-menu-item>

            <a-menu-item key="6-2" @click="navigatePage('/tableLocation','6-2')" >
              <div class="icon-container">{{t('Pos.TableLocation')}}</div>
            </a-menu-item>

            <a-menu-item key="6-3" @click="navigatePage('/tables-reservation','6-3')" >
              <div class="icon-container">{{t('Pos.TableReservations')}}</div>
            </a-menu-item>

            <a-menu-item key="6-4" @click="navigatePage('/tablesV2','6-4')" class="sub-menu-item">
              <div class="icon-container">{{t('Pos.Tables')}}</div>
            </a-menu-item>

          </a-sub-menu>

          <a-menu-item key="5">
            <div class="icon-container" @click="navigatePage('/workers','5')">
             <TeamOutlined />
              <span>{{t('Pos.Workers')}}</span>
            </div>
          </a-menu-item>

          <a-menu-item key="7">
            
            <div class="icon-container" @click="navigatePage('/device','7')">
              <span>
                <DesktopOutlined />
                <span >{{t('Pos.Hardwares')}}</span>
              </span>
            </div>
          
          </a-menu-item>

        
          <a-sub-menu key="8">
            <template #title>
              <div class="icon-container">
                <span>
                  <PercentageOutlined />
                  <span v-show="!collapsed">{{t('Pos.Promotion')}}</span>
                </span>
              </div>
            </template>
            <a-menu-item key="8-1" @click="navigatePage('/discount','8-1')">
              <div class="icon-container">{{t('Pos.Discount')}}</div>
            </a-menu-item>
            <a-menu-item key="8-2"  @click="navigatePage('/coupon','8-2')">
              <div class="icon-container">{{t('Pos.Coupon')}}</div>
            </a-menu-item>

            <a-menu-item key="8-3"  @click="navigatePage('/gift-card','8-3')">
              <div class="icon-container">{{t('Pos.giftCard')}}</div>
            </a-menu-item>
          </a-sub-menu>

          <a-menu-item key="9" >
            <div class="icon-container" @click="navigatePage('/payments','9')">
              <DollarOutlined />
              <span >{{t('Pos.Payments')}}</span>
            </div>
          </a-menu-item>

         

          <a-menu-item key="10">
            <div class="icon-container" @click="navigatePage('/sales','10')">
                <ShoppingOutlined />
                <span>{{t('Pos.Sales')}}</span>
            </div>
          </a-menu-item>

          <a-menu-item key="11">
            <div class="icon-container" @click="navigatePage('/Cart','11')">
                <ShoppingCartOutlined />
                <span>{{t('Pos.Cart')}}</span>
            </div>
          </a-menu-item>
          
          <a-sub-menu key="12">
            <template #title>
              <span>
                <div class="icon-container">
                  <SnippetsOutlined />
                  <span>{{t('Pos.Invoice')}}</span>
                </div>
              </span>
            </template>
           
            <a-menu-item key="12-1"  @click="navigatePage('/invoices','12-1')">
              <div class="icon-container">{{t('Pos.Invoice')}}</div>
            </a-menu-item>
            <a-menu-item key="12-2"  @click="navigatePage('/purchases','12-2')">
              <div class="icon-container">{{t('Pos.PurchaseInvloice')}}</div>
            </a-menu-item>
            
            <!-- new -->
            <!-- <a-menu-item key="12-3"  @click="navigatePage('/delivaryNote','12-3')">
              <div class="icon-container">{{t('Pos.deliveryNote')}}</div>
            </a-menu-item> -->
         
         
         

          </a-sub-menu>

          <!-- <a-menu-item key="14">
            <div class="icon-container" @click="navigatePage('/invoices','14')">
              <ShoppingOutlined />
              <span>{{t('Pos.Invoice')}}</span>
            </div>
          </a-menu-item> -->


          <a-sub-menu key="11">
            <template #title>
              <span>
                <div class="icon-container">
                  <SettingOutlined />
                  <span>{{t('Pos.Settings')}}</span>
                </div>
              </span>
            </template>

            <a-menu-item key="11-1"  @click="navigatePage('/invoice-settings','11-1')">
              <div class="icon-container">{{t('Pos.Billing')}}</div>
            </a-menu-item>

            
           
            <a-menu-item key="11-2"  @click="navigatePage('/users','11-2')">
              <div class="icon-container">{{t('Pos.Users')}}</div>
            </a-menu-item>
            <a-menu-item key="11-4"  @click="navigatePage('/vatrate','11-4')">
              <div class="icon-container">{{t('Pos.VatRates')}}</div>
            </a-menu-item>
            <a-menu-item key="11-5"  @click="navigatePage('/attributes','11-5')">
              <div class="icon-container">{{t('Pos.Attributes')}}</div>
            </a-menu-item>
            <a-menu-item key="11-6"  @click="navigatePage('/variants','11-6')">
              <div class="icon-container">{{t('Pos.Variants')}}</div>
            </a-menu-item>
            <a-menu-item key="11-7"  @click="navigatePage('/production-points','11-7')">
              <div class="icon-container">{{t('Pos.ProductionPoints')}}</div>
            </a-menu-item>
            <a-menu-item key="11-8"  @click="navigatePage('/custom-filed','11-8')">
              <div class="icon-container">{{t('Pos.Customfileds')}}</div>
            </a-menu-item>
            <a-menu-item key="11-9"  @click="navigatePage('/paymentsSettings','11-9')">
              <div class="icon-container">{{t('Pos.PaymentSettings')}}</div>
            </a-menu-item>

            <a-menu-item key="11-10"  @click="navigatePage('/loyalty','11-10')">
              <div class="icon-container">{{t('Pos.LoyaltySettings')}}</div>
            </a-menu-item>

            <a-menu-item key="11-11"  @click="navigatePage('/currency','11-11')">
              <div class="icon-container">{{t('Pos.Currency')}}</div>
            </a-menu-item>

            <!-- new -->
            <!-- <a-menu-item key="11-1"  @click="navigatePage('/cheques','11-12')">
              <div class="icon-container">{{t('Pos.backDatedChecks')}}</div>
            </a-menu-item> -->

            <!-- new -->
            <!-- <a-menu-item key="11-1"  @click="navigatePage('/bordereaux','11-13')">
              <div class="icon-container">{{t('Pos.checkSlips')}}</div>
            </a-menu-item> -->

          </a-sub-menu>

          <!--
          <a-sub-menu key="7">
            <template #title>
              <div class="icon-container">
                <span>
                  <ShopOutlined />
                  <span v-show="!collapsed">Test</span>
                </span>
              </div>
            </template>
            
            <a-menu-item key="2-1">option2</a-menu-item>
            <a-menu-item key="2-2">option3</a-menu-item>
            <a-menu-item key="2-3">option4</a-menu-item>
          </a-sub-menu>
          -->
            
        </a-menu>
      </div>
    </a-layout-sider>

    <a-layout style="overflow-y: auto;" >
      <a-layout-header  class="layout-header">

        


        <div class="trigger" style="margin-left: 16px;" >
          <menu-unfold-outlined v-if="collapsed" @click="() => (collapsed = !collapsed)" />
          <menu-fold-outlined v-else @click="() => (collapsed = !collapsed)" />
        </div>

        <a-dropdown class="lang-drop">
          <template #overlay>
            <a-menu>
              <a-menu-item key="1" @click="setLanguage('fr')">
                {{ t('Pos.French') }}
              </a-menu-item>
              <a-menu-item key="2" @click="setLanguage('ar')">
                {{ t('Pos.Arabic') }}
              </a-menu-item>
              <a-menu-item key="3" @click="setLanguage('en')">
                {{ t('Pos.English') }}
              </a-menu-item>
            </a-menu>
          </template>
          <a-button class="drop-btn">
            {{ currentLanguageText }}
            <DownOutlined />
          </a-button>
        </a-dropdown>



       
        <!-- right side -->
        <div :class="[locale === 'ar' ? 'avatar-container-rtl' : 'avatar-container']" >
          
          <a-space>
            <!-- notification -->
            <!-- <a-dropdown :trigger="['click']" placement="bottomRight" class="notif-container">
              <a class="ant-dropdown-link" @click.prevent>
                <a-badge :count="notificationCount">
                  <BellOutlined  :class="{'notification-icon': true, 'animate': shouldAnimate}" />
                </a-badge>
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0"  >
                    Notification admin
                  </a-menu-item>

                  <a-menu-divider />
                  <a-menu-item key="1"  >
                    Notification admin
                  </a-menu-item>

                  <a-menu-divider />

                  <a-menu-item key="555" >
                    <span class="view-all-txt"> > {{t('Pos.viewAll')}}</span>
                  </a-menu-item>

                </a-menu>
              </template>
            </a-dropdown> -->

           
            <a-dropdown class="stores-drop" @click.prevent>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-for="store in state.stores" :key="store.id" @click="switchStore(store.id)">
                    {{ store.name }}
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button class="drop-btn">
                {{state.storeName}}
                <DownOutlined />
              </a-button>
            </a-dropdown>
         
            <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" @click.prevent>
                <a-avatar  :size="35" class="avatar-icon">
                  <template #icon><UserOutlined /></template>
                </a-avatar>
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0"  @click="navigatePage('/profile')">
                {{t('Pos.Profile')}}
                  </a-menu-item>

                  <a-menu-divider />

                  <a-menu-item key="1" @click="logout()">{{t('Pos.Logout')}}</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </a-space>
          
           
        </div>

      </a-layout-header>

      <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: 'auto' }" :class="{ 'dashboard-bg': noBgRoute}">
        <router-view></router-view>
      </a-layout-content>

      <a-layout-footer class="footer">
       
        <p>{{t('Pos.Copyright')}}</p>
      </a-layout-footer>

    </a-layout>
  </a-layout>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, reactive,onBeforeUnmount, watchEffect  } from 'vue';
import { useRouter } from 'vue-router';
import { authService } from '@/_services';
import { useI18n } from 'vue-i18n';

import {
  ShopOutlined,
  InboxOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DesktopOutlined,
  SettingOutlined,
  DollarOutlined,
  PercentageOutlined,
  ShoppingOutlined,
  DownOutlined ,
  TeamOutlined,
  BarcodeOutlined,
  AppstoreOutlined,
  HomeOutlined,
  SnippetsOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  BellOutlined,
} from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { storeService  } from '@/_services';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    UserOutlined,
    ShopOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    InboxOutlined,
    PercentageOutlined,
    ShoppingOutlined,
    DownOutlined,
    DollarOutlined,
    TeamOutlined,
    LbrxLoaderSpinner,
    BarcodeOutlined,
    DesktopOutlined,
    AppstoreOutlined,
    SettingOutlined,
    HomeOutlined,
    SnippetsOutlined,
    ShoppingCartOutlined,
    //BellOutlined,
    //BookOutlined
  },
  setup() {
    
    const siderWidth = ref('159px');
    const { t, locale } = useI18n();
    const currentLanguage = ref(locale.value);
    const currentLanguageText = ref(t(`Pos.${currentLanguage.value === 'fr' ? 'French' : currentLanguage.value === 'en' ? 'English' : 'Arabic'}`));
    const setLanguage = (lang:any) => {
      localStorage.setItem('Language', lang);
      location.reload();
    };

    const updateSiderWidth = () => {
      siderWidth.value = window.innerWidth >= 1200 ? '236px' : '159px';
      collapsed.value = window.innerWidth >= 1200 ? false : true;
    };


    const state = reactive({
      stores: [] as any[],
      storeName:'',
      currentPath:'',
    });

    const route = useRoute();

    const noBgRoute = computed(() => {
      return route.path === '/' || route.path === '/profile' || route.path === '/menu' || route.path === '/tablesV2';
    });



    interface Theme {
      [key: string]: string;
    } 

    const iconSize = computed(() => collapsed.value ? 30 : 24);

    const selectedKeys = ref<string[]>(['0']); 
    const collapsed = ref<boolean>(false);
    const router = useRouter();

    const navigatePage = (link: string, key:string) => {
      // router.push(link); 
      // selectedKeys.value = [key];
      // localStorage.setItem('selectedKey', key);
      // console.log('selected Key', selectedKeys.value);


      router.push(link).then(() => {
        selectedKeys.value = [key];
        localStorage.setItem('selectedKey', key);

        selectedKeys.value = [getSelectedKey(router.currentRoute.value.path)];

      });
    };


    const FrLanguage=()=> {
      localStorage.setItem('Language', 'fr')
      location.reload();
    }

    const ArLanguage=()=> {
      localStorage.setItem('Language', 'ar')
      location.reload();
    }

    const EnLanguage=()=> {
      localStorage.setItem('Language', 'en')
      location.reload();
    }


    const isSelected = (key: string) => {
      return selectedKeys.value.includes(key);
    };

    // ---
    const themeTemplate = {
      primaryColor: "#fc8019",
      confirmationColor: "#09aa29",
      cart: {
          selectCartItemColor: "#09aa29"
      }
    }
    const themeString = window.localStorage.getItem('themeTemplate');
    let theme: Theme = {};
    if (themeString) {
      theme = JSON.parse(themeString) ;
      themeTemplate.primaryColor = theme.primaryColor;
      themeTemplate.confirmationColor = theme.confirmationColor;
      console.log('rrrrrrrr : ', themeTemplate)
      const customStyles = `
      .bg-primary {
          background-color: ${themeTemplate.primaryColor};
      }
      .text-primary {
          color: ${themeTemplate.primaryColor};
      }
      .border-primary {
          border-color: ${themeTemplate.primaryColor};
      }
      .bg-confirmation {
          background-color: ${themeTemplate.confirmationColor};
      }
      .text-confirmation {
          color: ${themeTemplate.confirmationColor};
      }
      .accent-primary {
          accent-color: ${themeTemplate.primaryColor};
      }
      `;

      const styleElement = document.createElement('style');
      styleElement.type = 'text/css';

      if ('styleSheet' in styleElement) {
          (styleElement as any).styleSheet.cssText = customStyles;
      } else {
          styleElement.appendChild(document.createTextNode(customStyles));
      }

      document.head.appendChild(styleElement);
    }

    const logout = () => {
        authService.logout().then(() => {
          // 
          localStorage.removeItem("user");
          localStorage.removeItem("store");
          localStorage.setItem('selectedKey', '0');
          location.reload();
          
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        console.log('finish')
      })
    };

    const getStores=()=>{
      storeService.getUserStores().then((response)=>{
        state.stores=response.data;
      }).catch((error)=>{
        console.log('error get stores api', error);
      });
    }

    const getCurrentStore =()=>{
      const storeName = localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!).name : '';
      state.storeName=storeName;
    }
 
    const loading = ref(false);

    const switchStore=(id:any)=>{
      console.log('id', id)
      
      
      storeService.switchStore(id).then((response)=>{
        console.log('switch store successfully', response);
        state.storeName=response.name;
        location.reload();
        
      }).catch((error)=>{
        console.log("error switch store", error);
      }).finally(()=>{
        console.log('finish');
      });
    }

    function getSelectedKey(path:any) {
      switch (path) {

        case '/':
          return '0';

        case '/store':
          return '1';

        case '/warehouse':
          return '2-1';

        case '/inventory':
          return '2-2';

        case '/stockOverview':
          return '2-3';

        case '/transfer-requests':
          return '2-4';

        case '/products':
          return '3-1';

        case '/category':
          return '3-2';

        case '/ingredients':
          return '3-3-1';

        case '/ingredient-categories':
          return '3-3-2';

        case '/manufacturer':
          return '3-4';

        case '/supplier':
          return '3-5';

        case '/preferences':
          return '3-6';

        case '/menus':
          return '4';

        case '/customers':
          return '13';

        case '/tables':
          return '6-1';

        case '/tableLocation':
          return '6-2';

        case '/tables-reservation':
          return '6-3';

        case '/workers':
          return '5';

        case '/device':
          return '7';

        case '/discount':
          return '8-1';

        case '/coupon':
          return '8-2';

        case '/sales':
          return '10';

        case '/payments':
          return '9';

        case '/Cart':
          return '11';

        case '/invoices':
          return '12-1';

        case '/purchases':
          return '12-2';

        case '/delivaryNote':
          return '12-3';

        case '/invoice-settings':
          return '11-1';

        case '/users':
          return '11-2';
        
        case '/vatrate':
          return '11-4';

        case '/attributes':
          return '11-5';

        case '/variants':
          return '11-6';

        case '/production-points':
          return '11-7';

        case '/custom-filed':
          return '11-8';

        case '/paymentsSettings':
          return '11-9';

        case '/loyalty':
          return '11-10';

        case '/currency':
          return '11-11';

        case '/cheques':
          return '11-12';

        case '/bordereaux':
          return '11-13';

        default:
          return ''; 
      }
    }

    watchEffect(() => {
      const currentPath = router.currentRoute.value.path;
      state.currentPath=currentPath;
      selectedKeys.value=[getSelectedKey(state.currentPath)];
    });

    //notification
    const notificationCount = ref(5);
    const shouldAnimate = ref(false);
    let animationInterval: ReturnType<typeof setInterval>;


    onBeforeUnmount(() => {
      clearInterval(animationInterval);
    });

    function triggerAnimation() {
      shouldAnimate.value = true;
      setTimeout(() => {
        shouldAnimate.value = false;
      }, 2000); 
    }


    onMounted(() => { 

      //notification
      if (notificationCount.value > 0) {
        triggerAnimation();
        animationInterval = setInterval(triggerAnimation, 15000);
      }


      //notification

      updateSiderWidth();
      window.addEventListener('resize', updateSiderWidth);
   
      getStores();
      getCurrentStore();

      const currentPath=useRouter();
      console.log('current paaaatth mount', currentPath.currentRoute.value.name);
      selectedKeys.value=[getSelectedKey(currentPath.currentRoute.value.name)];
      
      // const storedSelectedKeys = localStorage.getItem('selectedKey');
      // selectedKeys.value = storedSelectedKeys ? [storedSelectedKeys] : [];
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateSiderWidth);
    });

    //rtl
    const textDirection = computed(() => {
      return locale.value === 'ar' ? 'rtl' :'ltr';}
    );

  


    return {
      shouldAnimate,
      notificationCount,

      collapsed,
      siderWidth,
      currentLanguageText,
      setLanguage,
      t,
      locale,
      textDirection,
      state,
      selectedKeys,
      navigatePage,
      iconSize,
      isSelected,
      themeTemplate,
      logout,
      noBgRoute,
      getCurrentStore,
      switchStore,
      loading,
      FrLanguage,
      ArLanguage,
      EnLanguage
    };
  }
});
</script>
<style scoped>

 
  

  .icon-container{
    display: flex;
    align-items: center;
  }


  .icon{
    margin-right: 8px;
  }

  .logo {
    text-align: center;
    margin-top: 25px;
  }

  .logo p{
    font-size: 20px;
    color: #fc8019;
    cursor: pointer;
    font-family: "Exo 2", sans-serif;
    font-weight: bold;
    font-size: 25px;
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: #fc8019;
  }

  .trigger:hover {
    color: #fc8019;
  }

  .footer{
    display: flex;
    padding: 12px 20px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    background: #f5f5f5;
  }
  .footer p{
    color: #fc8019;
  }

  .layout-sider{
    /* overflow: 'auto';
    height: '100vh';
    position: 'fixed'; */


    width: 200px;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;

    
    /*border-right: 2px solid #d1d5db;*/
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

  }

  .avatar-container {
    margin-left: auto; /*to the right*/
    margin-right: 16px;
  }

  .avatar-container-rtl{
    margin-right: auto;
    margin-left: 16px;
  }

  .avatar-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    background: #fff;
    color: #fc8019;
  }

  #components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }

  #components-layout-demo-custom-trigger .trigger:hover {
    color: #FFF2E8;
  }

  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.3);
    margin: 16px;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  .sider-menu{
    background: #fff ;
    font-size: 13px;
    color: black;
    font-family: "Exo 2", sans-serif; 

    height: '100%';

  }

  .menu-item{
    flex: initial !important;
  }

  .layout-header{
    background: #fff;
    padding: 0;
    display: flex;
    top: 0;
    position: sticky;
    align-items: center;
    /*border-bottom: 2px solid #d1d5db; */
  
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;



  }


  /* header new*/
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    background: #fff;
  }
  .header-left,
  .header-right,
  .header-right-rtl {
    display: flex;
    align-items: center;
  }

  .header-right {
  justify-content: flex-end;
}

.header-right-rtl {
  justify-content: flex-start;
}

.trigger {
  margin-right: 16px;
  cursor: pointer;
}

.lang-drop,
.stores-drop,
.notif-container {
  margin-left: 16px;
}

/* header new*/



  .stores-drop{
    color: #fc8019;
    border-color : #fc8019;
  }

  .drop-btn{
    font-family: "Exo 2";
  }

  .lang-drop{
    color: #fff;
    background: #fc8019;
    border-color: #fc8019;
    box-shadow: none;
  }

  .lang-drop:hover{
    color: #fff;
    border-color: #fc8019 ;
  }

  .dashboard-bg{
    background: none !important;
  }

  .logo-img{
    cursor: pointer;
  }

  .logo-img-collapsed{
    max-height: 100px;
    max-width: 35px;

    cursor: pointer;
  }

  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notif-container{
    margin-right: 10px;
  }

  .notification-icon{
    color: #fc8019;
    font-size: 20px;
  }

  .view-all-txt{
    font-weight: 600;
    color:#fc8019 ;
  }


  /* CSS for RTL */
  .is-rtl {
    direction:rtl;
  }

  /* bell animation*/
  @keyframes bell-ring {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(-15deg); }
    20% { transform: rotate(15deg); }
    30% { transform: rotate(-10deg); }
    40% { transform: rotate(10deg); }
    50% { transform: rotate(-5deg); }
    60% { transform: rotate(5deg); }
    70% { transform: rotate(-3deg); }
    80% { transform: rotate(3deg); }
    90% { transform: rotate(-1deg); }
    100% { transform: rotate(0deg); }
  }

  .notification-icon.animate {
    animation: bell-ring 2.0s ease-in-out ;
  }

  @media (max-width: 768px) {
  .lang-drop {
    font-size: 12px; /* Smaller font size for mobile */
    margin-left: 8px;
  }

  .drop-btn {
    font-size: 12px; /* Smaller font size for mobile */
  }

  .ant-menu-item {
    font-size: 12px; /* Smaller font size for menu items */
  }
  
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .header-left,
  .header-right,
  .header-right-rtl {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px;
  }
}

 

  
 
  

  

 
 

</style>
