<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Home") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ t("Pos.Settings") }}</a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.Currency") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer(true)">
      {{ t("Pos.Add") }}
    </a-button>
  </div>
  <div>
    <a-table
      class="table-container"
      :columns="columns"
      :data-source="reactiveData.data"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <a-space>
            <a-tooltip>
              <template #title>{{ t("Pos.View") }}</template>
              <a-button
                class="view-btn"
                @click="() => showDisplayDrawer(record)"
              >
                <template #icon>
                  <EyeOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.Edit") }}</template>

              <a-button type="primary" @click="() => showDrawer(record)">
                <template #icon>
                  <EditOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.Delete") }}</template>
              <a-button type="primary" danger @click="showDeletePopup(record)">
                <template #icon>
                  <DeleteOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>
        <template v-if="column.key === 'is_default'">
          <a-tag :bordered="false" color="error" v-if="record.is_default == 0">
            <template #icon>
              <check-circle-outlined />
            </template>
            {{ t("Pos.Nouse") }}
          </a-tag>
          <a-tag
            :bordered="false"
            color="success"
            v-if="record.is_default == 1"
          >
            <template #icon>
              <check-circle-outlined />
            </template>
            {{ t("Pos.Inuse") }}
          </a-tag>
        </template>
        <template v-if="column.key == 'currencyName'">
          {{ formatcurrency(record) }}
        </template>

        <template v-if="column.key == 'code'">
          {{ formatcurrencyCode(record) }}
        </template>

        <template
          v-if="column.key === 'created_at' || column.key === 'updated_at'"
        >
          {{ formatDisplayDate(record[column.key]) }}
        </template>
      </template>
      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon>
              <SearchOutlined />
            </template>
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ t("Pos.Reset") }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>

    <div class="pagination">
      <a-pagination
        class="paginationArrows"
        v-model:current="currentPage"
        :total="form.customLastPage"
        @change="handlePageChange"
        :default-page-size="perPage"
        :showSizeChanger="false"
      />
      <a-select
        class="select-input"
        v-model:value="customPagination"
        placeholder="Order By"
        @change="choosePerPage"
        v-if="form.customLastPage > 10"
      >
        <a-select-option value="10">10 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="20">20 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="50">50 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="100">100 / {{ t("Pos.page") }}</a-select-option>
      </a-select>
    </div>

    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
    >
      <template #title>
        <div
          class="drawer-header-ar"
          v-if="locale === 'ar' && reactiveData.action == 'add'"
        >
          <span>{{ t("Pos.AddCurrency") }}</span>
        </div>
        <div
          class="drawer-header-all"
          v-if="locale !== 'ar' && reactiveData.action == 'add'"
        >
          <span>{{ t("Pos.AddCurrency") }}</span>
        </div>

        <div
          class="drawer-header-ar"
          v-if="locale === 'ar' && reactiveData.action == 'update'"
        >
          <span>{{ t("Pos.UpdateCurrency") }}</span>
        </div>
        <div
          class="drawer-header-all"
          v-if="locale !== 'ar' && reactiveData.action == 'update'"
        >
          <span>{{ t("Pos.UpdateCurrency") }}</span>
        </div>
      </template>

      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item name="active">
              <template #label>
                <span class="label-form">{{ t("Pos.Currencys") }}</span>
              </template>
              <a-select
                v-model:value="form.active"
                placeholder="t('Pos.CurrencyChoose')"
                class="input-form"
                :disabled="reactiveData.action !== 'add'"
                show-search
                :option-filter-prop="'label'"
              >
                <a-select-option
                  v-for="currency in currencys.data"
                  :key="currency.id"
                  :value="currency.id"
                  :label="currency.name"
                >
                  {{ currency.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item name="default" v-if="reactiveData.action == 'update'">
              <a-checkbox v-model:checked="form.default"
                >{{ t("Pos.PaymentDefault") }}
              </a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <div :class="{ 'is-rtl': textDirection === 'rtl' }">
          <a-space v-if="reactiveData.action == 'update'">
            <a-button type="primary" @click="onSubmitUpdate(form.id)">{{
              t("Pos.Update")
            }}</a-button>
            <a-button type="primary" danger @click="hideForm">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
          <a-space v-else>
            <a-button type="primary" @click="onSubmitAdd">{{
              t("Pos.Add")
            }}</a-button>
            <a-button type="primary" danger @click="hideForm">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>
      </template>
    </a-drawer>

    <a-drawer
      v-model:open="openDisplay"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :width="modalWidth"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenShowChange"
      @close="hideForm"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.ViewCurrency") }}</span>
        </div>
        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.ViewCurrency") }}</span>
        </div>
      </template>

      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="24">
          <a-col :span="8">
            <p>
              <span class="info-title">{{ t("Pos.currency") }} :</span>
              {{ form.name }}
            </p>
          </a-col>

          <a-col :span="8">
            <p>
              <span class="info-title">{{ t("Pos.Code") }} :</span>
              {{ form.code }}
            </p>
          </a-col>

          <a-row :gutter="24">
            <a-col :span="24">
              <p>
                <span class="info-title">{{ t("Pos.Status") }} :</span>
                <a-tag
                  :bordered="false"
                  color="success"
                  v-if="form.default === true"
                >
                  <template #icon>
                    <check-circle-outlined />
                  </template>
                  {{ t("Pos.Inuse") }}
                </a-tag>
                <a-tag
                  :bordered="false"
                  color="error"
                  v-if="form.default === false"
                >
                  <template #icon>
                    <check-circle-outlined />
                  </template>
                  {{ t("Pos.Nouse") }}
                </a-tag>
              </p>
            </a-col>
          </a-row>
        </a-row>
      </a-form>

      <template #footer> </template>
    </a-drawer>
  </div>
</template>
    <script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRaw,
  computed,
} from "vue";
import type { Rule } from "ant-design-vue/es/form";
import Swal from "sweetalert2";
import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";

import { notification } from "ant-design-vue";
import { attributeService, currencyService } from "@/_services";
import dayjs from "dayjs";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "indexAttributes",
  components: {
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    LbrxLoaderSpinner,
    CheckCircleOutlined,
    SearchOutlined,
  },
  setup() {
    const modalWidth = ref(720);
    const customPagination = ref("10");
    const { locale } = useI18n();
    const { t } = useI18n();
    const searchInput = ref<HTMLInputElement | null>(null);
    const columns = [
      {
        title: t("Pos.Currency"),
        key: "currencyName",
        dataIndex: "currencyName",
      },

      {
        title: t("Pos.Code"),
        key: "code",
        dataIndex: "code",
      },

      {
        title: t("Pos.Status"),
        key: "is_default",
        dataIndex: "is_default",
      },
      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      },
    ];

    //form
    const formRef = ref();
    const visible = ref<boolean>(false);

    const reactiveData = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchedColumn: "",
      searchText: "",
    });

    const currencys = reactive({
      data: [] as any[],
      currency: [] as any[],
      action: "",
      totalPages: 0,
      searchedColumn: "",
      searchText: "",
    });

    const form = reactive({
      id: "",
      name: "",
      active: "",
      default: false,
      defaultWithNumber: "",
      code: "",
      customLastPage: 0,
      currency_id: 0,
      currencyName: "",
    });

    const resetForm = () => {
      form.active = "";
      form.name = "";
      form.default = false;
      form.code = "";
    };

    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);
    const rules: Record<string, Rule[]> = {
      active: [{ required: true, message: t("Pos.CurrencyValidation") }],
    };

    const formatDate = (dateString: string) => {
      return dayjs(dateString);
    };

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    };

    //drawer

    const open = ref<boolean>(false);

    const showAddDrawer = (state: boolean) => {
      open.value = state;
      resetForm();
      displayCurrency();
      reactiveData.action = "add";
      resetForm();
    };

    const afterOpenChange = (bool: boolean) => {
      //console.log('open', bool);
    };
    const formatcurrency = (dateString: any) => {
      if (dateString && dateString.currency) {
        if (dateString.currency.name) {
          return dateString.currency.name;
        }
      }
      return "";
    };
    const formatcurrencyCode = (dateString: any) => {
      if (dateString && dateString.currency) {
        if (dateString.currency.code) {
          return dateString.currency.code;
        }
      }
      return "";
    };

    const showDrawer = (record: any) => {
      updateModalWidth();
      open.value = true;
      reactiveData.action = "update";
      getCurrencyById(record);
    };

    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      reactiveData.searchText = "";
      customPagination.value = "10";
      getCurrency();
    };

    const onSubmitAdd = () => {
      formRef.value
        .validate()
        .then(() => {
          addCurrency();
        })
        .catch((error: any) => {
          // console.log('error', error);
        });
    };

    const hideForm = (bool: boolean) => {
      open.value = false;
      formRef.value.resetFields();
    };

    const goToValue = (record: any) => {
      router.push(`/attribute/${record.id}/values`);
    };

    const onSubmitUpdate = (id: string) => {
      formRef.value
        .validate()
        .then(() => {
          updateCurrency(id);
        })
        .catch((error: any) => {
          //  console.log('error', error);
        });
    };

    const displayCurrency = () => {
      //   console.log(currencys.data);
    };

    const getCurrency = () => {
      loading.value = true;
      currencyService
        .getAll(currentPage.value, perPage.value)
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
          form.customLastPage = res.meta.last_page * 10;
        })
        .catch((error) => {
          // console.log("error api : get attributes", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getCurrencyList = () => {
      loading.value = true;
      currencyService
        .getList()
        .then((res) => {
          currencys.data = res.data;
        })
        .catch((error) => {
          //  console.log("error api : get attributes", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getAttributForFilter = (searchText: string) => {
      loading.value = true;

      // Adjust this part according to your API call
      attributeService
        .searchWithoutPagination("name", searchText)
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
        })
        .catch((error) => {
          // console.log("error api : get warehouses", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const addCurrency = () => {
      let payload = {
        currency_id: form.active,
        is_default: 0,
      };

      loading.value = true;

      currencyService
        .create(payload)
        .then((res) => {
          reactiveData.data.push(res.data);
          showSuccessNotification(t("Pos.CurrencyAdded"));

          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch((err) => {
          //  console.log(err);
        })
        .finally(() => {
          open.value = false;
          loading.value = false;
        });
    };

    const updateCurrency = (id: string) => {
      if (form.default == true) {
        form.defaultWithNumber = "1";
      } else {
        form.defaultWithNumber = "0";
      }
      loading.value = true;
      let payload = {
        currency_id: form.currency_id,
        is_default: form.defaultWithNumber,
      };
      currencyService
        .update(id, payload)
        .then((res) => {
          reactiveData.data = reactiveData.data.map((el) =>
            el.id !== id ? el : res.data
          );
          showSuccessNotification(t("Pos.UpdateCurrency"));
          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch((err) => {
          //  console.log(err)
        })
        .finally(() => {
          open.value = false;
          loading.value = false;
        });
    };

    const getCurrencyById = (record: any) => {
      form.id = record.id;
      form.code = record.currency.code;
      form.name = record.currency.name;
      form.active = record.currency.name;
      form.currency_id = record.currency_id;
      if (record.is_default == 1) {
        form.default = true;
      } else {
        form.default = false;
      }
      console.log(form.currency_id);
    };

    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      // reactiveData.action = 'view';
      openDisplay.value = true;
      getCurrencyById(record);
      reactiveData.action = "view";
    };

    const updateAttribute = (id: string) => {
      let payload = {
        name: form.name,
      };
      loading.value = true;
      attributeService
        .update(id, payload)
        .then((res) => {
          reactiveData.data = reactiveData.data.map((el) =>
            el.id !== id ? el : res.data
          );
          showSuccessNotification("attribute updated successfully");
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          open.value = false;
          loading.value = false;
        });
    };

    const deleteAttribute = (id: string) => {
      loading.value = true;
      attributeService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification("Attribute deleted successfully");
        })
        .catch((err) => {
          //  console.log(err)
        })
        .finally(() => {
          // console.log('finish')
          loading.value = false;
        });
    };

    const choosePerPage = () => {
      currentPage.value = 1;
      // currentPage.value = newPage;
      loading.value = true;
      //console.log('per page', perPage.value);
      //console.log('page', currentPage.value)
      currencyService
        .getAll(currentPage.value, customPagination.value)
        .then((response) => {
          //console.log('data', response.data)
          reactiveData.data = response.data;
          form.customLastPage = response.meta.last_page * 10;

          console.log("lastPage", form.customLastPage);
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const deleteCurrency = (id: string) => {
      loading.value = true;
      currencyService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification(t("Pos.CurrencyDeleted"));
          loading.value = false;
        })
        .catch((err) => {
          //  console.log(err)
        })
        .finally(() => {
          console.log("finish");
          //   loading.value = false;
        });
    };
    const openDisplay = ref<boolean>(false);
    const afterOpenShowChange = (bool: boolean) => {
      //  console.log('open', bool);
    };

    const loading = ref(false);
    const router = useRouter();
    const navigatePage = (link: string) => {
      router.push(link);
    };

    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;
      loading.value = true;

      // console.log('per page', perPage.value);
      // console.log('page', currentPage.value)

      currencyService
        .getAll(currentPage.value, customPagination.value)
        .then((response) => {
          //  console.log('data', response.data)
          reactiveData.data = response.data;
          form.customLastPage = response.meta.last_page * 10;
        })
        .catch((error) => {
          //   console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleSearch = (
      selectedKeys: any[],
      confirm: () => void,
      dataIndex: string
    ) => {
      confirm();
      getAttributForFilter(selectedKeys[0]);
      reactiveData.searchedColumn = dataIndex;
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: t("Pos.DeleteTitle"),
        text: t("Pos.DeleteText"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Delete"),
        cancelButtonColor: "#d33",
        cancelButtonText: t("Pos.Cancel"),
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteCurrency(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          //   console.log("cancel delete ");
        }
      });
    };

    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };
    onMounted(() => {
      getCurrencyList();
      getCurrency();
      searchInput.value = document.querySelector(
        ".ant-table-filter-dropdown input"
      );
    });

    return {
      updateModalWidth,
      modalWidth,
      textDirection,
      locale,
      displayCurrency,
      loading,
      t,
      rules,
      columns,
      formRef,
      afterOpenChange,
      form,
      formatDisplayDate,
      reactiveData,
      showAddDrawer,
      showDrawer,
      open,
      formatDate,
      onSubmitUpdate,
      onSubmitAdd,
      showDeletePopup,
      visible,
      hideForm,
      goToValue,
      showDisplayDrawer,
      openDisplay,
      afterOpenShowChange,
      navigatePage,
      perPage,
      currentPage,
      handlePageChange,
      handleSearch,
      formatcurrency,
      formatcurrencyCode,
      currencys,
      deleteCurrency,
      getCurrencyById,
      handleReset,
      choosePerPage,
      customPagination,
    };
  },
});
</script>
    
    <style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.updateText {
  color: black;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.is-rtl {
  direction: rtl;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.breadcrumb-icon {
  cursor: pointer;
}
.test-btn {
  color: #bfffca8a;
}

.input-form[disabled] {
  background-color: white;
  color: #000000;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.select-input {
  width: 8%;
}

.drawer-header-ar {
  padding-right: 2%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.drawer-header-all {
  display: flex;
  justify-content: start;
  align-items: center;
}

.paginationArrows {
  direction: ltr;
}

.table-container {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
    margin-top: 10px;
  }
}
</style>