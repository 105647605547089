import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33793d93"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar-text" }
const _hoisted_2 = { class: "label-form" }
const _hoisted_3 = { class: "label-form" }
const _hoisted_4 = { class: "label-form" }
const _hoisted_5 = { class: "label-form" }
const _hoisted_6 = { class: "label-form" }
const _hoisted_7 = { class: "label-form" }
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_segmented = _resolveComponent("a-segmented")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_input_password = _resolveComponent("a-input-password")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          span: 24,
          class: "options-container"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_segmented, {
              value: _ctx.activeTab,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
              options: _ctx.tabList,
              onChange: _ctx.onTabChange
            }, null, 8, ["value", "options", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.activeTab === _ctx.t('Pos.Profile') )
      ? (_openBlock(), _createBlock(_component_a_card, {
          key: 0,
          class: "profile-card"
        }, {
          title: _withCtx(() => [
            _createVNode(_component_a_row, {
              class: "title-row",
              align: "middle"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 12,
                  class: "title-text"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.ProfileDetails')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 12,
                  class: "avatar-col"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_avatar, { size: 50 }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.state.firstName), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              ref: "profileRef",
              model: _ctx.profileForm,
              rules: _ctx.rules,
              layout: "vertical"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { gutter: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "firstName" }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('Pos.FisrtName')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.profileForm.firstName,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.profileForm.firstName) = $event)),
                              placeholder: _ctx.t('Pos.FisrtName'),
                              class: "input-form"
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "lastName" }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('Pos.LastName')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.profileForm.lastName,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.profileForm.lastName) = $event)),
                              placeholder: _ctx.t('Pos.LastName'),
                              class: "input-form"
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { gutter: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          name: "email",
                          "has-feedback": "",
                          "validate-status": _ctx.validatingStatus,
                          help: _ctx.validatingHelp
                        }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('Pos.Email')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.profileForm.email,
                              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.profileForm.email) = $event)),
                              placeholder: _ctx.t('Pos.Email'),
                              onBlur: _ctx.validateEmail
                            }, null, 8, ["value", "placeholder", "onBlur"])
                          ]),
                          _: 1
                        }, 8, ["validate-status", "help"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "userName" }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('Pos.Username')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _ctx.profileForm.userName,
                              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.profileForm.userName) = $event)),
                              placeholder: _ctx.t('Pos.Username'),
                              class: "input-form"
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "rules"]),
            _createVNode(_component_a_row, { class: "card-footer" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_space, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateUser(_ctx.profileForm.id)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.SaveChanges')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: "",
                      onClick: _ctx.resetFormProf
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_ctx.activeTab === _ctx.t('Pos.Security'))
        ? (_openBlock(), _createBlock(_component_a_card, {
            key: 1,
            class: "profile-card"
          }, {
            title: _withCtx(() => [
              _createVNode(_component_a_row, {
                class: "title-row",
                align: "middle"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 12,
                    class: "title-text"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.ChangePassword')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_form, {
                ref: "securityRef",
                model: _ctx.securityForm,
                rules: _ctx.securityRules,
                layout: "vertical"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            "has-feedback": "",
                            name: "current"
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Pos.CurrentPass')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_password, {
                                value: _ctx.securityForm.current,
                                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.securityForm.current) = $event)),
                                autocomplete: "off"
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            "has-feedback": "",
                            name: "pass"
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.Password')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_password, {
                                value: _ctx.securityForm.pass,
                                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.securityForm.pass) = $event)),
                                type: "password",
                                autocomplete: "off"
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            "has-feedback": "",
                            name: "checkPass"
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.Confirmpassword')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_password, {
                                value: _ctx.securityForm.checkPass,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.securityForm.checkPass) = $event)),
                                type: "password",
                                autocomplete: "off"
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "rules"]),
              _createVNode(_component_a_row, { class: "card-footer" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_space, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateUserPass()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.UpdatePass')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: _ctx.resetFormPass
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_ctx.activeTab === _ctx.t('Pos.Billing&Plans'))
          ? (_openBlock(), _createElementBlock("p", _hoisted_9, "Billing content"))
          : _createCommentVNode("", true)
  ], 64))
}