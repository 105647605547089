<template>
    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>
    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{ t('Pos.Dashboard') }}
        </a-breadcrumb-item>
        <a-breadcrumb-item  @click="navigatePage('/attributes')" class="breadcrumb-icon"> {{ t('Pos.Attributes') }}</a-breadcrumb-item>
        <a-breadcrumb-item>{{t('Pos.AttributeValue')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>
    <div class="btn-container">
        <a-button class="add-btn" @click="showAddDrawer(true)">
            {{ t('Pos.Add') }}
        </a-button>
    </div>
    <div>
        <a-table class="table-container" :columns="columns" :data-source="reactiveData.data" :pagination="false">

            <template #bodyCell="{ column, record }">
                <template v-if="column.key == 'actions'">
                    <div class="btn-container">
                        <a-space>

                            <a-tooltip>
                                <template #title>{{ t('Pos.View') }}</template>
                                <a-button class="view-btn" @click="showDisplayDrawer(record)">
                                    <template #icon>
                                        <EyeOutlined />
                                    </template>
                                </a-button>
                            </a-tooltip>


                            <a-tooltip>
                                <template #title>{{ t('Pos.Update') }}</template>
                                <a-button type="primary" @click="showDrawer(record)">
                                    <template #icon>
                                        <EditOutlined />
                                    </template>
                                </a-button>
                            </a-tooltip>


                            <a-tooltip>
                                <template #title>{{ t('Pos.Delete') }}</template>
                                <a-button type="primary" danger @click="showDeletePopup(record)">
                                    <template #icon>
                                        <DeleteOutlined />
                                    </template>
                                </a-button>
                            </a-tooltip>
                        </a-space>
                    </div>
                </template>

                <template v-if="column.key === 'created_at' || column.key === 'updated_at'">
                    {{ formatDisplayDate(record[column.key]) }}
                </template>
            </template>
        </a-table>

        <a-drawer v-model:open="open" class="custom-class" root-class-name="root-class-name" :root-style="{ color: 'blue' }"
            style="color: red" :title="reactiveData.action == 'add' ? t('Pos.AddAttributeValue') : t('Pos.UpdateAttributeValue')"
            placement="right" :width="modalWidth" :body-style="{ paddingBottom: '80px' }" @after-open-change="afterOpenChange" @close="hideForm">

            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">

                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item name="name">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Name') }}</span>
                            </template>
                            <a-input v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form" />
                        </a-form-item>
                    </a-col>
                    <!-- <a-col :span="12">
                        <a-form-item name="attribute_id">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Attribute') }}</span>
                            </template>
                            <a-select placeholder="Please choose an attribute" v-model:value="form.attribute_id">
                                <a-select-option v-for="(item, index) in reactiveData.attributes" :key="index"
                                    :value="item.id">{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col> -->
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item name="value">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Value') }}</span>
                            </template>
                            <a-input v-model:value="form.value" :placeholder="t('Pos.Value')" class="input-form" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item name="type">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Type') }}</span>
                            </template>
                            <a-select :placeholder="t('Pos.Type')" v-model:value="form.type">
                                <a-select-option v-for="(item, index) in types" :key="index"
                                    :value="item.name">{{ item.name }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>

            </a-form>

            <template #footer>
                <a-space v-if="reactiveData.action == 'update'">
                    <a-button type="primary" @click="onSubmitUpdate(form.id)">{{ t('Pos.Update') }}</a-button>
                    <a-button type="primary" danger @click="hideForm">{{ t('Pos.Cancel') }}</a-button>
                </a-space>
                <a-space v-else>
                    <a-button type="primary" @click="onSubmitAdd">{{ t('Pos.Add') }}</a-button>
                    <a-button type="primary" danger @click="hideForm">{{ t('Pos.Cancel') }}</a-button>
                </a-space>
            </template>

        </a-drawer>

        <!-- display drawer -->
        <a-drawer
            v-model:open="openDisplay"
            class="custom-class"
            root-class-name="root-class-name"
            :title="t('Pos.PurchaseinvoiceInfo')"
            placement="right"
            :width="modalWidth"
        >

            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
            
                <a-row >

                    <a-col :span="12">
                        <p><span class="info-title">{{ t('Pos.Name') }} :</span> {{form.name}}</p>
                    </a-col>

                    <a-col :span="12">
                        <p><span class="info-title">{{ t('Pos.Value') }} :</span> {{form.value}}</p>
                    </a-col>

                </a-row>

                <a-row>

                    <a-col :span="12">
                        <p><span class="info-title">{{ t('Pos.Type') }} :</span>{{form.type}}</p>
                    </a-col>

                </a-row>

                

                
               
            </a-form>

        </a-drawer>

    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { attributeValuesService, attributeService } from '@/_services'
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';


export default defineComponent({
    name: 'attributeValues',
    components: {
        EyeOutlined,
        DeleteOutlined,
        LbrxLoaderSpinner,
        EditOutlined
    },
    setup() {
        const modalWidth = ref(720);
        const loading = ref(false);
        const { t } = useI18n();
        const route = useRoute();
        const router = useRouter();
        const routeId = ref<string>('');

        const navigatePage = (link: string) => {
            router.push(link); 
        };


        const columns = [
            {
                title: t('Pos.Name'),
                key: 'name',
                dataIndex: 'name',
            },
            {
                title: t('Pos.Value'),
                key: 'value',
                dataIndex: 'value',
            },
            {
                title: t('Pos.Type'),
                key: 'type',
                dataIndex: 'type',
            },
            {
                title: t('Pos.Actions'),
                key: 'actions',
                dataIndex: 'actions',
            }

        ];

        
        const state = reactive({
            totalPages:0,
        });


        //form  
        const formRef = ref();
        const visible = ref<boolean>(false);

        const reactiveData = reactive({
            data: [] as any[],
            attribute: {},
            action: ''
        });

        const form = reactive({
            id: '',
            name: '',
            attribute_id: '',
            value: '',
            type: ''
        });

        const types = reactive([{id: 1, name: t('Pos.color')}, {id: 2, name:t('Pos.shape')}, {id: 3, name: t('Pos.size')}]);

        const resetForm = () => {
            form.id = '';
            form.name = '';
            form.attribute_id = '';
            form.value = '';
            form.type = '';
        }

        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t('Pos.RequiredField') }],
            attribute_id: [{ required: true, message: t('Pos.RequiredField') }],
            value: [{ required: true, message: t('Pos.RequiredField') }],
            type: [{ required: true, message: t('Pos.RequiredField') }]
        };

        const formatDate = (dateString: string) => {
            return dayjs(dateString);
        }

        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
        }

        //drawer

        const open = ref<boolean>(false);
        const openDisplay = ref<boolean>(false);

        const showDisplayDrawer = (record: any) => {
            updateModalWidth();
            openDisplay.value = true;
            getAttributeValueById(record.id);
        };

        

        const showAddDrawer = (state: boolean) => {
            updateModalWidth();
            open.value = state;
            reactiveData.action = 'add';
            resetForm();
        };

        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showDrawer = (record: any) => {
            updateModalWidth();
            open.value = true;
            getAttributeValueById(record.id);
            reactiveData.action = 'update';
        };

        

        const showSuccessNotification = (message: string) => {
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style: {
                    backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        const getAttributeById = (id: string) => {
            loading.value=true;
            attributeService.show(id)
                .then((res) => {
                    reactiveData.attribute = res.data;
                    loading.value=false;
                })
                .catch((error: any) => {
                    console.error('Error fetching Attribute:', error);
                });
        }

        const getAttributeValue = () => {
            loading.value=true;
            attributeValuesService.getAll(routeId.value)
                .then((res) => {
                    reactiveData.data = res.data;
                    loading.value=false;
                })
                .catch((error: any) => {
                    console.error('Error fetching Attribute Values:', error);
                });
        }

        const getAttributeValueById = (id: string) => {
            loading.value=true;
            attributeValuesService.show(id, routeId.value)
                .then(res => {
                    form.name = res.data.name;
                    form.attribute_id = res.data.attribute_id;
                    form.value = res.data.value;
                    form.type = res.data.type;
                    form.id = res.data.id;
                    loading.value=false;
                })
                .catch(error => {
                    console.error('Error fetching categories:', error);
                });
        }

        const onSubmitAdd = () => {
            formRef.value
                .validate()
                .then(() => {
                    addAttributeValue(reactiveData.attribute);
                })
                .catch((error: any) => {
                    console.log('error', error);
                });
        };

        const hideForm = (bool: boolean) => {
            open.value = false;
            formRef.value.resetFields();
        };

        const onSubmitUpdate = (id: string) => {
            formRef.value
                .validate()
                .then(() => {
                    updateAttributeValue(id);
                })
                .catch((error: any) => {
                    console.log('error', error);
                    });
        };

        const addAttributeValue = (attribute: any) => {
            let payload = {
                name: form.name,
                value: form.value,
                type: form.type,
                attribute_id:attribute.id

            }
            loading.value=true;
            attributeValuesService.create(attribute.id,payload)
                .then((res) => {
                    reactiveData.data.push(res.data);
                    loading.value=false;
                    showSuccessNotification(t('Pos.AttributeValueAddSuccess'))
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    open.value = false;
                })
        }

        const updateAttributeValue = (id: string) => {
            let payload = {
                name: form.name,
                attribute_id: form.attribute_id,
                value: form.value,
                type: form.type
            }
            loading.value=true;
            attributeValuesService.update(id, payload, routeId.value).then((res) => {
                reactiveData.data = reactiveData.data.map(el => el.id !== id ? el : res.data);
                loading.value=false;
                showSuccessNotification(t('Pos.AttributeValueUpdateSuccess'));

            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                open.value = false;
            })
        }

        const deleteAttributeValue = (id: string) => {
            loading.value=true;
            attributeValuesService.delete(id,routeId.value).then((res) => {
                reactiveData.data = reactiveData.data.filter(e => e.id !== id);
                loading.value=false;
                showSuccessNotification(t('Pos.AttributeValuedeleteSuccess'));
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                console.log('finish')
            })
        };

        const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };


        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteAttributeValue'),
                text: t('Pos.TextDeleteAttributeValue'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t('Pos.Delete'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteAttributeValue(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        onMounted(() => {
            routeId.value = route.params.id as string;
            getAttributeById(routeId.value);
            getAttributeValue();
        });

        return {
            updateModalWidth,
            modalWidth,
            t,
            rules,
            columns,
            formRef,
            form,
            formatDisplayDate,
            reactiveData,
            showAddDrawer,
            showDrawer,
            open,
            formatDate,
            onSubmitUpdate,
            onSubmitAdd,
            showDeletePopup,
            visible,
            types,
            navigatePage,
            loading,
            showDisplayDrawer,
            openDisplay,
            hideForm,
            afterOpenChange

        }
    }

})
</script>

<style scoped>
.add-btn {
    color: #fc8019;
    border: 2px solid #fc8019;
    box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}

.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.date-picker {
    width: 100%
}



.breadcrumb-icon{
  cursor: pointer;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-btn{
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
}

.table-container {
    overflow-x: auto;
}


</style>