<template>

    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
            <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
                {{ t('Pos.Dashboard') }}
                
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ t('Pos.Promotion') }}</a-breadcrumb-item>
            <a-breadcrumb-item>{{ t('Pos.Coupon') }}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div>
        <div class="btn-container">
            <a-button class="add-btn"  @click="showAddDrawer">
               {{ t('Pos.Add') }}
            </a-button>
        </div>
    </div>

    <div>
        <a-table
            :columns="columns"
            :data-source="state.data"
            :pagination="false"
            class="table-container"
        >

            <template #emptyText>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
            </template>

            <template #bodyCell="{ column, record }">
                <template v-if="column.key=='actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>{{ t('Pos.View') }}</template>
                            <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                            <template #icon>
                                <EyeOutlined/>
                            </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip>
                            <template #title>{{ t('Pos.Update') }}</template>
                            <a-button type="primary"  @click="() => showDrawer(record)">
                            <template #icon>
                                <EditOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                        
                        <a-tooltip>
                            <template #title>{{ t('Pos.Delete') }}</template>
                            <a-button type="primary" danger @click="showDeletePopup(record)">
                            <template #icon>
                                <DeleteOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>
                <template v-if="column.key=='active'">
                    <template v-if="record.active===1 || record.active===true">
                        <check-circle-two-tone two-tone-color="#52c41a" />
                    </template>

                    <template  v-if="record.active===0 || record.active===false">
                        <close-circle-two-tone two-tone-color="#ff4d4f"/>
                    </template>
                </template>

                <template v-if="column.key === 'start_date' || column.key === 'end_date'">
                    {{ formatDisplayDate(record[column.key]) }}
                </template>

                
            </template>

            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                <a-input
                    ref="searchInput"
                    :placeholder="`${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                /> 

                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                
                    {{ t('Pos.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Pos.Reset') }}
                </a-button>
                </div>
            </template>

            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>
        </a-table>

        <div class="pagination">
            <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange"/>

            <a-select
                class="select-input"
                v-model:value="selectPagination"
                @change="handleSelectPaginationChange"
                v-if="state.totalPages>10"
            >
                <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
            </a-select>
        </div>


        <!-- <div class="pagination" v-if="state.totalPagesSearch ===0">
            <a-pagination v-model:current="currentPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />
        </div>
        <div class="pagination" v-if="state.totalPagesSearch !==0">
            
            <a-pagination v-model:current="currentPageSearch" :total="state.totalPagesSearch" @change="handlePageChangeSearch"  :default-page-size="perPageSearch" :showSizeChanger=false />
        </div> -->

        <!-- add drawer -->
        <a-drawer
            v-model:open="openAdd"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width=modalWidth
            @close="resetFormAdd"
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddCoupon')}}</p>
            </template>

            <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
               
                <a-row :gutter="16">   
                    <a-col :span="12">
                        <a-form-item  name="code">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Code') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.code" :placeholder="t('Pos.Code') " class="input-form"/>
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="value">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Value') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.value" :placeholder="t('Pos.Value')" class="input-form"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item  name="type">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Type') }}</span>
                            </template>
                            <a-select v-model:value="addForm.type" show-search :option-filter-prop="'label'">
                                <template #notFoundContent>
                                    <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                </template>
                                <a-select-option value="fixed" :label="t('Pos.Fixed')">{{ t('Pos.Fixed') }}</a-select-option>
                                <a-select-option value="percentage" :label="t('Pos.Percentage')">{{ t('Pos.Percentage') }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="minimum_amount">
                            <template #label>
                                <span class="label-form">{{ t('Pos.MinimumAmount') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.minimum_amount" :placeholder="t('Pos.MinimumAmount')" class="input-form"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item  name="usage_limit">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Usagelimit') }}</span>
                            </template>

                            <a-input  v-model:value="addForm.usage_limit" :placeholder="t('Pos.Usagelimit')" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="limit_per_user">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Limitperuser') }}</span>
                            </template>

                            <a-input  v-model:value="addForm.limit_per_user" :placeholder="t('Pos.Limitperuser')" />
                        </a-form-item>
                    </a-col>

                </a-row>

                <a-row>
                    <a-col :span="24">
                        <a-form-item  name="date">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Avalaibilty') }}</span>
                            </template>
                            <a-range-picker show-time class="range-picker"  v-model:value="addForm.date" >
                            </a-range-picker>
                        </a-form-item>
                        
                    </a-col>
                    
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12">
                        <div class="check-btn"> 
                            <span class="label-form">{{ t('Pos.Active') }}</span>
                            <a-switch v-model:checked="addForm.active" class="swicth-btn"/>
                        </div>
                    </a-col>

                    <a-col :span="12">
                        <div>
                           <span class="label-form">{{ t('Pos.Requiresapproval') }}</span>
                            <a-switch v-model:checked="addForm.requires_approval" class="swicth-btn" />
                        </div>
                    </a-col>

                </a-row>

            </a-form>

            <template #footer>
            <a-space>
                <a-button  type="primary" @click="add">{{ t('Pos.Add') }}</a-button>
                <a-button type="primary" danger  @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
            </a-space>
            </template>


        </a-drawer>
        <!-- add drawer -->

        <!-- display drawer -->
            <a-drawer
                v-model:open="openDisplay"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                @after-open-change="afterOpenChange"
            >
            
                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.CouponInfo')}}</p>
                </template>

                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                   <a-row :gutter="16">   
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.Code') }} :</span>{{form.code}}</p>
                        </a-col>

                        <a-col :span="12">
                            <p>
                                <span class="info-title">{{ t('Pos.Value') }} :</span>
                                {{form.value}}
                                <span v-if="form.type==='fixed'">{{state.currentCurrency}}</span>
                                <span v-if="form.type==='percentage'">%</span>
                            </p>
                        </a-col>
                    </a-row>    

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <p>
                                <span class="info-title">{{ t('Pos.Type') }} :</span>{{form.type}}
                               
                            </p>
                        </a-col>

                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.MinimumAmount') }} :</span>{{form.minimum_amount}}</p>
                        </a-col>
                    </a-row>

                   <a-row :gutter="16">
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.Usagelimit') }} :</span>{{form.usage_limit}}</p>
                        </a-col>

                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.Limitperuser') }} :</span>{{form.limit_per_user}}</p>
                        </a-col>

                    </a-row>

                    <a-row>
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.From') }} :</span>{{formatDisplayDate(form.start_date)}}</p>
                            
                        </a-col>
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.To') }} :</span>{{formatDisplayDate(form.end_date)}}</p>

                        </a-col>
                    
                    </a-row>
                
                   <a-row :gutter="16">
                        <a-col :span="12">
                            <p>
                                <span class="info-title">{{ t('Pos.Active') }} :</span>

                                <template v-if="form.active===1 || form.active===true">
                                    <check-circle-two-tone two-tone-color="#52c41a" />
                                </template>

                                <template  v-if="form.active===0 || form.active===false">
                                    <close-circle-two-tone two-tone-color="#ff4d4f"/>
                                </template>

                            </p>

                        </a-col>

                        <a-col :span="12">
                            <p>
                                <span class="info-title">{{ t('Pos.Requiresapproval') }} :</span>

                                <template v-if="form.requires_approval===1 || form.requires_approval===true">
                                    <check-circle-two-tone two-tone-color="#52c41a" />
                                </template>

                                <template  v-if="form.requires_approval===0 || form.requires_approval===false">
                                    <close-circle-two-tone two-tone-color="#ff4d4f"/>
                                </template>

                            </p>
                            
                        </a-col>

                    </a-row>    

                </a-form>
    
            </a-drawer>
        <!-- display drawer -->

        <!-- update drawer -->
            <a-drawer
                v-model:open="open"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                @after-open-change="afterOpenChange"
            >

                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateCoupon')}}</p>
                </template>

                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}" >

                   <a-row :gutter="16">   
                        <a-col :span="12">
                            <a-form-item  name="code">
                                <template #label>
                                <span class="label-form">{{ t('Pos.Code') }}</span>
                                </template>
                                <a-input  v-model:value="form.code" :placeholder="t('Pos.Code')" class="input-form"/>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item  name="value">
                                <template #label>
                                <span class="label-form">{{ t('Pos.Value') }}</span>
                                </template>
                                <a-input  v-model:value="form.value" :placeholder="t('Pos.Value')" class="input-form"/>
                            </a-form-item>
                        </a-col>
                    </a-row>    

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item  name="type">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Type') }}</span>
                                </template>
                                <a-select v-model:value="form.type" show-search :option-filter-prop="'label'">
                                    <template #notFoundContent>
                                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                    </template>
                                    <a-select-option value="fixed"  :label="t('Pos.Fixed')">{{ t('Pos.Fixed') }}</a-select-option>
                                    <a-select-option value="percentage" :label="t('Pos.Percentage')">{{ t('Pos.Percentage') }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item  name="minimum_amount">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.MinimumAmount') }}</span>
                                </template>
                                <a-input  v-model:value="form.minimum_amount" :placeholder="t('Pos.MinimumAmount')" class="input-form"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                   <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item  name="usage_limit">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Usagelimit') }}</span>
                                </template>

                                <a-input  v-model:value="form.usage_limit" :placeholder="t('Pos.Usagelimit')" />
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item  name="usage_per_user">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Limitperuser') }}</span>
                                </template>

                                <a-input  v-model:value="form.limit_per_user" :placeholder="t('Pos.Limitperuser')" />
                            </a-form-item>
                        </a-col>

                    </a-row>

                    <a-row>
                        <a-col :span="24">
                            <a-form-item  name="start_date">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Avalaibilty') }}</span>
                                </template>
                                 <a-range-picker 
                                    show-time 
                                    class="range-picker"  
                                    :value="[form.start_date, form.end_date]"
                                    @change="handleDateChange"
                                >
                                </a-range-picker>
                            </a-form-item>
                            
                        </a-col>
                    
                    </a-row>
                
                   <a-row :gutter="16">
                        <a-col :span="12">
                            <div class="check-btn"> 
                                <span class="label-form">{{ t('Pos.Active') }}</span>
                                <a-switch v-model:checked="form.active" class="swicth-btn"/>
                            </div>
                        </a-col>

                        <a-col :span="12">
                            <div>
                            <span class="label-form">{{ t('Pos.Requiresapproval') }}</span>
                                <a-switch v-model:checked="form.requires_approval" class="swicth-btn" />
                            </div>
                        </a-col>

                    </a-row>    
                </a-form>
                <template #footer>
                    <a-space>
                        <a-button  type="primary" @click="updateCoupon(form.id)">{{ t('Pos.Update') }}</a-button>
                        <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                </template>

            </a-drawer>
        <!-- update drawer -->

        


    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, watch, computed } from 'vue'
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
    EyeOutlined,
    DeleteOutlined,
    SearchOutlined,
    PercentageOutlined,
    EditOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,

} from '@ant-design/icons-vue';
import {notification } from 'ant-design-vue';
import {couponService} from '@/_services'
import dayjs , { Dayjs }from 'dayjs'; 
import { loadRouteLocation, useRouter } from 'vue-router';
import LbrxLoaderSpinner from '../../../components/LbrxLoaderSpinner.vue';
import { useI18n } from 'vue-i18n';
import { Empty } from 'ant-design-vue';

export default defineComponent({
    name: 'indexDiscount',
    components: {
        EyeOutlined,
        DeleteOutlined,
        EditOutlined,
        LbrxLoaderSpinner,
        CheckCircleTwoTone,
        CloseCircleTwoTone,
        SearchOutlined
    },
    setup() {
        const modalWidth = ref(720);
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const { t } = useI18n();
        const loading = ref(false);
        const router = useRouter();
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const perPageSearch = ref<number>(10);
        const currentPageSearch = ref<number>(1);
        const selectPagination = ref('10');
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            categories:[] as any[],
            products:[] as any[],
            totalPages:0,
            totalPagesSearch:0,
            searchName:'',
            beginSearch:false,
            currentCurrency:'',
        });
        const searchInput = ref<HTMLInputElement | null>(null);

        const columns=[
            {
                title:t('Pos.Code'),
                key:'code',
                dataIndex: 'code',
                customFilterDropdown: true,
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                        if (input instanceof HTMLInputElement) {
                            searchInput.value = input;
                            searchInput.value.focus();
                        }
                        }, 100);
                    }
                },

               
            },
            {
                title:t('Pos.Active'),
                key:'active',
                dataIndex: 'active',
            },
            {
                title: t('Pos.DiscountValue'),
                key:'value',
                dataIndex: 'value',

            },
            {
                title: t('Pos.MinimumAmount'),
                key:'minimum_amount',
                dataIndex: 'minimum_amount',
            },
            {
                title: t('Pos.startDate'),
                key:'start_date',
                dataIndex: 'start_date',
            },
            {
                title: t('Pos.endDate'),
                key:'end_date',
                dataIndex: 'end_date',
            },
            {
                title:t('Pos.Actions'),
                key:'actions'
            }

        ];

        //form  
        const formRef = ref();
        const addFormRef = ref();

        const form = reactive({
            id: '',
            code: '',
            type: '',
            minimum_amount:'',
            date:[],
            start_date: null as Dayjs | null,
            end_date: null as Dayjs | null,
            active:true,
            usage_limit:'',
            limit_per_user: '',
            value:'',
            requires_approval:false,
        });

        const addForm = reactive({
            id: '',
            code: '',
            type: '',
            value:'',
            minimum_amount:'',
            date:[],
            start_date:'',
            end_date:'',
            active:true,
            usage_limit:'',
            limit_per_user: '',
            requires_approval:false,
        });

        const resetForm = () => {
            form.id = '';
            form.code = '';
            form.type = '';
            form.minimum_amount='',
            form.start_date=null;
            form.end_date = null;
            form.active = true;
            form.usage_limit='';
            form.limit_per_user='';
            form.requires_approval=false;
        }

         const rules: Record<string, Rule[]> = {
            code: [
                { required: true, message:t('Pos.RequiredField') },
                
            ],
            usage_limit :[
                { 
                    validator: (_, value) => {
                        if (!isNaN(value) && parseFloat(value) >= 0) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],
            minimum_amount:[
                { 
                    validator: (_, value) => {
                        if (!isNaN(value) && parseFloat(value) >= 0) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],
           
            limit_per_user :[
                { 
                    validator: (_, value) => {
                        if (!isNaN(value) && parseFloat(value) >= 0) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],
            type:[{required: true, message: t('Pos.RequiredField') }],
            value:[
                {required: true, message: t('Pos.RequiredField') },
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],
            date: [{ required: true, message: t('Pos.RequiredField') }],
           
        };

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

        //drawer
        const open = ref<boolean>(false);
        const openDisplay = ref<boolean>(false);
        const openAdd = ref<boolean>(false);

        const showAddDrawer = (state: boolean) => {
            updateModalWidth();
            openAdd.value = true;
        };

        const showDrawer = (record: any) => {
            updateModalWidth();
            open.value = true;
            
            getCouponById(record.id);
        };

        const showDisplayDrawer =(record:any)=>{
            updateModalWidth();
            currentCurrency();
            getCouponById(record.id);
            openDisplay.value = true;
        }


        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

    
        const afterOpenAddChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const resetFormAdd = () => {
            addFormRef.value.resetFields();
        };

        const cancelUpdate=()=>{
            open.value=false;
        }

        

        const convertToString = (date:any) => {
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);
            const seconds = ('0' + date.getSeconds()).slice(-2);

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const getCouponById = (id: string) => {
            couponService.show(id)
            .then(res => {
                console.log('discount by id', res.data);
                form.code = res.data.code;
                form.value=res.data.value;
                form.type = res.data.type;
                form.minimum_amount=res.data.minimum_amount;
                form.active=res.data.active;
                form.usage_limit=res.data.usage_limit;
                form.limit_per_user = res.data.limit_per_user;
                form.requires_approval = res.data.requires_approval;
                form.start_date = dayjs(res.data.start_date);
                form.end_date = dayjs(res.data.end_date);
                form.id = res.data.id;
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
        }

        const getCoupons=()=>{
            loading.value = true;
            couponService.getAllCoupons(currentPage.value,perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages=response.meta.total;
            }).catch((error)=>{
                console.log("error get coupons", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
        }

        const add =()=>{
            addFormRef.value
            .validate()
            .then(()=>{
                const startDate = new Date(addForm.date[0]);
                const endDate = new Date(addForm.date[1]);
                let payload={
                    code:addForm.code,
                    value:addForm.value,
                    type:addForm.type,
                    minimum_amount:addForm.minimum_amount,
                    start_date:convertToString(startDate),
                    end_date:convertToString(endDate),
                    active:addForm.active,
                    usage_limit:addForm.usage_limit,
                    limit_per_user:addForm.limit_per_user,
                    requires_approval:addForm.requires_approval,
                }
                console.log("payload", payload);
                couponService.store(payload).then((rep)=>{
                    console.log('Discount added successfully:', rep);
                    openAdd.value=false;
                    state.data.push(rep.data);
                    resetFormAdd();
                    showSuccessNotification(t('Pos.AddCouponSuccess'));
                }).catch((error) => {
                    console.error('Error adding discount:', error); 
                });
            }).catch((error:any) => {
                console.error('Validation error:', error);
            });
        }

        const handleDateChange = (dates: Dayjs[]) => {
            form.start_date = dates[0];
            form.end_date = dates[1];
        };

        const deleteCoupon = (id: string) => {
            couponService.delete(id).then((res) => {
                state.data = state.data.filter(e => e.id !== id);
                showSuccessNotification (t('Pos.DeleteCouponSuccess'));
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                
                console.log('finish')
            })
        };

        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteCoupon'),
                text: t('Pos.TextDeleteCoupon'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t('Pos.Delete'),
                cancelButtonColor: "#d33",
                cancelButtonText: t('Pos.Cancel'),
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteCoupon(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const formatToStringDate = (date:any) => {
            if (!date || !date.$d) {
                return ''; 
            }

            const year = date.$y;
            const month = date.$M + 1;
            const day = date.$D < 10 ? '0' + date.$D : date.$D;
            const hours = date.$H < 10 ? '0' + date.$H : date.$H;
            const minutes = date.$m < 10 ? '0' + date.$m : date.$m;
            const seconds = date.$s < 10 ? '0' + date.$s : date.$s;

            const stringDate= `${year}-${month < 10 ? '0' : ''}${month}-${day} ${hours}:${minutes}:${seconds}`;
            return stringDate;
        };

        const updateCoupon =(id:string)=>{
            formRef.value
            .validate()
            .then(()=>{
                let payload = {
                    code:form.code,
                    value:form.value,
                    type:form.type,
                    minimum_amount:form.minimum_amount,
                    start_date:formatToStringDate(form.start_date),
                    end_date:formatToStringDate(form.end_date),
                    active:form.active,
                    usage_limit:form.usage_limit,
                    limit_per_user:form.limit_per_user,
                    requires_approval:form.requires_approval,
                }
                console.log('payload', payload);
                couponService.update(id, payload).then((response)=>{
                    open.value=false;
                    state.data = state.data.map(el => el.id !== id ? el : response.data);
                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    showSuccessNotification(t('Pos.UpdateCouponSuccess'));
                    console.log('finish')
                }); 
            })
        }

        const handleSelectPaginationChange=(selectedItem:any)=>{
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){


                if(state.searchName!=''){
                    loading.value = true;
                    couponService.filterWithPagination("code",state.searchName,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPagesSearch = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value = true;
                couponService.getAllCoupons(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

            
        }

        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                

                if(state.searchName!==''){

                    loading.value = true;
                    couponService.filterWithPagination("code",state.searchName,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{
                loading.value = true;
                couponService.getAllCoupons(currentPage.value,perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api : get coupons", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }





           
        }

        const handlePageChangeSearch = (newPage:any) => {
                currentPageSearch.value = newPage;
                loading.value = true;

                console.log('per page', perPageSearch.value);
                console.log('page', currentPageSearch.value);

                state.data=[];
                

                if(state.searchText!==''){
                    couponService.filterWithPagination("code",state.searchText,currentPageSearch.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPagesSearch = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }
            


        }

        //search

        const filter=(key:string, value:string)=>{
            currentPage.value=1;
            loading.value=true;
            couponService.filterWithPagination(key,value, currentPage.value.toString(),perPage.value).then((res)=>{
                state.data=res.data;
                state.totalPages=res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((error)=>{
                console.log('error fetching', error);
            });
        }

        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'code' && state.searchText!=='') {
                console.log('begin filter name', state.searchText);
                state.searchName=state.searchText;
                filter("code",state.searchText );
            }

        };

        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.totalPagesSearch=0;
            state.beginSearch=false;

            state.searchName='';

            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';

            getCoupons();
        };

        const currentCurrency=()=>{
            const currencyJson=localStorage.getItem('currencies');
            if(currencyJson===null){
                state.currentCurrency= 'TND';
            }else{
                const currency = JSON.parse(currencyJson);
                state.currentCurrency= currency.code;
            }

        }




        onMounted(() => {
            getCoupons();
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');

           
        });

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );


        const updateModalWidth = () => {
                modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
            };


        return{
            updateModalWidth,
            modalWidth,
            t,
            textDirection,
            locale,
            state,
            columns,
            formRef,
            addFormRef,
            form,
            addForm,
            rules,
            showSuccessNotification,
            open,
            openAdd,
            openDisplay,
            showAddDrawer,
            showDrawer,
            showDisplayDrawer,
            afterOpenChange,
            afterOpenAddChange,
            resetFormAdd,
            navigatePage,
            formatDisplayDate,
            add,
            handleDateChange,
            showDeletePopup,
            loading,
            updateCoupon,
            perPage,
            currentPage,
            handlePageChange,
            searchInput,
            perPageSearch,
            currentPageSearch,
            handleSearch,
            handleReset,
            handlePageChangeSearch,
            cancelUpdate,
            selectPagination,
            handleSelectPaginationChange,
            simpleImage


















        }
    },
})
</script>

<style scoped>

    .breadcrumb-icon{
        cursor: pointer;
    }

    .btn-container {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
    }   

    .range-picker{
        width: 100% 
    }

    .label-form {
        font-size: 14px;
        font-weight: 600;
    }

    .check-btn{
        display: flex;
        flex-direction: row;
       
    }

    .swicth-btn{
        margin-left: 20px;
    }

    .pagination{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .add-btn:hover{
        color: #fff;
        box-shadow: inset 0 -100px 0 0 #fc8019;
        border-color: #fc8019;
    }

    .info-title{
        font-size: 15px !important;
        font-weight: bold;
        margin-right: 4px;
    }
    .view-btn{
        color: #fc8019;
        border-color: #fc8019;
        background: #fafafa;
    }

    
    .is-rtl {
        direction:rtl;
    }
    .paginationArrows {
        direction:ltr;
        margin-bottom: 16px;
    }

    .table-container {
    overflow-x: auto;
    }

    @media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
  }
}

</style>
