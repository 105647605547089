

<template>

    <div>
      <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>
    <div>
      <a-breadcrumb style="margin: 16px 0" :class="{ 'is-rtl': textDirection==='rtl'}">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{ t('Pos.Home') }}
        </a-breadcrumb-item>    
        <a-breadcrumb-item>{{t('Pos.Settings')}}</a-breadcrumb-item>
        <a-breadcrumb-item>  {{ t('Pos.Users') }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
  
  
    <div class="btn-container">
        <a-button class="add-btn" @click="showAddDrawer(true)">
        {{ t('Pos.Add') }}
        </a-button>
    </div>
    <div>
        <a-table class="table-container" :columns="columns" :data-source="reactiveData.data" :pagination="false">
  
            <template #bodyCell="{ column, record }">
                <template v-if="column.key == 'actions'">
                <a-space>
                    <a-tooltip>
                        <template #title>   {{ t('Pos.View') }}</template>
                        <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                        <template #icon>
                            <EyeOutlined/>
                        </template>
                        </a-button>
                    </a-tooltip>
  
                    <a-tooltip>
                    <template #title>   {{ t('Pos.Edit') }}</template>
    
                    <a-button type="primary" @click="() => showDrawer(record)">
                        <template #icon>
                        <EditOutlined />
                        </template>
                    </a-button>
    
                    </a-tooltip>
    
                    <a-tooltip>
                    <template #title>   {{ t('Pos.Delete') }}</template>
                    <a-button type="primary" danger @click="showDeletePopup(record)">
                        <template #icon>
                        <DeleteOutlined />
                        </template>
                    </a-button>
                    </a-tooltip>
  
                </a-space>
                </template>
  
                  <template v-if="column.key == 'name'">
                      {{ formatName(record) }}
                  </template>
  
                  <template v-if="column.key === 'created_at' || column.key === 'updated_at'">
                      {{ formatDisplayDate(record[column.key]) }}
                  </template>

                  <template v-if="column.key == 'firstname'">
                      {{ formatNameConcat(record) }}
                  </template>

              </template>

              <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
            <template #icon>
              <SearchOutlined />
            </template>
            {{ t('Pos.Search') }}
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            {{ t('Pos.Reset') }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      
          </a-table>
  
          <div class="pagination">
        <a-pagination v-model:current="currentPage" :total="form.customLastPage" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger="false"/>
            <a-select class="select-input" v-model:value="form.customPagination" placeholder="Order By" @change="choosePerPage">
                <a-select-option value="10">10 / Page</a-select-option>
                <a-select-option value="20">20 / Page</a-select-option>
                <a-select-option value="50">50 / Page</a-select-option>
                <a-select-option value="100">100 / Page</a-select-option>
                <a-select-option value="200">200 / Page</a-select-option>
            </a-select>
    </div>
  
    <a-drawer v-model:open="open"
        class="custom-class"
        root-class-name="root-class-name"
        :root-style="{ color: 'blue' }"
        style="color: red" 
        :title="null"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
        :body-style="{ paddingBottom: '80px' }"
        @click="hideForm" @close="resetFields">


        <template #title>
                <div class="drawer-header-ar" v-if="(locale === 'ar') && (reactiveData.action=='add')">
                    <span>{{ t('Pos.AddUserDrawer') }}</span>
                </div>
                <div class="drawer-header-all" v-if="(locale !== 'ar') && (reactiveData.action=='add')">
                    <span>{{ t('Pos.AddUserDrawer') }}</span>
                </div>

                <div class="drawer-header-ar" v-if="(locale === 'ar') && (reactiveData.action=='update')">
                    <span>{{ t('Pos.UpdateWareUserDrawer') }}</span>
                </div>
                <div class="drawer-header-all" v-if="(locale !== 'ar') && (reactiveData.action=='update')">
                    <span>{{ t('Pos.UpdateWareUserDrawer') }}</span>
                </div>
        </template>

        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-item name="firstname">
                        <template #label>
                            <span class="label-form"> {{ t('Pos.FisrtName') }}</span>
                        </template>
                        <a-input v-model:value="form.firstname" :placeholder="t('Pos.FisrtName')" class="input-form" :class="{ 'is-rtl': textDirection==='rtl'}" />
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item name="lastname">
                        <template #label>
                            <span class="label-form">{{ t('Pos.LastName') }}</span>
                        </template>
                        <a-input v-model:value="form.lastname" :placeholder="t('Pos.LastName')" class="input-form" />
                    </a-form-item>
                </a-col>


                
            </a-row>
            <a-row :gutter="24">
                <a-col :span="12">
                    <a-form-item name="email">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Email') }}</span>
                        </template>
                        <a-input v-model:value="form.email" :placeholder="t('Pos.Email')" class="input-form" />
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item name="username">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Username') }}</span>
                        </template>
                        <a-input v-model:value="form.username" :placeholder="t('Pos.Username') " class="input-form" />
                    </a-form-item>
                </a-col>

        

            </a-row>



        <a-row :gutter="16" v-if="reactiveData.action != 'update'">
                <a-col :span="24">
                    <a-form-item name="active">
                    <template #label>
                        <span class="label-form"  >{{ t('Pos.Roles') }}</span>
                    </template>
                    <a-select v-model:value="form.active" :placeholder="t('Pos.RoleChoose')" class="input-form">
                                <a-select-option value=1>super_admin</a-select-option>
                                <a-select-option value=2>Admin</a-select-option>
                                <a-select-option value=3>Manager</a-select-option>
                                <a-select-option value=4>Staff</a-select-option>
                                <a-select-option value=5>User</a-select-option>
                    </a-select>
                    </a-form-item>
                </a-col>
                </a-row>


                <a-row :gutter="24">
                    <a-col :span="12" v-if="reactiveData.action != 'add'" >
                        <a-form-item name="oldPassword">
                            <template #label>
                                <span class="label-form">{{ t('Pos.OldPassword') }}</span>
                            </template>
                            <a-input-password v-model:value="form.oldPassword" :visible="visible" :placeholder="t('Pos.OldPassword')"
                                class="input-form" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item name="password">
                            <template #label>
                                <span class="label-form"  >{{ t('Pos.Password') }}</span>
                            </template>
                            <a-input-password v-model:value="form.password" :visible="visible" :placeholder="t('Pos.Password')"
                                class="input-form" />
                        </a-form-item>
                    </a-col>

            
                </a-row>
        


                <a-row :gutter="24" v-if="reactiveData.action!='add'">    
                    <a-col :span="24">
                        <a-form-item  name="is_active">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Active') }}</span>
                            </template>
                            <a-switch v-model:checked="form.is_active" />
                        </a-form-item>
                    </a-col>
                </a-row>
        


        </a-form>

        <template #footer>
            <div :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-space v-if="reactiveData.action == 'update'">
                    <a-button type="primary" @click="onSubmitUpdate(form.id)">{{ t('Pos.Update') }}</a-button>
                    <a-button type="primary" danger @click="hideForm">{{ t('Pos.Cancel') }}</a-button>
                </a-space>
                <a-space v-else>
                    <a-button type="primary" @click="onSubmitAdd">{{ t('Pos.Add') }}</a-button>
                    <a-button type="primary" danger @click="hideForm">{{ t('Pos.Cancel') }}</a-button>
                </a-space>
            </div>
        </template>

    </a-drawer>
  
  

    <!-- Details-->


    <a-drawer
    v-model:open="openDisplay"
    class="custom-class"
    root-class-name="root-class-name"
    :title="null"
    :placement="locale === 'ar' ? 'left' : 'right'"
    :width="modalWidth"
    @after-open-change="afterOpenShowChange"
    >

    <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
            <span>{{ t('Pos.DetailsUserDrawer') }}</span>
        </div>  
        <div class="drawer-header-all" v-if="locale !== 'ar'">
            <span>{{ t('Pos.DetailsUserDrawer') }}</span>
        </div>      
      </template>

        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
        <a-row :gutter="16">

            <a-col :span="12">
                <p><span class="info-title"  placeholder="Currency">{{ t('Pos.FisrtName') }} :</span> {{form.firstname}} </p>
            </a-col>


            <a-col :span="12">
                <p><span class="info-title"  placeholder="Currency">{{ t('Pos.LastName') }} :</span> {{form.lastname}} </p>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="12">
                <p><span class="info-title"  placeholder="Email">{{ t('Pos.Username') }} :</span> {{form.username}} </p>
            </a-col>

            <a-col :span="12">
                <p><span class="info-title"  placeholder="Discount">{{ t('Pos.Email') }} :</span> {{form.email}} </p>
            </a-col>
        </a-row>
        

</a-form>


            <template #footer>
            </template>

    </a-drawer>
  
  
      </div>
  </template>
  <script lang="ts">
  import { defineComponent, ref, reactive, onMounted, toRaw ,computed} from 'vue';
  import type { Rule } from 'ant-design-vue/es/form';
  import Swal from 'sweetalert2';
  import {
      EyeOutlined,
      DeleteOutlined,
      EditOutlined,
      SearchOutlined

  } from '@ant-design/icons-vue';
  import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
  
  import { notification } from 'ant-design-vue';
  import { userService , roleService } from '@/_services'
  import dayjs from 'dayjs';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';
import roles from './roles.vue';
  
  
  export default defineComponent({
      name: 'indexUsers',
      components: {
          EyeOutlined,
          DeleteOutlined,
          EditOutlined,
          LbrxLoaderSpinner,
          SearchOutlined
      },
      setup() {
         const modalWidth = ref(720);
          const { locale }=useI18n();
          const loading = ref(false);
  
          const router = useRouter();
          const navigatePage = (link: string) => {
          router.push(link); 
          };
  
          const { t } = useI18n();
              const searchInput = ref<HTMLInputElement | null>(null);

          const columns = [
              {
                title: t('Pos.FullName'),
                key: 'firstname',
                dataIndex: 'firstname',
                customFilterDropdown: true,
                onFilter: (value: string, record1: any) => record1.first_name.toString()+record1.last_name.toString(),        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector('.ant-table-filter-dropdown input');

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
              },
              {
                  title: t('Pos.Username'),
                  key: 'username',
                  dataIndex: 'username',
              },
              {
                  title: t('Pos.Email'),
                  key: 'email',
                  dataIndex: 'email',
              },
              {
                  title: t('Pos.Actions'),
                  key: 'actions',
                  dataIndex: 'actions',
              }
  
          ];
  
          //form  
          const formRef = ref();
          const visible = ref<boolean>(false);
  
          const reactiveData = reactive({
              data: [] as any[],
              action: '',
              totalPages:0,
              searchText:'',
              searchedColumn:''
           
          });


          const reactiveData2 = reactive({
              data: [] as any[],
              action: '',
              totalPages:0,
              searchText:'',
              searchedColumn:'',

          });


  
          const form = reactive({
            paymentMethod:'',
              active:'',
              id: '',
              badge_id: '',
              firstname: '',
              lastname:'',
              username: '',
              email: '',
              password: '',
              is_active:false,
              oldPassword:'',
              role:'',
              customPagination : 10 ,
              customLastPage : 0
          });
  
          const resetForm = () => {
              form.active ='',
              form.id = '';
              form.badge_id = '';
              form.firstname = '';
              form.lastname = '';
              form.username = '';
              form.email = '';
              form.password = '';
              form.oldPassword='',
              form.role=''

          }
          
          const formatName = (dateString: any) => {
      if (dateString && dateString.first_name && dateString.last_name ) {
          return dateString.first_name + " " + dateString.last_name ;
      }
      return 'N/A'; 
  };


 
  
          const perPage = ref<number>(10);
          const currentPage = ref<number>(1);
          const rules: Record<string, Rule[]> = {
             firstname: [{ required: true, message: t('Pos.firstNameVerification') }],
             lastname: [{ required: true, message: t('Pos.lastnameVerification') }],
             username: [{ required: true, message:t('Pos.usernameVerification') }],
             email: [
                  {
                      validator: (_, value) => {
                          if (validEmail(value)) {
                              return Promise.resolve();
                          }
                          return Promise.reject(t('Pos.emailVerification'));
                      }
                  }],

            oldPassword: [
                  { required: true, message: t('Pos.OldpasswordVerification') }
              ],

              
              active: [{ required: true, message: t('Pos.roleSelect') }]
          };
  
          const formatDate = (dateString: string) => {
              return dayjs(dateString);
          }
  
          const formatDisplayDate = (dateString: string) => {
              return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
          }
  
          //drawer
          const open = ref<boolean>(false);
          const showAddDrawer = (state: boolean) => {
              updateModalWidth();
              open.value = state;
              reactiveData.action = 'add';
              resetForm();
          };
  
          const validEmail = (email: string) => {
              let re =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return re.test(email);
          };
  
      
  
          const showDrawer = (record: any) => {
            updateModalWidth();
              form.password = '',
              form.oldPassword='',
              open.value = true;
              getUserById(record.id);
              reactiveData.action = 'update';
          };
  
       
  
          const showSuccessNotification = (message: string) => {
              notification.success({
                  message: message,
                  duration: 3,
                  top: '80px',
                  style: {
                      backgroundColor: '#bfffca8a',
                      color: 'white'
                  }
              });
          };

          const showErrorNotification  = (message:string)=>{
            notification.error({ 
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#FFBFC98A',
                    color: 'white'
                }
            });
        };

  
          const onSubmitAdd = () => {
              formRef.value
                  .validate()
                  .then(() => {
                      addUser();
                  })
                  .catch((error: any) => {
                     // console.log('error', error);
                  });
          };
  
          

          const formatcustomer = (dateString: any) => {
        
        if (dateString && dateString.customer) {
            if (dateString.customer.first_name) {
                return dateString.customer.first_name+" "+dateString.customer.last_name;
            }
        }
        return t('Pos.Anonyme'); 
    };

    
          const showDisplayDrawer = (record: any) => {
            updateModalWidth();
              openDisplay.value = true;
              getUserById(record.id);
              resetForm();
              reactiveData.action = 'view';
          };
  
  
          const hideForm = (bool: boolean) => {
              open.value = false;
              openDisplay.value= false;
              formRef.value.resetFields();
          };

          const resetFields=()=> {
            formRef.value.resetFields();
          }
  
          const onSubmitUpdate = (id: string) => {
              formRef.value
                  .validate()
                  .then(() => {
                      updateUser(id);
                  })
                  .catch((error: any) => {
                      //console.log('error', error);
                  });
          };
          


          const formatNameConcat = (dateString: any) => {
                if (dateString && dateString.first_name && dateString.last_name ) {
                    return dateString.first_name+' '+dateString.last_name;}
                else
                    {return '';}
            };

            const choosePerPage=()=> {
                currentPage.value=1;
                console.log(form.customPagination);
            // currentPage.value = newPage;
                loading.value = true;
            //console.log('per page', perPage.value);
            //console.log('page', currentPage.value)
            userService.getAll(currentPage.value,form.customPagination).then((response)=>{
                //console.log('data', response.data)
                reactiveData.data=response.data;
                form.customLastPage = response.meta.last_page*10;

                console.log('lastPage',form.customLastPage)
            }).catch((error)=>{
                //console.log("error api : get products", error);
            })
            .finally(()=>{
                loading.value = false;
            });
            }


          const getUser = () => {
              loading.value = true ; 
              userService.getAll(currentPage.value , perPage.value)
  
                  .then((res) => {
                      reactiveData.data = res.data;
                      reactiveData.totalPages =res.meta.total;
                      form.customLastPage = res.meta.last_page*10;

  
                  }).catch((error)=>{
         // console.log("error api : get users", error);
        })
        .finally(()=>{
          loading.value = false;
        });
      }
  

      const getroles = () => {
              loading.value = true ; 
              roleService.getAll()
  
                  .then((res) => {
                      reactiveData2.data = res.data;

                  }).catch((error)=>{
         // console.log("error api : get roles", error);
        })
        .finally(()=>{
          loading.value = false;
        });
      }


  
          const getUserById = (id: string) => {
            loading.value = true
              userService.show(id)
                  .then(res => {
                      form.firstname = res.data.first_name;
                      form.lastname = res.data.last_name;
                      form.username = res.data.username;
                      form.email = res.data.email;
                      form.id = res.data.id;
                      if (res.data.is_active == 1) {
                        form.is_active = true;
                      }
                      else
                      {
                        form.is_active = false;
                      }
                      //form.password = res.data.first_name;
                      //form.oldPassword = res.data.first_name;
                      
                      loading.value = false
                    //console.log(form.is_active);
                  })
                  .catch(error => {
                     // console.error('Error fetching categories:', error);
                  });
          }
  
       
          

          const addUser = () => {
             let payload = {
                  first_name: form.firstname,
                  last_name: form.lastname,
                  email: form.email,
                  password: form.password,
                  username: form.username,
                  role_id:form.active,
                  is_active:form.is_active,
                }
        loading.value=true;
        userService.create(payload).then((res) => {
          reactiveData.data.push(res.data);
          showSuccessNotification(t('Pos.AddUser'))
        }).catch((err) => {
         // console.log(err)
        }).finally(() => {
          // resetAdd();
          showAddDrawer(false)
          loading.value=false;
        })
      };

          const updateUser = (id: string) => {
              loading.value = true;
              let payload = {
                  first_name: form.firstname,
                  last_name: form.lastname,
                  email: form.email,
                  password: form.password,
                  username: form.username,
                  is_active:form.is_active,
                  old_password:form.oldPassword
              }
              userService.update(id, payload).then((res) => {
                  reactiveData.data = reactiveData.data.map(el => el.id !== id ? el : res.data);
                  showSuccessNotification(t('Pos.UpdateUser'));
                  open.value = false;
                  console.log("is_active",form.is_active)
                }).catch((err) => {
                    showErrorNotification(t('Pos.oldPasswordVerification'));
                    loading.value = false;
                //  console.log(err)
              }).finally(() => {
                //  console.log(err)
                loading.value = false;
              })
          }
  
          const deleteUser = (id: string) => {
              loading.value = true;
              userService.delete(id).then((res) => {
                  reactiveData.data = reactiveData.data.filter(e => e.id !== id);
                  showSuccessNotification(t('Pos.DeleteUser'));
              }).catch((err) => {
                 // console.log(err)
              }).finally(() => {
                 // console.log('finish')
                  loading.value = false;
  
              })
          };
  
          const openDisplay = ref<boolean>(false);
              const afterOpenShowChange = (bool: boolean) => {
              //console.log('open', bool);
          };
          
         /* const getColumnSpan =()=> {
            return reactiveData.action !== 'add' ? 24 : 12;

          }*/
  
          const handlePageChange = (newPage:any) => {
        currentPage.value = newPage;
        loading.value = true;
  
       // console.log('per page', perPage.value);
       // console.log('page', currentPage.value)
  
       userService.searchWithFullParams('first_name',reactiveData.searchText,currentPage.value,form.customPagination).then((response)=>{
         
         // console.log('data', response.data)
          reactiveData.data=response.data;
          form.customLastPage = response.meta.last_page*10;
        }).catch((error)=>{
         // console.log("error api : get products", error);
        })
        .finally(()=>{
          loading.value = false;
        });
      }
      

      const handleSearch = (selectedKeys: any[], confirm: () => void, dataIndex: string) => {
        currentPage.value=1;
        confirm();        
        console.log("hné",selectedKeys[0])
        reactiveData.searchText = selectedKeys[0]
        searchWithFullParams(selectedKeys[0]); 
      reactiveData.searchedColumn = dataIndex;
    };


    const searchWithFullParams = (selectedText : any) => {
                loading.value = true;

                userService.searchWithFullParams("first_name", selectedText,currentPage.value,perPage.value)
              .then((res) => {
                reactiveData.data = res.data;
                reactiveData.totalPages =res.meta.total
                form.customLastPage = res.meta.last_page*10;

              })
              .catch((error) => {
                //console.log("error api : get warehouses", error);
              })
              .finally(() => {
                loading.value = false;
              });
      };


    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      form.customPagination = 10 ;
      reactiveData.searchText = '';
      getUser();
    };


    const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };

    const textDirection = computed(() => {
      return locale.value === 'ar' ? 'rtl' :'ltr';}
    );
  
          const showDeletePopup = (record: any) => {
              Swal.fire({
                  title: t('Pos.TitleDeleteUser'),
                  text: t('Pos.TextDeleteCustUser'),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: "#fc8019",
                  confirmButtonText: t('Pos.Delete'),
                  cancelButtonColor: "#d33",
                  cancelButtonText: t('Pos.Cancel'),
                  customClass: {
                      popup: 'swal2-popup'
                  }
  
              }).then((result) => {
                  if (result.isConfirmed) {
                      deleteUser(record.id);
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                     // console.log("cancel delete ");
                  }
              })
          };
  
          onMounted(() => {
    
              getUser();
              searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
              getroles();
          });
  
          return {
            updateModalWidth,
            modalWidth,
            formatNameConcat,
            textDirection,
            locale,
            resetFields,
            handleSearch,
            handleReset,
              loading,
              currentPage,
              perPage,
              t,
              handlePageChange,
              openDisplay,
              afterOpenShowChange,
              rules,
              columns,
              navigatePage,
              formRef,
              form,
              formatDisplayDate,
              reactiveData,
              showAddDrawer,
              showDrawer,
              open,
              formatDate,
              onSubmitUpdate,
              onSubmitAdd,
              showDeletePopup,
              visible,
              hideForm,
              showDisplayDrawer,
              formatName,
              searchInput,
              reactiveData2,
              searchWithFullParams,
              choosePerPage
          }
      }
  
  })
  </script>
  
  <style scoped>
  .add-btn {
      color: #fc8019;
      border: 2px solid #fc8019;
      box-shadow: inset 0 0 0 0 #fc8019;
  }
  
  .add-btn:hover {
      color: #fff;
      box-shadow: inset 0 -100px 0 0 #fc8019;
      border-color: #fc8019;
  }
  
  .btn-container {
      display: flex;
      margin-bottom: 20px;
      justify-content: flex-end;
  }

  .select-input {
        width: 8%
    }
    
  
  .label-form {
      font-family: "Exo 2", sans-serif;
      font-size: 14px;
      font-weight: 600;
  }
  
  .date-picker {
      width: 100%
  }
  
  
  /* loader container */
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .view-btn{
      color: #fc8019;
      border-color: #fc8019;
      background: #fafafa;
    }
  
    .info-title{
      font-size: 14px;
      font-weight: 600;
      padding-right: 15px;
    }
  
    
    .breadcrumb-icon{
    cursor: pointer;
  }
  
  .test-btn {
      color: #bfffca8a;
  }
  
  .pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .is-rtl {
  direction:rtl;
}


.drawer-header-ar {
        padding-right: 2%;
        display: flex ;
        justify-content :end ;
        align-items: center ;
}
.drawer-header-all {
        display: flex ;
        justify-content :start ;
        align-items: center ;
}

.table-container {
    overflow-x: auto;
}



    @media (max-width: 768px) {
        .pagination {
            flex-direction: column;
            align-items: flex-start;
        }

        .select-input {
            width: 100%;
            margin-top: 10px;
        }
    }


  </style>