<template>

    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{t('Pos.Dashboard')}}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.Settings')}}</a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.StoreSettings')}}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

    <div>

        <a-row >
            <a-card
                class="card-settings"
                :tab-list="settingsTitle"
                :active-tab-key="key"
                @tabChange="key => onTabChange(key, 'key')"
            >
                <p v-if="key === 'store'">
                <a-form>

                </a-form>

                    <a-row class="card-footer">
                        <a-space>
                            <a-button  type="primary">{{ t('Pos.Update') }}    </a-button>
                            <a-button type="primary" danger >{{ t('Pos.Cancel') }}</a-button>
                        </a-space>
                    </a-row>
                </p>
               
                <p v-else>project content</p>
            
            </a-card>
        </a-row>
    </div>

    
</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import { useI18n } from 'vue-i18n';
import { warehouseService, productService, ingredientsService, invoiceService, supplierService } from "@/_services";
import Swal from 'sweetalert2';
import { notification, Table } from 'ant-design-vue';
import { loadRouteLocation, useRouter } from 'vue-router';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import type { UploadChangeParam, UploadFile } from 'ant-design-vue';


import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  ShopOutlined,
  EditOutlined,
  InboxOutlined,
  SwapOutlined,
  RightOutlined,
  LeftOutlined,
  ShoppingOutlined
  
  
} from '@ant-design/icons-vue';

export default defineComponent({
    name: 'indexSettings',
    components: {

        LbrxLoaderSpinner, 
        //InboxOutlined 
    
    },
    setup() {

        const { t } = useI18n();
        const loading = ref(false);
        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

       

        const settingsTitle = [
            {
                key: 'store',
                tab: t('Pos.Store'),
            },
           
            {
                key: 'others',
                tab: t('Pos.Other'),
            },
        ];
        const key = ref('store');

        const onTabChange = (value: string, type: string) => {
            console.log("value : ",value,"type: ,", type);
           
            key.value = value;
           
        };

        //form 
        const formRef = ref();

        const form = reactive({
            id: '',
            number_prefix: '',
            start_number: '',
            dueDate:'',
            authCollabs:true,
            authCustomer:false,
            allowInvoiceDelete:true,
            incrInvoiceNumberAfterdelete:true,
            excludeDraftInvoices:true,
            format1:true,
            format2:false,
            format3:false,
            format4:false,
            description:'',
            photo:'',
        });

        const rules: Record<string, Rule[]> = {
            number_prefix: [{ required: true, message: t('Pos.RequiredField') }],
            start_number: [{ required: true, message: t('Pos.RequiredField') }],
           
        };

        //file 
        const fileList = ref([]);
        const fileInfo = ref<UploadFile | null>(null);
        const handleChange = (info: UploadChangeParam) => {
            fileInfo.value=info.file;
        };

        function handleDrop(e: DragEvent) {
            console.log(e);
        }

        const beforeUpload = () => {
            return false;
        };




        return{
            t,
            loading,
            navigatePage,
            settingsTitle,
            key,
            onTabChange,
            formRef,
            form,
            fileList,
            handleChange,
            handleDrop,
            beforeUpload,
            rules








        }
        
    },
})
</script>

<style scoped>

    .breadcrumb-icon{
        cursor: pointer;
    }

    .card-settings{
        width: 100%;
        margin-top: 15px;
    }

    .label-form {
        font-family: "Exo 2", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    .check-conatiner{
        display: flex;
    }

  
</style>
