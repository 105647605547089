import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4aaec77e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_5 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_6 = {
  key: 2,
  class: "drawer-header-ar"
}
const _hoisted_7 = {
  key: 3,
  class: "drawer-header-all"
}
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_11 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_12 = { class: "info-title" }
const _hoisted_13 = { class: "info-title" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }
const _hoisted_16 = { class: "label-form" }
const _hoisted_17 = { class: "label-form" }
const _hoisted_18 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_ShopOutlined = _resolveComponent("ShopOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_SwapOutlined = _resolveComponent("SwapOutlined")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_InboxOutlined = _resolveComponent("InboxOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, {
        style: {"margin":"16px 0"},
        class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Warehouse')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_row, { gutter: 16 }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            xs: { span: 24},
            sm: { span: 12},
            md: {span: 12},
            lg: { span: 12 },
            xl: { span: 12 },
            xxl: { span: 12 },
            class: "action-btn-container"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                class: "add-btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddDrawer(true)))
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_ShopOutlined)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')) + " ", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            xs: { span: 24},
            sm: { span: 12},
            md: {span: 12},
            lg: { span: 12 },
            xl: { span: 12 },
            xxl: { span: 12 },
            class: "action-btn-container"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                class: "add-btn",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showTransferModal()))
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_SwapOutlined)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Transfer')) + " ", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        class: "table-container",
        columns: _ctx.columns,
        "data-source": _ctx.state.data,
        pagination: false
      }, {
        emptyText: _withCtx(() => [
          _createVNode(_component_a_empty, {
            description: _ctx.t('Pos.NoData'),
            image: _ctx.simpleImage
          }, null, 8, ["description", "image"])
        ]),
        bodyCell: _withCtx(({ column, record }) => [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        onClick: () => _ctx.showDisplayDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Edit')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.ViewStock')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "variant-btn",
                        onClick: ($event: any) => (_ctx.viewStock(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_InboxOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_input, {
              ref: "searchInput",
              placeholder:  `${column.title}`,
              value: selectedKeys[0],
              style: {"width":"188px","margin-bottom":"8px","display":"block"},
              onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
              onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_pagination, {
          current: _ctx.currentPage,
          "onUpdate:current": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentPage) = $event)),
          class: "paginationArrows",
          total: _ctx.state.totalPages,
          onChange: _ctx.handlePageChange,
          "default-page-size": _ctx.perPage
        }, null, 8, ["current", "total", "onChange", "default-page-size"])
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px' },
        onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.close()))
      }, {
        title: _withCtx(() => [
          ((_ctx.locale === 'ar') && (_ctx.state.action=='add'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.AddWarehouseDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale !== 'ar') && (_ctx.state.action=='add'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.AddWarehouseDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale === 'ar') && (_ctx.state.action=='update'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.UpdateWarehouseDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale !== 'ar') && (_ctx.state.action=='update'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.UpdateWarehouseDrawer')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, [
            (_ctx.state.action == 'update')
              ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateWarhouse(_ctx.form.id)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : (_ctx.state.action == 'add')
                ? (_openBlock(), _createBlock(_component_a_space, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _ctx.createWarhouse
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_a_space, { key: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
          ], 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.name,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "location" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.Location')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.location,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.location) = $event)),
                            placeholder: _ctx.t('Pos.Location'),
                            class: "input-form",
                            disabled: _ctx.state.action !== 'add' && _ctx.state.action !== 'update'
                          }, null, 8, ["value", "placeholder", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterOpenShowChange
      }, {
        title: _withCtx(() => [
          (_ctx.locale === 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.DetailsWarehouseDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale !== 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.DetailsWarehouseDrawer')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('Pos.Name')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.name), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Pos.Location')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString((_ctx.form.location)), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_modal, {
        open: _ctx.openTransferModal,
        "onUpdate:open": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.openTransferModal) = $event)),
        onCancel: _ctx.resetStockItem
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "submit",
            type: "primary",
            onClick: _ctx.handleTransferOk
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Confirm')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _ctx.handleCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.TransferStockItem')), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formStockRef",
            model: _ctx.addStockForm,
            rules: _ctx.stockRules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "sender_stock" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Pos.From')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addStockForm.sender_stock,
                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addStockForm.sender_stock) = $event)),
                            "show-search": "",
                            "option-filter-prop": 'label',
                            onSelect: _ctx.selectedSender
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.warehouseTarget, (item) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  label: item.name,
                                  key: item.id,
                                  value: item.id,
                                  selected: item.id === _ctx.addStockForm.sender_stock 
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label", "value", "selected"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "onSelect"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "reciever_stock" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Pos.To')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addStockForm.reciever_stock,
                            "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.addStockForm.reciever_stock) = $event)),
                            "show-search": "",
                            "option-filter-prop": 'label',
                            disabled: !_ctx.addStockForm.sender_stock
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.warehouses, (item) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  label: item.name,
                                  key: item.id,
                                  value: item.id,
                                  selected: item.id === _ctx.addStockForm.reciever_stock 
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["label", "value", "selected"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "item_type" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('Pos.Typeofmerchandise')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addStockForm.item_type,
                            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.addStockForm.item_type) = $event)),
                            "show-search": "",
                            "option-filter-prop": 'label',
                            onChange: _ctx.handleTypeChange,
                            disabled: !_ctx.addStockForm.sender_stock || !_ctx.addStockForm.reciever_stock
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, {
                                value: "product",
                                label: _ctx.t('Pos.Product')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Product')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_select_option, {
                                value: "ingredient",
                                label: _ctx.t('Pos.Ingredient')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Ingredient')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }, 8, ["value", "onChange", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "items" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('Pos.Items')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addStockForm.items,
                            "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.addStockForm.items) = $event)),
                            mode: "multiple",
                            style: {"width":"100%"},
                            placeholder: _ctx.t('Pos.Item'),
                            options: _ctx.options,
                            onChange: _ctx.handleMultiChange,
                            "show-search": "",
                            "option-filter-prop": 'label',
                            disabled: !_ctx.addStockForm.item_type
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder", "options", "onChange", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.qtArray, (item) => {
                return (_openBlock(), _createBlock(_component_a_row, {
                  key: item,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 9 }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(item.item_name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_col, { span: 15 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          "has-feedback": "",
                          "validate-status": _ctx.getValidatingStatus(item.item_id),
                          help: _ctx.getValidatingHelp(item.item_id)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              id: 'item' + item.item_id,
                              placeholder: _ctx.t('Pos.Quantity'),
                              class: "input-form",
                              onBlur: ($event: any) => (_ctx.validateItemQuantity(item.item_id, item.item_quantity))
                            }, null, 8, ["id", "placeholder", "onBlur"])
                          ]),
                          _: 2
                        }, 1032, ["validate-status", "help"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "onCancel"])
    ])
  ], 64))
}