<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Dashboard") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.Inventory") }}</a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.TransferRequests") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <a-table
    class="table-container"
    :columns="columns"
    :data-source="state.data"
    :pagination="false"
  >
    <template #emptyText>
      <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
    </template>

    <template #bodyCell="{ column, record }">
      <template v-if="column.key == 'actions'">
        <a-space>
          <a-tooltip>
            <template #title>{{ t("Pos.View") }}</template>
            <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
              <template #icon>
                <EyeOutlined />
              </template>
            </a-button>
          </a-tooltip>

          <a-tooltip>
            <template #title>{{ t("Pos.Delete") }}</template>
            <a-button type="primary" danger @click="showDeletePopup(record)">
              <template #icon>
                <DeleteOutlined />
              </template>
            </a-button>
          </a-tooltip>
        </a-space>
      </template>
    </template>

    <template
      #customFilterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }"
    >
      <div style="padding: 8px">
        <template v-if="column.key === 'invoice_number'">
          <a-input
            ref="searchInput"
            :placeholder="`${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>

        <a-button
          type="primary"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          {{ t("Pos.Search") }}
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="handleReset(clearFilters)"
        >
          {{ t("Pos.Reset") }}
        </a-button>
      </div>
    </template>

    <template #customFilterIcon="{ filtered }">
      <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
    </template>
  </a-table>

  <div class="pagination">
    <a-pagination
      class="paginationArrows"
      v-model:current="currentPage"
      v-model:pageSize="perPage"
      :total="state.totalPages"
      :default-page-size="perPage"
      :showSizeChanger="false"
      @change="handlePageChange"
    />

    <a-select
      v-model:value="selectPagination"
      @change="handleSelectPaginationChange"
      v-if="state.totalPages > 10"
      class="select-input"
    >
      <a-select-option value="10">10 / {{ t("Pos.page") }}</a-select-option>
      <a-select-option value="20">20 / {{ t("Pos.page") }}</a-select-option>
      <a-select-option value="50">50 / {{ t("Pos.page") }}</a-select-option>
      <a-select-option value="100">100 / {{ t("Pos.page") }}</a-select-option>
    </a-select>
  </div>

  <!-- display drawer -->
  <a-drawer
    v-model:open="openDisplay"
    class="custom-class"
    root-class-name="root-class-name"
    :placement="locale === 'ar' ? 'left' : 'right'"
    :width="modalWidth"
  >
    <template #title>
      <p :class="{ 'is-rtl': textDirection === 'rtl' }">
        {{ t("Pos.PurchaseinvoiceInfo") }}
      </p>
    </template>

    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
      layout="vertical"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-row>
        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.InvoiceNumber") }} :</span>
            {{ form.invoice_number }}
          </p>
        </a-col>

        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.Amount") }} :</span>
            {{ form.amount }}
          </p>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.Supplier") }} :</span>
            {{ form.supplier_name }}
          </p>
        </a-col>

        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.Warehouse") }} :</span>
            {{ form.warehouse_name }}
          </p>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.PurchaseDate") }} :</span>
            {{ formatDateDrawer(form.purchase_date) }}
          </p>
        </a-col>

        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.CreatedAt") }} :</span>
            {{ formatDateDrawer(form.created_at) }}
          </p>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <p>
            <span class="info-title">{{ t("Pos.PaymentType") }} :</span>
            {{ form.payement_type_name }}
          </p>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <p>
            <span class="info-title">{{ t("Pos.Items") }} :</span>
          </p>
        </a-col>
      </a-row>

      <template v-for="item in form.details" :key="item">
        <a-descriptions bordered size="small" class="ingredient-des">
          <a-descriptions-item :label="t('Pos.Name')" :span="3">{{
            item.name
          }}</a-descriptions-item>
          <a-descriptions-item :label="t('Pos.Quantity')">{{
            item.quantity
          }}</a-descriptions-item>
          <a-descriptions-item :label="t('Pos.PurchasePrice')">{{
            item.unit_price
          }}</a-descriptions-item>
        </a-descriptions>
      </template>
    </a-form>
  </a-drawer>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import { ref, defineComponent, reactive, onMounted, computed } from "vue";
import {
  supplierService,
  warehouseService,
  invoiceService,
  productService,
  ingredientsService,
  paymentMethodsService,
  purchaseInvoiceService,
} from "@/_services";
import { useRouter } from "vue-router";
import type { Rule } from "ant-design-vue/es/form";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import dayjs, { Dayjs } from "dayjs";
import { notification } from "ant-design-vue";
import Swal from "sweetalert2";
import "dayjs/locale/fr";
import "dayjs/locale/ar";
import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";
import { Empty } from "ant-design-vue";

export default defineComponent({
  name: "registerIndex",
  components: {
    LbrxLoaderSpinner,
    SearchOutlined,
    DeleteOutlined,
    EyeOutlined,
  },
  setup() {
    const modalWidth = ref(720);
    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const loading = ref(false);
    const { t } = useI18n();
    const searchInput = ref<HTMLInputElement | null>(null);
    const state = reactive({
      searchText: "",
      searchedColumn: "",
      data: [] as any[],
      totalPages: 0,
      suppliers: [] as any[],
      warehouses: [] as any[],
      invoices: [] as any[],
      items: [] as any[],
      qtArray: [] as any[],
      allValues: [] as any[],
      paymentMethods: [] as any[],
      selectedWarehouse: "",
      itemsArray: [] as any[],
      searchNumber: "",
      beginSearch: false,
    });
    const router = useRouter();

    const navigatePage = (link: string) => {
      router.push(link);
    };

    const columns = [
      {
        title: t("Pos.targetWarehouse"),
        key: "target_warehouse",
        dataIndex: "target_warehouse",
        customFilterDropdown: true,
      },
      {
        title: t("Pos.DestinationWarehouse"),
        key: "destination_warehouse",
        dataIndex: "destination_warehouse",
        customFilterDropdown: true,
      },

      {
        title: t("Pos.Approved"),
        key: "approved",
        dataIndex: "approved",
        customFilterDropdown: true,
      },
      {
        title: t("Pos.Date"),
        key: "date",
        dataIndex: "date",
        customFilterDropdown: true,
      },

      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      },
    ];

    const value = ref<string>("1");
    const selectedWarehouse = (value: any) => {
      state.selectedWarehouse = value.target.value;
    };

    //search

    const filter = (key: string, value: string) => {
      loading.value = true;
      purchaseInvoiceService
        .filter(key, value, currentPage.value, perPage.value)
        .then((res) => {
          state.data = res.data;
          state.totalPages = res.meta.total;
          state.beginSearch = true;
          loading.value = false;
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();

      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;

      console.log("searched data", state.searchText);
      console.log("seareched column", state.searchedColumn);

      if (dataIndex === "invoice_number" && state.searchText) {
        state.searchNumber = state.searchText;
        filter(dataIndex, state.searchText);
      }

      if (dataIndex === "warehouse_id" && state.selectedWarehouse !== "") {
        state.totalPages = 0;
        filter("warehouse_id", state.selectedWarehouse);
      }
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      state.searchText = "";
      state.searchNumber = "";
      state.selectedWarehouse = "";
      state.beginSearch = false;
      value.value = "";

      currentPage.value = 1;
      perPage.value = 10;
      selectPagination.value = "10";
      getAll();
    };

    //pagination
    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);

    const selectPagination = ref("10");

    const handleSelectPaginationChange = (selectedItem: any) => {
      console.log("selected Per page:", selectedItem);
      perPage.value = Number.parseInt(selectedItem);
      currentPage.value = 1;

      if (state.beginSearch === true) {
        if (state.selectedWarehouse !== "") {
          loading.value = true;
          purchaseInvoiceService
            .filter(
              "warehouse_id",
              state.selectedWarehouse,
              currentPage.value.toString(),
              perPage.value
            )
            .then((res) => {
              state.data = res.data;
              state.totalPages = res.meta.total;
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              loading.value = false;
            });
        }

        if (state.searchNumber != "") {
          loading.value = true;
          purchaseInvoiceService
            .filter(
              "invoice_number",
              state.searchNumber,
              currentPage.value.toString(),
              perPage.value
            )
            .then((res) => {
              state.data = res.data;
              state.totalPages = res.meta.total;
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              loading.value = false;
            });
        }
      } else {
        loading.value = true;
        purchaseInvoiceService
          .getAllWithPagination(currentPage.value, perPage.value)
          .then((response) => {
            state.data = response.data;
            state.totalPages = response.meta.total;
          })
          .catch((error) => {
            console.log("error api", error);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;

      console.log("per page", perPage.value);
      console.log("page", currentPage.value);

      if (state.beginSearch === true) {
        state.data = [];
        if (state.selectedWarehouse !== "") {
          loading.value = true;
          purchaseInvoiceService
            .filter(
              "warehouse_id",
              state.selectedWarehouse,
              currentPage.value.toString(),
              perPage.value
            )
            .then((res) => {
              state.data = res.data;
              state.totalPages = res.meta.total;
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              loading.value = false;
            });
        }

        if (state.searchNumber !== "") {
          loading.value = true;
          purchaseInvoiceService
            .filter(
              "invoice_number",
              state.searchNumber,
              currentPage.value.toString(),
              perPage.value
            )
            .then((res) => {
              state.data = res.data;
              state.totalPages = res.meta.total;
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              loading.value = false;
            });
        }
      } else {
        loading.value = true;
        purchaseInvoiceService
          .getAllWithPagination(currentPage.value, perPage.value)
          .then((response) => {
            state.data = response.data;
            state.totalPages = response.meta.total;
          })
          .catch((error) => {
            console.log("error api", error);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    //form
    const formRef = ref();
    const form = reactive({
      id: "",
    });

    const getAll = () => {
      loading.value = true;
      purchaseInvoiceService
        .getAllWithPagination(currentPage.value, perPage.value)
        .then((response) => {
          state.data = response.data;
          state.totalPages = response.meta.total;
        })
        .catch((error) => {
          console.log("error get invoice purchase", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    //notification
    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    const showErrorNotification = (message: string) => {
      notification.error({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#FFBFC98A",
          color: "white",
        },
      });
    };

    // display drawer
    const openDisplay = ref<boolean>(false);

    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      openDisplay.value = true;
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: t("Pos.TitleDeleteTransferRequest"),
        text: t("Pos.TextDeleteTransferRequest"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Delete"),
        cancelButtonText: t("Pos.Cancel"),
        cancelButtonColor: "#d33",
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("delete");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      });
    };

    onMounted(() => {
      console.log("mount");
    });

    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    return {
      updateModalWidth,
      modalWidth,
      loading,
      t,
      locale,
      textDirection,
      state,
      navigatePage,
      columns,
      formRef,
      form,
      openDisplay,
      showDisplayDrawer,
      open,
      showDeletePopup,
      perPage,
      currentPage,
      handlePageChange,
      handleSearch,
      handleReset,
      selectPagination,
      handleSelectPaginationChange,
      simpleImage,
    };
  },
});
</script>

<style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.breadcrumb-icon {
  cursor: pointer;
}
.input-form {
  color: black;
  background-color: #fff;
}
.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.range-picker {
  width: 100%;
}

.ingredient-des {
  margin-bottom: 15px;
}

.search-radio-grp {
  display: flex;
  flex-direction: column !important;
}

.radio-item {
  margin-bottom: 10px;
}

/* CSS for RTL */
.is-rtl {
  direction: rtl;
}

.paginationArrows {
  direction: ltr;
}

.table-container {
  overflow-x: auto;
}
</style>
