<template>
    <a-row>
        <a-col :span="24" class="options-container">
            <a-segmented v-model:value="activeTab" :options="tabList" @change="onTabChange"/>
        </a-col>
    </a-row>

        <template v-if="activeTab === t('Pos.Profile') ">
            <a-card class="profile-card" >
                <template #title>
                    <a-row class="title-row"  align="middle">
                        <a-col :span="12" class="title-text">
                            <p>{{ t('Pos.ProfileDetails') }}</p>
                        </a-col>
                        <a-col :span="12" class="avatar-col">
                            <a-avatar  :size="50">
                               <p class="avatar-text">{{state.firstName}}</p>
                            </a-avatar>
                        </a-col>
                    </a-row>
    
                </template>
                <a-form ref="profileRef" :model="profileForm"  :rules="rules" layout="vertical">
                        <a-row :gutter="16">   
                            <a-col :span="12">
                                <a-form-item  name="firstName">
                                    <template #label>
                                        <span class="label-form">{{ t('Pos.FisrtName') }}</span>
                                    </template>
                                    <a-input  v-model:value="profileForm.firstName" :placeholder="t('Pos.FisrtName')" class="input-form" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item  name="lastName">
                                    <template #label>
                                        <span class="label-form">{{ t('Pos.LastName') }}</span>
                                    </template>
                                    <a-input  v-model:value="profileForm.lastName" :placeholder="t('Pos.LastName')" class="input-form" />
                                </a-form-item>
                            </a-col>
                        </a-row>    
                        <a-row :gutter="16">   
                            <a-col :span="12">
                               <a-form-item
                                    name="email" has-feedback
                                    :validate-status="validatingStatus"
                                    :help="validatingHelp"
                                >

                                    <template #label>
                                    <span class="label-form">{{ t('Pos.Email') }}</span>
                                    </template>

                                    <a-input v-model:value="profileForm.email" :placeholder="t('Pos.Email')"  @blur="validateEmail" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item  name="userName">
                                    <template #label>
                                        <span class="label-form">{{ t('Pos.Username') }}</span>
                                    </template>
                                    <a-input  v-model:value="profileForm.userName" :placeholder="t('Pos.Username')" class="input-form" />
                                </a-form-item>
                            </a-col>
                        </a-row>    
                </a-form>

                <a-row class="card-footer">
                    <a-space>
                        <a-button  type="primary" @click="updateUser(profileForm.id)">{{ t('Pos.SaveChanges') }}</a-button>
                        <a-button type="primary" danger @click="resetFormProf">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                </a-row>
            </a-card>


        </template>
        <template v-else-if="activeTab === t('Pos.Security')">
            <a-card class="profile-card">
                <template #title>
                    <a-row class="title-row"  align="middle">
                        <a-col :span="12" class="title-text">
                            <p>{{ t('Pos.ChangePassword')}}</p>
                        </a-col>
                    </a-row>
                </template>

                <a-form  ref="securityRef" :model="securityForm"  :rules="securityRules" layout="vertical">
                    <a-row>
                        <a-col :span="24">
                            <a-form-item  has-feedback name="current">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.CurrentPass') }}</span>
                                </template>
                                <a-input-password v-model:value="securityForm.current"  autocomplete="off" />

                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="24">
                            <a-form-item  has-feedback name="pass">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Password') }}</span>
                                </template>
                                <a-input-password v-model:value="securityForm.pass" type="password" autocomplete="off" />

                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="24">
                            <a-form-item  has-feedback name="checkPass">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Confirmpassword') }}</span>
                                </template>
                                <a-input-password v-model:value="securityForm.checkPass" type="password" autocomplete="off" />
                            </a-form-item>
                        </a-col>
                    </a-row>

                </a-form>
                <a-row class="card-footer">
                    <a-space>
                        <a-button  type="primary" @click="updateUserPass()">{{ t('Pos.UpdatePass') }}    </a-button>
                        <a-button type="primary" danger @click="resetFormPass">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                </a-row>
            </a-card>
        </template>


        <template v-else-if="activeTab === t('Pos.Billing&Plans')">
            <p>Billing content</p>
        </template>
  
 
</template>
<script  lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, watch, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
EyeOutlined,
DeleteOutlined,
SearchOutlined,
PercentageOutlined,
EditOutlined

} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { userService} from '@/_services'
import dayjs , { Dayjs }from 'dayjs'; 
import { getLastMeasureIndex } from 'ant-design-vue/es/vc-mentions/src/util';
import type { TreeSelectProps } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'indexProfile',
    components: {
        // EyeOutlined,
        // DeleteOutlined,
        // SearchOutlined,
        // PercentageOutlined,
        // EditOutlined,
        // LbrxLoaderSpinner
    },
    setup() {
        const { t } = useI18n();

        const dirtyFields = reactive({
            firstName: false,
            lastName: false,
            email: false,
            userName: false,
        });
        
       
        


        const state = reactive({
            firstName: '',
        });

        const activeTab = ref(t('Pos.Profile'));
        const tabList = [t('Pos.Profile'), t('Pos.Security'),t('Pos.Billing&Plans')];

        //form 
        const profileRef = ref();
        const securityRef = ref();

        const profileForm = reactive({
            id: '',
            firstName: '',
            lastName: '',
            userName:'',
            email:'',
            photo:null,
            created_at: null as Dayjs | null,
        });
      
        let changedEmail=false;

        watch(()=>profileForm.email,(newValue, oldValue) =>{
            console.log('old value', oldValue);
            console.log('new value', newValue);
            const userEmail = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).email : '';

            if(newValue!==oldValue && userEmail!==newValue){
                changedEmail=true;
            }
        });
        

        const securityForm = reactive({
            current:'',
            pass: '',
            checkPass: '',
        });

        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t('Pos.RequiredField') }],
            firstName: [{ required: true, message: t('Pos.RequiredField') }],
            lastName: [{ required: true, message: t('Pos.RequiredField') }],
            email: [
                { required: true, message: t('Pos.RequiredField') , trigger: ['blur', 'change'] },
                {
                    validator: (rule, value) => {
                        if (!value) {
                            return Promise.resolve(); 
                        }
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value)) {
                            return Promise.reject(t('Pos.invalidEmail'));
                        }
                        return Promise.resolve();
                    }, trigger: 'change'
                }
                 
            ], 
            userName: [{ required: true, message: t('Pos.RequiredField')}],

        };

        const validatePass = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject(t('Pos.RequiredField'));
            } else {
                if (securityForm.checkPass !== '') {
                    securityRef?.value?.validateFields('checkPass');
                }
                return Promise.resolve();
            }
        };
        const validatePass2 = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject(t('Pos.RequiredField'));
            } else if (value !== securityForm.pass) {
                return Promise.reject(t('Pos.PasswordMatchError'));
            } else {
                return Promise.resolve();
            }
        };
        const securityRules: Record<string, Rule[]> = {
            current: [{ required: true, message: t('Pos.RequiredField') }],
            pass: [{ required: true, validator: validatePass, trigger: 'change' }],
            checkPass: [
                { required: true,validator: validatePass2, trigger: 'change' }
            ],
        };

        const validatingStatus = ref('');
        const validatingHelp = ref('');

        const validateEmail = () => {

            console.log('begin validation email');
            const email = profileForm.email.trim();
            console.log('changed email value', changedEmail);
            if (changedEmail===false) {
                // email isn't changed, no need to validate
                console.log('no email validation ');
                console.log(dirtyFields)
                return;
            }
            
            const payload = { email: email };
            validatingStatus.value = 'validating'; 
            validatingHelp.value = t('Pos.validationEmailTxt');

            userService.verifyEmail(payload)
            .then(response => {
                console.log('response', response);
                if (response.message==="Email available for use") {
                    validatingStatus.value = 'success';
                    validatingHelp.value = t('Pos.EmailValidRep');
                    changedEmail=false;
                } else {
                    validatingStatus.value = 'error'; 
                    validatingHelp.value = t('Pos.EmailInUse');
                }
            })
            .catch(error => {
                console.error('Error validating email:', error);
                validatingStatus.value = 'error'; 
                validatingHelp.value = t('Pos.EmailInUse');
            });
        };

        const resetFormPass = () => {
            securityRef.value.resetFields();
        };

        const resetFormProf = () => {
            profileRef.value.resetFields();
        };


        const getUserById=(id:string)=>{
            
            userService.show(id)
            .then(res => {
                console.log('user', res.data);

                profileForm.id = res.data.id;
                profileForm.firstName=res.data.first_name;
                profileForm.lastName=res.data.last_name;
                profileForm.email=res.data.email;
                profileForm.userName=res.data.username;
                profileForm.created_at=res.data.created_at;
            })
            .catch(error => {
                console.error('Error fetching user by id:', error);
            });
        }

        const onTabChange = (tab: string) => {
            if (tab === 'Profile') {
                const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).id : '';
                getUserById(userId); 
            }
        }

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

        const showErrorNotification  = (message:string)=>{
            notification.error({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#FFBFC98A',
                color: 'white'
                }
            });
            resetFormPass();
        };

        const updateUser =(id:string)=>{
        
            profileRef!.value!
            .validate()
            .then(()=>{
                let payload = {
                    first_name:profileForm.firstName,
                    last_name:profileForm.lastName,
                    username:profileForm.userName,
                    email:profileForm.email,
                    
                }
                console.log('payload', payload);
                userService.update(id, payload).then((response)=>{
                    console.log('updated successfully', response.data);
                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    showSuccessNotification(t('Pos.UpdateProfileSuccess'));
                    console.log('finish')
                }); 
            }).catch((error:any)=>{
                console.log('error validation form', error);
            });
        }

        
        const updateUserPass =()=>{
        
            securityRef!.value!
            .validate()
            .then(()=>{
                const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).id : '';

                let payload = {
                    old_password:securityForm.current,
                    password:securityForm.pass
                }
                
                userService.update(userId, payload).then((response)=>{
                    console.log('updated successfully', response.data);
                    showSuccessNotification(t('Pos.PasswordUpdateSuccess'));
                    resetFormPass();
                }).catch((err) => {
                    console.log(err)
                    if(err==="wrong old password"){
                        showErrorNotification("Current password invalid");
                    }
                }).finally(() => {
                    console.log('finish')
                }); 
                
            }).catch((error:any)=>{
                console.log('error validation form', error);
            });
        }


        const getFirstLetter=(name:string)=>{ 
            state.firstName= name.charAt(0).toUpperCase();
        }

        onMounted(() => {
            const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).id : '';
            const firstName = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).first_name : '';

            getUserById(userId);
            getFirstLetter(firstName.toString());
        });

        


    



        return{
            t,
            state,
            activeTab,
            tabList,
            profileRef,
            securityRef,
            profileForm,
            securityForm,
            onTabChange,
            updateUser,
            rules,
            securityRules,
            validatingStatus,
            validatingHelp,
            validateEmail,
            dirtyFields,
            resetFormPass,
            resetFormProf,
            updateUserPass







        }    
    },
})
</script>
<style scoped>

    .options-container{
        display: flex;
        margin-bottom: 24px;
    }

   
    .profie-card{
        width: 100%;
    }

    .label-form {
        font-size: 14px;
        font-weight: 600;
    }

    .input-form{
        padding:.422rem .875rem;
        color: #6f6b7d;

    }

    .card-footer{
        margin-top: 24px;
    }

    .card-title{
        display: flex;
    }

    .title-row{
        padding: 0 10px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .title-text{
        text-align: left;
    }
    .title-text p{
        font-size: 18px;
        color: #4B4650;
    }

    .avatar-col {
        text-align: right; 
        height: 50px;
    }

   
    .avatar-text{
        font-size: 24px;
       line-height: 5px;
       
    }

    
   

</style>
