<template>

    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
            <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
                {{t('Pos.Dashboard')}}
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{t('Pos.Tables')}}</a-breadcrumb-item>
            <a-breadcrumb-item>{{t('Pos.TableLocation')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div class="btn-container">
        <a-button class="add-btn" @click="showAddDrawer" >
            {{ t('Pos.Add') }}
        </a-button>
    </div>

    
    <a-table
        :columns="columns"
        :data-source="state.data"
        :pagination="false"
        class="table-container"
    >
        <template #bodyCell="{ column, record }">
            <template v-if="column.key=='actions'">
                <a-space>
                    <a-tooltip>
                        <template #title>{{ t('Pos.View') }}</template>
                        <a-button class="view-btn"  @click="() => displayTable(record)">
                        <template #icon>
                            <EyeOutlined/>
                        </template>
                        </a-button>
                    </a-tooltip>
                    
                    <a-tooltip>
                        <template #title>{{ t('Pos.Update') }}</template>
                        <a-button type="primary"  @click="showDrawer(record)">
                        <template #icon>
                            <EditOutlined />
                        </template>
                        </a-button>
                    </a-tooltip>

                    <a-tooltip>
                        <template #title>{{ t('Pos.Delete') }}</template>
                        <a-button type="primary" danger @click="showDeletePopup(record)">
                        <template #icon>
                            <DeleteOutlined />
                        </template>
                        </a-button>
                    </a-tooltip>

                   
                </a-space>
            </template>

            <template v-if="column.key === 'created_at' ">
                {{ formatStringDate(record[column.key]) }}
            </template>
            
        </template>

        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
            
            <a-input
            ref="searchInput"
            :placeholder="`${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />  
           

            <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
            
                {{ t('Pos.Search') }}
            </a-button>
            <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                {{ t('Pos.Reset') }}
            </a-button>
                
            </div>
        </template>
        
      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>

    <div class="pagination">
        <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />

            
        <a-select
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages>10"
        >
            <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
            <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
            <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
            <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
        </a-select>

    </div>





    <!-- <div class="pagination" v-if="state.totalPagesSearch ===0 ">
      <a-pagination v-model:current="currentPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />
    </div>

    <div class="pagination" v-if="state.totalPagesSearch !==0 ">
      
      <a-pagination v-model:current="currentPageSearch" :total="state.totalPagesSearch" @change="handlePageChangeSearch"  :default-page-size="perPageSearch" :showSizeChanger=false />
    </div> -->


    <!-- display drawer --> 
    <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width=modalWidth
        @after-open-change="afterDisplayOpenChange"
    >
        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.TableLocationInfo')}}</p>
        </template>

        <a-form  ref="formRef" :model="form" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row :gutter="16"> 

                <a-col flex="150px">
                <a-image
                    :width="100"
                    :src="form.photo"
                />    
                </a-col>
                <a-col flex="auto" >
                <p>{{transformUpperCase(form.name)}}</p>
                </a-col>
            </a-row>

            <a-row>   
                <a-col :span="24">
                    <p><span class="info-title">{{ t('Pos.Description') }} :</span>{{form.description}}</p>
                   
                </a-col>
            </a-row>

          
        </a-form>
      
        
    </a-drawer>

    <!-- update drawer -->

    <a-drawer
        v-model:open="open"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width=modalWidth
        @after-open-change="afterOpenChange"
    >
        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateTableLocation')}}</p>
        </template>


        <a-form  ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row >   
                <a-col :span="24">
                    <a-form-item  name="name">
                    <template #label>
                        <span class="label-form">{{ t('Pos.Name') }}</span>
                    </template>
                    <a-input  v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>   
                <a-col :span="24">
                    <a-form-item  name="description">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Description') }}</span>
                        </template>
                        <a-textarea v-model:value="form.description" :rows="2" :placeholder="t('Pos.Description')" />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>   
                <a-col :span="24">
                    <a-form-item  name="photo">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Photo') }}</span>
                        </template>

                        <a-upload-dragger
                            v-model:fileList="fileList"
                            name="file"
                            :multiple="false"
                            :max-count="1"
                            @change="handleChangeFile"
                            @drop="handleDrop"
                            :before-upload="beforeUpload"
                            >

                            <p class="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p class="ant-upload-text">{{ t('Pos.dropTitle1') }}</p>
                            <p class="ant-upload-hint">
                                {{ t('Pos.dropTitle2') }}
                            </p>

                        </a-upload-dragger>   
                        <template v-if="form.photo!==null && !state.fileChanged">
                            <div>
                                <p class="photo-info" @click="() => setVisible(true)"><PaperClipOutlined /><span class="photo-title"> {{extractFilename(form.photo)}}</span> <DeleteOutlined @click="deletePhoto"/></p>

                                <a-image
                                    :width="200"
                                    :style="{ display: 'none' }"
                                    :preview="{
                                        visible,
                                        onVisibleChange: setVisible,
                                    }"
                                    :src="form.photo"
                                />
                            </div>
                        </template>    
                        

                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
      <template #footer>
          <a-space :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-button  type="primary" @click="updateTableLocation(form.id)">{{ t('Pos.Update') }}</a-button>
            <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
          </a-space>
      </template>
        
    </a-drawer>

    <!-- add drawer -->
    <a-drawer
        v-model:open="openAdd"
        class="custom-class"
        root-class-name="root-class-name"
        :title="t('Pos.AddTableLocation')"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width=modalWidth
        @close="resetFormAdd"
    >   
        <template></template>

        <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            
            <a-row >   
                <a-col :span="24">
                    <a-form-item  name="name">
                    <template #label>
                        <span class="label-form">{{ t('Pos.Name') }}</span>
                    </template>
                    <a-input  v-model:value="addForm.name" :placeholder="t('Pos.Name')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>   
                <a-col :span="24">
                    <a-form-item  name="description">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Description') }}</span>
                        </template>
                        <a-textarea v-model:value="addForm.description" :rows="2" :placeholder="t('Pos.Description')" />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row >   
                <a-col :span="24">
                    <a-form-item  name="photo">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Photo') }}</span>
                        </template>
                        <a-upload-dragger
                            v-model:file-list="fileList"
                            name="file"
                            :multiple="false"
                            :max-count="1"
                            @change="handleChange"
                            @drop="handleDrop"
                            :before-upload="beforeUpload"
                            accept=".png, .jpg .jpeg"
                        >

                            <p class="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p class="ant-upload-text">{{ t('Pos.dropTitle1') }}</p>
                            <p class="ant-upload-hint">
                               {{ t('Pos.dropTitle2') }}
                            </p>

                        </a-upload-dragger>            
                    </a-form-item>
                </a-col>


            </a-row>
           
            <!-- <a-row >
                <a-form-item name="photo">
                    <template #label>
                        <span class="label-form">Photo</span>
                    </template>
                    <input type="file" @change="handleFileChange" accept=".png, .jpg, .jpeg" />
                </a-form-item>
                

            </a-row> -->

           
          
            
        </a-form>
        <template #footer>
            <a-space :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-button  type="primary" @click="addTableLocation">{{ t('Pos.Add') }}</a-button>
                <a-button type="primary" danger  @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
            </a-space>
        </template>
    </a-drawer>
 
</template>


<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, Ref, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  InboxOutlined,
  EditOutlined,
  FileOutlined,
  PaperClipOutlined,

  


} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { tableLocationService  } from '@/_services';
import type { UploadChangeParam,UploadFile } from 'ant-design-vue';
import dayjs from 'dayjs'; 
import relativeTime from 'dayjs/plugin/relativeTime';

import { useI18n } from 'vue-i18n';
import LbrxLoaderSpinner from '../../../components/LbrxLoaderSpinner.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'indexTablesLocation',
  components: {
    EyeOutlined,
    DeleteOutlined,
    InboxOutlined,
    EditOutlined,
    SearchOutlined,
    LbrxLoaderSpinner,
    PaperClipOutlined
  },
  setup() {
    const modalWidth = ref(720);
    const router = useRouter();
    dayjs.extend(relativeTime);



    const navigatePage = (link: string) => {
        router.push(link); 
    };
    const state = reactive({
        searchText: '',
        searchedColumn: '',
        data: [] as any[],
        totalPages:0,
        totalPagesSearch:0,
        fileChanged:false,
        beginSearch:false,
        searchName:'',

    });
    const { t } = useI18n();
    const searchInput = ref<HTMLInputElement | null>(null);

    const columns=[
        {
            title:t('Pos.Name'),
            key:'name',
            dataIndex: 'name',
            customFilterDropdown: true,
            onFilter: (value:string, record:any) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownOpenChange:  (visible: boolean) => {
                if (visible  && !searchInput.value) {
                    setTimeout(() => {
                    const input = document.querySelector('.ant-table-filter-dropdown input');

                    if (input instanceof HTMLInputElement) {
                        searchInput.value = input;
                        searchInput.value.focus();
                    }
                    }, 100);
                }
            },
        },
        {
            title:t('Pos.Description'),
            key:'description',
            dataIndex: 'description',
        },
        {
            title:t('Pos.CreatedAt'),
            key:'created_at',
        },
        {
            title:t('Pos.Actions'),
            key:'actions',
        }
    ];

    const loading = ref(false);
    //pagination
    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);

    const selectPagination = ref('10');

    const perPageSearch = ref<number>(10);
    const currentPageSearch = ref<number>(1);

    const handleSelectPaginationChange=(selectedItem:any)=>{
            
        perPage.value=Number.parseInt(selectedItem);
        currentPage.value=1;

        if(state.beginSearch===true){

            if(state.searchName!==''){
                loading.value = true;
                tableLocationService.filterWithPagination("name",state.searchName,currentPage.value.toString(),perPage.value).then((res)=>{
                    state.data=res.data;
                    state.totalPages=res.meta.total;
                }).catch((err:any) => {
                    console.log(err)
                }).finally(()=>{
                    loading.value = false;
                });
            }

        }else{

            loading.value=true;
            tableLocationService.getAllWithPagination( perPage.value,currentPage.value,).then((res)=>{
                state.data=res.data;
                state.totalPages=res.meta.total;
            }).catch((err:any) => {
                console.log(err)
            }).finally(()=>{
                loading.value = false;
            });

        }

        
    }



    const handlePageChange = (newPage:any) => {
      currentPage.value = newPage;
     

      console.log('per page', perPage.value);
      console.log('page', currentPage.value);

      if(state.beginSearch===true){

        loading.value = true;

        if(state.searchName!==''){
            tableLocationService.filterWithPagination('name',state.searchName,currentPage.value.toString(), perPage.value).then((res)=>{
                state.data=res.data;
                state.totalPages=res.meta.total;
            }).catch((err:any) => {
                console.log(err)
            }).finally(()=>{
                loading.value = false;
            });
        }

      }else{

        tableLocationService.getAllWithPagination(perPage.value, currentPage.value).then((response)=>{
        state.data=response.data;
        state.totalPages=response.meta.total;
        }).catch((error)=>{
            console.log("error api : get tables", error);
        })
        .finally(()=>{
            loading.value = false;
        });

      }


     
    }

    const handlePageChangeSearch = (newPage:any) => {
      currentPageSearch.value = newPage;
      loading.value = true;

      console.log('per page', perPageSearch.value);
      console.log('page', currentPageSearch.value);

      state.data=[];
      tableLocationService.filterWithPagination("name",state.searchText,currentPageSearch.value.toString(), perPage.value).then((res)=>{
        console.log('res', res.data);
        state.data=res.data;
        state.totalPagesSearch = res.meta.total;
      }).catch((err:any) => {
        console.log(err)
      }).finally(()=>{
        loading.value = false;
      })


    }

    const filter=(key:string, value:string)=>{
        loading.value=true;
        currentPage.value=1; 
        tableLocationService.filterWithPagination(key,value, currentPage.value.toString(), perPage.value).then((res)=>{
            state.data=res.data;
            state.totalPages = res.meta.total;
            state.beginSearch=true;
            loading.value=false;
        }).catch((err:any) => {
            console.log(err)
        });
    }

    //search
    const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
      confirm();

      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;

      console.log('searched data', state.searchText);
      console.log('seareched column', state.searchedColumn);


      
      if (dataIndex === 'name' && state.searchText) {
        console.log('begin filter');
        state.searchName=state.searchText;
        filter("name", state.searchText );
      }
    };

    const handleReset =  (clearFilter: (arg: any) => void)=> {
        clearFilter({ confirm: true });
        state.searchText = '';
        state.searchName='';
        state.beginSearch=false;
        currentPage.value=1;
        perPage.value=10;
        selectPagination.value='10';
        getTablesLocation();

    };

    //form  
    const formRef = ref();
    const addFormRef = ref();

    const form = reactive({
        id: '',
        name: '',
        description: '',
        photo:'' as string ||null,
    });

    const addForm = reactive({
       id: '',
        name: '',
        description: '',
        photo:'',
    });

    const rules: Record<string, Rule[]> = {
        name: [{ required: true, message: t('Pos.RequiredField') }],
    };

    const showSuccessNotification  = (message:string)=>{
        notification.success({
            message: message,
            duration: 3,
            top: '80px',
            style:{
            backgroundColor: '#bfffca8a',
            color: 'white'
            }
        });
    };

    //drawer
    const open = ref<boolean>(false);

    const showAddDrawer = (state: boolean) => {
        updateModalWidth();
        openAdd.value = true;
    };

    const afterOpenChange = (bool: boolean) => {
        console.log('open', bool);
    };
  
    const showDrawer = (record: any) => {
        updateModalWidth();
        open.value = true;
        getTableLocationById(record.id);
    };

    const openDisplay = ref<boolean>(false);

    const displayTable = (record:any)=>{
        updateModalWidth();
        openDisplay.value = true;
        getTableLocationById(record.id);

    }

    const afterDisplayOpenChange = (bool: boolean) => {
        console.log('open', bool);
    };

    const cancelUpdate=()=>{
        open.value=false;      
    }

    const getTableLocationById = (id:string)=>{
        tableLocationService.show(id)
            .then(res => {
                form.name = res.data.name;
                form.description = res.data.description;
                form.photo = res.data.photo;
                form.id = res.data.id;
            })
            .catch(error => {
                console.error('Error fetching table location by id:', error);
            });
    }
    

    const openAdd = ref<boolean>(false);

    const afterOpenAddChange = (bool: boolean) => {
        console.log('open', bool);
    };



    const resetFormAdd = () => {
        addFormRef.value.resetFields();
        fileList.value=[];
    };

    const getTablesLocation=()=>{
        loading.value = true;
        tableLocationService.getAllWithPagination(perPage.value, currentPage.value).then((response)=>{
            console.log("getting table locations", response.data);
            state.data=response.data;
            state.totalPages=response.meta.total;
        }).catch((error)=>{
            console.log("error get table location", error);
        }).finally(()=>{
         loading.value = false;
        });
    }

    //file
    const fileList = ref([]);

    const beforeUpload = () => {
      return false;
    };

    //let uploadedFile =ref(null);
    const uploadedFile : Ref<any> = ref(null);
    const files = ref<File | null>(null);

    const fileInfo = ref<UploadFile | null>(null);
    const fileUpdateInfo = ref<UploadFile | null>(null);

    const handleChange = (info: UploadChangeParam) => {
      
        const file = fileList.value[0];
        console.log('info', info.file);
        fileInfo.value=info.file;
    };

    const handleChangeFile = (info: UploadChangeParam) => {
      state.fileChanged=true;
      fileUpdateInfo.value=info.file;
    };

    const handleFileChange = (event:any) => {
        const file = event.target.files[0];
        addForm.photo = file;
        console.log('file', file);
    };

    function handleDrop(e: DragEvent) {
      console.log(e);
    }

    const addTableLocation =()=>{
        
        addFormRef.value
            .validate()
            .then(()=>{
                const file = fileList.value[0];
                
                
                let payload = {
                    name:addForm.name,
                    description:addForm.description,
                    photo:file,
                }

                const formData = new FormData();
                formData.append("name", addForm.name);
                formData.append("description", addForm.description);

                if(files.value!==null){
                    formData.append("photo",addForm.photo);
                }

                console.log('form data', formData);
                console.log('added values', payload);
                loading.value = true;
                tableLocationService.createTableLocation(addForm.name, addForm.description, fileInfo.value)
                .then((response)=>{
                    getTablesLocation();
                    //state.data.push(response.data);
                    showSuccessNotification(t('Pos.AddTableLocationSuccess'));

                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    console.log('finish');
                    loading.value = false;
                    openAdd.value=false;
                    resetFormAdd();
                }); 
            }).catch((error:any) => {
            console.error('Validation error:', error);
        });
        
       
       
    }

    const formatStringDate=(str:string)=>{
        return dayjs(str).fromNow();
    }

    const formatDisplayDate=(dateString : string)=>{
        return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    }

    const validPhoto=(url:string):boolean=>{
        const prefix = 'https://posi-api.liberrex.com/';
        return url.startsWith(prefix);
    }

    const showDeletePopup = (record: any) => {
        Swal.fire({
            title: t('Pos.TitleDeleteTableLocation'),
            text: t('Pos.TextDeleteTableLocation'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: "#fc8019",
            confirmButtonText: t('Pos.Delete'),
            cancelButtonText: t('Pos.Cancel'),
            cancelButtonColor: "#d33",
            customClass: {
                popup: 'swal2-popup'
            }

        }).then((result) => {
            if (result.isConfirmed) {
                deleteTableLocation(record.id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log("cancel delete ");
            }
        })
    };

    const deleteTableLocation = (id: string) => {
        tableLocationService.delete(id).then((res) => {
           // state.data = state.data.filter(e => e.id !== id);
           getTablesLocation();
           showSuccessNotification(t('Pos.DeleteTableLocationSuccess'));

        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            console.log('finish')
        })
    };

    const updateTableLocation =(id:string)=>{
        
        formRef.value
        .validate()
        .then(()=>{
            loading.value = true;
            tableLocationService.updateTableLocation(id, form.name, form.description,fileUpdateInfo.value ).then((response)=>{
                showSuccessNotification(t('Pos.UpdateTableLocationSuccess'));

                state.data = state.data.map(el => el.id !== id ? el : response.data);
            }).catch((err) => {
            console.log(err)
            }).finally(() => {
                console.log('finish');
                open.value=false;
                loading.value = false;
                state.fileChanged=false;
            }); 
        })
    }
    
    const transformUpperCase=(str:string)=>{
      if (!str || str.length === 0) {
        return str;
      }

      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function extractFilename(url:any) {
      const parts = url.split('/');
      const filename = parts[parts.length - 1];
      return filename;
    }

    //img visible 
    const visible = ref<boolean>(false);
    const setVisible = (value:any): void => {
      visible.value = value;
    };

    const deletePhoto=()=>{
     console.log('aaj');
      form.photo=null;
      state.fileChanged=true;
      console.log('form ref ', form.photo);
    }

    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
        return locale.value === 'ar' ? 'rtl' :'ltr';}
    );



   

    onMounted(() => {
        getTablesLocation();
        searchInput.value = document.querySelector('.ant-table-filter-dropdown input');

    });


    const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };


    return{
        updateModalWidth,
        modalWidth,
        t,
        locale,
        textDirection,
        state,
        columns,
        formRef,
        addFormRef,
        form,
        addForm,
        rules,
        open,
        showDrawer,
        showAddDrawer,
        afterOpenChange,
        openAdd,
        afterOpenAddChange,
        resetFormAdd,
        cancelUpdate,
        fileList,
        beforeUpload,
        handleChange,
        handleDrop,
        addTableLocation,
        formatDisplayDate,
        validPhoto,
        openDisplay,
        displayTable,
        afterDisplayOpenChange,
        showDeletePopup,
        updateTableLocation,
        handleFileChange,
        handlePageChange,
        perPage,
        currentPage,
        loading,
        handleSearch,
        handlePageChangeSearch,
        perPageSearch,
        currentPageSearch,
        transformUpperCase,
        extractFilename,
        visible,
        setVisible,
        deletePhoto,
        fileUpdateInfo,
        handleChangeFile,
        navigatePage,
        formatStringDate,
        handleReset,
        handleSelectPaginationChange,
        selectPagination









    }
  }
})
</script>

<style scoped>

    .breadcrumb-icon{
        cursor: pointer;
    }

    .btn-container{
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
    }

    .add-btn{
        color: #fc8019;
        border: 2px solid #fc8019;
        box-shadow: inset 0 0 0 0 #fc8019;
    }
    .add-btn:hover{
        color: #fff;
        box-shadow: inset 0 -100px 0 0 #fc8019;
        border-color: #fc8019;
    }

    .label-form{
        font-family: "Exo 2", sans-serif; 
        font-size: 14px;
        font-weight: 600;
    }

    .date-picker{
        width:100%
    }
    .new-location{
        display: flex;
        align-items: center;
    }
    .location-btn{
        margin-top: 7.5px;
        width: 100%;
    }
    .ant-upload-wrapper{
        color: #fc8019  ;
    }

    .view-btn{
        color: #fc8019;
        border-color: #fc8019;
        background: #fafafa;
    }

    .pagination{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info-title{
        font-size: 14px;
        font-weight: 600;
        padding-right: 15px;
    }

    .photo-info{
        padding:0px 8px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .photo-info:hover{
    background: #efefefec;
    }

    /* CSS for RTL */
    .is-rtl {
        direction:rtl;
    }

    .paginationArrows{
        direction: ltr;
    }
    .table-container {
        overflow-x: auto;
    }

    @media (max-width: 768px) {
        .pagination {
        flex-direction: column;
        align-items: flex-start;
        }

        .select-input {
        width: 100%;
        }
    }


</style>