<template>
    

    <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>


    <div>
    <a-breadcrumb style="margin: 16px 0" :class="{ 'is-rtl': textDirection==='rtl'}">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t('Pos.Home') }}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.Settings')}}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ t('Pos.Variants') }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

    <div class="btn-container">
        <a-button class="add-btn" @click="showAddDrawer(true)">
            {{ t('Pos.Add') }}
        </a-button>
    </div>
    <div>
        <a-table class="table-container" :columns="columns" :data-source="reactiveData.data" :pagination="false" >

          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'actions'">
                <a-space>
                  <a-tooltip>
                    <template #title> {{ t('Pos.View') }}</template>
                    <a-button  class="view-btn" type="primary" @click="showDisplayDrawer(record)">

                      <template #icon>
                        <EyeOutlined />
                      </template>
                    </a-button>
                  </a-tooltip>
                  <a-tooltip>
                    <template #title>{{ t('Pos.Edit') }}</template>

                    <a-button type="primary"  @click="() => showDrawer(record)">
                      <template #icon>
                        <EditOutlined />
                      </template>
                    </a-button>

                  </a-tooltip>
                  <a-tooltip>

                    <template #title>{{ t('Pos.Delete') }}</template>
                    <a-button type="primary" danger @click="showDeletePopup(record)">
                      <template #icon>
                        <DeleteOutlined />
                      </template>
                    </a-button>
                  </a-tooltip>
                </a-space>
              
            </template>
          </template>
      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
          
            {{ t('Pos.Search') }}
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            {{ t('Pos.Reset') }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
        </a-table>


        <div class="pagination">
      <a-pagination class="paginationArrows" v-model:current="currentPage" :total="reactiveData.totalPages" @change="handlePageChange"  :default-page-size="perPage"/>
    </div>



        <a-drawer v-model:open="open"
         class="custom-class"
          root-class-name="root-class-name"
          :root-style="{ color: 'blue' }"
          style="color: red" 
          :title="null" 
          :placement="locale === 'ar' ? 'left' : 'right'"
          :width="modalWidth" 
          :body-style="{ paddingBottom: '80px' }"
          @close="hideForm">


          <template #title>
                <div class="drawer-header-ar" v-if="(locale === 'ar') && (reactiveData.action=='add')">
                    <span>{{ t('Pos.AddVariantDrawer') }}</span>
                </div>
                <div class="drawer-header-all" v-if="(locale !== 'ar') && (reactiveData.action=='add')">
                    <span>{{ t('Pos.AddVariantDrawer') }}</span>
                </div>

                <div class="drawer-header-ar" v-if="(locale === 'ar') && (reactiveData.action=='update')">
                    <span>{{ t('Pos.UpdateVariantDrawer') }}</span>
                </div>
                <div class="drawer-header-all" v-if="(locale !== 'ar') && (reactiveData.action=='update')">
                    <span>{{ t('Pos.UpdateVariantDrawer') }}</span>
                </div>
          </template>
    
            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item name="name">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Name') }}</span>
                            </template>
                            <a-input v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item name="value">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Value') }}</span>
                            </template>
                            <a-input v-model:value="form.value" :placeholder="t('Pos.Value')" class="input-form" />
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="24">
                        <a-col :span="24">
                            <a-form-item name="active">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Products') }}</span>
                            </template>
                          
                                  <a-select v-model:value="form.active" placeholder="Choose your product" class="input-form" show-search :option-filter-prop="'label'">
                              <a-select-option v-for="product in reactiveData2.data" :key="product.id" :value="product.id" :label="product.name">
                                {{ product.name }}
                              </a-select-option>
                            </a-select>



                            </a-form-item>
                        </a-col>
                </a-row>



            </a-form>

            <template #footer>
              <div :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-space v-if="reactiveData.action == 'update'">
                    <a-button type="primary" @click="onSubmitUpdate(form.id)">{{ t('Pos.Update') }}</a-button>
                    <a-button type="primary" danger @click="hideForm">{{ t('Pos.Cancel') }}</a-button>
                </a-space>
                <a-space v-else>
                    <a-button type="primary" @click="onSubmitAdd">{{ t('Pos.Add') }}</a-button>
                    <a-button type="primary" danger @click="hideForm">{{ t('Pos.Cancel') }}</a-button>
                </a-space>
              </div>
            </template>

        </a-drawer>

        <a-drawer v-model:open="openDisplay"
          class="custom-class"
          root-class-name="root-class-name" 
          :root-style="{ color: 'blue' }"
          style="color: red" 
          :title="null"
          :placement="locale === 'ar' ? 'left' : 'right'"
          :width="modalWidth"
          :body-style="{ paddingBottom: '80px' }"
          @close="hideForm" 
          @after-open-change="afterOpenShowChange" >

        <template #title>
          <div class="drawer-header-ar" v-if="locale === 'ar'">
              <span>{{ t('Pos.DetailsVariantDrawer') }}</span>
          </div>  
          <div class="drawer-header-all" v-if="locale !== 'ar'">
              <span>{{ t('Pos.DetailsVariantDrawer') }}</span>
          </div>      
        </template>

      
            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

<template #expandIcon="{ isActive }">
      <CaretRightOutlined :rotate="isActive ? 90 : 0"  class="caret-icon"/>
    </template>

      <template #header>
        <div class="custom-header">{{t('Pos.GeneralInformations')}}</div>
      </template>
     


      <a-row :gutter="16">

        <a-col :span="12">
          <p><span class="info-title"  placeholder="name">{{t('Pos.Name')}} :</span> {{form.name}} </p>
        </a-col>

        <a-col :span="12">
            <p><span class="info-title"  placeholder="value">{{t('Pos.Value')}} :</span> {{form.value}} </p>
        </a-col>

       

      </a-row>


</a-form>

            <template #footer>
            </template>

        </a-drawer>


    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw , computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    SearchOutlined
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { variantService , productService } from '@/_services'
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';


export default defineComponent({
    name: 'indexVariant',
    components: {
        EyeOutlined,
        DeleteOutlined,
        EditOutlined,
        LbrxLoaderSpinner,
        SearchOutlined
    },
    setup() {
      const modalWidth = ref(720);
      const { locale } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);
        const loading = ref(false);

        const { t } = useI18n();
        const columns = [
            {
                title: t('Pos.Name'),
                key: 'name',
                dataIndex: 'name',
                customFilterDropdown: true,
        onFilter: (value: string, record: any) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector('.ant-table-filter-dropdown input');

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
            },
            {
                title: t('Pos.Value'),
                key: 'value',
                dataIndex: 'value',
            },
            {
                title: t('Pos.Actions'),
                key: 'actions',
                dataIndex: 'actions',
            }

        ];

        //form  
        const formRef = ref();
        const visible = ref<boolean>(false);

        const reactiveData = reactive({
            data: [] as any[],
            action: '',
            totalPages:0,
            searchText:'',
            searchedColumn:''

        });

        const reactiveData2 = reactive({
            data: [] as any[],
            action: '',
            totalPages:0,
            searchText:'',
            searchedColumn:''

        });


        const form = reactive({
            id: '',
            name: '',
            value: '',
            active:''
        });

        const resetForm = () => {
            form.id = '';
            form.name = '';
            form.value = '';
        }
        const perPage = ref<number>(1);
        const currentPage = ref<number>(1);
        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t('Pos.nameVerification') }],
            active: [{ required: true, message:  t('Pos.ProductVerification') }],
            value: [{ required: true, message: t('Pos.ValueVerification'), pattern: new RegExp(/^[0-9]{1,2}([,.][0-9]{1,2})?$/) }]

        };

        const formatDate = (dateString: string) => {
            return dayjs(dateString);
        }

        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
        }
        

        
        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;
            loading.value = true;

            console.log('per page', perPage.value);
            console.log('page', currentPage.value)

            variantService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
       
                console.log('data', response.data)
                reactiveData.data=response.data;
                reactiveData.totalPages =response.meta.total

            }).catch((error)=>{
                console.log("error api : get products", error);
            })
            .finally(()=>{
                loading.value = false;
            });
        }

        //drawer

        const open = ref<boolean>(false);

        const showAddDrawer = (state: boolean) => {
          updateModalWidth();
          open.value = state;
          reactiveData.action = 'add';
          resetForm();
        };

        const showDrawer = (record: any) => {
          updateModalWidth();
          open.value = true;
          getVariantById(record.id);
          reactiveData.action = 'update';
        };

        const openAdd = ref<boolean>(false);


        const showDisplayDrawer = (record: any) => {
          updateModalWidth();
          openDisplay.value = true;
          // getVariantById(record.id);
          //resetForm();
          reactiveData.action = 'view';

          //state.action='view';
          openDisplay.value = true;
          form.name = record.name;
          form.value = record.value;
          form.id = record.id;
          

        };


        const showSuccessNotification = (message: string) => {
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style: {
                    backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        const getVariant = () => {
            loading.value = true;
            variantService.getAllWithPagination(currentPage.value, perPage.value)
                .then((res) => {
                    reactiveData.data = res.data;
                    reactiveData.totalPages =res.meta.total

                })
                .catch((error)=>{
        console.log("error api : get variants", error);
      })
      .finally(()=>{
        loading.value = false;
      });
    }


    



    const getProducts = () => {
            loading.value = true;
            productService.getAll()
                .then((res) => {
                    reactiveData2.data = res.data;

                })
                .catch((error)=>{
        console.log("error api : get variants", error);
      })
      .finally(()=>{
        loading.value = false;
      });
    }


        const getVariantById = (id: string) => {
            variantService.show(id)
                .then(res => {
                    form.name = res.data.name;
                    form.value = res.data.value;
                    form.id = res.data.id;
                })
                .catch(error => {
                    console.error('Error fetching categories:', error);
                });
        }

        const openDisplay = ref<boolean>(false);
          const afterOpenShowChange = (bool: boolean) => {
          console.log('open', bool);
  };

        const onSubmitAdd = () => {
            formRef.value
                .validate()
                .then(() => {
                    addVariant();
                })
                .catch((error: any) => {
                    console.log('error', error);
                });
        };

        const hideForm = (bool: boolean) => {
            open.value = false;
            formRef.value.resetFields();
        };

        const onSubmitUpdate = (id: string) => {
            formRef.value
                .validate()
                .then(() => {
                    updateVariant(id);
                })
                .catch((error: any) => {
                    console.log('error', error);
                });
        };

        const addVariant = () => {
            let payload = {
                name: form.name,
                value: form.value,
                product_id: form.active
            }
            loading.value = true;
            variantService.create(payload)
                .then((res) => {
                    reactiveData.data.push(res.data);
                    showSuccessNotification(t('Pos.AddVariant'))
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    open.value = false;
                    loading.value = false;
                })
        }

        const updateVariant = (id: string) => {
            let payload = {
                name: form.name,
                value: form.value,
                product_id: form.active
            }
            loading.value = true;

            variantService.update(id, payload).then((res) => {
                reactiveData.data = reactiveData.data.map(el => el.id !== id ? el : res.data);
                showSuccessNotification(t('Pos.UpdateVariant'))
              }).catch((err) => {
                console.log(err)
            }).finally(() => {
                open.value = false;
                loading.value = false;
            })
        }

        const deleteVariant = (id: string) => {
            loading.value = true;
            variantService.delete(id).then((res) => {
                reactiveData.data = reactiveData.data.filter(e => e.id !== id);
                showSuccessNotification(t('Pos.DeleteVariant'));
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                console.log('finish')
                loading.value = false;
            })
        };


        const handleSearch = (selectedKeys: any[], confirm: () => void, dataIndex: string) => {
    confirm();
    getVariantsForFilter(selectedKeys[0]); 
      reactiveData.searchedColumn = dataIndex;
    };


    const getVariantsForFilter = (searchText : string) => {
            loading.value = true;

// Adjust this part according to your API call
variantService.searchWithoutPagination("name", searchText)
  .then((res) => {
    reactiveData.data = res.data;
    reactiveData.totalPages = res.meta.total;
  })
  .catch((error) => {
    console.log("error api : get warehouses", error);
  })
  .finally(() => {
    loading.value = false;
  });
    };

    const textDirection = computed(() => {
      return locale.value === 'ar' ? 'rtl' :'ltr';}
    );


    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      reactiveData.searchText = '';
    };



        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.DeleteVariant'),
                text: t('Pos.TextDeleteCustVariantRole'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t('Pos.Delete'),
                cancelButtonColor: "#d33",
                cancelButtonText: t('Pos.Cancel'),

                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteVariant(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };
        const router = useRouter();
         const navigatePage = (link: string) => {
      router.push(link); 
    };

    
    const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };

        onMounted(() => {
            getVariant();
            getProducts();
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
        });

   

        return {
          updateModalWidth,
          modalWidth,
          locale,
          textDirection,

            searchInput,
            handleSearch,
            handleReset,
            loading,
            currentPage,
            perPage,
            t,
            rules,
            columns,
            formRef,
            form,
            formatDisplayDate,
            reactiveData,
            showAddDrawer,
            showDrawer,
            open,
            formatDate,
            onSubmitUpdate,
            onSubmitAdd,
            showDeletePopup,
            visible,
            hideForm,
            navigatePage,
            afterOpenShowChange,
            showDisplayDrawer,
            openDisplay,
            handlePageChange,
            reactiveData2,

        }
    }

})
</script>

<style scoped>
.add-btn {
    color: #fc8019;
    border: 2px solid #fc8019;
    box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}

.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.date-picker {
    width: 100%
}

.test-btn {
    color: #bfffca8a;
}

.breadcrumb-icon{
  cursor: pointer;
}

.view-btn{
    color: #fc8019;
    border-color: #fc8019;
    background: #fafafa;
  }

  .info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
  }

  .pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.is-rtl {
  direction:rtl;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawer-header-ar {
        padding-right: 2%;
        display: flex ;
        justify-content :end ;
        align-items: center ;
}
.drawer-header-all {
        display: flex ;
        justify-content :start ;
        align-items: center ;
}

  .is-rtl {
    direction:rtl;
  }
  
  .paginationArrows {
    direction: ltr
  }

  .table-container {
    overflow-x: auto;
  }

  @media (max-width: 768px) {
    .pagination {
      flex-direction: column;
      align-items: flex-start;
    }

    .select-input {
      width: 100%;
      margin-top: 10px;
    }
  }

</style>