<template>
  <a-config-provider
    :theme="{
      token: {
          colorPrimary: '#008fc9',
          fontFamily: 'Exo 2'
      },
    }"
    ></a-config-provider>
  <router-view> </router-view>
</template>

<style>
  @import './assets/css/main.scss';

  #app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }

  html, body, #app {
    margin: 0;
    padding: 0;
    height: 100%; 
  }
</style>
