<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb style="margin: 16px 0" :class="{ 'is-rtl': textDirection==='rtl'}">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{t('Pos.Dashboard')}}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.Warehouse')}}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>


  <div class="btn-container">

    <a-row :gutter="16">
      <a-col :xs="{ span: 24}" :sm="{ span: 12}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }" class="action-btn-container" >
        <a-button class="add-btn" @click="showAddDrawer(true)">
          {{t('Pos.Add')}}
          <template #icon>
            <ShopOutlined />
          </template>
        </a-button>
      </a-col>

      <a-col :xs="{ span: 24}" :sm="{ span: 12}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 12 }" :xxl="{ span: 12 }"   class="action-btn-container">
        <a-button class="add-btn" @click="showTransferModal()">
         {{t('Pos.Transfer')}}
          <template #icon>
            <SwapOutlined />
          </template>
        </a-button>
      </a-col>

    </a-row>

    
    
  </div>


  <div>

    <a-table class="table-container" :columns="columns" :data-source="state.data" :pagination="false">

      <template #emptyText>
        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
              <a-space>
                <a-tooltip>
                    <template #title>{{t('Pos.View')}}</template>
                    <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                    <template #icon>
                        <EyeOutlined/>
                    </template>
                    </a-button>
                </a-tooltip>

                <a-tooltip>
                  <template #title>{{t('Pos.Edit')}}</template>

                  <a-button type="primary" @click="() => showDrawer(record)">
                    <template #icon>
                      <EditOutlined />
                    </template>
                  </a-button>

                </a-tooltip>

              <a-tooltip>
                <template #title>{{t('Pos.Delete')}}</template>
                <a-button type="primary" danger @click="showDeletePopup(record)">
                  <template #icon>
                    <DeleteOutlined />
                  </template>
                </a-button>
              </a-tooltip>

        

              <a-tooltip>
                <template #title>{{t('Pos.ViewStock')}}</template>
                <a-button class="variant-btn"  @click="viewStock(record)" >
                  <template #icon>
                    <InboxOutlined />
                  </template>
                </a-button>
              </a-tooltip>


          </a-space>
        </template>


   

      </template>


      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder=" `${column.title}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
            
            {{t('Pos.Search')}}
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            {{t('Pos.Reset')}}
          </a-button>
        </div>
      </template>


      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>


    </a-table>

    <div class="pagination">
      <a-pagination v-model:current="currentPage" class="paginationArrows" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage"/>
    </div>
    
    <a-drawer v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red" :title="null" 
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth 
      :body-style="{ paddingBottom: '80px' }"
     @close="close()"
    > 
    <template #title>
                <div class="drawer-header-ar" v-if="(locale === 'ar') && (state.action=='add')">
                    <span>{{ t('Pos.AddWarehouseDrawer') }}</span>
                </div>
                <div class="drawer-header-all" v-if="(locale !== 'ar') && (state.action=='add')">
                    <span>{{ t('Pos.AddWarehouseDrawer') }}</span>
                </div>

                <div class="drawer-header-ar" v-if="(locale === 'ar') && (state.action=='update')">
                    <span>{{ t('Pos.UpdateWarehouseDrawer') }}</span>
                </div>
                <div class="drawer-header-all" v-if="(locale !== 'ar') && (state.action=='update')">
                    <span>{{ t('Pos.UpdateWarehouseDrawer') }}</span>
                </div>
    </template>



      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

        <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item name="name">
                <template #label>
                  <span class="label-form">{{ t('Pos.Name') }}</span>
                </template>
                <a-input v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form" />
              </a-form-item>
            </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="location">
              <template #label>
                <span class="label-form">{{ t('Pos.Location') }}</span>
              </template>
              <a-input v-model:value="form.location" :placeholder="t('Pos.Location')" class="input-form" :disabled="state.action !== 'add' && state.action !== 'update'" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <div :class="{ 'is-rtl': textDirection==='rtl'}">
          <a-space v-if="state.action == 'update'">
            <a-button type="primary" @click="updateWarhouse(form.id)">{{ t('Pos.Update') }}</a-button>
            <a-button type="primary" danger>{{ t('Pos.Cancel') }}</a-button>
          </a-space>
          <a-space v-else-if="state.action == 'add'">
            <a-button type="primary" @click="createWarhouse">{{ t('Pos.Add') }}</a-button>
            <a-button type="primary" danger>{{ t('Pos.Cancel') }}</a-button>
          </a-space>

          <a-space v-else>
            <a-button type="primary" danger>{{ t('Pos.Cancel') }}</a-button>
          </a-space>
        </div>

      </template>

    </a-drawer>


    <!-- view interface -->
    <a-drawer
      v-model:open="openDisplay"
      class="custom-class"
      root-class-name="root-class-name"
     :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth
      @after-open-change="afterOpenShowChange"
    >

      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
            <span>{{ t('Pos.DetailsWarehouseDrawer') }}</span>
        </div>  
        <div class="drawer-header-all" v-if="locale !== 'ar'">
            <span>{{ t('Pos.DetailsWarehouseDrawer') }}</span>
        </div>      
      </template>

    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row :gutter="16">
        <a-col :span="24">
          <p><span class="info-title">{{ t('Pos.Name') }} :</span> {{form.name}}</p>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24">
          <p><span class="info-title">{{ t('Pos.Location') }} :</span> {{(form.location)}}</p>
        </a-col>
      </a-row>
    </a-form>
    </a-drawer>

    <!-- modal transfer -->

    <a-modal v-model:open="openTransferModal" @cancel="resetStockItem" >

      <template #footer>
        <a-button key="submit" type="primary"  @click="handleTransferOk">{{ t('Pos.Confirm') }}</a-button>
        <a-button key="back" @click="handleCancel">{{ t('Pos.Cancel') }}</a-button>
      </template>

      <template #title>
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{ t('Pos.TransferStockItem') }}</p>
      </template>

      <a-form  ref="formStockRef" :model="addStockForm" :rules="stockRules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="sender_stock">
              <template #label>
                <span class="label-form">{{ t('Pos.From') }}</span>
              </template>

              <a-select v-model:value="addStockForm.sender_stock" show-search :option-filter-prop="'label'"   @select="selectedSender">
                <template #notFoundContent>
                  <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                </template>

                <a-select-option v-for="item in state.warehouseTarget" :label="item.name" :key="item.id" :value="item.id" :selected="item.id === addStockForm.sender_stock ">{{ item.name }}</a-select-option>
              </a-select>

            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="reciever_stock">
              <template #label>
                <span class="label-form">{{ t('Pos.To') }}</span>
              </template>
              <a-select v-model:value="addStockForm.reciever_stock" show-search :option-filter-prop="'label'" :disabled="!addStockForm.sender_stock">
                <template #notFoundContent>
                  <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                </template>
                <a-select-option v-for="item in state.warehouses" :label="item.name" :key="item.id" :value="item.id" :selected="item.id === addStockForm.reciever_stock ">{{ item.name }}</a-select-option>
              </a-select>

            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">

            <a-form-item  name="item_type">
              <template #label>
                  <span class="label-form">{{ t('Pos.Typeofmerchandise') }}</span>
              </template>
              <a-select v-model:value="addStockForm.item_type" show-search  :option-filter-prop="'label'" @change="handleTypeChange" :disabled="!addStockForm.sender_stock || !addStockForm.reciever_stock">
                <template #notFoundContent>
                  <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                </template>
                <a-select-option value="product" :label="t('Pos.Product')">{{ t('Pos.Product') }}</a-select-option>
                <a-select-option value="ingredient" :label="t('Pos.Ingredient')">{{ t('Pos.Ingredient') }}</a-select-option>
              </a-select>
            </a-form-item>

          </a-col>

          <a-col :span="12" >

            <a-form-item  name="items">
              <template #label>
                <span class="label-form">{{t('Pos.Items')}}</span>
              </template>
              <a-select
                v-model:value="addStockForm.items"
                mode="multiple"
                style="width: 100%"
                :placeholder="t('Pos.Item')"
                :options="options"
                @change="handleMultiChange"
                show-search
                :option-filter-prop="'label'"
                :disabled="!addStockForm.item_type"

              >
                <template #notFoundContent>
                  <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                </template>
              </a-select>
            </a-form-item>

          </a-col>
        </a-row>

        <a-row v-for="item in state.qtArray" :key="item" :span="12">

          <a-col :span="9">
            <span class="label-form">{{item.item_name}}</span>
          </a-col>

          <a-col :span="15">
            <a-form-item has-feedback :validate-status="getValidatingStatus(item.item_id)" :help="getValidatingHelp(item.item_id)" >
              <a-input :id="'item' + item.item_id" :placeholder="t('Pos.Quantity')" class="input-form" @blur="validateItemQuantity(item.item_id, item.item_quantity)"/>
            </a-form-item>
          </a-col>

        </a-row>

        

        
      </a-form>
      
      
    </a-modal>

  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted,computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import { useI18n } from 'vue-i18n';
import { warehouseService, productService, ingredientsService, invoiceService, supplierService } from "@/_services";
import Swal from 'sweetalert2';
import { notification, Table } from 'ant-design-vue';
import { loadRouteLocation, useRouter } from 'vue-router';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import { Empty } from 'ant-design-vue';


import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  ShopOutlined,
  EditOutlined,
  InboxOutlined,
  SwapOutlined,
} from '@ant-design/icons-vue';
export default defineComponent({
  name: 'indexWarhouse',
  components: {
    EyeOutlined,
    DeleteOutlined,
    SearchOutlined,
    ShopOutlined,
    EditOutlined,
    InboxOutlined,
    SwapOutlined,
    LbrxLoaderSpinner,
   
    
  },


  setup() {
    const modalWidth = ref(720);
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    const { t } = useI18n();
    const { locale } = useI18n();
    const loading = ref(false);
    const router = useRouter();
    const navigatePage = (link: string) => {
      router.push(link); 
    };
   
    const open = ref<boolean>(false);
    const openAdd = ref<boolean>(false);
    const addFormRef = ref();
    const formRef = ref();

    const formStockRef = ref();

    const fromInvoiceRef = ref();


    const state = reactive({
      searchText: '',
      searchedColumn: '',
      data: [] as any[],
      stockables:[] as any[],
      variants:[] as any[],
      warehouses:[] as any[],
      warehouseTarget:[] as any[],
      target_warehouse_id:'',
      itemQte:0,
      action:'',
      totalPages:0,
      qtArray:[] as any[],
      invoices:[] as any[],
      suppliers:[] as any[],
      qtArrayInvoice:[] as any[],
      warehousesInvoice:[] as any[],
    });



    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);
    const rules: Record<string, Rule[]> = {
      name: [{ required: true, message: t('Pos.RequiredField')}],
      location: [{ required: true, message: t('Pos.RequiredField') }],
    };

    const stockRules: Record<string, Rule[]> = {
      sender_stock: [{ required: true, message: t('Pos.RequiredField') }],
      reciever_stock: [{ required: true, message: t('Pos.RequiredField') }],
      item_type: [{ required: true, message: t('Pos.RequiredField') }],
      items: [{ required: true, message: t('Pos.RequiredField')}],
      
      quantity: [
        { required: true, message: t('Pos.RequiredField') },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t('Pos.numericField'));
          }
        }
      ],

    };

    const invoiceRules: Record<string, Rule[]> = {
      supplier_id: [{ required: true,message:t('Pos.RequiredField')}],
      amount: [
          { required: true, message:  t('Pos.RequiredField') },
          { 
          validator: (_, value) => {
              if (!isNaN(value)) {
              return Promise.resolve();
              }
              return Promise.reject( t('Pos.numericField'));
          }
          }
      ],
      invoice_number: [{ required: true,message:t('Pos.RequiredField')}],
      purchase_date: [{ required: true,message:t('Pos.RequiredField')}],
      warehouse_id: [{ required: true,message:t('Pos.RequiredField')}],
      purchase_type: [{ required: true,message:t('Pos.RequiredField')}],


    };

    const form = reactive({
      id: '',
      name: '',
      location: ''
    });

    const formInvoice = reactive({
      id:'',
      supplier_id:'',
      invoice_number:'',
      amount:'',
      purchase_date:'',
      warehouse_id:'',
      purchase_type:'',
    });


 

    const addStockForm = reactive({
      id: '',
      sender_stock: '',
      reciever_stock: '',
      item_type: '',
      item_id:'',
      item_name:'',
      quantity:'',
      items: [] as any,
     
    });


    const searchInput = ref<HTMLInputElement | null>(null);

    const columns = [

     
      {
        title: t('Pos.Name'),
        key: 'name',
        dataIndex: 'name',
        customFilterDropdown: true,
        onFilter: (value: string, record: any) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector('.ant-table-filter-dropdown input');

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
      },
      {
        title: t('Pos.Location'),
        key: 'location',
        dataIndex: 'location',
        customFilterDropdown: true,
        onFilter: (value: string, record: any) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector('.ant-table-filter-dropdown input');

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
      },
      {
        title: t('Pos.Actions'),
        dataIndex: 'actions',
        key: 'actions',
      }

    ];

    const textDirection = computed(() => {
      return locale.value === 'ar' ? 'rtl' :'ltr';}
    );

    const handlePageChange = (newPage:any) => {
      currentPage.value = newPage;
      loading.value = true;

      console.log('per page', perPage.value);
      console.log('page', currentPage.value)

      warehouseService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
       
        console.log('data', response.data)
        state.data=response.data;
        state.totalPages=response.meta.total;
      }).catch((error)=>{
        console.log("error api : get products", error);
      })
      .finally(()=>{
        loading.value = false;
      });
    }



    const onSubmitAdd = () => {
      addFormRef.value
        .validate()
        .then(() => {
          createWarhouse();
        })
        .catch((error: any) => {
          console.log('error', error);
        });
    };

    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: '80px',
        style: {
          backgroundColor: '#bfffca8a',
          color: 'white'
        }
      });
    };


    const updateWarhouse = (id: string) => {
      
      let payload = {
        name: form.name,
        location: form.location,
      }
      loading.value=true;
      warehouseService.update(id, payload).then((res) => {
        state.data = state.data.map(el => el.id !== id ? el : res.data);
        showSuccessNotification(t('Pos.UpdateWarehouse'))
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        loading.value=false;
        open.value = false;
      })
    };

    const onSubmitUpdate = (id: string) => {
      formRef.value
        .validate()
        .then(() => {
          updateWarhouse(id);
        })
        .catch((error: any) => {
          console.log('error', error);
        });
    };

    const getAllWarhouses = () => {
      loading.value=true;
      warehouseService.getAllWithPagination(currentPage.value, perPage.value).then((res) => {
        state.data = res.data;
        state.totalPages=res.meta.total;
        loading.value=false;
      }).catch((err) => {
        console.log(err)
      })
    };

    const createWarhouse = () => {

     formRef.value.validate().then(()=>{

      let payload = {
        name: form.name,
        location: form.location,
      }
      loading.value=true;
      warehouseService.store(payload).then((res) => {
        state.data.push(res.data);
        showSuccessNotification(t('Pos.AddWarehouse'))

      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        loading.value=false;
        resetAdd();
        showAddDrawer(false)
      });
    

     }).catch((error:any)=>{
        console.log('error validation form', error);
     })

      

    };
     
    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };

    const showErrorNotification  = (message:string)=>{
      notification.error({
        message: message,
        duration: 3,
        top: '80px',
        style:{
        backgroundColor: '#FFBFC98A',
          color: 'white'
        }
      });
    };



    const deleteWarhouse = (id: string) => {
      loading.value=true;
      warehouseService.delete(id).then((res) => {
        state.data = state.data.filter(e => e.id !== id)
        showSuccessNotification(t('Pos.DeleteWarehouse'));
        loading.value=false;
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        console.log('finish')
      })
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: t('Pos.TitleDeleteWarehouse'),
        text: t('Pos.TextDeleteWarehouse'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t('Pos.Delete'),
        cancelButtonText: t('Pos.Cancel'),
        cancelButtonColor: "#d33",
        customClass: {
          popup: 'swal2-popup'
        }

      }).then((result) => {
        if (result.isConfirmed) {
          deleteWarhouse(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      })
    };

    const handleMoreActions = (record: any) => {
      console.log('More actions', record);
    };

    //drawer
    const afterOpenChange = (bool: boolean) => {
      console.log('open', bool);
    };

    const hideForm = (bool: boolean) => {
      open.value = false;
      formRef.value.resetFields();
    };

    const showDrawer = (record: any) => {
      updateModalWidth();
      state.action='update'
      console.log('record:', record);

      open.value = true;

      form.id = record.id;
      form.name = record.name;
      form.location = record.location;
    };

    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      state.action='view';
      openDisplay.value = true;
      form.id = record.id;
      form.name = record.name;
      form.location = record.location;
    };


    const showAddDrawer = (states: boolean) => {
      updateModalWidth();
      resetAdd();
      open.value = states;
      state.action = 'add';
        
    };

    const afterOpenAddChange = (bool: boolean) => {
      console.log('open', bool);
    };

    onMounted(() => {
      getAllWarhouses();
      searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
    });


    const openDisplay = ref<boolean>(false);
    const afterOpenShowChange = (bool: boolean) => {
    console.log('open', bool);
};

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    state.searchText = selectedKeys[0];
    state.searchedColumn = dataIndex;
  };

  const handleReset = (clearFilter: (arg: any) => void) => {
    clearFilter({ confirm: true });
    state.searchText = '';
  };

  const resetAdd = () => {
    form.name="";
    form.location="";

  }
  const goToStock=(id:string)=>{
    router.push(`/warehouse/${id}/stock`)

  }

  const goToProducts = (record: any) => {
    router.push(`/warehouse/${record.id}/products`)
  };

  const openAddItem = ref<boolean>(false);
  let warehouseId = ref();
  const showAddItemDrawer = (record:any) => {
    console.log('warehouse id :', record.id );
    openAddItem.value = true;
    warehouseId=record.id;
  };

    
  const afterOpenItemChange = (bool: boolean) => {
    console.log('open', bool);
  };


  const addStock=(record:any)=>{
    Swal.fire({
      title:  t('Pos.AddStock'),
      text:  t('Pos.AddStockDesc'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#fc8019",
      confirmButtonText: t('Pos.AddStock'),
      cancelButtonText: t('Pos.Cancel'),
      customClass: {
        popup: 'swal2-popup'
      }

    }).then((result) => {
      if (result.isConfirmed) {
        console.log('add product')


      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("cancel delete ");
      }
    })
  }

    const handleStockableChange=(selectedItem:any)=>{
      console.log('selected item', selectedItem);
      if(selectedItem==="product"){
        state.stockables=[];
        productService.getAll().then((response)=>{
          state.stockables=response.data;
           
        }).catch((error)=>{
          console.log('error get products api', error);
        });
      }else if(selectedItem==="ingredient"){
        state.stockables=[];
        ingredientsService.getAll().then((response)=>{
          state.stockables=response.data;
        }).catch((error)=>{
          console.log('error get ingredients api', error);
        });

      }

    }

    const selectedItem=(value:any, option:any)=>{
      console.log('selected stcokable id ', value);
      productService.show(value).then((res)=>{
        state.variants=res.data.variants;
        console.log('product varinats', state.variants);
      });
      
    }

    const viewStock=(record:any)=>{
      
      router.push(`/warehouse/${record.id}/stock`);
      localStorage.setItem('warehouseName', record.name);

    }

   
    const resetStockItem=()=>{
      formStockRef.value.resetFields();
      
      items.value=[];
      options.value=[];
      state.qtArray=[];

      validationStatus.value={};
      validationHelp.value={};

    }

    //modal 
    const openTransferModal = ref<boolean>(false);


    const showTransferModal = () => {
      getWarehouses();
      openTransferModal.value = true;
    };

    const handleCancel = () => {
      openTransferModal.value = false;
      resetStockItem();
    };

    

    const getWarehouses=()=>{
      
      warehouseService.getAll().then((res)=>{
        state.warehouseTarget=res.data;
        
      }).catch((error)=>{
        console.log('error getting warehouses', error);
      });
    }

    
    const selectedSender=(value:any, option:any)=>{
      state.target_warehouse_id=value;
      
      state.warehouses = state.warehouseTarget.filter(warehouse => warehouse.id !== value);
      if(state.warehouses.length===0){
        addStockForm.reciever_stock='';
      }
      // }else{
      //   //addStockForm.reciever_stock=state.warehouses[0].id;

      // }


    }

    const selectedReciever=(value:any, option:any)=>{
      addStockForm.sender_stock=state.warehouseTarget[0].id;
    }



    const handleTypeChange=(selectedItem:any)=>{

      if(selectedItem==="product"){
        state.qtArray=[];
        items.value=[];
        warehouseService.getStockItemsWithoutPagination("warehouse_id,stockable_type",state.target_warehouse_id+",product").then((res) => {
         
          options.value = res.data.map((item:any) => ({
            
            //value: item.stockable_id,
            value:item.id,
            label: item.stockable.name,
            quantity:item.quantity,

          }));
          //items.value=options.value[0];
          item_id.value=options.value[0].value;
        }).catch((error) => {
          console.error('Error fetching products:', error);
        });

        
      }else if(selectedItem==="ingredient"){
        state.qtArray=[];
        items.value=[];
        warehouseService.getStockItemsWithoutPagination("warehouse_id,stockable_type", state.target_warehouse_id+",ingredient").then((res) => {
          options.value = res.data.map((item:any) => ({
            //value: item.stockable_id,
            value:item.id,
            label: item.stockable.name,
            quantity:item.quantity,
          }));
          //items.value=options.value[0];
          item_id.value=options.value[0].value;
        }).catch((error) => {
          console.error('Error fetching ingredients:', error);
        });

      }

      /*
      if(selectedItem==="product"){
        state.stockables=[];
        addStockForm.item_id='',
        warehouseService.getStockItemsWithoutPagination("warehouse_id,stockable_type", state.target_warehouse_id+",product").then((response)=>{
          state.stockables=response.data; 
          // addStockForm.item_id=response.data[0].stockable.id;
          // addStockForm.item_name=response.data[0].name;
          state.itemQte=response.data[0].quantity;
        }).catch((error)=>{
        console.log('error get products api', error);
        });
      }else if(selectedItem==="ingredient"){
        state.stockables=[];
        addStockForm.item_id='',
        warehouseService.getStockItemsWithoutPagination("warehouse_id,stockable_type", state.target_warehouse_id+",ingredient").then((response)=>{
          state.stockables=response.data;
          // addStockForm.item_id=response.data[0].stockable.id;
          // addStockForm.item_name=response.data[0].name;
        }).catch((error)=>{
        console.log('error get ingredients api', error);
        });

      }*/

    }

    //validate quantity: 
    const validatingStatus = ref('');
    const validatingHelp = ref('');

    const selectedStockItem=(value:any, option:any)=>{
      const selectedItem = state.stockables.find(item => item.id === value);

      console.log('selected stock item', selectedItem);

      state.itemQte=selectedItem.quantity;
    }
    const validateQte = ref(false);

    function isNumber(value:any) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    }

    interface ValidationStatus {
      [key: string]: string;
    }

    interface ValidationHelp {
      [key: string]: string;
    }

    const validationStatus = ref<ValidationStatus>({});

    const validationHelp=ref<ValidationHelp>({});


    const getValidatingStatus=(id:any)=>{
      return validationStatus.value[id] || '';
    }

    const getValidatingHelp=(id:any)=>{
      return validationHelp.value[id] || '';
    }

   

    const validateItemQuantity=(id:any, quantity:any)=>{
      
      const txtInput = document.getElementById(`item${id}`) as HTMLInputElement;
     
      if(txtInput){
        if(txtInput.value ===""){
          validationStatus.value[id] = 'error';
          validationHelp.value[id] = t('Pos.RequiredField');

         
        }else if(!isNumber(txtInput.value)){
          validationStatus.value[id] = 'error';
          validationHelp.value[id] = t('Pos.numericField');
          validateQte.value=true;
         
        }else if( Number.parseInt(txtInput.value) > Number.parseInt(quantity) ){
          console.log('txt input', Number.parseInt(txtInput.value), 'quantity', quantity);
          validationStatus.value[id] = 'error';
          validationHelp.value[id] = t('Pos.QuantityAboveError');
          validateQte.value=true;
          
          
        }else if( Number.parseInt(txtInput.value) <= Number.parseInt(quantity) ){
          console.log('success');
          validationStatus.value[id] = 'success';
          validationHelp.value[id] = '';
          
        }
      }
    }

   

  

    //multiselect : 
    let items= ref<any[]>([]);
    let item_id=ref();
    const options=ref();
    

    const handleSelectItem=(value:any, option:any)=>{
      console.log('selected item', value, 'otpion', option);
    }

   
    const handleMultiChange = (value: any[]) => {
      items.value=value;
      addStockForm.items=value;
      console.log(`selected ${value}`);
      state.qtArray = state.qtArray.filter((item) => value.includes(item.item_id));
     
      if(value){
        value.forEach((itemId)=>{
          const selectedItem = options.value.find((option: any) => option.value === itemId);
           if (selectedItem && !state.qtArray.some((item) => item.item_id === itemId) ) {
              state.qtArray.push({
                
                item_id: itemId,
                item_name: selectedItem.label,
                item_quantity:selectedItem.quantity,
              });
            }
        })
      }

      console.log('qt array', state.qtArray);
         
    };
    
    const validatingStatusItems = ref('');
    const validatingHelpItems = ref('');

    const handleTransferOk = (e: MouseEvent) => {

      let quantities: string[]=[];
      let products: any[]=[];
      let hasErrors = false;
          

      state.qtArray.forEach((item)=>{


        const txtInput = document.getElementById(`item${item.item_id}`) as HTMLInputElement;
            
        if(txtInput.value ===""){
          validationStatus.value[`item${item.item_id}`] = 'error';
          validationHelp.value[`item${item.item_id}`] = t('Pos.RequiredField');
          hasErrors = true;
        
        }else if(!isNumber(txtInput.value)){
          validationStatus.value[`item${item.item_id}`] = 'error';
          validationHelp.value[`item${item.item_id}`] = t('Pos.numericField');
          hasErrors = true;
        
        }else if( Number.parseInt(txtInput.value) > Number.parseInt(item.item_quantity) ){
          console.log('txt input', Number.parseInt(txtInput.value), 'quantity', item.item_quantity);
          validationStatus.value[`item${item.item_id}`] = 'error';
          validationHelp.value[`item${item.item_id}`] = t('Pos.QuantityAboveError');
          hasErrors = true;
          
          
        }else if( Number.parseInt(txtInput.value) <= Number.parseInt(item.item_quantity) ){
          console.log('success in function');
          validationStatus.value[`item${item.item_id}`] = 'success';
          validationHelp.value[`item${item.item_id}`] = '';
          quantities.push(txtInput.value);
          products.push(item.item_id);
        }
            
        
      });





      if(!hasErrors){

        console.log('validateQTe', validateQte.value);
        console.log('hasErros', hasErrors);
        formStockRef.value
        .validate()
        .then(()=>{

        

        
          let payload;
          let id;
          if(state.qtArray.length>1){

            console.log('hey here');
          
            payload={
              target_warehouse_id:addStockForm.reciever_stock,
              quantity: quantities.join(','),
              stock_items_ids:products.join(','),
            }
          
          }else{
            const item=state.qtArray[0].item_id;
            const qt=document.getElementById(`item${item}`) as HTMLInputElement;
            payload={
              target_warehouse_id:addStockForm.reciever_stock,
              quantity:qt.value,
            }
            id=item;
          }

          

          console.log('payload', payload);

          loading.value=true;
          warehouseService.transfer(id, payload).then((res)=>{
            console.log('res', res);
            showSuccessNotification(t('Pos.transferSuccess'));
            loading.value=false;
          }).catch((error)=>{
            console.log('error transfer', error);
            showErrorNotification(t('Pos.ErrorTransferTxt'));
            loading.value=false;
          }).finally(()=>{
            // loading.value=false;
            openTransferModal.value = false;
            resetStockItem();
          });

        
        
        }).catch((error:any) => {
          console.error('Validation error:', error);
        });
      }else{
        console.log('error validation inside hasErros', hasErrors);
      }
      

    };

    const close =()=> {
      formRef.value.resetFields();
      open.value = false ;
    }
    
    return {
      textDirection,
      locale,
      t,
      loading,
      selectedStockItem,
      validatingStatus,
      validatingHelp,
      handleTypeChange,
      selectedSender,
      selectedReciever,
      openTransferModal,
      showTransferModal,
      handleTransferOk,
      addStock,
      goToProducts,
      afterOpenShowChange,
      openDisplay,
      currentPage,
      createWarhouse,
      showDisplayDrawer,
      navigatePage,
      columns,
      handleMoreActions,
      searchInput,
      open,
      afterOpenChange,
      afterOpenAddChange,
      showAddDrawer,
      openAdd,
      showDrawer,
      state,
      handleSearch,
      handleReset,
      form,
      addFormRef,
      formRef,
      rules,
      onSubmitAdd,
      onSubmitUpdate,
      resetAdd,
      showDeletePopup,
      updateWarhouse,
      hideForm,
      handlePageChange,
      perPage,
      openAddItem,
      showAddItemDrawer,
      afterOpenItemChange,
      formStockRef,
      stockRules,
      addStockForm,
      handleStockableChange,
      selectedItem,
      resetStockItem,
      viewStock,
      items,
      options,
      handleMultiChange,
      handleSelectItem,
      validateItemQuantity,
      getValidatingStatus,
      getValidatingHelp,
      handleCancel,
      validatingStatusItems,
      validatingHelpItems,
      simpleImage,
      close,
      updateModalWidth,
      modalWidth
    };
  },
  methods: {

  }

})
</script>

<style scoped>
.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.eye-icon {
  font-size: 24px;
  padding: 0;
}


.delete-btn {
  background: #ff4d4f;
}

/* drawer */
.btn-save {
  color: #1b6a2b;
  border: 2px solid #1b6a2b;
  box-shadow: inset 0 0 0 0 #1b6a2b;
}

.btn-save:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #1b6a2b;
}

.btn-save:active {
  transform: scale(0.9);
}



.btn-cancel {
  color: #ff4d4f;
  border: 2px solid #ff4d4f;
  box-shadow: inset 0 0 0 0 #ff4d4f;
}

.btn-cancel:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #ff4d4f;
}

.btn-cancel:active {
  transform: scale(0.9);
}


.drawer-btn {
  position: relative;
  display: inline-block;
  margin: 15px;
  padding: 7px 10px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 1px;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border-radius: 10px;
}


/* drawer */

.action-btn {
  font-family: inherit;
  font-size: 14px;
  color: white;
  padding: 0.5em 0.7em;
  padding-left: 0;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  margin-right: 5px;

}

.action-btn span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.action-btn .eye-icon {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
  transform: scale(0.7);
}

.action-btn:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.action-btn:hover .eye-icon {
  transform: translateX(1em) rotate(45deg) scale(0.8);
}

.action-btn:hover span {
  transform: translateX(3em);
}

.action-btn:active {
  transform: scale(0.95);
}

.drawer-footer {
  display: flex;
}
.input-form{
  color: black;
  background-color: white;
}


.red-title {
  text-align: right;
}

.custom-header{
    font-size: 16px; 
    font-weight: 600;
    line-height: 1.5; 
  }

.view-btn{
    color: #fc8019;
    border-color: #fc8019;
    background: #fafafa;
  }
.pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
  }

  .variant-btn{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
  }

  .variant-btn:hover{
    color: #3b8dffdf;
    border-color: #3b8dffdf;
    background: #fafafa;
  }

  .stock-btn{
    color: #0b390fdf;
    border-color: #0b390fdf;
    background: #fafafa;
  }

  .label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  .breadcrumb-icon{
    cursor: pointer;
  }


  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }

    to {
      transform: translateY(-0.1em);
    }
  }

  /* list transfer */

  .list-container{
    max-height:200px;
    overflow-y: auto;

    border: 1px solid #d9d9d9; 
    border-radius: 4px;
  }

  .add-btn{
    width: 100%;
  }

  .add-btn:hover{
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
  }

  .range-picker{
    width: 100% 
  }



  /* loader */
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  /* CSS for RTL */
.is-rtl {
  direction:rtl;
}

.drawer-header-ar {
        padding-right: 2%;
        display: flex ;
        justify-content :end ;
        align-items: center ;
}
.drawer-header-all {
        display: flex ;
        justify-content :start ;
        align-items: center ;
}

.paginationArrows{
  direction: ltr;
}

.table-container {
overflow-x: auto;
}

.action-btn-container{
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}


  @media (max-width: 768px) {
    .pagination {
      flex-direction: column;
      align-items: flex-start;
    }

    .select-input {
      width: 100%;
      margin-top: 10px;
    }
  }


</style>