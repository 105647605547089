<template>

  <div >
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{t('Pos.Dashboard')}}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.Categories')}}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>


  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer">
      {{ t('Pos.Add') }}
      <template #icon > 
          <AppstoreOutlined />
      </template>
    </a-button>
  </div>

  <div>
    <a-table class="table-container" :columns="columns" :data-source="state.data"  :pagination='false'>

      <template #emptyText>
        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
      </template>


      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <a-space>
            <a-tooltip>
              <template #title>{{ t('Pos.View') }}</template>
              <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                <template #icon>
                  <EyeOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
                <template #title>{{ t('Pos.Update') }}</template>

                <a-button type="primary" @click="() => showDrawer(record)">
                  <template #icon>
                    <EditOutlined />
                  </template>
                </a-button>

              </a-tooltip>


            <a-tooltip>
              <template #title>{{ t('Pos.Delete') }}</template>
              <a-button type="primary" danger @click="showDeletePopup(record)">
                <template #icon>
                  <DeleteOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>

      <template v-if="column.key === 'created_at' || column.key === 'updated_at'">
        {{ formatDisplayDate(record.created_at) }}
      </template>
    </template>

    <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
      <div style="padding: 8px">
        <a-input
          ref="searchInput"
          :placeholder="`${column.title}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
        /> 

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            {{ t('Pos.Search') }}
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            {{ t('Pos.Reset') }}
          </a-button>
      </div>
    </template>

    <template #customFilterIcon="{ filtered }">
      <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
    </template>
        
    </a-table>

    <div class="pagination">
      <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  @change="handlePageChange" :showSizeChanger=false />

      <a-select
        v-model:value="selectPagination"
        @change="handleSelectPaginationChange"
        v-if="state.totalPages>10"
      >
        <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
        <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
      </a-select>

    </div>


    <!-- update drawer -->
    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth
      @after-open-change="afterOpenChange"
    >

      <template #title>

        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateCategory')}}</p>

      </template>

      <a-form  ref="formRef" :model="form" :rules="rules" layout="vertical"  :class="{ 'is-rtl': textDirection==='rtl'}">
        <a-row :gutter="16">   
          <a-col :span="12">
            <a-form-item  name="name">
              <template #label>
                  <span class="label-form">{{ t('Pos.Name') }}</span>
              </template>
              <a-input  v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form"/>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item  name="display_rank">
              <template #label>
                  <span class="label-form">{{ t('Pos.DisplayRank') }}</span>
              </template>
              <a-input  v-model:value="form.display_rank" :placeholder=" t('Pos.DisplayRank')" class="input-form"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
            <a-col :span="12">
                <a-form-item  name="created_at">
                  <template #label>
                      <span class="label-form">{{ t('Pos.CreatedAt') }} </span>
                  </template>
                    <a-date-picker show-time :placeholder="t('Pos.CreatedAt')" class="date-picker" disabled :value="formatDate(form.created_at)"> -->
                    </a-date-picker>
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item  name="updated_at">

                    <template #label>
                      <span class="label-form">{{ t('Pos.UpdatedAt') }}</span>
                    </template>

                    <a-date-picker show-time :placeholder="t('Pos.UpdatedAt')" class="date-picker" disabled :value="formatDate(form.updated_at)">
                    </a-date-picker>
                
                </a-form-item>
            </a-col>
        </a-row>
        
        
        <a-row :gutter="16">
            <a-col :span="24">
                <a-form-item  name="description">

                <template #label>
                    <span class="label-form"> {{ t('Pos.Description') }}</span>
                </template>
                <a-textarea
                v-model:value="form.description"
                :rows="4"
                :placeholder="t('Pos.Description')"
                />
                </a-form-item>
            </a-col>
        </a-row>

      

        <template v-for="item in form.customFields" :key="item">
           
          <a-col v-if="item.type === 'text'" :span="12">
            <a-form-item
              :rules="[item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]"
            >
              <template #label>
                <span class="label-form">{{ transformUpperCase(item.name) }}</span>
              </template>
              <a-input class="custom-input" :id="'txtUpdate_' + item.id" v-model:value="item.value" />
            </a-form-item>
          </a-col>

            <a-col v-if=" item.type === 'textarea'" :span="12">
              <a-form-item
                :rules="[item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]"
                
              >
                <template #label>
                  <span class="label-form">{{ transformUpperCase(item.name) }}</span>
                </template>
                <a-textarea class="custom-input" v-model:value="item.value" :id="'areaUpdate_' + item.id"   :rows="4" />
              </a-form-item>
            </a-col>


            <a-col v-if=" item.type === 'number'" :span="12">
              <a-form-item
                :rules="[item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]"
              
              >
                <template #label>
                  <span class="label-form">{{ transformUpperCase(item.name) }}</span>
                </template>
                <a-input-number class="custom-input"  v-model:value="item.value" :id="'numberUpdate_' + item.id" />
              </a-form-item>
            </a-col>

            <a-col v-if=" item.type === 'date'" :span="12">
              <a-form-item
                :rules="[item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]"
               
              >
                <template #label>
                  <span class="label-form">{{ transformUpperCase(item.name) }}</span>
                </template>
                <a-date-picker show-time class="custom-input" v-model:value="item.value"  :id="'dateUpdate_' + item.id" />
              </a-form-item>
            </a-col>


            <a-col v-if=" item.type === 'select'" :span="12">
              <a-form-item
                :rules="[item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]"
               
              >
                <template #label>
                  <span class="label-form">{{ transformUpperCase(item.name) }}</span>
                </template>
                <a-select v-model:value="item.value" :id="'selectUpdate_' + item.id"  @change="handleSelectUpdate">
                  <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option">
                    <a-select-option :value="option">{{ option }}</a-select-option>
                  </template>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col v-if="item.type === 'checkbox'" :span="12">

              <a-form-item
                :rules="[item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]"
               
              >
                <template #label>
                  <span class="label-form">{{ transformUpperCase(item.name) }}</span>
                </template>

                <a-checkbox-group v-model:value="item.value" :id="'checkUpdate_' + item.id">
                  <template v-for="option in item.options.split(',')" :key="option.trim()">
                      <a-checkbox :value="option.trim()" style="line-height: 32px">{{ option.trim() }}</a-checkbox>
                  </template>
                </a-checkbox-group>

              </a-form-item>
            </a-col>


            <a-col v-if=" item.type === 'radio'" :span="12">
              <a-form-item
                :rules="[item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]"
               
              >
                <template #label>
                  <span class="label-form">{{ transformUpperCase(item.name) }}</span>
                </template>

                <a-radio-group :id="'radioUpdate_' + item.id" v-model:value="item.value">
                  <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option" >
                    <a-radio :value="option" style="line-height: 32px">{{ option }}</a-radio>
                  </template>
                </a-radio-group>

              </a-form-item>
            </a-col>


            
        </template>

    
    </a-form>
    <template #footer>
        <a-space>
          <a-button  type="primary" @click="updateCategory(form.id)">{{ t('Pos.Update') }}</a-button>
          <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
        </a-space>
    </template>

    </a-drawer>

    <!--display drawer -->
    <a-drawer
      v-model:open="openDisplay"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth
      @after-open-change="afterOpenShowChange"
    >

    <template #title>
      <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.CategoryInfo')}}</p>
    </template>

    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical"  :class="{ 'is-rtl': textDirection==='rtl'}">
      
      <a-row :gutter="16">

        <a-col :span="12">
          <p><span class="info-title"  :placeholder="t('Pos.Name')">{{t('Pos.Name')}} :</span> {{form.name}} </p>
        </a-col>
        <a-col :span="12">
          <p><span class="info-title"  :placeholder="t('Pos.Description')">{{t('Pos.Description')}} :</span> {{form.description}} </p>
        </a-col>

      </a-row>

      <a-row>

        <a-col :span="12" v-if="form.display_rank!==null">
          <p><span class="info-title">{{t('Pos.DisplayRank')}}:</span> {{form.display_rank}} </p>
        </a-col>

        <a-col :span="12">
            <p><span class="info-title">{{t('Pos.CreatedAt')}} :</span> {{formatDateDrawer(form.created_at)}} </p>
        </a-col>
      </a-row>

      <a-row v-if="form.customData!==null">
  
        <template v-for="item in form.customFields" :key="item.id">
         
          <a-col :span="12">
            <p><span class="info-title">{{item.name}} :</span> {{item.value}} </p>
          </a-col>  
          
        </template>
      </a-row>

        

  
    </a-form>
    <template #footer>
        <a-space>
          <a-button type="primary" danger @click="cancelUpdate">{{t('Pos.Cancel')}}</a-button>
        </a-space>
    </template>

    </a-drawer>


    <!-- add drawer -->
    <a-drawer
      v-model:open="openAdd"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth
      @close="resetFormAdd"
    >

      <template #title>
        <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddCategory')}}</p>
      </template>

      <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical"  :class="{ 'is-rtl': textDirection==='rtl'}">
        <a-row :gutter="16">   
          <a-col :span="12">
            <a-form-item  name="name">
              <template #label>
                <span class="label-form">{{ t('Pos.Name') }}</span>
              </template>
              <a-input  v-model:value="addForm.name" :placeholder="t('Pos.Name')" class="input-form"/>
            </a-form-item>
          </a-col >

          <a-col :span="12">
            <a-form-item  name="display_rank">
              <template #label>
                <span class="label-form">{{ t('Pos.DisplayRank') }}</span>
              </template>
              <a-input  v-model:value="addForm.display_rank" :placeholder=" t('Pos.DisplayRank')" class="input-form"/>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <a-form-item  name="description">
              <template #label>
                <span class="label-form">{{ t('Pos.Description') }}</span>
              </template>
              <a-input  v-model:value="addForm.description" :placeholder="t('Pos.Description')" class="input-form"/>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-if="state.customFields !== null" :gutter="16">
          <a-col v-for="item in state.customFields" :key="item" :span="12">
            <a-row>
             
              <a-form-item
                :name="'customData_' + item.id"
                v-if="item.type==='text'"
                class="dynamic-input"
                :validateStatus="getErrorStatus(item.id)"
                :help="getErrorText(item.id)"
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>
                <a-input class="custom-input" :id="'txt_' + item.id" @blur="handleError(item)"/>
                
              </a-form-item>
              
            </a-row>
            
            <a-row>
              
                <a-form-item
                  name="customData"
                  v-if="item.type==='textarea'"
                  class="dynamic-input"
                  :validateStatus="getErrorStatus(item.id)"
                  :help="getErrorText(item.id)"
                >

                  <template #label>
                    <span class="label-form">{{transformUpperCase(item.name)}}</span>
                  </template>
                  <a-textarea class="custom-input" :id="'area_' + item.id"   :rows="4" @blur="handleError(item)" />
                </a-form-item>
             
            </a-row>

            <a-row>
              
              <a-form-item
                name="customData"
                v-if="item.type==='number'"
                class="dynamic-input"
                :validateStatus="getErrorStatus(item.id)"
                :help="getErrorText(item.id)"
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>
                <a-input-number class="custom-input" :id="'number_' + item.id" @blur="handleError(item)" />
              </a-form-item>
              
            </a-row>

            <a-row>
             
              <a-form-item
                name="customData"
                v-if="item.type==='date'"
                class="dynamic-input"
                :validateStatus="getErrorStatus(item.id)"
                :help="getErrorText(item.id)"
              >
                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>


                <a-date-picker show-time class="custom-input" :id="'date_' + item.id"  @blur="handleError(item)"/>
              </a-form-item>
              
            </a-row>
           
            <a-row>
              
              <a-form-item
                name="customData"
                v-if="item.type==='select'"
                class="dynamic-input"
                :validateStatus="getErrorStatus(item.id)"
                :help="getErrorText(item.id)"
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>

                <a-select
                  
                  :id="'select_' + item.id" @change="handleSelectChange"
                >
                  <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option" >
                    <a-select-option  :value="option" @blur="handleError(item)">{{ option }}</a-select-option>
                  </template>
                </a-select>

              </a-form-item>
              
            </a-row>
           
            <a-row>
              
              <a-form-item 
                name="customData"
                :rules="[item.is_mandatory === 1 ? { required: true, message: t('Pos.RequiredField') } : {}]"
                v-if="item.type==='checkbox'"
                :validateStatus="getErrorStatus(item.id)"
                :help="getErrorText(item.id)"
               
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>

                <a-checkbox-group  :id="'check_' + item.id">
                  <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option" >
                    <a-checkbox :value="option" style="line-height: 32px"  @blur="handleError(item)">{{ option }}</a-checkbox>
                  </template>
                </a-checkbox-group>
              </a-form-item>
              
            </a-row>

            <a-row>
              
              <a-form-item 
                name="customData"
                :rules="[item.is_mandatory === 1 ? { required: true, message: t('Pos.RequiredField') } : {}]"
                v-if="item.type==='radio'"
                :validateStatus="getErrorStatus(item.id)"
                :help="getErrorText(item.id)"
              >

                <template #label>
                  <span class="label-form">{{transformUpperCase(item.name)}}</span>
                </template>

                <a-radio-group :id="'radio_' + item.id">
                  <template v-for="option in item.options.split(',').filter(option => option.trim() !== '')" :key="option" >
                    <a-radio :value="option" style="line-height: 32px" @blur="handleError(item)">{{ option }}</a-radio>
                  </template>
                </a-radio-group>
              </a-form-item>
             
            </a-row>

          </a-col>
        </a-row>
      </a-form>
      <template #footer>
        <a-space  :class="{ 'is-rtl': textDirection==='rtl'}">
          <a-button  type="primary" @click="addCategory">{{ t('Pos.Add') }}</a-button>
          <a-button type="primary" danger  @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
        </a-space>
      </template>
    </a-drawer>


    



  </div>
 
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  EyeOutlined,
  DeleteOutlined,
  AppstoreOutlined,
  SearchOutlined,
  EditOutlined
} from '@ant-design/icons-vue';
import { categoryService, customFieldsService } from '@/_services'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { notification } from 'ant-design-vue';
import { useRouter , useRoute } from 'vue-router';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import { useI18n } from 'vue-i18n';
import 'dayjs/locale/fr'; 
import 'dayjs/locale/ar'; 
import { Empty } from 'ant-design-vue';


export default defineComponent({
  name: 'indexCategory',
  components: {
    EyeOutlined,
    DeleteOutlined,
    AppstoreOutlined,
    SearchOutlined,
    LbrxLoaderSpinner,
    EditOutlined
  },
  setup() {
    const modalWidth = ref(720);
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    const { t } = useI18n();
    dayjs.extend(relativeTime);
    const loading = ref(false);

    const router = useRouter();

    const navigatePage = (link: string) => {
      router.push(link); 
    };

    const route=useRoute();
    const path = route.path;

    interface CustomFieldValues {
      [key: string]: { id: string; value: any };
    }
    const customFieldValues: CustomFieldValues = {};

    interface Errors {
      [key: string]: boolean;
    }


    const state = reactive({
      searchText: '',
      searchedColumn: '',
      data: [] as any[],
      action:'', 
      totalPages:0,
      customFields: [] as any[],
      errors: {} as Errors,
      beginSearch:false,
      searchName:'',

    });
    
    const searchInput = ref<HTMLInputElement | null>(null);

    const columns = [
      {
        title: t('Pos.Name'),
        key: 'name',
        dataIndex: 'name',
        customFilterDropdown: true,
        
        onFilterDropdownOpenChange:  (visible: boolean) => {
          if (visible  && !searchInput.value) {
            setTimeout(() => {
            const input = document.querySelector('.ant-table-filter-dropdown input');

            if (input instanceof HTMLInputElement) {
              searchInput.value = input;
              searchInput.value.focus();
            }
            }, 100);
          }
        },
      },
      {
        title: t('Pos.Description'),
        key: 'description',
        dataIndex: 'description',
        defaultSortOrder: 'descend',
      },
      {
        title:t('Pos.CreatedAt'),
        key: 'created_at',
        dataIndex: 'created_at',

      },
      {
        title: t('Pos.Actions'),
        key: 'actions',
        dataIndex: 'actions',
      }
    ];


    //view/update drawer
    const open = ref<boolean>(false);

    const afterOpenChange = (bool: boolean) => {
      console.log('open', bool);
    };

    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      openDisplay.value = true;
      getCategoryById(record.id);
      
    };


    const showDrawer = (record: any) => {
      updateModalWidth();
      open.value = true;
      getCategoryById(record.id);
      getCustomFields();
    };

    //form 
    const formRef = ref();
    const addFormRef = ref();

    

    const form = reactive({
      id: '',
      name: '',
      description: '',
      customData:'',
      display_rank:'',
      customFields:'',
      created_at: '',
      updated_at: '',
      

    });

    const addForm = reactive({
      name: '',
      description: '',
      display_rank:'',
      created_at: '',
      updated_at: '',
    });

    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);

  

    const rules: Record<string, Rule[]> = {
      name: [{ required: true, message: t('Pos.RequiredField') }],
      display_rank:[
       
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject( t('Pos.numericField'));
          }
        }
      ]
      
    };

    const onShowSizeChange = (current: number, pageSize: number) => {
      console.log(current, pageSize);
      perPage.value=pageSize;

      loading.value=true;
      categoryService.getAllWithPagination(currentPage.value, perPage.value).then((res)=>{
        state.data=res.data;
      }).catch((err:any) => {
        console.log(err)
      }).finally(()=>{
        loading.value = false;
      });
    
    };

    //pagination

    const selectPagination = ref('10');

    const handleSelectPaginationChange=(selectedItem:any)=>{
      console.log('selected Per page:', selectedItem);
      perPage.value=Number.parseInt(selectedItem);
      currentPage.value=1;

      if(state.beginSearch===true){

         
        if(state.searchName!==''){
          loading.value = true;
          categoryService.filterWithPagination("name",state.searchName,currentPage.value.toString(),perPage.value).then((res)=>{
            console.log('res', res.data);
            state.data=res.data;
            state.totalPages=res.meta.total;
          }).catch((err:any) => {
            console.log(err)
          }).finally(()=>{
            loading.value = false;
          });

        }

      }else{

        loading.value=true;
        categoryService.getAllWithPagination(currentPage.value, perPage.value).then((res)=>{
          state.data=res.data;
          state.totalPages=res.meta.total;
        }).catch((err:any) => {
          console.log(err)
        }).finally(()=>{
          loading.value = false;
        });

      }

            
    }


        
    const handlePageChange = (newPage:any, pageSize:number) => {
      currentPage.value = newPage;
      perPage.value=pageSize;

      console.log('per page', perPage.value);
      console.log('page', currentPage.value);
      if(state.beginSearch===true){
        state.data=[];

        loading.value = true;
        categoryService.filterWithPagination("name",state.searchName,currentPage.value, perPage.value)
        .then((res)=>{
          state.data=res.data;
          state.totalPages=res.meta.total;
        }).catch((err:any) => {
          console.log(err);
        }).finally(()=>{
          loading.value = false;
        });

      }else{

        loading.value = true;
        categoryService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
        
          console.log('data', response.data)
          state.data=response.data;
        }).catch((error)=>{
          console.log("error api : get products", error);
        })
        .finally(()=>{
          loading.value = false;
        });

      }

      
    }


    const onSubmit = () => {

      formRef.value
        .validate()
        .then(() => {
          console.log('values', form, toRaw(form));
        })
        .catch((error: any) => {
          console.log('error', error);
        });

    };

    const resetFormAdd = () => {
      console.log("begin rest");
      addFormRef.value.resetFields();
    };

    const getCategories = () => {
      loading.value = true;
      categoryService.getAllWithPagination(currentPage.value, perPage.value)
        .then(response => {
          state.data = response.data;
          state.totalPages = response.meta.total;
          console.log("categories", response.data);
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        }).finally(()=>{
         loading.value = false;
        });
    }

    function removeLeadingSlash(str:string) {
      if (str.startsWith('/')) {
        return str.substring(1);
      } else {
        return str;
      }
    }

    const getCustomFields=()=>{
      customFieldsService.filter(removeLeadingSlash(path)).then((response)=>{
        state.customFields= response.data;
        console.log('custom fields', state.customFields);
      })
    }

    const getCategoryById = (id: string) => {
      categoryService.show(id)
        .then(res => {
          form.name = res.data.name;
          form.description = res.data.description;
          form.display_rank=res.data.display_rank;
          form.customData= JSON.parse(res.data.custom_data);
          form.customFields= res.data.custom_fields;
          form.created_at = res.data.created_at;
          form.updated_at = res.data.updated_at;
          
          form.id = res.data.id;
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        });
    }
    const selectedOption = ref('');
    const  handleSelectChange=(value:any) =>{
      console.log('Selected value:', value);
      selectedOption.value=value;
    }


    const selectedUpdateOption = ref('');
    const  handleSelectUpdate=(value:any) =>{
      console.log('Selected value:', value);
      selectedUpdateOption.value=value;
    }

    


    const addCategory = () => {
     
      
      addFormRef.value
        .validate()
        .then(() => {

          
          let customData: { [key: string]: string } = {};

          state.customFields.forEach(element => {
              
            const txtInput = document.getElementById(`txt_${element.id}`) as HTMLInputElement;
            const areaInput = document.getElementById(`area_${element.id}`) as HTMLInputElement;
            const numberInput = document.getElementById(`number_${element.id}`) as HTMLInputElement;
            const dateInput = document.getElementById(`date_${element.id}`) as HTMLInputElement;
            const selectInput = document.getElementById(`select_${element.id}`) as HTMLInputElement;
            const checkInput = document.getElementById(`check_${element.id}`) as HTMLInputElement;
            const radioInput = document.getElementById(`radio_${element.id}`) as HTMLInputElement;
            
            if(txtInput){
              console.log(`input ${element.name}:`, txtInput.value);
              customData[element.id] = txtInput.value;
            }
            if(areaInput){
              console.log(`input ${element.name}:`, areaInput.value);
              customData[element.id] = areaInput.value;

            }
            if(numberInput){
              console.log(`input ${element.name}:`, numberInput.value);
              customData[element.id] = numberInput.value;

            }
            if(dateInput){
              console.log(`input ${element.name}:`, dateInput.value);
              customData[element.id] = dateInput.value;

            }

            if(selectInput){
              console.log('selected input');
              console.log(`input ${element.name}:`, selectInput.value);
              

              if(selectedOption.value!==null){
                customData[element.id] =selectedOption.value;
              }

            }

            if(checkInput){
              const selectedCheckboxes = Array.from(checkInput.querySelectorAll('input[type="checkbox"]:checked')) as HTMLInputElement[];
              const selectedValues = selectedCheckboxes.map((checkbox) => checkbox.value);
              console.log(`Selected values for ${element.name}:`, selectedValues);
              customData[element.id] = selectedValues.join(','); 

            }

            if(radioInput){
              console.log(`input ${element.name}:`, radioInput.value);
              customData[element.id] = radioInput.value;
            }

              
          });
          console.log('customData:', customData);

          let payload={
            name:addForm.name,
            description:addForm.description,
            custom_data:JSON.stringify(customData),
            display_rank:addForm.display_rank,
          }
          console.log("paylod", payload);

          loading.value = true;
          categoryService.add(payload)
            .then((res) => {

              
              showSuccessNotification(t('Pos.AddCategorySuccess'));
              //state.data.push(res.data);
              getCategories();
              resetFormAdd();

            }).catch((err:any) => {
              console.log(err)
            }).finally(()=>{
              loading.value = false;
              openAdd.value = false;
              resetFormAdd();

            })
         
        }).catch((error:any) => {
          console.error('Validation error:', error);
        });
    }

    const cancelUpdate=()=>{
      open.value=false;
    }

    const updateCategory = (id: string) => {
      formRef.value
        .validate()
        .then(()=>{
          let customData: { [key: string]: string } = {};

          state.customFields.forEach(element => {
            console.log('element');
              
            const txtInput = document.getElementById(`txtUpdate_${element.name}`) as HTMLInputElement;
            const areaInput = document.getElementById(`areaUpdate_${element.name}`) as HTMLInputElement;
            const numberInput = document.getElementById(`numberUpdate_${element.name}`) as HTMLInputElement;
            const dateInput = document.getElementById(`dateUpdate_${element.name}`) as HTMLInputElement;
            const selectInput = document.getElementById(`selectUpdate_${element.name}`) as HTMLInputElement;
            const checkInput = document.getElementById(`checkUpdate_${element.name}`) as HTMLInputElement;
            const radioInput = document.getElementById(`radioUpdate_${element.name}`) as HTMLInputElement;
            
            if(txtInput){
              console.log(`input ${element.name}:`, txtInput.value);
              customData[element.name] = txtInput.value;
            }
            if(areaInput){
              console.log(`input ${element.name}:`, areaInput.value);
              customData[element.name] = areaInput.value;

            }
            if(numberInput){
              console.log(`input ${element.name}:`, numberInput.value);
              customData[element.name] = numberInput.value;

            }
            if(dateInput){
              console.log(`input ${element.name}:`, dateInput.value);
              customData[element.name] = dateInput.value;

            }

            if(selectInput){
              console.log('selected input');
              console.log(`input ${element.name}:`, selectInput.value);
              

              if(selectedOption.value!==null){
                customData[element.name] =selectedUpdateOption.value;
              }

            }

            if(checkInput){
              const selectedCheckboxes = Array.from(checkInput.querySelectorAll('input[type="checkbox"]:checked')) as HTMLInputElement[];
              const selectedValues = selectedCheckboxes.map((checkbox) => checkbox.value);
              console.log(`Selected values for ${element.name}:`, selectedValues);
              customData[element.name] = selectedValues.join(','); 

            }

            if(radioInput){
              console.log(`input ${element.name}:`, radioInput.value);
              customData[element.name] = radioInput.value;
            }

              
          });



          let payload = {
            name: form.name,
            description: form.description,
            display_rank:form.display_rank,
            custom_data:JSON.stringify(customData),
          }
          console.log('custom data', customData);
          console.log('payload', payload);

          loading.value = true;
          categoryService.update(id, payload).then((res) => {
            state.data = state.data.map(el => el.id !== id ? el : res.data);
            showSuccessNotification(t('Pos.UpdateCategorySuccess'));

          }).catch((err) => {
            console.log(err)
          }).finally(() => {
            loading.value = false;
            open.value = false;
            
          })
         

      }).catch((error:any) => {
        console.error('Validation error:', error);
      });
      
    }

    const deleteCategory = (id: string) => {
      categoryService.delete(id).then((res) => {
        //state.data = state.data.filter(e => e.id !== id);
        getCategories();
        showSuccessNotification(t('Pos.DeleteCategorySuccess'));
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        console.log('finish')
      })
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title:  t('Pos.TitleDeletecategory'),
        text: t('Pos.TextDeletecategory'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: 'Delete',
        cancelButtonColor: "#d33",
        cancelButtonText: t('Pos.Cancel'),
        customClass: {
          popup: 'swal2-popup'
        }

      }).then((result) => {
        if (result.isConfirmed) {
          deleteCategory(record.id);    
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      })
    };

    require('dayjs/locale/fr');
    require('dayjs/locale/ar');
    const lang=localStorage.getItem('Language')|| 'en';

    const formatDate = (dateString: string) => {
      return dayjs(dateString);
    }

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).locale(lang).fromNow();
    }

    const formatDateDrawer=(dateString:string)=>{
      return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
    }
    
    const openDisplay = ref<boolean>(false);
    const afterOpenShowChange = (bool: boolean) => {
      console.log('open', bool);
    };
    //add drawer : 
    const openAdd = ref<boolean>(false);

    const afterOpenAddChange = (bool: boolean) => {
      console.log('open', bool);
    };

    const showAddDrawer = () => {
      updateModalWidth();
      openAdd.value = true;
      getCustomFields();

    };

    //search
    const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
      confirm();

      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;

      console.log('searched data', state.searchText);
      console.log('seareched column', state.searchedColumn);


      
      if (dataIndex === 'name' && state.searchText) {
        console.log('begin filter');
        filter(state.searchText);
      }
    };

    const handleReset =  (clearFilter: (arg: any) => void)=> {
      clearFilter({ confirm: true });
      state.searchText = '';
      currentPage.value=1;
      state.beginSearch=false;
      state.searchName='';
      perPage.value=10;
      selectPagination.value='10';
      getCategories();

    };
    
    //notification
    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: '80px',
        style: {
          backgroundColor: '#bfffca8a',
          color: 'white'
        }
      });
    };

    const filter = (value:string) => {
      console.log('begin filter');
      currentPage.value=1;
      categoryService.filterWithPagination("name", value,currentPage.value,perPage.value)
        .then((res) => {
          console.log('res filter', res.data);
          state.data=res.data;
          console.log('state.data', state.data);
          state.totalPages = res.meta.total;
          state.beginSearch=true;
          state.searchName=value;
        }).catch((err:any) => {
          console.log(err)
        });
        
    };

    const transformUpperCase=(str:string)=>{
      if (!str || str.length === 0) {
        return str;
      }

      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const validateInput=(rule:any, value:any, callback:any)=> {
      console.log('value', value);
      if (value.trim() === '' && rule.required) {
        callback(new Error('This field is required'));
      } else {
        callback();
      }
    }

    const handleError=(item:any)=>{
     
      const txtInput = document.getElementById(`txt_${item.id}`) as HTMLInputElement;
      if (item.is_mandatory === 1 && txtInput.value==="" ) {
        console.log('error in item', item.id);
        state.errors[item.id] = true;
      }else{
        console.log('no error in item', item.id);
        state.errors[item.id] = false;
      }
      
    }

    const getErrorStatus=(itemId:any)=> {
      return state.errors[itemId] ? 'error' : '';
    }

    const getErrorText=(itemId:any)=> {
      return state.errors[itemId] ? t('Pos.RequiredField') : '';
    }

    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };


    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === 'ar' ? 'rtl' :'ltr';}
    );


  


    onMounted(() => {
      getCategories();
      searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
    });
    return {
      updateModalWidth,
      modalWidth,
      t,
      textDirection,
      validateInput,
      handleError,
      getErrorStatus,
      getErrorText,
      handlePageChange,
      transformUpperCase,
      showDisplayDrawer,
      cancelUpdate,
      handleSearch,
      handleReset,
      columns,
      state,
      open,
      afterOpenChange,
      showDrawer,
      showDeletePopup,
      form,
      formRef,
      rules,
      onSubmit,
      formatDate,
      openAdd,
      afterOpenAddChange,
      showAddDrawer,
      resetFormAdd,
      addForm,
      addFormRef,
      addCategory,
      formatDisplayDate,
      updateCategory,
      navigatePage,
      loading,
      openDisplay,
      afterOpenShowChange,
      currentPage, 
      perPage,
      customFieldValues,
      handleSelectChange,
      handleSelectUpdate,
      formatDateDrawer,
      onShowSizeChange,
      selectPagination,
      handleSelectPaginationChange,
      simpleImage,
      locale


    };
  },
  

})
</script>

<style scoped>
.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.table-container {
  overflow-x: auto;
  }
  

.date-picker{
  width:100%
}

.test-btn {
  color: #bfffca8a;
}

.breadcrumb-icon{
  cursor: pointer;
}
.input-form[disabled] {
    background-color: white;
    color: #000000;
}


.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
  }

  
  .view-btn{
    color: #fc8019;
    border-color: #fc8019;
    background: #fafafa;
  }

  .pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .custom-input{
    width: 100%;
  }

  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dynamic-input{
    width: 100%;
  }

  .add-btn:hover{
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
  }
 

  /* CSS for RTL */
  .is-rtl {
    direction:rtl;
  }
  
  .paginationArrows {
    direction:ltr;
  }

  /* pagintaion */
  @media (max-width: 768px) {
    .pagination {
      flex-direction: column;
      align-items: flex-start;
    }

    .select-input {
      width: 100%;
      margin-top: 10px;
    }
  }




</style>