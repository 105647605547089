import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-356f05ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = {
  key: 1,
  class: "search-btn-radio"
}
const _hoisted_4 = { class: "info-title" }
const _hoisted_5 = { class: "info-title" }
const _hoisted_6 = { class: "info-title" }
const _hoisted_7 = { class: "info-title" }
const _hoisted_8 = { class: "info-title" }
const _hoisted_9 = { class: "info-title" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "label-form" }
const _hoisted_12 = { class: "label-form" }
const _hoisted_13 = { class: "label-form" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }
const _hoisted_16 = { class: "label-form" }
const _hoisted_17 = { class: "label-form" }
const _hoisted_18 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigatePage('/warehouse'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Warehouse')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.state.warehouseName), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAddDrawer(true)))
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_PlusOutlined)
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')) + " ", 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        columns: _ctx.columns,
        "data-source": _ctx.state.data,
        pagination: false,
        class: "table-container"
      }, {
        emptyText: _withCtx(() => [
          _createVNode(_component_a_empty, {
            description: _ctx.t('Pos.NoData'),
            image: _ctx.simpleImage
          }, null, 8, ["description", "image"])
        ]),
        bodyCell: _withCtx(({ column, record }) => [
          (column.key=='actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        onClick: () => _ctx.showDisplayDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Adjustment')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "variant-btn",
                        onClick: ($event: any) => (_ctx.showTransferModal(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_PlusOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key=='stockable_type')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.t(`Pos.${record.stockable_type}`)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key=='stockable')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createTextVNode(_toDisplayString(record.stockable.name), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key==='code' && record.stockable!==null)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createTextVNode(_toDisplayString(record.stockable.barcode), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_2, [
            (column.key === 'stockable' || column.key==='code')
              ? (_openBlock(), _createBlock(_component_a_input, {
                  key: 0,
                  ref: "searchInput",
                  placeholder: _ctx.t('Pos.Search'),
                  value: selectedKeys[0],
                  style: {"width":"188px","margin-bottom":"8px","display":"block"},
                  onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
                  onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
                }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
              : _createCommentVNode("", true),
            (column.key === 'stockable_type')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_a_radio_group, {
                    value: _ctx.value,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value) = $event)),
                    onChange: _ctx.selectedType,
                    class: "search-radio-grp"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_radio, {
                        value: "product",
                        class: "radio-item"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Product')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_radio, {
                        value: "ingredient",
                        class: "radio-item"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Ingredient')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "onChange"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createVNode(_component_a_row, { class: "pagination" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_pagination, {
            class: "paginationArrows",
            current: _ctx.currentPage,
            "onUpdate:current": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentPage) = $event)),
            pageSize: _ctx.perPage,
            "onUpdate:pageSize": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.perPage) = $event)),
            total: _ctx.state.totalPages,
            onChange: _ctx.handlePageChange,
            "default-page-size": _ctx.perPage,
            showSizeChanger: false
          }, null, 8, ["current", "pageSize", "total", "onChange", "default-page-size"]),
          (_ctx.state.totalPages>10)
            ? (_openBlock(), _createBlock(_component_a_select, {
                key: 0,
                class: "select-input",
                value: _ctx.selectPagination,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectPagination) = $event)),
                onChange: _ctx.handleSelectPaginationChange
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: "10" }, {
                    default: _withCtx(() => [
                      _createTextVNode("10 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "20" }, {
                    default: _withCtx(() => [
                      _createTextVNode("20 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "50" }, {
                    default: _withCtx(() => [
                      _createTextVNode("50 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "100" }, {
                    default: _withCtx(() => [
                      _createTextVNode("100 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "onChange"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterDisplayOpenChange
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.StockItem')), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('Pos.Item')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.stockable_name) + " ", 1),
                        (_ctx.form.stockable_name===null)
                          ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('Pos.Type')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.t(`Pos.${_ctx.form.stockable_type}`)), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Pos.Unit')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.t(`Pos.${_ctx.form.unit}`)) + " ", 1),
                        (_ctx.form.unit===null)
                          ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.Quantity')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.quantity) + " ", 1),
                        (_ctx.form.quantity===null)
                          ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.CreatedBy')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.user_name), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.CreatedAt')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.formatDisplayDate(_ctx.form.created_at)), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterOpenChange
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.UpdateStockItem')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.update(_ctx.form.id)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.cancelUpdate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "unit" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Pos.Unit')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.form.unit,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.unit) = $event)),
                            "show-search": ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, { value: "mg" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "pcs" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "ml" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "mm" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "quantity" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Pos.Quantity')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.quantity,
                            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.quantity) = $event)),
                            placeholder: _ctx.t('Pos.Quantity'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openAdd,
        "onUpdate:open": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.openAdd) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onClose: _ctx.resetStockItem
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.AddStockItem')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _ctx.addStockItem
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.resetStockItem
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.addForm,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "stockable_type" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('Pos.Type')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addForm.stockable_type,
                            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.addForm.stockable_type) = $event)),
                            onChange: _ctx.handleStockableChange
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, { value: "product" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Product')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "ingredient" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Ingredient')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value", "onChange"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "stockable_id" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Pos.Item')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addForm.stockable_id,
                            "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.addForm.stockable_id) = $event)),
                            onSelect: _ctx.selectedStockable,
                            placeholder: _ctx.t('Pos.Item'),
                            "show-search": "",
                            "option-filter-prop": 'label',
                            disabled: !_ctx.addForm.stockable_type
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.stockables, (item) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: item.id,
                                  value: item.id,
                                  label: item.name,
                                  selected: item.id === _ctx.addForm.stockable_id 
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value", "label", "selected"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "onSelect", "placeholder", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.state.variants.length!==0)
                ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "product_variant_id" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Pos.Variants')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _ctx.addForm.product_variant_id,
                                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.addForm.product_variant_id) = $event))
                              }, {
                                notFoundContent: _withCtx(() => [
                                  _createVNode(_component_a_empty, {
                                    description: _ctx.t('Pos.NoData'),
                                    image: _ctx.simpleImage
                                  }, null, 8, ["description", "image"])
                                ]),
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.variants, (item) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: item.id,
                                      value: item.id,
                                      selected: item.id === _ctx.addForm.product_variant_id 
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "selected"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "unit" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Pos.Unit')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addForm.unit,
                            "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.addForm.unit) = $event)),
                            "show-search": "",
                            "option-filter-prop": 'label'
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, {
                                value: "mg",
                                label: _ctx.t('Pos.Milligrams')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_select_option, {
                                value: "pcs",
                                label: _ctx.t('Pos.Pieces')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_select_option, {
                                value: "ml",
                                label: _ctx.t('Pos.Milliliter')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_select_option, {
                                value: "mm",
                                label: _ctx.t('Pos.Millimeter')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Millimeter')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "quantity" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('Pos.Quantity')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.quantity,
                            "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.addForm.quantity) = $event)),
                            placeholder: _ctx.t('Pos.Quantity'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onClose"]),
      _createVNode(_component_a_modal, {
        open: _ctx.openTransferModal,
        "onUpdate:open": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.openTransferModal) = $event)),
        onOk: _ctx.handleTransferOk,
        onCancel: _ctx.resetFormProd
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "submit",
            type: "primary",
            onClick: _ctx.handleTransferOk
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Ok')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _ctx.handleCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.AdjustmentTitle')), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "refForm",
            model: _ctx.formProd,
            rules: _ctx.rulesProd,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        name: "quantity",
                        "has-feedback": "",
                        "validate-status": _ctx.validatingStatus,
                        help: _ctx.validatingHelp
                      }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('Pos.Quantity')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.formProd.quantity,
                            "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.formProd.quantity) = $event)),
                            placeholder: _ctx.t('Pos.Quantity'),
                            class: "input-form",
                            onBlur: _ctx.validateQuantity
                          }, null, 8, ["value", "placeholder", "onBlur"])
                        ]),
                        _: 1
                      }, 8, ["validate-status", "help"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "reason" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Pos.Reason')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.formProd.reason,
                            "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.formProd.reason) = $event)),
                            "show-search": ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, { value: "lost" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Lost')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "dammaged" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Damaged')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "expired" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Expired')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "other" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Other')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "onOk", "onCancel"])
    ])
  ], 64))
}