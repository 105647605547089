<template>
    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{ t('Pos.Dashboard') }}
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{ t('Pos.Attributes') }}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>



    <div class="btn-container">
        <a-button class="add-btn" @click="showAddDrawer(true)">
            {{ t('Pos.Add') }}
        </a-button>
    </div>
    <div>
        <a-table class="table-container" :columns="columns" :data-source="reactiveData.data" :pagination="false">

<template #bodyCell="{ column, record }">
    <template v-if="column.key == 'actions'">
        <a-space>
  <a-tooltip>
      <template #title>    {{ t('Pos.View') }}</template>
      <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
      <template #icon>
          <EyeOutlined/>
      </template>
      </a-button>
  </a-tooltip>

  <a-tooltip>
    <template #title>{{ t('Pos.Edit') }}</template>

    <a-button type="primary" @click="() => showDrawer(record)">
      <template #icon>
        <EditOutlined />
      </template>
    </a-button>

  </a-tooltip>

  <a-tooltip>
    <template #title>{{ t('Pos.Delete') }}</template>
    <a-button type="primary" danger @click="showDeletePopup(record)">
      <template #icon>
        <DeleteOutlined />
      </template>
    </a-button>
  </a-tooltip>

    <a-tooltip>
        <template #title>{{ t('Pos.AddValue') }}</template>
        <a-button type="primary" @click="goToValue(record)">
            <template #icon>
                <PlusCircleOutlined />
            </template>
        </a-button>
    </a-tooltip>

</a-space>
    </template>

    <template v-if="column.key === 'created_at' || column.key === 'updated_at'">
        {{ formatDisplayDate(record[column.key]) }}
    </template>
</template>
<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
            
            {{ t('Pos.Search') }}
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            {{ t('Pos.Reset') }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

</a-table>


<div class="pagination">
    <a-pagination class="paginationArrows" v-model:current="currentPage" :total="reactiveData.totalPages" @change="handlePageChange"  :default-page-size="perPage"/>
</div>


    <a-drawer v-model:open="open" class="custom-class" root-class-name="root-class-name" :root-style="{ color: 'blue' }"
        style="color: red" :title="reactiveData.action == 'add' ? t('Pos.AddAttributDrawer') : t('Pos.UpdateAttributDrawer')"
        placement="right" :width="modalWidth" :body-style="{ paddingBottom: '80px' }" @after-open-change="afterOpenChange" @close="hideForm">
    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">

            <a-row :gutter="16">
                <a-col :span="24">
                    <a-form-item name="name">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Name') }}</span>
                        </template>
                        <a-input v-model:value="form.name" placeholder="name" class="input-form" />
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>

        <template #footer>
            <a-space v-if="reactiveData.action == 'update'">
                <a-button type="primary" @click="onSubmitUpdate(form.id)">{{ t('Pos.Update') }}</a-button>
                <a-button type="primary" danger @click="hideForm">{{ t('Pos.Cancel') }}</a-button>
            </a-space>
            <a-space v-else>
                <a-button type="primary" @click="onSubmitAdd">{{ t('Pos.Add') }}</a-button>
            </a-space>
        </template>
    </a-drawer>


        <a-drawer v-model:open="openDisplay" class="custom-class" root-class-name="root-class-name" :root-style="{ color: 'blue' }"
            style="color: red" :title="t('Pos.DetailsAttributDrawer')"
            placement="right" :width="modalWidth" :body-style="{ paddingBottom: '80px' }" @after-open-change="afterOpenShowChange" @close="hideForm">
            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">


                <template #header>
                    <div class="custom-header">{{ t('Pos.GeneralInformations') }}</div>
                </template>
                


                <a-row :gutter="16">
                    <a-col :span="24">
                        <p><span class="info-title"  :placeholder="t('Pos.Name')">{{ t('Pos.Name') }}</span> {{form.name}} </p>
                    </a-col>
                </a-row>


            </a-form>

            <template #footer>
                <a-button type="primary" danger @click="hideForm">{{ t('Pos.Cancel') }}</a-button>
            </template>
        </a-drawer>


    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
    EyeOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    EditOutlined,
    SearchOutlined
    
} from '@ant-design/icons-vue';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';

import { notification } from 'ant-design-vue';
import { attributeService } from '@/_services'
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';


export default defineComponent({
    name: 'indexAttributes',
    components: {
        EyeOutlined,
        DeleteOutlined,
        EditOutlined,
        LbrxLoaderSpinner,
        SearchOutlined,
        PlusCircleOutlined
    },
    setup() {
        const modalWidth = ref(720);
        const { t } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);
        const columns = [
            
            {
                title: t('Pos.Name'),
                key: 'name',
                dataIndex: 'name',
                customFilterDropdown: true,
        onFilter: (value: string, record: any) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector('.ant-table-filter-dropdown input');

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
            },
            {
                title: t('Pos.Actions'),
                key: 'actions',
                dataIndex: 'actions',
            }

        ];

        //form  
        const formRef = ref();
        const visible = ref<boolean>(false);

        const reactiveData = reactive({
            data: [] as any[],
            action: '',
            totalPages:0,
            searchedColumn:'',
            searchText:''
            
        });

        const form = reactive({
            id: '',
            name: '',
        });

        const resetForm = () => {
            form.id = '';
            form.name = '';
        }

        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t('Pos.nameVerification') }]
        };

        const formatDate = (dateString: string) => {
            return dayjs(dateString);
        }

        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
        }

        //drawer

        const open = ref<boolean>(false);

        const showAddDrawer = (state: boolean) => {
            updateModalWidth();
            open.value = state;
            reactiveData.action = 'add';
            resetForm();
        };

        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showDrawer = (record: any) => {
            updateModalWidth();
            open.value = true;
            getAttributeById(record.id);
            reactiveData.action = 'update';
        };

        const afterOpenAddChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showSuccessNotification = (message: string) => {
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style: {
                    backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        const onSubmitAdd = () => {
            formRef.value
                .validate()
                .then(() => {
                    addAttribute();
                })
                .catch((error: any) => {
                    console.log('error', error);
                });
        };

        const hideForm = (bool: boolean) => {
            open.value = false;
            formRef.value.resetFields();
        };
        
        const goToValue = (record: any) => {
            router.push(`/attribute/${record.id}/values`)
        };

        const onSubmitUpdate = (id: string) => {
            formRef.value
                .validate()
                .then(() => {
                    updateAttribute(id);
                })
                .catch((error: any) => {
                    console.log('error', error);
                });
        };

        const getAttribute = () => {
            loading.value = true;

            attributeService.getAllWithPagination(currentPage.value , perPage.value)
                .then((res) => {
                    reactiveData.data = res.data;
                    reactiveData.totalPages =res.meta.total;

                })
                .catch((error)=>{
                    console.log("error api : get attributes", error);
                })
      .finally(()=>{
        loading.value = false;
        
      });
    }


    const getAttributForFilter = (searchText : string) => {
            loading.value = true;

// Adjust this part according to your API call
attributeService.searchWithoutPagination("name", searchText)
  .then((res) => {
    reactiveData.data = res.data;
    reactiveData.totalPages = res.meta.total;
  })
  .catch((error) => {
    console.log("error api : get warehouses", error);
  })
  .finally(() => {
    loading.value = false;
  });
    };


        const getAttributeById = (id: string) => {
            attributeService.show(id)
                .then(res => {
                    form.name = res.data.name;
                    form.id = res.data.id;
                })
                .catch(error => {
                    console.error('Error fetching categories:', error);
                });
        }

        const addAttribute = () => {
            let payload = {
                name: form.name
            }
            loading.value = true;

            attributeService.create(payload)
                .then((res) => {
                    reactiveData.data.push(res.data);
                    showSuccessNotification(t('Pos.AddAttribut'))
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    open.value = false;
                    loading.value = false;
                })
        }


        const showDisplayDrawer = (record: any) => {
            updateModalWidth();
            openDisplay.value = true;
            getAttributeById(record.id);
            resetForm();
            reactiveData.action = 'view';
        };


        const updateAttribute = (id: string) => {
            let payload = {
                name: form.name
            }
            loading.value = true;
            attributeService.update(id, payload).then((res) => {
                reactiveData.data = reactiveData.data.map(el => el.id !== id ? el : res.data);
                showSuccessNotification(t('Pos.UpdateAttribut'))
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                open.value = false;
                loading.value = false;
            })
        }

        const deleteAttribute = (id: string) => {
            loading.value = true;
            attributeService.delete(id).then((res) => {
                reactiveData.data = reactiveData.data.filter(e => e.id !== id);
                showSuccessNotification(t('Pos.DeleteAttribut'))
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                console.log('finish')
                loading.value = false;
            })
        };

        const openDisplay = ref<boolean>(false);
            const afterOpenShowChange = (bool: boolean) => {
            console.log('open', bool);
        };


        const loading = ref(false);
        const router = useRouter();
        const navigatePage = (link: string) => {
        router.push(link); 
        };
        
    
    const handlePageChange = (newPage:any) => {
      currentPage.value = newPage;
      loading.value = true;

      console.log('per page', perPage.value);
      console.log('page', currentPage.value)

      attributeService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
       
        console.log('data', response.data)
        reactiveData.data=response.data;
        reactiveData.totalPages =response.meta.total;
      }).catch((error)=>{
        console.log("error api : get products", error);
      })
      .finally(()=>{
        loading.value = false;
      });
    }

        const handleSearch = (selectedKeys: any[], confirm: () => void, dataIndex: string) => {
    confirm();
    getAttributForFilter(selectedKeys[0]); 
      reactiveData.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      reactiveData.searchText = '';
      getAttribute();
    };

    const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };

        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteAttribut'),
                text: t('Pos.TextDeleteAttribut'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t('Pos.Delete'),
                cancelButtonColor: "#d33",
                cancelButtonText: t('Pos.Cancel'),
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteAttribute(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        onMounted(() => {
            getAttribute();
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
        });

        return {
            updateModalWidth,
            modalWidth,
            loading,
            t,
            rules,
            columns,
            formRef,
            form,
            formatDisplayDate,
            reactiveData,
            showAddDrawer,
            showDrawer,
            open,
            formatDate,
            onSubmitUpdate,
            onSubmitAdd,
            showDeletePopup,
            visible,
            hideForm,
            goToValue,
            showDisplayDrawer,
            openDisplay,
            afterOpenShowChange,
            navigatePage,
            perPage,
            currentPage,
            handlePageChange,
            handleSearch,
            handleReset,
            afterOpenChange
        }   
    }

})
</script>

<style scoped>
.add-btn {
    color: #fc8019;
    border: 2px solid #fc8019;
    box-shadow: inset 0 0 0 0 #fc8019;
}

.pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}



.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
  }


  .table-container {
    overflow-x: auto;
}

  
.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}

.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.date-picker {
    width: 100%
}

.breadcrumb-icon{
  cursor: pointer;
}


.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-btn{
    color: #fc8019;
    border-color: #fc8019;
    background: #fafafa;
}

.paginationArrows {
    direction: ltr
}

</style>