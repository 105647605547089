import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const tableReservationService = {
    getAll,
    show,
    create,
    delete: _delete,
    update,
    getByMonth,
    
};

function getByMonth(start:string, end:string){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.tableReservation.getAll}?pagination=off&start=${start}&end=${end}`, requestOptions).then(handleResponse);
}

function getAll(page:number) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.tableReservation.getAll}?page=${page}`, requestOptions)
        .then(
            handleResponse
        );
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.tableReservation.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function create(payload:object ){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.tableReservation.create}`, requestOptions)
        .then(
            handleResponse
        );
}


function _delete(id: string){
    const requestOptions={
        method:"DELETE",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.tableReservation.delete.replace(':id', id)}`, requestOptions)
        .then(handleResponse);
}

function update(id: string, payload: object){
    console.log('ertert', id)
    const requestOptions={
        method:"PUT",
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(payload)
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.tableReservation.update.replace(':id', id)}`, requestOptions)
    .then(handleResponse);
}




function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
