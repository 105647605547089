import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import WareHouse from '../views/warehouse/warehouse.vue'
import ProductVue from '../views/products/products.vue'
import Login from '../views/auth/login.vue'
import Customers from '../views/customers/customers.vue'
import CategoryVue from '../views/category/category.vue'
import Manufacturer from '../views/manufacturer/manufacturer.vue'
import Supplier from '../views/supplier/supplier.vue'
import Ingredients from '../views/products/ingredients/ingredients.vue'
import Tables from '../views/tables/tables.vue'
import Store from '../views/store/store.vue'
import Discount from '../views/discount/discount.vue'
import Coupon from '../views/discount/coupon/coupon.vue'
import PageNotFound from '../views/notFound/pageNotFound.vue'
import TableLocation from '../views/tables/tableLocation/tableLocation.vue'
import IngredientCategories from '../views/products/ingredients/ingredients-cat/ingredientCategories.vue'
import Dashboard from '../views/dashboard/dashboard.vue'
import Variants from '../views/settings/variant.vue'
import Attributes from '../views/settings/attributes.vue'
import AttributeValues from '../views/settings/attributeValues.vue'
import Vatrate from '../views/settings/vatrate.vue'
import Loyalty from '../views/settings/loyalty.vue'
import Roles from '../views/settings/roles.vue'
import Users from '../views/settings/users.vue'
import PaymentsSettings from '../views/paymentsSettings/paymentsSettings.vue'
import ProductionPoints from '../views/productionPoint/productionPoint.vue'
import Workers from '../views/workers/workers.vue'
import Device from '../views/device/device.vue'
import Profile from '../views/profile/profile.vue'
import CustomField from '../views/customFields/customFields.vue'
import Inventory from '../views/inventory/inventory.vue'
import TableReservation from '../views/tables/tableReservation/tableReservation.vue'
import Home2 from '../views/home/Home.vue'
import sales from '@/views/sales/sales.vue'
import Payments from '../views/payment/payments.vue'
import WarehouseStock from '../views/warehouse/products/productsStock.vue'
import Currency from '../views/settings/currency.vue'
import Invoice from '../views/invoice/invoice.vue'
import Register from '../views/auth/register.vue'

import StockOverview from '../views/stockOverview/stockOverview.vue'
import invoiceSales from '../views/invoice/invoiceSales.vue'
import StoreSettings from '../views/settings/storeSettings.vue'
import InvoiceSettings from '../views/settings/invoiceSettings.vue'
import Prefences from '../views/products/ingredientPreference.vue'
import Cart from '../views/Cart/Cart.vue'

import delivaryNote from '../views/delivaryNote/delivaryNote.vue'
import transferRequests from '../views/transferRequests/transferRequests.vue'

import backDatedCheques from '../views/backDatedCheques/backDatedCheques.vue'
import giftCard from '../views/giftCards/giftCard.vue'
import Menu from '../views/menu/menu.vue'
import Menus from '../views/menu/menus.vue'
import tablesV2 from '../views/tables/tablesV2.vue'
import Bordereau from '../views/checkSlips/checkSlips.vue'

import MenuConfig from '../views/menuConfig/menuConfig.vue'
import MenuConfigV2 from '../views/menuConfig/menuConfogV2.vue'
import MenuConfigV3 from '../views/menuConfig/menuConfigV3.vue'

const routes: Array<RouteRecordRaw> = [



  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: Register,
  },
  {
    path:'/home2',
    name:'home2',
    component: Home2
  },
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
      authorize: ["*"],
    },
    component: HomeView,
    children:[
      {
        path:'/products',
        name:'products',
        component: ProductVue
      },
      {
        path:'/warehouse',
        name:'warehouse',
        component: WareHouse
      },
      {
        path:'/customers',
        name:'customers',
        component: Customers
      },
      {
        path:'/category',
        name:'category',
        component: CategoryVue
      },
      {
        path:'/manufacturer',
        name:'manufacturer',
        component: Manufacturer
      },
      {
        path:'/supplier',
        name:'supplier',
        component: Supplier
      },
      {
        path:'/ingredients',
        name:'ingredients',
        component: Ingredients
      },
      {
        path:'/tables',
        name:'tables',
        component: Tables
      },
      {
        path:'/store',
        name:'store',
        component: Store
      },
      {
        path:'/discount',
        name:'discount',
        component: Discount
      },
      {
        path:'/coupon',
        name:'coupon',
        component: Coupon
      },
      {
        path:'/tableLocation',
        name:'tableLocation',
        component: TableLocation
      },
      {
        path:'/ingredient-categories',
        name:'ingredientCategories',
        component: IngredientCategories
      },
      {
        path:'/',
        name:'dashboard',
        component: Dashboard 
      },
      {
      
        path:'/variants',
        name:'variants',
        component: Variants
      },
      {
        path:'/attributes',
        name:'attributes',
        component: Attributes
      },
      {
        path:'/attribute/:id/values',
        name:'attribute-values',
        component: AttributeValues
      },
      {
        path:'/vatrate',
        name:'vatrate',
        component: Vatrate
      },
      {
        path:'/loyalty',
        name:'loyalty',
        component: Loyalty
      },
   
      {
        path:'/currency',
        name:'currency',
        component: Currency
      },

      
      {
        path:'/users',
        name:'users',
        component: Users
      },
      {
        path:'/paymentsSettings',
        name:'paymentsSettings',
        component: PaymentsSettings
      },

      {
        path:'/payments',
        name:'payments',
        component: Payments
      },

      {
        path:'/production-points',
        name:'production-points',
        component: ProductionPoints
      },
      {
        path:'/workers',
        name:'workers',
        component: Workers
      },
      {
        path:'/device',
        name:'device',
        component: Device
      },
      {
        path:'/profile',
        name:'profile',
        component: Profile
      },
      {
        path:'/custom-filed',
        name:'custom-filed',
        component: CustomField
      },
      {
        path:'/inventory',
        name:'inventory',
        component: Inventory
      },
      {
        path:'/tables-reservation',
        name:'tables-reservation',
        component: TableReservation
      },
      {
        path:'/sales',
        name:'sales',
        component: sales
      },
      {
        path:'/warehouse/:id/stock',
        name:'warehouse-stock',
        component: WarehouseStock
      },
     

      {
        path:'/invoices',
        name:'invoice',
        component: Invoice
      },
      {
        path:'/stockOverview',
        name:'stockOverview',
        component: StockOverview
      },
      {
        path:'/purchases',
        name:'purchases',
        component: invoiceSales
      },
      {
        path:'/store-settings',
        name:'store-settings',
        component: StoreSettings
      },
      {
        path:'/invoice-settings',
        name:'invoice-settings',
        component: InvoiceSettings
      },
      {
        path:'/preferences',
        name:'preferences',
        component: Prefences
      },

      {
        path:'/Cart',
        name:'Cart',
        component: Cart
      },

      {
        path:'/delivaryNote',
        name:'delivaryNote',
        component: delivaryNote
      },

      {
        path:'/transfer-requests',
        name:'transfer-requests',
        component: transferRequests
      },

      {
        path:'/cheques',
        name:'cheques',
        component: backDatedCheques
      },

      {
        path:'/gift-card',
        name:'gift-card',
        component: giftCard
      },

      {
        path:'/menu',
        name:'menu',
        component: Menu
      },
      {
        path:'/menus',
        name:'menus',
        component: Menus
      },

      
      {
        path:'/tablesV2',
        name:'tablesV2',
        component: tablesV2
      },
      {
        path:'/bordereaux',
        name:'bordereaux',
        component: Bordereau
      },

      {
        path:'/menuConfig',
        name:'menuConfig',
        component: MenuConfig
      },
    


    


      


      




    ]
  },
  {
    path:'/menuConfigV2',
    name:'menuConfigV2',
    component: MenuConfigV2
  },
  {
    path:'/menuConfigV3',
    name:'menuConfigV3',
    component: MenuConfigV3
  },

  {
    path: '/:catchAll(.*)', 
    component: PageNotFound, 
  },
 



  /*
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ // '../views/AboutView.vue')
  //}//

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  console.log('111111')
  const userLoggedIn = localStorage.getItem('user') || null;
    if (to.meta.authRequired && userLoggedIn === null) {
      console.log('2222222')
      return next('/login');
    }

  if (to.path == '/login' && userLoggedIn !== null) {
    console.log('33333333333')
    return next('/');
  }

    next();
})



export default router
