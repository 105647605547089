<template>
    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{t('Pos.Dashboard')}}
            
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{t('Pos.Settings')}}</a-breadcrumb-item>
        <a-breadcrumb-item>{{t('Pos.Productionpoints')}}</a-breadcrumb-item>

        </a-breadcrumb>
    </div>
    <div class="btn-container">
        <a-button class="add-btn"  @click="showAddDrawer">
            {{t('Pos.Add')}}
            
        </a-button>
    </div>

    <div>
        <a-table
            class="table-container"
            :columns="columns"
            :data-source="state.data"
        >

            <template #emptyText>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
            </template>

            <template #bodyCell="{ column, record }">
                <template v-if="column.key=='actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>{{t('Pos.View')}}</template>
                            <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                            <template #icon>
                                <EyeOutlined/>
                            </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip>
                            <template #title>{{t('Pos.Edit')}}</template>
                            <a-button type="primary"  @click="() => showDrawer(record)">
                            <template #icon>
                                <EditOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                        
                        <a-tooltip>
                            <template #title>{{t('Pos.Delete')}}</template>
                            <a-button type="primary" danger @click="showDeletePopup(record)">
                            <template #icon>
                                <DeleteOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>

                <template v-if="column.key === 'created_at'">
                    {{ formatStringDate(record[column.key]) }}
                </template>

                
            </template>

            <!--search -->
            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                <a-input
                    ref="searchInput"
                    :placeholder="`${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                /> 

                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                
                    {{t('Pos.Search')}}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{t('Pos.Reset')}}
                </a-button>
                </div>
            </template>

            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>
            <!--search -->


        </a-table>

        <!-- display drawer -->
            <a-drawer
                v-model:open="openDisplay"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                @after-open-change="afterOpenChange"
            >

                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.ProductionPointInfo')}}</p>
                </template>

                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                    <a-row>   
                        <a-col :span="24">
                            <a-col :span="12">
                                <p><span class="info-title">{{ t('Pos.Name') }} :</span> {{form.name}}</p>
                            </a-col>
                            
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="24">
                            <a-col :span="12">
                                <p><span class="info-title">{{ t('Pos.Description') }} :</span> {{form.description}}</p>
                            </a-col>
                           
                        </a-col>
                    </a-row>
                </a-form>
        

            </a-drawer>
        <!-- display drawer -->

        <!-- update drawer -->
            <a-drawer
                v-model:open="open"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                
            >

                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateProductionPoint')}}</p>
                </template>

                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                    <a-row>   
                        <a-col :span="24">
                        <a-form-item  name="name">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Name') }}</span>
                            </template>
                            <a-input  v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form"/>
                        </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="24">
                        <a-form-item  name="description">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Description') }}</span>
                            </template>
                            <a-textarea v-model:value="form.description" :rows="4" :placeholder="t('Pos.Description')" />
                        </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
                <template #footer>
                    <a-space>
                        <a-button  type="primary" @click="updateProductionPoint(form.id)">{{ t('Pos.Update') }}</a-button>
                        <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                </template>

            </a-drawer>
        <!-- update drawer -->

        <!-- add drawer -->
        <a-drawer
            v-model:open="openAdd"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width=modalWidth
           @close="resetFormAdd"
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddProductionPoint')}}</p>
            </template>

            <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical"  :class="{ 'is-rtl': textDirection==='rtl'}">
               
                <a-row>   
                    <a-col :span="24">
                    <a-form-item  name="name">
                        <template #label>
                        <span class="label-form">{{ t('Pos.Name') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.name" :placeholder="t('Pos.Name')" class="input-form"/>
                    </a-form-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24">
                    <a-form-item  name="description">
                        <template #label>
                        <span class="label-form">{{ t('Pos.Description') }}</span>
                        </template>
                        <a-textarea v-model:value="addForm.description" :rows="2" :placeholder="t('Pos.Description')" />
                    </a-form-item>
                    </a-col>
                </a-row>

            </a-form>

            <template #footer>
            <a-space>
                <a-button  type="primary" @click="add">{{ t('Pos.Add') }}</a-button>
                <a-button type="primary" danger  @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
            </a-space>
            </template>


        </a-drawer>
        <!-- add drawer -->
       

    </div>
</template>

<script  lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined
} from '@ant-design/icons-vue';
import dayjs from 'dayjs'; 
import relativeTime from 'dayjs/plugin/relativeTime';

import {  notification } from 'ant-design-vue';
import { useRouter } from 'vue-router';

import {productionPointService} from '@/_services'
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import { useI18n } from 'vue-i18n';
import { Empty } from 'ant-design-vue';

export default defineComponent({
    name: 'indexProductionPoint',
    components: {
        
        EyeOutlined,
        DeleteOutlined,
        SearchOutlined,
        EditOutlined,
        LbrxLoaderSpinner
    
    },
    setup() {
        const modalWidth = ref(720);
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

        const { t } = useI18n();
        dayjs.extend(relativeTime);
        const loading = ref(false);
        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
        });

        const searchInput = ref<HTMLInputElement | null>(null);
        const searchContact = ref<HTMLInputElement | null>(null);

        const columns=[
            {
                title:t('Pos.Name'),
                key:'name',
                dataIndex: 'name',
                customFilterDropdown: true,
                onFilter: (value:string, record:any) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                        if (input instanceof HTMLInputElement) {
                            searchInput.value = input;
                            searchInput.value.focus();
                        }
                        }, 100);
                    }
                },
            },
            {
                title:t('Pos.Description'),
                key:'description',
                dataIndex: 'description',
            },
            {
                title: t('Pos.CreatedAt'),
                key:'created_at',
                dataIndex: 'created_at',
            },
            {
                title:t('Pos.Actions'),
                key:'actions'
            }
        ];

        const formRef = ref();
        const addFormRef = ref();

        const form = reactive({
            id: '',
            name: '',
            description: '',
        });

        const addForm = reactive({
            id: '',
            name: '',
            description: '',
        });

        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message:t('Pos.RequiredField') }],
                
        };

        //search
        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;
        };

        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
        };

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

        //drawer
        const open = ref<boolean>(false);
        const openDisplay = ref<boolean>(false);
        const openAdd = ref<boolean>(false);

        const showAddDrawer = (state: boolean) => {
            updateModalWidth();
            openAdd.value = true;
        };

        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showDisplayDrawer =(record:any)=>{
            updateModalWidth();
            openDisplay.value = true;
            getProductionPointById(record.id);
        }

        const showDrawer = (record: any) => {
            updateModalWidth();
            open.value = true;
            getProductionPointById(record.id);
        };


        const afterOpenAddChange = (bool: boolean) => {
            console.log('open', bool);
        };

    

        const resetFormAdd = () => {
            addFormRef.value.resetFields();
        };

        const cancelUpdate=()=>{
            open.value=false;
        }

        const getProductionPoints=()=>{
            loading.value = true;
            productionPointService.getAll().then((response)=>{
                state.data=response.data;
            }).catch((error)=>{
                console.log("error get Production Points", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const getProductionPointById = (id: string) => {
            loading.value=true;
            productionPointService.show(id)
            .then(res => {
                console.log('production point by id', res.data);
                form.name = res.data.name;
                form.description = res.data.description;
                form.id = res.data.id;
            })
            .catch(error => {
                console.error('Error fetching production point by id:', error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const updateProductionPoint =(id:string)=>{
              
            formRef.value
            .validate()
            .then(()=>{
                let payload = {
                    name:form.name,
                    description:form.description,
                }
                console.log('payload', payload);
                loading.value = true;
                productionPointService.update(id, payload).then((response)=>{
                   
                    showSuccessNotification(t('Pos.UpdateProductionPointSuccess'));
                    state.data = state.data.map(el => el.id !== id ? el : response.data);
                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                 
                    loading.value = false;
                    open.value=false;
                }); 
            })
        }

        const deleteProductionPoint = (id: string) => {
                productionPointService.delete(id).then((res) => {
                    state.data = state.data.filter(e => e.id !== id);
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    showSuccessNotification(t('Pos.DeleteProductionPointSuccess'));
                    console.log('finish')
                })
        };

        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteProductionPoint'),
                text: t('Pos.TextDeleteProductionPoint'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t('Pos.Delete'),
                cancelButtonColor: "#d33",
                cancelButtonText: t('Pos.Cancel'),
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteProductionPoint(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const add =()=>{
            addFormRef.value
            .validate()
            .then(()=>{
                
                let payload = {
                    name:addForm.name,
                    description:addForm.description,
                    
                }
                console.log('payload', payload);
                loading.value = true;
                productionPointService.create(payload).then((rep)=>{
                    console.log('Production point added successfully:', rep);
                    
                    state.data.push(rep.data);
                    resetFormAdd();
                    showSuccessNotification(t('Pos.AddProductionPointSuccess'));
                }).catch((error) => {
                    console.error('Error adding Production point:', error); 
                }).finally(()=>{
                    loading.value = false;
                    openAdd.value=false;
                });

            }).catch((error:any) => {
                console.error('Validation error:', error);
            });
            
        }

       

       

        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
        }

        const formatStringDate=(str:string)=>{
           
            return dayjs(str).fromNow();
        }


        onMounted(() => {
            getProductionPoints()
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');

        });

        const updateModalWidth = () => {
                modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
        };


        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        
        return{
            updateModalWidth,
            modalWidth,
            t,
            textDirection,
            locale,
            loading,
            navigatePage,
            state,
            columns,
            formRef,
            addFormRef,
            form,
            addForm,
            rules,
            handleSearch,
            handleReset,
            open,
            openDisplay,
            openAdd,
            showAddDrawer,
            afterOpenChange,
            showDisplayDrawer,
            showDrawer,
            afterOpenAddChange,
            resetFormAdd,
            cancelUpdate,
            updateProductionPoint,
            showDeletePopup,
            formatDisplayDate,
            add,
            formatStringDate,
            simpleImage


        }


    },
})
</script>
<style scoped>
    .add-btn {
        color: #fc8019;
        border: 2px solid #fc8019;
        box-shadow: inset 0 0 0 0 #fc8019;
    }

    .add-btn:hover {
        color: #fff;
        box-shadow: inset 0 -100px 0 0 #fc8019;
        border-color: #fc8019;
    }

    .btn-container {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
    }

    .label-form {
        font-family: "Exo 2", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    .date-picker {
        width: 100%
    }
    .range-picker{
        width: 100% 
    }

    .switch-container {
        display: flex;
        align-items: center;
    }
    .switch-container p {
        margin-right: 15px ;
    }

    .view-btn{
        color: #fc8019;
        border-color: #fc8019;
        background: #fafafa;
    }
   
    .breadcrumb-icon{
        cursor: pointer;
    }

    .info-title{
        font-size: 15px !important;
        font-weight: bold;
        margin-right: 4px;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* CSS for RTL */
    .is-rtl {
        direction:rtl;
    }

    .paginationArrows {
    direction: ltr
}

.table-container {
    overflow-x: auto;
}
   
</style>
