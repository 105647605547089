<template>
  <a-layout has-sider class="admin-layout">
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible class="sider-layout">
      <div class="logo">
        <p @click="navigatePage('/')">POSYS</p>
      </div>
      <!-- <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
        <a-menu-item key="1">
          <pie-chart-outlined />
          <span>Option 1</span>
        </a-menu-item>

        <a-menu-item key="2">
          <desktop-outlined />
          <span>Option 2</span>
        </a-menu-item>
          <a-sub-menu key="sub1">
            <template #title>
              <span>
              <user-outlined />
              <span>User</span>
              </span>
            </template>
            <a-menu-item key="3">Tom</a-menu-item>
            <a-menu-item key="4">Bill</a-menu-item>
            <a-menu-item key="5">Alex</a-menu-item>
          </a-sub-menu>

          <a-sub-menu key="sub2">
            <template #title>
                <span>
                <team-outlined />
                <span>Team</span>
                </span>
            </template>
            <a-menu-item key="6">Team 1</a-menu-item>
            <a-menu-item key="8">Team 2</a-menu-item>
            </a-sub-menu>
            <a-menu-item key="9">
            <file-outlined />
            <span>File</span>
        </a-menu-item>
      </a-menu> -->
    
      <a-menu
        theme="dark"
        v-model:openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        mode="inline"
      >
      <a-sub-menu title="Store" key="sub1" style="flex: initial;">
        <a-menu-item key="1">Option 1</a-menu-item>
        <a-menu-item key="2">Option 2</a-menu-item>
        <a-menu-item key="3">Option 3</a-menu-item>
        <a-menu-item key="4">Option 4</a-menu-item>
      </a-sub-menu>
    
      <a-sub-menu title="Warehouse" key="sub2">
        <a-menu-item key="5">Option 5</a-menu-item>
        <a-menu-item key="6">Option 6</a-menu-item>
        <a-sub-menu key="sub3" title="Submenu">
          <a-menu-item key="7">Option 7</a-menu-item>
          <a-menu-item key="8">Option 8</a-menu-item>
        </a-sub-menu>
      </a-sub-menu>

      <a-menu-divider />

      <a-sub-menu title="Navigation Three" key="sub4">
        <a-menu-item key="9">Option 9</a-menu-item>
        <a-menu-item key="10">Option 10</a-menu-item>
        <a-menu-item key="11">Option 11</a-menu-item>
        <a-menu-item key="12">Option 12</a-menu-item>
      </a-sub-menu>

      <a-sub-menu title="Group" key="sub5">
        <a-menu-item key="13">Option 13</a-menu-item>
        <a-menu-item key="14">Option 14</a-menu-item>
      </a-sub-menu>
    </a-menu>
    
    
    </a-layout-sider>

    <a-layout>

      <a-layout-header style="background: #fff; padding: 0">
        <menu-unfold-outlined
        v-if="collapsed"
        class="trigger"
        @click="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
      </a-layout-header>

      <a-layout-content
        :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }"
      >
        Content
      </a-layout-content>
    </a-layout>

  </a-layout>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, reactive,  } from 'vue';
import { useRouter } from 'vue-router';
import { authService } from '@/_services';
import {
  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
} from '@ant-design/icons-vue';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import workersIcon from "../../components/icons/workersIcon.vue"
import tablesIcon from "../../components/icons/tablesIcon.vue"
import { Theme } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { storeService  } from '@/_services';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';

export default defineComponent({
  name: 'Home2View',
  components: {
    // MenuUnfoldOutlined,
    // MenuFoldOutlined,
    // PieChartOutlined,
    // DesktopOutlined,
    // UserOutlined,
    // TeamOutlined,
    // FileOutlined,
   
  },
  setup() {
    const selectedKeys = ref<string[]>(['1']);
    const openKeys = ref<string[]>(['sub1']);

    const collapsed = ref<boolean>(false);

    const router = useRouter();

    const navigatePage = (link: string) => {
      router.push(link); 
    };






    

    return {
      selectedKeys,
      openKeys,
      collapsed,
      navigatePage

    };
  }
});
</script>
<style scoped>

.admin-layout{
  height: 100vh;
  overflow: hidden;
}


/* antdv css */

#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
} 
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.sider-layout{
  overflow: 'auto';
  height: '100vh';
  position: 'fixed';
  left: 0;
  top: 0;
  bottom: 0;
}
 
 
 

</style>
