import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b3b4de8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_5 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_6 = {
  key: 2,
  class: "drawer-header-ar"
}
const _hoisted_7 = {
  key: 3,
  class: "drawer-header-all"
}
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "label-form" }
const _hoisted_12 = { class: "label-form" }
const _hoisted_13 = { class: "label-form" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }
const _hoisted_16 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_17 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_18 = {
  class: "info-title",
  placeholder: "Currency"
}
const _hoisted_19 = {
  class: "info-title",
  placeholder: "Currency"
}
const _hoisted_20 = {
  class: "info-title",
  placeholder: "Email"
}
const _hoisted_21 = {
  class: "info-title",
  placeholder: "Discount"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, {
        style: {"margin":"16px 0"},
        class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Home')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Settings')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Users')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddDrawer(true)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        class: "table-container",
        columns: _ctx.columns,
        "data-source": _ctx.reactiveData.data,
        pagination: false
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        onClick: () => _ctx.showDisplayDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Edit')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key == 'name')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatName(record)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key === 'created_at' || column.key === 'updated_at')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createTextVNode(_toDisplayString(_ctx.formatDisplayDate(record[column.key])), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key == 'firstname')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createTextVNode(_toDisplayString(_ctx.formatNameConcat(record)), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_input, {
              ref: "searchInput",
              placeholder: `Search ${column.dataIndex}`,
              value: selectedKeys[0],
              style: {"width":"188px","margin-bottom":"8px","display":"block"},
              onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
              onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_SearchOutlined)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_pagination, {
          current: _ctx.currentPage,
          "onUpdate:current": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
          total: _ctx.form.customLastPage,
          onChange: _ctx.handlePageChange,
          "default-page-size": _ctx.perPage,
          showSizeChanger: false
        }, null, 8, ["current", "total", "onChange", "default-page-size"]),
        _createVNode(_component_a_select, {
          class: "select-input",
          value: _ctx.form.customPagination,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.customPagination) = $event)),
          placeholder: "Order By",
          onChange: _ctx.choosePerPage
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, { value: "10" }, {
              default: _withCtx(() => [
                _createTextVNode("10 / Page")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "20" }, {
              default: _withCtx(() => [
                _createTextVNode("20 / Page")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "50" }, {
              default: _withCtx(() => [
                _createTextVNode("50 / Page")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "100" }, {
              default: _withCtx(() => [
                _createTextVNode("100 / Page")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "200" }, {
              default: _withCtx(() => [
                _createTextVNode("200 / Page")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "onChange"])
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px' },
        onClick: _ctx.hideForm,
        onClose: _ctx.resetFields
      }, {
        title: _withCtx(() => [
          ((_ctx.locale === 'ar') && (_ctx.reactiveData.action=='add'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.AddUserDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale !== 'ar') && (_ctx.reactiveData.action=='add'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.AddUserDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale === 'ar') && (_ctx.reactiveData.action=='update'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.UpdateWareUserDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale !== 'ar') && (_ctx.reactiveData.action=='update'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.UpdateWareUserDrawer')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, [
            (_ctx.reactiveData.action == 'update')
              ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onSubmitUpdate(_ctx.form.id)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: "",
                      onClick: _ctx.hideForm
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_a_space, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _ctx.onSubmitAdd
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: "",
                      onClick: _ctx.hideForm
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
          ], 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "firstname" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.FisrtName')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.firstname,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.firstname) = $event)),
                            placeholder: _ctx.t('Pos.FisrtName'),
                            class: _normalizeClass(["input-form", { 'is-rtl': _ctx.textDirection==='rtl'}])
                          }, null, 8, ["value", "placeholder", "class"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "lastname" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.LastName')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.lastname,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.lastname) = $event)),
                            placeholder: _ctx.t('Pos.LastName'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "email" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Pos.Email')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.email,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.email) = $event)),
                            placeholder: _ctx.t('Pos.Email'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "username" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Pos.Username')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.username,
                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.username) = $event)),
                            placeholder: _ctx.t('Pos.Username') ,
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.reactiveData.action != 'update')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 0,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "active" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('Pos.Roles')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _ctx.form.active,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.active) = $event)),
                                placeholder: _ctx.t('Pos.RoleChoose'),
                                class: "input-form"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select_option, { value: "1" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("super_admin")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_select_option, { value: "2" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Admin")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_select_option, { value: "3" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Manager")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_select_option, { value: "4" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Staff")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_select_option, { value: "5" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("User")
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  (_ctx.reactiveData.action != 'add')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 0,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "oldPassword" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Pos.OldPassword')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_password, {
                                value: _ctx.form.oldPassword,
                                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.oldPassword) = $event)),
                                visible: _ctx.visible,
                                placeholder: _ctx.t('Pos.OldPassword'),
                                class: "input-form"
                              }, null, 8, ["value", "visible", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "password" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Pos.Password')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input_password, {
                            value: _ctx.form.password,
                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.password) = $event)),
                            visible: _ctx.visible,
                            placeholder: _ctx.t('Pos.Password'),
                            class: "input-form"
                          }, null, 8, ["value", "visible", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.reactiveData.action!='add')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 1,
                    gutter: 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "is_active" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Pos.Active')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_switch, {
                                checked: _ctx.form.is_active,
                                "onUpdate:checked": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.is_active) = $event))
                              }, null, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onClick", "onClose"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterOpenShowChange
      }, {
        title: _withCtx(() => [
          (_ctx.locale === 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.DetailsUserDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale !== 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.DetailsUserDrawer')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => []),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Pos.FisrtName')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.firstname), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Pos.LastName')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.lastname), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Pos.Username')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.username), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('Pos.Email')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.email), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"])
    ])
  ], 64))
}