import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const couponService = {
    getAll,
    store,
    update,
    delete: _delete,
    show,
    getAllCoupons,
    filterWithPagination
};
function getAllCoupons(page:number, perPage:any) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
        };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.coupon.getAll}?page=${page}&per_page=${perPage}`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.coupon.getAll}`, requestOptions).then(handleResponse);
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.coupon.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function store(payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.coupon.create}`, requestOptions).then(handleResponse);
}

function update(id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.coupon.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function _delete(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.coupon.delete.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function filterWithPagination(key:string, value:string, page:string, perPage:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.coupon.getAll}?keys=${key}&values=${value}&page=${page}&per_page=${perPage}`, requestOptions).then(handleResponse);

}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();

            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
