<template>

    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>
    
   <div>
        <a-breadcrumb style="margin: 16px 0">
            <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
                {{t('Pos.Dashboard')}}
                
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{t('Pos.Tables')}}</a-breadcrumb-item>
            <a-breadcrumb-item>{{t('Pos.TableReservations')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div class="btn-container">
        <a-button class="add-btn"  @click="showAddDrawer">
            {{ t('Pos.Add') }}
            <template #icon>
                <ScheduleOutlined />
            </template>
        </a-button>
    </div>

    <a-row>
        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }">
            <FullCalendar ref="calendarRef" :options="calendarOptions" >
                <template v-slot:eventContent='arg'>
                    <b>{{ arg.timeText }}</b>
                    <i>{{ arg.event.title }}</i>
                </template>
            </FullCalendar>
        </a-col>
    </a-row>

  
    <!-- add drawer -->
        <a-drawer
            v-model:open="openAdd"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width="modalWidth"
            @close="resetFormAdd"
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddTableReservation')}}</p>
            </template>

            <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
               
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item  name="reservation_time">
                            <template #label>
                                <span class="label-form">{{ t('Pos.ReservationTime') }}</span>
                            </template>
                            <a-date-picker  show-time :placeholder="t('Pos.ReservationTime')" v-model:value="addForm.reservation_time" class="range-picker"/>
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="number_of_guests">
                            <template #label>
                                <span class="label-form">{{ t('Pos.NumberOfGuests') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.number_of_guests" :placeholder="t('Pos.NumberOfGuests')" class="input-form"/>

                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16"> 

                    <a-col :span="12">
                        <a-form-item  name="location_id">
                            <template #label>
                                <span class="label-form">{{ t('Pos.TableLocation') }}</span>
                            </template>
                            <a-select 
                                show-search
                                allowClear 
                                placeholder="Please choose table location"
                                v-model:value="addForm.location_id"
                            >
                                <a-select-option v-for="location in state.tableLocations" :key="location.id" :value="location.id" :selected="location.id === addForm.location_id ">{{ location.name }}</a-select-option>
                            </a-select>
                        </a-form-item>

                    </a-col>  
                
                    <a-col :span="12">
                        <a-form-item  name="table_id">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Table') }}</span>
                            </template>
                            <a-select 
                                show-search
                                allowClear 
                                placeholder="Please choose Table"
                                v-model:value="addForm.table_id"
                                :disabled="!addForm.reservation_time || !addForm.number_of_guests || !addForm.location_id"
                            >
                                <a-select-option v-for="table in state.tables" :key="table.id" :value="table.id" :selected="table.id === addForm.table_id ">{{ table.number }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    

                   
                </a-row>

                <a-row :gutter="16">
                   <a-col :span="12">
                        <!--search customer -->

                        <a-form-item  name="customers">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Customer') }}</span>
                            </template>

                        
                            <a-select
                                v-model:value="state.userValue"
                                show-search
                                :default-active-first-option="false"
                                :filter-option="false"
                                @search="fetchUser"
                                :options="state.users"
                                :placeholder="t('Pos.Customer')"
                                style="width: 100%"
                                @change="handleUsersSelect"
                            >
                                <template v-if="state.fetchingUser" #notFoundContent>
                                    <a-spin size="small" />
                                </template>
                            </a-select>
                           
                            
                        </a-form-item> 
                   </a-col>

                    <template v-if="emptyData===true || customers.length===0">
                        <a-col :span="12" class="add-cutsomer-btn">

                            <a-form-item  name="customer" >
                                <a-button type="primary" @click="addCustomer">{{ t('Pos.AddCustomerBtn') }}</a-button>
                            </a-form-item>
                        </a-col>
                    </template>
                </a-row>

                <template v-if="addNewForm===true">

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item   name="first_name" >
                                <template #label>
                                    <span class="label-form">{{ t('Pos.FisrtName') }}</span>
                                </template>
                                <a-input  v-model:value="addForm.first_name" :placeholder="t('Pos.FisrtName')" class="input-form"/>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item   name="last_name" >
                                <template #label>
                                    <span class="label-form">{{ t('Pos.LastName') }}</span>
                                </template>
                                <a-input  v-model:value="addForm.last_name" :placeholder="t('Pos.FisrtName')" class="input-form"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item   name="email" >
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Email') }}</span>
                                </template>
                                <a-input  v-model:value="addForm.email" :placeholder="t('Pos.Email')" class="input-form"/>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item   name="phonr" >
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Phone') }}</span>
                                </template>
                                <a-input  v-model:value="addForm.phone" :placeholder="t('Pos.Phone')" class="input-form"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row :gutter="24">
                        <a-col :span="24">
                            <a-form-item name="free_orders">
                                <a-checkbox v-model:checked="addForm.free_orders">{{t('Pos.CustomerFreeOrder')}} </a-checkbox>
                            </a-form-item>
                        </a-col>
                    </a-row>

                   

                </template>

                

               

        
            </a-form>
            <template #footer>
            <a-space>
                <a-button  type="primary" @click="add">{{ t('Pos.Add') }}</a-button>
                <a-button type="primary" danger @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
            </a-space>
            </template>


        </a-drawer>
    <!-- add drawer -->

    <!--- display drawer -->
        <a-drawer
            v-model:open="openDisplay"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width="modalWidth"
            @after-open-change="afterOpenChange"
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.TableReservationInfo')}}</p>
            </template>

            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="24">
                    <p><span class="info-title">{{ t('Pos.ReservationTime') }} :</span>{{form.reservation_time}}</p>
                </a-col>
                
            </a-row>

            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Customer') }} :</span>{{form.customer_name}}</p>
                </a-col>
                <a-col :span="12">
                    <p>
                        <span class="info-title">{{ t('Pos.Status') }} :</span>
                        
                        <a-tag :bordered="false" color="orange" v-if="form.status==='confirmed'">
                          
                            {{ t(`Pos.${form.status}`)}}
                        </a-tag>

                        <a-tag :bordered="false" color="success" v-if="form.status==='arrived'">
                            <template #icon>
                                <check-circle-outlined />
                            </template>
                           {{ t(`Pos.${form.status}`)}}
                        </a-tag>

                        <a-tag :bordered="false" color="error" v-if="form.status==='cancelled'">
                            <template #icon>
                                <close-circle-outlined />
                            </template>
                            {{ t(`Pos.${form.status}`)}}
                        </a-tag>
                    </p>
                </a-col>
            </a-row>
            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.NumberOfGuests') }} :</span>{{form.number_of_guests}}</p>
                </a-col>

                <a-col>
                    <p><span class="info-title">{{ t('Pos.Table') }} :</span>{{form.table_number}}</p>

                </a-col>
            </a-row>

            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="24">
                    <p><span class="info-title">{{ t('Pos.TableLocation') }} :</span> {{form.location_name}}</p>
                </a-col>

               
            </a-row>


        

        </a-drawer>
    <!-- display drawer -->

    <!--update drawer -->
        <a-drawer
            v-model:open="open"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width="modalWidth"
            @after-open-change="afterOpenChange"
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateTableReservation')}}</p>
            </template>

            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                <a-row :gutter="16">   
                    <a-col :span="12">
                        <a-form-item  name="reservation_time">
                            <template #label>
                            <span class="label-form">{{ t('Pos.ReservationTime') }}</span>
                            </template>
                            <a-date-picker show-time v-model:value="form.reservation_datetime" :placeholder="t('Pos.ReservationTime')" class="range-picker" @change="setDirty"/>
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="number_of_guests">
                            <template #label>
                            <span class="label-form">{{ t('Pos.NumberOfGuests') }}</span>
                            </template>
                            <a-input  v-model:value="form.number_of_guests" :placeholder="t('Pos.NumberOfGuests')" class="input-form" @input="setDirty"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                   
                    <a-col :span="12">
                        <a-form-item  name="table_location_id">
                            <template #label>
                                <span class="label-form">{{ t('Pos.TableLocation') }}</span>
                            </template>
                           <a-select v-model:value="form.location_name" @change="setDirty">
                                <a-select-option v-for="location in state.tableLocations" :key="location.id" :value="location.id" >{{ location.name }}</a-select-option>
                            </a-select>
                        </a-form-item>

                    </a-col>  

                    <a-col :span="12">

                        <a-form-item  name="table_id">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Table') }}</span>
                            </template>
                            <a-select 
                                show-search 
                                v-model:value="form.table_id"
                                @change="handleTableChange" 
                            >
                                <a-select-option v-for="table in state.availabeTables" :key="table.id" :value="table.id" :selected="table.id === form.table_id ">{{ table.number }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                   
                </a-row>

                <a-row :gutter="16">

                    <a-col :span="12">
                        <a-form-item  name="status">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Status') }}</span>
                            </template>
                            <a-select v-model:value="form.status">
                                <a-select-option value="confirmed">{{ t('Pos.Confirmed') }}</a-select-option>
                                <a-select-option value="arrived">{{ t('Pos.Arrived') }}</a-select-option>
                                <a-select-option value="cancelled">{{ t('Pos.Cancelled') }}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                   <a-col :span="12" >

                        <a-form-item  name="customers">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Customer') }}</span>
                            </template>

                        
                            <a-select
                                v-model:value="form.customer_name"
                                show-search
                                :default-active-first-option="false"
                                :filter-option="false"
                                @search="fetchUser"
                                :options="state.users"
                                :placeholder="t('Pos.Customer')"
                                style="width: 100%"
                                @change="handleUsersSelectUpdate"
                            >
                                <template v-if="state.fetchingUser" #notFoundContent>
                                    <a-spin size="small" />
                                </template>
                            </a-select>
                           
                            
                        </a-form-item> 
                    
                   </a-col>
                </a-row>

            

            </a-form>
            <template #footer>
                <a-space>
                    <a-button  type="primary" @click="update(form.id)">{{ t('Pos.Update') }}</a-button>
                    <a-button type="primary" @click="cancelUpdate" danger >{{ t('Pos.Cancel') }}</a-button>
                </a-space>
            </template>

        </a-drawer>
    <!--update drawer -->

</template>
<script  lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, computed, watch, onBeforeUnmount } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  CloseCircleOutlined,
  ScheduleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  
} from '@ant-design/icons-vue';
import {  notification } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import {customerService, tableLocationService, tableReservationService, tablesService} from '@/_services'
import dayjs, { Dayjs } from 'dayjs';
import LbrxLoaderSpinner from '../../../components/LbrxLoaderSpinner.vue';

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';
import arLocale from '@fullcalendar/core/locales/ar'
export default defineComponent({

    name: 'indexTableReservation',
    components: {
        CloseCircleOutlined,
        CheckCircleOutlined,
        ScheduleOutlined,
        FullCalendar,
        //LoadingOutlined,
        LbrxLoaderSpinner
    },
    setup() {

        const modalWidth = ref(720);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
        };

        const loading = ref(false);
        const { t } = useI18n();

        const router = useRouter();
        const navigatePage = (link: string) => {
            router.push(link); 
        };

        //dynamic search in select 
        const customers = ref([]);
        const selectKey = ref(0);
        const isSelectOpen = ref(false);

        const selectUpdateKey = ref(0);
        const isUpdateSelectOpen = ref(false);

        let customersUpdate= ref([]);



        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            customers: [] as any[],
            tables: [] as any[],
            availabeTables :[] as any[],
            tableLocations:[] as any[],

            userValue : [] as any[],
            users:[] as any[],
            fetchingUser: false,

            


        });

        const columns = [
            {
                title:t('Pos.ReservationTime'),
                key:'reservation_time',
                dataIndex: 'reservation_time',
            },
            {
                title:t('Pos.NumberOfGuests'),
                key:'number_of_guests',
                dataIndex: 'number_of_guests',
            },
            {
                title: t('Pos.Status'),
                key:'status',
                dataIndex: 'status',
            }
         
        ];

        
        //form  
        const formRef = ref();
        const addFormRef = ref();

        const form = reactive({
            id:'',
            location_id:'',
            customer_id: '',
            customer_name:'',
            table_id: '',
            table_number:'',
            reservation_time: '',
            reservation_datetime:null as Dayjs | null,
            number_of_guests:'',
            status: '',
            date: null as Dayjs | null,
            created_at: '',
            location_name:'',

        });

        const addForm = reactive({
            id:'',
            location_id:'',
            customer_id: '',
            table_id: '',
            reservation_time: '',
            number_of_guests:'',
            status: '',
            date:[],
            created_at: '',
            first_name:'',
            last_name:'',
            email:'',
            phone:'',
            address:'',
            company_name:'',
            free_orders:true,

        });

        const isTableDisabled = computed(() => {
            return !form.reservation_time || !form.number_of_guests;
        });

        const tableRules = computed(() => {
            return isTableDisabled.value ? [] : rules.table_id;
        });

       

        const rules: Record<string, Rule[]> = {
            reservation_time: [{ required: true, message: t('Pos.RequiredField') }],
            customer_id:[{  required: true, message: t('Pos.RequiredField')}],
            number_of_guests:[
                {  required: true, message: t('Pos.RequiredField') },
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],
            location_id:[{ required: true, message: t('Pos.RequiredField') }],

            table_id:[
                {  required: true, message: t('Pos.RequiredField') },
            ],

            first_name: [{ required: true,message:t('Pos.RequiredField')}],
            last_name: [{ required: true,message:t('Pos.RequiredField')}],
            email:[{
                validator: (rule, value) => {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegex.test(value) && value ) {
                        return Promise.reject(t('Pos.invalidEmail'));
                    }
                    return Promise.resolve();

                },
                 
            }],
        };

       

        //drawer
        const open = ref<boolean>(false);
        const openDisplay = ref<boolean>(false);
        const openAdd = ref<boolean>(false);

       


        const showAddDrawer = (state: boolean) => {
            updateModalWidth();
            getTableLocations();
            openAdd.value = true;
           
        };

        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showDisplayDrawer =(id:any)=>{
            updateModalWidth();
            openDisplay.value = true;
            getReservationById(id);
        }

        const showDrawer = async (id:any) => {
            updateModalWidth();

           

            await getReservationById(id);
            getTableLocations();

            console.log('show update drawwer', form.reservation_time);

            getTables(form.reservation_time, form.number_of_guests, form.location_id, "update");

            open.value = true;
            
        };


        const afterOpenAddChange = (bool: boolean) => {
            
            console.log('open', bool);
        };

        const resetFormAdd = () => {
            addFormRef.value.resetFields();
            isSelectOpen.value=false;
            customers.value=[];
        };

        const cancelUpdate=()=>{
            open.value=false;
        }


        const showActionSwal = (id:string) => {
           
            Swal.fire({
                title: t('Pos.TableReservationActions'),
                text: t('Pos.TableResTxtActions'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: "#ffcf94",
                confirmButtonText: t('Pos.View'),
                cancelButtonColor: "#d33",
                cancelButtonText:  t('Pos.Delete'),

                showDenyButton: true,
                denyButtonColor: "#fc8019",
                denyButtonText:  t('Pos.Update'),

                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('view' , id );
                    showDisplayDrawer(id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                   deleteReservation(id);
                }else if (result.isDenied) {
                    console.log("update");
                    showDrawer(id);
                }
            })
           
        };


        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

       const formatReservationTime = (dateTimeString:any) => {
            const reservationTime = new Date(dateTimeString);
            const options = {
                month: 'short' as const,
                weekday: 'short' as const,
                day: '2-digit' as const,
                year: 'numeric' as const,
                hour: 'numeric' as const,
                minute: 'numeric' as const,
                second: 'numeric' as const,
                hour12: true
            };
            return reservationTime.toLocaleDateString('en-US', options);
        };
        const selectedLocation = ref(null);


        const getReservationById = async (id: string) => {
            loading.value=true;
            try{

                const res = await tableReservationService.show(id);

                console.log('table reservation by id', res.data);
                form.customer_id = res.data.customer.id;
                form.customer_name = res.data.customer.first_name + " " + res.data.customer.last_name;
                form.location_id = res.data.table.table_location_id;
                form.location_name=res.data.table.location.name?res.data.table.location.name:"";
                form.table_id = res.data.table.id;
                form.table_number = res.data.table.number;
                form.reservation_time = res.data.reservation_datetime;
                form.reservation_datetime = dayjs(res.data.reservation_datetime);
                form.number_of_guests = res.data.number_of_guests;
                form.status = res.data.status;
                form.created_at = res.data.created_at;
                form.id = res.data.id;
                loading.value = false;


            }catch(error){
                console.error('Error fetching reservation by id:', error);

            }

            /*
            tableReservationService.show(id)
            .then(res => {
                console.log('table reservation by id', res.data);
                form.customer_id = res.data.customer.id;
                form.customer_name = res.data.customer.first_name+" "+res.data.customer.last_name;
                form.location_id=res.data.table.table_location_id;
                form.table_id = res.data.table.id;
                form.table_number=res.data.table.number;
                form.reservation_time=res.data.reservation_datetime;
                form.reservation_datetime=dayjs(res.data.reservation_datetime);
                form.number_of_guests=res.data.number_of_guests;
                form.status=res.data.status;
                form.created_at=res.data.created_at;
                form.id = res.data.id;
                //customersUpdate.value.push(res.data.customer_id);

                loading.value=false;

            })
            .catch(error => {
                console.error('Error fetching reservation by id:', error);
            });
            */
        }

        const deleteReservation = (id: string) => {
            tableReservationService.delete(id).then((res) => {
                //state.data = state.data.filter(e => e.id !== id);
                //tablesReservations = tablesReservations.filter(reservation => reservation.id !== id);
                getAllByMonth();
                showSuccessNotification(t('Pos.DeleteTableReservationSuccess'));
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                console.log('finish')
            })
        };

        const formatDateToStr = (date:any) => {
            if (!date || !date.$d) {
                return ''; 
            }

            const year = date.$y;
            const month = date.$M + 1;
            const day = date.$D < 10 ? '0' + date.$D : date.$D;
            const hours = date.$H < 10 ? '0' + date.$H : date.$H;
            const minutes = date.$m < 10 ? '0' + date.$m : date.$m;
            const seconds = date.$s < 10 ? '0' + date.$s : date.$s;

            const stringDate= `${year}-${month < 10 ? '0' : ''}${month}-${day} ${hours}:${minutes}:${seconds}`;
            return stringDate;
        };

        const update=(id:string)=>{

            formRef.value
            .validate()
            .then(()=>{
                let payload={
                    customer_id:form.customer_id,
                    table_id:form.table_id,
                    reservation_datetime:formatDateToStr(form.reservation_datetime),
                    number_of_guests:form.number_of_guests,
                    status:form.status
                }
                console.log('payload', payload);
                
                loading.value = true;
                tableReservationService.update(id, payload).then((response)=>{
                    getAllByMonth();
                    showSuccessNotification(t('Pos.UpdateTableReservationSuccess'));
                    loading.value = false;
                    
                }).catch((err) => {
                    loading.value = false;
                    console.log(err);
                }).finally(() => {
                    open.value=false;
                    state.fetchingUser=false;
                    state.users=[];
                    
                    
                }); 
                
               
               

            })
        }

        //calander
        const date = ref(dayjs());
        const selectedValue = ref(dayjs());
        const storeCreationStr = localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!).created_at : '';
        const storeCreationDate = dayjs(storeCreationStr);


        const storeCreationYear = storeCreationDate.year();
        

        const disabledDate = (date: Dayjs) => {
            const year = date.year();
            return year < storeCreationYear;
        };

        const formatToStringDate = (date:any) => {
            if (!date || !date.$d) {
                return ''; 
            }

            const year = date.$y;
            const month = date.$M + 1;
            const day = date.$D < 10 ? '0' + date.$D : date.$D;
            const hours = date.$H < 10 ? '0' + date.$H : date.$H;
            const minutes = date.$m < 10 ? '0' + date.$m : date.$m;
            const seconds = date.$s < 10 ? '0' + date.$s : date.$s;

            const stringDate= `${year}-${month < 10 ? '0' : ''}${month}-${day} ${hours}:${minutes}:${seconds}`;
            return stringDate;
        };

        

        const onSelect = (value: Dayjs) => {
            console.log('select')
            showActionSwal("1");     
            date.value = value;
            selectedValue.value = value;
        };

        
        const onPanelChange = (value: Dayjs) => {
            console.log('pannel change', value);
            date.value = value;
        };

        const transformDateStr =(stringDate:string)=>{
            return stringDate.split(' ')[0];
        }

        const getDateFromDateStr =(stringDate:string)=>{
           return stringDate.split(' ')[1];
        }

        const getStartDate = () => {
            const currentMonth = date.value.month() + 1; 
            const currentYear = date.value.year();
            const startDate = dayjs(`${currentYear}-${currentMonth}-01`).format('YYYY-MM-DD');
            return startDate;
        };

        const getEndDate = () => {
            const currentMonth = date.value.month() + 1; 
            const currentYear = date.value.year();
            const endDate = dayjs(`${currentYear}-${currentMonth}-01`).endOf('month').format('YYYY-MM-DD');
            return endDate;
        };

        interface Reservation {
            id: string;
            customer: {
                contact_name: string;
            };
            reservation_datetime: string;
        }

        let tablesReservations=reactive<Reservation[]>([]);
        const transformReservationsForCalendar = (reservations:any) => {
            return reservations.map((reservation:any) => {
                return {
                    id:reservation.id,
                    title:reservation.customer.first_name+" "+reservation.customer.last_name +" - "+getDateFromDateStr(reservation.reservation_datetime),
                    start:transformDateStr(reservation.reservation_datetime),
                    end:transformDateStr(reservation.reservation_datetime)
                };
            });
            
        };

        const getAllByMonth=()=>{
            loading.value = true;
        
            tableReservationService.getByMonth(getStartDate(), getEndDate()).then((response)=>{
                console.log('table reservations', response.data);
                const reservations : any[]= response.data;
                tablesReservations.splice(0, tablesReservations.length, ...transformReservationsForCalendar(reservations));
                console.log('tables reservations', tablesReservations);
            }).catch((error)=>{
                console.log("error get tables reservations", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const getByMonth =(startDate:any, endDate:any)=>{
            loading.value = true;
        
            tableReservationService.getByMonth(startDate, endDate).then((response)=>{
                console.log('table reservations next month :', response.data);
                const reservations : any[]= response.data;
                tablesReservations.splice(0, tablesReservations.length, ...transformReservationsForCalendar(reservations));
                console.log('tables reservations next month :', tablesReservations);
            }).catch((error)=>{
                console.log("error get tables reservations", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

      
        const handleChange = (value: string) => {
            console.log(`selected ${value}`);
            isSelectOpen.value = false;
        };

        const selectedCustomer=(value:any, option:any)=>{
            console.log('selected customer', value, option);
           
            isSelectOpen.value=false;
            startedTyping.value=false;
            console.log('isOpen Value', isSelectOpen.value);
        }

       

        const handleDropdownVisibleChange=(visible:any)=>{
            console.log('handle drop down ', visible);

            if(emptyData.value===true){
                isSelectOpen.value=visible;
            }

          
            // if(startedTyping.value===false && customers.value.length=== 0){
            //     isSelectOpen.value = false;
            // }
             
            
        }

        const handleTableChange = (value: string) => {
            console.log(`selected ${value}`);
            form.table_id=value;
        };

        const handleLocationChange = (value:any) => {
            form.location_id = value.value;
        };

       

        const handleCustomerChange= (value:string)=>{
            console.log(`selected new customer ${value}`);
            isUpdateSelectOpen.value = false;
            form.customer_id=value;

        }
        
        const handleFilterOption = (input: string) => {
            getCustomers(input);
        };

        //watchers
        watch([() => addForm.reservation_time, () => addForm.number_of_guests, () => addForm.location_id], () => {
            if (addForm.reservation_time && addForm.number_of_guests && addForm.location_id) {
                getTables(formatToStringDate(addForm.reservation_time),addForm.number_of_guests, addForm.location_id, "add" );
            }
        });

        const isDirty = ref(false);
        watch([() => form.reservation_datetime, () => form.number_of_guests, () => form.location_name], () => {
            console.log('attempting to change', form.location_name);
            if (isDirty.value) {
                
                if (form.reservation_datetime!=null && (form.number_of_guests!=null && form.number_of_guests!=="")&& form.location_name !=null) {
                    console.log('get available Tables', )

                    getTables(form.reservation_time,form.number_of_guests, form.location_name, "update");
                    
                }else{
                    console.log('unable to get available tables');
                }
            }
        });

        const setDirty = (selectedValue:any) => {
            isDirty.value = true;
            console.log('selected value');
        };


        //api

        const getTableLocations=()=>{
            console.log('begin table locations');
            return tableLocationService.getAll().then((rep)=>{
                console.log('rep.data : ', rep.data);
                
                state.tableLocations=rep.data;
               
            }).catch((error)=>{
                console.log("error filter tabe locations", error);
            });
        }  
        
        let emptyData=ref(false);
        const getCustomers= (value:string)=>{
            
            console.log('begin customers');
            return customerService.filter("name",value ).then((rep)=>{
                console.log('rep.data : ', rep.data);
                
                //state.customers=rep.data;
                customers.value=rep.data;
                selectKey.value += 1;
                startedTyping.value=false;
                console.log('reactive display data', customers.value);
                addForm.customer_id="";
                if(customers.value.length===0){
                    emptyData.value=true;
                }
                console.log('empty data ? ', emptyData.value);
            }).catch((error)=>{
                console.log("error filter customers", error);
            });
        }

        const getCustomersUpdate=(value:string)=>{
            console.log('begin customers');
            return customerService.filter("name",value ).then((rep)=>{
                console.log('rep.data update : ', rep.data);
                
                //state.customers=rep.data;
                customersUpdate.value=rep.data;
                selectUpdateKey.value += 1;
                isUpdateSelectOpen.value = true;
                console.log('reactive display data', customers.value);
                
            }).catch((error)=>{
                console.log("error filter customers", error);
            });
        }

        const getTables=(reservationTime:any, numberOfGuests:any, locationId:any, action:string)=>{
            console.log('begin tables');
            if(action==="add"){
                console.log('add action , get availabe tables');
                tablesService.getAvailibility(reservationTime,numberOfGuests, locationId).then((rep)=>{
                    console.log('tables : ', rep.data);
                    state.tables=rep.data;
                    if(state.tables.length!==0){
                        addForm.table_id= state.tables[0].id;
                    }else{
                        addForm.table_id= '';
                    }
                }).catch((error)=>{
                    console.log("error filter tables", error);
                });
            }else if(action==="update"){
                tablesService.getAvailibility(reservationTime,numberOfGuests, locationId).then((rep)=>{
                    console.log('tables : ', rep.data);
                    state.availabeTables=rep.data;
                    // if(state.availabeTables.length!==0){
                    //     form.table_id= state.availabeTables[0].id;
                    // }else{
                    //     form.table_id= '';
                    // }

                }).catch((error)=>{
                    console.log("error filter tables", error);
                });
            }
            
           
        }

        

        let debounceTimer: ReturnType<typeof setTimeout> | null = null;

        let startedTyping = ref(false);
        const handleInput = async  (event: InputEvent) => {
            startedTyping.value=true;
            console.log('started typing', startedTyping);
            isSelectOpen.value = true;
            const input = (event.target as HTMLInputElement).value;
            console.log('input',input);
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }

            debounceTimer = setTimeout(() => {
                getCustomers(input);
                
                console.log('call api');
                //handleFilterOption(input);
            }, 2000); //ms
           
        };


        //filter customer 
        let startedTypingUpdate = ref(false);

        let debounceTimerUpdate: ReturnType<typeof setTimeout> | null = null;
        const handleUpdateCustomer = async  (event: InputEvent) => {
            startedTypingUpdate.value=true;

            isUpdateSelectOpen.value = true;
            const input = (event.target as HTMLInputElement).value;
            console.log('input',input);
            if (debounceTimerUpdate) {
                clearTimeout(debounceTimerUpdate);
            }

            debounceTimerUpdate = setTimeout(() => {
                getCustomersUpdate(input);
                
                console.log('call api');
                //handleFilterOption(input);
            },2000); //ms
           
        };









        const enableTableSearch = ref(true);
        const showErrorMsg = ref(false);

        const clickSelectTable = ()=>{
            console.log('open select table');
           if(form.reservation_datetime===null && form.number_of_guests === ''){
                enableTableSearch.value===false;
           }else{
                enableTableSearch.value===true;
           }

           console.log('enableTableSearch', enableTableSearch.value);
        }

        let debounceTimerTable: ReturnType<typeof setTimeout> | null = null;
        const handleInputTable = (event: InputEvent) => {
            if( enableTableSearch.value===true){
                showErrorMsg.value=false;
                const input = (event.target as HTMLInputElement).value;
                console.log('input',input);
                if (debounceTimerTable) {
                    clearTimeout(debounceTimerTable);
                }

                debounceTimerTable = setTimeout(() => {
                    //getTables(input);
                    console.log('call api tables    ');
                    //handleFilterOption(input);
                }, 3000); //ms
            }else{
                showErrorMsg.value=true;
            }
            
        };

        const add =()=>{
            addFormRef.value.validate()
            .then(()=>{
                let customerId;
                if(addNewForm.value===true){
                    let customerPayload={
                        first_name:addForm.first_name,
                        last_name:addForm.last_name,
                        email:addForm.email,
                        phone:addForm.phone,
                        can_get_free_orders:addForm.free_orders,
                    }
                    
                    customerService.store(customerPayload).then((res)=>{
                        customerId=res.data.id;

                        //add tr
                        loading.value = true;
                        let payload ={
                            customer_id:customerId,
                            table_id:addForm.table_id,
                            reservation_datetime:formatToStringDate(addForm.reservation_time),
                            number_of_guests:addForm.number_of_guests,
                        }
                        tableReservationService.create(payload).then((rep)=>{
                            console.log('Table Reservation added successfully:', rep);
                            
                            state.data.push(rep.data);
                            getAllByMonth();
                            resetFormAdd();
                            showSuccessNotification(t('Pos.AddTableReservationSuccess'));
                        }).catch((error) => {
                            console.error('Error adding Table Reservation:', error); 
                        }).finally(()=>{
                            openAdd.value=false;
                            loading.value = false;
                        });

                    }).catch((error=>{
                        console.log('error adding new customer');
                    }));
                }else{

                    let payload ={
                        customer_id:addForm.customer_id,
                        table_id:addForm.table_id,
                        reservation_datetime:formatToStringDate(addForm.reservation_time),
                        number_of_guests:addForm.number_of_guests,
                    }
                    console.log('payload', payload);
                    loading.value = true;
                    tableReservationService.create(payload).then((rep)=>{
                        console.log('Table Reservation added successfully:', rep);
                        
                        state.data.push(rep.data);
                        getAllByMonth();
                        resetFormAdd();
                        showSuccessNotification(t('Pos.AddTableReservationSuccess'));
                    }).catch((error) => {
                        console.error('Error adding Table Reservation:', error); 
                    }).finally(()=>{
                        openAdd.value=false;
                        loading.value = false;
                    });


                }

                
               


               
            }).catch((error:any) => {
                console.error('Validation error:', error);
            });
        }

        
    


        //calender : 

        

        const handleEventClick =(clickInfo:any)=>{

          console.log('id res', clickInfo.event._def.publicId);
          const id=clickInfo.event._def.publicId;
          //showDisplayDrawer(id);
          showActionSwal(id);
        }

        const handleDateSelect =(info : any)=>{
            console.log('selected date', info);
        }
        const formatDate = (date:any)=>{
            const year = date.getFullYear();
            const month = date.getMonth() + 1; 
            const day = date.getDate();
            const endOfMonth = `${year}-${month.toString().padStart(2, '0')}-${day}`;
            return endOfMonth;
        }

        const getEndOfMonth = (date:any) => {
            // const year = date.getFullYear();
            // const month = date.getMonth() + 1; 
            // const startOfMonth = `${year}-${month.toString().padStart(2, '0')}-01`;
            // return startOfMonth;

            return dayjs(date).endOf('month').format('YYYY-MM-DD');

        };


        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );


        const calendarRef = ref<any>(null);

        

        const calendarOptions = {
            plugins: [dayGridPlugin,timeGridPlugin, interactionPlugin],
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            },
            customButtons: { 
                prev: { 
                   
                    click: () => {           
                        console.log("eventPrev");
                        const calendarApi = calendarRef?.value?.getApi();
                        if (calendarApi) {
                            calendarApi.prev();

                            const currentDate = calendarApi.getDate();
                            const startDate= formatDate(currentDate);
                            const endDate=getEndOfMonth(currentDate);

                            console.log('current date', currentDate);
                            console.log('start date', startDate);
                            console.log('end date', endDate);

                            getByMonth(startDate.toString(), endDate.toString());
                        }
                    }
                },next: { 
                   
                    click: () => {
                        console.log("eventNext");
                        const calendarApi = calendarRef?.value?.getApi();
                        if (calendarApi) {
                            calendarApi.next();
                            const currentDate = calendarApi.getDate();

                            const startDate= formatDate(currentDate);
                            const endDate=getEndOfMonth(currentDate);
                            console.log('current date', currentDate);
                            console.log('start date', startDate);
                            console.log('end date', endDate);
                            getByMonth(startDate.toString(), endDate.toString());

                           
                            
                        }   
                    }
                },today: {
                    text: t('Pos.Today'),
                    click: () => {
                        const calendarApi = calendarRef?.value?.getApi();
                        if (calendarApi) {
                            calendarApi.today();
                        }
                        getAllByMonth();
                    }
                }

            },

            locales: [ arLocale, frLocale ],
            locale:locale.value,


            initialView: 'dayGridMonth',
            editable: true,
            selectable: true,
            selectMirror: true,
            weekends: true,
            events:tablesReservations,
            select: handleDateSelect,
            eventClick: handleEventClick,  
            eventColor: '#fc8019',
        };

        const resizeCalendar = () => {
            const calendarApi = calendarRef.value?.getApi();
            const width = window.innerWidth;
            let newView = 'dayGridMonth';
            let newToolbar = {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
            };

            if (width < 601) {
                newView = 'timeGridDay';
                newToolbar = {
                    left: 'prev,next',
                    center: 'title',
                    right: 'today',
                };
            } else if (width < 1025) {
                newView = 'timeGridWeek';
                newToolbar = {
                    left: 'prev,next today',
                    center: 'title',
                    right: '',
                };
            }

            if (calendarApi && calendarApi.view.type !== newView) {
                calendarApi.changeView(newView);
                calendarApi.setOption('headerToolbar', newToolbar);
            }
        };

        let addNewForm = ref(false);
        const addCustomer=()=>{
            console.log('click')
            addNewForm.value = !addNewForm.value;
            
        }

        //add table reservation: customer multoselect 
        let debounceTimerUser: ReturnType<typeof setTimeout> | null = null;

        const fetchUser = (value: any) => {

            
            //disable fetching when the input is empty
            if(value===''){
                return;
            }


            state.users = [];
           
            state.fetchingUser = true;


            //clear previous timer
            if (debounceTimerUser) {
                clearTimeout(debounceTimerUser);
            }


            //fetch users 

            debounceTimerUser = setTimeout(() => {
                customerService.filter("name", value)
                .then((res) => {

                    console.log('res', res);

                    const data = res.data.map((user: { first_name: any; last_name: any; id: any; }) => ({
                        label:  `${user.first_name} ${user.last_name}`,
                        value: user.id,
                    }));

                    
                    state.users = data;

                    
                    state.fetchingUser = false;


                    console.log('state.users : ', state.userValue);


                })
                .catch((error) => {
                    console.log('error fetching customers', error);
                });
            }, 700);





           
             

        };
     
        watch(state.userValue, () => {
            state.users = [];
            state.fetchingUser = false;
        });

        const handleUsersSelect=(value: any)=>{
            console.log('selected customer', value);
            addForm.customer_id=value;
        };

        const handleUsersSelectUpdate=(value: any)=>{
            form.customer_id=value;
        }
        



                

       
       
      

        onMounted(() => {
            resizeCalendar();
            window.addEventListener('resize', resizeCalendar);
            getAllByMonth();
            console.log('customers length', customers.value.length);
           
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', resizeCalendar);
        });

        watch(locale, (newLocale) => {
            const calendarApi = calendarRef.value?.getApi();
            if (calendarApi) {
                calendarApi.setOption('locale', newLocale);
            }
        });

        


        return{
            modalWidth,
            textDirection,
            locale,
            addCustomer,
            addNewForm ,
            calendarRef,
            startedTyping,
            isSelectOpen,
            selectKey,
            selectUpdateKey,
            isUpdateSelectOpen,
            customers,
            calendarOptions,
            handleInputTable,
            handleInput,
            handleChange,
            handleFilterOption,
            t,
            navigatePage,
            state,
            columns,
            rules,
            formRef,
            addFormRef,
            form,
            addForm,
            open,
            openDisplay,
            openAdd,
            showAddDrawer,
            afterOpenChange,
            showDisplayDrawer,
            showDrawer,
            afterOpenAddChange,
            date,
            selectedValue,
            onSelect,
            onPanelChange,
            disabledDate,
            loading,
            resetFormAdd,
            add,
            update,
            customersUpdate,
            enableTableSearch,
            showErrorMsg,
            clickSelectTable,
            handleUpdateCustomer,
            handleCustomerChange,
            startedTypingUpdate,
            handleTableChange,
            selectedLocation,
            handleLocationChange,
            isDirty,
            setDirty,
            cancelUpdate,
            handleDropdownVisibleChange,
            selectedCustomer,
            emptyData,
            handleUsersSelect,
            fetchUser,
            handleUsersSelectUpdate,

            






















        }
        
    },
})
</script>

<style scoped>
    @import '../../../assets/css/fullCalender.css';

    .add-btn {
        color: #fc8019;
        border: 2px solid #fc8019;
        box-shadow: inset 0 0 0 0 #fc8019;
    }

    .add-btn:hover {
        color: #fff;
        box-shadow: inset 0 -100px 0 0 #fc8019;
        border-color: #fc8019;
    }

    .btn-container {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
    }

    .label-form {
        font-family: "Exo 2", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    .date-picker {
        width: 100%
    }
    .range-picker{
        width: 100% 
    }

    .switch-container {
        display: flex;
        align-items: center;
    }
    .switch-container p {
        margin-right: 15px ;
    }

    .view-btn{
        color: #fc8019;
        border-color: #fc8019;
        background: #fafafa;
    }
   
    .breadcrumb-icon{
        cursor: pointer;
    }

    .pagination{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .calender-container{
        margin-top: 15px !important;
    }
    /* calender */

    
    .fc-daygrid-day-number{ 
        color: pink !important;
    }

    .fc-col-header-cell-cushio{
        color: #2c3e50 !important;
    }

   
    .info-title{
        font-size: 15px !important;
        font-weight: bold;
        margin-right: 4px;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .add-cutsomer-btn{
        display: flex;
        align-items: flex-end;
    }

    /* CSS for RTL */
    .is-rtl {
    direction:rtl;
    }

    /* .table-container {
    overflow-x: auto;
    } */

   

</style>
