<template>
    <div >
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>
    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{t('Pos.Dashboard')}}
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{t('Pos.Supplier')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div class="btn-container">
        <a-button class="add-btn" @click="showAddDrawer" >
            {{ t('Pos.Add') }}
        <template #icon > 
            <AppstoreOutlined />
        </template>
        </a-button>
    </div>

    <div>
        <a-table
            :columns="columns"
            :data-source="state.data"
            :pagination="false"
            class="table-container"

        >

            <template #emptyText>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
            </template>

            <template #bodyCell="{ column, record }">
                <template v-if="column.key=='actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>{{ t('Pos.View') }}</template>
                            <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                            <template #icon>
                                <EyeOutlined/>
                            </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip>
                            <template #title>{{ t('Pos.Update') }}</template>

                            <a-button type="primary" @click="() => showDrawer(record)">
                            <template #icon>
                                <EditOutlined />
                            </template>
                            </a-button>

                        </a-tooltip>
                        

                        <a-tooltip>
                            <template #title>{{ t('Pos.Delete') }}</template>
                            <a-button type="primary" danger @click="showDeletePopup(record)">
                            <template #icon>
                                <DeleteOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>

                <template v-if="column.key === 'created_at' || column.key === 'updated_at'">
                    {{ formatDisplayDate(record[column.key]) }}
                </template>
            </template>

            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                    <a-input
                    ref="searchInput"
                    :placeholder="`${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    /> 

                    <a-button
                        type="primary"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    >
                        {{ t('Pos.Search') }}
                    </a-button>
                    <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                        {{ t('Pos.Reset') }}
                    </a-button>
                </div>
            </template>

            <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>

            
        </a-table>
        <div class="pagination">
            <a-pagination class="paginationArrows" model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />

            
            <a-select
                v-model:value="selectPagination"
                @change="handleSelectPaginationChange"
                v-if="state.totalPages>10"
            >
                <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
            </a-select>

        </div>

       
        <a-drawer
            v-model:open="open"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width=modalWidth
            @after-open-change="afterOpenChange"
           
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateSupplier')}}</p>
            </template>

            <a-form  ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-row :gutter="16">   
                    <a-col :span="12">
                        <a-form-item  name="company_name">
                        <template #label>
                            <span class="label-form">{{ t('Pos.CompanyName') }}</span>
                        </template>
                        <a-input  v-model:value="form.company_name" :placeholder="t('Pos.CompanyName')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="contact_name">
                        <template #label>
                            <span class="label-form">{{ t('Pos.ContactName') }}</span>
                        </template>
                        <a-input  v-model:value="form.contact_name" :placeholder="t('Pos.ContactName')" class="input-form"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">   
                    <a-col :span="12">
                        <a-form-item  name="address">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Address') }}</span>
                        </template>
                        <a-input  v-model:value="form.address" :placeholder="t('Pos.Address')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="vat_number">
                        <template #label>
                            <span class="label-form">{{ t('Pos.VATNumber') }}</span>
                        </template>
                        <a-input  v-model:value="form.vat_number" :placeholder="t('Pos.VATNumber')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                   
                </a-row>

                <a-row :gutter="16">   
                    <a-col :span="12">
                        <a-form-item  name="email">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Email') }}</span>
                        </template>
                        <a-input  v-model:value="form.email" :placeholder="t('Pos.Email')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="website">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Website') }}</span>
                        </template>
                        <a-input  v-model:value="form.website" :placeholder="t('Pos.Website')" class="input-form"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">   
                   
                   

                    <a-col :span="24">
                        <a-form-item  name="created_at">
                            <template #label>
                                <span class="label-form">{{ t('Pos.CreatedAt') }} </span>
                            </template>
                            <a-date-picker show-time :placeholder="t('Pos.CreatedAt')" class="date-picker" disabled :value="formatDate(form.created_at)">
                            </a-date-picker>
                        </a-form-item>
                    </a-col>
                </a-row>

                
            
            </a-form>
            <template #footer>
                <a-space :class="{ 'is-rtl': textDirection==='rtl'}">
                    <a-button  type="primary" @click="updateSupplier(form.id)">{{ t('Pos.Update') }}</a-button>
                    <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
                </a-space>
            </template>

        </a-drawer>

        <a-drawer
            v-model:open="openAdd"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width=modalWidth
            @close="resetFormAdd"
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}" >{{t('Pos.AddSupplier')}}</p>
            </template>

            <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-row :gutter="16">   
                    <a-col :span="12">
                        <a-form-item   name="company_name" >
                            <template #label>
                                <span class="label-form">{{ t('Pos.CompanyName') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.company_name" :placeholder=" t('Pos.CompanyName')" class="input-form"/>

                        </a-form-item>
                        
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="contact_name">
                        <template #label>
                            <span class="label-form">{{ t('Pos.ContactName') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.contact_name" :placeholder="t('Pos.ContactName')" class="input-form"/>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">   
                    <a-col :span="12">
                        <a-form-item  name="address">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Address') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.address" :placeholder="t('Pos.Address')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="vat_number">
                        <template #label>
                            <span class="label-form">{{ t('Pos.VATNumber') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.vat_number" :placeholder="t('Pos.VATNumber')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                    
                </a-row>

                <a-row :gutter="16">   
                    <a-col :span="12">
                       
                        <a-form-item  name="email">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Email') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.email" :placeholder="t('Pos.Email')" class="input-form"/>
                        </a-form-item> 

                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="website">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Website') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.website" :placeholder="t('Pos.Website')" class="input-form"/>
                        </a-form-item>
                    </a-col>
                </a-row>

              
            </a-form>
            <template #footer>
                <a-space>
                <a-button  type="primary" @click="addSupplier">{{ t('Pos.Add') }}</a-button>
                <a-button type="primary" danger  @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
                </a-space>
            </template>
        </a-drawer>

        <a-drawer
            v-model:open="openDisplay"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width=modalWidth
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.SupplierInfo')}}</p>
            </template>
            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.CompanyName') }} :</span>{{form.company_name}}</p>
                </a-col>
                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.ContactName') }} :</span>{{form.contact_name}}</p>
                </a-col>
            </a-row>

            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">   
                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Address') }} :</span>{{form.billing_address}}</p>
                </a-col>
            </a-row>

            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}">   
                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Email') }} :</span>{{form.email}}</p>
                </a-col>

                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Website') }} :</span>{{form.website}}</p>
                </a-col>
            </a-row>

            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}"> 
                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.VATNumber') }} :</span>{{form.vat_number}}</p>
                </a-col>

                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.CreatedAt') }} :</span> {{formatDateDrawer(form.created_at)}}</p>
                </a-col>

            </a-row>
            
        </a-drawer>




    </div>

   
</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  EyeOutlined,
  DeleteOutlined,
  AppstoreOutlined,
  EditOutlined,
  SearchOutlined
} from '@ant-design/icons-vue';
import dayjs from 'dayjs'; 
import { notification } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useVuelidate} from '@vuelidate/core';
import { required, url, email} from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import {supplierService} from '../../_services/supplier.service'
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import { Empty } from 'ant-design-vue';
import { manufacturerService } from '@/_services';

export default defineComponent({
  name: 'indexCategory',
  components: {
        EyeOutlined,
        DeleteOutlined,
        AppstoreOutlined,
        EditOutlined,
        LbrxLoaderSpinner,
        SearchOutlined
  },

  setup() {
        const modalWidth = ref(720);
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const { t } = useI18n();
        const loading = ref(false);

        const router = useRouter();

        const navigatePage = (link: string) => {
        router.push(link); 
        };

        const searchInput = ref<HTMLInputElement | null>(null);

        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
            searchName:'',


        });

        const columns = [
            {
                title:t('Pos.CompanyName'),
                key:'company_name',
                dataIndex: 'company_name',
                customFilterDropdown: true,
        
                onFilterDropdownOpenChange:  (visible: boolean) => {
                if (visible  && !searchInput.value) {
                    setTimeout(() => {
                    const input = document.querySelector('.ant-table-filter-dropdown input');

                    if (input instanceof HTMLInputElement) {
                    searchInput.value = input;
                    searchInput.value.focus();
                    }
                    }, 100);
                }
                },

            },
            {
                title:t('Pos.Email'),
                key:'email',
                dataIndex: 'email',
            },
            {
                title: t('Pos.ContactName'),
                key:'contact_name',
                dataIndex: 'contact_name',
            },
            
            {
                title: t('Pos.BillingAddress'),
                key:'billing_address',
                dataIndex: 'billing_address',
            },
            {
                title: t('Pos.ShippingAddress'),
                key:'shipping_address',
                dataIndex: 'shipping_address',
            },
            {
                title: t('Pos.VATNumber'),
                key:'vat_number',
                dataIndex: 'vat_number',  
            },
            {
                title:  t('Pos.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);


        const selectPagination = ref('10');

        const handleSelectPaginationChange=(selectedItem:any)=>{
            
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchName!==''){
                    loading.value = true;
                    supplierService.filter("name",state.searchName,currentPage.value,perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

            }else{

                loading.value=true;
                supplierService.getAllWithPagination( perPage.value,currentPage.value,).then((res)=>{
                    state.data=res.data;
                    state.totalPages=res.meta.total;
                }).catch((err:any) => {
                    console.log(err)
                }).finally(()=>{
                    loading.value = false;
                });

            }

            
        }

        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;
            

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                state.data=[];

                console.log('handle page search', state.searchName, currentPage.value, perPage.value);
                loading.value = true;



                if(state.searchName!==''){
                    supplierService.filter('name',state.searchName, currentPage.value, perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{
                loading.value = true;
                supplierService.getAllWithPagination(perPage.value, currentPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api : get tables", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

           
        }


        //form  
        const formRef = ref();
        const addFormRef = ref();

        const form = reactive({
            id:'',
            company_name: '',
            vat_id: '',
            contact_name: '',
            address: '',
            vat_number: '',
            email: '',
            website: '',
            created_at: '',
            updated_at: '',
        });

        const addForm = reactive({
            id:'',
            company_name: '',
            vat_id: '',
            contact_name: '',
            address: '',
            vat_number: '',
            email: '',
            website: '',
            created_at: '',
            updated_at: '',
        });

        const rules: Record<string, Rule[]> = {
            company_name: [
                { required: true,message:t('Pos.RequiredField')}
            ],
            email:[{
                validator: (rule, value) => {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegex.test(value) && value ) {
                        return Promise.reject(t('Pos.invalidEmail'));
                    }
                    return Promise.resolve();

                },
                 
            }],
            // website:[{
               
            //     validator: (rule, value) => {
            //         const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
            //         if (!urlRegex.test(value) && value) {
            //             return Promise.reject(t('Pos.invalidUrl'));
            //         }
            //         return Promise.resolve();
            //     },
            // }]

        };

        const rule ={
            company_name:{required},
            email:{email},
            website:{url}
        }

        const ruless= computed(()=>{
            return {
                company_name:{required},
                email:{email},
                website:{url}
            }
        });

        const vuelidate = useVuelidate(ruless, addForm);
        

        const formatDisplayDate=(dateString : string)=>{
            return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
        }

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        

        //drawer
        const open = ref<boolean>(false);

        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showDrawer = (record:any) => {
            updateModalWidth();
            open.value = true;
            getSupplierById(record.id);
            
            /*
            form.company_name = record.company_name;
            form.vat_id = record.vat_id;
            form.contact_name = record.contact_name;
            form.billing_address = record.billing_address;
            form.shipping_address = record.shipping_address;
            form.vat_number = record.vat_number;
            form.website = record.website;
            form.email = record.email;
            form.created_at = record.created_at;
            form.updated_at = record.updated_at;
            form.id=record.id;
            console.log(record.name);
            */
        };

        const openAdd = ref<boolean>(false);

        const afterOpenAddChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const openDisplay = ref<boolean>(false);
        const showDisplayDrawer =(record:any)=>{
            updateModalWidth();
            openDisplay.value = true;
            getSupplierById(record.id);
        }


        const showAddDrawer = () => {
            updateModalWidth();
            openAdd.value = true;
        };

        const resetFormAdd = () => {
            addFormRef.value.resetFields();
        };

        const cancelUpdate=()=>{
            open.value=false;
        }

        const formatDate=(dateString:string)=>{
            return dayjs(dateString);
        }

        const showErrorNotification  = (message:string)=>{
            notification.error({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#FFBFC98A',
                    color: 'white'
                }
            });
        };

        const getSuppliers=()=>{
            loading.value = true;
            supplierService.getAllWithPagination(perPage.value, currentPage.value).then((response)=>{
                console.log("getting suppliers", response.data);
                state.data=response.data;
                state.totalPages=response.meta.total;

            }).catch((error)=>{
                console.log("error get suppliers", error);
            }).finally(()=>{
                loading.value = false;
            });
        }


      
        

        const addSupplier =()=>{

            addFormRef.value
            .validate()
            .then(()=>{
                loading.value = true;
                let payload={
                    company_name:addForm.company_name,
                    email:addForm.email,
                    website:addForm.website,
                    vat_number:addForm.vat_number,
                    contact_name:addForm.contact_name,
                    address:addForm.address,
                }
                supplierService.create(payload)
                .then((response)=>{
                    
                    //state.data.push(response.data);
                    showSuccessNotification(t('Pos.AddSupplierSuccess'));
                    getSuppliers();

                }).catch((err) => {
                    console.log(err);
                    showErrorNotification(t('Pos.Error'));
                }).finally(() => {
                    loading.value = false;
                    openAdd.value=false;
                    resetFormAdd();
                }); 
            }).catch((error:any) => {
                console.error('Validation error:', error);
            });
           
        }

        const updateSupplier =(id:string)=>{
            formRef.value
            .validate()
            .then(()=>{
                loading.value = true;
                supplierService.update(id, toRaw(form)).then((response)=>{
                    
                    state.data = state.data.map(el => el.id !== id ? el : response.data);
                    showSuccessNotification(t('Pos.UpdateSupplierSuccess'));
                    loading.value = false;


                }).catch((err) => {
                    console.log(err);
                    loading.value = false;

                }).finally(() => {
                    console.log('finish')
                    open.value=false;
                }); 
            })
        }

        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteSupplier'),
                text: t('Pos.TextDeleteSupplier'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t('Pos.Delete'),
                cancelButtonText: t('Pos.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteSupplier(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const deleteSupplier = (id: string) => {
            supplierService.delete(id).then((res) => {
                //state.data = state.data.filter(e => e.id !== id);
                showSuccessNotification(t('Pos.DeleteSupplierSuccess'));
                getSuppliers();

            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                console.log('finish')
            })
        };

        const getSupplierById = (id: string) => {
            loading.value=true;
            supplierService.show(id)
                .then(res => {
                    form.company_name = res.data.company_name;
                    form.vat_id = res.data.vat_id;
                    form.contact_name = res.data.contact_name;
                    form.address = res.data.billing_address;
                    form.vat_number = res.data.vat_number;
                    form.vat_number = res.data.vat_number;
                    form.website = res.data.website;
                    form.email = res.data.email;
                    form.created_at = res.data.created_at;
                    form.updated_at = res.data.updated_at;
                    form.id = res.data.id;

                    loading.value=false;
                })
                .catch(error => {
                    loading.value=false;
                    console.error('Error fetching supplier by id:', error);
                });
        }


        //search

        const filter=(key:string, value:string)=>{
            loading.value=true;
            currentPage.value=1; 
            supplierService.filter(key,value,currentPage.value, perPage.value)
            .then((res) => {
                state.data=res.data;
                state.totalPages = res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err)
            });
        }
        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            console.log('searched data', state.searchText);
            console.log('seareched column', state.searchedColumn);


            
            if (dataIndex === 'company_name' && state.searchText) {
                console.log('begin filter');
                state.searchName=state.searchText;
                filter(dataIndex,state.searchText);
            }
        };

        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.searchName='';
            state.beginSearch=false;
            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';

            getSuppliers();
        };

        const formatDateDrawer=(dateString:string)=>{
            return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
        }
        
        onMounted(() => {
            getSuppliers();
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');

            
        }); 
        const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };
        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        


       

        return {
            updateModalWidth,
            modalWidth,
            t,
            textDirection,
            state,
            columns,
            formRef,
            addFormRef,
            form,
            addForm,
            rules,
            formatDisplayDate,
            open,
            afterOpenChange,
            showDrawer,
            openAdd,
            afterOpenAddChange,
            showAddDrawer,
            resetFormAdd,
            cancelUpdate,
            formatDate,
            addSupplier,
            updateSupplier,
            deleteSupplier,
            showDeletePopup,
            navigatePage,
            ruless,
            vuelidate,
            openDisplay,
            showDisplayDrawer,
            loading,
            perPage,
            currentPage,
            handlePageChange,
            handleReset,
            handleSearch,
            formatDateDrawer,
            simpleImage,

            selectPagination,
            handleSelectPaginationChange,
            locale,








            
        };
    },
})
</script>

<style scoped>

.view-btn{
    color: #fc8019;
    border-color: #fc8019;
    background: #fafafa;
}

.btn-container{
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.add-btn{
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}
.add-btn:hover{
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.label-form{
  font-family: "Exo 2", sans-serif; 
  font-size: 14px;
  font-weight: 600;
}

.date-picker{
    width:100%
}

.table{
    width: 100%;
    height: 100%;
}
.breadcrumb-icon{
  cursor: pointer;
}
.error{
    color:#ff4d4f ;
    margin-top: -10px;

}

.info-title{
    font-size: 15px !important;
    font-weight: bold;
    margin-right: 4px;
}

.pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-btn:hover{
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
}

.is-rtl {
direction:rtl;
}

.paginationArrows {
direction:ltr;
}

.table-container {
  overflow-x: auto;
}

  
@media (max-width: 768px) {
    .pagination {
        flex-direction: column;
        align-items: flex-start;
    }

    .select-input {
        width: 100%;
        margin-top: 10px;
    }
}




</style>
