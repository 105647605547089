import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b5ec184"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu_divider = _resolveComponent("a-menu-divider")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    "has-sider": "",
    class: "admin-layout"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_sider, {
        collapsed: _ctx.collapsed,
        "onUpdate:collapsed": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.collapsed) = $event)),
        trigger: null,
        collapsible: "",
        class: "sider-layout"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/')))
            }, "POSYS")
          ]),
          _createVNode(_component_a_menu, {
            theme: "dark",
            openKeys: _ctx.openKeys,
            "onUpdate:openKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.openKeys) = $event)),
            selectedKeys: _ctx.selectedKeys,
            "onUpdate:selectedKeys": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedKeys) = $event)),
            mode: "inline"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_sub_menu, {
                title: "Store",
                key: "sub1",
                style: {"flex":"initial"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_menu_item, { key: "1" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 1")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, { key: "2" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 2")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, { key: "3" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 3")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, { key: "4" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 4")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_sub_menu, {
                title: "Warehouse",
                key: "sub2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_menu_item, { key: "5" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 5")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, { key: "6" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 6")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_sub_menu, {
                    key: "sub3",
                    title: "Submenu"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_menu_item, { key: "7" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Option 7")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_menu_item, { key: "8" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Option 8")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_menu_divider),
              _createVNode(_component_a_sub_menu, {
                title: "Navigation Three",
                key: "sub4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_menu_item, { key: "9" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 9")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, { key: "10" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 10")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, { key: "11" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 11")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, { key: "12" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 12")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_sub_menu, {
                title: "Group",
                key: "sub5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_menu_item, { key: "13" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 13")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_menu_item, { key: "14" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Option 14")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["openKeys", "selectedKeys"])
        ]),
        _: 1
      }, 8, ["collapsed"]),
      _createVNode(_component_a_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout_header, { style: {"background":"#fff","padding":"0"} }, {
            default: _withCtx(() => [
              (_ctx.collapsed)
                ? (_openBlock(), _createBlock(_component_menu_unfold_outlined, {
                    key: 0,
                    class: "trigger",
                    onClick: _cache[4] || (_cache[4] = () => (_ctx.collapsed = !_ctx.collapsed))
                  }))
                : (_openBlock(), _createBlock(_component_menu_fold_outlined, {
                    key: 1,
                    class: "trigger",
                    onClick: _cache[5] || (_cache[5] = () => (_ctx.collapsed = !_ctx.collapsed))
                  }))
            ]),
            _: 1
          }),
          _createVNode(_component_a_layout_content, { style: { margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' } }, {
            default: _withCtx(() => [
              _createTextVNode(" Content ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}