<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Dashboard") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.Invoice") }}</a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.deliveryNote") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer">
      {{ t("Pos.Add") }}
    </a-button>
  </div>

  <a-table :columns="columns" :data-source="state.data"  :pagination="false">
    <!-- search -->
    <template
      #customFilterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }"
    >
      <div style="padding: 8px">
        <template v-if="column.key === 'number'">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
        </template>

        <a-button
          type="primary"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          {{ t("Pos.Search") }}
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="handleReset(clearFilters)"
        >
          {{ t("Pos.Reset") }}
        </a-button>
      </div>
    </template>

    <template #customFilterIcon="{ filtered }">
      <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
    </template>
  </a-table>

  <!-- add drawer -->
  <a-drawer
    v-model:open="openAdd"
    class="custom-class"
    root-class-name="root-class-name"
    :placement="locale === 'ar' ? 'left' : 'right'"
    :width="modalWidth"
    @close="resetFormAdd"
  >
    <template #title>
      <p :class="{ 'is-rtl': textDirection === 'rtl' }">
        {{ t("Pos.AddDelivaryNote") }}
      </p>
    </template>

    <a-form
      ref="addFormRef"
      :model="addForm"
      :rules="rules"
      layout="vertical"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >

      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item name="noteType">
            <template #label>
              <span class="label-form">{{ t("Pos.Type") }}</span>
            </template>

            <a-select v-model:value="addForm.noteType" >
              <a-select-option value="manual">{{
                t("Pos.manual")
              }}</a-select-option>
              <a-select-option value="sales">{{
                t("Pos.fromSales")
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="12">

          <a-form-item name="carNumber">
            <template #label>
              <span class="label-form">{{ t("Pos.carNumber") }}</span>
            </template>
            <a-input  v-model:value="addForm.carNumber" :placeholder="t('Pos.carNumber')" class="input-form" />
          </a-form-item>

        </a-col>
        <a-col :span="12">
          <a-form-item name="driverName">
            <template #label>
              <span class="label-form">{{ t("Pos.driverName") }}</span>
            </template>
            <a-input  v-model:value="addForm.driverName" :placeholder="t('Pos.driverName')" class="input-form" />
          </a-form-item>

        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item name="details">

            <template #label>
              <span class="label-form">{{ t("Pos.details") }}</span>
            </template>

            <a-textarea
              v-model:value="addForm.detailes"
              :rows="3"
              :placeholder="t('Pos.details')"
              class="input-form"
            />
          </a-form-item>

        </a-col>

      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item name="date">

            <template #label>
              <span class="label-form">{{ t('Pos.delivaryDate') }}</span>
            </template>


            <a-date-picker class="date-input"  format="YYYY-MM-DD HH:mm" v-model:value="dateNote" show-time :placeholder="t('Pos.SelectDelivaryDate')" />

          </a-form-item>

        </a-col>
      </a-row>



      <template v-if="addForm.noteType==='manual'">

        <a-row :gutter="16" >
          <a-col :span="24">
            <a-form-item name="type">
              <template #label>
                <span class="label-form">{{ t("Pos.Type") }}</span>
              </template>

              <a-select v-model:value="addForm.type">
                <a-select-option value="external">{{
                  t("Pos.external")
                }}</a-select-option>
                <a-select-option value="internal">{{
                  t("Pos.internal")
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </template>

      <!-- internal -->
      <a-row v-if="addForm.type==='internal' && addForm.noteType==='manual'" :gutter="16" >
        <a-col :span="12">
          <a-form-item name="sender_stock">
            <template #label>
              <span class="label-form">{{ t('Pos.From') }} {{ t('Pos.Store') }}</span>
            </template>

            <a-select v-model:value="addForm.sender_store" show-search :option-filter-prop="'label'"   @select="selectedSender" class="store-select">
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>

              <a-select-option v-for="item in state.targertStore" :label="item.name" :key="item.id" :value="item.id" :selected="item.id === addForm.sender_store ">{{ item.name }}</a-select-option>
            </a-select>

          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="reciever_stock">
            <template #label>
              <span class="label-form">{{ t('Pos.To') }} {{ t('Pos.Store') }}</span>
            </template>
            <a-select v-model:value="addForm.reciever_store" show-search :option-filter-prop="'label'" :disabled="!addForm.sender_store" class="store-select">
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>
              <a-select-option v-for="item in state.stores" :label="item.name" :key="item.id" :value="item.id" :selected="item.id === addForm.reciever_store ">{{ item.name }}</a-select-option>
            </a-select>

          </a-form-item>
        </a-col>
      </a-row>
      
      <!-- external -->
      <a-row v-if="addForm.type==='external' && addForm.noteType==='manual'" :gutter="16">
        <a-col :span="12">
          <a-form-item name="sender_stock">
            <template #label>
              <span class="label-form">{{ t('Pos.From') }} {{ t('Pos.Client') }}</span>
            </template>

            <a-select 
              v-model:value="state.sender_client" 
              show-search 
              :default-active-first-option="false"
              :filter-option="false"
              @search="fetchClient"
              :options="state.targertClient"
              @select="selectedSenderClient" 
              class="store-select">


              <template v-if="state.fetchingTargetCustomer" #notFoundContent>
                <a-spin size="small" />
              </template>

            </a-select>

          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="reciever_stock">
            <template #label>
              <span class="label-form">{{ t('Pos.To') }} {{ t('Pos.Client') }}</span>
            </template>
            <a-select 
              v-model:value="state.reciever_client"
              show-search
              :default-active-first-option="false"
              :filter-option="false"
              @search="fetchReceiverClient"
              :options="state.clients"
              :disabled="!state.sender_client" class="store-select">

              <template v-if="state.fetchingReceiverCustomer" #notFoundContent>
                <a-spin size="small" />
              </template>
            </a-select>

          </a-form-item>
        </a-col>
      </a-row>

      <template v-if="addForm.noteType==='manual'">
        <a-row>
          <a-col :span="24">
            <a-form-item name="products">
              <template #label>
                <span class="label-form">{{ t("Pos.Products") }}</span>
              </template>

              <a-select
                v-model:value="state.value"
                mode="multiple"
                label-in-value
                :placeholder="t('Pos.Products')"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="state.fetching ? undefined : null"
                :options="state.products"
                @search="fetchProducts"
                @change="handleProductSelect"
              >
                <template v-if="state.fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-for="item in state.productsDelivary" :key="item">
          <a-col
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :md="{ span: 12 }"
            :lg="{ span: 12 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 12 }"
            class="ingr-name-container"
          >
            <span class="label-form">{{ item.label }}</span>
          </a-col>

          <a-col
            v-if="item.option.variants.length === 0"
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :md="{ span: 12 }"
            :lg="{ span: 12 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 12 }"
            class="ingr-content-container"
          >
            <a-form-item
              has-feedback
              :name="'qt_' + item.value"
              :validate-status="getValidatingStatus(item.value, 'qt')"
              :help="getValidatingHelp(item.value, 'qt')"
            >
              <a-input
                :id="'qt_' + item.value"
                :placeholder="t('Pos.Quantity')"
                v-model:value="item.quantity"
                class="input-form"
              />
            </a-form-item>
          </a-col>

          <a-col
            v-if="item.option.variants.length !== 0"
            :xs="{ span: 24 }"
            :sm="{ span: 24 }"
            :md="{ span: 12 }"
            :lg="{ span: 12 }"
            :xl="{ span: 12 }"
            :xxl="{ span: 12 }"
            class="ingr-content-container"
          >
            <a-form-item
              :validate-status="getValidatingStatus(item.item_id, 'unit')"
              :help="getValidatingHelp(item.item_id, 'unit')"
            >
              <a-select
                :id="'unit_' + item.value"
                mode="multiple"
                :placeholder="t('Pos.Variants')"
                show-search
                :option-filter-prop="'label'"
                @change="(value) =>handleSelectVarinats(item, value)"
              >
                <a-select-option
                  v-for="variant in item.option.variants"
                  :key="variant.id"
                  :label="variant.name"
                  :value="variant.id"
                  
                  >
                      {{ variant.name }}
                  </a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row v-for="variant in state.variantsValues" :key="variant.id">

          <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="ingr-name-container">
              <span class="label-form">{{variant.name}}</span>
          </a-col>
          
          <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 6}" :lg="{ span: 6 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="ingr-content-container">
              <a-form-item has-feedback  :name="'qt_' + variant.id" :validate-status="getValidatingStatus(variant.id,'qt')" :help="getValidatingHelp(variant.id,'qt')">
                  <a-input :id="'qt_' + variant.id" :placeholder="t('Pos.Quantity')" v-model:value="variant.quantity" class="input-form" />
              </a-form-item>
          </a-col>

        </a-row>  
      </template>
      

    </a-form>
    <template #footer>
      <a-space>
        <a-button type="primary" @click="add">{{ t("Pos.Add") }}</a-button>
        <a-button type="primary" danger @click="resetFormAdd">{{
          t("Pos.Cancel")
        }}</a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";

import {
  ref,
  defineComponent,
  reactive,
  onMounted,
  computed,
  watch,
} from "vue";
import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons-vue";
import { Empty } from "ant-design-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import type { Rule } from "ant-design-vue/es/form";
import { notification } from "ant-design-vue";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { customerService, productService, storeService, userService } from "@/_services";
import dayjs , { Dayjs }from 'dayjs'; 

export default defineComponent({
  name: "delivaryNoteIndex",
  components: {
    LbrxLoaderSpinner,
    SearchOutlined,
  },
  setup() {
    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    const modalWidth = ref(720);
    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const loading = ref(false);
    const { t } = useI18n();
    const searchInput = ref<HTMLInputElement | null>(null);
    const router = useRouter();

    const navigatePage = (link: string) => {
      router.push(link);
    };

    const state = reactive({
      searchText: "",
      searchedColumn: "",
      data: [] as any[],
      totalPages: 0,

      products: [] as any[],
      fetching: false,
      value: [] as any[],
      productsDelivary: [] as any[],

      variantsValues: [] as any[],

      targertStore:[] as any[],
      stores:[] as any[],
      target_store_id:'',


      targertClient:[] as any[],
      clients:[] as any[],
      target_client_id:'',

      sender_client:[] as any[],
      fetchingTargetCustomer:false,

      reciever_client:[] as any[],
      fetchingReceiverCustomer:false,


    });

    const columns = [
      {
        title: t("Pos.Number"),
        key: "number",
        dataIndex: "number",
        customFilterDropdown: true,

        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector(
                ".ant-table-filter-dropdown input"
              );

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
      },
      {
        title: t("Pos.Date"),
        key: "date",
        dataIndex: "date",
      },
      {
        title: t("Pos.Destination"),
        key: "destionation",
        dataIndex: "destionation",
      },
      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      },
    ];
    //form
    const addFormRef = ref();

    const dateNote = ref<Dayjs>();
    const addForm = reactive({
      id: "",
      type: "",
      date: dateNote,
      detailes:'',
      carNumber:'',
      driverName:'',
      sender_store: '',
      reciever_store: '',

      sender_client: '',
      reciever_client: '',

      noteType:'',

    });

    const rules: Record<string, Rule[]> = {
      noteType: [{ required: true, message: t("Pos.RequiredField") }],
      type: [{ required: true, message: t("Pos.RequiredField") }],
      date: [{ required: true, message: t("Pos.RequiredField") }],
    };

    //add darwer
    const openAdd = ref<boolean>(false);
    const showAddDrawer = () => {
      updateModalWidth();
      getStores();
      openAdd.value = true;
    };

    const add = () => {
      addFormRef.value
        .validate()
        .then(() => {
          console.log("type", addForm.type);
        })
        .catch(() => {
          console.log("error validation form fields");
        });
    };

    const resetFormAdd = () => {
      addFormRef.value.resetFields();
      state.clients=[];
      state.reciever_client=[];
      state.sender_client=[];
      state.targertClient=[];
      state.fetchingReceiverCustomer=false;
      state.fetchingTargetCustomer=false;
      state.fetching=false;
      state.products=[];
      state.value=[];
      state.productsDelivary=[];
      state.variantsValues=[];
      
    };


 
    const getStores=()=>{
      
      storeService.getMyStores().then((res)=>{
        state.targertStore=res.data;
        
      }).catch((error)=>{
        console.log('error getting stores', error);
      });
    }

    const selectedSender=(value:any, option:any)=>{
      state.target_store_id=value;
      
      state.stores = state.targertStore.filter(store => store.id !== value);
      if(state.stores.length===0){
        addForm.reciever_store='';
      }
    }

    let debonceTimerClient: ReturnType<typeof setTimeout> | null = null;
    const fetchClient = (value: any) => {
      //disable fetching when the input is empty
      if (value === "") {
        return;
      }

      state.targertClient = [];

      state.fetchingTargetCustomer = true;

      //clear previous timer
      if (debonceTimerClient) {
        clearTimeout(debonceTimerClient);
      }

      //fetch users

      debonceTimerClient = setTimeout(() => {
        
        customerService.filter("first_name", value)
          .then((res) => {
           const data = res.data.map((user: { first_name: any; last_name: any; id: any; }) => ({
              label: `${user.first_name} ${user.last_name}`,
              value: user.id,
            }));


            state.targertClient = data;

            console.log("stores", state.targertClient);

            state.fetchingTargetCustomer = false;

          })
          .catch((error) => {
            console.log("error fetching users", error);
          });
      }, 700);
    };

    watch(state.sender_client, () => {
      state.targertClient = [];
      state.fetchingTargetCustomer = false;
    });


   

    const selectedSenderClient=(value:any, option:any)=>{
      state.target_client_id=value;
      console.log('target customer', value);
      state.clients = state.targertClient.filter(client => client.value !== value);
      if(state.clients.length===0){
        addForm.reciever_client='';
        
      }

      console.log('state.clients', state.clients);
    }

    let debonceTimerReceiverClient: ReturnType<typeof setTimeout> | null = null;
    const fetchReceiverClient = (value: any) => {
      //disable fetching when the input is empty
      if (value === "") {
        return;
      }

      state.clients = [];

      state.fetchingReceiverCustomer = true;

      //clear previous timer
      if (debonceTimerReceiverClient) {
        clearTimeout(debonceTimerReceiverClient);
      }

      //fetch users

      debonceTimerReceiverClient = setTimeout(() => {
        
        customerService.filter("first_name", value)
          .then((res) => {
           const data = res.data.map((user: { first_name: any; last_name: any; id: any; }) => ({
              label: `${user.first_name} ${user.last_name}`,
              value: user.id,
            }));


            state.clients = data;
            //state.clients = data.filter((client: { value: string; }) => client.value !== state.target_client_id);


            console.log("stores", state.targertClient);

            state.fetchingReceiverCustomer = false;




          })
          .catch((error) => {
            console.log("error fetching users", error);
          });
      }, 700);
    };

    watch(state.reciever_client, () => {
      state.clients = [];
      state.fetchingReceiverCustomer = false;
    });









    //multiselect products
    let debounceTimer: ReturnType<typeof setTimeout> | null = null;

    const fetchProducts = (value: any) => {
      //disable fetching when the input is empty
      if (value === "") {
        return;
      }

      state.products = [];

      state.fetching = true;

      //clear previous timer
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      //fetch products

      debounceTimer = setTimeout(() => {
        productService
          .getProductsByName(value)
          .then((res) => {
            const data = res.data.map(
              (product: {
                name: any;
                id: any;
                variants_by_attribute_type: any;
              }) => {
                // processing variants
                const variants = product.variants_by_attribute_type.flatMap(
                  (variantType: any) =>
                    variantType.attribute_values.map((attributeValue: any) => ({
                      id: attributeValue.id,
                      name: `${attributeValue.name} (${variantType.name})`,
                      value: attributeValue.value,
                    }))
                );

                return {
                  label: `${product.name}`,
                  value: product.id,
                  variants: variants,
                };
              }
            );

            state.products = data;
            state.fetching = false;

            console.log("products", state.products);

            console.log("state.value : ", state.value);
          })
          .catch((error) => {
            console.log("error fetching products", error);
          });
      }, 700);
    };

    watch(state.value, () => {
      state.products = [];
      state.fetching = false;
    });

    const handleProductSelect = (value: any[]) => {
      console.log("selected user", value);
      const existingProducts = state.productsDelivary;

      value.forEach((selectedProduct) => {
        const existingProduct = existingProducts.find(
          (product) => product.value === selectedProduct.value
        );
        if (!existingProduct) {
          existingProducts.push({ ...selectedProduct, quantity: "" });
        }
      });

      state.productsDelivary = existingProducts.filter((product) =>
        value.some((selectedProduct) => selectedProduct.value === product.value)
      );

      console.log("state.productsDelivary", state.productsDelivary);
    };

    interface ValidationStatus {
      [key: string]: string;
    }

    interface ValidationHelp {
      [key: string]: string;
    }

    const validationStatus = ref<ValidationStatus>({});

    const validationHelp = ref<ValidationHelp>({});

    const getValidatingStatus = (id: any, fieldType: string) => {
      return validationStatus.value[`${fieldType}_${id}`] || "";
    };

    const getValidatingHelp = (id: any, fieldType: string) => {
      return validationHelp.value[`${fieldType}_${id}`] || "";
    };

    function isNumber(value: any) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    }

    const handleSelectVarinats=(item:any,selectedValues:any[])=>{
        console.log('selected values', selectedValues, 'form item', item);

        state.variantsValues = state.variantsValues.filter((variant) => 
            selectedValues.includes(variant.id) || variant.item_id !== item.item_id
        );

        selectedValues.forEach((value) => {
            const existingVariant = state.variantsValues.find((variant) => variant.id === value && variant.item_id === item.item_id);
            if (!existingVariant) {
                const variantDetails = item.option.variants.find((variant: { id: any; }) => variant.id === value);
                if (variantDetails) {
                    state.variantsValues.push({
                    item_id: item.item_id,
                    id: variantDetails.id,
                    name: variantDetails.name,
                    });
                }
            }
        });
            

        
    }

    return{
      state,
      modalWidth,
      simpleImage,
      loading,
      t,
      searchInput,
      navigatePage,
      columns,
      openAdd,
      showAddDrawer,
      addFormRef,
      addForm,
      rules,
      resetFormAdd,
      add,
      textDirection,
      locale,
      fetchProducts,
      handleProductSelect,
      getValidatingStatus,
      getValidatingHelp,
      handleSelectVarinats,
      selectedSender,
      selectedSenderClient,
      fetchClient,
      fetchReceiverClient,
      dateNote,

    };
  },
});
</script>

<style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.breadcrumb-icon {
  cursor: pointer;
}
.input-form {
  color: black;
  background-color: #fff;
}
.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.range-picker {
  width: 100%;
}

.ingredient-des {
  margin-bottom: 15px;
}

.search-radio-grp {
  display: flex;
  flex-direction: column !important;
}

.radio-item {
  margin-bottom: 10px;
}

/* CSS for RTL */
.is-rtl {
  direction: rtl;
}

.paginationArrows {
  direction: ltr;
}

.table-container {
  overflow-x: auto;
}

.store-select{
  width: 100%;
}
.date-input{
  width: 100%;
}
@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
