import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76e8cfc1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onMousedown", "onClick"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_qrcode = _resolveComponent("a-qrcode")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Menu')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", {
      class: "preview-container",
      ref: "previewContainer",
      style: _normalizeStyle(`--bg-image: url('${_ctx.backgroundImageUrl}')`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "draggable",
          style: _normalizeStyle(_ctx.getElementStyle(element)),
          onMousedown: ($event: any) => (_ctx.startDrag($event, element)),
          onClick: ($event: any) => (_ctx.selectElement(element))
        }, [
          (element.type === 'text')
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                contenteditable: "true",
                class: "elet",
                onClick: ($event: any) => (_ctx.selectElement(element))
              }, _toDisplayString(element.content), 9, _hoisted_2))
            : _createCommentVNode("", true)
        ], 44, _hoisted_1))
      }), 128)),
      _createElementVNode("div", {
        class: "qr-code-container",
        ref: "qrCodeContainer",
        style: _normalizeStyle(_ctx.qrCodeStyle)
      }, [
        _createVNode(_component_a_space, {
          direction: "vertical",
          align: "center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_qrcode, {
              value: _ctx.url,
              size: 150,
              onMousedown: _ctx.startDragQr,
              color: _ctx.state.qrColor,
              "bg-color": _ctx.state.qrBg
            }, null, 8, ["value", "onMousedown", "color", "bg-color"])
          ]),
          _: 1
        })
      ], 4)
    ], 4)
  ], 64))
}