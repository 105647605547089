<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Home") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ t("Pos.Sales") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-space>
      <a-button class="add-btn" @click="exportReport()">
        {{ t("Pos.Export") }}
        <template #icon>
          <DownloadOutlined />
        </template>
      </a-button>

      <a-button
        class="add-btn"
        @click="multiselect()"
        v-if="form.countselectedRowIds > 0"
      >
        {{ t("Pos.InvoiceDownload") }}
        <template #icon>
          <SnippetsOutlined />
        </template>
      </a-button>
    </a-space>
  </div>

  <div class="btn-container"></div>
  <div>
    <a-table
      class="table-container"
      :columns="columns"
      :data-source="reactiveData.data"
      :pagination="false"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <a-space>
            <a-tooltip>
              <template #title>{{ t("Pos.View") }}</template>
              <a-button type="primary" @click="showDrawer(record)">
                <template #icon>
                  <EyeOutlined />
                </template>
              </a-button>
            </a-tooltip>

            <a-tooltip>
              <template #title>{{ t("Pos.Invoice") }}</template>
              <a-button class="report-btn" @click="onSubmitInvoice(record)">
                <template #icon>
                  <SnippetsOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </a-space>
        </template>

        <template
          v-if="column.key === 'created_at' || column.key === 'updated_at'"
        >
          {{ formatDisplayDate(record[column.key]) }}
        </template>

        <template v-if="column.key == 'fullname'">
          {{ formatcustomer(record) }}
        </template>

        <template v-if="column.key == 'device'">
          {{ formatDeviceName(record) }}
        </template>

        <template v-if="column.key == 'currency'">
          {{ formatcurrency_code(record) }}
        </template>

        <template v-if="column.key == 'total'">
          {{ formatTotal(record) }}
        </template>

        <template v-if="column.key === 'status'">
          <a-tag :bordered="false" color="error" v-if="record.is_paid == '0'">
            <template #icon>
              <check-circle-outlined />
            </template>
            {{ t("Pos.unpaid") }}
          </a-tag>
          <a-tag :bordered="false" color="success" v-if="record.is_paid == '1'">
            <template #icon>
              <check-circle-outlined />
            </template>
            {{ t("Pos.paid") }}
          </a-tag>
        </template>

        <template v-if="column.key === 'checkbox'">
          <a-checkbox
            v-model="record.checked"
            @change="handleCheckboxChange(record)"
          />
        </template>
      </template>

      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon>
              <SearchOutlined />
            </template>
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ t("Pos.Reset") }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        class="paginationArrows"
        model:current="currentPage"
        :total="form.customLastPage"
        @change="handlePageChange"
        :default-page-size="perPage"
        :showSizeChanger="false"
      />
      <a-select
        class="select-input"
        v-model:value="customPagination"
        placeholder="Order By"
        @change="choosePerPage"
        v-if="form.customLastPage > 10"
      >
        <a-select-option value="10">10 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="20">20 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="50">50 / {{ t("Pos.page") }}</a-select-option>
        <a-select-option value="100">100 / {{ t("Pos.page") }}</a-select-option>
      </a-select>
    </div>

    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.saleDetails") }}</span>
        </div>
        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.saleDetails") }}</span>
        </div>
      </template>

      <a-form
        ref="formRef"
        :model="form"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <template #title>
          <div class="drawer-header-ar" v-if="locale === 'ar'">
            <span>{{ t("Pos.DetailsWarehouseDrawer") }}</span>
          </div>
          <div class="drawer-header-all" v-if="locale !== 'ar'">
            <span>{{ t("Pos.DetailsWarehouseDrawer") }}</span>
          </div>
        </template>

        <a-row :gutter="16">
          <a-col :span="12">
            <p>
              <span class="info-title" v-if="form.name != ''"
                >{{ t("Pos.Name") }} :</span
              >
              {{ form.name }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" v-if="form.email != ''"
                >{{ t("Pos.Email") }} :</span
              >
              {{ form.email }}
            </p>
          </a-col>
        </a-row>

        <a-row :gutter="16">
            
          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.Username") }} :</span>
              {{ form.username }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.Price") }} :</span>
              {{ form.price }}
            </p>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.Status") }} :</span>
              <a-tag
                :bordered="false"
                color="success"
                v-if="form.status === 'paid'"
              >
                <template #icon>
                  <check-circle-outlined />
                </template>
                {{ t("Pos.paid") }}
              </a-tag>
              <a-tag
                :bordered="false"
                color="error"
                v-if="form.status === 'unpaid'"
              >
                <template #icon>
                  <check-circle-outlined />
                </template>
                {{ t("Pos.unpaid") }}
              </a-tag>
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.discountType") }} :</span>
              {{ form.discount_type }}
            </p>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="12">
            <p>
              <span class="info-title">{{ t("Pos.discount") }} :</span>
              {{ form.discount }}
            </p>
          </a-col>
        </a-row>

        <a-row>
            <a-col :span="24">
                <p>
                    <span class="info-title">{{ t("Pos.Products") }} :</span>
                </p>
            </a-col>
        </a-row>

        <template v-for="(sales, id) in form.products" :key="id">
            <a-descriptions bordered size="small" class="table-margin">
              <a-descriptions-item :label="t('Pos.Name')" :span="3">{{
                sales.product.name
              }}</a-descriptions-item>
              <a-descriptions-item :label="t('Pos.Description')" :span="3">{{
                sales.product.description
              }}</a-descriptions-item>
              <a-descriptions-item :label="t('Pos.Price')" :span="3">{{
                sales.product.full_price
              }}</a-descriptions-item>
            </a-descriptions>
        </template>

      </a-form>
    </a-drawer>

    <a-drawer
      v-model:open="openAdd"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
      @close="resetFields"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.GetInvoice") }}</span>
        </div>
        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.GetInvoice") }}</span>
        </div>
      </template>

      <a-form
        ref="addFormRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item name="customer_id">
              <template #label>
                <span class="label-form">{{ t("Pos.Customer") }}</span>
              </template>
              <a-select
                show-search
                :placeholder="t('Pos.Customer')"
                v-model:value="form.customer_id"
                @input="handleInput"
                @change="handleChange"
                :key="selectKey"
                :open="isSelectOpen"
              >
                <template
                  #suffixIcon
                  v-if="customers.length === 0 && startedTyping === true"
                >
                  <LoadingOutlined />
                </template>

                <template
                  #notFoundContent
                  v-if="customers.length === 0 && startedTyping === true"
                >
                  <LoadingOutlined /> {{ t("Pos.FilterCustomers") }}
                </template>
                <a-select-option
                  v-for="customer in customers"
                  :key="customer.id"
                  :value="customer.id"
                  :selected="customer.id === form.customer_id"
                  >{{ customer.first_name }}
                  {{ customer.last_name }}</a-select-option
                >
                <!-- <a-select-option v-for="customer in customers" :key="customer.id" :value="customer.id" :selected="customer.id === addForm.customer_id ">{{ customer.first_name }} {{ customer.last_name }}</a-select-option> -->
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="invoice_date">
              <template #label>
                <span class="label-form">{{ t("Pos.InvoiceDate") }}</span>
              </template>
              <a-date-picker
                :placeholder="t('Pos.InvoiceDate')"
                v-model:value="form.invoice_date"
                class="range-picker"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item name="notes">
              <template #label>
                <span class="label-form">{{ t("Pos.Notes") }}</span>
              </template>
              <a-textarea
                v-model:value="form.notes"
                :rows="4"
                :placeholder="t('Pos.Notes')"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <div v-if="locale === 'ar'" class="is-rtl">
          <a-space v-if="reactiveData.action == 'update'"> </a-space>
          <a-space v-else-if="reactiveData.action == 'add'">
            <a-button type="primary" @click="createInvoice">{{
              t("Pos.Add")
            }}</a-button>
            <a-button type="primary" danger @click="hideForm">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>

          <a-space v-else>
            <a-button type="primary" danger @click="hideForm">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>

        <div v-if="locale !== 'ar'" class="is-ltr">
          <a-space v-if="reactiveData.action == 'update'"> </a-space>
          <a-space v-else-if="reactiveData.action == 'add'">
            <a-button type="primary" @click="createInvoice">{{
              t("Pos.Add")
            }}</a-button>
            <a-button type="primary" danger @click="hideForm">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>

          <a-space v-else>
            <a-button type="primary" danger @click="hideForm">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>
      </template>
    </a-drawer>

    <a-drawer
      v-model:open="openMultiple"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
      @close="resetFieldsMulitpleSelection"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.GetInvoice") }}</span>
        </div>
        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.GetInvoice") }}</span>
        </div>
      </template>

      <a-form
        ref="addFormRefForMultiple"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item name="customer_id">
              <template #label>
                <span class="label-form">{{ t("Pos.Customer") }}</span>
              </template>

              <a-select
                show-search
                :placeholder="t('Pos.Customer')"
                v-model:value="form.customer_id"
                @input="handleInput"
                @change="handleChange"
                :key="selectKey"
                :open="isSelectOpen"
              >
                <template
                  #suffixIcon
                  v-if="customers.length === 0 && startedTyping === true"
                >
                  <LoadingOutlined />
                </template>

                <template
                  #notFoundContent
                  v-if="customers.length === 0 && startedTyping === true"
                >
                  <LoadingOutlined /> {{ t("Pos.FilterCustomers") }}
                </template>

                <a-select-option
                  v-for="customer in customers"
                  :key="customer.id"
                  :value="customer.id"
                  :selected="customer.id === form.customer_id"
                  >{{ customer.first_name }}
                  {{ customer.last_name }}</a-select-option
                >

                <!-- <a-select-option v-for="customer in customers" :key="customer.id" :value="customer.id" :selected="customer.id === addForm.customer_id ">{{ customer.first_name }} {{ customer.last_name }}</a-select-option> -->
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="invoice_date">
              <template #label>
                <span class="label-form">{{ t("Pos.InvoiceDate") }}</span>
              </template>
              <a-date-picker
                :placeholder="t('Pos.InvoiceDate')"
                v-model:value="form.invoice_date"
                class="range-picker"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item name="notes">
              <template #label>
                <span class="label-form">{{ t("Pos.Notes") }}</span>
              </template>
              <a-textarea
                v-model:value="form.notes"
                :rows="4"
                :placeholder="t('Pos.Notes')"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <div v-if="locale !== 'ar'" class="is-ltr">
          <a-space v-if="reactiveData.action == 'update'"> </a-space>
          <a-space v-else-if="reactiveData.action == 'add'">
            <a-button type="primary" @click="createInvoiceMultipleSelection">{{
              t("Pos.Add")
            }}</a-button>
            <a-button type="primary" danger @click="hideFormMultiple">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>

          <a-space v-else>
            <a-button type="primary" danger @click="hideFormMultiple">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>

        <div v-if="locale === 'ar'" class="is-rtl">
          <a-space v-if="reactiveData.action == 'update'"> </a-space>
          <a-space v-else-if="reactiveData.action == 'add'">
            <a-button type="primary" @click="createInvoiceMultipleSelection">{{
              t("Pos.Add")
            }}</a-button>
            <a-button type="primary" danger @click="hideFormMultiple">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>

          <a-space v-else>
            <a-button type="primary" danger @click="hideFormMultiple">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>
      </template>
    </a-drawer>

    <!-- modal transfer -->
    <a-modal
      v-model:open="openTransferModal"
      @ok="handleTransferOk"
      @cancel="closeModal"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <template #title>
        <div class="drawer-header-ar-padding" v-if="locale === 'ar'">
          <span>{{ t("Pos.ExportSales") }}</span>
        </div>
        <div class="drawer-header-all-padding" v-if="locale !== 'ar'">
          <span>{{ t("Pos.ExportSales") }}</span>
        </div>
      </template>

      <template #footer>
        <div v-if="locale === 'ar'" class="is-ltr">
          <a-button key="submit" type="primary" @click="handleTransferOk">{{
            t("Pos.downloadFile")
          }}</a-button>
          <a-button key="back" @click="closeModal">{{
            t("Pos.Cancel")
          }}</a-button>
        </div>

        <div v-if="locale !== 'ar'" class="is-rtl">
          <a-button key="submit" type="primary" @click="handleTransferOk">{{
            t("Pos.downloadFile")
          }}</a-button>
          <a-button key="back" @click="closeModal">{{
            t("Pos.Cancel")
          }}</a-button>
        </div>
      </template>
      <a-form
        ref="formSalesRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item name="startDateForExport">
              <template #label>
                <span class="label-form">{{ t("Pos.startDate") }}</span>
              </template>
              <a-date-picker
                :placeholder="t('Pos.startDate')"
                v-model:value="form.startDateForExport"
                class="range-picker"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item name="endDateForExport">
              <template #label>
                <span class="label-form">{{ t("Pos.endDate") }}</span>
              </template>
              <a-date-picker
                :placeholder="t('Pos.endDate')"
                v-model:value="form.endDateForExport"
                class="range-picker"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item name="orderBy">
              <template #label>
                <span class="label-form">{{ t("Pos.OrderBy") }}</span>
              </template>
              <a-select
                v-model:value="form.orderBy"
                placeholder="Order By"
                class="input-form"
              >
                <a-select-option value="name">{{
                  t("Pos.Name")
                }}</a-select-option>
                <a-select-option value="id">{{ t("Pos.id") }}</a-select-option>
                <a-select-option value="unit">{{
                  t("Pos.unit")
                }}</a-select-option>
                <a-select-option value="price">{{
                  t("Pos.price")
                }}</a-select-option>
                <a-select-option value="total">{{
                  t("Pos.total")
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="order">
              <template #label>
                <span class="label-form">{{ t("Pos.OrderBy") }}</span>
              </template>
              <a-select
                v-model:value="form.order"
                placeholder="Order"
                class="input-form"
              >
                <a-select-option value="asc">{{
                  t("Pos.Ascending")
                }}</a-select-option>
                <a-select-option value="desc">{{
                  t("Pos.Descending")
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item name="export_type">
              <template #label>
                <span class="label-form">{{ t("Pos.ExportType") }}</span>
              </template>
              <a-select
                v-model:value="form.export_type"
                placeholder="Order"
                class="input-form"
              >
                <a-select-option value="pdf">pdf</a-select-option>
                <a-select-option value="excel">excel</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item name="user">
              <template #label>
                <span class="label-form">{{ t("Pos.Customer") }}</span>
              </template>
              <a-select
                v-model:value="form.user"
                :placeholder="t('Pos.paymentType')"
                class="input-form"
                show-search
                :option-filter-prop="'label'"
              >
                <a-select-option
                  v-for="user in reactiveDataForUser.data"
                  :key="user.id"
                  :value="user.id"
                  :label="user.first_name + ' ' + user.last_name"
                >
                  {{ user.first_name + " " + user.last_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item name="device">
              <template #label>
                <span class="label-form">{{ t("Pos.Device") }}</span>
              </template>
              <a-select
                v-model:value="form.device"
                :placeholder="t('Pos.paymentType')"
                class="input-form"
                show-search
                :option-filter-prop="'label'"
              >
                <a-select-option
                  v-for="device in reactiveDataForDevice.data"
                  :key="device.id"
                  :value="device.id"
                  :label="device.name"
                >
                  {{ device.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
    <script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRaw,
  watch,
  onBeforeUnmount,
} from "vue";

import type { Rule } from "ant-design-vue/es/form";
import Swal from "sweetalert2";

import {
  EyeOutlined,
  SearchOutlined,
  LoadingOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  SnippetsOutlined,
} from "@ant-design/icons-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import { notification } from "ant-design-vue";
import {
  storeService,
  customerService,
  deviceService,
  userService,
} from "@/_services";
import {
  paymentMethodsService,
  salesService,
  invoiceService,
} from "@/_services";
import dayjs from "dayjs";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { currencyConfig } from "@/_helpers";
import { computed } from "vue";

export default defineComponent({
  name: "indexStore",
  components: {
    LoadingOutlined,
    EyeOutlined,
    LbrxLoaderSpinner,
    SearchOutlined,
    CheckCircleOutlined,
    DownloadOutlined,
    SnippetsOutlined,
  },
  setup() {
    const modalWidth = ref(720);
    const popupWidth = ref(729);
    const customPagination = ref("10");
    const { locale } = useI18n();

    const customStyle =
      "background: none !important;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden";

    const isSelectOpen = ref(false);

    let debounceTimer: ReturnType<typeof setTimeout> | null = null;
    let startedTyping = ref(false);
    const handleInput = async (event: InputEvent) => {
      startedTyping.value = true;
      //console.log('started typing', startedTyping);
      isSelectOpen.value = true;
      const input = (event.target as HTMLInputElement).value;
      // console.log('input',input);
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      debounceTimer = setTimeout(() => {
        getCustomers(input);

        //console.log('call api');
        //handleFilterOption(input);
      }, 800); //mss
    };
    const customers = ref([]);
    const selectKey = ref(0);

    const getCustomers = (value: string) => {
      //console.log('begin customers');
      return customerService
        .filter("name", value)
        .then((rep) => {
          // console.log('rep.data : ', rep.data);

          //state.customers=rep.data;
          customers.value = rep.data;
          selectKey.value += 1;
          isSelectOpen.value = true;
          //console.log('reactive display data', customers.value);
        })
        .catch((error) => {
          //console.log("error filter customers", error);
        });
    };

    const handleChange = (value: string) => {
      //console.log(`selected ${value}`);
      isSelectOpen.value = false;
    };

    const openTransferModal = ref<boolean>(false);
    const searchInput = ref<HTMLInputElement | null>(null);
    const loading = ref(false);
    const router = useRouter();
    const navigatePage = (link: string) => {
      router.push(link);
    };

    const { t } = useI18n();
    const columns = [
      {
        title: t("Pos.SaleId"),
        key: "id",
        dataIndex: "id",
      },

      {
        title: t("Pos.Client"),
        key: "fullname",
        dataIndex: "fullname",
        responsive: ["md"],
      },

      {
        title: t("Pos.Total"),
        key: "total",
        dataIndex: "total",
        responsive: ["md"],
      },

      {
        title: t("Pos.Status"),
        key: "status",
        dataIndex: "status",
      },

      {
        title: t("Pos.Device"),
        key: "device",
        dataIndex: "device",
        responsive: ["md"],
      },
      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      },

      {
        title: "#",
        key: "checkbox",
        dataIndex: "checkbox",
      },
    ];

    //form
    const formRef = ref();
    const visible = ref<boolean>(false);
    const addFormRefForMultiple = ref();
    const addFormRef = ref();

    const reactiveData = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchedColumn: "",
      customers: [] as any[],
      searchText: "",
    });

    const reactiveDataForUser = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchedColumn: "",
      customers: [] as any[],
      searchText: "",
    });

    const reactiveDataForDevice = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchedColumn: "",
      customers: [] as any[],
      searchText: "",
    });

    const reactiveData2 = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchedColumn: "",
      searchText: "",
    });

    const form = reactive({
      id: "",
      countSelected: false,
      name: "",
      description: "",
      address: "",
      phone: "",
      email: "",
      username: "",
      password: "",
      payment_method_id: "",
      config: "",
      status: "",
      accountNumber: "",
      active: "",

      customer: "",
      discount_type: "",
      discount: "",
      price: "",
      product1: "",
      product2: "",
      saleDetails: [],
      loyaltyPoints: 0,
      productname: "",
      productdescription: "",
      user_id: "Anonymes",
      customer_id: "",
      sale_id: "",
      invoice_number: "",
      subtotal: "",
      vat: "",
      total: "",
      notes: " ",
      invoice_date: "",
      selectedRowIds: "",
      countselectedRowIds: 0,
      is_paid: 0,
      startDate: "",
      endDateForExport: "",
      startDateForExport: "",
      orderBy: "",
      order: "",
      export_type: "",
      user: "",
      device: "",
      products: {},
      customLastPage: 0,
    });

    const Days = (date: string) => {
      form.invoice_date = new Date().toISOString().substr(0, 10);
    };

    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });
    const resetForm = () => {
      form.name = "";
      form.description = "";
      form.address = "";
      form.phone = "";
      form.email = "";
      form.username = "";
      form.status = "";
      form.password = "";
      form.active = "";
      form.price = "";
      form.discount_type = "";
      form.discount = "";
      form.product1 = "";
      form.productdescription = "";
      form.loyaltyPoints = 0;
      form.productname = "";
      (form.invoice_date = ""), (form.customer_id = "");
    };

    const closeModal = () => {
      openTransferModal.value = false;
      formSalesRef.value.resetFields();
      (form.startDateForExport = ""),
        (form.endDateForExport = ""),
        (form.order = ""),
        (form.orderBy = "");
    };

    const resetModal = () => {
      formSalesRef.value.resetFields();
      (form.startDateForExport = ""),
        (form.endDateForExport = ""),
        (form.order = ""),
        (form.orderBy = "");
    };

    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);

    const formatDate = (dateString: string) => {
      return dayjs(dateString);
    };

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    };

    const handleTransferOk = () => {
      formSalesRef.value
        .validate()
        .then(() => {
          createBlop();
          resetModal();
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    };

    const rules: Record<string, Rule[]> = {
      customer_id: [{ required: true, message: t("Pos.CustomerValidation") }],
      invoice_date: [
        { required: true, message: t("Pos.InvoiceDateValidation") },
      ],
      startDateForExport: [
        { required: true, message: t("Pos.StartDateExport") },
      ],
      endDateForExport: [{ required: true, message: t("Pos.EndDateExport") }],
      orderBy: [{ required: true, message: t("Pos.orderBy") }],
      order: [{ required: true, message: t("Pos.orderBy") }],
      export_type: [{ required: true, message: t("Pos.ExportTypeValidation") }],
    };
    const resetFields = () => {
      addFormRef.value.resetFields();
    };

    const resetFieldsMulitpleSelection = () => {
      addFormRefForMultiple.value.resetFields();
    };

    const choosePerPage = () => {
      currentPage.value = 1;
      // currentPage.value = newPage;
      loading.value = true;
      //console.log('per page', perPage.value);
      //console.log('page', currentPage.value)
      salesService
        .getAll(currentPage.value, customPagination.value)
        .then((res) => {
          reactiveData.data = res.data;
          form.customLastPage = res.meta.last_page * 10;
        })
        .catch((error: any) => {
          console.log("");
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const createBlop = () => {
      const payload = {
        endDateForExport: form.endDateForExport,
        startDateForExport: form.startDateForExport,
      };
      loading.value = true;
      salesService
        .exportFile(
          formatToStringDate(form.startDateForExport),
          formatToStringDate(form.endDateForExport),
          form.orderBy,
          form.order,
          form.export_type,
          form.user,
          form.device
        )
        .then((blob) => {
          // Log the form values here
          console.log(
            "Start Date:",
            formatToStringDate(payload.endDateForExport)
          );

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "sales-report" +
            "-" +
            formatToStringDate(payload.startDateForExport) +
            "-" +
            formatToStringDate(payload.endDateForExport);
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          openTransferModal.value = false;
          showSuccessNotification(t("Pos.exportFile"));
        })
        .catch((error) => {
          // Handle errors
          console.error("Error exporting file:", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const formatcustomer = (dateString: any) => {
      if (dateString && dateString.customer) {
        if (dateString.customer.first_name) {
          return (
            dateString.customer.first_name + " " + dateString.customer.last_name
          );
        }
      }
      return t("---");
    };

    const formatcurrency_code = (dateString: any) => {
      if (dateString && dateString.payments && dateString.payments.length > 0) {
        // Accessing the currency code from the first payment object
        return dateString.payments[0].currency.code;
      } else {
        return "";
      }
    };

    const formatTotal = (dateString: any) => {
      if (dateString && dateString.total) {
        const currenciesString = localStorage.getItem("currencies");
        if (currenciesString === null) {
          return;
        }
        const currencies = JSON.parse(currenciesString);
        const currencyCode = currencies.code;
        const decimalPrecision = 3;
        return currencyConfig.formatAmount(
          dateString.total,
          currencyCode,
          decimalPrecision
        );
      }
    };

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();
      reactiveData.searchText = selectedKeys[0];
      reactiveData.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      reactiveData.searchText = "";
      customPagination.value = "10";
    };
    //drawers

    const open = ref<boolean>(false);

    const showAddDrawer = (state: boolean) => {
      open.value = state;
      reactiveData.action = "add";
      resetForm();
    };

    const closeOpens = () => {
      openMultiple.value = false;
      open.value = false;
      openAdd.value = false;
    };

    const exportReport = () => {
      openTransferModal.value = true;
      resetForm();
    };

    const afterOpenChange = (bool: boolean) => {
      //console.log('open', bool);
    };

    const showDrawer = (record: any) => {
      updateModalWidth();
      resetForm();
      open.value = true;
      getSalesByid(record.id);
      reactiveData.action = "update";
    };

    const hideForm = (bool: boolean) => {
      openAdd.value = false;
      open.value = false;
      resetFields();
    };

    const hideFormMultiple = (bool: boolean) => {
      openMultiple.value = false;
      resetFieldsMulitpleSelection();
    };

    const openAdd = ref<boolean>(false);
    const openMultiple = ref<boolean>(false);

    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    /*   const getStore = () => {
                storeService.getAll()
                    .then((res) => {
                        reactiveData.data = res.data;
                    })
                    .catch((error: any) => {
                        console.error('Error fetching Stores:', error);
                    });
            }*/

    const getPaymentMethods = () => {
      paymentMethodsService
        .getAll(currentPage.value, perPage.value)
        .then((res) => {
          reactiveData.data = res.data;
          //console.log(res.data)
        })
        .catch((error: any) => {
          //console.error('Error fetching Stores:', error);
        });
    };

    const formatToStringDate = (date: any) => {
      if (!date || !date.$d) {
        return "";
      }

      const year = date.$y;
      const month = date.$M + 1;
      const day = date.$D < 10 ? "0" + date.$D : date.$D;

      const stringDate = `${year}-${month < 10 ? "0" : ""}${month}-${day}`;
      return stringDate;
    };

    const formatDeviceName = (dateString: any) => {
      if (dateString && dateString.device) {
        if (dateString.device.name) {
          return dateString.device.name;
        }
      }
      return "";
    };

    const handleCheckboxChange = (record: any) => {
      record.checked = !record.checked;

      if (record.checked) {
        if (form.selectedRowIds) {
          form.selectedRowIds += "," + record.id;
        } else {
          form.selectedRowIds = record.id.toString();
        }
        form.countselectedRowIds += 1;
      } else {
        const ids = form.selectedRowIds.split(",");
        const index = ids.indexOf(record.id.toString());
        if (index !== -1) {
          ids.splice(index, 1);
          form.selectedRowIds = ids.join(",");
        }
        form.countselectedRowIds -= 1;
      }
    };

    const getAllSales = () => {
      loading.value = true;
      salesService
        .getAll(currentPage.value, perPage.value)
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
          form.customLastPage = res.meta.last_page * 10;
        })
        .catch((error: any) => {
          console.log("");
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getAllDevices = () => {
      loading.value = true;
      deviceService
        .getAll()
        .then((res) => {
          reactiveDataForDevice.data = res.data;
        })
        .catch((error: any) => {
          console.log("");
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getAllUsers = () => {
      loading.value = true;
      userService
        .getUserWithoutPagination()
        .then((res) => {
          reactiveDataForUser.data = res.data;
        })
        .catch((error: any) => {
          console.log("");
        })
        .finally(() => {
          loading.value = false;
        });
    };

    let timeoutId: any;

    const getAllCustomers = (searchValue: string) => {
      loading.value = true;
      customerService
        .searchWithoutPagination("name", "")
        .then((res) => {
          reactiveData2.data = res.data;
          // console.log(res.data);
        })
        .catch((error) => {
          //console.log("error api: get sales", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const debounceSearch = (func: any, delay: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(func, delay);
    };

    const handleSearchForSelect = (value: string) => {
      debounceSearch(() => {
        getAllCustomers(value);
      }, 300); // Adjust the delay time as needed (300ms in this example)
    };

    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;
      loading.value = true;

      //    console.log('per page', perPage.value);
      //   console.log('page', currentPage.value)

      salesService
        .getAll(currentPage.value, customPagination.value)
        .then((response) => {
          //  console.log('data', response.data)
          reactiveData.data = response.data;
          form.customLastPage = response.meta.last_page * 10;
        })
        .catch((error) => {
          //console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getSalesByid = (id: string) => {
      loading.value = true;
      salesService
        .show(id)
        .then((res) => {
          form.is_paid = res.data.is_paid;
          form.price = res.data.total;
          form.status = res.data.status;
          form.discount_type = res.data.discount_type;
          form.discount = res.data.discount_value;
          form.productname = res.data.sale_details[0].product.name;
          form.productdescription =
            res.data.sale_details[0].product.description;
          form.loyaltyPoints = res.data.sale_details[0].product.loyalty_points;
          form.products = res.data.sale_details;
          if (res.data.customer && res.data.customer.email) {
            form.email = res.data.customer.email;
          } else {
            form.email = "";
          }

          if (
            res.data.customer &&
            res.data.customer.first_name &&
            res.data.customer.last_name
          ) {
            form.name =
              res.data.customer.first_name + " " + res.data.customer.last_name;
          } else {
            form.name = "";
          }

          if (
            res.data.user &&
            res.data.user.first_name &&
            res.data.user.last_name
          ) {
            form.username =
              res.data.user.first_name + " " + res.data.user.last_name;
          } else {
            form.username = "";
          }

          console.log("log", form.products);
        })
        .catch((error) => {
          // console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getSalesForInvoice = (id: string) => {
      loading.value = true;
      salesService
        .show(id)
        .then((res) => {
          form.sale_id = res.data.id;
          form.customer_id = res.data.customer_id;
          form.invoice_number = res.data.id;
          form.user_id = res.data;
        })
        .catch((error) => {
          //  console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    // form.email = res.data.customer.email;

    const addStore = () => {
      formRef.value.validate().then(() => {
        let payload = {
          name: form.name,
          description: form.description,
          address: form.address,
          phone: form.phone,
          email: form.email,
          username: form.username,
          password: form.password,
        };
        storeService
          .create(payload)
          .then((res) => {
            reactiveData.data.push(res.store);
          })
          .catch((err) => {
            //  console.log(err)
          })
          .finally(() => {
            open.value = false;
          });
      });
    };

    const addPaymentMethod = () => {
      formRef.value.validate().then(() => {
        let payload = {
          payment_method_id: form.active,
          status: form.status,
          config: "null",
        };
        paymentMethodsService
          .create(payload)
          .then((res) => {
            reactiveData.data.push(res.store);
          })
          .catch((err) => {
            //  console.log(err)
          })
          .finally(() => {
            open.value = false;
          });
      });
    };

    const updatePaymentMethod = (id: string) => {
      let payload = {
        payment_method_id: 4,
        status: form.status,
        config: form.config,
      };
      paymentMethodsService
        .update(id, payload)
        .then((res) => {
          reactiveData.data = reactiveData.data.map((el) =>
            el.id !== id ? el : res.data
          );
        })
        .catch((err) => {
          //  console.log(err)
        })
        .finally(() => {
          open.value = false;
        });
    };

    const updateStore = (id: string) => {
      let payload = {
        name: form.name,
        description: form.description,
        address: form.address,
        phone: form.phone,
        email: form.email,
        username: form.username,
        password: form.password,
      };
      storeService
        .update(id, payload)
        .then((res) => {
          reactiveData.data = reactiveData.data.map((el) =>
            el.id !== id ? el : res.data
          );
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          open.value = false;
        });
    };

    const close = () => {
      openAdd.value = false;
    };
    const onSubmitInvoice = (record: any) => {
      updateModalWidth();
      resetForm();
      openAdd.value = true;
      reactiveData.action = "add";
      getSalesForInvoice(record.id);
      form.notes = "";
    };

    const multiselect = () => {
      resetForm();
      openMultiple.value = true;
      reactiveData.action = "add";
      form.notes = "";
    };

    const createInvoice = () => {
      addFormRef.value
        .validate()
        .then(() => {
          submitForm();
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    };
    const submitForm = () => {
      const userString = localStorage.getItem("user");
      if (userString === null) {
        return; // Exit early if user data is not available
      }

      const user = JSON.parse(userString);
      const userId = user.id;

      const payload = {
        customer_id: form.customer_id,
        user_id: userId,
        sales: form.sale_id,
        invoice_date: formatToStringDate(form.invoice_date),
        notes: form.notes,
      };

      loading.value = true;

      invoiceService
        .add(payload)
        .then((res) => {
          //reactiveData.data.push(res.data);
          return invoiceService.generate(res.data.id);
        })
        .then((blobData) => {
          const blobUrl = URL.createObjectURL(blobData);
          window.open(blobUrl, "_blank");
          loading.value = false;
          showSuccessNotification(t("Pos.InvoiceCreated"));
        })
        .catch((err) => {
          // console.error('Error adding invoice:', err);
        })
        .finally(() => {
          showAddDrawer(false);
          closeOpens();
          loading.value = false;
        });
    };

    const createInvoiceMultipleSelection = () => {
      addFormRefForMultiple.value
        .validate()
        .then(() => {
          submitInvoiceMultipleSelection();
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    };

    const submitInvoiceMultipleSelection = () => {
      let userString = localStorage.getItem("user");
      if (userString !== null) {
        let user = JSON.parse(userString);
        let userId = user.id;
        let payload = {
          customer_id: form.customer_id,
          user_id: userId,
          sales: form.selectedRowIds,
          notes: form.notes,
          invoice_date: formatToStringDate(form.invoice_date),
        };

        loading.value = true;
        invoiceService
          .add(payload)
          .then((res) => {
            return invoiceService.generate(res.data.id);
          })
          .then((blobData) => {
            const blobUrl = URL.createObjectURL(blobData);
            window.open(blobUrl, "_blank");
            showSuccessNotification(t("Pos.InvoiceCreated"));
            openMultiple.value = false;
            location.reload();
          })
          .catch((err) => {
            //console.error('Error adding invoice:', err);
          })
          .finally(() => {
            showAddDrawer(false);
            loading.value = false;
          });
      }
    };

    const formSalesRef = ref();

    const formattedStartDate = () => {
      if (!form.startDateForExport) {
        return null;
      }
      const date = new Date(form.startDateForExport);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    onMounted(() => {
      updateSiderWidth;
      window.addEventListener("resize", updateSiderWidth);
      getAllDevices();
      getAllUsers();
      getAllSales();
      getAllCustomers("");
      Days(form.invoice_date);
    });

    const updateSiderWidth = () => {
      popupWidth.value = window.innerWidth <= 500 ? 250 : 729;
    };

    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };

    onBeforeUnmount(() => {
      window.removeEventListener("resize", updateSiderWidth);
    });

    return {
      updateModalWidth,
      modalWidth,
      formatDeviceName,
      resetModal,
      choosePerPage,
      reactiveDataForDevice,
      reactiveDataForUser,
      handleChange,
      customers,
      selectKey,
      isSelectOpen,
      startedTyping,
      handleInput,
      formatToStringDate,
      handleTransferOk,
      resetFieldsMulitpleSelection,
      handleSearchForSelect,
      customStyle,
      reactiveData2,
      close,
      createInvoice,
      hideForm,
      formatcurrency_code,
      formatTotal,
      closeModal,
      hideFormMultiple,
      handleReset,
      handleSearch,
      loading,
      rules,
      handlePageChange,
      currentPage,
      perPage,
      formatcustomer,
      navigatePage,
      t,
      columns,
      formRef,
      form,
      Days,
      formatDisplayDate,
      reactiveData,
      showAddDrawer,
      showDrawer,
      open,
      formatDate,
      updateStore,
      addStore,
      visible,
      addPaymentMethod,
      updatePaymentMethod,
      handleCheckboxChange,
      isFieldDisabled: true,
      onSubmitInvoice,
      resetFields,
      openAdd,
      afterOpenChange,
      multiselect,
      createInvoiceMultipleSelection,
      openMultiple,
      closeOpens,
      exportReport,
      openTransferModal,
      formattedStartDate,
      addFormRef,
      addFormRefForMultiple,
      formSalesRef,
      textDirection,
      locale,
      customPagination,
      popupWidth,
    };
  },
});
</script>
    
    <style scoped>
.table-margin {
  margin-bottom: 15px;
}
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.select-input {
  width: 8%;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.table-container {
  overflow-x: auto;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.report-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}
/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-btn {
  color: #bfffca8a;
}
.breadcrumb-icon {
  cursor: pointer;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.range-picker {
  width: 100%;
}

.is-rtl {
  direction: rtl;
}
.is-ltr {
  direction: ltr;
}

.drawer-header-ar {
  padding-right: 2%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.drawer-header-all {
  display: flex;
  justify-content: start;
  align-items: center;
}

.drawer-header-ar-padding {
  padding-right: 2%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.drawer-header-all-padding {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 10px;
}

.paginationArrows {
  direction: ltr;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
    margin-top: 10px;
  }
}
</style>