<template>
    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

   <a-row class="login-container">

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 11}" :lg="{ span: 11 }" :xl="{ span: 11 }" :xxl="{ span: 11 }" class="side-container">
            

            
            <div class="logo-container">
                <img src="@/assets/img/posys-logo-small.png" alt="Posys Image" width="30%"  />
            </div>
            <div class="form-container">
                  
                <a-form ref="formRef" layout="vertical" :rules="rules" :model="form">

                    <a-row v-if="form.errorMessage!==''">
                        <a-col :span="24">
                            <p class="error-msg">{{ t('Pos.invalidCred') }}</p>
                        </a-col>
                        
                    </a-row>
                    
                    <a-row >
                        <a-col :span="24">
                            <a-form-item  name="email">

                                <template #label>
                                    <span class="label-form">{{ t('Pos.Email') }}</span>
                                </template>
                                <a-input v-model:value="form.email" :placeholder="t('Pos.Email')" class="login-input" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row :gutter="16">
                        <a-col :span="24">
                            <a-form-item  name="password">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.Password') }}</span>
                                </template>
                                <a-input-password v-model:value="form.password" v-model:visible="visible"
                                    :placeholder="t('Pos.Password')" class="login-input"  />
                            </a-form-item>
                        </a-col>
                    </a-row>

                    
                    
                    <a-row class="login-btn-container">
                        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }">
                            <button class="login-btn"  @click="onSubmit">{{ t('Pos.SignIn') }}</button>
                        </a-col>   
                    </a-row>

                    <a-row >
                        <a-col  :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" >
                            <p>{{ t('Pos.RegisterTxt') }} <span class="register-title"  @click="navigatePage('/signUp')">{{ t('Pos.SignUp') }}</span> </p>

                        </a-col>
                        <!-- <p style=" cursor: pointer;" @click="OpenRegisterModal" class="register-form">{{ t('Pos.Register') }}</p> -->
                    </a-row>
                
                </a-form>
            </div>

           
           
        </a-col>

        <a-col  :xs="{ span:0  }" :sm="{ span: 0 }"  :md="{span: 13}" :lg="{ span: 13 }" :xl="{ span: 13}" :xxl="{ span: 13 }" class="login-img">
            <img src="@/assets/img/login-img.png" alt="Posys Image" width="80%"  />
        </a-col>
   </a-row>








    <a-modal v-model:open="openTransferModal"  class="register-modal" :footer="null">
        <template #title>
            <p>{{ t('Pos.Register') }}</p>
        </template>

        <a-steps
            :current="current"
            :items="items"
        ></a-steps>
        <div class="steps-content">
            <a-form  ref="registerFormRef" :model="registerForm" :rules="rulesForRegister" layout="vertical">

                <div v-if="steps[current].content==='first-content'">
                    <a-row :gutter="16">    
                        
                        
                        <a-col :span="24">
                            <a-form-item  name="name">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.Name') }}</span>
                                </template>
                                <a-input :placeholder="t('Pos.Name')" v-model:value="registerForm.name"/>
                            </a-form-item>
                        </a-col>

                    
                    </a-row>

                    <a-row :gutter="16">

                        <a-col :span="12">
                            <a-form-item  name="first_name">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.FisrtName') }}</span>
                                </template>
                                <a-input :placeholder="t('Pos.FisrtName')" v-model:value="registerForm.first_name"/>
                            </a-form-item>
                        </a-col>



                        <a-col :span="12">
                            <a-form-item  name="last_name">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.LastName') }}</span>
                                </template>
                                <a-input :placeholder="t('Pos.LastName')" v-model:value="registerForm.last_name"/>
                            </a-form-item>
                        </a-col>

                    </a-row>

                


                    <a-row :gutter="16">    
                        <a-col :span="12">
                            <a-form-item  name="email">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.Email') }}</span>
                                </template>
                                <a-input :placeholder="t('Pos.Email')" v-model:value="registerForm.email"/>
                            </a-form-item>
                        </a-col>


                        <a-col :span="12">
                            <a-form-item  name="username">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.Username') }}</span>
                                </template>
                                <a-input :placeholder="t('Pos.Username')" v-model:value="registerForm.username"/>
                            </a-form-item>
                        </a-col>
                    
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item  name="phone">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.Phone') }}</span>
                                </template>
                                <a-input :placeholder="t('Pos.Phone')" v-model:value="registerForm.phone"/>
                            </a-form-item>
                        </a-col>
                
                        <a-col :span="12">
                            <a-form-item  name="phone_number">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.phone_number') }}</span>
                                </template>
                                <a-input :placeholder="t('Pos.phone_number')" v-model:value="registerForm.phone_number"/>
                            </a-form-item>
                        </a-col>

                    </a-row> 

                    
                    <a-row :gutter="16">    
                        
                        <a-col :span="12">
                            <a-form-item  name="password">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.Password') }}</span>
                                </template>
                                <a-input-password v-model:value="registerForm.password" :placeholder="t('Pos.Password')" type="password" autocomplete="off" />

                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item   name="checkPass">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.Confirmpassword') }}</span>
                                </template>
                                <a-input-password v-model:value="registerForm.checkPass" :placeholder="t('Pos.Confirmpassword')" type="password" autocomplete="off" />
                            </a-form-item>
                        </a-col>
                
                    
                    </a-row>

                   
                </div>

                <div v-if="steps[current].content==='second-content'">

                    <a-row :gutter="16">      
                        <a-col :span="24">
                            <a-form-item  name="Address">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.Address') }}</span>
                                </template>
                                <a-input :placeholder="t('Pos.Address')" v-model:value="form.address"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                      
                        
                    <a-row :gutter="16">     
                        <a-col :span="12">
                            <a-form-item  name="registration_number">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.registration_number') }}</span>
                                </template>
                                <a-input :placeholder="t('Pos.registration_number')" v-model:value="form.registration_number"/>
                            </a-form-item>
                        </a-col>


                        <a-col :span="12">
                            <a-form-item  name="vat_number">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.vat_number') }}</span>
                                </template>
                                <a-input :placeholder="t('Pos.vat_number')" v-model:value="form.vat_number"/>
                            </a-form-item>
                        </a-col>
                    
                    </a-row>
            
                    <a-row :gutter="16">       
                    
                        <a-col :span="24">
                        <a-form-item name="category">
                            <template #label>
                            <span class="label-form2">{{ t('Pos.Category') }}</span>
                            </template>
                            <a-select v-model:value="registerForm.category" :placeholder="t('Pos.Category')"  show-search :option-filter-prop="'label'">
                            <a-select-option v-for="category in activityCategory.data" :key="category.id" :value="category.id" :label="category.name">
                                {{ category.name }}
                            </a-select-option>
                            </a-select>
                        </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="24">
                            <a-form-item  name="Description">
                                <template #label>
                                    <span class="label-form2">{{ t('Pos.Description') }}</span>
                                </template>
                                <a-textarea v-model:value="form.description" :rows="4" :placeholder="t('Pos.Description')" />

                            </a-form-item>
                        </a-col>

                    </a-row>


                    <a-row :gutter="24">       
                        <a-col :span="24">
                            <a-form-item>
                                <template #label>
                                <span class="label-form2">{{ t('Pos.Logo') }}</span>
                                </template>
            
                                <a-upload-dragger
                                v-model:fileList="fileList"
                                name="file"
                                :multiple="false"
                                :max-count="1"
                                @change="handleChangeFile"
                                @drop="handleDrop"
                                :before-upload="beforeUpload"
                                >
            
                                <p class="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p class="ant-upload-text">{{ t('Pos.dropTitle1') }}</p>
                                <p class="ant-upload-hint">
                                    {{ t('Pos.dropTitle2') }}
                                </p>
            
                                </a-upload-dragger>          
                            </a-form-item>
                            </a-col>

                    </a-row>
                </div>
                
            </a-form>
        </div>

        <div class="steps-action">

            <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
            <a-button
            v-if="current == steps.length - 1"
            type="primary"
            @click="submitRegister()"
            >
            {{ t('Pos.register') }}
            </a-button>
            <a-button v-if="current > 0" style="margin-left: 8px" @click="prev"> {{ t('Pos.Previous') }}</a-button>
    
        </div>
        
        
    </a-modal>

</template>
  
<script lang="ts">
import { useI18n } from 'vue-i18n';
import type { UploadChangeParam,UploadFile } from 'ant-design-vue';
import { notification } from 'ant-design-vue';
import { ref, defineComponent, reactive, toRaw , onMounted } from 'vue';
import { authService  , userService , activitiesCategoriesService} from "@/_services";
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import {
  
  InboxOutlined,
 
  
} from '@ant-design/icons-vue';
export default defineComponent({
    name: "loginIndex",
    components: {
       
        LbrxLoaderSpinner,
        InboxOutlined
        

    },

    setup() {
        const loading = ref(false);
        const handleChangeFile = (info: UploadChangeParam) => {
            fileInfo.value=info.file;
        };

        const fileList = ref([]);
        const fileInfo = ref<UploadFile | null>(null);
        const reactiveData = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0
        });






        const navigatePage = (link: string) => {
            router.push(link); 
        };


      const activityCategory = reactive({
        searchText: '',
        searchedColumn: '',
        data: [] as any[],
        totalPages:0
      });


        const openTransferModal = ref<boolean>(false);
        const { t } = useI18n();
        const checked = ref<boolean>(false);
        const visible = ref<boolean>(false);
        const router = useRouter();
        const formRef = ref();

        
        const registerForm=reactive({
            name:'',
            description:'',
            address:'',
            phone:'',
            email:'',
            username:'',
            password:'',
            first_name:'',
            last_name:'',
            phone_number:'',
            registration_number:'',
            vat_number:'',
            settings:'',
            category:'',
            checkPass:'',




        })

        const form = reactive({
            name: "",
            description: "",
            address: "",
            phone:'',
            email:'',
            add_user:'',
            username:'',
            password:'',
            checkPass:'',
            first_name:'',
            last_name:'',
            phone_number:'',
            registration_number:'',
            vat_number:'',
            settings:'{}',
            activity_category_id:'',
            logo:'',
            errorMessage:'',
            confirmPassword:'',
            category:''
        });


        const reset =()=> {
            form.name = "",
            form.description= "",
            form.address= "",
            form.phone='',
            form.email='',
            form.username='',
            form.password='',
            form.first_name='',
            form.last_name='',
            form.phone_number = '',
            form.registration_number='',
            form.vat_number='',
            form.activity_category_id='',
            form.logo=''
            
        }
        

        
    
        
            
        const registerFormRef = ref();

        const submitRegister = () => {
            registerFormRef.value
                .validate()
                .then(() => {
                    Register();
                })
                .catch((error: any) => {
                    console.log('error', error);
                });
        };


        const showSuccessNotification = (message: string) => {
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style: {
                    backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        const OpenRegisterModal = ()=> {
            reset();
            openTransferModal.value = true
        }

        const rules: Record<string, Rule[]> = {
            email: [
                { required: true, message:  t('Pos.RequiredField') },
                {
                    validator: (_, value) => {
                        if (validEmail(value)) {
                            return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.invalidEmail'));
                    }
                }],
            password: [
                { required: true, message: t('Pos.RequiredField') }
            ]
        };

        const validEmail = (email: string) => {
            let re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        };

        const onSubmit = () => {
            formRef.value
                .validate()
                .then(() => {
                    login();
                })
                .catch((error: any) => {
                    console.log('error', error);
                });
        };

        const resetFormRegister = () => {
            registerFormRef.value.resetFields();
            registerForm.name="";
            registerForm.description="";
            registerForm.category="";
            registerForm.first_name="";
            registerForm.last_name="";
            registerForm.phone_number="";
            registerForm.password="";
            registerForm.checkPass="";
            registerForm.address="";
            registerForm.phone="";
            registerForm.email="";
            registerForm.username="";
            registerForm.registration_number="";
            registerForm.vat_number="";
            current.value=0;
            fileList.value=[];


        };


        const Register =()=> {
           
            loading.value=true;
            authService.createUser(registerForm.name,registerForm.description,registerForm.address,registerForm.phone ,registerForm.email,'true',registerForm.username,registerForm.password,
            registerForm.first_name,registerForm.last_name,registerForm.phone_number,registerForm.registration_number,registerForm.vat_number,registerForm.settings,registerForm.category,fileInfo.value).then((res) => {
            
            showSuccessNotification(t('Pos.AddCustomer'));
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                // resetAdd();
                openTransferModal.value = false
                loading.value=false;
                form.email=registerForm.email;
                form.password=registerForm.password;
                resetFormRegister();
            })        
        }


        function handleDrop(e: DragEvent) {
            console.log(e);
        }

        const beforeUpload = () => {
            return false;
        };

        const validatePass = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject(t('Pos.RequiredField'));
            } else {
                if (registerForm.checkPass !== '' || registerForm.checkPass !== '') {
                    formRef?.value?.validateFields('checkPass');
                }
                return Promise.resolve();
            }
        };
        const validatePass2 = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject(t('Pos.RequiredField'));
            } else if (value !== registerForm.password ||  value !== registerForm.password) {
                return Promise.reject(t('Pos.PasswordMatchError'));
            } else {
                return Promise.resolve();
            }
        };


        const rulesForRegister: Record<string, Rule[]> = {
        
            name: [{ required: true, message: t('Pos.RequiredField') }],
            username: [{ required: true, message: t('Pos.RequiredField') }],
            first_name: [{ required: true, message: t('Pos.RequiredField') }],
            last_name: [{ required: true, message:t('Pos.RequiredField') }],
            email: [
                { required: true, message: t('Pos.RequiredField')  , trigger: 'change'},
                {
                    validator: (rule, value) => {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value)) {
                            return Promise.reject(t('Pos.invalidEmail'));
                        }
                        return Promise.resolve();
                    }, trigger: 'change'
                },
                    
            ],

            category: [{ required: true, message: t('Pos.RequiredField') }],
            password: [{ required: true, validator: validatePass, trigger: 'change' }],
            checkPass: [{ validator: validatePass2, trigger: 'change' }],

            phone: [
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],

            phone_number: [
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],

            registration_number: [
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],

            vat_number: [
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],
        };

        const login = () => {
            
            authService.login(form.email, form.password).then((res) => {
                
                if (res.status == 'ok') {

                    router.push("/");
                }
                
            }).catch((err) => {
                console.log("err", err)
                form.errorMessage = err;
                
            })
        };


        const getActivitiesCategories = () => {
            loading.value = true ;
            activitiesCategoriesService.getAll()
            .then((res) => {    
                activityCategory.data = res;
                console.log(activityCategory.data)
            })
            .catch((error)=>{
            console.log("error api : get store", error);
            })
            .finally(()=>{
            loading.value = false;
            });
        }





            
        const handleChange = (info: UploadChangeParam) => {
            const file = info.file.originFileObj; // Access the actual file object
            console.log('info', info.file);
            fileInfo.value=info.file;
        };

        // register stepper 
        const current = ref<number>(0);
        const goNext = ref(false);

        const next = () => {
      
            registerFormRef.value.validate().then(()=>{
                current.value++;

            }).catch((error:any) => {
                console.error('Validation error:', error);
            });

        };

        const prev = () => {
            current.value--;
        };

        const steps = [
            {
                title: 'Step 1',
                content: 'first-content',

            },
            {
                title: 'Step 2',
                content: 'second-content',

            }
        ];
        const items = steps.map(item => ({ key: item.title, title: item.title }));
           

       const onRegistrationSuccess = (email:any, password:any) => {
            console.log('Registration successful. Email:', email, 'Password:', password);
        };

        onMounted(() => {

            window.addEventListener('registration-success', (event: any) => {
                const { email, password } = event.detail;

                onRegistrationSuccess(email, password);
            });

            getActivitiesCategories();
        });       

        return {
            loading,
            registerFormRef,
            current,
            goNext,
            next,
            prev,
            steps,
            items,
            handleChange,
            fileList,
            handleChangeFile,
            fileInfo,
            registerForm,
            showSuccessNotification,
            submitRegister,
            OpenRegisterModal,
            Register,
            t,
            checked,
            form,
            login,
            visible,
            rules,
            onSubmit,
            formRef,
            openTransferModal,
            rulesForRegister,
            reactiveData,
            handleDrop,
            beforeUpload,
            reset,
            activityCategory,
            navigatePage
            
        }
    }
});
</script>

<style scoped>

.login-container{
   
   
    height: 100vh;
    width: 100vw;
    position: fixed;
    
}

.side-container{
    background: #f8f8fa;
}

.form-container{
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
}

.logo-container{
    margin-top: 100px;
    margin-bottom: 80px;
}

.login-input{
    line-height: 2.5;
    border: none;
    background: #fff;
    font-weight: 500;
}

.login-btn{
  width: 100%;
  color: black;
  font-weight: 500;
  font-size: 15px;
  padding: 8px;
  background:#fc8019 ;
  color: #fff;
  border: none;
  border-radius: 6px;
  line-height: 1.5;
  cursor: pointer;
}

.login-btn:hover{
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    
}

.login-btn:focus{
    transform: translateY(-3px);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);    
}

.login-btn:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.login-btn-container{
    margin-top: 10px;
}

.ant-form-item .ant-form-item-explain-error{
    display: flex !important;
}



.register-title{
    font-weight: 600;
    color: #fc8019;
    cursor: pointer;
}

.login-img{
    display: flex;
    align-items: center;
    justify-content: center;
}

.label-form{
    font-weight: 600;
}

.log-form{
    display: flex;
    align-content: center;
    justify-content: center;
}

.register-modal{
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    width: 400px;
   
}

/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* stepper */

.steps-content {
  margin-top: -45px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

.ant-steps-item-icon{
    background-color: #fc8019;
    border-color: #fc8019;

}

.error-msg{
    color: #ea5455;
    font-weight: 400;
    font-size: 16px;
}

/*
.login-container{
    background: #202020;
    color: #fff;
    height: 100vh;
    width: 100vw;
    position: fixed;
    
}

.logo-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;

}

.login-sub-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-form{
    color: white;
}


.register-form{
    color: white;
    width: 100%; 
    margin-top: 10px;
    font-size:15px;
    
}


.label-form2{
    color: black;
}



.login-btn{
    color: black;
    background-color: #fff;
}

.login-btn:hover{
    color:black;
    border-color: black;
    padding-top: 10px; 

}

.modal-ctn {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center
}
*/





@media (min-width: 768px) {
    .form-container[data-v-fcf1ae48] {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 1024px) {
    .form-container[data-v-fcf1ae48] {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 150px;
        padding-right: 150px;
    }
}
</style>