<template>
    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0" :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
                {{ t('Pos.Home') }}

            </a-breadcrumb-item>
            <a-breadcrumb-item>{{t('Pos.Settings')}}</a-breadcrumb-item>

            <a-breadcrumb-item>  {{ t('Pos.backDatedChecks') }}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div>
        <a-table :columns="columns"></a-table>
    </div>
 
</template>
<script  lang="ts">
import { useI18n } from "vue-i18n";
import { ref, defineComponent, reactive, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import type { Rule } from "ant-design-vue/es/form";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import dayjs, { Dayjs } from "dayjs";
import { notification } from "ant-design-vue";
import Swal from "sweetalert2";
import "dayjs/locale/fr";
import "dayjs/locale/ar";
import { Empty } from "ant-design-vue";
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  ShopOutlined,
  EditOutlined,
  InboxOutlined,
  SwapOutlined,
} from '@ant-design/icons-vue';
export default defineComponent({
    name: "registerIndex",
    components: {
        LbrxLoaderSpinner,
    },
    setup() {

        const modalWidth = ref(720);

        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
        };

        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

        const loading = ref(false);
        const { t } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);

        const router = useRouter();

        const navigatePage = (link: string) => {
            router.push(link);
        };

        const columns = [
            {
                title: t("Pos.chequeNumber"),
                key: "number",
                dataIndex: "number",
            },
            {
                title: t("Pos.Date"),
                key: "date",
                dataIndex: "date",
            },

          

            {
                title: t("Pos.Actions"),
                key: "actions",
                dataIndex: "actions",
            },
        ];
            
        return{
            modalWidth,
            updateModalWidth,
            simpleImage,
            loading,
            t,
            searchInput,
            navigatePage,
            columns,





        }
        
    },
})
</script>
