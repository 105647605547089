import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const paymentMethodsService = {
    getAll,
    show,
    create,
    delete: _delete,
    update,
    getNames,
    getAllWithouPagination
};

function getAll(currentPage : number , perPage : number) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.paymentMethods.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.paymentMethods.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function create(payload:object ){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.paymentMethods.create}`, requestOptions)
        .then(
            handleResponse
        );
}


function _delete(id: string){
    const requestOptions={
        method:"DELETE",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.paymentMethods.delete.replace(':id', id)}`, requestOptions)
        .then(handleResponse);
}

function update(id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.paymentMethods.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function getNames() {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.paymentMethods.getNames}`, requestOptions)
        .then(
            handleResponse
        );
}

function getAllWithouPagination() {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.paymentMethods.getAll}?pagination=off`, requestOptions)
        .then(
            handleResponse
        );
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}