import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_result = _resolveComponent("a-result")!

  return (_openBlock(), _createBlock(_component_a_result, {
    status: "404",
    title: "404",
    "sub-title": "Sorry, the page you visited does not exist."
  }, {
    extra: _withCtx(() => [
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/')))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Back Home")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}