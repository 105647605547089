import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78481621"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { class: "label-form" }
const _hoisted_3 = { class: "label-form" }
const _hoisted_4 = { class: "label-form" }
const _hoisted_5 = { class: "label-form" }
const _hoisted_6 = { class: "ant-upload-drag-icon" }
const _hoisted_7 = { class: "ant-upload-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_InboxOutlined = _resolveComponent("InboxOutlined")!
  const _component_a_upload_dragger = _resolveComponent("a-upload-dragger")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, {
        style: {"margin":"16px 0"},
        class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Home")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Settings")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.checkSlips")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _ctx.showAddDrawer
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Pos.Add")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        columns: _ctx.columns,
        pagination: false,
        class: "table-container"
      }, null, 8, ["columns"])
    ]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openAdd,
      "onUpdate:open": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.openAdd) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth,
      onClose: _ctx.resetFormAdd
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
        }, _toDisplayString(_ctx.t("Pos.AddCheckRegister")), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.add
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pos.Add")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.resetFormAdd
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pos.Cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "addFormRef",
          model: _ctx.addForm,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "bankName" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t("Pos.bankName")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.bankName,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addForm.bankName) = $event)),
                          placeholder: _ctx.t('Pos.bankName'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "num" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t("Pos.chequeSlipNumber")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.num,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addForm.num) = $event)),
                          placeholder: _ctx.t('Pos.chequeSlipNumber'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "handInDate" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t("Pos.hand-inDate")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_date_picker, {
                          placeholder: _ctx.t('Pos.hand-inDate'),
                          value: _ctx.addForm.handInDate,
                          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addForm.handInDate) = $event)),
                          class: "range-picker"
                        }, null, 8, ["placeholder", "value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "photo" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t("Pos.Photo")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_upload_dragger, {
                          fileList: _ctx.fileList,
                          "onUpdate:fileList": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fileList) = $event)),
                          name: "file",
                          multiple: true,
                          "max-count": 10,
                          onChange: _ctx.handleChange,
                          onDrop: _ctx.handleDrop,
                          "before-upload": _ctx.beforeUpload,
                          accept: ".jpg,.jpeg,.png"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", _hoisted_6, [
                              _createVNode(_component_InboxOutlined)
                            ]),
                            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t("Pos.dropTitle1")), 1)
                          ]),
                          _: 1
                        }, 8, ["fileList", "onChange", "onDrop", "before-upload"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width", "onClose"])
  ], 64))
}