<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Dashboard") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.Invoice") }}</a-breadcrumb-item>
      <a-breadcrumb-item> {{ t("Pos.PurchaseInvloice") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer">
      {{ t("Pos.Add") }}
    </a-button>
  </div>

  <a-table
    class="table-container"
    :columns="columns"
    :data-source="state.data"
    :pagination="false"
  >
    <template #emptyText>
      <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
    </template>

    <template #bodyCell="{ column, record }">
      <template v-if="column.key == 'actions'">
        <a-space>
          <a-tooltip>
            <template #title>{{ t("Pos.View") }}</template>
            <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
              <template #icon>
                <EyeOutlined />
              </template>
            </a-button>
          </a-tooltip>

          <a-tooltip>
            <template #title>{{ t("Pos.Edit") }}</template>

            <a-button type="primary" @click="() => showDrawer(record)">
              <template #icon>
                <EditOutlined />
              </template>
            </a-button>
          </a-tooltip>

          <a-tooltip>
            <template #title>{{ t("Pos.Delete") }}</template>
            <a-button type="primary" danger @click="showDeletePopup(record)">
              <template #icon>
                <DeleteOutlined />
              </template>
            </a-button>
          </a-tooltip>
        </a-space>
      </template>

      <template v-if="column.key === 'warehouse_id'">
        {{ record.warehouse.name }}
      </template>
    </template>

    <template
      #customFilterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }"
    >
      <div style="padding: 8px">
        <template v-if="column.key === 'invoice_number'">
          <a-input
            ref="searchInput"
            :placeholder="`${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
        </template>

        <template v-if="column.key === 'warehouse_id'">
          <div class="search-btn-radio">
            <a-radio-group
              v-model:value="value"
              @change="selectedWarehouse"
              class="search-radio-grp"
            >
              <a-radio
                v-for="item in state.warehouses"
                :label="item.name"
                :value="item.id"
                :key="item"
                class="radio-item"
                >{{ item.name }}</a-radio
              >
            </a-radio-group>
          </div>
        </template>

        <a-button
          type="primary"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          {{ t("Pos.Search") }}
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="handleReset(clearFilters)"
        >
          {{ t("Pos.Reset") }}
        </a-button>
      </div>
    </template>

    <template #customFilterIcon="{ filtered }">
      <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
    </template>
  </a-table>

  <div class="pagination">
    <a-pagination
      class="paginationArrows"
      v-model:current="currentPage"
      v-model:pageSize="perPage"
      :total="state.totalPages"
      :default-page-size="perPage"
      :showSizeChanger="false"
      @change="handlePageChange"
    />

    <a-select
      v-model:value="selectPagination"
      @change="handleSelectPaginationChange"
      v-if="state.totalPages > 10"
      class="select-input"
    >
      <a-select-option value="10">10 / {{ t("Pos.page") }}</a-select-option>
      <a-select-option value="20">20 / {{ t("Pos.page") }}</a-select-option>
      <a-select-option value="50">50 / {{ t("Pos.page") }}</a-select-option>
      <a-select-option value="100">100 / {{ t("Pos.page") }}</a-select-option>
    </a-select>
  </div>

  <!-- add purchase invoice drawer -->
  <a-drawer
    v-model:open="openAdd"
    class="custom-class"
    root-class-name="root-class-name"
    :placement="locale === 'ar' ? 'left' : 'right'"
    :width="modalWidth"
    @close="resetFormAdd"
  >
    <template #title>
      <p :class="{ 'is-rtl': textDirection === 'rtl' }">
        {{ t("Pos.AddPurchaseinvoice") }}
      </p>
    </template>

    <a-form
      ref="addFormRef"
      :model="addForm"
      :rules="rules"
      layout="vertical"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item name="invoice_number">
            <template #label>
              <span class="label-form">{{ t("Pos.InvoiceNumber") }}</span>
            </template>
            <a-input
              v-model:value="addForm.invoice_number"
              :placeholder="t('Pos.InvoiceNumber')"
              class="input-form"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="amount">
            <template #label>
              <span class="label-form">{{ t("Pos.Amount") }}</span>
            </template>
            <a-input
              v-model:value="addForm.amount"
              :placeholder="t('Pos.Amount')"
              class="input-form"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item name="supplier_id">
            <template #label>
              <span class="label-form">{{ t("Pos.Supplier") }}</span>
            </template>

            <a-select
              v-model:value="addForm.supplier_id"
              show-search
              :option-filter-prop="'label'"
            >
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>

              <a-select-option
                v-for="supplier in state.suppliers"
                :label="supplier.company_name"
                :key="supplier.id"
                :value="supplier.id"
                :selected="supplier.id === addForm.supplier_id"
                >{{ supplier.company_name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="warehouse_id">
            <template #label>
              <span class="label-form">{{ t("Pos.Warehouse") }}</span>
            </template>

            <a-select
              v-model:value="addForm.warehouse_id"
              show-search
              :option-filter-prop="'label'"
            >
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>

              <a-select-option
                v-for="warehouse in state.warehouses"
                :label="warehouse.name"
                :key="warehouse.id"
                :value="warehouse.id"
                :selected="warehouse.id === addForm.warehouse_id"
                >{{ warehouse.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item name="purchase_date">
            <template #label>
              <span class="label-form">{{ t("Pos.PurchaseDate") }}</span>
            </template>
            <a-date-picker
              :placeholder="t('Pos.PurchaseDate')"
              v-model:value="addForm.purchase_date"
              class="range-picker"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item name="payement_type">
            <template #label>
              <span class="label-form">{{ t("Pos.PaymentType") }}</span>
            </template>

            <a-select
              v-model:value="addForm.payement_type"
              show-search
              :option-filter-prop="'label'"
            >
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>
              <a-select-option
                v-for="item in state.paymentMethods"
                :label="item.name"
                :key="item.id"
                :value="item.id"
                :selected="item.id === addForm.payement_type"
                >{{ item.method.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item name="purchase_type">
            <template #label>
              <span class="label-form">{{ t("Pos.PurchaseType") }}</span>
            </template>

            <a-select
              v-model:value="addForm.purchase_type"
              show-search
              @change="handleTypeChange"
              :option-filter-prop="'label'"
            >
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>
              <a-select-option
                value="ingredient"
                :label="t('Pos.Ingredient')"
                >{{ t("Pos.Ingredient") }}</a-select-option
              >
              <a-select-option value="product" :label="t('Pos.Product')">{{
                t("Pos.Product")
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="items">
            <template #label>
              <span class="label-form">{{ t("Pos.Items") }}</span>
            </template>

            <a-select
              v-model:value="addForm.items"
              mode="multiple"
              style="width: 100%"
              :placeholder="t('Pos.PurchaseItem')"
              :options="options"
              @change="handleMultiChange"
              show-search
              :option-filter-prop="'label'"
            >
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-for="item in state.qtArray" :key="item" :span="12" :gutter="16">
        <a-col :span="12">
          <span class="label-form">{{ item.item_name }}</span>
        </a-col>

        <a-col :span="6">
          <a-form-item
            has-feedback
            :name="'item' + item.item_id"
            :validate-status="getValidatingStatusQT(item.item_id, 'item')"
            :help="getValidatingHelpQT(item.item_id, 'item')"
          >
            <a-input
              :id="'item' + item.item_id"
              :placeholder="t('Pos.Quantity')"
              class="input-form"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item
            has-feedback
            :name="'price' + item.item_id"
            :validate-status="getValidatingStatusQT(item.item_id, 'price')"
            :help="getValidatingHelpQT(item.item_id, 'price')"
          >
            <a-input
              :id="'price' + item.item_id"
              :placeholder="t('Pos.PurchasePrice')"
              class="input-form"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #footer>
      <a-space>
        <a-button type="primary" @click="add">{{ t("Pos.Add") }}</a-button>
        <a-button type="primary" danger @click="resetFormAdd">{{
          t("Pos.Cancel")
        }}</a-button>
      </a-space>
    </template>
  </a-drawer>

  <!-- display drawer -->
  <a-drawer
    v-model:open="openDisplay"
    class="custom-class"
    root-class-name="root-class-name"
    :placement="locale === 'ar' ? 'left' : 'right'"
    :width="modalWidth"
  >
    <template #title>
      <p :class="{ 'is-rtl': textDirection === 'rtl' }">
        {{ t("Pos.PurchaseinvoiceInfo") }}
      </p>
    </template>

    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
      layout="vertical"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-row>
        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.InvoiceNumber") }} :</span>
            {{ form.invoice_number }}
          </p>
        </a-col>

        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.Amount") }} :</span>
            {{ form.amount }}
          </p>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.Supplier") }} :</span>
            {{ form.supplier_name }}
          </p>
        </a-col>

        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.Warehouse") }} :</span>
            {{ form.warehouse_name }}
          </p>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.PurchaseDate") }} :</span>
            {{ formatDateDrawer(form.purchase_date) }}
          </p>
        </a-col>

        <a-col :span="12">
          <p>
            <span class="info-title">{{ t("Pos.CreatedAt") }} :</span>
            {{ formatDateDrawer(form.created_at) }}
          </p>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <p>
            <span class="info-title">{{ t("Pos.PaymentType") }} :</span>
            {{ form.payement_type_name }}
          </p>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <p>
            <span class="info-title">{{ t("Pos.Items") }} :</span>
          </p>
        </a-col>
      </a-row>

      <template v-for="item in form.details" :key="item">
        <a-descriptions bordered size="small" class="ingredient-des">
          <a-descriptions-item :label="t('Pos.Name')" :span="3">{{
            item.name
          }}</a-descriptions-item>
          <a-descriptions-item :label="t('Pos.Quantity')">{{
            item.quantity
          }}</a-descriptions-item>
          <a-descriptions-item :label="t('Pos.PurchasePrice')">{{
            item.unit_price
          }}</a-descriptions-item>
        </a-descriptions>
      </template>
    </a-form>
  </a-drawer>

  <!-- update drawer -->
  <a-drawer
    v-model:open="open"
    class="custom-class"
    root-class-name="root-class-name"
    :width="modalWidth"
    :placement="locale === 'ar' ? 'left' : 'right'"
  >
    <template #title>
      <p :class="{ 'is-rtl': textDirection === 'rtl' }">
        {{ t("Pos.UpdatePurchaseinvoice") }}
      </p>
    </template>

    <a-form
      ref="formRef"
      :model="form"
      :rules="rules"
      layout="vertical"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item name="invoice_number">
            <template #label>
              <span class="label-form">{{ t("Pos.InvoiceNumber") }}</span>
            </template>
            <a-input
              v-model:value="form.invoice_number"
              :placeholder="t('Pos.InvoiceNumber')"
              class="input-form"
              disabled
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="amount">
            <template #label>
              <span class="label-form">{{ t("Pos.Amount") }}</span>
            </template>
            <a-input
              v-model:value="form.amount"
              :placeholder="t('Pos.Amount')"
              class="input-form"
              disabled
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item name="supplier_id">
            <template #label>
              <span class="label-form">{{ t("Pos.Supplier") }}</span>
            </template>

            <a-select
              v-model:value="form.supplier_id"
              show-search
              :option-filter-prop="'label'"
            >
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>

              <a-select-option
                v-for="supplier in state.suppliers"
                :label="supplier.company_name"
                :key="supplier.id"
                :value="supplier.id"
                :selected="supplier.id === form.supplier_id"
                >{{ supplier.company_name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="warehouse_id">
            <template #label>
              <span class="label-form">{{ t("Pos.Warehouse") }}</span>
            </template>

            <a-select
              v-model:value="form.warehouse_id"
              show-search
              :option-filter-prop="'label'"
            >
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>

              <a-select-option
                v-for="warehouse in state.warehouses"
                :label="warehouse.name"
                :key="warehouse.id"
                :value="warehouse.id"
                :selected="warehouse.id === form.warehouse_id"
                >{{ warehouse.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item name="purchase_date">
            <template #label>
              <span class="label-form">{{ t("Pos.PurchaseDate") }}</span>
            </template>
            <a-date-picker
              :placeholder="t('Pos.PurchaseDate')"
              v-model:value="form.purchase_date"
              class="range-picker"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item name="payement_type">
            <template #label>
              <span class="label-form">{{ t("Pos.PaymentType") }}</span>
            </template>

            <a-select
              v-model:value="form.payement_type"
              show-search
              :option-filter-prop="'label'"
            >
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>
              <a-select-option
                v-for="item in state.paymentMethods"
                :label="item.name"
                :key="item.id"
                :value="item.id"
                :selected="item.id === addForm.payement_type"
              >
                {{ item.method.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <p>
            <span class="info-title">{{ t("Pos.Items") }} :</span>
          </p>
        </a-col>
      </a-row>

      <!-- <template v-for="item in state.itemsArray" :key="item">
                <a-descriptions bordered size="small" class="ingredient-des">
                    <a-descriptions-item :label="t('Pos.Name')" :span="3">{{item.name}}</a-descriptions-item>
                    <a-descriptions-item :label="t('Pos.Quantity')" >{{item.quantity}}</a-descriptions-item>
                    <a-descriptions-item :label="t('Pos.PurchasePrice')" >{{item.unit_price}}</a-descriptions-item>
                </a-descriptions>
            </template> -->
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item name="purchase_type">
            <template #label>
              <span class="label-form">{{ t("Pos.PurchaseType") }}</span>
            </template>

            <a-select
              v-model:value="form.purchase_type"
              show-search
              @change="handleTypeChangeUpdate"
              :option-filter-prop="'label'"
            >
              <template #notFoundContent>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
              </template>
              <a-select-option
                value="ingredient"
                :label="t('Pos.Ingredient')"
                >{{ t("Pos.Ingredient") }}</a-select-option
              >
              <a-select-option value="product" :label="t('Pos.Product')">{{
                t("Pos.Product")
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="items_id">
            <template #label>
              <span class="label-form">{{ t("Pos.Items") }}</span>
            </template>

            <a-select
              v-model:value="form.items_id"
              mode="multiple"
              style="width: 100%"
              :options="optionsUpdate"
              @change="handleUpdateItem"
              show-search
            ></a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-for="item in state.itemsArray" :key="item" :gutter="16">
        <a-col :span="12">
          <span class="label-form">{{ item.name }}</span>
        </a-col>

        <a-col :span="6">
          <a-form-item
            has-feedback
            :name="'qt' + item.id"
            :validate-status="getValidatingStatusQT(item.id, 'qt')"
            :help="getValidatingHelpQT(item.id, 'qt')"
          >
            <a-input
              :id="'qt' + item.id"
              v-model:value="item.quantity"
              :placeholder="t('Pos.Quantity')"
              class="input-form"
              :disabled="item.oldItem === true"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item
            has-feedback
            :name="'price' + item.id"
            :validate-status="getValidatingStatusQT(item.id, 'price')"
            :help="getValidatingHelpQT(item.id, 'price')"
          >
            <a-input
              :id="'price' + item.id"
              v-model:value="item.unit_price"
              :placeholder="t('Pos.PurchasePrice')"
              class="input-form"
              :disabled="item.oldItem === true"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #footer>
      <a-space>
        <a-button type="primary" @click="update(form.id)">{{
          t("Pos.Update")
        }}</a-button>
        <a-button type="primary" danger @click="cancelUpdate">{{
          t("Pos.Cancel")
        }}</a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n";
import { ref, defineComponent, reactive, onMounted, computed } from "vue";
import {
  supplierService,
  warehouseService,
  invoiceService,
  productService,
  ingredientsService,
  paymentMethodsService,
  purchaseInvoiceService,
} from "@/_services";
import { useRouter } from "vue-router";
import type { Rule } from "ant-design-vue/es/form";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import dayjs, { Dayjs } from "dayjs";
import { notification } from "ant-design-vue";
import Swal from "sweetalert2";
import "dayjs/locale/fr";
import "dayjs/locale/ar";
import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons-vue";
import { Empty } from "ant-design-vue";

export default defineComponent({
  name: "registerIndex",
  components: {
    LbrxLoaderSpinner,
    SearchOutlined,
    DeleteOutlined,
    EyeOutlined,
    EditOutlined,
  },
  setup() {
    const modalWidth = ref(720);
    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const loading = ref(false);
    const { t } = useI18n();
    const searchInput = ref<HTMLInputElement | null>(null);
    const state = reactive({
      searchText: "",
      searchedColumn: "",
      data: [] as any[],
      totalPages: 0,
      suppliers: [] as any[],
      warehouses: [] as any[],
      invoices: [] as any[],
      items: [] as any[],
      qtArray: [] as any[],
      allValues: [] as any[],
      paymentMethods: [] as any[],
      selectedWarehouse: "",
      itemsArray: [] as any[],
      searchNumber: "",
      beginSearch: false,
    });
    const router = useRouter();

    const navigatePage = (link: string) => {
      router.push(link);
    };

    const columns = [
      {
        title: t("Pos.InvoiceNumber"),
        key: "invoice_number",
        dataIndex: "invoice_number",
        customFilterDropdown: true,

        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector(
                ".ant-table-filter-dropdown input"
              );

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
      },
      {
        title: t("Pos.PurchaseDate"),
        key: "invoice_date",
        dataIndex: "invoice_date",
      },
      {
        title: t("Pos.Amount"),
        key: "total_amount",
        dataIndex: "total_amount",
      },
      {
        title: t("Pos.Warehouse"),
        key: "warehouse_id",
        dataIndex: "warehouse_id",
        customFilterDropdown: true,
      },

      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      }
    ];

    const value = ref<string>("1");
    const selectedWarehouse = (value: any) => {
      state.selectedWarehouse = value.target.value;
    };

    //search

    const filter = (key: string, value: string) => {
      loading.value = true;
      currentPage.value=1; 
      purchaseInvoiceService
        .filter(key, value, currentPage.value, perPage.value)
        .then((res) => {
          state.data = res.data;
          state.totalPages = res.meta.total;
          state.beginSearch = true;
          loading.value = false;
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();

      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;

      console.log("searched data", state.searchText);
      console.log("seareched column", state.searchedColumn);

      if (dataIndex === "invoice_number" && state.searchText) {
        state.searchNumber = state.searchText;
        filter(dataIndex, state.searchText);
      }

      if (dataIndex === "warehouse_id" && state.selectedWarehouse !== "") {
        state.totalPages = 0;
        filter("warehouse_id", state.selectedWarehouse);
      }
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      state.searchText = "";
      state.searchNumber = "";
      state.selectedWarehouse = "";
      state.beginSearch = false;
      value.value = "";

      currentPage.value = 1;
      perPage.value = 10;
      selectPagination.value = "10";
      getAll();
    };

    //pagination
    const perPage = ref<number>(10);
    const currentPage = ref<number>(1);

    const selectPagination = ref("10");

    const handleSelectPaginationChange = (selectedItem: any) => {
      console.log("selected Per page:", selectedItem);
      perPage.value = Number.parseInt(selectedItem);
      currentPage.value = 1;

      if (state.beginSearch === true) {
        if (state.selectedWarehouse !== "") {
          loading.value = true;
          purchaseInvoiceService
            .filter(
              "warehouse_id",
              state.selectedWarehouse,
              currentPage.value.toString(),
              perPage.value
            )
            .then((res) => {
              state.data = res.data;
              state.totalPages = res.meta.total;
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              loading.value = false;
            });
        }

        if (state.searchNumber != "") {
          loading.value = true;
          purchaseInvoiceService
            .filter(
              "invoice_number",
              state.searchNumber,
              currentPage.value.toString(),
              perPage.value
            )
            .then((res) => {
              state.data = res.data;
              state.totalPages = res.meta.total;
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              loading.value = false;
            });
        }
      } else {
        loading.value = true;
        purchaseInvoiceService
          .getAllWithPagination(currentPage.value, perPage.value)
          .then((response) => {
            state.data = response.data;
            state.totalPages = response.meta.total;
          })
          .catch((error) => {
            console.log("error api", error);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;

      console.log("per page", perPage.value);
      console.log("page", currentPage.value);

      if (state.beginSearch === true) {
        state.data = [];
        if (state.selectedWarehouse !== "") {
          loading.value = true;
          purchaseInvoiceService
            .filter(
              "warehouse_id",
              state.selectedWarehouse,
              currentPage.value.toString(),
              perPage.value
            )
            .then((res) => {
              state.data = res.data;
              state.totalPages = res.meta.total;
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              loading.value = false;
            });
        }

        if (state.searchNumber !== "") {
          loading.value = true;
          purchaseInvoiceService
            .filter(
              "invoice_number",
              state.searchNumber,
              currentPage.value.toString(),
              perPage.value
            )
            .then((res) => {
              state.data = res.data;
              state.totalPages = res.meta.total;
            })
            .catch((err: any) => {
              console.log(err);
            })
            .finally(() => {
              loading.value = false;
            });
        }
      } else {
        loading.value = true;
        purchaseInvoiceService
          .getAllWithPagination(currentPage.value, perPage.value)
          .then((response) => {
            state.data = response.data;
            state.totalPages = response.meta.total;
          })
          .catch((error) => {
            console.log("error api", error);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    //form
    const formRef = ref();
    const addFormRef = ref();

    interface ItemInfo {
      id: any;
      name: any;
      quantity: any;
      unit_price: any;
    }

    const form = reactive({
      id: "",
      supplier_id: "",
      invoice_number: "",
      amount: "",
      purchase_date: null as Dayjs | null,
      warehouse_id: "",
      purchase_type: "",
      payement_type: "",
      created_at: "",
      details: "",
      supplier_name: "",
      warehouse_name: "",
      payement_type_name: "",
      items_id: [] as string[],
      items_info: [] as ItemInfo[],
    });

    const addForm = reactive({
      id: "",
      supplier_id: "",
      invoice_number: "",
      amount: "",
      purchase_date: "",
      warehouse_id: "",
      payement_type: "",
      items: [] as any,
      purchase_type: "",
    });

    const rules: Record<string, Rule[]> = {
      supplier_id: [{ required: true, message: t("Pos.RequiredField") }],
      amount: [
        { required: true, message: t("Pos.RequiredField") },
        {
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t("Pos.numericField"));
          },
        },
      ],
      invoice_number: [{ required: true, message: t("Pos.RequiredField") }],
      purchase_date: [{ required: true, message: t("Pos.RequiredField") }],
      warehouse_id: [{ required: true, message: t("Pos.RequiredField") }],
      purchase_type: [{ required: true, message: t("Pos.RequiredField") }],
      payement_type: [{ required: true, message: t("Pos.RequiredField") }],
      items: [{ required: true, message: t("Pos.RequiredField") }],
    };

    const getAll = () => {
      loading.value = true;
      purchaseInvoiceService
        .getAllWithPagination(currentPage.value, perPage.value)
        .then((response) => {
          state.data = response.data;
          state.totalPages = response.meta.total;
        })
        .catch((error) => {
          console.log("error get invoice purchase", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    //notification
    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    //add darwer
    const openAdd = ref<boolean>(false);
    const showAddDrawer = () => {
      updateModalWidth();
      getInvoices();
      getSuppliers();
      getPayementMethods();
      openAdd.value = true;
    };

    const getSuppliers = () => {
      supplierService
        .getAll()
        .then((res) => {
          state.suppliers = res.data;
        })
        .catch((error) => {
          console.log("error getting suppliers", error);
        });
    };

    const getPayementMethods = () => {
      paymentMethodsService
        .getAllWithouPagination()
        .then((res) => {
          state.paymentMethods = res.data;
        })
        .catch((error) => {
          console.log("erorr getting payement methoods", error);
        });
    };

    const getWarehouses = () => {
      warehouseService
        .getAll()
        .then((res) => {
          state.warehouses = res.data;
        })
        .catch((error) => {
          console.log("error getting warehouses", error);
        });
    };

    const getInvoices = () => {
      invoiceService
        .getAll()
        .then((res) => {
          state.invoices = res.data;
        })
        .catch((error) => {
          console.log("error getting invoices", error);
        });
    };

    const formatToStringDate = (date: any) => {
      if (!date || !date.$d) {
        return "";
      }

      const year = date.$y;
      const month = date.$M + 1;
      const day = date.$D < 10 ? "0" + date.$D : date.$D;

      const stringDate = `${year}-${month < 10 ? "0" : ""}${month}-${day}`;
      return stringDate;
    };

    const resetFormAdd = () => {
      addFormRef.value.resetFields();
      state.qtArray = [];
      items.value = [];

      addForm.items = [];
      validationStatusQT.value = {};
      validationHelpQT.value = {};
    };

    //multiselect

    //let items = ref([]);
    let items = ref<any[]>([]);
    let item_id = ref();

    const options = ref();

    const handleTypeChange = (selectedItem: any) => {
      if (selectedItem === "product") {
        state.qtArray = [];
        items.value = [];

        addForm.items = [];
        productService
          .getAll()
          .then((res) => {
            options.value = res.data.map((item: any) => ({
              value: item.id,
              label: item.name,
            }));
            item_id.value = options.value[0].value;
          })
          .catch((error) => {
            console.error("Error fetching products:", error);
          });
      } else if (selectedItem === "ingredient") {
        state.qtArray = [];
        items.value = [];

        addForm.items = [];

        ingredientsService
          .getAll()
          .then((res) => {
            options.value = res.data.map((item: any) => ({
              value: item.id,
              label: item.name,
            }));
            item_id.value = options.value[0].value;
          })
          .catch((error) => {
            console.error("Error fetching ingredients:", error);
          });
      }
    };

    const handleMultiChange = (value: any[]) => {
      items.value = value;
      addForm.items = value;
      console.log("items", items.value);
      console.log("values", value);
      //console.log('state values', state.allValues);

      //state.allValues = value.slice();

      state.qtArray = state.qtArray.filter((item) =>
        value.includes(item.item_id)
      );

      if (value) {
        value.forEach((itemId) => {
          const selectedItem = options.value.find(
            (option: any) => option.value === itemId
          );
          if (
            selectedItem &&
            !state.qtArray.some((item) => item.item_id === itemId)
          ) {
            state.qtArray.push({
              item_id: itemId,
              item_name: selectedItem.label,
              item_quantity: selectedItem.quantity,
            });
          }
        });
      }

      console.log("qt array", state.qtArray);
    };

    //validation
    interface ValidationStatus {
      [key: string]: string;
    }

    interface ValidationHelp {
      [key: string]: string;
    }

    const validationStatusQT = ref<ValidationStatus>({});

    const validationHelpQT = ref<ValidationHelp>({});

    const getValidatingStatusQT = (id: any, fieldType: string) => {
      return validationStatusQT.value[`${fieldType}${id}`] || "";
    };

    const getValidatingHelpQT = (id: any, fieldType: string) => {
      return validationHelpQT.value[`${fieldType}${id}`] || "";
    };

    function isNum(value: any) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    }

    const clearError = (itemId: string, fieldType: string) => {
      console.log("clear error", itemId, fieldType);
      validationStatusQT.value[`${fieldType}${itemId}`] = "";
      validationHelpQT.value[`${fieldType}${itemId}`] = "";
    };

    const showErrorNotification = (message: string) => {
      notification.error({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#FFBFC98A",
          color: "white",
        },
      });
    };

    const add = () => {
      let items: any[] = [];
      let quantities: string[] = [];
      let prices: string[] = [];

      let hasErrors = false;
      state.qtArray.forEach((item) => {
        const txtInput = document.getElementById(
          `item${item.item_id}`
        ) as HTMLInputElement;
        const priceInput = document.getElementById(
          `price${item.item_id}`
        ) as HTMLInputElement;

        if (txtInput.value === "") {
          console.log("here", txtInput);
          validationStatusQT.value[`item${item.item_id}`] = "error";
          validationHelpQT.value[`item${item.item_id}`] =
            t("Pos.RequiredField");
          hasErrors = true;
        } else if (!isNum(txtInput.value)) {
          validationStatusQT.value[`item${item.item_id}`] = "error";
          validationHelpQT.value[`item${item.item_id}`] = t("Pos.numericField");
          hasErrors = true;
        } else {
          validationStatusQT.value[`item${item.item_id}`] = "";
          validationHelpQT.value[`item${item.item_id}`] = "";

          quantities.push(txtInput.value);
          items.push(item.item_id);
        }

        if (priceInput.value === "") {
          console.log("here", txtInput);
          validationStatusQT.value[`price${item.item_id}`] = "error";
          validationHelpQT.value[`price${item.item_id}`] =
            t("Pos.RequiredField");
          hasErrors = true;
        } else if (!isNum(priceInput.value)) {
          validationStatusQT.value[`price${item.item_id}`] = "error";
          validationHelpQT.value[`price${item.item_id}`] =
            t("Pos.numericField");
          hasErrors = true;
        } else {
          validationStatusQT.value[`price${item.item_id}`] = "";
          validationHelpQT.value[`price${item.item_id}`] = "";

          prices.push(priceInput.value);
        }
      });
      if (!hasErrors) {
        addFormRef.value
          .validate()
          .then(() => {
            let payload = {
              invoice_number: addForm.invoice_number,
              total_amount: addForm.amount,
              supplier_id: addForm.supplier_id,
              warehouse_id: addForm.warehouse_id,
              invoice_date: formatToStringDate(addForm.purchase_date),
              payment_method_id: addForm.payement_type,
              stockable_type: addForm.purchase_type,
              stockable_ids: items.join(","),
              quantities: quantities.join(","),
              prices: prices.join(","),
            };
            console.log("payload", payload);
            loading.value = true;
            purchaseInvoiceService
              .create(payload)
              .then((res) => {
                getAll();
                showSuccessNotification(t("Pos.AddPurchaseinvoiceSuccess"));
                loading.value = false;
                openAdd.value = false;
              })
              .catch((error) => {
                console.log("error adding purchase invoice", error);
                loading.value = false;
              })
              .finally(() => {
                resetFormAdd();
              });
          })
          .catch((error: any) => {
            console.log("error validation form", error);
          });
      } else {
        console.log("error validaton dynamic fields");
      }
    };

    // display drawer
    const openDisplay = ref<boolean>(false);

    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      openDisplay.value = true;
      getById(record.id);
    };

    const getClassName = (char: string) => {
      const parts = char.split("\\");
      const extractedClassName = parts[parts.length - 1];

      const capitalizedClassName =
        extractedClassName.charAt(0).toUpperCase() +
        extractedClassName.slice(1);

      return capitalizedClassName;
    };

    const fillOptionsUpdate = (type: any) => {
      console.log("begin fill ");
      if (type === "Product") {
        productService.getAll().then((res) => {
          optionsUpdate.value = res.data.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        });
      } else if (type === "Ingredient") {
        ingredientsService.getAll().then((res) => {
          optionsUpdate.value = res.data.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        });
      }
    };

    const getById = (id: any) => {
      let itemsIds: any[] = [];
      purchaseInvoiceService
        .show(id)
        .then((res) => {
          (form.id = res.data.id),
            (form.invoice_number = res.data.invoice_number),
            (form.purchase_date = dayjs(res.data.invoice_date)),
            (form.supplier_id = res.data.supplier_id),
            (form.warehouse_id = res.data.warehouse_id),
            (form.amount = res.data.total_amount),
            (form.payement_type = res.data.payment_method_id),
            (form.created_at = res.data.created_at),
            (form.warehouse_name = res.data.warehouse.name),
            (form.supplier_name = res.data.supplier.company_name),
            (form.payement_type_name = res.data.payment_method.method.name),
            (form.purchase_type = getClassName(
              res.data.invoice_details[0].invoicable_type
            ));
          form.details = res.data.invoice_details.map((detail: any) => ({
            name: detail.invoicable.name,
            quantity: detail.quantity,
            unit_price: detail.unit_price,
          }));

          state.itemsArray = res.data.invoice_details.map((detail: any) => {
            itemsIds.push(detail.invoicable.id);
            return {
              id: detail.invoicable.id,
              name: detail.invoicable.name,
              quantity: detail.quantity,
              unit_price: detail.unit_price,
              oldItem: true,
            };
          });

          form.items_id = itemsIds;
          form.items_info = state.itemsArray;
        })
        .catch((error) => {
          console.log("error getting invouce purchase by id", error);
        })
        .finally(() => {
          fillOptionsUpdate(form.purchase_type);
        });
    };

    const formatDateDrawer = (dateString: string) => {
      return dayjs(dateString).format("YYYY-MM-DD HH:mm:ss");
    };

    //update drawer
    const open = ref<boolean>(false);
    const showDrawer = (record: any) => {
      updateModalWidth();
      getInvoices();
      getSuppliers();
      getPayementMethods();
      getById(record.id);
      open.value = true;
    };

    const cancelUpdate = () => {
      open.value = false;
    };

    const update = (id: string) => {
      formRef.value.validate().then(() => {
        //validate dynamic
        let hasErrors = false;
        let items: any[] = [];
        let quantities: string[] = [];
        let prices: string[] = [];

        state.itemsArray.forEach((item) => {
          const qtInput = document.getElementById(
            `qt${item.id}`
          ) as HTMLInputElement;

          const priceInput = document.getElementById(
            `price${item.id}`
          ) as HTMLInputElement;

          if (qtInput.value === "") {
            validationStatusQT.value[`qt${item.id}`] = "error";
            validationHelpQT.value[`qt${item.id}`] = t("Pos.RequiredField");
            hasErrors = true;
          } else if (!isNum(qtInput.value)) {
            validationStatusQT.value[`qt${item.id}`] = "error";
            validationHelpQT.value[`qt${item.id}`] = t("Pos.numericField");
            hasErrors = true;
          } else {
            validationStatusQT.value[`qt${item.id}`] = "";
            validationHelpQT.value[`qt${item.id}`] = "";
            if (item.oldItem === false) {
              quantities.push(qtInput.value);
              items.push(item.id);
            }
          }

          if (priceInput.value === "") {
            validationStatusQT.value[`price${item.id}`] = "error";
            validationHelpQT.value[`price${item.id}`] = t("Pos.RequiredField");
            hasErrors = true;
          } else if (!isNum(priceInput.value)) {
            validationStatusQT.value[`price${item.id}`] = "error";
            validationHelpQT.value[`price${item.id}`] = t("Pos.numericField");
            hasErrors = true;
          } else {
            validationStatusQT.value[`price${item.id}`] = "";
            validationHelpQT.value[`price${item.id}`] = "";
            if (item.oldItem === false) {
              prices.push(priceInput.value);
            }
          }
        });

        if (!hasErrors) {
          let payload = {
            invoice_number: form.invoice_number,
            invoice_date: formatToStringDate(form.purchase_date),
            warehouse_id: form.warehouse_id,
            supplier_id: form.supplier_id,
            total_amount: form.amount.toString(),
            payment_method_id: form.payement_type,
            prices: prices.join(","),
            quantities: quantities.join(","),
            stockable_ids: items.join(","),
            stockable_type: form.purchase_type,
          };

          console.log("payload", payload);

          loading.value = true;
          purchaseInvoiceService
            .update(id, payload)
            .then((response) => {
              //state.data = state.data.map(el => el.id !== id ? el : response.data);
              getAll();
              showSuccessNotification(t("Pos.UpdatePurchaseinvoiceSuccess"));
              loading.value = false;
            })
            .catch((err) => {
              console.log(err);
              loading.value = false;
            })
            .finally(() => {
              console.log("finish");
              open.value = false;
            });
        } else {
          console.log("error validation dynamic fields");
        }
      });
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: t("Pos.TitleDeletePurchaseinvoice"),
        text: t("Pos.TextDeletePurchaseinvoice"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Delete"),
        cancelButtonText: t("Pos.Cancel"),
        cancelButtonColor: "#d33",
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteInvoice(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      });
    };

    const deleteInvoice = (id: string) => {
      purchaseInvoiceService
        .delete(id)
        .then(() => {
          //state.data = state.data.filter(e => e.id !== id);
          getAll();
          showSuccessNotification(t("Pos.DeletePurchaseinvoice"));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.log("finish");
        });
    };
    require("dayjs/locale/fr");
    require("dayjs/locale/ar");
    const lang = localStorage.getItem("Language") || "en";
    const formatDisplayDate = (dateString: string) => {
      const date = new Date(dateString);
      return dayjs(date).locale(lang).fromNow();
    };

    // multi select update
    let optionsUpdate = ref();

    const handleTypeChangeUpdate = (selectedItem: any) => {
      if (selectedItem === "product") {
        form.items_id = [];
        state.itemsArray = [];
        form.items_info = [];
        productService.getAll().then((res) => {
          optionsUpdate.value = res.data.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        });
      } else if (selectedItem === "ingredient") {
        form.items_id = [];
        state.itemsArray = [];
        form.items_info = [];
        ingredientsService.getAll().then((res) => {
          optionsUpdate.value = res.data.map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
        });
      }
    };

    const handleUpdateItem = (value: any[]) => {
      state.itemsArray = state.itemsArray.filter((item) =>
        value.includes(item.id)
      );
      if (value) {
        value.forEach((itemId) => {
          const selectedItem = form.items_info.find(
            (option: any) => option.id === itemId
          );
          if (
            selectedItem &&
            !state.itemsArray.some((itm: any) => itm.id === itemId)
          ) {
            // add dinto array with additional info
            state.itemsArray.push({
              id: selectedItem.id,
              name: selectedItem.name,
              quantity: selectedItem.quantity,
              unit_price: selectedItem.unit_price,
              oldItem: true,
            });
          } else if (
            !selectedItem &&
            !state.itemsArray.some((itm: any) => itm.id === itemId)
          ) {
            //add into array with id and name
            const newItem = optionsUpdate.value.find(
              (opt: any) => opt.value === itemId
            );
            state.itemsArray.push({
              id: itemId,
              name: newItem.label,
              oldItem: false,
            });
          }
        });
      }
    };

    onMounted(() => {
      getAll();
      getWarehouses();
    });

    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    return {
      updateModalWidth,
      modalWidth,
      loading,
      t,
      locale,
      textDirection,
      state,
      navigatePage,
      columns,
      formRef,
      addFormRef,
      form,
      addForm,
      rules,
      openAdd,
      showAddDrawer,
      add,
      resetFormAdd,
      handleTypeChange,
      items,
      options,
      handleMultiChange,
      simpleImage,
      validationStatusQT,
      validationHelpQT,
      getValidatingStatusQT,
      getValidatingHelpQT,
      openDisplay,
      showDisplayDrawer,
      formatDateDrawer,
      open,
      showDrawer,
      cancelUpdate,
      update,
      showDeletePopup,
      perPage,
      currentPage,
      handlePageChange,
      handleSearch,
      handleReset,
      selectedWarehouse,
      value,
      formatDisplayDate,
      optionsUpdate,
      handleTypeChangeUpdate,
      handleUpdateItem,
      selectPagination,
      handleSelectPaginationChange,
    };
  },
});
</script>

<style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.breadcrumb-icon {
  cursor: pointer;
}
.input-form {
  color: black;
  background-color: #fff;
}
.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.range-picker {
  width: 100%;
}

.ingredient-des {
  margin-bottom: 15px;
}

.search-radio-grp {
  display: flex;
  flex-direction: column !important;
}

.radio-item {
  margin-bottom: 10px;
}

/* CSS for RTL */
.is-rtl {
  direction: rtl;
}

.paginationArrows {
  direction: ltr;
}

.table-container {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
