import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-775c1930"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_4 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_5 = {
  key: 2,
  class: "drawer-header-ar"
}
const _hoisted_6 = {
  key: 3,
  class: "drawer-header-all"
}
const _hoisted_7 = { class: "label-form" }
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_10 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_11 = { class: "info-title" }
const _hoisted_12 = { class: "info-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, {
        style: {"margin":"16px 0"},
        class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Home')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Settings')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.VatRates')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddDrawer(true)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        class: "table-container",
        columns: _ctx.columns,
        "data-source": _ctx.reactiveData.data
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        onClick: () => _ctx.showDisplayDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Edit')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_input, {
              ref: "searchInput",
              placeholder: `Search ${column.dataIndex}`,
              value: selectedKeys[0],
              style: {"width":"188px","margin-bottom":"8px","display":"block"},
              onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
              onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px' },
        onAfterOpenChange: _ctx.afterOpenChange,
        onClose: _ctx.hideForm
      }, {
        title: _withCtx(() => [
          ((_ctx.locale === 'ar') && (_ctx.reactiveData.action=='add'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.RatesDrawerAdd')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale !== 'ar') && (_ctx.reactiveData.action=='add'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.RatesDrawerAdd')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale === 'ar') && (_ctx.reactiveData.action=='update'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.RatesDrawerUpdate')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale !== 'ar') && (_ctx.reactiveData.action=='update'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.RatesDrawerUpdate')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, [
            (_ctx.reactiveData.action == 'update')
              ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onSubmitUpdate(_ctx.form.id)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: "",
                      onClick: _ctx.hideForm
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_a_space, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _ctx.onSubmitAdd
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      danger: "",
                      onClick: _ctx.hideForm
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
          ], 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.name,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "rate" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.Rate')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.rate,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.rate) = $event)),
                            placeholder: _ctx.t('Pos.Rate'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange", "onClose"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px' },
        onAfterOpenChange: _ctx.afterOpenShowChange,
        onClose: _ctx.hideForm
      }, {
        title: _withCtx(() => [
          (_ctx.locale === 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.RatesDrawerView')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale !== 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.RatesDrawerView')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Pos.Name')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.name), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('Pos.Rate')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.rate), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange", "onClose"])
    ])
  ], 64))
}