<template>

    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{t('Pos.Dashboard')}}
            
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{t('Pos.Promotion')}} </a-breadcrumb-item>
        <a-breadcrumb-item>{{t('Pos.Discount')}}</a-breadcrumb-item>

        </a-breadcrumb>
    </div>
    <div class="btn-container">
        <a-button class="add-btn"  @click="showAddDrawer">
            {{t('Pos.Add')}}
            <template #icon>
                <PercentageOutlined />
            </template>
        </a-button>
    </div>  

    <div>
        <a-table
            :columns="columns"
            :data-source="state.data"
            :pagination="false"
            class="table-container"
        >

            <template #emptyText>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
            </template>



            <template #bodyCell="{ column, record }">
                <template v-if="column.key=='actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>{{t('Pos.View')}}</template>
                            <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                            <template #icon>
                                <EyeOutlined/>
                            </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip>
                            <template #title> {{t('Pos.Update')}} </template>
                            <a-button type="primary"  @click="() => showDrawer(record)">
                            <template #icon>
                                <EditOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                        
                        <a-tooltip>
                            <template #title> {{t('Pos.Delete')}} </template>
                            <a-button type="primary" danger @click="showDeletePopup(record)">
                            <template #icon>
                                <DeleteOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>


                <template v-if="column.key === 'start_date' || column.key === 'end_date'">
                    {{ formatDisplayDate(record[column.key]) }}
                </template>

                
            </template>

            <!--search -->
            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                    <template  v-if="column.key === 'name'">
                        <a-input
                            ref="searchInput"
                            :placeholder="`${column.title}`"
                            :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        /> 
                    </template>
                    <template  v-if="column.key === 'discountable_type'">
                        <div class="search-btn-radio">
                            <a-radio-group  v-model:value="value" @change="selectedType" class="search-radio-grp">
                                <a-radio value="product" class="radio-item">{{t('Pos.Product')}}</a-radio>
                                <a-radio value="category" class="radio-item">{{t('Pos.Category')}}</a-radio>
                            </a-radio-group>  
                        </div>   

                    </template>

                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                
                    {{t('Pos.Search')}}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{t('Pos.Reset')}}
                </a-button>
                </div>
            </template>

            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>
            <!--search -->

           


        </a-table>

        <div class="pagination">
            <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange"/>

            <a-select
                class="select-input"
                v-model:value="selectPagination"
                @change="handleSelectPaginationChange"
                v-if="state.totalPages>10"
            >
                <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
            </a-select>
        </div>




        <!-- <div class="pagination" v-if="state.totalPagesSearch ===0 && !state.beginSearch">
            <a-pagination v-model:current="currentPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage"/>
        </div>
        <div class="pagination" v-if="state.totalPagesSearch !==0 && state.beginSearch">
            
            <a-pagination v-model:current="currentPageSearch" :total="state.totalPagesSearch" @change="handlePageChangeSearch"  :default-page-size="perPageSearch"/>
        </div> -->

        <!-- display drawer -->
            <a-drawer
                v-model:open="openDisplay"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                @after-open-change="afterOpenChange"
            >

                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.DiscountInfo')}}</p>
                </template>

                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                    <a-row>   
                        <a-col :span="12">
                            <p><span class="info-title">{{t('Pos.Name')}}:</span>{{form.name}}</p>
                        </a-col>

                        <a-col :span="12">
                            <p><span class="info-title">{{t('Pos.Description')}}:</span>{{form.description}}</p>
                        </a-col>
                    
                    </a-row>

                   
                    <a-row :gutter="16">
                        <a-col :span="12">
                            <p><span class="info-title">{{t('Pos.PromotionType')}} :</span>{{t(`Pos.${form.promotion_type}`)}}</p>
                        </a-col>

                        <a-col :span="12">
                            <p><span class="info-title">{{t('Pos.DiscountTarget')}}:</span>{{form.discount_target}}</p>
                        </a-col>

                        
                    </a-row>

                    <a-row :gutter="16" v-if="form.promotion_type === 'bogo' " >

                        <a-col :span="12">
                            <p><span class="info-title">{{t('Pos.QuantityRequired')}}:</span>{{form.quantity_required}}</p>
                        </a-col>
                        
                        <a-col :span="12">
                            <p><span class="info-title">{{t('Pos.Freequantity')}}:</span>{{form.quantity_free}}</p>
                        </a-col>

                    </a-row>
                
                    

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <p><span class="info-title">{{t('Pos.DiscountType')}}:</span>{{form.discount_type}}</p>
                        </a-col>

                        <a-col  :span="12">
                            <p><span class="info-title">{{t('Pos.DiscountValue')}}:</span>{{form.discount_value}}</p>
                        </a-col>

                    </a-row>

                    <a-row>
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.startDate') }} :</span>{{formatDisplayDate(form.start_date)}}</p>
                            
                        </a-col>
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.endDate') }} :</span>{{formatDisplayDate(form.end_date)}}</p>

                        </a-col>
                    
                    </a-row>
                

                    <a-row :gutter="16">
                      

                        <a-col :span="24">
                            <p><span class="info-title">{{t('Pos.DiscountMember')}}:</span></p>
                            <!-- <span v-for="item in form.discountables" :key="item">
                                {{item}}<br>
                            </span> -->

                            <li v-for="item in form.discountables" :key="item">
                                <ul>{{item}}</ul>
                            </li>
                            
                        </a-col>

                    </a-row>

                  

                    <!-- <a-row>
                        <a-col :span="24">
                            <p><span class="info-title">{{t('Pos.Availability')}}:</span> <span class="availibitlity-txt">{{t('Pos.From')}}</span> {{formatDisplayDate(form.start_date)}}  <span class="availibitlity-txt">{{t('Pos.To')}}</span> {{ formatDisplayDate(form.end_date)}}</p>
                        </a-col>
                    </a-row> -->

                </a-form>
        

            </a-drawer>
        <!-- display drawer -->

        <!-- update drawer -->
            <a-drawer
                v-model:open="open"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                @after-open-change="afterOpenChange"
            >

                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateDiscount')}}</p>
                </template>

                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                    <a-row>   
                        <a-col :span="24">
                        <a-form-item  name="name">
                            <template #label>
                            <span class="label-form">{{t('Pos.Name')}}</span>
                            </template>
                            <a-input  v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form"/>
                        </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="24">
                        <a-form-item  name="description">
                            <template #label>
                            <span class="label-form">{{t('Pos.Description')}}</span>
                            </template>
                            <a-textarea v-model:value="form.description" :rows="4" :placeholder="t('Pos.Description')" />
                        </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="24">
                            <a-form-item   name="promotion_type">
                                <template #label>
                                    <span class="label-form">{{t('Pos.PromotionType')}}</span>
                                </template>

                                <a-select v-model:value="form.promotion_type" show-search :option-filter-prop="'label'">
                                    <template #notFoundContent>
                                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                    </template>
                                    <a-select-option value="discount" :label="t('Pos.Discount')">{{t('Pos.Discount')}}</a-select-option>
                                    <a-select-option value="bogo" :label="t('Pos.bogo')">{{t('Pos.bogo')}}</a-select-option>
                                </a-select>

                            </a-form-item>
    
                        </a-col>
                    </a-row>

                    <a-row :gutter="16" v-if="form.promotion_type === 'bogo' ">
                        <a-col :span="12">
                            <a-form-item   name="quantity_required">
                                <template #label>
                                    <span class="label-form">{{t('Pos.QuantityRequired')}}</span>
                                </template>
                                <a-input  v-model:value="form.quantity_required" :placeholder="t('Pos.QuantityRequired')" class="input-form"/>
                            </a-form-item>
    
                        </a-col>

                        <a-col :span="12">
                            <a-form-item   name="quantity_free">
                                <template #label>
                                    <span class="label-form">{{t('Pos.Freequantity')}}</span>
                                </template>

                                <a-input  v-model:value="form.quantity_free" :placeholder="t('Pos.Freequantity')" class="input-form"/>

                            </a-form-item>
                        </a-col>

                    </a-row>
                
                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item  name="discount_target">
                                <template #label>
                                    <span class="label-form">{{t('Pos.DiscountTarget')}}</span>
                                </template>

                                <a-select v-model:value="form.discount_target" show-search @change="handleTargetChange" :option-filter-prop="'label'">
                                    <template #notFoundContent>
                                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                    </template>
                                    <a-select-option value="product" :label="t('Pos.Product')">{{t('Pos.Product')}}</a-select-option>
                                    <a-select-option value="category" :label="t('Pos.Category')">{{t('Pos.Category')}}</a-select-option>
                                </a-select>

                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item  name="discount_id">
                                <template #label>
                                    <span class="label-form">{{t('Pos.DiscountMember')}}</span>
                                </template>
                                
                                <a-select
                                    v-model:value="form.discountables_id"
                                    mode="multiple"
                                    style="width: 100%"
                                    :options="optionsUpdate"
                                    @change="handleMultiChangeUpdate"
                                    show-search
                                    :option-filter-prop="'label'"
                                >

                                    <template #notFoundContent>
                                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                    </template>
                                
                                </a-select>

                               
                            </a-form-item>
                        </a-col>

                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item  name="discount_type">
                                <template #label>
                                    <span class="label-form">{{t('Pos.DiscountType')}}</span>
                                </template>

                                <a-select
                                    ref="select"
                                    v-model:value="form.discount_type"
                                    show-search
                                    :option-filter-prop="'label'"
                                >
                                    <template #notFoundContent>
                                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                        </template>
                                    <a-select-option value="percentage" :label="t('Pos.Percentage')">{{t('Pos.Percentage')}}</a-select-option>
                                    <a-select-option value="fixed" :label="t('Pos.Fixed')">{{t('Pos.Fixed')}}</a-select-option>
                                </a-select>

                            </a-form-item>
                        </a-col>

                        <a-col  :span="12">
                            <a-form-item  name="discount_value">
                                <template #label>
                                <span class="label-form">{{t('Pos.DiscountValue')}}</span>
                                </template>
                                <a-input  v-model:value="form.discount_value" :placeholder="t('Pos.DiscountValue')" class="input-form"/>
                            </a-form-item>
                        </a-col>

                    </a-row>

                    <a-row>
                        <a-col :span="24">
                            <a-form-item  name="start_date">
                                <template #label>
                                <span class="label-form">{{t('Pos.Availability')}}</span>
                                </template>
                                <a-range-picker 
                                    show-time 
                                    class="range-picker"  
                                    :value="[form.start_date, form.end_date]"
                                    @change="handleDateChange"
                                    
                                >
                                </a-range-picker>

                            </a-form-item>
                        </a-col>
                    </a-row>

                </a-form>
                <template #footer>
                    <a-space>
                        <a-button  type="primary" @click="updateDiscount(form.id)">{{t('Pos.Update')}}</a-button>
                        <a-button type="primary" danger @click="cancelUpdate" >{{t('Pos.Cancel')}}</a-button>
                    </a-space>
                </template>

            </a-drawer>
        <!-- update drawer -->

        <!-- add drawer -->
        <a-drawer
            v-model:open="openAdd"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width=modalWidth
            @close="resetFormAdd"
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddDiscount')}}</p>
            </template>

            <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
               
                <a-row>   
                    <a-col :span="24">
                    <a-form-item  name="name">
                        <template #label>
                        <span class="label-form">{{t('Pos.Name')}}</span>
                        </template>
                        <a-input  v-model:value="addForm.name" :placeholder="t('Pos.Name')" class="input-form"/>
                    </a-form-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24">
                    <a-form-item  name="description">
                        <template #label>
                        <span class="label-form">{{t('Pos.Description')}}</span>
                        </template>
                        <a-textarea v-model:value="addForm.description" :rows="2" :placeholder="t('Pos.Description')" />
                    </a-form-item>
                    </a-col>
                </a-row>

                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item   name="promotion_type">
                            <template #label>
                                <span class="label-form">{{t('Pos.PromotionType')}}</span>
                            </template>

                            <a-select v-model:value="addForm.promotion_type" show-search :option-filter-prop="'label'">
                                <template #notFoundContent>
                                    <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                </template>
                                <a-select-option value="discount" :label="t('Pos.Discount')">{{t('Pos.Discount')}}</a-select-option>
                                <a-select-option value="bogo" :label="t('Pos.bogo')">{{t('Pos.bogo')}}</a-select-option>
                           </a-select>

                        </a-form-item>
  
                    </a-col>

                </a-row>

                <a-row :gutter="16" v-if="bogoItem === true">
                    <a-col :span="12">
                        <a-form-item   name="quantity_required">
                            <template #label>
                                <span class="label-form">{{t('Pos.QuantityRequired')}}</span>
                            </template>
                            <a-input  v-model:value="addForm.quantity_required" :placeholder="t('Pos.QuantityRequired')" class="input-form"/>
                        </a-form-item>
  
                    </a-col>

                    <a-col :span="12">
                        <a-form-item   name="quantity_free">
                            <template #label>
                                <span class="label-form">{{t('Pos.Freequantity')}}</span>
                            </template>

                            <a-input  v-model:value="addForm.quantity_free" :placeholder="t('Pos.Freequantity')" class="input-form"/>

                        </a-form-item>
                    </a-col>

                </a-row>

                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-item  name="discount_target">
                            <template #label>
                                <span class="label-form">{{t('Pos.DiscountTarget')}}</span>
                            </template>

                           <a-select v-model:value="addForm.discount_target" show-search  :option-filter-prop="'label'">
                                <template #notFoundContent>
                                    <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                </template>
                                <a-select-option value="product" :label="t('Pos.Product')">{{t('Pos.Product')}}</a-select-option>
                                <a-select-option value="category" :label="t('Pos.Category')">{{t('Pos.Category')}}</a-select-option>
                           </a-select>

                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-item  name="discount_id">
                            <template #label>
                                <span class="label-form">{{t('Pos.DiscountMember')}}</span>
                            </template>

                           <a-select
                                v-model:value="discountables"
                                mode="multiple"
                                style="width: 100%"
                                :placeholder="t('Pos.DiscountMember')"
                                :options="options"
                                @change="handleMultiChange"
                                show-search
                                :option-filter-prop="'label'"
                            >
                                <template #notFoundContent>
                                    <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                </template>
                            </a-select>

                        </a-form-item>
                    </a-col>

                </a-row>

                <a-row :gutter="16">

                    <a-col :span="12">
                        <a-form-item  name="discount_type">
                            <template #label>
                                <span class="label-form">{{t('Pos.DiscountType')}}</span>
                            </template>

                            <a-select v-model:value="addForm.discount_type" show-search  :option-filter-prop="'label'">
                                <template #notFoundContent>
                                    <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                </template>
                                <a-select-option value="percentage" :label="t('Pos.Percentage')">{{t('Pos.Percentage')}}</a-select-option>
                                <a-select-option value="fixed" :label="t('Pos.Fixed')">{{t('Pos.Fixed')}}</a-select-option>
                            </a-select>

                        </a-form-item>

                        
                    </a-col>

                    <a-col  :span="12">
                        <a-form-item  name="discount_value">
                            <template #label>
                            <span class="label-form">{{t('Pos.DiscountValue')}}</span>
                            </template>
                            <a-input  v-model:value="addForm.discount_value" :placeholder="t('Pos.DiscountValue')" class="input-form"/>
                        </a-form-item>
                    </a-col>

                </a-row>

                <!--
                <a-row>
                    <a-col :span="12">
                        <div class="switch-container">
                            <p  class="label-form" >Add discount on cart</p>
                            <a-switch v-model:checked="addForm.discount_cart" />
                        </div>
                    </a-col>
                    
                </a-row>

                <a-row :gutter="16" v-if="addForm.discount_cart===true || addForm.discount_cart!=='' ">
                    <a-col :span="12">
                        <a-form-item  >
                            <template #label>
                            <span class="label-form">Total</span>
                            </template>
                            <a-input placeholder="Discount value" />
                        </a-form-item>   
                    </a-col>

                    <a-col :span="12">
                        <a-form-item >
                            <template #label>
                            <span class="label-form">Discount Value</span>
                            </template>
                            <a-input placeholder="Discount value" />
                        </a-form-item>   
                    </a-col>
                </a-row> -->

                <!--
                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item  name="start_date">
                                <template #label>
                                <span class="label-form">Start date</span>
                                </template>
                                <a-date-picker 
                                    show-time placeholder="Select start date" 
                                    v-model:value="addForm.start_date" 
                                    class="date-picker"
                                    @change="handleStartDateChange"
                                ></a-date-picker> 

                            </a-form-item>
                        </a-col>

                        <a-col  :span="12">
                            <a-form-item  name="end_date">
                                <template #label>
                                <span class="label-form">End date</span>
                                </template>

                                <a-date-picker 
                                    show-time placeholder="Select end date"
                                    v-model:value="addForm.end_date" 
                                    :disabled-date="disabledDate"
                                    class="date-picker"></a-date-picker> 
                            </a-form-item>
                        </a-col>

                    </a-row>
                -->

                <a-row>
                    <a-col :span="24">
                        <a-form-item  name="date">
                            <template #label>
                                <span class="label-form">{{t('Pos.Availability')}}</span>
                            </template>
                            <a-range-picker show-time class="range-picker"  v-model:value="addForm.date" >
                            </a-range-picker>
                        </a-form-item>
                        
                    </a-col>
                    
                </a-row>

            </a-form>

            <template #footer>
            <a-space>
                <a-button  type="primary" @click="add">{{t('Pos.Add')}}</a-button>
                <a-button type="primary" danger  @click="resetFormAdd">{{t('Pos.Cancel')}}</a-button>
            </a-space>
            </template>


        </a-drawer>
        <!-- add drawer -->
       

    </div>

</template>



<script lang="ts">
    import { defineComponent, ref, reactive, onMounted, toRaw, watch, computed } from 'vue';
    import type { Rule } from 'ant-design-vue/es/form';
    import Swal from 'sweetalert2';
    import {
    EyeOutlined,
    DeleteOutlined,
    SearchOutlined,
    PercentageOutlined,
    EditOutlined

    } from '@ant-design/icons-vue';
    import { notification } from 'ant-design-vue';
    import { productService, discountService, categoryService} from '@/_services'
    import dayjs , { Dayjs }from 'dayjs'; 
    import { getLastMeasureIndex } from 'ant-design-vue/es/vc-mentions/src/util';
    import type { TreeSelectProps } from 'ant-design-vue';
    import { useRouter } from 'vue-router';
    import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
    import { useI18n } from 'vue-i18n';
    import { Empty } from 'ant-design-vue';

    export default defineComponent({
        name: 'indexDiscount',
        components: {
            EyeOutlined,
            DeleteOutlined,
            SearchOutlined,
            PercentageOutlined,
            EditOutlined,
            LbrxLoaderSpinner
        },
        setup(){
            const modalWidth = ref(720);
            const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

            const loading = ref(false);
        
            const router = useRouter();

            const { t } = useI18n();

            const navigatePage = (link: string) => {
                router.push(link); 
            };
            const state = reactive({
                searchText: '',
                searchedColumn: '',
                data: [] as any[],
                categories:[] as any[],
                products:[] as any[],
                totalPages:0,
                options:[] as any,
                totalPagesSearch:0,
                selectedType:'',
                beginSearch:false,
                searchName:'',

            });
            const searchInput = ref<HTMLInputElement | null>(null);
            const searchContact = ref<HTMLInputElement | null>(null);

            const columns=[
                {
                    title:t('Pos.Name'),
                    key:'name',
                    dataIndex: 'name',
                    customFilterDropdown: true,
                    onFilter: (value:string, record:any) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownOpenChange:  (visible: boolean) => {
                        if (visible  && !searchInput.value) {
                            setTimeout(() => {
                            const input = document.querySelector('.ant-table-filter-dropdown input');

                            if (input instanceof HTMLInputElement) {
                                searchInput.value = input;
                                searchInput.value.focus();
                            }
                            }, 100);
                        }
                    },
                },
                {
                    title:t('Pos.DiscountTarget'),
                    key:'discountable_type',
                    dataIndex: 'discountable_type',
                    customFilterDropdown: true,
                    customRender: (text: any, record: any) => {
                        console.log('txt', text.text)
                        return t(`Pos.${text.text}`);
                    }

                },
                {
                    title: t('Pos.DiscountValue'),
                    key:'discount_value',
                    dataIndex: 'discount_value',

                },
                {
                    title: t('Pos.startDate'),
                    key:'start_date',
                    dataIndex: 'start_date',
                },
                {
                    title: t('Pos.endDate'),
                    key:'end_date',
                    dataIndex: 'end_date',
                },
                {
                    title:t('Pos.Actions'),
                    key:'actions'
                }

            ];

            //search select
            const value = ref<string>('1');
            const selectedType =(value:any)=>{
                console.log('selected radio option ', value.target.value);
                state.selectedType = value.target.value;
            }
            

            //form  
            const formRef = ref();
            const addFormRef = ref();

            const perPage = ref<number>(10);
            const currentPage = ref<number>(1);

            const selectPagination = ref('10');

            const perPageSearch = ref<number>(10);
            const currentPageSearch = ref<number>(1);
        
            const form = reactive({
                id: '',
                name: '',
                description: '',
                promotion_type:'',
                quantity_required:'',
                quantity_free:'',
                discount_target:'',
                discount_id:'',
                discountables_id:[] ,
                discountables:'',
                discount_type: '',
                discount_value: '',
                start_date: null as Dayjs | null,
                end_date: null as Dayjs | null,
                date:[],
            });

            const addForm = reactive({
                id: '',
                name: '',
                description: '',
                promotion_type:'',
                quantity_required:'',
                quantity_free:'',
                discount_target:'',
                discount_id:null,
                discountables:'',
                discount_type: '',
                discount_value: '',
                start_date: '',
                end_date: '',
                date:[],
            });

            const resetForm = () => {
                form.id = '';
                form.name = '';
                form.description = '';
                form.discount_target='',
                form.discount_id='';
                form.discount_type = '';
                form.discount_value = '';
                form.quantity_required='';
                form.quantity_free='';
                form.promotion_type='';
            }

            const options=ref();
            let hasCalledAPI = false;
            let hasCalleupdatedAPI = false;

            const bogoItem=ref();
            watch(()=>addForm.promotion_type,(newValue)=>{
                if(newValue === 'bogo'){
                    bogoItem.value=true;
                }else if(newValue === 'discount'){
                    bogoItem.value=false;
                }
            });

            let discountables = ref([]);
            let discountable_id=ref();

            let searchedType="";
            watch(() => addForm.discount_target, (newValue) => {
             
                if (newValue === 'product' && !hasCalledAPI) {
                    discountables.value=[];
                    productService.getAll().then((products) => {
                        console.log("getting products");
                        options.value = products.data.map((product:any) => ({
                            value: product.id,
                            label: product.name,
                        }));
                        discountables.value=options.value[0];
                        discountable_id.value=options.value[0].value;
                    }).catch((error) => {
                    console.error('Error fetching products:', error);
                    });
                    
                }else if(newValue === 'category'){
                    discountables.value=[];
                    categoryService.getAll().then((categories) => {
                        console.log("getting categories");
                        options.value = categories.data.map((category:any) => ({
                            value: category.id,
                            label: category.name,
                        }));
                        discountables.value=options.value[0];
                        discountable_id.value=options.value[0].value;
                    }).catch((error) => {
                    console.error('Error fetching categories:', error);
                    });
                   
                }else { 
                    options.value = [];
                   
                }
                

               /*
                if (newValue === 'product' || newValue === 'category') {
                    options.value = [];
                    searchedType = newValue;
                } else {
                    options.value = [];
                }

                */

            });

            const filterProducts=(value:string)=>{
                productService.getProductsByName(value).then((products) => {
                        console.log("getting productssssss", products.data);
                        options.value = products.data.map((product:any) => ({
                            value: product.id,
                            label: product.name,
                        }));
                        //discountables.value=options.value[0];
                    }).catch((error) => {
                    console.error('Error fetching products:', error);
                });
            }


            const handleSearchMulti=(value:any)=>{
                console.log('search', value);
                if(searchedType==="product"){
                    console.log('searching for products', value);
                    
                    setTimeout(() => {
                        filterProducts(value);
                    }, 1000);
                }   
            }

            
           const handleTargetChange=(selectedItem:any)=>{
                if(selectedItem==='product'){
                   
                    form.discountables_id=[];
                    productService.getAll().then((products) => {
                        console.log("getting products for update");
                        optionsUpdate.value = products.data.map((product:any) => ({
                            value: product.id,
                            label: product.name,
                        }));
                    
                    }).catch((error) => {
                    console.error('Error fetching products:', error);
                    });
                }else if(selectedItem === 'category'){
                   
                    form.discountables_id=[];
                    categoryService.getAll().then((categories) => {
                        console.log("getting categories");
                        optionsUpdate.value = categories.data.map((category:any) => ({
                            value: category.id,
                            label: category.name,
                        }));
                    }).catch((error) => {
                    console.error('Error fetching categories:', error);
                    });  
                }else{
                    optionsUpdate.value = [];   
                }
               
           }
           

           /*
            watch(() => form.discount_target, (newValue, oldValue) => {
                console.log('begin watcher form dicountable type update');
                if (newValue === 'product') {
                   
                    
                   
                    productService.getAll().then((products) => {
                        console.log("getting products for update");
                        optionsUpdate.value = products.data.map((product:any) => ({
                            value: product.id,
                            label: product.name,
                        }));
                    
                    }).catch((error) => {
                    console.error('Error fetching products:', error);
                    });
                }else if(newValue === 'category'){
                    
                    
                    categoryService.getAll().then((categories) => {
                        console.log("getting categories");
                        optionsUpdate.value = categories.data.map((category:any) => ({
                            value: category.id,
                            label: category.name,
                        }));
                    }).catch((error) => {
                    console.error('Error fetching categories:', error);
                    });
                }else { 
                    optionsUpdate.value = [];   
                }
            });
            */
           

            const handleDiscountMemberChange = (value:any) => {
                console.log('change', value);
                addForm.discount_id = value;
            };

            const handleDiscountMemberSearch= async (value: string) => {
                console.log('begin filter products by name');
                if (value.trim() !== '') {
                    try {
                    const products = await productService.getProductsByName(value);
                        state.options = products.data.map((product: any) => ({
                            value: product.id,
                            label: product.name,
                        }));
                        console.log('products', products);
                    } catch (error) {
                    console.error('Error fetching products:', error);
                    }
                } else {
                    state.options = [];
                }
            };
            /*
            watch(options, (newOptions) => {
                console.log('watch options', options)
                if (newOptions.length > 0) {
                    addForm.discount_id = newOptions[0].value;
                } else {
                    addForm.discount_id = null;
                }
            });
            */

            const filteredOptions = computed(() => {
                return options.value ? options.value.map((option: any) => ({
                    value: option.value,
                    label: option.label,
                })) : [];
            });

            const handleMultiChange = (value: any[]) => {
                console.log(`selected ${value}`);
                const selectedIds = value.map(item => item);
                console.log('selected ids',selectedIds);
                addForm.discountables=JSON.stringify(selectedIds);
            };
            let updatedDiscountables="" as string |null;
            const handleMultiChangeUpdate=(value:any[])=>{
                console.log(`selected update ${value}`);
                const selectedIds = value.map(item => item);
                updatedDiscountables=JSON.stringify(selectedIds);

                console.log('updated discountables', updatedDiscountables);

            }
            


            

            const rules: Record<string, Rule[]> = {
                name: [{ required: true, message: t('Pos.RequiredField') }],
                description: [{ required: true, message: t('Pos.RequiredField')}],
                discount_type: [{ required: true, message: t('Pos.RequiredField') }],
                /*
                discount_target: [{ required: true, message: 'Please select a discount target' }],
                discount_id: [{ required: true, message: 'Please enter a discount ' }],
                */
                discount_value: [
                    { required: true, message: t('Pos.RequiredField') },
                    { 
                        validator: (_, value) => {
                            if (!isNaN(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(t('Pos.numericField'));
                        }
                    }
                ],
                date: [{ required: true, message: t('Pos.RequiredField') }],
                start_date: [{ required: true, message: t('Pos.RequiredField') }],
                end_date: [{ required: true, message: t('Pos.RequiredField') }],
            };

        

            const formatDate = (dateString: string) => {
                return dayjs(dateString);
            }

            const formatDisplayDate = (dateString: string) => {
                return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
            }

            
            //search

            const filter=(key:string, value:string)=>{
                currentPage.value=1;
                loading.value=true;
                discountService.filterWithPagination(key,value, currentPage.value.toString(),perPage.value).then((res)=>{
                    state.data=res.data;
                    state.totalPages=res.meta.total;
                    //state.totalPagesSearch= res.meta.total;
                    state.beginSearch=true;
                    loading.value=false;
                }).catch((error)=>{
                    console.log('error fetching', error);
                });
            }

            const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
                confirm();
                state.searchText = selectedKeys[0];
                state.searchedColumn = dataIndex;

                if (dataIndex === 'name' && state.searchText!=='') {
                    state.searchName=state.searchText;
                    filter("name",state.searchText );
                }

                if (dataIndex === 'discountable_type' && state.selectedType!=='') {
                    state.totalPagesSearch=0;
                    filter("discountable_type",state.selectedType );
                }
            };

            const handleReset =  (clearFilter: (arg: any) => void)=> {
                clearFilter({ confirm: true });
                state.searchText = '';
                state.selectedType='';
                state.totalPagesSearch=0;
                state.beginSearch=false;
                value.value='';

                currentPage.value=1;
                perPage.value=10;
                selectPagination.value='10';
                state.searchName='';
                getDiscounts();
            };
            
            //notification
            const showSuccessNotification  = (message:string)=>{
                notification.success({
                    message: message,
                    duration: 3,
                    top: '80px',
                    style:{
                    backgroundColor: '#bfffca8a',
                    color: 'white'
                    }
                });
            };

            //drawer
            const open = ref<boolean>(false);
            const openDisplay = ref<boolean>(false);
            const openAdd = ref<boolean>(false);

            const showAddDrawer = (state: boolean) => {
                updateModalWidth();
                openAdd.value = true;
            };

            const afterOpenChange = (bool: boolean) => {
                console.log('open', bool);
            };

            const showDisplayDrawer =(record:any)=>{
                updateModalWidth();
                openDisplay.value = true;
                getDiscountById(record.id);
            }

            const showDrawer = (record: any) => {
                updateModalWidth();
                open.value = true;
                getDiscountById(record.id);
            };


            const afterOpenAddChange = (bool: boolean) => {
                console.log('open', bool);
            };

           
        

            const resetFormAdd = () => {
                addFormRef.value.resetFields();
                discountables.value=[];
                openAdd.value= false;
            };

            const cancelUpdate=()=>{
               open.value=false;
            }

        

            const getDiscounts=()=>{
                loading.value = true;
                discountService.getAllDiscounts(currentPage.value,perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error get discounts", error);
                }).finally(()=>{
                    loading.value = false;
                });
            }

            const formatAPIDate = (dateString:string) => {
                return dateString ? dayjs(dateString).format('YYYY-MM-DD HH:mm:ss') : null;
            };

            const convertToString = (date:any) => {
                const year = date.getFullYear();
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const day = ('0' + date.getDate()).slice(-2);
                const hours = ('0' + date.getHours()).slice(-2);
                const minutes = ('0' + date.getMinutes()).slice(-2);
                const seconds = ('0' + date.getSeconds()).slice(-2);

                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            };



            const add =()=>{
                addFormRef.value
                .validate()
                .then(()=>{
                    const startDate = new Date(addForm.date[0]);
                    const endDate = new Date(addForm.date[1]);

                   
                    let payload = {
                        name:addForm.name,
                        description:addForm.description,
                        promotion_type:addForm.promotion_type,
                        discount_type:addForm.discount_type,
                        discountable_type:addForm.discount_target,
                        discountable_id:addForm.discountables === "" ? "[" + discountable_id.value + "]" : addForm.discountables,
                        discount_value:addForm.discount_value,
                        start_date:convertToString(startDate),
                        end_date:convertToString(endDate),
                        quantity_required:addForm.quantity_required,
                        quantity_free:addForm.quantity_free,

                    }
                    console.log('payload', payload);
                    console.log('discountables', discountables.value);
                    loading.value = true;
                    discountService.store(payload).then((rep)=>{
                        showSuccessNotification(t('Pos.AddDiscountSuccess'));

                        //state.data.push(rep.data);
                        getDiscounts();
                        resetFormAdd();
                    }).catch((error) => {
                        console.error('Error adding discount:', error); 
                    }).finally(()=>{
                        loading.value = false;
                        openAdd.value=false;   
                    })
                    

                }).catch((error:any) => {
                    console.error('Validation error:', error);
                });
               
            }

            function extractClassName(fullClassName:string) {
                const parts = fullClassName.split('\\');
                let className= parts[parts.length - 1];
                className = className.charAt(0).toLowerCase() + className.slice(1);
                return className;

            }

            let optionsUpdate=ref();
            const getAllProducts=()=>{
                optionsUpdate.value=[];
                productService.getAll().then((rep)=>{
                    optionsUpdate.value = rep.data.map((product:any) => ({
                        value: product.id,
                        label: product.name,
                    }));
                })
            }

            const getAllCtageories=()=>{
                optionsUpdate.value=[];
                categoryService.getAll().then((rep)=>{
                    optionsUpdate.value = rep.data.map((product:any) => ({
                        value: product.id,
                        label: product.name,
                    }));
                })
            }
           


            const getDiscountById = (id: string) => {
                discountService.show(id)
                .then(res => {


                    console.log('discount by id', res.data);
                    const discountableIds = res.data.discountables.map((discountable: { id: any; }) => discountable.id);
                    const discountables = res.data.discountables.map((discountable: {name:any }) => discountable.name);


                    form.name = res.data.name;
                    form.description = res.data.description;
                    form.quantity_free=res.data.quantity_free;
                    form.quantity_required=res.data.quantity_required;
                    //form.promotion_type=t(`Pos.${res.data.promotion_type}`);
                    form.promotion_type=res.data.promotion_type;
                    form.discount_target=t(`Pos.${extractClassName(res.data.discountable_type)}`);
                    form.discount_id=res.data.discountable_id;
                    form.discountables_id=discountableIds;
                    form.discountables=discountables;
                    form.discount_type =t(`Pos.${res.data.discount_type}`) ;
                    form.discount_value = res.data.discount_value;
                    form.start_date = dayjs(res.data.start_date);
                    form.end_date = dayjs(res.data.end_date);
                    form.quantity_free=res.data.quantity_free;
                    form.quantity_required=res.data.quantity_required;

                    form.id = res.data.id;

                    console.log('disocuntables', discountables);

                    if(res.data.discountable_type==="product"){
                        console.log('get device by id , fill products')
                        getAllProducts();
                       
                    }else if(res.data.discountable_type==="category"){
                        getAllCtageories();
                    }
                })
                .catch(error => {
                    console.error('Error fetching discount by id:', error);
                });
            }

            
            const handleDateChange = (dates: Dayjs[]) => {
                form.start_date = dates[0];
                form.end_date = dates[1];
            };

            const formatToStringDate = (date:any) => {
                if (!date || !date.$d) {
                    return ''; 
                }

                const year = date.$y;
                const month = date.$M + 1;
                const day = date.$D < 10 ? '0' + date.$D : date.$D;
                const hours = date.$H < 10 ? '0' + date.$H : date.$H;
                const minutes = date.$m < 10 ? '0' + date.$m : date.$m;
                const seconds = date.$s < 10 ? '0' + date.$s : date.$s;

                const stringDate= `${year}-${month < 10 ? '0' : ''}${month}-${day} ${hours}:${minutes}:${seconds}`;
                return stringDate;
            };

            function upperFistChar(string:string) {
                let newString = string.charAt(0).toUpperCase() + string.slice(1);
                return newString;

            }

            const updateDiscount =(id:string)=>{

                if(form.discountables_id.length>0 && updatedDiscountables==''){
                    updatedDiscountables=form.discountables_id.join(',');
                }
               
                
                formRef.value
                .validate()
                .then(()=>{
                    let payload = {
                        id:id,
                        name:form.name,
                        description:form.description,
                        promotion_type:form.promotion_type,
                        discount_type:form.discount_type,
                        discountable_type:form.discount_target,
                        discountable_id:updatedDiscountables,
                        discount_value:form.discount_value,
                        start_date:formatToStringDate(form.start_date),
                        end_date:formatToStringDate(form.end_date),
                        quantity_required:form.quantity_required,
                        quantity_free:form.quantity_free,
                    }
                    console.log('payload', payload);
                    loading.value = true;
                    discountService.update(id, payload).then((response)=>{
                        state.data = state.data.map(el => el.id !== id ? el : response.data);
                        showSuccessNotification(t('Pos.UpdateDiscountSuccess'));

                    }).catch((err) => {
                    console.log(err)
                    }).finally(() => {
                        loading.value = false;
                        open.value=false;
                    }); 
                    
                })
            }

            const deleteDiscount = (id: string) => {
                loading.value=true;
                discountService.delete(id).then((res) => {
                    //state.data = state.data.filter(e => e.id !== id);
                    showSuccessNotification(t('Pos.DeleteDiscountSuccess'));
                    getDiscounts();
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    
                    loading.value=false;
                })
            };

            const showDeletePopup = (record: any) => {
                Swal.fire({
                    title: t('Pos.TitleDeleteDiscount'),
                    text: t('Pos.TextDeleteDiscount'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: "#fc8019",
                    confirmButtonText: t('Pos.Delete'),
                    cancelButtonColor: "#d33",
                    cancelButtonText: t('Pos.Cancel'),
                    customClass: {
                        popup: 'swal2-popup'
                    }

                }).then((result) => {
                    if (result.isConfirmed) {
                        deleteDiscount(record.id);
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        console.log("cancel delete ");
                    }
                })
            };

            const handleSelectPaginationChange=(selectedItem:any)=>{
                console.log('selected Per page:', selectedItem);
                perPage.value=Number.parseInt(selectedItem);
                currentPage.value=1;

                if(state.beginSearch===true){

                if(state.selectedType!==''){
                    loading.value = true;
                    discountService.filterWithPagination("discountable_type",state.selectedType,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

                

                if(state.searchName!=''){
                    loading.value = true;
                    discountService.filterWithPagination("name",state.searchName,currentPage.value.toString(),perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPagesSearch = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                    }

                }else{

                    loading.value = true;
                    discountService.getAllDiscounts(currentPage.value, perPage.value).then((response)=>{
                        state.data=response.data;
                        state.totalPages=response.meta.total;
                    }).catch((error)=>{
                        console.log("error api : get products", error);
                    })
                    .finally(()=>{
                        loading.value = false;
                    });

                }

                
            }


            const handlePageChange = (newPage:any) => {
                currentPage.value = newPage;
                

                console.log('per page', perPage.value);
                console.log('page', currentPage.value);

                if(state.beginSearch===true){
                    loading.value = true;
                    state.data=[];

                    if(state.selectedType!==''){

                        loading.value = true;
                        discountService.filterWithPagination("discountable_type",state.selectedType,currentPage.value.toString(),perPage.value).then((res)=>{
                            state.data=res.data;
                            state.totalPages=res.meta.total;
                        }).catch((err:any) => {
                            console.log(err)
                        }).finally(()=>{
                            loading.value = false;
                        });

                    }

                    if(state.searchName!==''){

                        loading.value = true;
                        discountService.filterWithPagination("name",state.searchName,currentPage.value.toString(),perPage.value).then((res)=>{
                            state.data=res.data;
                            state.totalPages=res.meta.total;
                        }).catch((err:any) => {
                            console.log(err)
                        }).finally(()=>{
                            loading.value = false;
                        });

                    }

                }else{
                    loading.value = true;
                    discountService.getAllDiscounts(currentPage.value, perPage.value).then((response)=>{
                        state.data=response.data;
                        state.totalPages=response.meta.total;
                    }).catch((error)=>{
                        console.log("error api : get products", error);
                    })
                    .finally(()=>{
                        loading.value = false;
                    });

                }

                
            }

            const handlePageChangeSearch = (newPage:any) => {
                currentPageSearch.value = newPage;
                loading.value = true;

                console.log('per page', perPageSearch.value);
                console.log('page', currentPageSearch.value);

                state.data=[];
                if(state.selectedType!==''){
                    discountService.filterWithPagination("discountable_type",state.selectedType,currentPageSearch.value.toString(), perPage.value).then((res)=>{
                    console.log('res', res.data);
                    state.data=res.data;
                    state.totalPagesSearch = res.meta.total;
                    }).catch((err:any) => {
                    console.log(err)
                    }).finally(()=>{
                    loading.value = false;
                    });
                }

                if(state.searchText!==''){
                    discountService.filterWithPagination("name",state.searchText,currentPageSearch.value.toString(),perPage.value).then((res)=>{
                        console.log('res', res.data);
                        state.data=res.data;
                        state.totalPagesSearch = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }
            
            }
    


            const transformUpperCase=(str:string)=>{
                if (!str || str.length === 0) {
                    return str;
                }

                return str.charAt(0).toUpperCase() + str.slice(1);
            }


            const updateModalWidth = () => {
                modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
            };
        

            onMounted(() => {
                
                getDiscounts();
                searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
                searchContact.value = document.querySelector('.ant-table-filter-dropdown input');
            });

            //rtl
            const { locale } = useI18n();
            const textDirection = computed(() => {
                return locale.value === 'ar' ? 'rtl' :'ltr';}
            );

            return {
                updateModalWidth,
                modalWidth,
                t,
                handleSearchMulti,
                textDirection,
                locale,
                discountables,
                handleMultiChange,
                handlePageChange,
                columns,
                state,
                rules,
                formRef,
                form,
                addFormRef,
                addForm,
                handleSearch,
                handleReset,
                formatDisplayDate,
                openAdd,
                afterOpenAddChange,
                showAddDrawer,
                open,
                afterOpenChange,
                showDrawer,
                resetFormAdd,
                add,
                updateDiscount,
                deleteDiscount,
                showDeletePopup,
                options,
                handleDiscountMemberChange, 
                bogoItem,
                handleDateChange,
                openDisplay,
                showDisplayDrawer,
                navigatePage,
                loading,
                perPage,
                currentPage,
                handleDiscountMemberSearch,
                filteredOptions,
                optionsUpdate,
                handleMultiChangeUpdate,
                transformUpperCase,
                value,
                selectedType,
                perPageSearch,
                currentPageSearch,
                handlePageChangeSearch,
                cancelUpdate,
                handleTargetChange,
                selectPagination,
                handleSelectPaginationChange,
                simpleImage



            } 
        }

    })
</script>



<style scoped>

    .add-btn {
        color: #fc8019;
        border: 2px solid #fc8019;
        box-shadow: inset 0 0 0 0 #fc8019;
    }

    .add-btn:hover {
        color: #fff;
        box-shadow: inset 0 -100px 0 0 #fc8019;
        border-color: #fc8019;
    }

    .btn-container {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
    }

    .label-form {
        font-family: "Exo 2", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    .date-picker {
        width: 100%
    }
    .range-picker{
        width: 100% 
    }

    .switch-container {
        display: flex;
        align-items: center;
    }
    .switch-container p {
        margin-right: 15px ;
    }

    .view-btn{
        color: #fc8019;
        border-color: #fc8019;
        background: #fafafa;
    }
   
    .breadcrumb-icon{
        cursor: pointer;
    }

    .pagination{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .info-title{
        font-size: 15px !important;
        font-weight: bold;
        margin-right: 4px;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .search-radio-grp{
        display: flex;
        flex-direction: column !important;
    }

    .radio-item{
        margin-bottom: 10px;
    }

    .is-rtl {
        direction:rtl;
    }
    .paginationArrows{
    direction: ltr;
    margin-bottom: 16px;
    }

    .table-container {
    overflow-x: auto;
    }

    @media (max-width: 768px) {
        .pagination {
            flex-direction: column;
            align-items: flex-start;
        }

        .select-input {
            width: 100%;
        }
    }



</style>
