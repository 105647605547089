import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const tablesService = {
    getAllTables,
    getTables,
    addTable,
    deleteTable,
    updateTable,
    show,
    filter,
    getAvailibility,
    filterWithPagination,
    filterStatusWithPagination
};


function getAvailibility(dateTime:any, seating_capacity:any, table_location_id:any){
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials, 
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.tables.filter}?datetime=${dateTime}&seating_capacity=${seating_capacity}&location_id=${table_location_id}`, requestOptions).then(handleResponse);

}

function getAllTables(perPage:number, currentPage:number){
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,
 
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.tables.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions)
    .then(
        handleResponse
    );
}



function filter(key:string, value:string) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.tables.getAll}?key=${key}&value=${value}`, requestOptions).then(handleResponse);

}

function filterWithPagination(key:string, value:string, page:string, perPage:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.tables.getAll}?key=${key}&value=${value}&page=${page}&per_page=${perPage}`, requestOptions).then(handleResponse);

}

function filterStatusWithPagination(status:string,page:string, perPage:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.tables.getAll}?status=${status}&page=${page}&per_page=${perPage}`, requestOptions).then(handleResponse);

}


function getTables() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.tables.getAll}`, requestOptions)
        .then(
            handleResponse
        );
}

function addTable(payload:object ){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.tables.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function deleteTable(id:string){
    const requestOptions={
        method:"DELETE",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,

    }
    return fetch(`${ApiConfigs.base_url+ApiConfigs.tables.delete.replace(':id', id)}`, requestOptions)
    .then(handleResponse);
}


function updateTable(id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.tables.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.tables.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}




function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
