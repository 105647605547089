<template>
    <a-row :gutter="16">
        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 16}" :lg="{ span: 16 }" :xl="{ span: 16 }" :xxl="{ span: 16 }" >
            <a-flex gap="middle" vertical align="start">
                <p class="dash-title"> {{t('Pos.Dashboard')}}</p>
                <p class="dash-sub-title"> {{t('Pos.DahsboardTxt')}}</p>
            </a-flex>

        </a-col>

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 4}" :lg="{ span: 4 }" :xl="{ span: 4 }" :xxl="{ span: 4 }" class="btn-dash-container" >
            <a-date-picker v-model:value="monthlyPicker" picker="month"  class="calender-input" :disabled-date="disabledDate"  @change="handleMonthly" v-if="selectValue==='monthly'"/>
            <a-date-picker v-model:value="annualPicker" picker="year"  class="calender-input" :disabled-date="disabledDate"  @change="handleAnnual" v-if="selectValue==='annual'" />
        </a-col>

        
        
        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 4}" :lg="{ span: 4 }" :xl="{ span: 4 }" :xxl="{ span: 4 }" class="btn-dash-container">
        
            <a-select v-model:value="selectValue" show-search class="select-calender" @change="handleChange">
                
                <template #notFoundContent>
                    <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                </template>

                <a-select-option value="daily" :label="t('Pos.daily')">{{ t('Pos.daily') }}</a-select-option>
                <a-select-option value="monthly" :label="t('Pos.monthly')">{{ t('Pos.monthly') }}</a-select-option>
                <a-select-option value="annual" :label="t('Pos.annual')">{{ t('Pos.annual') }}</a-select-option>

            </a-select>
        </a-col>
        
   </a-row>

  

   <a-row :gutter="16">
    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="card-container">
        <a-card hoverable>
            <a-flex gap="middle" justify="space-between">
                <div class="revenu-info">
                    <div :class="[locale === 'ar' ? 'revenu-container-rtl' : 'revenu-container']" >
                        <DollarCircleOutlined class="revenu-icon"/>
                    </div>
                    <span class="revenu-title" >{{t('Pos.Revenue')}}</span>
                </div>
            </a-flex>

            <a-flex align="start" vertical class="numbers-container">
                <span class="big-title" v-if="selectValue==='monthly' && monthlyPicker!==null"> {{formatAmount(state.monthlyRevenu)}}</span>
                <span class="revenu-title" v-if="selectValue==='monthly' && monthlyPicker!==null">
                    <template v-if="state.monthlyRevenuEff===0"><span class="normal-txt">{{state.monthlyRevenuEff}}</span> {{t('Pos.fromLastMonth')}}</template>
                    <template v-if="state.monthlyRevenuEff>0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">{{fixNumberVirgul(state.monthlyRevenuEff)}}%</span> {{t('Pos.fromLastMonth')}}</template>
                    <template v-if="state.monthlyRevenuEff<0"><FallOutlined class="fall-icon"/> <span class="fall-txt">{{fixNumberVirgul(state.monthlyRevenuEff)}}%</span> {{t('Pos.fromLastMonth')}}</template>
                </span>


                <span class="big-title" v-if="selectValue==='daily'" :class="{ 'is-rtl': textDirection==='rtl'}"> {{formatAmount(state.dailyRevenu)}}</span>
                <span class="revenu-title" v-if="selectValue==='daily'">
                    <template v-if="state.dailyRevenuEffecency>=0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">{{fixNumberVirgul(state.dailyRevenuEffecency)}}%</span> {{t('Pos.FromYesterday')}}</template>
                    <template v-if="state.dailyRevenuEffecency<0"><FallOutlined class="fall-icon"/> <span class="fall-txt">{{fixNumberVirgul(state.dailyRevenuEffecency)}}%</span> {{t('Pos.FromYesterday')}}</template>
                </span>


                <span class="big-title" v-if="selectValue==='annual'"> {{formatAmount(state.annualRevenue)}} </span>
                <span class="revenu-title" v-if="selectValue==='annual'">
                    <template v-if="state.annualRevenuEffecency===0"> <span class="rise-txt">0%</span> {{t('Pos.FromLastYear')}}</template>
                    <template v-if="state.annualRevenuEffecency>0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">0%</span> {{t('Pos.FromLastYear')}}</template>
                    <template v-if="state.annualRevenuEffecency<0"><FallOutlined class="fall-icon"/> <span class="fall-txt">0%</span> {{t('Pos.FromLastYear')}}</template>
                </span>
            </a-flex>
           
           
        </a-card>
    </a-col>


    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="card-container">
        <a-card hoverable>

            <a-flex gap="middle" justify="space-between">
                <div class="revenu-info">
                    <div  :class="[locale === 'ar' ? 'order-container-rtl' : 'order-container']" >
                        <ShoppingOutlined class="revenu-icon"/>
                    </div>
                
                    <span class="revenu-title">{{t('Pos.TotalOrder')}}</span>
                </div>

            </a-flex>

            <a-flex align="start" vertical class="numbers-container">
                <span class="big-title" v-if="selectValue==='monthly' && monthlyPicker!==null"> {{formatAmount(state.monthlySales)}}</span>
                <span class="revenu-title"  v-if="selectValue==='monthly' && monthlyPicker!==null"> 
                    <template v-if="state.salesEfficiency===0"><span class="normal-txt">{{state.salesEfficiency}}</span> {{t('Pos.fromLastMonth')}}</template>
                    <template v-if="state.salesEfficiency>0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">{{fixNumberVirgul(state.salesEfficiency)}}%</span> {{t('Pos.fromLastMonth')}}</template>
                    <template v-if="state.salesEfficiency<0"><FallOutlined class="fall-icon"/> <span class="fall-txt">{{fixNumberVirgul(state.salesEfficiency)}}%</span> {{t('Pos.fromLastMonth')}}</template>
                </span>

                <span class="big-title" v-if="selectValue==='daily'">{{formatAmount(state.todayTotalSales)}}</span>
                <span class="revenu-title"  v-if="selectValue==='daily'"> 
                    <template v-if="state.salesEfficiency>0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">{{fixNumberVirgul(state.salesEfficiency)}}%</span> {{t('Pos.FromYesterday')}}</template>
                    <template v-if="state.salesEfficiency<0"><FallOutlined class="fall-icon"/> <span class="fall-txt">{{fixNumberVirgul(state.salesEfficiency)}}%</span> {{t('Pos.FromYesterday')}}</template>
                </span>

                <span class="big-title" v-if="selectValue==='annual'"> {{formatAmount(state.annualSales)}} </span>
                <span class="revenu-title"  v-if="selectValue==='annual'">
                    <template v-if="state.salesEfficiency===0"><span class="rise-txt">{{fixNumberVirgul(state.salesEfficiency)}}%</span> {{t('Pos.FromYesterday')}}</template>
                    <template v-if="state.salesEfficiency>0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">{{fixNumberVirgul(state.salesEfficiency)}}%</span> {{t('Pos.FromYesterday')}}</template>
                    <template v-if="state.salesEfficiency<0"><FallOutlined class="fall-icon"/> <span class="fall-txt">{{fixNumberVirgul(state.salesEfficiency)}}%</span> {{t('Pos.FromYesterday')}}</template>
                </span>

                
            </a-flex>
           
        </a-card>
    </a-col>

    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="card-container">
        <a-card hoverable>

            <a-flex gap="middle" justify="space-between">
                <div class="revenu-info">
                    <div :class="[locale === 'ar' ? 'products-container-rtl' : 'products-container']">
                        <ShoppingCartOutlined class="revenu-icon"/>
                    </div>  
                    <span class="revenu-title">{{t('Pos.Products')}}</span>
                </div>   
            </a-flex>

            <a-flex align="start" vertical class="numbers-container">
                <span class="big-title">{{state.totProducts}} {{t('Pos.Items')}}</span>
                <span class="revenu-title">
                    <ExclamationCircleOutlined :class="[locale === 'ar' ? 'fall-icon-rtl' : 'fall-icon']"/> 
                    <span class="fall-txt">{{state.totProdOutOfStock}}</span> 
                    <span :class="[locale === 'ar' ? 'end-prod-txt-rtl' : 'end-prod-txt']"  :data-original-text="t('Pos.Endofstockproducts')"> {{t('Pos.Endofstockproducts')}} </span>
                </span>
            </a-flex>

          
           
        </a-card>
    </a-col>

    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 12}" :lg="{ span: 12 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="card-container">
        <a-card hoverable>

            <a-flex gap="middle" justify="space-between">
                <div class="revenu-info">
                    <div  :class="[locale === 'ar' ? 'discount-container-rtl' : 'discount-container']">
                        <PercentageOutlined class="revenu-icon"/>
                    </div>
                    <span class="revenu-title">{{t('Pos.TotalDiscounts')}}</span>
                </div>

            </a-flex>

            <a-flex align="start" vertical class="numbers-container">
                <span class="big-title" v-if="selectValue==='monthly' && monthlyPicker!==null">{{formatAmount(state.monthlyDiscount)}}</span>
                <span class="revenu-title" v-if="selectValue==='monthly' && monthlyPicker!==null">
                    <template v-if="state.monthlyDiscountEff===0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">{{state.monthlyDiscountEff}}%</span> {{t('Pos.fromLastMonth')}}</template>
                   <template v-if="state.monthlyDiscountEff>0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">{{state.monthlyDiscountEff}}%</span> {{t('Pos.fromLastMonth')}}</template>
                    <template v-if="state.monthlyDiscountEff<0"><FallOutlined class="fall-icon"/> <span class="fall-txt">{{state.monthlyDiscountEff }}%</span> {{t('Pos.fromLastMonth')}}</template>
                </span>


                <span class="big-title" v-if="selectValue==='daily'">{{formatAmount(state.dailyDiscounts)}}</span>
                <span class="revenu-title" v-if="selectValue==='daily'">
                    <template v-if="state.dailyDiscountEffecency===0"><span class="rise-txt">{{state.dailyDiscountEffecency}}%</span> {{t('Pos.FromYesterday')}}</template>
                    <template v-if="state.dailyDiscountEffecency>0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">{{state.dailyDiscountEffecency}}%</span> {{t('Pos.FromYesterday')}}</template>
                    <template v-if="state.dailyDiscountEffecency<0"><FallOutlined class="fall-icon"/> <span class="fall-txt">{{state.dailyDiscountEffecency }}%</span> {{t('Pos.FromYesterday')}}</template>
                </span>

                <span class="big-title" v-if="selectValue==='annual'">{{formatAmount(state.annualDiscounts)}}</span>
                <span class="revenu-title" v-if="selectValue==='annual'">
                    <template v-if="state.dailyDiscountEffecency===0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">0%</span> {{t('Pos.FromLastYear')}}</template>
                    <template v-if="state.dailyDiscountEffecency>0"><RiseOutlined class="rise-icon"/> <span class="rise-txt">0%</span> {{t('Pos.FromLastYear')}}</template>
                    <template v-if="state.dailyDiscountEffecency<0"><FallOutlined class="fall-icon"/> <span class="fall-txt">0%</span> {{t('Pos.FromLastYear')}}</template>
                </span>
            </a-flex>
           
        </a-card>
    </a-col>
   </a-row>

    <!-- sales chart --> 
    <a-row class="charts-container" :gutter="16">
        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 18 }" :xxl="{ span: 18 }" class="card-container">
            <a-card hoverable>
                <a-flex justify="space-between">
                    <span class="charts-title">{{t('Pos.SalesOver')}}</span>

                    <!-- <a-select v-model:value="state.chartType" show-search class="select-chart" @select="selectChartType">
                        <a-select-option value="daily">{{ t('Pos.daily') }}</a-select-option>
                        <a-select-option value="monthly">{{ t('Pos.monthly') }}</a-select-option>
                        <a-select-option value="yearly">{{ t('Pos.yearly') }}</a-select-option>
                    </a-select> -->

                </a-flex>
                <canvas class="revenue-chart-container" id="revenuChart"></canvas>
            </a-card>
        </a-col>

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 6 }" :xxl="{ span: 6 }" class="card-container">
            <a-card hoverable class="donut-card">
                <a-flex alig="start" vertical>
                    <span class="charts-title">{{t('Pos.PaymentMethod')}}</span>
                    <template v-if="state.payements.length!==0">
                        <canvas id="donutChart" class="donut-container"  ></canvas>
                    </template>

                    <template v-if="state.payements.length===0">
                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                    </template>
                </a-flex>
                
            </a-card>
        </a-col>
    </a-row>
   
    
    <a-row class="charts-container" :gutter="24">

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="card-container">
            <a-card hoverable>

                <a-row class="tbl-title" :gutter="16">

                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 16}" :lg="{ span: 16 }" :xl="{ span: 16 }" :xxl="{ span: 16 }" class="tbl-right">
                        
                        <ExclamationCircleOutlined  :class="[locale === 'ar' ? 'warning-icon-rtl' : 'warning-icon']" />
                        <span class="warning-title">{{t('Pos.Endofstockproducts')}}</span>
                       
                    </a-col>
                    
                    
                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 8}" :lg="{ span: 8 }" :xl="{ span: 8 }" :xxl="{ span: 8 }">
                        <a-select v-model:value="warehouse_id" show-search :option-filter-prop="'label'" class="select-input" @change="handleWarehouseChange">
                            <template #notFoundContent>
                                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                            </template>

                            <a-select-option v-for="item in state.warehouses" :label="item.name" :key="item.id" :value="item.id" :selected="item.id === warehouse_id ">{{ item.name }}</a-select-option>
                        </a-select>
                    </a-col>

                </a-row>

                <a-table :columns="endColumns" :data-source="state.endOfStock" class="table-container" >
                    <template #emptyText>
                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                    </template>
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'total'">
            
                            <span class="warning-txt">{{record.total}}</span>
                        </template>
                    </template>
                    <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                        <div style="padding: 8px">
                            <a-input ref="searchInput" :placeholder=" `${column.title}`" :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

                            <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
                                @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
                                <template #icon>
                                <SearchOutlined />
                                </template>
                                {{t('Pos.Search')}}
                            </a-button>
                            <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                                {{t('Pos.Reset')}}
                            </a-button>
                        </div>
                    </template>

                    <template #customFilterIcon="{ filtered }">
                        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
                    </template>
                </a-table>
            </a-card>
        </a-col>

    </a-row> 
   

   <a-row class="popular-table">
        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="card-container">

            <a-card hoverable>
                <a-flex class="tbl-title">
                    <span class="charts-title">{{t('Pos.PopularProducts')}}</span>
                </a-flex>

                <a-table :columns="columns" :data-source="state.data" class="table-container" >

                    
                    <template #emptyText>
                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                    </template>

                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'total'">
            
                        {{formatTotal(record.total)}}
                        </template>
                    </template>
                    <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                        <div style="padding: 8px">
                            <a-input ref="searchInput" :placeholder=" `${column.title}`" :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

                            <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
                                @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
                                <template #icon>
                                <SearchOutlined />
                                </template>
                                {{t('Pos.Search')}}
                            </a-button>
                            <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                                {{t('Pos.Reset')}}
                            </a-button>
                        </div>
                    </template>

                    <template #customFilterIcon="{ filtered }">
                        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
                    </template>

                </a-table>


            </a-card>
           
        </a-col>
   </a-row>

</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed } from 'vue';
import {
 
  ShoppingCartOutlined,
  ShoppingOutlined,
  ExclamationCircleOutlined,
  RiseOutlined,
  FallOutlined,
 
  DollarCircleOutlined,
  PercentageOutlined,
  SearchOutlined,

  

} from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import {useRouter } from 'vue-router';
import {staticticsService, warehouseService} from'@/_services';
import { Chart} from 'chart.js/auto';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr'; 
import 'dayjs/locale/ar'; 
import { Empty } from 'ant-design-vue';
import {currencyConfig} from "@/_helpers";
export default defineComponent({
    name: 'indexDashboard',
    components: {
        //CalendarOutlined,
        DollarCircleOutlined,
        RiseOutlined,
        ShoppingOutlined,
        ShoppingCartOutlined,
        FallOutlined,
        PercentageOutlined,
        SearchOutlined,
        ExclamationCircleOutlined,
        // DownOutlined,
        // UpOutlined,
        // RiseOutlined,
         
       

    },
    setup() {

       
        const { locale } = useI18n();

        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        let warehouse_id = ref('');

        const { t } = useI18n();
        const router = useRouter();

        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const state = reactive({
           searchText: '',
           searchedColumn: '',
           todayTotalSales:0,
           monthlySales:0,
           totProducts:0,
           totProdOutOfStock:0,
           totIngredients:0,
           totIngrOutOfStock:0,
           data:[] as any[],
           endOfStock:[] as any[],
           testEnd: [] as any[],
           soldProducts:[] as any[],
           totalDaysSales:[] as any[],
           monthSales:[] as any[],
           warehouses:[] as any[],
           currentMonthEfficiency:0,
           previousMonthEfficiency:0,
           salesEfficiency:0,
           chartType:'daily',
           payements:[] as any[],

           dailyRevenu:'',
           dailyRevenuEffecency:0,

           dailyDiscounts:'',
           dailyDiscountEffecency:0,

           monthlyRevenu:'',
           monthlyRevenuEff:0,

           monthlyDiscount:'',
           monthlyDiscountEff:0,

           annualDiscounts:'',

           annualRevenue:'',

           annualSales:'',
           currentCurrency:'',
           annualRevenuEffecency:0,

        
        });

        const selectValue = ref('daily');
        const searchInput = ref<HTMLInputElement | null>(null);
        const columns = [
            {
                title: t('Pos.Name'),
                key: 'name',
                dataIndex: 'name',
                customFilterDropdown: true,
                onFilterDropdownOpenChange: (visible: boolean) => {
                    if (visible && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                        if (input instanceof HTMLInputElement) {
                            searchInput.value = input;
                            searchInput.value.focus();
                        }
                        }, 100);
                    }
                },
            },
            {
                title: t('Pos.Quantity'),
                key: 'quantity',
                dataIndex: 'quantity',
            
            },
            {
                title: t('Pos.Total'),
                dataIndex: 'total',
                key: 'total',
            }

        ];

        const endColumns = [

            
            {
                title: t('Pos.Product'),
                key: 'name',
                dataIndex: 'name',
                customFilterDropdown: true,
                // onFilterDropdownOpenChange: (visible: boolean) => {
                //     if (visible && !searchInput.value) {
                //         setTimeout(() => {
                //         const input = document.querySelector('.ant-table-filter-dropdown input');

                //         if (input instanceof HTMLInputElement) {
                //             searchInput.value = input;
                //             searchInput.value.focus();
                //         }
                //         }, 100);
                //     }
                // },
            },
            {
                title: t('Pos.Quantity'),
                dataIndex: 'total',
                key: 'total',
            }

        ];

        const getSales=()=>{
            staticticsService.getSales().then((response)=>{
                console.log("getting sales", response.today_total_sales);
                state.todayTotalSales=response.today_total_sales.toFixed(2);
                state.monthlySales = response.current_month_sales.toFixed(2);
                state.currentMonthEfficiency=response.currentMonthEfficiency.toFixed(2);
                state.previousMonthEfficiency=response.previousMonthEfficiency.toFixed(2);
                state.totalDaysSales=response.dailySalesPrevious30Days;
                state.salesEfficiency=state.currentMonthEfficiency-state.previousMonthEfficiency;
                state.monthSales=response.monthlySales;
                state.annualSales=response.current_year_sales.toFixed(2);
                
            }).catch((error)=>{
                console.log("error fetching staticts sales", error);
            }).finally(()=>{
                console.log('finally revenu chart');

                const existingChart = Chart.getChart("revenuChart");
                if (existingChart) {
                    existingChart.destroy();
                }

                //dailySales();
                salesChart(state.totalDaysSales);
            });
        }

        const getProducts=()=>{
            staticticsService.getProducts().then((response)=>{
                state.data=response.products_sold;
                state.totProducts=response.total_products;
                state.totProdOutOfStock=response.total_products_out_of_stock;
                state.totIngredients=response.total_ingredients;
                state.totIngrOutOfStock=response.total_ingredients_out_of_stock;
                state.soldProducts=response.products_sold;
            }).catch((error)=>{
                console.log("error fetching products", error);
            }).finally(()=>{
                console.log('finally');
                // //charts :

                // const existingChart = Chart.getChart("pieChart");
                // if (existingChart) {
                //     existingChart.destroy();
                // }
                // pieChart();
            });
        }

        const getPaymentMethods=(period:any)=>{
           staticticsService.getPayementMethods(period).then((res)=>{
                state.payements=res.data;
           }).catch((error)=>{
                console.log('error getting payment methods', error);
           }).finally(()=>{
                const existingChart = Chart.getChart("donutChart");
                if (existingChart) {
                    existingChart.destroy();
                }

                donutChart(state.payements);
           });
        }

        //charts
        // const generateRandomColors = (count:any) => {
        //     const colors = [];
        //     for (let i = 0; i < count; i++) {
        //         const color = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
        //         colors.push(color);
        //     }
        //     return colors;
        // };

        const donutChart=(datas:any[])=>{

            let labels: string[] = [];
            let totals:number[]=[];

            datas.forEach(item=>{
                labels.push(item.name),
                totals.push(item.sum)
            });

           
            
            return  new Chart("donutChart", {
                type: 'doughnut',
                data: {
                    labels: labels ,
                    datasets: [{
                        label: t('Pos.CA'),
                        data: totals,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.6)', 
                            'rgba(255, 159, 64, 0.6)', 
                            'rgba(255, 205, 86, 0.6)', 
                            'rgba(75, 192, 192, 0.6)', 
                            'rgba(54, 162, 235, 0.6)', 
                            'rgba(153, 102, 255, 0.6)', 
                            'rgba(201, 203, 207, 0.6)', 
                        ]
                    }]
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'bottom'
                        }
                    },
                    layout: {
                        padding: 0,
                    }
                    
                    
                }
                
                
            });
        }

        const pieChart=()=>{
            console.log('pie chart');
            const productNames = state.soldProducts.map(product => product.name);
            const quantities = state.soldProducts.map(product => parseFloat(product.quantity));
            
            return  new Chart("pieChart", {
                type: 'pie',
                data: {
                    labels: productNames ,
                    datasets: [{
                        label: t('Pos.Quantity'),
                        data: quantities,
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.6)', 
                            'rgba(255, 159, 64, 0.6)', 
                            'rgba(255, 205, 86, 0.6)', 
                            'rgba(75, 192, 192, 0.6)', 
                            'rgba(54, 162, 235, 0.6)', 
                            'rgba(153, 102, 255, 0.6)', 
                            'rgba(201, 203, 207, 0.6)', 
                            'rgba(0, 128, 0, 0.6)', 
                            'rgba(255, 0, 255, 0.6)', 
                            'rgba(0, 255, 255, 0.6)' 
                        ]
                    }]
                },
                options: {
                    responsive: true,
                    
                    
                }
                
                
            });
        }

        const selectChartType=(value:any)=>{
            state.chartType=value;

            const existingChart = Chart.getChart("revenuChart");
            if (existingChart) {
                existingChart.destroy();
            }

            if(state.chartType=="daily" ){
                console.log('state.tot sales', state.totalDaysSales);
                salesChart(state.totalDaysSales);
            }

            

            if(state.chartType=="monthly"){

                console.log('state.tot sales', state.totalDaysSales);
                salesChart(state.totalDaysSales);
            }

            if(state.chartType=="yearly"){
                console.log('state.tot sales', state.totalDaysSales);
                salesChart(state.totalDaysSales);
            }
        }

        const dailySales=()=>{
            const dailySales: Record<string, number> = {};
            let labels;
            let totals;
            state.totalDaysSales.forEach(sale => {
                const date = new Date(sale.date);
                const dayNumber = date.getDate().toString(); 
                if (!dailySales[dayNumber]) {
                    dailySales[dayNumber] = sale.total;
                } else {
                    dailySales[dayNumber] += sale.total;
                }
            });

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1; 
            const numDaysInMonth = new Date(year, month, 0).getDate();

            // days with no sales have 0
            for (let i = 1; i <= numDaysInMonth; i++) {
                const dayNumber = i.toString();
                if (!dailySales[dayNumber]) {
                    dailySales[dayNumber] = 0;
                }
            }



            labels = Array.from({ length: numDaysInMonth }, (_, i) => (i + 1).toString());
            totals = labels.map(dayNumber => dailySales[dayNumber]);

            return new Chart("revenuChart", {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        label: t('Pos.Sales'),
                        data: totals,
                        fill: false,
                        borderColor: '#fc8019',
                        tension: 0.1
                    }]
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        }
                    },
                }
            });


        }

        const handleChange=(selectedType:any)=>{
            console.log('selecteType', selectedType);
            const existingChart = Chart.getChart("revenuChart");
            if (existingChart) {
                existingChart.destroy();
            }
            

            const existingDonutChart = Chart.getChart("donutChart");
            if (existingDonutChart) {
                existingDonutChart.destroy();
            }
            

            if(selectedType==='daily'){
                salesChart(state.totalDaysSales);
                getPaymentMethods('DAILY');
            }
            const currentDate=dayjs();
            if(selectedType==='monthly' ){
                
                monthlyPicker.value = currentDate;
                const startOfMonth = currentDate.startOf('month').format('DD-MM-YYYY');
                const endOfMonth = currentDate.endOf('month').format('DD-MM-YYYY');

                getMonthlyRevenu(startOfMonth, endOfMonth);
                getMonthlyDiscounts(startOfMonth, endOfMonth);

                salesChart(state.totalDaysSales);
                getMonthlyRevenuEff();

                getPaymentMethods('MONTHLY');

                //donutChart(state.payements);
            }

            if(selectedType==='annual'){
                annualPicker.value=currentDate;
                getAnuualDiscounts();
                getAnnualRevenu();

                salesChart(state.monthSales);
                getPaymentMethods('YEARLY');
                
            }

           

        }

        require('dayjs/locale/fr');
        require('dayjs/locale/ar');

        const salesChart = (datas:any[]) => {
            const dailySales: Record<string, number> = {};
            let labels;
            let totals;

            console.log('selected value', selectValue.value)

            const today=dayjs();
            const lang=localStorage.getItem('Language')|| 'en';

        
            //monthly
            if(selectValue.value==="monthly"){

                //old
               
                console.log('monthly picker', monthlyPicker.value?.month());
                const selectedMonth = monthlyPicker.value?.month();

                if (selectedMonth !== undefined) {
                    const dailySales: Record<string, number> = {}; 

                    // days of the week
                    const allDays = [ t('Pos.Monday').toLocaleLowerCase(), t('Pos.Tuesday').toLocaleLowerCase(), t('Pos.Wednesday').toLocaleLowerCase(), t('Pos.Thursday').toLocaleLowerCase(), t('Pos.Friday').toLocaleLowerCase(), t('Pos.Saturday').toLocaleLowerCase(), t('Pos.Sunday').toLocaleLowerCase()];

                    //const allDays = [ t('Pos.Monday'),  t('Pos.Tuesday'), t('Pos.Wednesday'), t('Pos.Thursday'), t('Pos.Friday'), t('Pos.Saturday'), t('Pos.Sunday')];
                    datas.forEach(sale => {
                        dayjs.locale(lang);
                        const date = dayjs(sale.date).locale(lang);
                        console.log('date monthly translation', date);
                        const day = date.format('dddd').toLocaleLowerCase(); 

                        console.log('daty', day);

                        const month = date.month();


                        // Check if the sale is in the selected month
                        if (month === selectedMonth) {

                            console.log('here')
                            if (!dailySales[day]) {
                                console.log('aaa', dailySales)
                                dailySales[day] = sale.total;
                            } else {
                                dailySales[day] += sale.total;
                            }
                        }
                    });

                    // Fill in zeros for days without sales
                    allDays.forEach(day => {
                        if (!dailySales[day]) {
                            dailySales[day] = 0;
                        }
                    });

                    // Sort days by order
                    const sortedDays = allDays.slice().sort((a, b) => {
                        return allDays.indexOf(a) - allDays.indexOf(b);
                    });

                    // Prepare labels and totals arrays
                    labels = sortedDays;
                    totals = sortedDays.map(day => dailySales[day]);

                    console.log('Labels:', labels);
                    console.log('Totals:', totals);

                } else {
                    console.log('No month selected');
                }
                


               
               
                


                
            }

            if(selectValue.value==="daily"){
                /*
                const dailySales: Record<string, number> = {}; 

                // days of the week
                const allDays = [ t('Pos.Monday').toLocaleLowerCase(), t('Pos.Tuesday').toLocaleLowerCase(), t('Pos.Wednesday').toLocaleLowerCase(), t('Pos.Thursday').toLocaleLowerCase(), t('Pos.Friday').toLocaleLowerCase(), t('Pos.Saturday').toLocaleLowerCase(), t('Pos.Sunday').toLocaleLowerCase()];

                datas.forEach(sale => {
                    dayjs.locale(lang);
                    const date = dayjs(sale.date).locale(lang);
                    console.log('date monthly translation', date);
                    const day = date.format('dddd').toLocaleLowerCase(); 

                    console.log('daty', day);

                    const month = date.month();


                   
                    if (!dailySales[day]) {
                        console.log('aaa', dailySales)
                        dailySales[day] = sale.total;
                    } else {
                        dailySales[day] += sale.total;
                    }
                    
                });

                // Fill in zeros for days without sales
                allDays.forEach(day => {
                    if (!dailySales[day]) {
                        dailySales[day] = 0;
                    }
                });

                // Sort days by order
                const sortedDays = allDays.slice().sort((a, b) => {
                    return allDays.indexOf(a) - allDays.indexOf(b);
                });

                // Prepare labels and totals arrays
                labels = sortedDays;
                totals = sortedDays.map(day => dailySales[day]);

                console.log('Labels:', labels);
                console.log('Totals:', totals);
                */


                datas.forEach(sale => {
                    const date = new Date(sale.date);
                    const dayNumber = date.getDate().toString(); 
                    if (!dailySales[dayNumber]) {
                        dailySales[dayNumber] = sale.total;
                    } else {
                        dailySales[dayNumber] += sale.total;
                    }
                });

                const uniqueDays = Array.from(new Set(datas.map(sale => {
                    const date = new Date(sale.date);
                    return date.getDate().toString();
                })));

                labels = uniqueDays.map(day => dailySales[day] ? day : "");
                totals = uniqueDays.map(day => dailySales[day] || 0);


            }

            if(selectValue.value==='annual'){

                dayjs.locale(lang);
                labels=[t('Pos.January'),  t('Pos.February'), t('Pos.March'), t('Pos.April'), t('Pos.May'), t('Pos.Juin'), t('Pos.July'), t('Pos.August'),  t('Pos.September'),  t('Pos.October'),  t('Pos.November'), t('Pos.December') ];
                totals = Array(12).fill(0);
                datas.forEach(sale=>{
                    const { month, total } = sale;
                    totals[month - 1] = total;
                })
            }

            console.log('line chart ', labels, totals);
    
            return new Chart("revenuChart", {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        label: t('Pos.CA'),
                        data: totals,
                        fill: false,
                        borderColor: '#fc8019',
                        tension: 0.1
                    }]
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        };

        const salesChart1 = (datas:any[]) => {
            const dailySales: Record<string, number> = {};
            let labels;
            let totals;

            console.log('selected value', selectValue.value)

            const today=dayjs();
            const lang=localStorage.getItem('Language')|| 'en';

        

            if(selectValue.value==="monthly"){

                console.log('monthly picker', monthlyPicker.value?.month());
                const selectedMonth = monthlyPicker.value?.month();

                if (selectedMonth !== undefined) {
                    const dailySales: Record<string, number> = {}; 

                    // days of the week
                    const allDays = [ t('Pos.Monday').toLocaleLowerCase(), t('Pos.Tuesday').toLocaleLowerCase(), t('Pos.Wednesday').toLocaleLowerCase(), t('Pos.Thursday').toLocaleLowerCase(), t('Pos.Friday').toLocaleLowerCase(), t('Pos.Saturday').toLocaleLowerCase(), t('Pos.Sunday').toLocaleLowerCase()];

                    //const allDays = [ t('Pos.Monday'),  t('Pos.Tuesday'), t('Pos.Wednesday'), t('Pos.Thursday'), t('Pos.Friday'), t('Pos.Saturday'), t('Pos.Sunday')];
                    datas.forEach(sale => {
                        dayjs.locale(lang);
                        const date = dayjs(sale.date).locale(lang);
                        console.log('date monthly translation', date);
                        const day = date.format('dddd').toLocaleLowerCase(); 

                        console.log('daty', day);

                        const month = date.month();


                        // Check if the sale is in the selected month
                        if (month === selectedMonth) {

                            console.log('here')
                            if (!dailySales[day]) {
                                console.log('aaa', dailySales)
                                dailySales[day] = sale.total;
                            } else {
                                dailySales[day] += sale.total;
                            }
                        }
                    });

                    // Fill in zeros for days without sales
                    allDays.forEach(day => {
                        if (!dailySales[day]) {
                            dailySales[day] = 0;
                        }
                    });

                    // Sort days by order
                    const sortedDays = allDays.slice().sort((a, b) => {
                        return allDays.indexOf(a) - allDays.indexOf(b);
                    });

                    // Prepare labels and totals arrays
                    labels = sortedDays;
                    totals = sortedDays.map(day => dailySales[day]);

                    console.log('Labels:', labels);
                    console.log('Totals:', totals);

                } else {
                    console.log('No month selected');
                }

               
                // const allDays = [ t('Pos.Monday'),  t('Pos.Tuesday'), t('Pos.Wednesday'), t('Pos.Thursday'), t('Pos.Friday'), t('Pos.Saturday'), t('Pos.Sunday')];

                // datas.forEach(sale => {
                //     dayjs.locale(lang);
                //     const date = dayjs(sale.date).locale(lang);
                //     const day = date.format('dddd').toLocaleLowerCase(); 
                   
                //     if (!dailySales[day]) {
                //         dailySales[day] = sale.total;
                //     } else {
                //         dailySales[day] += sale.total;
                //     }
                // });

                // console.log('daily sales', dailySales);

                // //filling zeros for days without sales
                // console.log('all days', allDays);
                // allDays.forEach(day => {
                //     const lowercaseDay = day.toLowerCase();
                //     if (!dailySales[lowercaseDay]) {
                //         dailySales[lowercaseDay] = 0;
                //     }
                // });

                // // sort days by order
                // const sortedDays = allDays.slice().sort((a, b) => {
                //     return allDays.indexOf(a) - allDays.indexOf(b);
                // });

                // // labels and tot arrays
                // labels = sortedDays;
                // totals = sortedDays.map(day => dailySales[day.toLowerCase()]);


                
            }

            if(selectValue.value==="daily"){

                // datas.forEach(sale => {
                //     const date = new Date(sale.date);
                //     const dayNumber = date.getDate().toString(); 
                //     if (!dailySales[dayNumber]) {
                //         dailySales[dayNumber] = sale.total;
                //     } else {
                //         dailySales[dayNumber] += sale.total;
                //     }
                // });

                // const currentDate = new Date();
                // const year = currentDate.getFullYear();
                // const month = currentDate.getMonth() + 1; 
                // const numDaysInMonth = new Date(year, month, 0).getDate();
                // console.log('numDaysInMonth', numDaysInMonth);

                // // days with no sales have 0
                // for (let i = 1; i <= numDaysInMonth; i++) {
                //     const dayNumber = i.toString();
                //     if (!dailySales[dayNumber]) {
                //         dailySales[dayNumber] = 0;
                //     }
                // }



                // labels = Array.from({ length: numDaysInMonth }, (_, i) => (i + 1).toString());
                // totals = labels.map(dayNumber => dailySales[dayNumber]);


                datas.forEach(sale => {
                    const date = new Date(sale.date);
                    const dayNumber = date.getDate().toString(); 
                    if (!dailySales[dayNumber]) {
                        dailySales[dayNumber] = sale.total;
                    } else {
                        dailySales[dayNumber] += sale.total;
                    }
                });

                const uniqueDays = Array.from(new Set(datas.map(sale => {
                    const date = new Date(sale.date);
                    return date.getDate().toString();
                })));

                labels = uniqueDays.map(day => dailySales[day] ? day : "");
                totals = uniqueDays.map(day => dailySales[day] || 0);


                
            }

            if(selectValue.value==='annual'){

                dayjs.locale(lang);
                labels=[t('Pos.January'),  t('Pos.February'), t('Pos.March'), t('Pos.April'), t('Pos.May'), t('Pos.Juin'), t('Pos.July'), t('Pos.August'),  t('Pos.September'),  t('Pos.October'),  t('Pos.November'), t('Pos.December') ];

                //totals=[1,8,3,0,4,8,0,22,1,3,5,6];
                totals = Array(12).fill(0);
                datas.forEach(sale=>{
                    const { month, total } = sale;
                    totals[month - 1] = total;
                })
                console.log('totals revenu annual', totals);

            }

            console.log('line chart ', labels, totals);
    
            return new Chart("revenuChart", {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        label: t('Pos.CA'),
                        data: totals,
                        fill: false,
                        borderColor: '#fc8019',
                        tension: 0.1
                    }]
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        };

        // const dailySaless = () => {
        //     const dailySales: Record<string, number> = {};

        //     const allDays = Array.from({ length: 7 }, (_, i) => {
        //         const date = new Date(Date.UTC(2000, 0, i + 1));
        //         return date.toLocaleString('default', { weekday: 'long' });
        //     });

        //     state.totalDaysSales.forEach(sale => {
        //         const date = new Date(sale.date);
        //         const day = date.toLocaleString('default', { weekday: 'long' });
        //         if (!dailySales[day]) {
        //             dailySales[day] = sale.total;
        //         } else {
        //             dailySales[day] += sale.total;
        //         }
        //     });

        //     // Fill in zeros for months without sales
        //     allDays.forEach(day => {
        //         if (!dailySales[day]) {
        //             dailySales[day] = 0;
        //         }
        //     });

        //     // Sorting the months alphabetically
        //    const sortedDays = Object.keys(dailySales).sort((a, b) => {
        //         return allDays.indexOf(a) - allDays.indexOf(b);
        //     });

        //     // Creating labels and totals arrays
        //     const labels = sortedDays;
        //     const totals = sortedDays.map(day => dailySales[day]);

        //     return new Chart("revenuChart", {
        //         type: 'line',
        //         data: {
        //             labels: labels,
        //             datasets: [{
        //                 label: t('Pos.Sales'),
        //                 data: totals,
        //                 fill: false,
        //                 borderColor: '#fc8019',
        //                 tension: 0.1
        //             }]
        //         },
        //         options: {
        //             responsive: true,
                    
        //             scales: {
        //                 y: {
        //                     beginAtZero: true
        //                 }
        //             }
        //         }
        //     });
        // };

        

        //search 
    
        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            console.log('searched data', state.searchText);
            console.log('seareched column', state.searchedColumn);


            /*
            if (dataIndex === 'name' && state.searchText) {
                console.log('begin filter');
                filter(state.searchText);
            }
            */
        };

        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            getProducts();

        };

        const formatTotal = (price:any) => {
            return parseFloat(price).toFixed(3); 
        };

        const getWarehouses=()=>{
            warehouseService.getAll().then((res)=>{
                state.warehouses=res.data;
                if (res.data.length > 0) {
                    warehouse_id.value = res.data[0].id; 
                    console.log('warehouse_id', warehouse_id.value);
                } else {
                    console.log('No warehouses available.');
                }
                
            }).catch((error)=>{
                console.log('error getting warehouses', error);
            }).finally(()=>{
                getEndOfStock(warehouse_id.value);
            })
        }

        const getEndOfStock=(warehouse_id:any)=>{
          staticticsService.getEndofStockProducts(warehouse_id).then((res)=>{
            state.endOfStock=res.products_out_of_stock;
          }).catch((error)=>{
            console.log('error getting out of stock products', error);
          })
        }

        const handleWarehouseChange=(selectedItem:any)=>{
            getEndOfStock(selectedItem);
        }


        const currentDate = dayjs();
        const yesterdayDate = currentDate.subtract(1, 'day');

        const getDailyrevenu=()=>{

            staticticsService.getDailyRevenu('DAILY').then((res)=>{
                const todayRevenu = res.data.find((item:any) => 
                    item.day === currentDate.date() &&
                    item.month === currentDate.month() + 1 &&
                    item.year === currentDate.year()
                );

                const yesterdayRevenu = res.data.find((item:any) => 
                    item.day === yesterdayDate.date() &&
                    item.month === yesterdayDate.month() + 1 &&
                    item.year === yesterdayDate.year()
                );

                const todayRevenuValue = todayRevenu ? todayRevenu.revenue : 0;
                const yesterdayRevenuValue = yesterdayRevenu ? yesterdayRevenu.revenue : 0;

                console.log('yesetday s revenu',yesterdayRevenuValue); 
                let efficiency = 0;
                if (yesterdayRevenuValue !== 0) {
                    efficiency = ((todayRevenuValue - yesterdayRevenuValue) / yesterdayRevenuValue) * 100;
                }else{
                    efficiency=0;
                }
                



                state.dailyRevenu = parseFloat(todayRevenuValue).toFixed(3);
                state.dailyRevenuEffecency = parseFloat(efficiency.toFixed(2));
                console.log('revenu effecncy', state.dailyRevenuEffecency);

            }).catch((error)=>{
                console.log(error);
            });
          
            
           
        }




        const fixNumberVirgul =(dailyRevenuEffecency:any)=> {
            if (typeof dailyRevenuEffecency !== 'number') {
        throw new Error('Input must be a number');
    }
    return parseFloat(dailyRevenuEffecency.toFixed(1));

        }


        const getAnuualDiscounts=()=>{
            staticticsService.getDailyDiscounts('YEARLY').then((res=>{
                state.annualDiscounts=parseFloat(res.data[0].discount).toFixed(3);
                console.log('annual discount', state.annualDiscounts);
            })).catch((error)=>{
                console.log(error);
            })
        }


        const dailyDiscounts=()=>{
            staticticsService.getDailyDiscounts('DAILY').then((res)=>{
                const todayDiscount = res.data.find((item:any) => 
                    item.day === currentDate.date() &&
                    item.month === currentDate.month() + 1 &&
                    item.year === currentDate.year()
                );

                const yesterdayDiscount = res.data.find((item:any) => 
                    item.day === yesterdayDate.date() &&
                    item.month === yesterdayDate.month() + 1 &&
                    item.year === yesterdayDate.year()
                );

                const todayDiscountValue = todayDiscount ? todayDiscount.discount : 0;
                const yesterdayDiscountValue = yesterdayDiscount ? yesterdayDiscount.discount : 0;

                const efficiency = yesterdayDiscountValue !== 0 ? ((todayDiscountValue - yesterdayDiscountValue) / yesterdayDiscountValue) * 100 : 0;



                state.dailyDiscounts = parseFloat(todayDiscountValue).toFixed(3);
                state.dailyDiscountEffecency = parseFloat(efficiency.toFixed(2));

            }).catch((error)=>{
                console.log(error);
            });
        }


        const getAnnualRevenu=()=>{
            staticticsService.getDailyRevenu('YEARLY').then((res)=>{
                state.annualRevenue=parseFloat(res.data[0].revenue).toFixed(3);
            }).catch((error)=>{
                console.log(error);
            });
        }
       

        const getMonthlyRevenu=(start_date:any, end_date:any)=>{
            staticticsService.getMonthlyRevenu(start_date,end_date,'MONTHLY').then((res)=>{

                if(res.data && res.data.length>0){
                    state.monthlyRevenu=parseFloat(res.data[0].revenue).toFixed(3);
                }else{
                    state.monthlyRevenu='0';
                }
                
            });
        }

        const getMonthlyRevenuEff=()=>{
            staticticsService.getMonthlyRevenuEfficieny().then((res)=>{
                const prevEff=res.previous_month;
                const monthEff=res.last30Days;
                state.monthlyRevenuEff=monthEff-prevEff;
            }).catch((error)=>{
                console.log(error);
            });
        }

        const getMonthlyDiscountEff=()=>{
            staticticsService.getMonthlyDiscountEfficieny().then((res)=>{
                const prevEff=res.previous_month;
                const monthEff=res.last30Days;
                state.monthlyDiscountEff=monthEff-prevEff;
            }).catch((error)=>{
                console.log(error);
            });
        }

        const getMonthlyDiscounts=(start_date:any, end_date:any)=>{
            staticticsService.getMonthlyDiscounts(start_date,end_date,'MONTHLY').then((res)=>{
                if(res.data && res.data.length>0){
                    state.monthlyDiscount=parseFloat(res.data[0].discount).toFixed(3);
                }else{
                    state.monthlyDiscount='0';
                }
            }).catch((error)=>{
                console.log(error);
            });
        }

        
        //date pickers
        const monthlyPicker = ref<Dayjs | null>(null);
        const annualPicker = ref<Dayjs | null>(null);


        const handleMonthly=(date: any )=>{
           if(date!==null){
                const month = date.$M + 1;
                const year = date.$y;

                const startOfMonth = date.startOf('month').format('DD-MM-YYYY');
                const endOfMonth = date.endOf('month').format('DD-MM-YYYY');

                console.log('selected date', month, year);
                console.log('start date', startOfMonth);
                console.log('end date', startOfMonth);

                getMonthlyRevenu(startOfMonth,endOfMonth);
                getMonthlyDiscounts(startOfMonth,endOfMonth);
                getMonthlyRevenuEff();

                getMonthlyDiscountEff();


                const existingChart = Chart.getChart("revenuChart");
                if (existingChart) {
                    existingChart.destroy();
                }
                salesChart(state.totalDaysSales);
            }
           
        }

        const handleAnnual=(date: any )=>{
           if(date!==null){
                const year = date.$y;

                console.log('selected year', year);

                const existingChart = Chart.getChart("revenuChart");
                if (existingChart) {
                    existingChart.destroy();
                }
                salesChart(state.totalDaysSales);
            }
           
        }

        const disabledDate = (current: Dayjs) => {

            const store = JSON.parse(localStorage.getItem('store')||'null');
            const creationDate=store.created_at;

            const currentDate=dayjs();

            //unable to select days before the store creation month 
            //return current.isBefore(creationDate, 'day');


            // disable dates beyond the current month and year
            const isAfterCurrentMonthYear = current.isAfter(currentDate, 'month') || 
                                            current.isAfter(currentDate, 'year');

            // disable dates before the store creation month and year
            const isBeforeCreationMonthYear = current.isBefore(creationDate, 'month') ||
                                            current.isBefore(creationDate, 'year');

            return isAfterCurrentMonthYear || isBeforeCreationMonthYear;

        };

       
        //rtl 
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
       


      /*  const getCurrentCurrency=()=>{
            currencyService.getAllWithoutPagination().then((res)=>{
                const currencies = res.data;

                console.log('currencies', currencies);

                const defaultCurrency = currencies.find((currency:any) => currency.is_default === 1);
                if (defaultCurrency) {
                    state.currentCurrency = defaultCurrency.currency.code;
                    console.log("defaultCurrency",defaultCurrency.currency)
                    localStorage.setItem("currencies",JSON.stringify(defaultCurrency.currency))
                } else {
                    console.error("Default currency not found.");
                }
            }).catch((error)=>{
                console.log(error);
            });
        }*/

        const formatAmount=(amount:any)=>{
            if(amount){
                const currencyJson=localStorage.getItem('currencies');
                if(currencyJson===null){
                    return amount+'TND';
                }else{
                    const currency = JSON.parse(currencyJson);
                    const currencyCode= currency.code;

                    return(currencyConfig.formatAmount(amount, currencyCode,3));
                }
            }
        }

        //shrink end of stcok priducts card txt 
        window.addEventListener('resize', function() {
            truncateText();
        });

        const truncateText=()=>{

            var screenWidth = window.innerWidth;
            var textElements = document.querySelectorAll('.end-prod-txt');
            
            if (screenWidth >= 1199 && screenWidth <= 1306 ) {
                textElements.forEach(function(element) {
                    element.textContent = t('Pos.endOf'); 
                });
            }else{
                textElements.forEach(function(element) {
                    // reset original txt
                    element.textContent = element.getAttribute('data-original-text');
                });
            }
        }

        onMounted(()=>{
            truncateText();
            getSales();
            getProducts(); 
            getPaymentMethods('DAILY');
            getWarehouses();
            getDailyrevenu();
            dailyDiscounts();
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
        })
        return{
            formatAmount,
            fixNumberVirgul,
            t,
            textDirection,
            navigatePage,
            state,
            selectChartType,
            columns,
            handleSearch,
            handleReset,
            formatTotal,
            endColumns,
            selectValue,
            handleChange,
            simpleImage,
            warehouse_id,
            handleWarehouseChange,
            monthlyPicker,
            handleMonthly,
            disabledDate,
            annualPicker,
            handleAnnual,
            locale



          





        }
    },
})
</script>
<style scoped>

.dashboard-title{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   
}

.dash-title{
    font-weight: 600;
    font-size: 18px;
   
}

.dash-sub-title{
    margin-top: -30px;
    color: #828282;
}

.btn-dash-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.select-calender{
    width: 100%;
}

.btn-dash{
    border-radius: 20px !important;
    font-weight: 500;
}

.revenu-info{
    display: flex;
    align-items: center;
}

.revenu-container{
    background-color: #fff5e6;
    color: #fc8019;
    border-radius: 7px;
    margin-right: 3px;
}

.revenu-container-rtl{
    background-color: #fff5e6;
    color: #fc8019;
    border-radius: 7px;
    margin-left: 3px;
}

.order-container{
    background-color: #eae8fd;
    color: #7367f0;
    border-radius: 7px;
    margin-right: 3px;
}

.order-container-rtl{
    background-color: #eae8fd;
    color: #7367f0;
    border-radius: 7px;
    margin-left: 3px;
}

.products-container{
    background-color: #d9f8fc;
    color: #06b6ca;
    border-radius: 7px;
    margin-right: 3px;
}

.products-container-rtl{
    background-color: #d9f8fc;
    color: #06b6ca;
    border-radius: 7px;
    margin-left: 3px;
}


.discount-container{
    background-color: #dff7e9;
    color: #28c76f;
    border-radius: 7px;
    margin-right: 3px;
}

.discount-container-rtl{
    background-color: #dff7e9;
    color: #28c76f;
    border-radius: 7px;
    margin-left: 3px;
}


.revenu-icon{
    padding: 8px;
    font-size: 18px;
}

.revenu-title{
    color: #828282;

}
.big-title{
    font-weight: 700;
    font-size: 23px;
}

.numbers-container{
    margin-top: 10px;
}

.rise-icon{
    color: #28c76f;
    font-size: 16px;
    padding-right: 4px;
}

.fall-icon{
    color: #ea5455;
    font-size: 16px;
    padding-right: 4px;
}

.fall-icon-rtl{
    color: #ea5455;
    font-size: 16px;
    padding-left: 4px;
}
.fall-txt{
    color: #ea5455;
    font-weight: 600; 
}
.rise-txt{
    color: #28c76f;
    font-weight: 600;
}

.normal-txt{
    color: #7367f0;
}
.table-container {
    overflow-x: auto;
    }

.charts-container{
    margin-top: 10px;
}

.charts-title{
    font-weight: 700;
    font-size: 16px;
}

.warning-title{
    font-weight: 600;
    font-size: 16px;
    color: #ea5455;
}
.donut-chart-container{
    display: flex;
}

.donut-container{
    max-height: 230px;
    display: flex;
}

.revenue-chart-container{
    max-height: 230px;
}

.popular-table{
    margin-top: 10px;
}

.tbl-title{
    margin-bottom: 15px;
}

.warning-icon{
    color: #ea5455;
    font-size: 24px !important;
    padding-right: 10px;
}

.warning-icon-rtl{
    color: #ea5455;
    font-size: 24px !important;
    padding-left: 10px;
}



.warning-txt{
    color: #ea5455;

}

.select-input{
    width: 100%;
}

.calender-container{
    display:flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.calender-input{
    width: 100%;
}

.card-container {
    margin-top: 10px;
}

.end-prod-txt{
    margin-left: 5px;
}

.end-prod-txt-rtl{
    margin-right: 5px;
}


@media screen and (max-width: 765px) {
  .card-container {
    margin-top: 20px;
  }

 
} 

@media (max-width: 1200px) {
    .donut-container {
        max-height: 230px !important; 
        overflow: auto !important; 
    }

    .revenue-chart-container {
        max-height: 230px !important; 
        overflow: auto !important; 
    }
}

/* @media (max-width: 1314px) {
    .end-prod-txt {
         display: none; /
        
    }
} */


.card-container2 {
    margin-top: 20px;
}



/* CSS for RTL */
.is-rtl {
    direction:rtl;
}

.table-container {
overflow-x: auto;
}

.tbl-right{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
}

.donut-card{
    height: 100%;
    width: 100%;
}








/*

.card-title-container{
    margin-bottom: 20px;
    position: relative;
}
.card-title{
    font-size: 18px;
    font-weight: 600;
    margin: 0px 0px 16px;
    color: #5d596c;

    position: absolute;
    left: 20px;
    top: 0;
}

.card-sub-container{
    display: flex;
    justify-content:flex-end;
}

.card-sub{
    font-size: 13px;
    color: #a5a3ae;

}

.sales-number{
   

    font-size: 22px;
    font-weight: 600;
    color:#fc8019 ;
    
}

.sales-icon{
    background-color: #eae8fd;
    color: #7367f0;
    font-size: 25px;
    border-radius: 50%;
    padding: 0.6rem;
}

.customers-icon{
    background-color: #d9f8fc ;
    color: #00cfe8;
    font-size: 24px;
    border-radius: 50%;
    padding: 0.6rem;
}

.customers-icon{
    background-color: #d9f8fc ;
    color: #00cfe8;
    font-size: 24px;
    border-radius: 50%;
    padding: 0.6rem;
}

.products-icon{
    background-color: #fce5e6;
    color: #ea5455;
    font-size: 24px;
    border-radius: 50%;
    padding: 0.6rem;
}

.sales-icon{
    background-color: #dff7e9;
    color: #28c76f;
    font-size: 24px;
    border-radius: 50%;
    padding: 0.6rem;
}

.numbers{
    color: #4b465c;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.37;
}

.item-title{
    font-size: 14px;
    color: #4b465c ;
}

.sales-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.sales-title{
    font-size: 18px;
    font-weight: 600;
    color: #5d596c;
}

.sales-sub{
    color: #6F6B7D;
}

.btn-sales-container{
   margin-top: 5px;
}

.sales-btn{
    display: flex;
}

.sales-btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
*/



/* custom btn sales */

/* button {
  font-family: inherit;
  font-size: 18px;
  background: linear-gradient(to bottom, #e89a5a 0%,#fc8019 100%);
  color: white;
  padding: 0.4em 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 25px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
} */
/*
button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

button:active {
  transform: scale(0.95);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}



button .total-sales-icon {
  width: 18px;
  height: 18px;
  fill: white;
  transition: all 0.3s;
}

button .svg-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 0.5em;
  transition: all 0.3s;
}

button:hover .svg-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
}

button:hover .total-sales-icon {
  transform: rotate(45deg);
}

.total-sales-icon{
    display: flex;
}

.static-item-container{
    display: flex;
}
*/

/* charts  */
/*
.chart-container{
    margin-top: 10px;
}

.info-card{
    margin-bottom: 10px;
    
}

.info-container{
    display: flex;
}

.revenue-chart-container{
   max-height: 80%;
}


.pie-chart-card{
    width: 50%;
    border-radius: 10px;
   
}

.card-chart-title{
    color: #5d596c;
    padding: 5px;
}

.card-title-chart{
    font-size: 18px;
    font-weight: 600;
    color: #5d596c;
    
}

.info-card-title{
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0px 16px;
    color: #5d596c;

    position: absolute;
    left: 20px;
    top: 0;
    
}



.card-info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.card-info-title{
    color: #5d596c;
    font-size: 15px;
    font-weight: 600;

}

.efficiency-icon-container{
    display: flex;
}

.warning-icon{
    color: #ea5455;
    font-size: 24px !important;
    padding-right: 10px;
}

.info-numbers{
    padding-top: 5px;
    
}

.info-numbers-txt{
    color: #ea5455;
    font-weight:600 ;
    font-size: 15px;
}

.icon-txt{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.numbers-container{
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.select-container{

    display: flex;
    justify-content:flex-end;
}
.select-chart{
    width: 50%;


}
.rise-icon {
    color: #28c76f;
    font-size: 15px;
    padding-left: 5px;
}

.up-title{
    color: #28c76f;
    font-weight: 500;
}

.down-title{

    color: #ea5455;
    font-weight: 500;
    padding-left: 5px;

}

.info-icon-container{
    display: flex;
    justify-content: flex-end;
}

.fall-icon{
    color: #ea5455;
    font-size: 25px;
}
.info-icon{
    font-size: 25px !important;
}



 

@media screen and (max-width: 765px) {
  .statistics-container {
    margin-top: 10px;
  }
}
*/

</style>