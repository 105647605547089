import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";
// import store from '@/store';

export const warehouseService = {
    getAll,
    searchWithoutPagination,
    store,
    update,
    delete: _delete,
    getAllWithPagination,

    storeStockItem,
    getStockItems,
    getStockItemsWithoutPagination,
    showStockItem,
    deleteStockItem,
    updateStockItem,
    filterStockItems,
    filterStockItemsName,
    transfer,
    getStockItemsWithoutFilter,
    exportStockOverView


};

function exportStockOverView(export_type : string)  {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.stock.export}?export_type=${export_type}`, requestOptions)
        .then(
            handleResponse2
        );
}

function getAll() {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.warehouse.getAll}?pagination=off`, requestOptions).then(handleResponse);
}


function searchWithoutPagination(key : string , value:string ) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.warehouse.getAll}?key=${key}&value=${value}`, requestOptions).then(handleResponse);
}



function getAllWithPagination(currentPage: number, perPage: number) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.warehouse.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions).then(handleResponse);
}

function store(payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.warehouse.store}`, requestOptions).then(handleResponse);
}

function update(id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.warehouse.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function _delete(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.warehouse.delete.replace(':id', id)}`, requestOptions).then(handleResponse);
}

//stock 
function storeStockItem(payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.stock.create}`, requestOptions).then(handleResponse);
}


function getStockItems(keys:string,value:string,currentPage: number, perPage: number) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.stock.getAll}?keys=${keys}&values=${value}&per_page=${perPage}&page=${currentPage}`, requestOptions).then(handleResponse);
}

function getStockItemsWithoutFilter(stockable_name:string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.stock.getAll}?pagination=off&stockable_name=${stockable_name}`, requestOptions).then(handleResponse);

}

function getStockItemsWithoutPagination(keys:string,value:string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.stock.getAll}?keys=${keys}&values=${value}&pagination=off`, requestOptions).then(handleResponse);
}


function showStockItem(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.stock.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function updateStockItem(id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.stock.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function deleteStockItem(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.stock.delete.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function filterStockItems(key:string,value:string, page:string, perPage:string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.stock.getAll}?keys=${key}&values=${value}&page=${page}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function filterStockItemsName(key:string,value:string, name:string, page:any, perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.stock.getAll}?keys=${key}&values=${value}&stockable_name=${name}&page=${page}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function transfer(id:string, payload:object){

    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.stock.transfer.replace(':id', id)}`, requestOptions).then(handleResponse);

}







function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponse2(response: Response) {
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.blob(); // Change to response.blob() to get blob data
}
