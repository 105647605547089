import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const salesService = {
    getAll,
    getAllWithPerPage,
    show,
    exportFile

};

function getAll(currentPage : any , perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.sales.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions)
        .then(
            handleResponse
        );
}


function getAllWithPerPage(currentPage : any , perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.sales.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions)
        .then(
            handleResponse
        );
}


function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.sales.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}


function exportFile(from : string , to :string , orderBy:string , order:string , export_type:string , user:any , device : any)  {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.sales.exportFile}?from=${from}&to=${to}&orderBy=${orderBy}&order=${order}&export_type=${export_type}&device_id=${device}&user_id=${user}`, requestOptions)
        .then(
            handleResponse2
        );
}



function handleResponse2(response: Response) {
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.blob(); // Change to response.blob() to get blob data
}


function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
