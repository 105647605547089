<template>

    <div >
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>


    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{t('Pos.Dashboard')}}
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{t('Pos.Manufacturer')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

   <div class="btn-container">
        <a-button class="add-btn"  @click="showAddDrawer">
           {{ t('Pos.Add') }}
           
            <template #icon>
            <InboxOutlined />
            </template>
        </a-button>
    </div>
    <div>
        <a-table
            :columns="columns"
            :data-source="state.data"
            :pagination="false" 
            class="table-container" 
        >

            <template #emptyText>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
            </template>

            <template #bodyCell="{ column, record }">
                <template v-if="column.key=='actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>{{ t('Pos.View') }}</template>
                            <a-button class="view-btn"  @click="() => showDisplayDrawer(record)">
                            <template #icon>
                                <EyeOutlined/>
                            </template>
                            </a-button>
                        </a-tooltip>
                        
                            <a-tooltip>
                    <template #title>{{t('Pos.Manufacturer')}}{{ t('Pos.Edit') }}</template>

                    <a-button type="primary" @click="() => showDrawer(record)">
                    <template #icon>
                        <EditOutlined />
                    </template>
                    </a-button>

                </a-tooltip>

                        <a-tooltip>
                            <template #title>{{ t('Pos.Delete') }}</template>
                            <a-button type="primary" danger @click="showDeletePopup(record)">
                            <template #icon>
                                <DeleteOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>

                <template v-if="column.key === 'created_at' || column.key === 'updated_at'">
                    {{ formatDisplayDate(record[column.key]) }}
                </template>

                
            </template>

            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                <a-input
                    ref="searchInput"
                    :placeholder="`${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                /> 

                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                
                    {{ t('Pos.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Pos.Reset') }}
                </a-button>
                </div>
            </template>

            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>

        </a-table>
        <div class="pagination">
            <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />

            <a-select
                v-model:value="selectPagination"
                @change="handleSelectPaginationChange"
                v-if="state.totalPages>10"
            >
                <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
            </a-select>
        </div>

        <!-- update drawer -->
            <a-drawer
                v-model:open="open"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width="modalWidth"
                @after-open-change="afterOpenChange"
            >
                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}"> {{t('Pos.UpdateManufacturer')}} </p>
                </template>
                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
                    <a-row :gutter="16">   
                        <a-col :span="24">
                        <a-form-item  name="name">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Name') }}</span>
                            </template>
                            <a-input  v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form"/>
                        </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="24">
                        <a-form-item  name="contact_info">
                            <template #label>
                            <span class="label-form">{{ t('Pos.ContactInfo') }}</span>
                            </template>
                            <a-input  v-model:value="form.contact_info" :placeholder="t('Pos.ContactInfo')" class="input-form"/>
                        </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="24">
                            <a-form-item  name="created_at">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.CreatedAt') }} </span>
                                </template>
                                <a-date-picker show-time :placeholder="t('Pos.CreatedAt')" class="date-picker" disabled :value="formatDate(form.created_at)">
                                </a-date-picker>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
                <template #footer>
                    <a-space :class="{ 'is-rtl': textDirection==='rtl'}">
                        <a-button  type="primary" @click="updateManufacturer(form.id)">{{ t('Pos.Update') }}</a-button>
                        <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                </template>

            </a-drawer>
        <!-- update drawer -->

        <!-- Detail drawer-->

            <a-drawer
                v-model:open="openDisplay"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                @after-open-change="afterOpenShowChange"
            >
                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.ManufacturerInfo')}}</p>
                </template>
                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
                
                    <a-row >

                        <a-col :span="24">
                            <p><span class="info-title">{{ t('Pos.Name') }} :</span> {{form.name}}</p>
                        </a-col>

                       

                    </a-row>

                    <a-row>

                        <a-col :span="24">
                            <p><span class="info-title">{{ t('Pos.ContactInfo') }} :</span> {{form.contact_info}}</p>
                        </a-col>

                    </a-row>

                    <a-row>

                        <a-col :span="24">
                            <p><span class="info-title">{{ t('Pos.CreatedAt') }} :</span> {{formatDateDrawer(form.created_at)}}</p>
                        </a-col>

                    </a-row>

                </a-form>

                <template #footer>
                    <a-space :class="{ 'is-rtl': textDirection==='rtl'}">
                        <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                </template>

            </a-drawer>

        <!-- add drawer -->
            <a-drawer
                v-model:open="openAdd"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                @close="resetFormAdd"
            >   
                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddManufacturer')}}</p>
                </template>

                <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
                    <a-row :gutter="16">   
                        <a-col :span="24">
                        <a-form-item  name="name">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Name') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.name" :placeholder="t('Pos.Name')" class="input-form"/>
                        </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="24">
                        <a-form-item  name="contact_info">
                            <template #label>
                            <span class="label-form">{{ t('Pos.ContactInfo') }}</span>
                            </template>
                            <a-input  v-model:value="addForm.contact_info" :placeholder="t('Pos.ContactInfo')" class="input-form"/>
                        </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
                <template #footer>
                <a-space>
                    <a-button  type="primary" @click="addManufacturer">{{ t('Pos.Add') }}</a-button>
                    <a-button type="primary" danger @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
                </a-space>
                </template>


            </a-drawer>
        <!-- add drawer -->
       

    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined,
  InboxOutlined
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import {manufacturerService} from '../../_services/manufacturer.service'
import dayjs from 'dayjs'; 
import relativeTime from 'dayjs/plugin/relativeTime';

import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import 'dayjs/locale/fr'; 
import 'dayjs/locale/ar'; 
import { previewProps } from 'ant-design-vue/es/vc-image/src/Preview';
import { Empty } from 'ant-design-vue';
export default defineComponent({
    name: 'indexManufacturer',
    components: {
        EyeOutlined,
        DeleteOutlined,
        SearchOutlined,
        EditOutlined,
        InboxOutlined,
        LbrxLoaderSpinner
    },
    setup(){
        const modalWidth = ref(720);
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const { t } = useI18n();
        dayjs.extend(relativeTime);

        const loading = ref(false);
        const router = useRouter();
        const navigatePage = (link: string) => {
        router.push(link); 
        };

        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
            searchName:'',

        });
        const searchInput = ref<HTMLInputElement | null>(null);
        const searchContact = ref<HTMLInputElement | null>(null);

        const columns=[
            {
                title:t('Pos.Name'),
                key:'name',
                dataIndex: 'name',
                customFilterDropdown: true,
                onFilter: (value:string, record:any) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                        if (input instanceof HTMLInputElement) {
                            searchInput.value = input;
                            searchInput.value.focus();
                        }
                        }, 100);
                    }
                },
            },
            {
                title:t('Pos.ContactInfo'),
                key:'contact_info',
                dataIndex: 'contact_info',
                // customFilterDropdown: true,
                // onFilterDropdownOpenChange:  (visible: boolean) => {
                //     if (visible  && !searchContact.value) {
                //         setTimeout(() => {
                //         const input = document.querySelector('.ant-table-filter-dropdown input');

                //         if (input instanceof HTMLInputElement) {
                //             searchContact.value = input;
                //             searchContact.value.focus();
                //         }
                //         }, 100);
                //     }
                // },
            },
            {
                title:t('Pos.CreatedAt'),
                key:'created_at',
                dataIndex: 'created_at',

            },
            
            {
                title: t('Pos.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }

        ];
        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');

        const handleSelectPaginationChange=(selectedItem:any)=>{
            
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchName!==''){
                    loading.value = true;
                    manufacturerService.filter("name",state.searchName,currentPage.value,perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

            }else{

                loading.value=true;
                manufacturerService.getAllWithPagination(currentPage.value, perPage.value).then((res)=>{
                    state.data=res.data;
                    state.totalPages=res.meta.total;
                }).catch((err:any) => {
                    console.log(err)
                }).finally(()=>{
                    loading.value = false;
                });

            }

            
        }





        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);
            if(state.beginSearch===true){
                state.data=[];
                loading.value = true;

                if(state.searchName!==''){
                    manufacturerService.filter('name',state.searchName, currentPage.value, perPage.value).then((res)=>{
                        state.data=res.data;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{
                loading.value = true;

                manufacturerService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                }).catch((error)=>{
                    console.log("error api : get products", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

            
        }
                

        //form  
        const formRef = ref();
        const addFormRef = ref();


       
        const form = reactive({
            id: '',
            name: '',
            contact_info: '',
            created_at: '',
            updated_at: '',
        });

        const addForm = reactive({
            id:'',
            name: '',
            contact_info: '',
            created_at: '',
            updated_at: '',
        });

        const resetForm = () => {
            form.id = '';
            form.name = '';
            form.contact_info = '';
            form.created_at = '';
            form.updated_at = '';
        }

        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t('Pos.RequiredField') }],
            contact_info: [{ required: true, message: t('Pos.RequiredField') }]
        };

        const formatDate = (dateString: string) => {
            return dayjs(dateString);
        }

        const lang=localStorage.getItem('Language')|| 'en';

        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).locale(lang).fromNow();
        }

        
        const openDisplay = ref<boolean>(false);
        const afterOpenShowChange = (bool: boolean) => {
         console.log('open', bool);
        
        };



        const filter=(key:string,value:string)=>{
            loading.value=true;
            currentPage.value=1;    
            manufacturerService.filter(key,value,currentPage.value,perPage.value)
            .then((res) => {
                state.data=res.data;
                state.totalPages = res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err)
            });

        }

        //search
        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'name' && state.searchText) {
                console.log('begin filter');
                state.searchName=state.searchText;
                filter(dataIndex,state.searchText);
            }
        
        };

        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.beginSearch=false;
            currentPage.value=1;
            perPage.value=10;
            state.searchName='';
            getManufacturers();
        };


   

       
        
        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };



        //drawer

        const open = ref<boolean>(false);

        const showAddDrawer = () => {
            updateModalWidth();
            openAdd.value = true;
            resetForm();
        };

        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showDisplayDrawer = (record: any) => {
            updateModalWidth();
            openDisplay.value = true;
            getManufacturerById(record.id);
            resetForm();
        };

        
        const showDrawer = (record: any) => {
            updateModalWidth();
            open.value = true;
            getManufacturerById(record.id);
        };

        const openAdd = ref<boolean>(false);

        const afterOpenAddChange = (bool: boolean) => {
            console.log('open', bool);
        };

       

        const resetFormAdd = () => {
            console.log("begin rest");
            addFormRef.value.resetFields();
        };

        const cancelUpdate=()=>{
            form.name="";
            form.contact_info="";
            open.value=false;
        }

        

        const getManufacturers=()=>{
            loading.value = true;

            manufacturerService.getAllWithPagination(currentPage.value,perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages = response.meta.total;

            }).catch((error)=>{
                console.log("error get manufacturers", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const addManufacturer =()=>{
            addFormRef.value
            .validate()
            .then(()=>{
                loading.value = true;
                let payload={
                    name:addForm.name,
                    contact_info:addForm.contact_info,
                }
                manufacturerService.create(payload)
                .then((response)=>{
                    showSuccessNotification(t('Pos.AddManufacturerSuccess'));
                    //state.data.push(response.data);

                    getManufacturers();
                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    loading.value = false;
                    openAdd.value=false;
                    resetFormAdd();
                    
                }); 
            }).catch((error:any) => {
                console.error('Validation error:', error);
            });
        }

        const getManufacturerById = (id: string) => {
            manufacturerService.show(id)
                .then(res => {
                    form.name = res.data.name;
                    form.contact_info = res.data.contact_info;
                    form.created_at = res.data.created_at;
                    form.updated_at = res.data.updated_at;
                    form.id = res.data.id;
                })
                .catch(error => {
                    console.error('Error fetching categories:', error);
                });
        }


        const updateManufacturer =(id:string)=>{
         
            formRef.value
            .validate()
            .then(()=>{
                
                loading.value = true;

                let payload={
                    name:form.name,
                    contact_info:form.contact_info,
                }

                manufacturerService.update(id, payload).then((response)=>{
                    
                    state.data = state.data.map(el => el.id !== id ? el : response.data);
                    showSuccessNotification(t('Pos.UpdateManufacturerSuccess'));

                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    loading.value = false;
                    open.value=false;
                }); 

            })
        }

        const deleteManufacturer = (id: string) => {
            manufacturerService.delete(id).then(() => {
                //state.data = state.data.filter(e => e.id !== id);

                getManufacturers();
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                showSuccessNotification(t('Pos.DeleteManufacturerSuccess'));
                console.log('finish')
            })
        };

        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteManufacturer'),
                text: t('Pos.TextDeleteManufacturer'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t('Pos.Delete'),
                cancelButtonText: t('Pos.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteManufacturer(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const formatDateDrawer=(dateString:string)=>{
            return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
        }
        const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };

        onMounted(() => {
            getManufacturers();
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
            searchContact.value = document.querySelector('.ant-table-filter-dropdown input');
        });
        
        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );


        return {
            updateModalWidth,
            modalWidth,
            t,
            textDirection,
            openDisplay,
            formatDate, 
            formatDateDrawer,
            showDisplayDrawer,
            afterOpenShowChange,
            navigatePage,
            columns,
            state,
            rules,
            formRef,
            form,
            addFormRef,
            addForm,
            handleSearch,
            handleReset,
            formatDisplayDate,
            openAdd,
            afterOpenAddChange,
            showAddDrawer,
            open,
            afterOpenChange,
            showDrawer,
            resetFormAdd,
            addManufacturer,
            cancelUpdate,
            updateManufacturer,
            deleteManufacturer,
            showDeletePopup,
            perPage,
            currentPage,
            handlePageChange,
            loading,
            handleSelectPaginationChange,
            selectPagination,
            simpleImage,
            locale,


        } 
    }

})
</script>

<style scoped>
.add-btn {
    color: #fc8019;
    border: 2px solid #fc8019;
    box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}

.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.date-picker {
    width: 100%
}

.breadcrumb-icon{
  cursor: pointer;
}
.input-form{
    color: black;
    background-color: #fff;
}
.view-btn{
    color: #fc8019;
    border-color: #fc8019;
    background: #fafafa;
}

.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
}

.pagination{
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-btn:hover{
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
}


  /* CSS for RTL */
.is-rtl {
    direction:rtl;
}


.paginationArrows {
    direction:ltr;
}

.table-container {
  overflow-x: auto;
}

/* pagintaion */
@media (max-width: 768px) {
    .pagination {
        flex-direction: column;
        align-items: flex-start;
    }

    .select-input {
        width: 100%;
        margin-top: 10px;
    }
}


</style>