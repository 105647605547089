import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderList as _renderList, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e784895"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = {
  key: 0,
  class: "btn-container"
}
const _hoisted_3 = { class: "label-form" }
const _hoisted_4 = { class: "label-form" }
const _hoisted_5 = { class: "label-form" }
const _hoisted_6 = { class: "info-title" }
const _hoisted_7 = { class: "info-title" }
const _hoisted_8 = { class: "info-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigatePage('/attributes'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Attributes')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.AttributeValue')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAddDrawer(true)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        class: "table-container",
        columns: _ctx.columns,
        "data-source": _ctx.reactiveData.data,
        pagination: false
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.key == 'actions')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_a_space, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tooltip, null, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          class: "view-btn",
                          onClick: ($event: any) => (_ctx.showDisplayDrawer(record))
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_EyeOutlined)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_tooltip, null, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: ($event: any) => (_ctx.showDrawer(record))
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_EditOutlined)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_tooltip, null, {
                      title: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          danger: "",
                          onClick: ($event: any) => (_ctx.showDeletePopup(record))
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_DeleteOutlined)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]))
            : _createCommentVNode("", true),
          (column.key === 'created_at' || column.key === 'updated_at')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatDisplayDate(record[column.key])), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: _ctx.reactiveData.action == 'add' ? _ctx.t('Pos.AddAttributeValue') : _ctx.t('Pos.UpdateAttributeValue'),
        placement: "right",
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px' },
        onAfterOpenChange: _ctx.afterOpenChange,
        onClose: _ctx.hideForm
      }, {
        footer: _withCtx(() => [
          (_ctx.reactiveData.action == 'update')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onSubmitUpdate(_ctx.form.id)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_button, {
                    type: "primary",
                    danger: "",
                    onClick: _ctx.hideForm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_a_space, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _ctx.onSubmitAdd
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_a_button, {
                    type: "primary",
                    danger: "",
                    onClick: _ctx.hideForm
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.name,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "value" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('Pos.Value')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.value,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.value) = $event)),
                            placeholder: _ctx.t('Pos.Value'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "type" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('Pos.Type')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            placeholder: _ctx.t('Pos.Type'),
                            value: _ctx.form.type,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.type) = $event))
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.name
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["placeholder", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _: 1
      }, 8, ["open", "title", "width", "onAfterOpenChange", "onClose"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        title: _ctx.t('Pos.PurchaseinvoiceInfo'),
        placement: "right",
        width: _ctx.modalWidth
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Pos.Name')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.name), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.Value')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.value), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.Type')) + " :", 1),
                        _createTextVNode(_toDisplayString(_ctx.form.type), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _: 1
      }, 8, ["open", "title", "width"])
    ])
  ], 64))
}