import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const stockAdjustmentService = {
    add,

};






function add(payload:object ){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.stockAdjustment.create}`, requestOptions)
        .then(
            handleResponse
        );
}



function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
