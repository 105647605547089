import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb86e898"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = { class: "label-form" }
const _hoisted_4 = { class: "label-form" }
const _hoisted_5 = { class: "label-form" }
const _hoisted_6 = { class: "label-form" }
const _hoisted_7 = { class: "label-form" }
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "label-form" }
const _hoisted_12 = { class: "label-form" }
const _hoisted_13 = { class: "label-form" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Dashboard")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Invoice")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.deliveryNote")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _ctx.showAddDrawer
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("Pos.Add")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_table, {
      columns: _ctx.columns,
      "data-source": _ctx.state.data,
      pagination: false
    }, {
      customFilterDropdown: _withCtx(({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }) => [
        _createElementVNode("div", _hoisted_2, [
          (column.key === 'number')
            ? (_openBlock(), _createBlock(_component_a_input, {
                key: 0,
                ref: "searchInput",
                placeholder: `Search ${column.dataIndex}`,
                value: selectedKeys[0],
                style: {"width":"188px","margin-bottom":"8px","display":"block"},
                onChange: 
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            ,
                onPressEnter: ($event: any) => (
              _ctx.handleSearch(selectedKeys, confirm, column.dataIndex)
            )
              }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]))
            : _createCommentVNode("", true),
          _createVNode(_component_a_button, {
            type: "primary",
            size: "small",
            style: {"width":"90px","margin-right":"8px"},
            onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Search")), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_a_button, {
            size: "small",
            style: {"width":"90px"},
            onClick: ($event: any) => (_ctx.handleReset(clearFilters))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Pos.Reset")), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      customFilterIcon: _withCtx(({ filtered }) => [
        _createVNode(_component_search_outlined, {
          style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
        }, null, 8, ["style"])
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createVNode(_component_a_drawer, {
      open: _ctx.openAdd,
      "onUpdate:open": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.openAdd) = $event)),
      class: "custom-class",
      "root-class-name": "root-class-name",
      placement: _ctx.locale === 'ar' ? 'left' : 'right',
      width: _ctx.modalWidth,
      onClose: _ctx.resetFormAdd
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", {
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
        }, _toDisplayString(_ctx.t("Pos.AddDelivaryNote")), 3)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.add
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pos.Add")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              type: "primary",
              danger: "",
              onClick: _ctx.resetFormAdd
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pos.Cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "addFormRef",
          model: _ctx.addForm,
          rules: _ctx.rules,
          layout: "vertical",
          class: _normalizeClass({ 'is-rtl': _ctx.textDirection === 'rtl' })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "noteType" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t("Pos.Type")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: _ctx.addForm.noteType,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addForm.noteType) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select_option, { value: "manual" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("Pos.manual")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_select_option, { value: "sales" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("Pos.fromSales")), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { gutter: 16 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "carNumber" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t("Pos.carNumber")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.carNumber,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addForm.carNumber) = $event)),
                          placeholder: _ctx.t('Pos.carNumber'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "driverName" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t("Pos.driverName")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.addForm.driverName,
                          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addForm.driverName) = $event)),
                          placeholder: _ctx.t('Pos.driverName'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "details" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t("Pos.details")), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          value: _ctx.addForm.detailes,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addForm.detailes) = $event)),
                          rows: 3,
                          placeholder: _ctx.t('Pos.details'),
                          class: "input-form"
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, { name: "date" }, {
                      label: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.delivaryDate')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_date_picker, {
                          class: "date-input",
                          format: "YYYY-MM-DD HH:mm",
                          value: _ctx.dateNote,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dateNote) = $event)),
                          "show-time": "",
                          placeholder: _ctx.t('Pos.SelectDelivaryDate')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.addForm.noteType==='manual')
              ? (_openBlock(), _createBlock(_component_a_row, {
                  key: 0,
                  gutter: 16
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "type" }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t("Pos.Type")), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.addForm.type,
                              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.addForm.type) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select_option, { value: "external" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t("Pos.external")), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_select_option, { value: "internal" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t("Pos.internal")), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.addForm.type==='internal' && _ctx.addForm.noteType==='manual')
              ? (_openBlock(), _createBlock(_component_a_row, {
                  key: 1,
                  gutter: 16
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "sender_stock" }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.From')) + " " + _toDisplayString(_ctx.t('Pos.Store')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.addForm.sender_store,
                              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.addForm.sender_store) = $event)),
                              "show-search": "",
                              "option-filter-prop": 'label',
                              onSelect: _ctx.selectedSender,
                              class: "store-select"
                            }, {
                              notFoundContent: _withCtx(() => [
                                _createVNode(_component_a_empty, {
                                  description: _ctx.t('Pos.NoData'),
                                  image: _ctx.simpleImage
                                }, null, 8, ["description", "image"])
                              ]),
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.targertStore, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    label: item.name,
                                    key: item.id,
                                    value: item.id,
                                    selected: item.id === _ctx.addForm.sender_store 
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["label", "value", "selected"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "onSelect"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "reciever_stock" }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Pos.To')) + " " + _toDisplayString(_ctx.t('Pos.Store')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.addForm.reciever_store,
                              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.addForm.reciever_store) = $event)),
                              "show-search": "",
                              "option-filter-prop": 'label',
                              disabled: !_ctx.addForm.sender_store,
                              class: "store-select"
                            }, {
                              notFoundContent: _withCtx(() => [
                                _createVNode(_component_a_empty, {
                                  description: _ctx.t('Pos.NoData'),
                                  image: _ctx.simpleImage
                                }, null, 8, ["description", "image"])
                              ]),
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.stores, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    label: item.name,
                                    key: item.id,
                                    value: item.id,
                                    selected: item.id === _ctx.addForm.reciever_store 
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["label", "value", "selected"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.addForm.type==='external' && _ctx.addForm.noteType==='manual')
              ? (_openBlock(), _createBlock(_component_a_row, {
                  key: 2,
                  gutter: 16
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "sender_stock" }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Pos.From')) + " " + _toDisplayString(_ctx.t('Pos.Client')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.state.sender_client,
                              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.sender_client) = $event)),
                              "show-search": "",
                              "default-active-first-option": false,
                              "filter-option": false,
                              onSearch: _ctx.fetchClient,
                              options: _ctx.state.targertClient,
                              onSelect: _ctx.selectedSenderClient,
                              class: "store-select"
                            }, _createSlots({ _: 2 }, [
                              (_ctx.state.fetchingTargetCustomer)
                                ? {
                                    name: "notFoundContent",
                                    fn: _withCtx(() => [
                                      _createVNode(_component_a_spin, { size: "small" })
                                    ]),
                                    key: "0"
                                  }
                                : undefined
                            ]), 1032, ["value", "onSearch", "options", "onSelect"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { name: "reciever_stock" }, {
                          label: _withCtx(() => [
                            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('Pos.To')) + " " + _toDisplayString(_ctx.t('Pos.Client')), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.state.reciever_client,
                              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.reciever_client) = $event)),
                              "show-search": "",
                              "default-active-first-option": false,
                              "filter-option": false,
                              onSearch: _ctx.fetchReceiverClient,
                              options: _ctx.state.clients,
                              disabled: !_ctx.state.sender_client,
                              class: "store-select"
                            }, _createSlots({ _: 2 }, [
                              (_ctx.state.fetchingReceiverCustomer)
                                ? {
                                    name: "notFoundContent",
                                    fn: _withCtx(() => [
                                      _createVNode(_component_a_spin, { size: "small" })
                                    ]),
                                    key: "0"
                                  }
                                : undefined
                            ]), 1032, ["value", "onSearch", "options", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.addForm.noteType==='manual')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createVNode(_component_a_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "products" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t("Pos.Products")), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _ctx.state.value,
                                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.state.value) = $event)),
                                mode: "multiple",
                                "label-in-value": "",
                                placeholder: _ctx.t('Pos.Products'),
                                style: {"width":"100%"},
                                "filter-option": false,
                                "not-found-content": _ctx.state.fetching ? undefined : null,
                                options: _ctx.state.products,
                                onSearch: _ctx.fetchProducts,
                                onChange: _ctx.handleProductSelect
                              }, _createSlots({ _: 2 }, [
                                (_ctx.state.fetching)
                                  ? {
                                      name: "notFoundContent",
                                      fn: _withCtx(() => [
                                        _createVNode(_component_a_spin, { size: "small" })
                                      ]),
                                      key: "0"
                                    }
                                  : undefined
                              ]), 1032, ["value", "placeholder", "not-found-content", "options", "onSearch", "onChange"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.productsDelivary, (item) => {
                    return (_openBlock(), _createBlock(_component_a_row, {
                      gutter: 16,
                      key: item
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, {
                          xs: { span: 24 },
                          sm: { span: 24 },
                          md: { span: 12 },
                          lg: { span: 12 },
                          xl: { span: 12 },
                          xxl: { span: 12 },
                          class: "ingr-name-container"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_14, _toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (item.option.variants.length === 0)
                          ? (_openBlock(), _createBlock(_component_a_col, {
                              key: 0,
                              xs: { span: 24 },
                              sm: { span: 24 },
                              md: { span: 12 },
                              lg: { span: 12 },
                              xl: { span: 12 },
                              xxl: { span: 12 },
                              class: "ingr-content-container"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  "has-feedback": "",
                                  name: 'qt_' + item.value,
                                  "validate-status": _ctx.getValidatingStatus(item.value, 'qt'),
                                  help: _ctx.getValidatingHelp(item.value, 'qt')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      id: 'qt_' + item.value,
                                      placeholder: _ctx.t('Pos.Quantity'),
                                      value: item.quantity,
                                      "onUpdate:value": ($event: any) => ((item.quantity) = $event),
                                      class: "input-form"
                                    }, null, 8, ["id", "placeholder", "value", "onUpdate:value"])
                                  ]),
                                  _: 2
                                }, 1032, ["name", "validate-status", "help"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        (item.option.variants.length !== 0)
                          ? (_openBlock(), _createBlock(_component_a_col, {
                              key: 1,
                              xs: { span: 24 },
                              sm: { span: 24 },
                              md: { span: 12 },
                              lg: { span: 12 },
                              xl: { span: 12 },
                              xxl: { span: 12 },
                              class: "ingr-content-container"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  "validate-status": _ctx.getValidatingStatus(item.item_id, 'unit'),
                                  help: _ctx.getValidatingHelp(item.item_id, 'unit')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_select, {
                                      id: 'unit_' + item.value,
                                      mode: "multiple",
                                      placeholder: _ctx.t('Pos.Variants'),
                                      "show-search": "",
                                      "option-filter-prop": 'label',
                                      onChange: (value) =>_ctx.handleSelectVarinats(item, value)
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.option.variants, (variant) => {
                                          return (_openBlock(), _createBlock(_component_a_select_option, {
                                            key: variant.id,
                                            label: variant.name,
                                            value: variant.id
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(variant.name), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["label", "value"]))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1032, ["id", "placeholder", "onChange"])
                                  ]),
                                  _: 2
                                }, 1032, ["validate-status", "help"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.variantsValues, (variant) => {
                    return (_openBlock(), _createBlock(_component_a_row, {
                      key: variant.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, {
                          xs: { span: 24},
                          sm: { span: 24},
                          md: {span: 24},
                          lg: { span: 24 },
                          xl: { span: 24 },
                          xxl: { span: 24 },
                          class: "ingr-name-container"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_15, _toDisplayString(variant.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_col, {
                          xs: { span: 24},
                          sm: { span: 24},
                          md: {span: 6},
                          lg: { span: 6 },
                          xl: { span: 6 },
                          xxl: { span: 6 },
                          class: "ingr-content-container"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              "has-feedback": "",
                              name: 'qt_' + variant.id,
                              "validate-status": _ctx.getValidatingStatus(variant.id,'qt'),
                              help: _ctx.getValidatingHelp(variant.id,'qt')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  id: 'qt_' + variant.id,
                                  placeholder: _ctx.t('Pos.Quantity'),
                                  value: variant.quantity,
                                  "onUpdate:value": ($event: any) => ((variant.quantity) = $event),
                                  class: "input-form"
                                }, null, 8, ["id", "placeholder", "value", "onUpdate:value"])
                              ]),
                              _: 2
                            }, 1032, ["name", "validate-status", "help"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["model", "rules", "class"])
      ]),
      _: 1
    }, 8, ["open", "placement", "width", "onClose"])
  ], 64))
}