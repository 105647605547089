<template>
    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{ t("Pos.Dashboard") }}
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{ t("Pos.Menu") }}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <a-row :gutter="16">

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 16}" :lg="{ span: 16 }" :xl="{ span: 16 }" :xxl="{ span: 16 }" class="search-container">
            <a-input  class="search-input" :placeholder="t('Pos.searchDish')">
                <template #prefix>
                    <SearchOutlined class="search-icon" />
                </template>
            </a-input>
                
        </a-col>


        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 8}" :lg="{ span: 8 }" :xl="{ span: 8 }" :xxl="{ span: 8 }" 
            class="add-btn-container"
        >
            <a-button class="add-dish-btn" @click="openAddMenu">Add Category</a-button>
        </a-col>
    </a-row>

    <a-row class="collapse-container">
        <a-col :span="24">
            <a-collapse  v-model:activeKey="activeKey" :bordered="false" class="collapse-container">
               
                <template #expandIcon="{ isActive }">
                    <caret-right-outlined :rotate="isActive ? 90 : 0" />
                </template>

                <a-collapse-panel   class="menu-collapse" >

                    <template #header>
                        <div class="custom-header-container">
                            <span class="menu-title">{{menu.name}}</span>

                            <div class="location-container">
                                
                                <a-tag color="green">
                                    <EnvironmentOutlined />
                                    {{menu.locationName}}
                                </a-tag>

                                <div>
                                    <DeleteOutlined class="delete-icon" @click="showDeletePopup" />
                                </div>

                                
                            </div>

                            
                        </div>
                    </template>

                    <a-row class="cat-container">
                        <a-col :xs="{ span: 24}" :sm="{ span: 24}" :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="categories-wrapper">
                            <div v-for="cat in menu.categories" :key="cat.id" class="category-item" @click="toggleCategory(cat.menu_category_id, cat.name)">
                                <span>
                                    
                                    {{ cat.name }}
                                </span>

                            </div>
                        </a-col>
                    </a-row>


                    <a-row class="new-dish-container" v-for="item in menu.categories" :key="item">
                        <a-col  :xs="{ span: 24}" :sm="{ span: 24}" :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" >
                            
                            <a-row>
                                <p class="cat-name-menu">{{ item.name }}</p>
                            </a-row>

                            <a-row>
                                <button class="new-dish-btn" @click="openAddDish(item.menu_category_id)">
                                    <PlusCircleOutlined class="plus-icon" />
                                    <span>{{ t("Pos.AddNewDish") }}</span>
                                </button>

                                <div class="produc-cat">
                                    <div v-for="product in item.products" :key="product.id" class="product-card">
                                        <span>{{product.name}}</span>

                                        <img :src="product.photo"  />
                                    </div>
                                </div>
                            </a-row>

                        
                            


                        </a-col>
                    </a-row>

                    <!-- <a-row class="new-dish-container" v-for="item in state.selectedCat" :key="item">
                        <a-col  :xs="{ span: 24}" :sm="{ span: 24}" :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" >
                            
                            <a-row>
                                <p class="cat-name-menu">{{item.category}}</p>
                            </a-row>

                            <a-row>
                                <button class="new-dish-btn" @click="openAddDish(item.id)">
                                    <PlusCircleOutlined class="plus-icon" />
                                    <span>{{ t("Pos.AddNewDish") }}</span>
                                </button>
                            </a-row>

                        
                            


                        </a-col>
                    </a-row> -->







                    
                </a-collapse-panel>
            </a-collapse>
        </a-col>
    </a-row>

  

   

    <!-- <a-row>

         <a-col :xs="{ span: 24}" :sm="{ span: 24}" :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="menu-item-container">
            
       
             <a-upload
                v-model:file-list="fileList"
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="true"
                :before-upload="beforeUpload"
                @change="handleChange"
            >
                <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                <div v-else>
                    <loading-outlined v-if="loading"></loading-outlined>
                    <PlusCircleOutlined v-else class="upload-icon"></PlusCircleOutlined >
                    <div class="ant-upload-text">{{ t("Pos.Upload") }}</div>
                </div>
            </a-upload> 

        </a-col>
    </a-row> -->


    <!-- add drawer -->
    <a-drawer 
        v-model:open="openAdd"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
        @close="resetFormAdd"
    >

        <template #title>
            <p :class="{ 'is-rtl': textDirection === 'rtl' }">
                Add menu
            </p>
        </template>

        <a-form
            ref="addFormRef"
            :model="addForm"
            :rules="rules"
            layout="vertical"
            :class="{ 'is-rtl': textDirection === 'rtl' }"
        >

            <a-row>
                <a-col :span="24">
                    <a-form-item name="name">
                        <template #label>
                            <span class="label-form">{{t('Pos.Name')}}</span>
                        </template>
                        <a-input
                            v-model:value="addForm.name"
                            :placeholder="t('Pos.Name')"
                            class="input-form"
                        />
                    </a-form-item>
                </a-col>
            </a-row>


            <a-row>

                <a-col :span="24">
                    <a-form-item name="location_id">
                        <template #label>
                            <span class="label-form">Location</span>
                        </template>

                        <a-select
                            v-model:value="addForm.location_id"
                            show-search
                            :option-filter-prop="'label'"
                        >

                            <template #notFoundContent>
                                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                            </template>

                            <a-select-option
                                v-for="location in state.locations"
                                :label="location.name"
                                :key="location.id"
                                :value="location.id"
                                :selected="location.id === addForm.location_id"
                            >
                                {{ location.name }}
                            </a-select-option>

                        </a-select>
                    </a-form-item>
                </a-col>

            </a-row>

            <a-row>
                <a-col :span="24" >
                    <a-form-item name="categories">
                        <template #label>
                            <span class="label-form">Categories</span>
                        </template>

                        <a-select
                            v-model:value="state.categoryValue"
                            mode="multiple"
                            label-in-value
                            placeholder="Fetch category"
                            style="width: 100%"
                            :filter-option="false"
                            :not-found-content="state.fetchingCat ? undefined : null"
                            :options="state.catOptions"
                            @search="fetchCategory"
                            @change="handleCatSelect"
                        >
                            <template v-if="state.fetchingCat" #notFoundContent>
                                <a-spin size="small" />
                            </template>
                        </a-select>
                           


                    </a-form-item>
                </a-col>
            </a-row>

        </a-form>

        <template #footer>
            <a-space>
                <a-button type="primary" @click="add">{{ t("Pos.Add") }}</a-button>
                <a-button type="primary" danger @click="resetFormAdd">{{
                t("Pos.Cancel")
                }}</a-button>
            </a-space>
        </template>



    </a-drawer>

    <!-- add dish product -->
    <a-modal 
        v-model:open="addDish"
        @cancel="resetDishFields"
    >

        <template #title>
            Add a new dish
        </template>
        <template #footer>
            <a-button key="submit" type="primary"  @click="addDishProduct"> {{ t('Pos.Add') }}</a-button>
            <a-button key="back" @click="resetDishFields">{{ t('Pos.Cancel') }}</a-button>
        </template>

        <a-form
            ref="dishFormRef"
            :model="dishForm"
            :rules="dishRules"
            layout="vertical"
        >

            



            <a-row>
                <a-col :span="24">
                    <a-form-item name="product">

                        <template #label>
                            <p class="label-form">{{ t('Pos.Product') }}</p>
                        </template>

                        <a-select
                            v-model:value="state.product"
                            show-search 
                            :default-active-first-option="false"
                            :filter-option="false"
                            @search="fetchProduct"
                            :options="state.products"
                            @select="selecetProduct" 
                            class="store-select"
                        >
                            <template v-if="state.fetchProduct" #notFoundContent>
                                <a-spin size="small" />
                            </template>
                        </a-select>
                           

                    </a-form-item>
                </a-col>
            </a-row>

            <a-row>

                <a-col :span="24">
                    <a-form-item name="product_price" >
                        <template #label>
                            <span class="label-form">{{ t("Pos.Price") }}</span>
                        </template>

                        <a-input
                            :disabled="!state.product"
                            v-model:value="dishForm.product_price"
                            :placeholder="t('Pos.Price')"
                            class="input-form"
                        />
                    </a-form-item>
                </a-col>
            </a-row>

        </a-form>

    </a-modal>

</template>

<script  lang="ts">
import { useI18n } from "vue-i18n";
import { ref, defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import type { Rule } from "ant-design-vue/es/form";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import { notification, UploadChangeParam } from "ant-design-vue";
import Swal from "sweetalert2";
import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
 PlusCircleOutlined,
 CaretRightOutlined ,
EnvironmentOutlined,
MoreOutlined
} from "@ant-design/icons-vue";
import { Empty } from "ant-design-vue";
import { categoryService, menuService, productService, tableLocationService } from "@/_services";
import { er } from "@fullcalendar/core/internal-common";

export default defineComponent({
    name: "registerIndex",
    components: {
        LbrxLoaderSpinner,
        SearchOutlined,
        // CheckCircleTwoTone,
        // CloseCircleTwoTone,
        PlusCircleOutlined,
        CaretRightOutlined,
        EnvironmentOutlined,
        DeleteOutlined
      
        
    },
    setup() {

        const modalWidth = ref(520);
        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <= 750 ? window.innerWidth : 650;
        };

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === "ar" ? "rtl" : "ltr";
        });

        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

        const loading = ref(false);
        const { t } = useI18n();

        
        const router = useRouter();

        const navigatePage = (link: string) => {
            router.push(link);
        };

        const state = reactive({
            searchText: "",
            searchedColumn: "",
            data: [] as any[],
            categories : [] as any[],
            selectedCat: [] as any[],
            locations: [] as any[],

            categoryValue : [] as any[],
            fetchingCat:false,
            catOptions : [] as any[],

            menus: [] as any[],

            product:'',
            products:[] as any[],
            fetchProduct:false,
            productPrice:'',

        });

        const getCategories=()=>{
            categoryService.getAll().then((res)=>{
                state.categories=res.data;
            }).catch((error)=>{
                console.log('error getting categories', error);
            })
        }

        const toggleCategorys = (id: string , name:string) => {

           
            const index = state.selectedCat.indexOf(id);
            if (index === -1) {
                state.selectedCat.push({
                   id: id,
                   category:name,
                });
            } else {
                state.selectedCat.splice(index, 1);
            }

            console.log('selected categories', state.selectedCat);
        };

        const toggleCategory = (id: string, name: string) => {
            const index = state.selectedCat.findIndex(cat => cat.id === id);
            if (index === -1) {
                state.selectedCat.push({ id, category: name });
            } else {
                state.selectedCat.splice(index, 1);
            }
        };

        
        const addFormRef = ref();

        const addForm= reactive({
            categories:[] as any[],
            location_id:'',
            name:'',

        });

        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t("Pos.RequiredField") }],
            location_id: [{ required: true, message: t("Pos.RequiredField") }],
            categories: [{ required: true, message: t("Pos.RequiredField") }],
        };

        const resetFormAdd=()=>{
            addFormRef.value.resetFields();
        }

        const getLocations=()=>{
            tableLocationService.getAll().then((res)=>{
                state.locations=res.data;
            }).catch((error)=>{
                console.log('error geting table locations', error);
            })
        }


        //fetch categories
        
        let debounceTimer: ReturnType<typeof setTimeout> | null = null;

        const fetchCategory = (value: any) => {

            
            //disable fetching when the input is empty
            if(value===''){
                return;
            }


            state.catOptions = [];
           
            state.fetchingCat = true;


            //clear previous timer
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }


            //fetch users 

            debounceTimer = setTimeout(() => {
                categoryService.filter("name", value)
                .then((res) => {
                    const data = res.data.map((cat: { name: any; id: any; }) => ({
                        label: `${cat.name}`,
                        value: cat.id,
                    }));

                    
                    state.catOptions = data;

                    
                    state.fetchingCat = false;

                    if(data.length===0){
                        console.log('cat not found', data);
                        state.catOptions.push({
                            label: value,
                            value: value,
                        });
                    }
                    

                    console.log('state.catOptions : ', state.catOptions);


                })
                .catch((error) => {
                    console.log('error fetching categories', error);
                });
            }, 700);





           
             

        };
     
        watch(state.categoryValue, () => {
            state.catOptions = [];
            state.fetchingCat = false;
        });
        

        const handleCatSelect=(value: any[])=>{
            console.log('selected category', value);
            addForm.categories=value;
        }

        //collapse
        const activeKey = ref(['1']);

        const getLocationName=(id:any)=>{
           getLocations();
            const location = state.locations.find(item => item.id === id);
            return location ? location.name : null;
        }




        //add drawer

        const openAdd = ref<boolean>(false);
        const openAddMenu =()=>{
            updateModalWidth();
            getLocations();
            openAdd.value=true;
        }

        //add dish
        const addDish = ref<boolean>(false);

        const openAddDish =(catId:string)=>{
            menu.category_id=catId;
            addDish.value=true;
        }

       

        const dishFormRef = ref();

        const dishForm= reactive({
           product_id:'',
           product_name:'',
           product_price:'',
            get_price:false,

        });

        const dishRules: Record<string, Rule[]> = {
            product_price: [

                { required: true, message:  t('Pos.RequiredField') },
                { 
                validator: (_, value) => {
                    if (!isNaN(value)) {
                    return Promise.resolve();
                    }
                    return Promise.reject( t('Pos.numericField'));
                }
                }
                
            ],
           
        };

        const resetDishFields =()=>{
            dishFormRef.value.resetFields();
            state.products=[];
            state.product="";
            addDish.value=false;
        }

        const showSuccessNotification = (message: string) => {
            notification.success({
                message: message,
                duration: 3,
                top: "80px",
                style: {
                backgroundColor: "#bfffca8a",
                color: "white",
                },
            });
        };

        const addDishProduct=()=>{
            dishFormRef.value.validate().then(()=>{
                console.log('product', state.product, "menu", menu.id, "category", menu.category_id);
                menuService.attachProduct(menu.id, menu.category_id, state.product).then((res)=>{
                    getMenuById();
                    showSuccessNotification(t('Pos.AddProductSuccess'));


                }).catch((error)=>{
                    console.log('error attach product', error)
                }).finally(()=>{
                    addDish.value=false;
                    dishFormRef.value.resetFields();
                    state.product='';
                    state.products=[];
                });

            }).catch((error:any)=>{
                console.log('error fileds', error);
            });
        }



        let id=1;
        const add=()=>{
            addFormRef.value.validateFields().then(()=>{
                console.log('add');
                
                state.menus.push({
                    id:id,
                    name:addForm.name,
                    categories: addForm.categories,
                    location_id: addForm.location_id,
                });
                id++;


                openAdd.value=false;
                resetFormAdd();
                state.catOptions=[];
                state.categoryValue=[];
                state.fetchingCat=false;
                

                console.log('state.menus', state.menus);
                
            }).catch((error:any)=>{
                console.log('error validation fiels', error);
            });
        }

        //delete 
        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: "Delete menu",
                text: "Are you sure you want to delete this menu",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t("Pos.Delete"),
                cancelButtonText: t("Pos.Cancel"),
                cancelButtonColor: "#d33",
                customClass: {
                popup: "swal2-popup",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('delete');
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log("cancel delete ");
                }
            });
        };

        //fetch product
        let debounceTimeProduct: ReturnType<typeof setTimeout> | null = null;
        const fetchProduct = (value: any) => {
            //disable fetching when the input is empty
            if (value === "") {
                return;
            }

            state.products = [];

            state.fetchProduct = true;

            //clear previous timer
            if (debounceTimeProduct) {
                clearTimeout(debounceTimeProduct);
            }

            //fetch product

            debounceTimeProduct = setTimeout(() => {
                
                productService.getProductsByName( value)
                .then((res) => {
                const data = res.data.map((product: { name: any; id: any; price:any; }) => ({
                        label: `${product.name}`,
                        value: product.id,
                        price:product.price
                    }));


                    state.products = data;

                    console.log("stores", state.products);

                    state.fetchProduct = false;

                })
                .catch((error) => {
                    console.log("error fetching products", error);
                });
            }, 700);
        };

        const selecetProduct=(value:any, option:any)=>{
            console.log('selected product', value, option);
            dishForm.product_price=option.price;
        }


       
        //upload
        const fileList = ref([]);
        const loadingUplaod = ref<boolean>(false);
        const imageUrl = ref<string>('');

        function getBase64(img: Blob, callback: (base64Url: string) => void) {
            const reader = new FileReader();
            reader.addEventListener('load', () => callback(reader.result as string));
            reader.readAsDataURL(img);
        }

        const handleChange = (info: UploadChangeParam) => {
            if (info.file.status === 'uploading') {

                console.log('uploading ..')
                loadingUplaod.value = true;
                return;
            }
            if (info.file.status === 'done') {
               console.log('done');
            }
            if (info.file.status === 'error') {
                loadingUplaod.value = false;
                console.log('upload error');
            }
        };



        const beforeUpload = () => {
            return false;
        };

        const intialiseMenu=()=>{
            state.menus.push({
                id:0,
                name:"Menu",
                categories:[
                    
                    {label: 'Drinks', value: 1, key: 1, },
                    
                    {label: 'Pastry', value: 2, key: 2},
                    
                    {label: 'Sandwich', value: 3, key: 3},

                ],
                location_id:"1",

            });

            console.log('menu', state.menus);
        }


        
        const menu= reactive({
            categories:[] as any[],
            location_id:'',
            name:'',
            description:'',
            locationName:'',
            id:'',
            category_id:'',

        });

        const getMenuById=()=>{

            const id= localStorage.getItem('MenuId');
            if(id){

                menuService.show(id).then((res)=>{
                    menu.name=res.data.name;
                    menu.description=res.data.description;
                    menu.location_id=res.data.location.id;
                    menu.locationName=res.data.location.name;
                    menu.categories=res.data.categories;
                    menu.id=res.data.id;
                })

            }
            
        }

        onMounted(()=>{
            getMenuById();
        })



       onMounted(()=>{
            intialiseMenu();
            getCategories();           
       });

        return{
            t,
            locale,
            textDirection,
            modalWidth,
            loading,
            state,
            simpleImage,
            updateModalWidth,
            navigatePage,
            toggleCategory,
            fileList,
            loadingUplaod,
            beforeUpload,
            handleChange,
            imageUrl,
            openAddDish,
            openAdd,
            addForm,
            addFormRef,
            resetFormAdd,
            handleCatSelect,
            fetchCategory,
            add,
            rules,
            activeKey,
            getLocationName,
            showDeletePopup,
            addDish,
            openAddMenu,
            dishFormRef,
            dishForm,
            dishRules,
            fetchProduct,
            selecetProduct,
            resetDishFields,
            addDishProduct,
            menu,





        }
        
    },
})
</script>

<style >

.ant-collapse-borderless >.ant-collapse-item:last-child .ant-collapse-header{
    border-radius: 20px;
}

.collapse-container{
    margin-top: 10px;
}

.collapse-container{
    background-color: #f5f5f5;
    border-radius: 20px;
}

.menu-collapse{
    background: #fff;
    border-radius: 20px;
    margin-bottom: 24px;
    border: 0;
    overflow: hidden
}

.custom-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;

  
    /* margin-top: -10px; */
}

.custom-header{
    font-size: 16px; 
    font-weight: 600;
    line-height: 1.5; 
}



.location-container{
    display: flex;
}

.menu-title{
    font-weight: 600;
    font-size: 15px;
}


.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}



.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-container{
    display: flex;
    align-items: flex-start;
}


.search-input{
    line-height: 1.5 !important;
    border-radius: 20px;
    font-size: 14px ;
    width: 60%;
    padding: 8px;
    border: none;
    
}

.search-icon{
    padding-right:10px ;
    font-size: 18px;
    color: #d9d9d9;
}
.add-btn-container{
    display: flex;
    justify-content: flex-end; 
}

.add-dish-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 40px;
    font-size: 14px;
    background-color: #fff;
    color: #fc8019;
    border: 1px solid #fc8019;
    border-radius: 20px;
    font-weight: 500;

    box-shadow: inset 0 0 0 0 #fc8019;

}

.add-dish-btn:hover{
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
}

.cat-container {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 15px;

  border-radius: 20px;

}

.categories-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.category-item {
  padding: 5px 10px;
  border-radius: 20px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  border: 2px solid #fc8019;



}

.category-item:hover {
  background-color: #fc8019;
  color: #fff;
}

.category-item span {
  display: flex;
  align-items: center;
}

.category-item span > *:not(:last-child) {
  margin-right: 8px;
 
}

.toggle-icon{
    padding-right: 8px;
}


.new-dish-container{
    margin-top: 5px;
    margin-bottom: 10px;
}
.new-dish-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    background-color:#fff5e670;
    border-radius: 20px;
    border: 1px dashed #fc8019;

    cursor: pointer;

}

.cat-menu-container{
    display: flex;
}
.cat-name-menu{
    font-weight: 600;
    margin-left: 10px;
}


.plus-icon{
    margin-bottom: 10px;
    font-size: 25px;
    color: #fc8019;
}

.more-icon{
    font-size: 20px;
}

.delete-icon{
    color: #ff4d4f;
    font-size: 16px;
}


.ant-drawer .ant-drawer-content{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}


/*products*/

.produc-cat{
    display: flex;
    flex-wrap: nowrap;
    gap: 40px;
    margin-left: 20px;
}

.product-card{
                    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
}


/* upload*/
/* 
avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload-icon{
    font-size: 22px;
    color: #fc8019;
}

.menu-item-container{
    display: flex;
    
    justify-content: flex-start;
} */



</style>
