<template>

    <a-config-provider
      :theme="{
      token: {
        colorPrimary: themeTemplate.primaryColor,
        fontFamily: 'Exo 2',
      },
      }"
    ></a-config-provider>
    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>
    <a-row class="login-container">

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 11}" :lg="{ span: 11 }" :xl="{ span: 11 }" :xxl="{ span: 11 }" class="side-container">
            

            
            <div  class="logo-container">
                <img src="@/assets/img/posys-logo-small.png" alt="Posys Image" width="30%"  />
            </div>
            <div class="form-container">
                  
                <a-steps
                    :current="current"
                    :items="items"
                ></a-steps>
                <div class="steps-content">
                    <a-form  ref="registerFormRef" :model="registerForm" :rules="rulesForRegister" layout="vertical">

                        <div v-if="steps[current].content==='first-content'">
                            <a-row :gutter="16">    
                                
                                
                                <a-col :span="24">
                                    <a-form-item  name="name">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.Name') }}</span>
                                        </template>
                                        <a-input :placeholder="t('Pos.Name')" v-model:value="registerForm.name"/>
                                    </a-form-item>
                                </a-col>

                            
                            </a-row>

                            <a-row :gutter="16">

                                <a-col :span="12">
                                    <a-form-item  name="first_name">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.FisrtName') }}</span>
                                        </template>
                                        <a-input :placeholder="t('Pos.FisrtName')" v-model:value="registerForm.first_name"/>
                                    </a-form-item>
                                </a-col>



                                <a-col :span="12">
                                    <a-form-item  name="last_name">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.LastName') }}</span>
                                        </template>
                                        <a-input :placeholder="t('Pos.LastName')" v-model:value="registerForm.last_name"/>
                                    </a-form-item>
                                </a-col>

                            </a-row>

                        


                            <a-row :gutter="16">    
                                <a-col :span="12">
                                    <a-form-item  name="email" has-feedback :validate-status="validatingStatus" :help="validatingHelp">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.Email') }}</span>
                                        </template>
                                        <a-input :placeholder="t('Pos.Email')" v-model:value="registerForm.email" @blur="validateEmail" />
                                    </a-form-item>
                                </a-col>


                                <a-col :span="12">
                                    <a-form-item  name="username">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.Username') }}</span>
                                        </template>
                                        <a-input :placeholder="t('Pos.Username')" v-model:value="registerForm.username"/>
                                    </a-form-item>
                                </a-col>
                            
                            </a-row>

                        
                            <a-row :gutter="16">    
                                
                                <a-col :span="12">
                                    <a-form-item  name="password">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.Password') }}</span>
                                        </template>
                                        <a-input-password v-model:value="registerForm.password" :placeholder="t('Pos.Password')" type="password" autocomplete="off" />

                                    </a-form-item>
                                </a-col>

                                <a-col :span="12">
                                    <a-form-item   name="checkPass">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.Confirmpassword') }}</span>
                                        </template>
                                        <a-input-password v-model:value="registerForm.checkPass" :placeholder="t('Pos.Confirmpassword')" type="password" autocomplete="off" />
                                    </a-form-item>
                                </a-col>
                        
                            
                            </a-row>

                        
                        </div>

                        <div v-if="steps[current].content==='second-content'">

                            <a-row :gutter="16">
                                <a-col :span="12">
                                    <a-form-item  name="phone">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.Phone') }}</span>
                                        </template>
                                        <a-input :placeholder="t('Pos.Phone')" v-model:value="registerForm.phone"/>
                                    </a-form-item>
                                </a-col>
                        
                                <a-col :span="12">
                                    <a-form-item  name="phone_number">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.phone_number') }}</span>
                                        </template>
                                        <a-input :placeholder="t('Pos.phone_number')" v-model:value="registerForm.phone_number"/>
                                    </a-form-item>
                                </a-col>

                            </a-row> 


                            <a-row :gutter="16">      
                                <a-col :span="24">
                                    <a-form-item  name="Address">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.Address') }}</span>
                                        </template>
                                        <a-input :placeholder="t('Pos.Address')" v-model:value="registerForm.address"/>
                                    </a-form-item>
                                </a-col>
                            </a-row>

                            
                                
                            <a-row :gutter="16">     
                                <a-col :span="12">
                                    <a-form-item  name="registration_number">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.registration_number') }}</span>
                                        </template>
                                        <a-input :placeholder="t('Pos.registration_number')" v-model:value="registerForm.registration_number"/>
                                    </a-form-item>
                                </a-col>


                                <a-col :span="12">
                                    <a-form-item  name="vat_number">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.vat_number') }}</span>
                                        </template>
                                        <a-input :placeholder="t('Pos.vat_number')" v-model:value="registerForm.vat_number"/>
                                    </a-form-item>
                                </a-col>
                            
                            </a-row>

                            <a-row :gutter="16">       
                            
                                <a-col :span="24">
                                <a-form-item name="category">
                                    <template #label>
                                    <span class="label-form2">{{ t('Pos.Category') }}</span>
                                    </template>
                                    <a-select v-model:value="registerForm.category" :placeholder="t('Pos.CurrencyChoose')"  show-search :option-filter-prop="'label'">
                                    <a-select-option v-for="category in state.data" :key="category.id" :value="category.id" :label="category.name">
                                        {{ category.name }}
                                    </a-select-option>
                                    </a-select>
                                </a-form-item>
                                </a-col>
                            </a-row>
                    
                           
                        </div>

                        <div  v-if="steps[current].content==='third-content'">
                           

                            <a-row>
                                <a-col :span="24">
                                    <a-form-item  name="Description">
                                        <template #label>
                                            <span class="label-form2">{{ t('Pos.Description') }}</span>
                                        </template>
                                        <a-textarea v-model:value="registerForm.description" :rows="4" :placeholder="t('Pos.Description')" />

                                    </a-form-item>
                                </a-col>

                            </a-row>


                            <a-row :gutter="24">       
                                <a-col :span="24">
                                    <a-form-item>
                                        <template #label>
                                        <span class="label-form2">{{ t('Pos.Logo') }}</span>
                                        </template>
                    
                                        <a-upload-dragger
                                        v-model:fileList="fileList"
                                        name="file"
                                        :multiple="false"
                                        :max-count="1"
                                        @change="handleChangeFile"
                                        @drop="handleDrop"
                                        :before-upload="beforeUpload"
                                        >
                    
                                        <p class="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p class="ant-upload-text">{{ t('Pos.dropTitle1') }}</p>
                                        <p class="ant-upload-hint">
                                            {{ t('Pos.dropTitle2') }}
                                        </p>
                    
                                        </a-upload-dragger>          
                                    </a-form-item>
                                    </a-col>

                            </a-row>
                        </div>
                        
                    </a-form>
                </div>

                <div class="steps-action">
                    

                    <a-button v-if="current < steps.length - 1" type="primary" @click="next">{{ t('Pos.Next') }}</a-button>
                    <a-button
                        v-if="current == steps.length - 1"
                        type="primary"
                        @click="submitRegister()"
                    >
                        {{ t('Pos.register') }}
                    </a-button>
                    <a-button v-if="current > 0" style="margin-left: 8px" @click="prev"> {{ t('Pos.Previous') }}</a-button>
            
                </div>
        
            </div>

            <div >
                <p>{{ t('Pos.LoginInText') }} <span class="register-title"  @click="navigatePage('/login')">{{ t('Pos.SignIn') }}</span> </p>

            </div>

           
           
        </a-col>

        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 13}" :lg="{ span: 13 }" :xl="{ span: 13 }" :xxl="{ span: 13 }" class="login-img">
            <img src="@/assets/img/login-img.png" alt="Posys Image" width="80%"  />
        </a-col>
   </a-row>
    
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { UploadChangeParam,UploadFile } from 'ant-design-vue';
import { notification } from 'ant-design-vue';
import { ref, defineComponent, reactive, toRaw , onMounted, defineEmits } from 'vue';
import { authService  , userService , activitiesCategoriesService } from "@/_services";
import { useRouter } from 'vue-router';
import type { Rule } from 'ant-design-vue/es/form';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import {
  
  InboxOutlined,
 
  
} from '@ant-design/icons-vue';
export default defineComponent({
    name: "registerIndex",
    components: {
       
        LbrxLoaderSpinner,
        InboxOutlined
        

    },
   

    setup() {


        const loading = ref(false);
        const { t } = useI18n();
        const visible = ref<boolean>(false);

        const state = reactive({
            data: [] as any[],
        })

        const router = useRouter();

        const navigatePage = (link: string) => {
            router.push(link); 
        };

    

        const registerFormRef = ref();
        const registerForm=reactive({
            name:'',
            description:'',
            address:'',
            phone:'',
            email:'',
            username:'',
            password:'',
            first_name:'',
            last_name:'',
            phone_number:'',
            registration_number:'',
            vat_number:'',
            settings:'',
            category:'',
            checkPass:'',
        })

        const validatePass = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject(t('Pos.RequiredField'));
            } else {
                if (registerForm.checkPass !== '' || registerForm.checkPass !== '') {
                    registerFormRef?.value?.validateFields('checkPass');
                }
                return Promise.resolve();
            }
        };
        const validatePass2 = async (_rule: Rule, value: string) => {
            if (value === '') {
                return Promise.reject(t('Pos.RequiredField'));
            } else if (value !== registerForm.password ||  value !== registerForm.password) {
                return Promise.reject(t('Pos.PasswordMatchError'));
            } else {
                return Promise.resolve();
            }
        };

        const rulesForRegister: Record<string, Rule[]> = {
        
            name: [{ required: true, message: t('Pos.RequiredField') }],
            username: [{ required: true, message: t('Pos.RequiredField') }],
            first_name: [{ required: true, message: t('Pos.RequiredField') }],
            last_name: [{ required: true, message:t('Pos.RequiredField') }],
            email: [
                { required: true, message: t('Pos.RequiredField')  , trigger: 'change'},
                {
                    validator: (rule, value) => {
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value)) {
                            return Promise.reject(t('Pos.invalidEmail'));
                        }
                        return Promise.resolve();
                    }, trigger: 'change'
                },
                    
            ],

            category: [{ required: true, message: t('Pos.RequiredField') }],
            password: [{ required: true, validator: validatePass, trigger: 'change' }],
            checkPass: [{ validator: validatePass2, trigger: 'change' }],

            phone: [
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],

            phone_number: [
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],

            registration_number: [
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],

            vat_number: [
                { 
                    validator: (_, value) => {
                        if (!isNaN(value)) {
                        return Promise.resolve();
                        }
                        return Promise.reject(t('Pos.numericField'));
                    }
                }
            ],
        };


        const resetFormRegister = () => {
            registerFormRef.value.resetFields();
            registerForm.name="";
            registerForm.description="";
            registerForm.category="";
            registerForm.first_name="";
            registerForm.last_name="";
            registerForm.phone_number="";
            registerForm.password="";
            registerForm.checkPass="";
            registerForm.address="";
            registerForm.phone="";
            registerForm.email="";
            registerForm.username="";
            registerForm.registration_number="";
            registerForm.vat_number="";
            
            fileList.value=[];


        };
        const fileList = ref([]);

        const fileInfo = ref<UploadFile | null>(null);

        const handleChange = (info: UploadChangeParam) => {
            const file = info.file.originFileObj; 
            console.log('info', info.file);
            fileInfo.value=info.file;
        };

        function handleDrop(e: DragEvent) {
            console.log(e);
        }

        const beforeUpload = () => {
            return false;
        };

        const handleChangeFile = (info: UploadChangeParam) => {
            fileInfo.value=info.file;
        };

        const current = ref<number>(0);
        const goNext = ref(false);

        const next = () => {
            //validateEmail();
            registerFormRef.value.validate().then(()=>{
                current.value++;

            }).catch((error:any) => {
                console.error('Validation error:', error);
            });

        };

        const prev = () => {
            current.value--;
        };

        const steps = [
            {
                title: t('Pos.Step1'),
                content: 'first-content',

            },
            {
                title: t('Pos.Step2'),
                content: 'second-content',
            },
            {
                title: t('Pos.Step3'),
                content: 'third-content',
            }
        ];
        const items = steps.map(item => ({ key: item.title, title: item.title }));

        const validatingStatus = ref('');
        const validatingHelp = ref('');

        const emailValid = (email:any) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        const validateEmail = () => {
            if(registerForm.email!==""){
                if (emailValid(registerForm.email)) {
                    console.log('begin validation email');
                    const payload = { email: registerForm.email };
                    validatingStatus.value = 'validating'; 
                    validatingHelp.value = t('Pos.validationEmailTxt');

                    userService.verifyEmail(payload)
                    .then(response => {
                        console.log('response', response);
                        if (response.message==="Email available for use") {
                            validatingStatus.value = 'success';
                            validatingHelp.value = t('Pos.validEmail');
                        } else {
                            validatingStatus.value = 'error'; 
                            validatingHelp.value = t('Pos.EmailInUse');
                        }
                    })
                    .catch(error => {
                        console.error('Error validating email:', error);
                        validatingStatus.value = 'error'; 
                        validatingHelp.value = t('Pos.errorEmailValidation');
                    });
                }else{

                    validatingStatus.value = 'error'; 
                    validatingHelp.value = t('Pos.invalidEmail');
                }
            }else{
                validatingStatus.value = 'error'; 
                validatingHelp.value = t('Pos.RequiredField');
            }
        };
       

        const Register =()=> {
           
            loading.value=true;
            authService.createUser(registerForm.name,registerForm.description,registerForm.address,registerForm.phone ,registerForm.email,'true',registerForm.username,registerForm.password,
            registerForm.first_name,registerForm.last_name,registerForm.phone_number,registerForm.registration_number,registerForm.vat_number,registerForm.settings,registerForm.category,fileInfo.value).then((res) => {
            
          
                //showSuccessNotification(t('Pos.AddCustomer'));
                //emit('registration-success', { email: registerForm.email, password: registerForm.password });

                router.push({ name: 'login'});
               


            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                // resetAdd();
                //openTransferModal.value = false
                loading.value=false;

                //form.email=registerForm.email;
                //form.password=registerForm.password;
                resetFormRegister();
            })  
             
        }
        const submitRegister = () => {
            registerFormRef.value
                .validate()
                .then(() => {
                    Register();
                })
                .catch((error: any) => {
                    console.log('error', error);
                });
        };



        const getActivitiesCategories = () => {
            loading.value = true ;
            activitiesCategoriesService.getAll()
            .then((res) => {    
                state.data = res;
            })
            .catch((error)=>{
            console.log("error api : get store", error);
            })
            .finally(()=>{
            loading.value = false;
            });
        }

        onMounted(() => {
               
            getActivitiesCategories();
        });   

        //theme

        interface Theme {
            [key: string]: string;
        }
        const themeTemplate = {
            primaryColor: "#fc8019",
            confirmationColor: "#09aa29",
            cart: {
                selectCartItemColor: "#09aa29"
            }
        }
        const themeString = window.localStorage.getItem('themeTemplate');
        let theme: Theme = {};
        if (themeString) {
        theme = JSON.parse(themeString) ;
        themeTemplate.primaryColor = theme.primaryColor;
        themeTemplate.confirmationColor = theme.confirmationColor;
        console.log('rrrrrrrr : ', themeTemplate)
        const customStyles = `
        .bg-primary {
            background-color: ${themeTemplate.primaryColor};
        }
        .text-primary {
            color: ${themeTemplate.primaryColor};
        }
        .border-primary {
            border-color: ${themeTemplate.primaryColor};
        }
        .bg-confirmation {
            background-color: ${themeTemplate.confirmationColor};
        }
        .text-confirmation {
            color: ${themeTemplate.confirmationColor};
        }
        .accent-primary {
            accent-color: ${themeTemplate.primaryColor};
        }
        `;

        const styleElement = document.createElement('style');
        styleElement.type = 'text/css';

        if ('styleSheet' in styleElement) {
            (styleElement as any).styleSheet.cssText = customStyles;
        } else {
            styleElement.appendChild(document.createTextNode(customStyles));
        }

        document.head.appendChild(styleElement);
        }
           
        return{
            t,
            navigatePage,
            themeTemplate,
            loading,
            state,
            visible,
            registerFormRef,
            registerForm,
            rulesForRegister,
            submitRegister,
            current,
            goNext,
            next,
            prev,
            steps,
            items,
            fileList,
            handleChange,
            handleDrop,
            beforeUpload,
            handleChangeFile,
            validatingStatus,
            validatingHelp,
            validateEmail,

















        }
    }

});

</script>

<style scoped>
.login-container{
   
   
    height: 100vh;
    width: 100vw;
    position: fixed;

    

    
    
    
}

.side-container{
    background: #f8f8fa;

    
}

.form-container{
    padding-top: 0;
    padding-bottom: 0;
     padding-left: 50px;
    padding-right: 50px; 
}

.logo-container{
    margin-top: 30px;
    margin-bottom: 20px;
}

.login-input{
    line-height: 2.5;
    border: none;
    background: #fff;
    font-weight: 500;
}

/* stepper */

.steps-content {
  margin-top: -45px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
  display: flex;
}

/* loader container */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-title{
    font-weight: 600;
    color: #fc8019;
    cursor: pointer;
}



</style>