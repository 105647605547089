import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01035d5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "label-form" }
const _hoisted_3 = { class: "label-form" }
const _hoisted_4 = { class: "label-form" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "label-form" }
const _hoisted_7 = { class: "label-form" }
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { class: "label-form" }
const _hoisted_13 = { class: "label-form" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }
const _hoisted_16 = { class: "label-form" }
const _hoisted_17 = { key: 3 }
const _hoisted_18 = { class: "label-form" }
const _hoisted_19 = { class: "label-form" }
const _hoisted_20 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_QuestionCircleOutlined = _resolveComponent("QuestionCircleOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Settings')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.InvoiceSettings')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_card, {
          class: "card-settings",
          "tab-list": _ctx.settingsTitle,
          "active-tab-key": _ctx.key,
          onTabChange: _cache[32] || (_cache[32] = key => _ctx.onTabChange(key, 'key'))
        }, {
          default: _withCtx(() => [
            (_ctx.key === 'general')
              ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
                  _createVNode(_component_a_form, {
                    ref: "formRef",
                    model: _ctx.form,
                    rules: _ctx.rules,
                    layout: "vertical"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_row, { gutter: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "decimal_seperator" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('Pos.DecimalSeparator')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.form.decimal_seperator,
                                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.decimal_seperator) = $event)),
                                    "show-search": ""
                                  }, {
                                    notFoundContent: _withCtx(() => [
                                      _createVNode(_component_a_empty, {
                                        description: _ctx.t('Pos.NoData'),
                                        image: _ctx.simpleImage
                                      }, null, 8, ["description", "image"])
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_select_option, { value: "." }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(".")
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_select_option, { value: "," }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(",")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "thousands_seperator" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('Pos.ThousandsSeparator')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.form.thousands_seperator,
                                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.thousands_seperator) = $event)),
                                    "show-search": ""
                                  }, {
                                    notFoundContent: _withCtx(() => [
                                      _createVNode(_component_a_empty, {
                                        description: _ctx.t('Pos.NoData'),
                                        image: _ctx.simpleImage
                                      }, null, 8, ["description", "image"])
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_select_option, { value: "." }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(".")
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_select_option, { value: "," }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(",")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, {
                            span: 24,
                            class: "check-conatiner"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "authTax" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_checkbox, {
                                    checked: _ctx.form.authTax,
                                    "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.authTax) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Pos.showTawPeritem')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["checked"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, {
                            span: 24,
                            class: "check-conatiner"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "excludeCurrencySymbol" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_checkbox, {
                                    checked: _ctx.form.excludeCurrencySymbol,
                                    "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.excludeCurrencySymbol) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Pos.excludeCurrencySymbol')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["checked"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, {
                            span: 24,
                            class: "check-conatiner"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "roundNumbers" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_checkbox, {
                                    checked: _ctx.form.roundNumbers,
                                    "onUpdate:checked": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.roundNumbers) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Pos.RoundNumbers')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["checked"]),
                                  _createVNode(_component_a_tooltip, null, {
                                    title: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Pos.roundNumbersTxt')), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_QuestionCircleOutlined, { class: "tooltip-icon" })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, {
                            span: 24,
                            class: "check-conatiner"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "excludeTaxName" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_checkbox, {
                                    checked: _ctx.form.excludeTaxName,
                                    "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.excludeTaxName) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Pos.ExcludeTaxName')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["checked"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 24 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, { name: "tax_id" }, {
                                label: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('Pos.defaultTax')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: _ctx.form.defaultTax,
                                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.defaultTax) = $event)),
                                    "show-search": "",
                                    "option-filter-prop": 'label'
                                  }, {
                                    notFoundContent: _withCtx(() => [
                                      _createVNode(_component_a_empty, {
                                        description: _ctx.t('Pos.NoData'),
                                        image: _ctx.simpleImage
                                      }, null, 8, ["description", "image"])
                                    ]),
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.vats, (item) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          label: item.name,
                                          key: item.id,
                                          value: item.id,
                                          selected: item.id === _ctx.form.defaultTax 
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["label", "value", "selected"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model", "rules"]),
                  _createVNode(_component_a_row, { class: "card-footer" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_space, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            type: "primary",
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateConfig()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_button, {
                            type: "primary",
                            danger: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : (_ctx.key==='invoice')
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                    _createVNode(_component_a_form, {
                      ref: "formInvoiceRef",
                      model: _ctx.formInvoice,
                      rules: _ctx.Invoicerules,
                      layout: "vertical"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_row, { gutter: 16 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, { name: "invoice_number_type" }, {
                                  label: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Pos.InvoiceNumberGenerationMethod')), 1)
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_select, {
                                      value: _ctx.formInvoice.invoice_number_type,
                                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formInvoice.invoice_number_type) = $event)),
                                      "show-search": ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select_option, { value: "inc" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('Pos.Incremental')), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_a_select_option, { value: "random" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('Pos.Random')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }, 8, ["value"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_row, { gutter: 16 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, { name: "number_prefix" }, {
                                  label: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.InvoiceNumberPrefix')), 1)
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: _ctx.formInvoice.number_prefix,
                                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formInvoice.number_prefix) = $event)),
                                      placeholder: _ctx.t('Pos.InvoiceNumberPrefix'),
                                      class: "input-form"
                                    }, null, 8, ["value", "placeholder"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_row, { gutter: 16 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, { name: "nextNumber" }, {
                                  label: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.InvoiceNextNumber')), 1)
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: _ctx.formInvoice.nextNumber,
                                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formInvoice.nextNumber) = $event)),
                                      placeholder: _ctx.t('Pos.InvoiceNextNumber'),
                                      class: "input-form"
                                    }, null, 8, ["value", "placeholder"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_row, { gutter: 16 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, { name: "limitDate" }, {
                                  label: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.limitDateInvoice')), 1)
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: _ctx.formInvoice.limitDate,
                                      "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formInvoice.limitDate) = $event)),
                                      placeholder: _ctx.t('Pos.limitDateInvoice'),
                                      class: "input-form"
                                    }, null, 8, ["value", "placeholder"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              span: 24,
                              class: "check-conatiner"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, { name: "indicateContact" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_checkbox, {
                                      checked: _ctx.formInvoice.indicateContact,
                                      "onUpdate:checked": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formInvoice.indicateContact) = $event))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t('Pos.IndicateContact')), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["checked"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              span: 24,
                              class: "check-conatiner"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, { name: "excludeDraftInvoices" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_checkbox, {
                                      checked: _ctx.formInvoice.excludeDraftInvoices,
                                      "onUpdate:checked": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formInvoice.excludeDraftInvoices) = $event))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t('Pos.excludeDraftInvoices')), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["checked"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_row, { gutter: 16 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, { name: "anonymous_customer" }, {
                                  label: _withCtx(() => [
                                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Pos.AnonymousCustomerDisplayName')), 1)
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      value: _ctx.formInvoice.anonymous_customer,
                                      "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formInvoice.anonymous_customer) = $event)),
                                      placeholder: _ctx.t('Pos.AnonymousCustomerDisplayName'),
                                      class: "input-form"
                                    }, null, 8, ["value", "placeholder"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["model", "rules"]),
                    _createVNode(_component_a_row, { class: "card-footer" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_space, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              onClick: _ctx.updateInvoice
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_a_button, {
                              type: "primary",
                              danger: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : (_ctx.key==='credit')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
                      _createVNode(_component_a_form, {
                        ref: "formCreditRef",
                        model: _ctx.formCredit,
                        rules: _ctx.rulesCredit,
                        layout: "vertical"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_row, { gutter: 16 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 24 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "prefixNC" }, {
                                    label: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('Pos.NCprefix')), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        value: _ctx.formCredit.prefixNC,
                                        "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.formCredit.prefixNC) = $event)),
                                        placeholder: _ctx.t('Pos.NCprefix'),
                                        class: "input-form"
                                      }, null, 8, ["value", "placeholder"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_row, { gutter: 16 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 24 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "nextNumber" }, {
                                    label: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Pos.NcnextNumber')), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        value: _ctx.formCredit.nextNumber,
                                        "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.formCredit.nextNumber) = $event)),
                                        placeholder: _ctx.t('Pos.NcnextNumber'),
                                        class: "input-form"
                                      }, null, 8, ["value", "placeholder"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 24 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "formatNC" }, {
                                    label: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Pos.NumberFormat')), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_radio_group, {
                                        value: _ctx.formCredit.formatNC,
                                        "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.formCredit.formatNC) = $event))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_a_radio, { value: "baseNumber" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.t('Pos.NumberBase')) + " (000001)", 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_a_radio, { value: "yearAndNumber" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.t('Pos.YearAndNumber')) + " (YYYY/00001)", 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_a_radio, { value: "000001-YY" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("000001-YY")
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_a_radio, { value: "000001/MM/YYYY" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("\">000001/MM/YYYY")
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["value"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                span: 24,
                                class: "check-conatiner"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "dcrInvoiceNumberAfterdelete" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_checkbox, {
                                        checked: _ctx.formCredit.dcrInvoiceNumberAfterdelete,
                                        "onUpdate:checked": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.formCredit.dcrInvoiceNumberAfterdelete) = $event))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.dcrNcAfterDelete')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["checked"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                span: 24,
                                class: "check-conatiner"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "displayNameInNc" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_checkbox, {
                                        checked: _ctx.formCredit.displayNameInNc,
                                        "onUpdate:checked": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.formCredit.displayNameInNc) = $event))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.displayNameNC')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["checked"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 24 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "notesClient" }, {
                                    label: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Pos.NotesClient')), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_textarea, {
                                        value: _ctx.formCredit.notesClient,
                                        "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.formCredit.notesClient) = $event)),
                                        rows: 4,
                                        placeholder: _ctx.t('Pos.NotesClient')
                                      }, null, 8, ["value", "placeholder"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 24 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "terms" }, {
                                    label: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('Pos.Terms')), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_textarea, {
                                        value: _ctx.formCredit.terms,
                                        "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.formCredit.terms) = $event)),
                                        rows: 4,
                                        placeholder: _ctx.t('Pos.Terms')
                                      }, null, 8, ["value", "placeholder"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["model", "rules"]),
                      _createVNode(_component_a_row, { class: "card-footer" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_space, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_button, {
                                type: "primary",
                                onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.updateNC()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_button, {
                                type: "primary",
                                danger: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]))
                  : (_ctx.key==='devis')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                        _createVNode(_component_a_form, {
                          ref: "formDevisRef",
                          model: _ctx.formDevis,
                          rules: _ctx.rulesDevis,
                          layout: "vertical"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_row, { gutter: 16 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, { span: 24 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, { name: "prefix" }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Pos.Quotationprefix')), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input, {
                                          value: _ctx.formDevis.prefix,
                                          "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.formDevis.prefix) = $event)),
                                          placeholder: _ctx.t('Pos.Quotationprefix'),
                                          class: "input-form"
                                        }, null, 8, ["value", "placeholder"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, { gutter: 16 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, { span: 24 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, { name: "nextNumber" }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Pos.QuotationnextNumber')), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input, {
                                          value: _ctx.formDevis.nextNumber,
                                          "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.formDevis.nextNumber) = $event)),
                                          placeholder: _ctx.t('Pos.QuotationnextNumber'),
                                          class: "input-form"
                                        }, null, 8, ["value", "placeholder"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, { gutter: 16 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, { span: 24 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, { name: "quotationLimitDate" }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Pos.QuotationLimit')), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input, {
                                          value: _ctx.formDevis.quotationLimitDate,
                                          "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.formDevis.quotationLimitDate) = $event)),
                                          placeholder: _ctx.t('Pos.QuotationLimit'),
                                          class: "input-form"
                                        }, null, 8, ["value", "placeholder"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, {
                                  span: 24,
                                  class: "check-conatiner"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, { name: "deleteLatest" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_checkbox, {
                                          checked: _ctx.formDevis.deleteLatest,
                                          "onUpdate:checked": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.formDevis.deleteLatest) = $event))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('Pos.AllowDeleteLatest')), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["checked"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, {
                                  span: 24,
                                  class: "check-conatiner"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, { name: "dcrNumberAfterDelete" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_checkbox, {
                                          checked: _ctx.formDevis.dcrNumberAfterDelete,
                                          "onUpdate:checked": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.formDevis.dcrNumberAfterDelete) = $event))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('Pos.dcrNumberAfterDelete')), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["checked"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, {
                                  span: 24,
                                  class: "check-conatiner"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, { name: "allowCollabs" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_checkbox, {
                                          checked: _ctx.formDevis.allowCollabs,
                                          "onUpdate:checked": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.formDevis.allowCollabs) = $event))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('Pos.allowCollabsDevis')), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["checked"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_col, {
                                  span: 24,
                                  class: "check-conatiner"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_form_item, { name: "showStoreNameQuotation" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_checkbox, {
                                          checked: _ctx.formDevis.displayNameInNc,
                                          "onUpdate:checked": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.formDevis.displayNameInNc) = $event))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.t('Pos.showStoreNameQuotation')), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["checked"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_row, { class: "card-footer" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_space, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_button, {
                                      type: "primary",
                                      onClick: _cache[31] || (_cache[31] = ($event: any) => (_ctx.updateDevis()))
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_button, {
                                      type: "primary",
                                      danger: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["model", "rules"])
                      ]))
                    : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["tab-list", "active-tab-key"])
      ]),
      _: 1
    })
  ], 64))
}