import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d2b4f6d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Settings')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.StoreSettings')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_card, {
            class: "card-settings",
            "tab-list": _ctx.settingsTitle,
            "active-tab-key": _ctx.key,
            onTabChange: _cache[1] || (_cache[1] = key => _ctx.onTabChange(key, 'key'))
          }, {
            default: _withCtx(() => [
              (_ctx.key === 'store')
                ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
                    _createVNode(_component_a_form),
                    _createVNode(_component_a_row, { class: "card-footer" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_space, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, { type: "primary" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_button, {
                              type: "primary",
                              danger: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("p", _hoisted_2, "project content"))
            ]),
            _: 1
          }, 8, ["tab-list", "active-tab-key"])
        ]),
        _: 1
      })
    ])
  ], 64))
}