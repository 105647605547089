import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62a978f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_5 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_6 = { class: "label-form" }
const _hoisted_7 = { class: "label-form" }
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "label-form" }
const _hoisted_12 = { class: "label-form" }
const _hoisted_13 = { class: "label-form" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }
const _hoisted_16 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_17 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_18 = { class: "custom-header" }
const _hoisted_19 = {
  class: "info-title",
  placeholder: "name"
}
const _hoisted_20 = { class: "info-title" }
const _hoisted_21 = { class: "info-title" }
const _hoisted_22 = { class: "info-title" }
const _hoisted_23 = {
  class: "info-title",
  placeholder: "name"
}
const _hoisted_24 = {
  class: "info-title",
  placeholder: "name"
}
const _hoisted_25 = {
  class: "info-title",
  placeholder: "name"
}
const _hoisted_26 = {
  class: "info-title",
  placeholder: "name"
}
const _hoisted_27 = {
  class: "info-title",
  placeholder: "name"
}
const _hoisted_28 = { class: "info-title" }
const _hoisted_29 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_30 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_31 = { class: "label-form" }
const _hoisted_32 = { class: "label-form" }
const _hoisted_33 = { class: "label-form" }
const _hoisted_34 = { class: "label-form" }
const _hoisted_35 = { class: "label-form" }
const _hoisted_36 = { class: "label-form" }
const _hoisted_37 = { class: "label-form" }
const _hoisted_38 = { class: "label-form" }
const _hoisted_39 = { class: "label-form" }
const _hoisted_40 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_check_circle_two_tone = _resolveComponent("check-circle-two-tone")!
  const _component_close_circle_two_tone = _resolveComponent("close-circle-two-tone")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, {
        style: {"margin":"16px 0"},
        class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Home')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Customer')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAddDrawer(true)))
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_UserOutlined)
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')) + " ", 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        class: "table-container",
        columns: _ctx.columns,
        "data-source": _ctx.reactiveData.data,
        pagination: false
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        type: "primary",
                        onClick: ($event: any) => (_ctx.showDisplayDrawer(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Edit')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key == 'firstname')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatNameConcat(record)), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_input, {
              ref: "searchInput",
              placeholder: `Search ${column.dataIndex}`,
              value: selectedKeys[0],
              style: {"width":"188px","margin-bottom":"8px","display":"block"},
              onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
              onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_pagination, {
          class: "paginationArrows",
          current: _ctx.currentPage,
          "onUpdate:current": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
          total: _ctx.form.customLastPage,
          onChange: _ctx.handlePageChange,
          "default-page-size": _ctx.perPage,
          showSizeChanger: false
        }, null, 8, ["current", "total", "onChange", "default-page-size"]),
        (_ctx.form.customLastPage>10)
          ? (_openBlock(), _createBlock(_component_a_select, {
              key: 0,
              class: "select-input",
              value: _ctx.customPagination,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customPagination) = $event)),
              onChange: _ctx.choosePerPage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select_option, { value: "10" }, {
                  default: _withCtx(() => [
                    _createTextVNode("10 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "20" }, {
                  default: _withCtx(() => [
                    _createTextVNode("20 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "50" }, {
                  default: _withCtx(() => [
                    _createTextVNode("50 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "100" }, {
                  default: _withCtx(() => [
                    _createTextVNode("100 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "onChange"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px'},
        onAfterOpenChange: _ctx.afterOpenChange,
        onClose: _ctx.resetUpdate
      }, {
        title: _withCtx(() => [
          ((_ctx.locale === 'ar') && (_ctx.reactiveData.actions=='update'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.UpdateWareCustomerDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale !== 'ar') && (_ctx.reactiveData.actions=='update'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.UpdateWareCustomerDrawer')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, [
            _createVNode(_component_a_space, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.onSubmitUpdate(_ctx.form.id)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_button, {
                  type: "primary",
                  danger: "",
                  onClick: _ctx.hideForm
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ], 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "first_name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Pos.FisrtName')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.first_name,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.first_name) = $event)),
                            placeholder: _ctx.t('Pos.FisrtName')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "last_name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.LastName')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.last_name,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.last_name) = $event)),
                            placeholder: _ctx.t('Pos.LastName')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "email" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.Email')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.email,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.email) = $event)),
                            placeholder: _ctx.t('Pos.Email')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "phone" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.Phone')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.phone,
                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.phone) = $event)),
                            placeholder: _ctx.t('Pos.Phone')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "address" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Pos.Address')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.address,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.address) = $event)),
                            placeholder: _ctx.t('Pos.Address')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "company_name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Pos.CompanyName')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.company_name,
                            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.company_name) = $event)),
                            placeholder: _ctx.t('Pos.CompanyName')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "vat_id" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('Pos.VATNumber')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.vat_id,
                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.vat_id) = $event)),
                            placeholder: _ctx.t('Pos.VATNumber')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "contact_name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Pos.ContactName')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.contact_name,
                            "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.contact_name) = $event)),
                            placeholder: _ctx.t('Pos.ContactName')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "billing_address" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Pos.BillingAddress')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.billing_address,
                            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.billing_address) = $event)),
                            placeholder: _ctx.t('Pos.BillingAddress')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "shipping_address" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Pos.ShippingAddress')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.shipping_address,
                            "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.shipping_address) = $event)),
                            placeholder: _ctx.t('Pos.ShippingAddress')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "default" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_checkbox, {
                            checked: _ctx.form.default,
                            "onUpdate:checked": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.default) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('Pos.CustomerFreeOrder')), 1)
                            ]),
                            _: 1
                          }, 8, ["checked"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange", "onClose"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px'},
        onAfterOpenChange: _ctx.afterOpenShowChange
      }, {
        title: _withCtx(() => [
          (_ctx.locale==='ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.DetailsCustomerDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale!=='ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.DetailsCustomerDrawer')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => []),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.t('Pos.GeneralInformations')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Pos.FisrtName')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.first_name), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Pos.LastName')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.last_name), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('Pos.Email')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.email), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('Pos.Phone')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.phone), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Pos.ContactName')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.contact_name), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('Pos.VATNumber')) + ": ", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.vat_id), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t('Pos.Address')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.address), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.t('Pos.BillingAddress')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.billing_address), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.t('Pos.ShippingAddress')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.shipping_address), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.t('Pos.CustomerFreeOrder')) + " : ", 1),
                        (_ctx.form.StringDefault==='yes')
                          ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                              key: 0,
                              "two-tone-color": "#52c41a"
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.form.StringDefault==='no')
                          ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                              key: 1,
                              "two-tone-color": "#ff4d4f"
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openAdd,
        "onUpdate:open": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.openAdd) = $event)),
        onClose: _ctx.resetAdd,
        class: "custom-class",
        "root-class-name": "root-class-name",
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px'},
        onAfterOpenChange: _ctx.afterOpenAddChange
      }, {
        title: _withCtx(() => [
          ((_ctx.locale === 'ar') && (_ctx.reactiveData.actions=='add'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.AddCustomerDrawer')), 1)
              ]))
            : _createCommentVNode("", true),
          ((_ctx.locale !== 'ar') && (_ctx.reactiveData.actions=='add'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.AddCustomerDrawer')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, [
            _createVNode(_component_a_space, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.onSubmitAdd
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_button, {
                  type: "primary",
                  danger: "",
                  onClick: _ctx.resetAdd
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ], 2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "addFormRef",
            model: _ctx.addForm,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "first_name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('Pos.FisrtName')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.first_name,
                            "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.addForm.first_name) = $event)),
                            placeholder: _ctx.t('Pos.FisrtName')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "last_name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.t('Pos.LastName')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.last_name,
                            "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.addForm.last_name) = $event)),
                            placeholder: _ctx.t('Pos.LastName')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "email" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.t('Pos.Email')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.email,
                            "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.addForm.email) = $event)),
                            placeholder: _ctx.t('Pos.Email')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "phone" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.t('Pos.Phone')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.phone,
                            "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.addForm.phone) = $event)),
                            placeholder: _ctx.t('Pos.Phone')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "address" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.t('Pos.Address')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.address,
                            "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.addForm.address) = $event)),
                            placeholder: _ctx.t('Pos.Address')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "company_name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.t('Pos.CompanyName')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.company_name,
                            "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.addForm.company_name) = $event)),
                            placeholder: _ctx.t('Pos.CompanyName')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "vat_id" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.t('Pos.VATNumber')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.vat_id,
                            "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.addForm.vat_id) = $event)),
                            placeholder: _ctx.t('Pos.VATNumber')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "contact_name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.t('Pos.ContactName')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.contact_name,
                            "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.addForm.contact_name) = $event)),
                            placeholder: _ctx.t('Pos.ContactName')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "billing_address" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.t('Pos.BillingAddress')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.billing_address,
                            "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.addForm.billing_address) = $event)),
                            placeholder: _ctx.t('Pos.BillingAddress')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "shipping_address" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.t('Pos.ShippingAddress')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.shipping_address,
                            "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.addForm.shipping_address) = $event)),
                            placeholder: _ctx.t('Pos.ShippingAddress')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "default" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_checkbox, {
                            checked: _ctx.addForm.default,
                            "onUpdate:checked": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.addForm.default) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('Pos.CustomerFreeOrder')), 1)
                            ]),
                            _: 1
                          }, 8, ["checked"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "onClose", "placement", "width", "onAfterOpenChange"])
    ])
  ], 64))
}