<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Home") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ t("Pos.Settings") }}</a-breadcrumb-item>

      <a-breadcrumb-item> {{ t("Pos.checkSlips") }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer">
      {{ t("Pos.Add") }}
    </a-button>
  </div>

  <div>
    <a-table
      :columns="columns"
      :pagination="false"
      class="table-container"
    ></a-table>
  </div>

  <!-- add drawer -->
  <a-drawer
    v-model:open="openAdd"
    class="custom-class"
    root-class-name="root-class-name"
    :placement="locale === 'ar' ? 'left' : 'right'"
    :width="modalWidth"
    @close="resetFormAdd"
  >
    <template #title>
      <p :class="{ 'is-rtl': textDirection === 'rtl' }">
        {{ t("Pos.AddCheckRegister") }}
      </p>
    </template>

    <a-form
      ref="addFormRef"
      :model="addForm"
      :rules="rules"
      layout="vertical"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item name="bankName">
            <template #label>
              <span class="label-form">{{ t("Pos.bankName") }}</span>
            </template>
            <a-input
              v-model:value="addForm.bankName"
              :placeholder="t('Pos.bankName')"
              class="input-form"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="num">
            <template #label>
              <span class="label-form">{{ t("Pos.chequeSlipNumber") }}</span>
            </template>
            <a-input
              v-model:value="addForm.num"
              :placeholder="t('Pos.chequeSlipNumber')"
              class="input-form"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-item name="handInDate">
            <template #label>
              <span class="label-form">{{ t("Pos.hand-inDate") }}</span>
            </template>
            <a-date-picker
              :placeholder="t('Pos.hand-inDate')"
              v-model:value="addForm.handInDate"
              class="range-picker"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="24">
          <a-form-item name="photo">
            <template #label>
              <span class="label-form">{{ t("Pos.Photo") }}</span>
            </template>

            <a-upload-dragger
              v-model:fileList="fileList"
              name="file"
              :multiple="true"
              :max-count="10"
              @change="handleChange"
              @drop="handleDrop"
              :before-upload="beforeUpload"
              accept=".jpg,.jpeg,.png"

            >
              <p class="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p class="ant-upload-text">{{ t("Pos.dropTitle1") }}</p>
            </a-upload-dragger>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <template #footer>
      <a-space>
        <a-button type="primary" @click="add">{{ t("Pos.Add") }}</a-button>
        <a-button type="primary" danger @click="resetFormAdd">{{
          t("Pos.Cancel")
        }}</a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script  lang="ts">
import { useI18n } from "vue-i18n";
import { ref, defineComponent, reactive, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import type { Rule } from "ant-design-vue/es/form";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import dayjs, { Dayjs } from "dayjs";
import { notification, UploadChangeParam, UploadFile } from "ant-design-vue";
import Swal from "sweetalert2";
import { Empty } from "ant-design-vue";
import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  InboxOutlined,
} from "@ant-design/icons-vue";
export default defineComponent({
  name: "registerIndex",
  components: {
    LbrxLoaderSpinner,
    InboxOutlined,
  },
  setup() {
    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    const modalWidth = ref(720);

    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };

    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const loading = ref(false);

    const { t } = useI18n();

    const searchInput = ref<HTMLInputElement | null>(null);

    const state = reactive({
      searchText: "",
      searchedColumn: "",
      data: [] as any[],
      totalPages: 0,
      searchNumber: "",
      beginSearch: false,
    });

    const router = useRouter();

    const navigatePage = (link: string) => {
      router.push(link);
    };

    const columns = [
      {
        title: t("Pos.bankName"),
        key: "bank_name",
        dataIndex: "bank_name",
      },
      {
        title: t("Pos.chequeSlipNumber"),
        key: "number",
        dataIndex: "number",
      },
      {
        title: t("Pos.Date"),
        key: "date",
        dataIndex: "date",
      },
      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      },
    ];

    const addFormRef = ref();

    const fileList = ref([]);
    const addForm = reactive({
      id: "",
      handInDate: "",
      bankName: "",
      num: "",
      photos: fileList,
    });

    const resetFormAdd = () => {
      addFormRef.value.resetFields();
    };

    const rules: Record<string, Rule[]> = {
      handInDate: [{ required: true, message: t("Pos.RequiredField") }],
      bankName: [{ required: true, message: t("Pos.RequiredField") }],
      num: [{ required: true, message: t("Pos.RequiredField") }],

    };

    //add drawer
    const openAdd = ref<boolean>(false);

    const showAddDrawer = () => {
      updateModalWidth();
      openAdd.value = true;
    };

    //file

    const beforeUpload = () => {
      return false;
    };
    const fileInfo = ref<UploadFile | null>(null);

    const handleChange = (info: UploadChangeParam) => {
      fileInfo.value = info.file;
    };

    function handleDrop(e: DragEvent) {
      console.log(e);
    }

    const add = () => {
      addFormRef.value
        .validate()
        .then(() => {
          console.log("added photos", fileInfo.value);
        })
        .catch((error: any) => {
          console.log("error validation", error);
        });
    };

    return {
      locale,
      modalWidth,
      simpleImage,
      loading,
      t,
      state,
      navigatePage,
      columns,
      openAdd,
      showAddDrawer,
      addFormRef,
      addForm,
      resetFormAdd,
      textDirection,
      rules,
      add,
      fileList,
      beforeUpload,
      handleChange,
      handleDrop,
    };
  },
});
</script>


<style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.breadcrumb-icon {
  cursor: pointer;
}
.input-form {
  color: black;
  background-color: #fff;
}
.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.range-picker {
  width: 100%;
}

.ingredient-des {
  margin-bottom: 15px;
}

.search-radio-grp {
  display: flex;
  flex-direction: column !important;
}

.radio-item {
  margin-bottom: 10px;
}

/* CSS for RTL */
.is-rtl {
  direction: rtl;
}

.paginationArrows {
  direction: ltr;
}

.table-container {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
    margin-top: 10px;
  }
}
</style>