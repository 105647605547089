<template>
    <a-row :gutter="16">
        <!-- <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 8}" :lg="{ span: 8 }" :xl="{ span: 8 }" :xxl="{ span: 8 }" class="card-container">

            <a-card>
                <a-row>
                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="card-container">
                        <a-input placeholder="Search Table" >
                            <template #prefix>
                                <SearchOutlined class="search-icon" />
                            </template>
                        </a-input>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col v-for="table in state.tables" :key="table.id" :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }">
                        <a-card class="table-card">
                           <a-row>
                                <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }"></a-col>
                           </a-row>
                        </a-card>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }">
                        <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"  :default-page-size="perPage" @change="handlePageChange" :showSizeChanger=false direction="ltr" />

                    </a-col>
                </a-row>
            </a-card>
        </a-col> -->
        <a-col :xs="{ span: 24}" :sm="{ span: 24}"  :md="{span: 24}" :lg="{ span: 24 }" :xl="{ span: 24 }" :xxl="{ span: 24 }" class="card-container">
            <a-card
                class="tables-card-container"
                :tab-list="tableLocations"
                :active-tab-key="locationKey"
                @tabChange="key => onTabChange(key, 'locationKey')"

            >

                <div class="tbls-container">
                    <div v-for="table in state.tables" :key="table.id" 
                        :class="['table', table.shape === 'round' ? 'round' : 'square']"
                        
                        :style="{ left: table.x + 'px', top: table.y + 'px' }"
                        @mousedown="startDragging($event, table)"
                    >
                      <div :class="['table-status', table.status.toLowerCase()]">{{ table.status }}</div>
                      <div class="table-number">
                        <span>{{ table.number }}</span>
                      </div>

                    </div>
                </div>

              <template #tabBarExtraContent>
                <a-tooltip>
                  <template #title>{{t('Pos.SaveLocation')}}</template>
                    <SaveOutlined class="save-icon"/>
                </a-tooltip>
                
              </template>
                
            </a-card>

        </a-col>
    </a-row>
  

</template>

<script lang="ts">
import { useI18n } from "vue-i18n";

import {
  ref,
  defineComponent,
  reactive,
  onMounted,
  computed,
  watch,
  Ref,
  onUnmounted,
} from "vue";
import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  SaveOutlined
} from "@ant-design/icons-vue";
import { Empty } from "ant-design-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import type { Rule } from "ant-design-vue/es/form";
import { notification } from "ant-design-vue";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { tableLocationService, tablesService, userService } from "@/_services";
import dayjs , { Dayjs }from 'dayjs'; 
import { VueDraggableNext } from 'vue-draggable-next'

export default defineComponent({
  name: "delivaryNoteIndex",
  components: {
    //SearchOutlined
    //draggable: VueDraggableNext,
    SaveOutlined,

  },
  setup() {
    const modalWidth = ref(720);
    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const loading = ref(false);
    const { t } = useI18n();

    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    interface Table {
        id: number;
        name: string;
        shape: string;
        x: number;
        y: number;
    }

    const state = reactive({
        locations:[] as any[],
        data: [] as any[],
        tables: [] as Table[],

        totalPages:'',
        offset:{ x: 0, y: 0 },
        isDragging:false,
        currentTable: null as Table | null,


    })


    const addFormRef = ref();
    const addForm = reactive({
      id:'',
      number: '',
      seating_capacity: '',
      location: '',
      status: '',
      shape:''
    });

    
    const rules: Record<string, Rule[]> = {
      number: [{ required: true, message:  t('Pos.RequiredField') }],
      seating_capacity: [
        { required: true, message:  t('Pos.RequiredField') },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
              return Promise.resolve();
            }
            return Promise.reject(t('Pos.numericField'));
          }
        }

        ],
      location: [{ required: true, message:  t('Pos.RequiredField') }],
      status: [{ required: true, message:  t('Pos.RequiredField') }],
      shape:[{ required: true, message:  t('Pos.RequiredField') }],
    };

    //pagination
    const perPage = ref<number>(7);
    const currentPage = ref<number>(1);

    const handlePageChange = (newPage:any, pageSize:number) => {
        currentPage.value = newPage;
        perPage.value=pageSize;
        tablesService.getAllTables(perPage.value , currentPage.value).then((res)=>{
            state.tables = res.data.map((table: any) => ({
                ...table,
                x: 0,  
                y: 0,  
            }));
            state.totalPages=res.meta.total;
        }).catch((error)=>{
            console.log('error getting tables', error);
        });
    }


    const getTables=()=>{
        tablesService.getAllTables( perPage.value , currentPage.value).then((res)=>{
            state.tables=res.data;
            state.totalPages=res.meta.total;
        }).catch((error)=>{
            console.log('error getting tables', error);
        });
    }

    
    //tabs

    interface TableLocation{
        key:string, 
        tab:string,
    }
    const tableLocations = ref<TableLocation[]>([]);

    const locationKey =ref<string | undefined >(undefined);

    const getTablesByLocation =(locationId:string)=>{
        tablesService.filter('table_location_id', locationId).then((res)=>{
          state.tables = res.data.map((table: any) => ({
            ...table,
            x: 0,
            y: 0,
          }));
        }).catch((error)=>{
            console.log('error filter tables with location' , error)
        })

    }

    const getTablesByLocations=()=>{
        tableLocationService.getAll().then((res) => {
          

            //tableLocations = res.data.map((location:any) => location.name); 
            tableLocations.value = res.data.map((location: any) => ({
                key: location.id, 
                tab: location.name,
            }));

            if (tableLocations.value.length > 0) {
                locationKey.value = tableLocations.value[0].key.toString();
                getTablesByLocation(locationKey.value);
            }

            
           
            console.log('Tables by Locations:', tableLocations.value);
        }).catch((error) => {
            console.error('Error getting table locations:', error);
        });
    }

    const onTabChange=(tab: string)=>{
        console.log('changed tab',tab);
        locationKey.value = tab.toString();
        getTablesByLocation(locationKey.value);

    }


    //test 
   
    const startDragging = (event: any, table: any) => {
     console.log('start dragging')
      state.isDragging = true;
      state.currentTable = table;
      state.offset = {
        x: event.clientX - table.x,
        y: event.clientY - table.y,
      };

       document.addEventListener('mousemove', dragTable);
       document.addEventListener('mouseup', stopDragging);

    }

    const stopDragging = () => {
      state.isDragging = false;
      state.currentTable = null;
      document.removeEventListener('mousemove', dragTable);
      document.removeEventListener('mouseup', stopDragging);
    }

    const dragTable = ( event: any) => {

      if (state.isDragging && state.currentTable) {
        const table = state.currentTable;

        console.log('current table', table);

        table.x = event.clientX - state.offset.x;
        table.y = event.clientY - state.offset.y;
      }
    }









    //draggable
    const handleTableChange = (event: any) => {
      // Check if the change is due to a table being dropped
      if (event.moved) {
        const movedTable = state.tables[event.moved.newIndex];

        // If a table is moved to an empty area
        if (!movedTable) {
          // Remove the table from its previous location
          state.tables.splice(event.moved.oldIndex, 1);
        }
      }
    };

    

    onMounted(()=>{
        getTablesByLocations();
    });


    //ttttessstt
    // let x = 0;

    // window.onload = function(){
    //     var ressource = document.getElementById("ressource");
    //     setInterval(function(){
    //         x++;
    //         ressource!.style.left=x.toString( );

    //     }, 10);
    // }


  


    return{




        t,
        locale,
        textDirection,
        state,
        perPage,
        currentPage,
        handlePageChange,
        tableLocations,
        locationKey,
        onTabChange,
        handleTableChange,

        startDragging,
        stopDragging,
        dragTable,





    }
  }
})

</script>

<style scoped>

.card-container{
    margin-bottom: 10px;
}

.table-card{
    margin-top: 10px;
    margin-bottom: 10px;
}

.tables-card-container{
    height: 100%;
    width: 100%;
}

.tables-container{
 display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 25px;

  padding: 10px;
 
}

.table {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fc8019;
  background-color:#fff5e670;

  width: 80px;
  height: 80px;

  cursor: move;

  position: absolute;
  left: 0px;
  top: 0px;




}



/* .table {
  position: absolute;
  cursor: move;
  border: 1px solid #fc8019;
  background-color: #fff5e670;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.round {
  border-radius: 50%;
}

.square {
  border-radius: 10px;
}

.table-number {
    padding: 2px 11px;
    margin-top: 70px;
    background-color: #fff5e6;
    border: 1px solid #fc8019;
    border-radius: 5px;
}

.table-number span{
    font-size: 12px;
}

.table-status {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.table-status.available {
  color: green;
}

.table-status.occupied {
  color: red;
}

.table-number {
  background-color: #fff5e6;
  border: 1px solid #fc8019;
  border-radius: 5px;
  /* padding: 2px 5px; */
  margin-top: auto;
  font-size: 12px;
  width: auto;
  text-align: center;
  position: absolute;
  bottom: -10px;
}

.save-icon{
  font-size: 20px;
  color: #fc8019;
}


/*test */

.tbls-container {
  position: relative;
  height: 500px; 
  width: 100%;
  background-color: #f0f0f0;
}


/* Responsive Styles */
@media (max-width: 600px) {
  .table {
    width: 60px;
    height: 60px;
    font-size: 12px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .table {
    width: 70px;
    height: 70px;
    font-size: 14px;
  }
}


</style>