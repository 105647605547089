<template>

    <div >
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
           {{t('Pos.Dashboard')}}
        </a-breadcrumb-item>
            <a-breadcrumb-item> {{t('Pos.Ingredients')}}</a-breadcrumb-item>
            <a-breadcrumb-item @click="navigatePage('/ingredient-categories')"  class="breadcrumb-icon">{{t('Pos.Categories')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>
    
    <div class="btn-container">
        <a-button class="add-btn"  @click="showAddDrawer">
            {{ t('Pos.Add') }}
        </a-button>
    </div>

    <div>
        <a-table
            class="table-container"
            :columns="columns"
            :data-source="state.data"
            :pagination="false">   

            <template #emptyText>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
            </template>

            <template #bodyCell="{ column, record }">
                <template v-if="column.key=='actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>{{ t('Pos.View') }}</template>
                            <a-button class="view-btn"  @click="() => showDisplayDrawer(record)">
                            <template #icon>
                                <EyeOutlined/>
                            </template>
                            </a-button>
                        </a-tooltip>
                        
                        <a-tooltip>
                            <template #title>{{ t('Pos.Update') }}</template>
                            <a-button type="primary"  @click="() => showDrawer(record)">
                            <template #icon>
                                <EditOutlined/>
                            </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip>
                            <template #title>{{ t('Pos.Delete') }}</template>
                            <a-button type="primary" danger @click="showDeletePopup(record)">
                            <template #icon>
                                <DeleteOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>

                <template v-if="column.key === 'created_at' || column.key === 'updated_at'">
                    {{ formatDisplayDate(record[column.key]) }}
                </template>

                
            </template>

            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                <a-input
                    ref="searchInput"
                    :placeholder="`${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                /> 

                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
               
                    {{ t('Pos.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Pos.Reset') }}
                </a-button>
                </div>
            </template>

            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>

        </a-table>

        <div class="pagination">

            <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />
            <a-select
                class="select-input"
                v-model:value="selectPagination"
                @change="handleSelectPaginationChange"
                v-if="state.totalPages>10"
            >
                <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
            </a-select>
        </div>

        <!-- <div class="pagination" v-if="state.totalPagesSearch ===0 ">
            <a-pagination v-model:current="currentPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />
        </div>

        <div class="pagination" v-if="state.totalPagesSearch !==0 ">
           
            <a-pagination v-model:current="currentPageSearch" :total="state.totalPagesSearch" @change="handlePageChangeSearch"  :default-page-size="perPageSearch" :showSizeChanger=false />
        </div> -->

        <!-- update drawer -->
       
            <a-drawer
                v-model:open="open"
                class="custom-class"
                root-class-name="root-class-name"
               
               :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                @after-open-change="afterOpenChange"
            >

                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateCategory')}}</p>
                </template>


                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
                    <a-row :gutter="16">   
                        <a-col :span="24">
                        <a-form-item  name="name">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Name') }}</span>
                            </template>
                            <a-input  v-model:value="form.name" :placeholder=" t('Pos.Name')" class="input-form"/>
                        </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="24">
                        <a-form-item  name="description">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Description') }}</span>
                            </template>
                                <a-textarea v-model:value="form.description" :rows="4" :placeholder="t('Pos.Description')" />
                        </a-form-item>
                        </a-col>
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item  name="created_at">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.CreatedAt') }} </span>
                                </template>
                                <a-date-picker show-time :placeholder="t('Pos.CreatedAt')" class="date-picker" disabled :value="formatDate(form.created_at)">
                                </a-date-picker>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item  name="updated_at">

                                <template #label>
                                <span class="label-form">{{ t('Pos.UpdatedAt') }}</span>
                                </template>

                                <a-date-picker show-time :placeholder="t('Pos.UpdatedAt')" class="date-picker" disabled :value="formatDate(form.updated_at)">
                                </a-date-picker>
                            
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
                <template #footer>
                    <a-space :class="{ 'is-rtl': textDirection==='rtl'}">
                        <a-button  type="primary" @click="update(form.id)">{{ t('Pos.Update') }}</a-button>
                        <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                </template>

            </a-drawer>
        <!-- update drawer -->

        <!-- display drawer -->

            <a-drawer
                v-model:open="openDisplay"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width=modalWidth
                @after-open-change="afterOpenShowChange"
            >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.CategoryInfo')}}</p>
            </template>


            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                <a-row :gutter="16">

                    <a-col :span="16">
                    <p><span class="info-title">{{t('Pos.Name')}}  :</span> {{form.name}}</p>
                    </a-col>

                    <a-col :span="16">
                    <p><span class="info-title">{{t('Pos.Description')}} :</span> {{form.description}}</p>
                    </a-col>

                </a-row>

          
             

                <a-row :gutter="16">

                    <a-col :span="12">
                    <p><span class="info-title">{{t('Pos.CreatedAt')}} :</span> {{formatDateDrawer(form.created_at)}}</p>
                    </a-col>

                    <a-col :span="12">
                    <p><span class="info-title">{{t('Pos.UpdatedAt')}} :</span> {{formatDateDrawer(form.updated_at)}}</p>
                    </a-col>

                </a-row>
  
            </a-form>
            <template #footer>
                <a-space>
                    <a-button type="primary" danger @click="cancelUpdate">{{t('Pos.Cancel')}}</a-button>
                </a-space>
            </template>

            </a-drawer>
        <!-- display drawer -->


        <!-- add drawer -->
        <a-drawer
            v-model:open="openAdd"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width=modalWidth
            @close="resetFormAdd"
        >

            <template #title>
                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddCategory')}}</p>
            </template>

            <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
                <a-row :gutter="16">   
                    <a-col :span="24">
                    <a-form-item  name="name">
                        <template #label>
                        <span class="label-form">{{ t('Pos.Name') }}</span>
                        </template>
                        <a-input  v-model:value="addForm.name" :placeholder="t('Pos.Name')" class="input-form"/>
                    </a-form-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24">
                    <a-form-item  name="description">
                        <template #label>
                        <span class="label-form">{{ t('Pos.Description') }}</span>
                        </template>
                        <a-textarea v-model:value="addForm.description" :rows="4" :placeholder="t('Pos.Description')" />
                    </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
            <template #footer>
            <a-space>
                <a-button  type="primary" @click="addCat">{{ t('Pos.Add') }}</a-button>
                <a-button type="primary" danger @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
            </a-space>
            </template>


        </a-drawer>
        <!-- add drawer -->
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditOutlined
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs'; 
import relativeTime from 'dayjs/plugin/relativeTime';

import {ingredientCategoriesService} from '@/_services'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import LbrxLoaderSpinner from '../../../../components/LbrxLoaderSpinner.vue';
import 'dayjs/locale/fr'; 
import 'dayjs/locale/ar'; 
import { Empty } from 'ant-design-vue';

export default defineComponent({
    name: 'indexIngredientCategories',
    components: {
        EyeOutlined,
        DeleteOutlined,
        SearchOutlined,
        EditOutlined,
        LbrxLoaderSpinner
    },
    setup() {
        const modalWidth = ref(720);
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        const { t } = useI18n();
        dayjs.extend(relativeTime);

        const router = useRouter();

        const navigatePage = (link: string) => {
            router.push(link); 
        };
        const loading = ref(false);

        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            totalPagesSearch:0,
            beginSearch:false,
            searchName:'',

        });
        const searchInput = ref<HTMLInputElement | null>(null);
        const searchContact = ref<HTMLInputElement | null>(null);

         const columns=[
            {
                title:t('Pos.Name'),
                key:'name',
                dataIndex: 'name',
                customFilterDropdown: true,
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                        if (input instanceof HTMLInputElement) {
                            searchInput.value = input;
                            searchInput.value.focus();
                        }
                        }, 100);
                    }
                },
            },
            {
                title: t('Pos.Description'),
                key:'description',
                dataIndex: 'description',
            },
            {
                title: t('Pos.CreatedAt'),
                key:'created_at',
                dataIndex: 'created_at',

            },
            
            {
                title: t('Pos.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }

        ];

        
        //form  
        const formRef = ref();
        const addFormRef = ref();


       
        const form = reactive({
            id: '',
            name: '',
            description: '',
            created_at: '',
            updated_at: '',
        });

        const addForm = reactive({
            id:'',
            name: '',
            description: '',
            created_at: '',
            updated_at: '',
        });

        const resetForm = () => {
            form.id = '';
            form.name = '';
            form.description = '';
            form.created_at = '';
            form.updated_at = '';
        }
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');
        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t('Pos.RequiredField')  }],
        };

        const formatDate = (dateString: string) => {
            return dayjs(dateString);
        }
        const lang=localStorage.getItem('Language')|| 'en';
        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).locale(lang).fromNow();
            
        }

        const filter=(key:string, value:string)=>{
            currentPage.value=1;
            loading.value=true;
            ingredientCategoriesService.filterWithPagination(key,value, currentPage.value.toString(), perPage.value.toString()).then((res)=>{
                state.data=res.data;
                state.totalPages= res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((error)=>{
                console.log('error fetching', error);
            });
        }

        //search
        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'name' && state.searchText!=='') {
                state.searchName=state.searchText;
                filter("name",state.searchText );
            }
        };

        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.searchName='';
            state.beginSearch=false;
            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';

            state.totalPagesSearch=0;
            getAll();


           
        };

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

        //drawer

        const open = ref<boolean>(false);

        const showAddDrawer = (state: boolean) => {
            openAdd.value = true;
            resetForm();
        };

        const handleSelectPaginationChange=(selectedItem:any)=>{
            console.log('selected Per page:', selectedItem);
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                if(state.searchName!=''){
                    loading.value = true;
                    ingredientCategoriesService.filterWithPagination("name",state.searchName,currentPage.value.toString(),perPage.value.toString()).then((res)=>{
                        state.data=res.data;
                        state.totalPages = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value = true;
                ingredientCategoriesService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api : get products", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

        
        }   



        
        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){

                if(state.searchName!=''){
                loading.value = true;
                ingredientCategoriesService.filterWithPagination("name",state.searchName,currentPage.value.toString(),perPage.value.toString()).then((res)=>{
                    state.data=res.data;
                    state.totalPages=res.meta.total;
                }).catch((err:any) => {
                    console.log(err)
                }).finally(()=>{
                    loading.value = false;
                });
                }



            }else{
                loading.value = true;
                ingredientCategoriesService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api : get products", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

        
        }



        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showDrawer = (record: any) => {
            updateModalWidth();
            open.value = true;
            getById(record.id);
        };

        const openAdd = ref<boolean>(false);

        const afterOpenAddChange = (bool: boolean) => {
            console.log('open', bool);
        };


        const showDisplayDrawer = (record: any) => {
            updateModalWidth();
            openDisplay.value = true;
            getById(record.id);
          
        };

        
        const resetFormAdd = () => {
            console.log("begin rest");
            addFormRef.value.resetFields();
        };

        const cancelUpdate=()=>{
            form.name="";
            form.description="";
            open.value=false; 
            openAdd.value=false;
            openDisplay.value=false;

        }

        const getAll = ()=>{
            loading.value = true;
            ingredientCategoriesService.getAllWithPagination(currentPage.value,perPage.value).then((response)=>{
                console.log("getting ingredient categories", response.data);
                state.data=response.data;
                state.totalPages = response.meta.total;
            }).catch((error)=>{
                console.log("error get ingredient categorie", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const addCat =()=>{
            let payload ={
               name: addForm.name,
               description :addForm.description,
            }
            console.log(payload);
          
            addFormRef.value
            .validate()
            .then(()=>{
                loading.value = true;
                ingredientCategoriesService.create(payload)
                .then((response)=>{
                    
                    //state.data.push(response.data);
                    getAll();


                    showSuccessNotification(t('Pos.AddCategorySuccess'));
                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    loading.value = false;
                    openAdd.value=false;
                    resetFormAdd();

                }); 
            }).catch((error:any) => {
                console.error('Validation error:', error);
            });
        }

        const getById = (id: string) => {
            ingredientCategoriesService.show(id)
                .then(res => {
                    form.id = res.data.id;
                    form.name = res.data.name;
                    form.description = res.data.description;
                    form.created_at = res.data.created_at;
                    form.updated_at = res.data.updated_at;
                })
                .catch(error => {
                    console.error('Error fetching ingredient categories:', error);
                });
        }

        const update =(id:string)=>{
            console.log('id', id);
            formRef.value
            .validate()
            .then(()=>{
                console.log('form', toRaw(form));
                loading.value = true;
                ingredientCategoriesService.update(id, toRaw(form)).then((response)=>{
                    
                    state.data = state.data.map(el => el.id !== id ? el : response.data);
                    showSuccessNotification(t('Pos.UpdateCategorySuccess'));

                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    console.log('finish');
                    loading.value = false;
                    open.value=false;
                }); 
            })
        }

        const deleteCat = (id: string) => {
            console.log('id', id);
            ingredientCategoriesService.delete(id).then((res) => {
                state.data = state.data.filter(e => e.id !== id);
                showSuccessNotification(t('Pos.DeleteCategorySuccess'));

            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                console.log('finish')
            })
        };

        const openDisplay = ref<boolean>(false);
        const afterOpenShowChange = (bool: boolean) => {
        console.log('open', bool);
};


        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeletecategory'),
                text: t('Pos.TextDeletecategory'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t('Pos.Delete'),
                cancelButtonColor: "#d33",
                cancelButtonText: t('Pos.Cancel'),
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteCat(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const formatDateDrawer=(dateString:string)=>{
            return dayjs(dateString).format('YYYY-MM-DD HH:mm:ss');
        }

        onMounted(() => {
            getAll();
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
            searchContact.value = document.querySelector('.ant-table-filter-dropdown input');
        });

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );


        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
        };

        return{
            modalWidth,
            updateModalWidth,
            t ,
            textDirection,
            perPage,
            currentPage,
            openDisplay,
            afterOpenShowChange,
            showDisplayDrawer,
            state,
            columns,
            formRef,
            addFormRef,
            form,
            addForm,
            resetForm,
            rules,
            open,
            showAddDrawer,
            afterOpenChange,
            showDrawer,
            openAdd,
            afterOpenAddChange,
            resetFormAdd,
            cancelUpdate,
            formatDate,
            formatDisplayDate,
            handleSearch,
            handleReset,
            showSuccessNotification,
            addCat,
            update,
            showDeletePopup,
            navigatePage,
            handlePageChange,
            loading,
            formatDateDrawer,
            selectPagination,
            handleSelectPaginationChange,
            simpleImage,
            locale,
            
            

        };
    },
})
</script>
<style scoped>
.add-btn {
    color: #fc8019;
    border: 2px solid #fc8019;
    box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}

.label-form {
    font-size: 14px;
    font-weight: 600;
}


.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
  }
  
.date-picker {
    width: 100%
}

.breadcrumb-icon{
  cursor: pointer;
}

.view-btn{
    color: #fc8019;
    border-color: #fc8019;
    background: #fafafa;
  }
  
.pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}


.paginationArrows {
    direction:ltr;
    margin-bottom: 16px;

}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-btn:hover{
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
}

  /* CSS for RTL */
  .is-rtl {
    direction:rtl;
  }

  .table-container {
    overflow-x: auto;
}


@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
  }
}

</style>
