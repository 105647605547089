import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const ingredientsService = {
    getAll,
    show,
    create,
    update,
    delete: _delete,
    getIngredientsPaginate,
    createIngredient,
    filterWithPagination,
    addIngredient,
    updateIngredient,
    filterWithoutPagination,
};

function getIngredientsPaginate(perPage:number,currentPage:number){
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }, 
        credentials : 'include' as RequestCredentials,
    }; 
    
    return fetch(`${ApiConfigs.base_url+ApiConfigs.ingredients.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions)
    .then(
        handleResponse
    );
}
function getAll() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }, 
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.ingredients.getAll}?pagination=off`, requestOptions)
        .then(
            handleResponse
        );
}

function createIngredient(formData:FormData){
    const requestOptions = {
        method: "POST",
        headers: {  "Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: formData,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.ingredients.add}`, requestOptions)
        .then(
            handleResponse
        );

}

function addIngredient(name:string, has_price:any, default_price:string,ingredient_category_id:string,
    custom_data:any,unit:string, photo:any  ){

    const formData = new FormData();

    formData.append('name', name);
    formData.append('has_price', has_price);
    formData.append('default_price', default_price);
    formData.append('ingredient_category_id', ingredient_category_id);
    formData.append('custom_data', custom_data);
    formData.append('unit', unit);
    if(photo!==null){
        formData.append('photo', photo);
    }
    

    

    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Accept": "application/json" },
        body: formData
    };
    
    return fetch(`${ApiConfigs.base_url + ApiConfigs.ingredients.add}`, requestOptions)
        .then(
            handleResponse
        );

    
}

function create(payload:object ){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.ingredients.add}`, requestOptions)
        .then(
            handleResponse
        );
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.ingredients.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function update(id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.ingredients.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function updateIngredient(id: string, name: string, photo:any, has_price:any,
    default_price:string, ingredient_category_id:string, unit :string) {
    const formData = new FormData();

    formData.append('name', name);
    
    formData.append('has_price', has_price);
    formData.append('default_price', default_price);
    formData.append('ingredient_category_id', ingredient_category_id);
    formData.append('unit', unit);

    if(photo!==null){
        formData.append('photo', photo);
    }

    

    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'POST',
        headers:{ "Accept": "application/json" },
        body: formData
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.ingredients.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function _delete(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.ingredients.delete.replace(':id', id)}`, requestOptions).then(handleResponse);
}


function filterWithPagination(key:string, value:string, page:string, per_page:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.ingredients.getAll}?keys=${key}&values=${value}&page=${page}&per_page=${per_page}`, requestOptions).then(handleResponse);

}



function filterWithoutPagination(key:string, value:string) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.ingredients.getAll}?keys=${key}&values=${value}`, requestOptions).then(handleResponse);

}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
