import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-108fe582"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = { class: "label-form" }
const _hoisted_5 = { class: "label-form" }
const _hoisted_6 = { class: "label-form" }
const _hoisted_7 = { class: "info-title" }
const _hoisted_8 = { class: "info-title" }
const _hoisted_9 = { class: "info-title" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_InboxOutlined = _resolveComponent("InboxOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Manufacturer')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _ctx.showAddDrawer
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_InboxOutlined)
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')) + " ", 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        columns: _ctx.columns,
        "data-source": _ctx.state.data,
        pagination: false,
        class: "table-container"
      }, {
        emptyText: _withCtx(() => [
          _createVNode(_component_a_empty, {
            description: _ctx.t('Pos.NoData'),
            image: _ctx.simpleImage
          }, null, 8, ["description", "image"])
        ]),
        bodyCell: _withCtx(({ column, record }) => [
          (column.key=='actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        onClick: () => _ctx.showDisplayDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Manufacturer')) + _toDisplayString(_ctx.t('Pos.Edit')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key === 'created_at' || column.key === 'updated_at')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatDisplayDate(record[column.key])), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_input, {
              ref: "searchInput",
              placeholder: `${column.title}`,
              value: selectedKeys[0],
              style: {"width":"188px","margin-bottom":"8px","display":"block"},
              onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
              onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_pagination, {
          class: "paginationArrows",
          current: _ctx.currentPage,
          "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
          pageSize: _ctx.perPage,
          "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.perPage) = $event)),
          total: _ctx.state.totalPages,
          onChange: _ctx.handlePageChange,
          "default-page-size": _ctx.perPage,
          showSizeChanger: false
        }, null, 8, ["current", "pageSize", "total", "onChange", "default-page-size"]),
        (_ctx.state.totalPages>10)
          ? (_openBlock(), _createBlock(_component_a_select, {
              key: 0,
              value: _ctx.selectPagination,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectPagination) = $event)),
              onChange: _ctx.handleSelectPaginationChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select_option, { value: "10" }, {
                  default: _withCtx(() => [
                    _createTextVNode("10 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "20" }, {
                  default: _withCtx(() => [
                    _createTextVNode("20 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "50" }, {
                  default: _withCtx(() => [
                    _createTextVNode("50 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "100" }, {
                  default: _withCtx(() => [
                    _createTextVNode("100 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "onChange"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterOpenChange
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.UpdateManufacturer')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateManufacturer(_ctx.form.id)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.cancelUpdate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.name,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "contact_info" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('Pos.ContactInfo')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.contact_info,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.contact_info) = $event)),
                            placeholder: _ctx.t('Pos.ContactInfo'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "created_at" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Pos.CreatedAt')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_date_picker, {
                            "show-time": "",
                            placeholder: _ctx.t('Pos.CreatedAt'),
                            class: "date-picker",
                            disabled: "",
                            value: _ctx.formatDate(_ctx.form.created_at)
                          }, null, 8, ["placeholder", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterOpenShowChange
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.ManufacturerInfo')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.cancelUpdate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.Name')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.name), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.ContactInfo')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.contact_info), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.CreatedAt')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.formatDateDrawer(_ctx.form.created_at)), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openAdd,
        "onUpdate:open": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.openAdd) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onClose: _ctx.resetFormAdd
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.AddManufacturer')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _ctx.addManufacturer
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.resetFormAdd
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "addFormRef",
            model: _ctx.addForm,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.name,
                            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.addForm.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "contact_info" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Pos.ContactInfo')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.contact_info,
                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addForm.contact_info) = $event)),
                            placeholder: _ctx.t('Pos.ContactInfo'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onClose"])
    ])
  ], 64))
}