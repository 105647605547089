export function authHeader(): { [key: string]: string } {
    const user = JSON.parse(localStorage.getItem('user') || 'null');

    if (user && user.token) {
        return {
            'Authorization': 'Bearer ' + user.token,
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Language': localStorage.getItem('Language') || 'en'
        };
    } else {
        return {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Language': localStorage.getItem('Language') || 'en'
        };
    }
}