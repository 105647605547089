<template>

    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

  <div>
    <a-breadcrumb style="margin: 16px 0" :class="{ 'is-rtl': textDirection==='rtl'}">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t('Pos.Home') }}

      </a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.Settings')}}</a-breadcrumb-item>

      <a-breadcrumb-item>  {{ t('Pos.VatRates') }}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>
 
  
    <div class="btn-container">
        <a-button class="add-btn" @click="showAddDrawer(true)">
            {{ t('Pos.Add') }}
        </a-button>
    </div>
    <div>
        <a-table class="table-container" :columns="columns" :data-source="reactiveData.data">

            <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
                    <a-space>
              <a-tooltip>
                  <template #title> {{ t('Pos.View') }}</template>
                  <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                  <template #icon>
                      <EyeOutlined/>
                  </template>
                  </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title> {{ t('Pos.Edit') }}</template>

                <a-button type="primary" @click="() => showDrawer(record)">
                  <template #icon>
                    <EditOutlined />
                  </template>
                </a-button>

              </a-tooltip>

              <a-tooltip>
                <template #title>{{ t('Pos.Delete') }}</template>
                <a-button type="primary" danger @click="showDeletePopup(record)">
                  <template #icon>
                    <DeleteOutlined />
                  </template>
                </a-button>
              </a-tooltip>

            </a-space>
                </template>


   

      </template>

      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />

          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
           
            {{ t('Pos.Search') }}
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            {{ t('Pos.Reset') }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

        </a-table>

        <a-drawer 
            v-model:open="open"
            class="custom-class" 
            root-class-name="root-class-name" 
            :root-style="{ color: 'blue' }"
            style="color: red" 
            :title="null" 
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width="modalWidth" 
            :body-style="{ paddingBottom: '80px' }" 
            @after-open-change="afterOpenChange" 
            @close="hideForm">


            <template #title>
                <div class="drawer-header-ar" v-if="(locale === 'ar') && (reactiveData.action=='add')">
                    <span>{{ t('Pos.RatesDrawerAdd') }}</span>
                </div>
                <div class="drawer-header-all" v-if="(locale !== 'ar') && (reactiveData.action=='add')">
                    <span>{{ t('Pos.RatesDrawerAdd') }}</span>
                </div>

                <div class="drawer-header-ar" v-if="(locale === 'ar') && (reactiveData.action=='update')">
                    <span>{{ t('Pos.RatesDrawerUpdate') }}</span>
                </div>
                <div class="drawer-header-all" v-if="(locale !== 'ar') && (reactiveData.action=='update')">
                    <span>{{ t('Pos.RatesDrawerUpdate') }}</span>
                </div>
            </template>

            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item name="name">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Name') }}</span>
                            </template>
                            <a-input v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item name="rate">
                            <template #label>
                                <span class="label-form">{{ t('Pos.Rate') }}</span>
                            </template>
                            <a-input v-model:value="form.rate" :placeholder="t('Pos.Rate')" class="input-form" />
                        </a-form-item>
                    </a-col>
                </a-row>

            </a-form>

            <template #footer>
                <div :class="{ 'is-rtl': textDirection==='rtl'}">
                    <a-space v-if="reactiveData.action == 'update'">
                        <a-button type="primary" @click="onSubmitUpdate(form.id)">{{ t('Pos.Update') }}</a-button>
                        <a-button type="primary" danger @click="hideForm">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                    <a-space v-else>
                        <a-button type="primary" @click="onSubmitAdd">{{ t('Pos.Add') }}</a-button>
                        <a-button type="primary" danger @click="hideForm">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                </div>
            </template>

        </a-drawer>

        <a-drawer v-model:open="openDisplay"
            class="custom-class"
            root-class-name="root-class-name"
            :root-style="{ color: 'blue' }"
            style="color: red"
            :title="null"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width="modalWidth"
            :body-style="{ paddingBottom: '80px' }" 
            @after-open-change="afterOpenShowChange"
            @close="hideForm">

            <template #title>
                    <div class="drawer-header-ar" v-if="locale === 'ar'">
                        <span>{{ t('Pos.RatesDrawerView') }}</span>
                    </div>  
                    <div class="drawer-header-all" v-if="locale !== 'ar'">
                        <span>{{ t('Pos.RatesDrawerView') }}</span>
                    </div>      
                </template>

            <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                <a-row :gutter="16">
                    <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Name') }} :</span> {{form.name}}</p>
                    </a-col>
                    <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Rate') }} :</span> {{form.rate}}</p>
                    </a-col>
                </a-row>

            </a-form>
                
        </a-drawer>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, toRaw , computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
    EyeOutlined,
    SearchOutlined,
    DeleteOutlined,
    EditOutlined
} from '@ant-design/icons-vue';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import { notification } from 'ant-design-vue';
import { vatrateService } from '@/_services'
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';


export default defineComponent({
    name: 'indexVatrate',
    components: {
        EyeOutlined,
        LbrxLoaderSpinner,
        DeleteOutlined,
        EditOutlined,
        SearchOutlined
    },
    setup() {
        const modalWidth = ref(720);
        const loading = ref(false);
        const { locale } = useI18n();

        const router = useRouter();

        const navigatePage = (link: string) => {
        router.push(link); 
};

        const { t } = useI18n();
        const searchInput = ref<HTMLInputElement | null>(null);
        const columns = [
            {
                title: t('Pos.Name'),
                key: 'name',
                dataIndex: 'name',
                customFilterDropdown: true,
        onFilter: (value: string, record: any) => record.name.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector('.ant-table-filter-dropdown input');

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
            },
            {
                title: t('Pos.Rate'),
                key: 'rate',
                dataIndex: 'rate',
            },
            {
                title: t('Pos.Actions'),
                key: 'actions',
                dataIndex: 'actions',
            }

        ];

        //form  
        const formRef = ref();
        const visible = ref<boolean>(false);

        const reactiveData = reactive({
            data: [] as any[],
            action: '',
            searchText:'',
            searchedColumn:''
        });

        const form = reactive({
            id: '',
            name: '',
            rate: ''
        });

        const resetForm = () => {
            form.id = '';
            form.name = '';
            form.rate = '';
        }

        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t('Pos.VarRatesVerification') }],
            rate: [{ required: true, message: t('Pos.TauxVerification'), pattern: new RegExp(/^[0-9]{1,2}([,.][0-9]{1,2})?$/) }]
        };

        const formatDate = (dateString: string) => {
            return dayjs(dateString);
        }

        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
        }

        //drawer

        const open = ref<boolean>(false);

        const showAddDrawer = (state: boolean) => {
            updateModalWidth();
            open.value = state;
            reactiveData.action='add';
            resetForm();
        };

        const afterOpenChange = (bool: boolean) => {
           //console.log('open', bool);
        };

        const showDrawer = (record: any) => {
            updateModalWidth();
            open.value = true;
            getVatrateById(record.id);
            reactiveData.action = 'update';
        };

        const openAdd = ref<boolean>(false);

        const afterOpenAddChange = (bool: boolean) => {
            //console.log('open', bool);
        };

        const showSuccessNotification = (message: string) => {
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style: {
                    backgroundColor: '#bfffca8a',
                    color: 'white'
                }
            });
        };

        const onSubmitAdd = () => {

            formRef.value
                .validate()
                .then(() => {
                    addVatrate();
                })
                .catch((error: any) => {
                    //console.log('error', error);
                });
        };

        const hideForm = (bool: boolean) => {
            open.value = false;
            formRef.value.resetFields();
        };

        const onSubmitUpdate = (id: string) => {
            formRef.value
                .validate()
                .then(() => {
                    updateVatrate(id);
                })
                .catch((error: any) => {
                    //.log('error', error);
                });
        };

        const getVatrate = () => {
            loading.value = true;
            vatrateService.getAll()
                .then((res) => {
                    //console.log('vats', res.data);
                    reactiveData.data = res.data;
                })
                .catch((error)=>{
        //console.log("error api : get vatrate", error);
      })
      .finally(()=>{
        loading.value = false;
      });
    }

        const getVatrateById = (id: string) => {
            vatrateService.show(id)
                .then(res => {
                    form.name = res.data.name;
                    form.rate = res.data.rate;
                    form.id = res.data.id;
                })
                .catch(error => {
                    //console.error('Error fetching categories:', error);
                });
        }

        const addVatrate = () => {
            loading.value=true;

            formRef.value
                .validate()
                .then(() => {
                    let payload = {
                        name: form.name,
                        rate: form.rate
                    }
                    vatrateService.create(payload)
                        .then((res) => {
                            reactiveData.data.push(res.data);
                            showSuccessNotification(t('Pos.AddVat-Rates'))
                        }).catch((err) => {
                            //console.log(err)
                        }).finally(() => {
                            open.value = false;
                            loading.value=false;
                        })
                })
        }


        const showDisplayDrawer = (record: any) => {
            updateModalWidth();
            reactiveData.action='view';
            openDisplay.value = true;
            form.name = record.name;
            form.rate = record.rate;
        };


        const textDirection = computed(() => {
        return locale.value === 'ar' ? 'rtl' :'ltr';}
        );

        const updateVatrate = (id: string) => {
            loading.value=true;
            let payload = {
                name: form.name,
                rate: form.rate
            }
            vatrateService.update(id, payload).then((res) => {
                reactiveData.data = reactiveData.data.map(el => el.id !== id ? el : res.data);
                showSuccessNotification(t('Pos.UpdateVat-Rates'))

            }).catch((err) => {
                //console.log(err)
            }).finally(() => {
                open.value = false;
                loading.value=false;
            })
        }

        const deleteVatrate = (id: string) => {
            loading.value=true;
            vatrateService.delete(id).then((res) => {
                reactiveData.data = reactiveData.data.filter(e => e.id !== id);
                showSuccessNotification(t('Pos.DeleteVat-Rates'))
            }).catch((err) => {
                //console.log(err)
            }).finally(() => {
                //console.log('finish')
                loading.value=false;
            })
        };


        const openDisplay = ref<boolean>(false);
    const afterOpenShowChange = (bool: boolean) => {
    //console.log('open', bool);
};

const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
      confirm();
      reactiveData.searchText = selectedKeys[0];
      reactiveData.searchedColumn = dataIndex;
    };

    const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      reactiveData.searchText = '';
    };


        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteVat-Rates'),
                text: t('Pos.TextDeleteVat-Rates'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText:  t('Pos.Delete'),
                cancelButtonColor: "#d33",
                cancelButtonText: t('Pos.Cancel'),                
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteVatrate(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    //console.log("cancel delete ");
                }
            })
        };

        onMounted(() => {
            getVatrate();
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');
        });

        return {
            updateModalWidth,
            modalWidth,
            textDirection,
            locale,
            afterOpenShowChange,
            openDisplay,
            t,
            loading,
            rules,
            columns,
            formRef,
            form,
            formatDisplayDate,
            reactiveData,
            showAddDrawer,
            showDrawer,
            open,
            formatDate,
            updateVatrate,
            addVatrate,
            showDeletePopup,
            visible,
            onSubmitAdd,
            onSubmitUpdate,
            hideForm,
            navigatePage,
            showDisplayDrawer,
            handleSearch,
            handleReset,
            searchInput,
            afterOpenChange
        }
    }

})
</script>

<style scoped>
.add-btn {
    color: #fc8019;
    border: 2px solid #fc8019;
    box-shadow: inset 0 0 0 0 #fc8019;
}

.table-container {
    overflow-x: auto;
}
.info-title{
    font-size: 14px;
    font-weight: 600;
    padding-right: 15px;
  }

.add-btn:hover {
    color: #fff;
    box-shadow: inset 0 -100px 0 0 #fc8019;
    border-color: #fc8019;
}

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}

.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.date-picker {
    width: 100%
}

.test-btn {
    color: #bfffca8a;
}


.breadcrumb-icon{
  cursor: pointer;
}

.view-btn{
    color: #fc8019;
    border-color: #fc8019;
    background: #fafafa;
  }

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

  /* CSS for RTL */
  .is-rtl {
  direction:rtl;
}

.drawer-header-ar {
        padding-right: 2%;
        display: flex ;
        justify-content :end ;
        align-items: center ;
}
.drawer-header-all {
        display: flex ;
        justify-content :start ;
        align-items: center ;
}


</style>