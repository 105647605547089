import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const menuService = {
    getAll,
    show,
    create,
    update,
    delete: _delete,
    getAllWithPagination,
    filter,
    attachProduct,
    dettachProduct
};

function getAll() {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials, 
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.menu.getAll}`, requestOptions)
            .then(
            handleResponse
        );
}

function getAllWithPagination(currentPage: number, perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.menu.getAll}?page=${currentPage}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function filter(key:string,value:string, page:any, perPage:any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.menu.getAll}?keys=${key}&values=${value}&page=${page}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}


function create(payload:object ){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json","Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.menu.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.menu.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function update(id: string, payload: object) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.menu.update.replace(':id', id)}`, requestOptions).then(handleResponse);
}

function _delete(id: string) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.menu.delete.replace(':id', id)}`, requestOptions).then(handleResponse);
}



function attachProduct(id:string, cId:string, pId:string ){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json","Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.menu.attach.replace(':id', id).replace(':cId', cId).replace(':pId', pId)}`, requestOptions)
        .then(
            handleResponse
        );
}


function dettachProduct(id:string, cId:string, pId:string){
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json","Accept": "application/json" },
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.menu.dettach.replace(':id', id).replace(':cId', cId).replace(':pId', pId)}`, requestOptions)
        .then(
            handleResponse
        );
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
