<template>
    
    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
            <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
                {{t('Pos.Dashboard')}}
            </a-breadcrumb-item>
            <a-breadcrumb-item> {{t('Pos.Product')}}</a-breadcrumb-item>
            <a-breadcrumb-item> {{t('Pos.Preferences')}}</a-breadcrumb-item>

        </a-breadcrumb>
    </div>



  <div>
        <a-table
        :columns="columns"
        :data-source="state.data"
        :pagination="false"
        class="table-container"
        >

            <template #emptyText>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
            </template>

            <template #bodyCell="{ column, record }">
                <template v-if="column.key == 'actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>{{ t('Pos.View') }}</template>
                            <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                                <template #icon>
                                <EyeOutlined />
                                </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>

                <template v-if="column.key === 'target'">
                    {{record.target?.name}}
                </template>

                <template v-if="column.key === 'target_type'">
                    {{t(`Pos.${record.target_type}`)}}
                </template>

            



            
            </template> 

            <!-- <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">

                
                    <div style="padding: 8px">
                        <template  v-if="column.key === 'invoice_number'">
                        <a-input
                            ref="searchInput"
                            :placeholder="`${column.title}`"
                            :value="selectedKeys[0]"
                            style="width: 188px; margin-bottom: 8px; display: block"
                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        /> 
                        </template>

                        <template  v-if="column.key === 'warehouse_id'">
                            <div class="search-btn-radio">
                                <a-radio-group  v-model:value="value" @change="selectedWarehouse" class="search-radio-grp">
                                    <a-radio v-for="item in state.warehouses" :label="item.name"  :value="item.id" :key="item" class="radio-item">{{ item.name }}</a-radio>
                                </a-radio-group>  
                            </div>   
                        </template>

                        <a-button
                            type="primary"
                            size="small"
                            style="width: 90px; margin-right: 8px"
                            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                        >
                            {{ t('Pos.Search') }}
                        </a-button>
                        <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                            {{ t('Pos.Reset') }}
                        </a-button>
                    </div>
                

                
            </template>

            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template> -->

    
        </a-table>
        <div class="pagination">
            <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />


            <a-select
                class="select-input"
                v-model:value="selectPagination"
                @change="handleSelectPaginationChange"
                v-if="state.totalPages>10"
            >
                <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
            </a-select>

        </div>


        <!--  display-->
        <a-drawer
            v-model:open="openDisplay"
            class="custom-class"
            root-class-name="root-class-name"
            :placement="locale === 'ar' ? 'left' : 'right'"
            :width=modalWidth
        >
            <template #title>

                <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.IngredientInfo')}}</p>

            </template>
         

            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}"> 

                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Preference') }} :</span>{{form.preference}}</p>
                </a-col>
                <a-col :span="12" >
                    <p><span class="info-title">{{ t('Pos.Description') }} :</span>{{form.description}}</p>
                </a-col>
            </a-row>


            <a-row :gutter="16" :class="{ 'is-rtl': textDirection==='rtl'}"> 

                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.TargetType') }} :</span>{{t(`Pos.${form.target_type}`)}}</p>
                </a-col>

                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Target') }} :</span>
                        {{form.targets?.name}}
                        <a-tag v-if="form.targets?.name===null">{{ t('Pos.Emptyfield') }}</a-tag>
                    </p>
                   
                </a-col>
            
            </a-row>
      
        </a-drawer>
        <!-- display-->


        <!-- add drawer -->
        <!--
    <a-drawer
        v-model:open="openAdd"
        class="custom-class"
        root-class-name="root-class-name"
        :title="t('Pos.AddPreference')"
        placement="right"
        :width="720"
        @close="resetFormAdd"
    >

        <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical">
            <a-row :gutter="16">   
                <a-col :span="12">
                    <a-form-item name="target_type" >

                        <template #label>
                            <span class="label-form">{{ t('Pos.TargetType') }}</span>
                        </template>

                        <a-select v-model:value="addForm.target_type" show-search :option-filter-prop="'label'" @change="handleTypeChange">

                            <template #notFoundContent>
                                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                            </template>

                            <a-select-option value="product">{{ t('Pos.Product') }}</a-select-option>
                            <a-select-option value="ingredient">{{ t('Pos.Ingredient') }}</a-select-option>
                        </a-select>


                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item  name="target_id">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Target') }}</span>
                        </template>

                        <a-select v-model:value="addForm.target_id" show-search :option-filter-prop="'label'">

                            <template #notFoundContent>
                                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                            </template>

                            <a-select-option v-for="item in state.items" :label="item.name" :key="item.id" :value="item.id" :selected="item.id === addForm.item_id ">{{ item.name }}</a-select-option>
                        </a-select>


                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">   
              

                <a-col :span="12">
                    <a-form-item  name="ingredient_id">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Ingredient') }}</span>
                        </template>

                        <a-select v-model:value="addForm.target_id" show-search :option-filter-prop="'label'">

                            <template #notFoundContent>
                                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                            </template>

                            <a-select-option v-for="item in state.ingredients" :label="item.name" :key="item.id" :value="item.id" :selected="item.id === addForm.item_id ">{{ item.name }}</a-select-option>
                        </a-select>


                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
        <template #footer>
            <a-space>
            <a-button  type="primary">{{ t('Pos.Add') }}</a-button>
            <a-button type="primary" danger  @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
            </a-space>
        </template>
    </a-drawer>
    -->


  </div>



</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons-vue';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import { notification } from 'ant-design-vue';
import {ingredientsService, ingredientCategoriesService, customFieldsService, preferenceService, productService } from'@/_services'
import type { UploadChangeParam, UploadFile } from 'ant-design-vue';
import { useRouter , useRoute} from 'vue-router';
import { useI18n } from 'vue-i18n';
import { Empty } from 'ant-design-vue';

export default defineComponent({
    name: 'indexIngredienrs',
    components: {
        LbrxLoaderSpinner,
        EyeOutlined
       
    },
    setup() {
        const modalWidth = ref(720);
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

        const { t } = useI18n();
        const loading = ref(false);

        const router = useRouter();
        const route=useRoute();

        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            items: [] as any[],
            ingredients: [] as any[],
            totalPages:0,


        });

        const columns = [

            {
                title: t('Pos.TargetType'),
                key: 'target_type',
                dataIndex: 'target_type',
            },
            {
                title: t('Pos.Target'),
                key: 'target',
                dataIndex: 'target',
            },
            {
                title: t('Pos.Preference'),
                key: 'preference',
                dataIndex: 'preference',
            },
            {
                title:t('Pos.Actions'),
                dataIndex: 'actions',
                key: 'actions',
            },

        ];

        const form = reactive({
            id:'',
            target_type: '',
            target_id: '',
            preference: '',
            description: '',
            targets: '',
            created_at:'',
        });

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);

        const selectPagination = ref('10');


        const handleSelectPaginationChange=(selectedItem:any)=>{
            
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            loading.value=true;
            preferenceService.getAllWithPagination( currentPage.value,perPage.value).then((res)=>{
                state.data=res.data;
                state.totalPages=res.meta.total;
            }).catch((err:any) => {
                console.log(err)
            }).finally(()=>{
                loading.value = false;
            });
            
        }



        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;

           

            loading.value = true;
            preferenceService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                state.data=response.data;
            }).catch((error)=>{
                console.log("error api : get prefs", error);
            })
            .finally(()=>{
                loading.value = false;
            });

            

      
        }


        const getPrefs=()=>{
            loading.value = true;
            preferenceService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages = response.meta.total;
            }).catch((error)=>{
                console.log("error get prefs", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        const getById=(id:any)=>{
            loading.value = true;

            preferenceService.show(id).then((res)=>{
                form.id=res.data.id;
                form.target_id=res.data.target_id,
                form.preference=res.data.preference,
                form.description=res.data.description,
                form.created_at=res.data.created_at,
                form.target_type=res.data.target_type,
                form.targets=res.data.target

            }).catch((error)=>{
                console.log("error api : get pref by id", error);
            })
            .finally(()=>{
                loading.value = false;
            });
        }

        //display drawer 
        const openDisplay = ref<boolean>(false);

        const showDisplayDrawer = (record: any) => {
            updateModalWidth();
            getById(record.id);
            openDisplay.value = true;
        };

        onMounted(() => {
            getPrefs();
        });

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );
        
        const updateModalWidth = () => {
        modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
    };

        return{
            modalWidth,
            updateModalWidth,
            t,
            locale,
            textDirection,
            loading,
            navigatePage,
            state,
            columns,
            simpleImage,
            perPage,
            currentPage,
            handlePageChange,
            openDisplay,
            showDisplayDrawer,
            form,
            selectPagination,
            handleSelectPaginationChange,






          










        }
        
    },
})
</script>

<style scoped>

.btn-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
}

.breadcrumb-icon{
  cursor: pointer;
}

.label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}


.view-btn{
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.info-title{
    font-size: 15px !important;
    font-weight: bold;
    margin-right: 4px;
}

.is-rtl {
    direction:rtl;
}

.paginationArrows {
    direction:ltr;
    margin-bottom: 16px;
}

.table-container {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
  }
}


</style>