<template>

    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>


    <div>
        <a-breadcrumb style="margin: 16px 0">
            <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
                {{t('Pos.Dashboard')}}
            </a-breadcrumb-item>
            <a-breadcrumb-item>  {{t('Pos.Settings')}}</a-breadcrumb-item>
            <a-breadcrumb-item>{{t('Pos.Customfileds')}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div class="btn-container">
        <a-button class="add-btn" @click="showAddDrawer">
            {{ t('Pos.Add') }}
        </a-button>
    </div>

    <div>
        <a-table
            class="table-container"
            :columns="columns"
            :data-source="state.data"
            :pagination="false"
        >

            <template #emptyText>
                <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
            </template>

            <template #bodyCell="{ column, record }">
                <template v-if="column.key=='actions'">
                    <a-space>
                        <a-tooltip>
                            <template #title>{{ t('Pos.View') }}</template>
                            <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                            <template #icon>
                                <EyeOutlined/>
                            </template>
                            </a-button>
                        </a-tooltip>

                        <a-tooltip>
                            <template #title>{{ t('Pos.Edit') }}</template>
                            <a-button type="primary"  @click="() => showDrawer(record)">
                            <template #icon>
                                <EditOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                        
                        <a-tooltip>
                            <template #title>{{ t('Pos.Delete') }}</template>
                            <a-button type="primary" danger @click="showDeletePopup(record)">
                            <template #icon>
                                <DeleteOutlined />
                            </template>
                            </a-button>
                        </a-tooltip>
                    </a-space>
                </template>

                <template v-if="column.key === 'created_at'">
                    {{ formatDisplayDate(record[column.key]) }}
                </template>

                <template v-if="column.key === 'is_mandatory'">
                    <a-tag color="red" v-if="record.is_mandatory===1 || record.is_mandatory===true ">Mandatory</a-tag>
                    <a-tag color="green" v-if="record.is_mandatory===0 || record.is_mandatory===false ">Optional</a-tag>
                </template>

                
            </template>

            <!--search -->
            <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
                <div style="padding: 8px">
                <a-input
                    ref="searchInput"
                    :placeholder="`${column.title}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                /> 

                <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                
                    {{ t('Pos.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                    {{ t('Pos.Reset') }}
                </a-button>
                </div>
            </template>

            <template #customFilterIcon="{ filtered }">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
            </template>
            <!--search -->

            <template v-if="column.key === 'is_active'">
                <template v-if="record[column.dataIndex]==='true'">
                    <check-circle-two-tone two-tone-color="#52c41a" />
                </template>
                <template v-else>
                    <CheckCircleOutlined />
                </template>
            </template>


        </a-table>
        <div class="pagination">
            <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages"   :default-page-size="perPage"  :showSizeChanger=false @change="handlePageChange"/>

            <a-select
                v-model:value="selectPagination"
                @change="handleSelectPaginationChange"
                v-if="state.totalPages>10"
            >
                <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
                <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
            </a-select>
        </div>




        <!-- display drawer -->
            <a-drawer
                v-model:open="openDisplay"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width="modalWidth"
                @after-open-change="afterOpenChange"
            >

                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.CustomFieldInfo')}}</p>
                </template>

                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                    <a-row :gutter="16"> 
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.Name') }} :</span>{{form.name}}</p>
                        </a-col>

                        <a-col :span="12">
                            <p>
                                <a-tag color="red" v-if="form.is_mandatory===1 || form.is_mandatory=== true">Mandatory</a-tag>
                                <a-tag color="green" v-if="form.is_mandatory===0 ||  form.is_mandatory=== false">Optional</a-tag>
                            </p>
                        </a-col>
                    </a-row>

                
                    <a-row :gutter="16">
                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.CustomizableType') }} :</span>{{form.customizable_type}}</p>
                        </a-col>

                        <a-col :span="12">
                            <p><span class="info-title">{{ t('Pos.Type') }} :</span>{{form.type}}</p>
                        </a-col>
                    </a-row>

                    <a-row v-if="form.type==='select' || form.type==='radio' || form.type==='checkbox'">
                        <a-col :span="24">
                            <p>
                                <span class="info-title">{{ t('Pos.Options') }} :</span>

                                <template v-for="item in form.options" :key="item">
                                    
                                    <a-tag :bordered="false">{{item}} </a-tag>

                                </template>
                            </p>
                        </a-col>
                    </a-row>


                </a-form>
        

            </a-drawer>
        <!-- display drawer -->

        <!-- update drawer -->
            <a-drawer
                v-model:open="open"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width="modalWidth"
                @after-open-change="afterOpenChange"
            >

                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateCustomfields')}}</p>
                </template>



                <a-form ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
                    
                    <a-row :gutter="16">   
                        <a-col :span="24">
                            <a-form-item  name="name">
                                <template #label>
                                <span class="label-form">{{ t('Pos.Name') }}</span>
                                </template>
                                <a-input  v-model:value="form.name" :placeholder="t('Pos.Name')" class="input-form" />
                            </a-form-item>
                        </a-col>
                    
                    </a-row>

                    <a-row :gutter="16">

                        <a-col :span="12">
                            <a-form-item  name="type">
                                <template #label>
                                <span class="label-form">{{ t('Pos.Type') }}</span>
                                </template>
                                <a-select v-model:value="form.type"  show-search :option-filter-prop="'label'">
                                    <template #notFoundContent>
                                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                    </template>

                                    <a-select-option value="text" :label="t('Pos.Text')">{{ t('Pos.Text') }}</a-select-option>
                                    <a-select-option value="textarea" :label="t('Pos.TextArea')">{{ t('Pos.TextArea') }}</a-select-option>
                                    <a-select-option value="number"  :label="t('Pos.Number')">{{ t('Pos.Number') }}</a-select-option>
                                    <a-select-option value="date" :label="t('Pos.Date')">{{ t('Pos.Date') }}</a-select-option>
                                    <a-select-option value="select" :label="t('Pos.Select')">{{ t('Pos.Select') }}</a-select-option>
                                    <a-select-option value="checkbox" :label="t('Pos.Checkbox')">{{ t('Pos.Checkbox') }}</a-select-option>
                                    <a-select-option value="radio" :label="t('Pos.Radio')">{{ t('Pos.Radio') }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item  name="is_mandatory">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Mandatory') }}</span>
                            </template>
                            <a-switch v-model:checked="form.is_mandatory" />
                            </a-form-item>
                        </a-col>

                    </a-row>

                    <a-row v-if="form.type==='select' || form.type==='radio' || form.type==='checkbox'">
                        <a-col :span="24">
                            
                            <div class="options-container" v-for="(option, index) in form.options" :key="index">
                                <a-input class="options-input" v-model:value="form.options[index]" :placeholder="t('Pos.Option') `${index + 1}`" />
                            </div>


                            <!-- dynamic input -->
                            <a-form
                                ref="formUpdateRef"
                                name="dynamic_form_item"
                                :model="dynamicValidateUpdateForm"
                                v-bind="formItemLayoutWithOutLabelUpdate"
                            >
                                <a-form-item
                                    v-for="(domain, index) in dynamicValidateUpdateForm.domains"
                                    :key="domain.key"
                                    v-bind="index === 0 ? formItemLayout : {}"
                                    :rules="index === 0 ? [rules.dynamic] : []"
                                >
                                <a-input
                                    v-model:value="domain.value"
                                    :placeholder=" t('Pos.RequiredField')"
                                    style="width: 72%; margin-right: 8px"
                                />
                                <MinusCircleOutlined
                                    v-if="dynamicValidateUpdateForm.domains.length > 1"
                                    class="dynamic-delete-button"
                                    @click="removeDomainUpdate(domain)"
                                />
                                </a-form-item>

                                <a-form-item v-bind="formItemLayoutWithOutLabelUpdate">
                                    <a-button type="dashed" style="width: 60%" @click="addDomainUpdate">
                                        <PlusOutlined />
                                        
                                        {{ t('Pos.Addfield') }}
                                    </a-button>
                                </a-form-item>

                            </a-form>
                            
                        </a-col>
                    </a-row>

                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-item name="customizable_type">
                                <template #label>
                                    <span class="label-form">{{ t('Pos.CustomizableType') }}</span>
                                </template>

                                <a-select placeholder="Please choose customizable type" v-model:value="form.customizable_type" show-search :option-filter-prop="'label'">
                                    <template #notFoundContent>
                                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                    </template>
                                    <a-select-option value="product" :label="t('Pos.Product')">{{ t('Pos.Product') }}</a-select-option>
                                    <a-select-option value="category" :label="t('Pos.Category')">{{ t('Pos.Category') }}</a-select-option>
                                    <a-select-option value="ingredient" :label="t('Pos.Ingredient')">{{ t('Pos.Ingredient') }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>

                </a-form>
                <template #footer>
                    <a-space>
                        <a-button  type="primary" @click="update(form.id)">{{ t('Pos.Update') }}</a-button>
                        <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
                    </a-space>
                </template>

            </a-drawer>
        <!-- update drawer -->

        <!-- add drawer -->
            <a-drawer
                v-model:open="openAdd"
                class="custom-class"
                root-class-name="root-class-name"
                :placement="locale === 'ar' ? 'left' : 'right'"
                :width="modalWidth"
                @close="resetFormAdd"
            >

                <template #title>
                    <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddCustomfields')}}</p>
                </template>

                <a-form ref="addFormRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

                    <a-row :gutter="16">   
                        <a-col :span="24">
                            <a-form-item  name="name">
                                <template #label>
                                <span class="label-form">{{t('Pos.Name')}}</span>
                                </template>
                                <a-input  v-model:value="addForm.name" :placeholder="t('Pos.Name')" class="input-form" />
                            </a-form-item>
                        </a-col>
                        
                    </a-row>

                    <a-row :gutter="16">

                        <a-col :span="12">
                            <a-form-item name="customizable_type">
                                <template #label>
                                    <span class="label-form">{{t('Pos.CustomizableType')}}</span>
                                </template>

                                <a-select placeholder="Please choose customizable type" v-model:value="addForm.customizable_type" show-search :option-filter-prop="'label'">
                                    <template #notFoundContent>
                                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                    </template>
                                    <a-select-option value="product" :label="t('Pos.Product')">{{t('Pos.Product')}}</a-select-option>
                                    <a-select-option value="category" :label="t('Pos.Category')">{{t('Pos.Category')}}</a-select-option>
                                    <a-select-option value="ingredient" :label="t('Pos.Ingredient')">{{t('Pos.Ingredient')}}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item  name="type">
                                <template #label>
                                <span class="label-form">{{ t('Pos.Type') }}</span>
                                </template>
                                <a-select v-model:value="addForm.type"  show-search :option-filter-prop="'label'">
                                    <template #notFoundContent>
                                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                                    </template>
                                    <a-select-option value="text" :label="t('Pos.Text')">{{ t('Pos.Text') }}</a-select-option>
                                    <a-select-option value="textarea" :label="t('Pos.TextArea')">{{ t('Pos.TextArea') }}</a-select-option>
                                    <a-select-option value="number" :label="t('Pos.Number')">{{ t('Pos.Number') }}</a-select-option>
                                    <a-select-option value="date" :label="t('Pos.Date')">{{ t('Pos.Date') }}</a-select-option>
                                    <a-select-option value="select" :label="t('Pos.Select')">{{ t('Pos.Select') }}</a-select-option>
                                    <a-select-option value="checkbox" :label="t('Pos.Checkbox')">{{ t('Pos.Checkbox') }}</a-select-option>
                                    <a-select-option value="radio" :label="t('Pos.Radio')">{{ t('Pos.Radio') }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                    </a-row>

                    <a-row v-if="addForm.type==='select' || addForm.type==='radio' || addForm.type==='checkbox'">
                        <a-col :span="24">

                            <a-form
                                ref="formRef"
                                name="dynamic_form_item"
                                :model="dynamicValidateForm"
                                v-bind="formItemLayoutWithOutLabel"
                            >
                                <a-form-item
                                    v-for="(domain, index) in dynamicValidateForm.domains"
                                    :key="domain.key"
                                    v-bind="index === 0 ? formItemLayout : {}"
                                    :label="index === 0 ? 'Options' : ''"
                                    :name="['domains', index, 'value']"
                                    :rules="index === 0 ? [rules.dynamic] : []"
                                >
                                    <a-input
                                        v-model:value="domain.value"
                                        :placeholder="t('Pos.Option')"
                                        style="width: 60%; margin-right: 8px"
                                    />
                                
                                    <MinusCircleOutlined
                                        v-if="dynamicValidateForm.domains.length > 1"
                                        class="dynamic-delete-button"
                                        @click="removeDomain(domain)"
                                    />
                                </a-form-item>
                                <a-form-item v-bind="formItemLayoutWithOutLabel">
                                    <a-button type="dashed" style="width: 60%" @click="addDomain">
                                        <PlusOutlined />
                                        
                                        {{t('Pos.Addoption')}}
                                    </a-button>
                                </a-form-item>
                            
                            </a-form>
                            
                        </a-col>
                    </a-row>

                    <a-row :gutter="16">
                    

                        <a-col :span="12">
                            <a-form-item  name="is_mandatory">
                            <template #label>
                            <span class="label-form">{{ t('Pos.Mandatory') }}</span>
                            </template>
                            <a-switch v-model:checked="addForm.is_mandatory" />
                            </a-form-item>
                        </a-col>
    
                    </a-row>

                
                </a-form>

                <template #footer>
                <a-space>
                    <a-button  type="primary" @click="add">{{ t('Pos.Add') }}</a-button>
                    <a-button type="primary" danger  @click="resetFormAdd">{{ t('Pos.Cancel') }}</a-button>
                </a-space>
                </template>


            </a-drawer>
        <!-- add drawer -->

       
    </div>

</template>
<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed} from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
EyeOutlined,
DeleteOutlined,
SearchOutlined,
EditOutlined,
CheckCircleOutlined,
MinusCircleOutlined,
PlusOutlined

} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import {customFieldsService} from '@/_services'
import dayjs , { Dayjs }from 'dayjs'; 
import { useRouter } from 'vue-router';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import {CheckCircleTwoTone } from '@ant-design/icons-vue';
import type { FormInstance } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';
import { Empty } from 'ant-design-vue';

export default defineComponent({

    name: 'indexCustomFiles',
    components: {
        EyeOutlined,
        DeleteOutlined,
        SearchOutlined,
        EditOutlined,
        CheckCircleOutlined,
        CheckCircleTwoTone,
        MinusCircleOutlined,
        PlusOutlined,
        LbrxLoaderSpinner
       
    },

    setup() {
        const modalWidth = ref(720);
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

        const { t } = useI18n();

        const loading = ref(false);

        const router = useRouter();

        const navigatePage = (link: string) => {
            router.push(link); 
        };

        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            totalPages:0,
            beginSearch:false,
            searchName:'',

        });

        const columns=[
            {
                title:t('Pos.Name'),
                key:'name',
                dataIndex: 'name',
                customFilterDropdown: true
            },
            {
                title:t('Pos.Type'),
                key:'type',
                dataIndex: 'type',

            },
            {
                title:t('Pos.Mandatory'),
                key:'is_mandatory',
                dataIndex: 'is_mandatory',
                
            },  
            {
                title:t('Pos.CustomizableType'),
                key:'customizable_type',
                dataIndex: 'customizable_type',
                
            },  
            {
                title:t('Pos.Actions'),
                key:'actions'
            }

        ];

        //form  
        const formRef = ref();
        const addFormRef = ref();
        const logInFormRef=ref();
    
        const form = reactive({
            id: '',
            name: '',
            type: '',
            is_mandatory:false,
            customizable_type:'',
            customizable_id:'',
            options:[],
            optionsUp:'',
            created_at: '',
            updated_at: '',
        });


        const addForm = reactive({
            id: '',
            name: '',
            type: '',
            is_mandatory:0,
            customizable_type:'',
            customizable_id:'',
            created_at: '',
            updated_at: '',
        });

        const rules: Record<string, Rule[]> = {
            name: [{ required: true, message: t('Pos.RequiredField') }],
            type: [{ required: true, message: t('Pos.RequiredField') }],
            is_mandatory: [{ required: true, message: t('Pos.RequiredField') }],
            customizable_type: [{ required: true, message: t('Pos.RequiredField') }],
            customizable_id: [{ required: true, message: t('Pos.RequiredField') }],
            dynamic: [{ required: true, message: t('Pos.AboveOptionRequired'), trigger: 'change' }],

        };

        //drawer
        const open = ref<boolean>(false);
        const openDisplay = ref<boolean>(false);
        const openAdd = ref<boolean>(false);

        const showAddDrawer = (state: boolean) => {
            updateModalWidth();
            openAdd.value = true;
        };

        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const showDisplayDrawer =(record:any)=>{
            updateModalWidth();
            openDisplay.value = true;
            getById(record.id);
        }

        const showDrawer = (record: any) => {
            updateModalWidth();
            open.value = true;
            getById(record.id);
        };


        const afterOpenAddChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const cancelUpdate=()=>{
            open.value=false;
        }

        const getById = (id: string) => {
            loading.value=true;
            customFieldsService.show(id)
            .then(res => {
                console.log('device by id', res.data);

                form.name = res.data.name;
                form.type = res.data.type;
                form.is_mandatory=res.data.is_mandatory === 1;
                form.customizable_id=res.data.customizable_id;
                form.customizable_type=res.data.customizable_type;
                form.created_at=res.data.created_at;
                form.updated_at = res.data.updated_at;
                form.id = res.data.id;

                if(res.data.type==="select" || res.data.type==="radio" || res.data.type==="checkbox" ){
                    form.options=  res.data.options.split(',');
                    form.optionsUp= res.data.options;
                }
                loading.value=false;

            })
            .catch(error => {
                console.error('Error fetching custom field by id:', error);
            });
        }

        const getAll=()=>{
            loading.value = true;
            customFieldsService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages = response.meta.total;
                console.log('custom fileds',state.data)
            }).catch((error)=>{
                console.log("error get custom fileds", error);
            }).finally(()=>{
                loading.value = false;
            });
        }

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };

        const deleteField = (id: string) => {
            customFieldsService.delete(id).then((res) => {
                state.data = state.data.filter(e => e.id !== id);
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                showSuccessNotification(t('Pos.DeleteCustomfieldsSuccess'));
                console.log('finish')
            })
        };

        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteCustomfields'),
                text: t('Pos.TextDeleteCustomfields'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText:t('Pos.Delete'),
                cancelButtonText: t('Pos.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                    popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                    deleteField(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    console.log("cancel delete ");
                }
            })
        };

        const resetFormAdd = () => {
            addFormRef.value.resetFields();
        };

        const add=()=>{

            addFormRef.value
                .validate()
                .then(()=>{
                    const options = dynamicValidateForm.domains.map(domain => domain.value).join(',');
                    const isMandatory = addForm.is_mandatory ? 1 : 0;

                    let payload = {
                        name:addForm.name,
                        type:addForm.type,
                        is_mandatory:isMandatory,
                        customizable_type:addForm.customizable_type,
                        options:options
                    };

                    
                    console.log('payload', payload);
                    customFieldsService.create(payload).then((rep)=>{
                        console.log('Custom field added successfully:', rep);
                        openAdd.value=false;
                        state.data.push(rep.data);
                        resetFormAdd();
                        showSuccessNotification(t('Pos.AddCustomfieldsSuccess'));
                    }).catch((error) => {
                        console.error('Error adding custom field:', error); 
                    }).finally(() => {
                        console.log('finish');
                        dynamicValidateForm.domains=[];
                    }); 

                }).catch((error:any) => {
                    console.error('Validation error:', error);
                    dynamicValidateForm.domains=[];
                });

        }

        const update =(id:string)=>{
                     
            formRef.value
            .validate()
            .then(()=>{

                
                let addedOptions='';

                if(form.type==="select" || form.type==='radio' || form.type==='checkbox'){
                    const optionsString = form.options.join(',');
                    const additionalOptions = dynamicValidateUpdateForm.domains.map(domain => domain.value).join(',');
                    const combinedOptions = `${optionsString},${additionalOptions}`;
                    addedOptions=combinedOptions;
                }

                let payload = {
                    name:form.name,
                    type:form.type,
                    is_mandatory:form.is_mandatory,
                    customizable_type:form.customizable_type,
                    options:addedOptions,
                }

              
                
                console.log('payload', payload);
              
                customFieldsService.update(id, payload).then((response)=>{
                    open.value=false;
                    state.data = state.data.map(el => el.id !== id ? el : response.data);
                    showSuccessNotification(t('Pos.UpdateCustomfieldsSuccess'));

                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    console.log('finish');
                    dynamicValidateUpdateForm.domains=[];
                }); 
                
            })
        }

        const options=ref();
        let hasCalledAPI = false;
        /*
        watch(() => addForm.customizable_type, (newValue) => {
            if (newValue === 'product' && !hasCalledAPI) {
                
                productService.getAll().then((products) => {
                    console.log("getting products");
                    options.value = products.data.map((product:any) => ({
                        value: product.id,
                        label: product.name,
                    }));
                    addForm.customizable_id = options.value.length > 0 ? options.value[0].value : null;

                }).catch((error:any) => {
                console.error('Error fetching products:', error);
                });
            }else if(newValue === 'category'){
                categoryService.getAll().then((categories) => {
                    console.log("getting categories");
                    options.value = categories.data.map((category:any) => ({
                        value: category.id,
                        label: category.name,
                    }));
                    addForm.customizable_id = options.value.length > 0 ? options.value[0].value : null;
                }).catch((error:any) => {
                console.error('Error fetching categories:', error);
                });
            }else { 
                options.value = [];
                addForm.customizable_id = '';  
            }
        });
        */

        onMounted(() => {
            getAll();
        });

        //dynamic fileds : 
        interface Domain {
            value: string;
            key: number;
        }
        const formDynamicRef = ref<FormInstance>();

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const dynamicValidateForm = reactive<{ domains: Domain[] }>({
            domains: [],
        });

        const submitForm = () => {
            formRef.value
                .validate()
                .then(() => {
                console.log('values', dynamicValidateForm.domains);
                })
                .catch((error:any) => {
                console.log('error', error);
                });
        };

        const resetForm = () => {
            formRef.value.resetFields();
        };

        const removeDomain = (item: Domain) => {
            const index = dynamicValidateForm.domains.indexOf(item);
            if (index !== -1) {
                dynamicValidateForm.domains.splice(index, 1);
            }
        };

        const dynamicRules = {
            required: true,
            message: t('Pos.AboveOptionRequired'),
            trigger: 'change',
        };

        const addDomain = () => {
            /*
            dynamicValidateForm.domains.push({
                value: '',
                key: Date.now(),
            });
            */
            const lastIndex = dynamicValidateForm.domains.length - 1;
            if (lastIndex >= 0 && dynamicValidateForm.domains[lastIndex].value === '') {
                
                formRef?.value?.validateFields(`domains.${lastIndex}.value`);
               
            } else {
                dynamicValidateForm.domains.push({
                    value: '',
                    key: 1,
                });
            }
        };

        const formatDate=(dateString:string)=>{
            return dayjs(dateString);
        }

        //dynamic update form : 
        interface DomainUpdate {
            value: string;
            key: number;
        }
        const formUpdateRef = ref<FormInstance>();
        const formItemLayoutUpdate = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const formItemLayoutWithOutLabelUpdate = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        const dynamicValidateUpdateForm = reactive<{ domains: DomainUpdate[] }>({
            domains: [],
        });
        const submitUpdateForm = () => {
            formUpdateRef.value!
            .validate()
            .then(() => {
                console.log('values', dynamicValidateUpdateForm.domains);
            })
            .catch(error => {
                console.log('error', error);
            });
        };

        const resetUpdateForm = () => {
            formUpdateRef.value!.resetFields();
        };

        const removeDomainUpdate = (item: DomainUpdate) => {
            const index = dynamicValidateUpdateForm.domains.indexOf(item);
            if (index !== -1) {
                dynamicValidateUpdateForm.domains.splice(index, 1);
            }
        };

        


        const addDomainUpdate = () => {
            // dynamicValidateUpdateForm.domains.push({
            //     value: '',
            //     key: Date.now(),
            // });

            const lastIndex = dynamicValidateUpdateForm.domains.length - 1;
            if (lastIndex >= 0 && dynamicValidateUpdateForm.domains[lastIndex].value === '') {
                
                formUpdateRef?.value?.validateFields(`domains.${lastIndex}.value`);
               
            } else {
                dynamicValidateUpdateForm.domains.push({
                    value: '',
                    key: 1,
                });
            }


        };

        //search

        const filter=(key:string, value:string)=>{
            loading.value=true;
            customFieldsService.filterWithPagination(key,value,currentPage.value.toString(),perPage.value.toString())
            .then((res) => {
                state.data=res.data;
                state.totalPages = res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err);
            });
        }
        
        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();
            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            if (dataIndex === 'name' && state.searchText) {
                state.searchName=state.searchText
                filter('name',state.searchText);
            }
        };

        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            state.searchName='';
            state.beginSearch=false;

            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';

            getAll();
        };

        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);
        const selectPagination = ref('10');

        const handleSelectPaginationChange=(selectedItem:any)=>{
            console.log('selected Per page:', selectedItem);
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){

                

                if(state.searchName!=''){
                    loading.value = true;
                    customFieldsService.filterWithPagination("name",state.searchName,currentPage.value.toString(),perPage.value.toString()).then((res)=>{
                        state.data=res.data;
                        state.totalPages = res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

            }else{

                loading.value = true;
                customFieldsService.getAllWithPagination(currentPage.value, perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }

                    
        }


        const updateModalWidth = () => {
                modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
        };


        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;
            

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);


            if(state.beginSearch===true){
                state.data=[];
              

                if(state.searchName!=''){
                    loading.value = true;
                    customFieldsService.filterWithPagination("name",state.searchName,currentPage.value.toString(),perPage.value.toString()).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

            }else{
                loading.value = true;
                customFieldsService.getAllWithPagination(perPage.value,currentPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                console.log("error api : get products", error);
                })
                .finally(()=>{
                loading.value = false;
                });

            }

        
        }

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );



        return{
            updateModalWidth,
            modalWidth,
            textDirection,
            locale,
            perPage,
            currentPage,
            handlePageChange,
            selectPagination,
            handleSelectPaginationChange,


            handleSearch,
            handleReset,

            addDomainUpdate,
            removeDomainUpdate,
            resetUpdateForm,
            submitUpdateForm,
            dynamicValidateUpdateForm,
            formItemLayoutWithOutLabelUpdate,
            formUpdateRef,
            
            t,
            loading,
            navigatePage,
            state,
            columns,
            formRef,
            addFormRef,
            logInFormRef,
            form,
            addForm,
            rules,
            open,
            openDisplay,
            openAdd,
            showAddDrawer,
            afterOpenChange,
            showDisplayDrawer,
            afterOpenAddChange,
            getAll,
            showDrawer,
            showDeletePopup,
            resetFormAdd,
            add,
            update,
            formDynamicRef,
            formItemLayout,
            formItemLayoutWithOutLabel,
            dynamicValidateForm,
            submitForm,
            resetForm,
            removeDomain,
            addDomain,
            dynamicRules,
            options,
            formatDate,
            cancelUpdate,
            simpleImage

            










        }

        
    },
})
</script>
<style scoped>

    .add-btn {
        color: #fc8019;
        border: 2px solid #fc8019;
        box-shadow: inset 0 0 0 0 #fc8019;
    }

    .add-btn:hover {
        color: #fff;
        box-shadow: inset 0 -100px 0 0 #fc8019;
        border-color: #fc8019;
    }

    .btn-container {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
    }

    .label-form {
        font-family: "Exo 2", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    .date-picker {
        width: 100%
    }
    .range-picker{
        width: 100% 
    }

    .switch-container {
        display: flex;
        align-items: center;
    }
    .switch-container p {
        margin-right: 15px ;
    }

    .view-btn{
        color: #fc8019;
        border-color: #fc8019;
        background: #fafafa;
    }
    .login-btn{
        color: #28c76f;
        border-color: #28c76f;
        background: #fafafa;
    }

    .login-btn :hover{
        color: #28c76f !important;
        border-color: #28c76f !important;
        background: #fafafa;
    }

    .paginationArrows {
        direction: ltr
    }


    .breadcrumb-icon{
        cursor: pointer;
    }

    .pagination{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .info-title{
        font-size: 15px !important;
        font-weight: bold;
        margin-right: 4px;
    }

    .options-container{
        margin-bottom:10px ;
        display: flex;
        align-items: center;
    }
    .options-input{
        
        margin-top: 5px;
        width: 60%;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .pagination{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

    /* CSS for RTL */
    .is-rtl {
        direction:rtl;
    }
    .table-container {
    overflow-x: auto;
    }

    
    @media (max-width: 768px) {
        .pagination {
            flex-direction: column;
            align-items: flex-start;
        }

        .select-input {
            width: 100%;
            margin-top: 10px;
        }
    }


</style>

