import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const tableLocationService = {
    getAll,
    show,
    create,
    delete: _delete,
    update,
    createTableLocation,
    getAllWithPagination,
    filterWithPagination,
    add,
    updateTableLocation
    
};

function getAll() {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.tableLocation.getAll}`, requestOptions)
        .then(
            handleResponse
        );
}

function getAllWithPagination(perPage:number, currentPage:number) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.tableLocation.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.tableLocation.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}

function add(formData:FormData ){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Accept": "application/json" },
        body: formData,
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.tableLocation.create}`, requestOptions)
        .then(
            handleResponse
        );
}



function create(payload:object ){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json", "Accept": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.tableLocation.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function createTableLocation(name:string, description:string, photo:any){

    const formData = new FormData();

    formData.append('name', name);
    formData.append('description', description);
    if(photo!==null){
        formData.append('photo', photo);
    }
    

    

    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Accept": "application/json" },
        body: formData
    };
    
    return fetch(`${ApiConfigs.base_url + ApiConfigs.tableLocation.create}`, requestOptions)
        .then(
            handleResponse
        );

    
}

function _delete(id: string){
    const requestOptions={
        method:"DELETE",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.tableLocation.delete.replace(':id', id)}`, requestOptions)
        .then(handleResponse);
}

function update(id: string, payload: object){
    console.log('ertert', id)
    const requestOptions={
        method:"PUT",
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(payload)
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.tableLocation.update.replace(':id', id)}`, requestOptions)
    .then(handleResponse);
}

function updateTableLocation(id: string, name:string, description: string,photo:any ){
    
    const formData = new FormData();

    formData.append('name', name);
    formData.append('description', description);
    if(photo!==null){
        formData.append('photo', photo);  
    }
    

    const requestOptions={
        method:"POST",
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), "Accept": "application/json"  },
        body: formData
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.tableLocation.update.replace(':id', id)}`, requestOptions)
    .then(handleResponse);
}

function filterWithPagination(key:string, value:string, page:string, perPage:any) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials : 'include' as RequestCredentials,
    };

    return fetch(`${ApiConfigs.base_url+ApiConfigs.tableLocation.getAll}?key=${key}&value=${value}&page=${page}&per_page=${perPage}`, requestOptions).then(handleResponse);

}




function handleResponse(response: any) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

