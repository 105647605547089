import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6f5a47e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = { class: "info-title" }
const _hoisted_5 = { class: "info-title" }
const _hoisted_6 = { class: "info-title" }
const _hoisted_7 = { class: "info-title" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "info-title" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "info-title" }
const _hoisted_12 = { class: "config-title" }
const _hoisted_13 = { class: "info-title" }
const _hoisted_14 = { class: "info-title" }
const _hoisted_15 = { class: "info-title" }
const _hoisted_16 = { class: "info-title" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 2 }
const _hoisted_20 = { class: "info-title" }
const _hoisted_21 = { class: "info-title" }
const _hoisted_22 = { class: "info-title" }
const _hoisted_23 = { class: "info-title" }
const _hoisted_24 = { class: "info-title" }
const _hoisted_25 = { class: "info-title" }
const _hoisted_26 = { class: "info-title" }
const _hoisted_27 = { class: "config-title" }
const _hoisted_28 = { class: "info-title title-check" }
const _hoisted_29 = { class: "info-title title-check" }
const _hoisted_30 = { class: "info-title title-check" }
const _hoisted_31 = { class: "info-title title-check" }
const _hoisted_32 = { class: "info-title title-check" }
const _hoisted_33 = { class: "info-title title-check" }
const _hoisted_34 = { class: "info-title title-check" }
const _hoisted_35 = { class: "info-title title-check" }
const _hoisted_36 = { class: "info-title title-check" }
const _hoisted_37 = { class: "info-title" }
const _hoisted_38 = { class: "info-title" }
const _hoisted_39 = { class: "info-title" }
const _hoisted_40 = { class: "info-title" }
const _hoisted_41 = { class: "info-title" }
const _hoisted_42 = { class: "info-title" }
const _hoisted_43 = { class: "info-title" }
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { key: 1 }
const _hoisted_46 = { key: 2 }
const _hoisted_47 = { class: "info-title" }
const _hoisted_48 = { class: "label-form" }
const _hoisted_49 = { class: "label-form" }
const _hoisted_50 = { class: "label-form" }
const _hoisted_51 = { class: "label-form" }
const _hoisted_52 = { class: "label-form" }
const _hoisted_53 = { class: "config-title" }
const _hoisted_54 = { class: "label-form" }
const _hoisted_55 = { class: "label-form" }
const _hoisted_56 = { class: "label-form" }
const _hoisted_57 = { class: "label-form" }
const _hoisted_58 = { class: "label-form" }
const _hoisted_59 = { class: "label-form" }
const _hoisted_60 = { class: "label-form" }
const _hoisted_61 = { class: "label-form" }
const _hoisted_62 = { class: "label-form" }
const _hoisted_63 = { class: "label-form" }
const _hoisted_64 = { class: "label-form" }
const _hoisted_65 = { class: "config-title" }
const _hoisted_66 = { class: "label-form" }
const _hoisted_67 = { class: "label-form" }
const _hoisted_68 = { class: "label-form" }
const _hoisted_69 = { class: "label-form" }
const _hoisted_70 = { class: "label-form" }
const _hoisted_71 = { class: "label-form" }
const _hoisted_72 = { class: "label-form" }
const _hoisted_73 = { class: "label-form" }
const _hoisted_74 = { class: "label-form" }
const _hoisted_75 = { class: "label-form" }
const _hoisted_76 = { class: "config-title" }
const _hoisted_77 = { class: "label-form" }
const _hoisted_78 = { class: "label-form" }
const _hoisted_79 = { class: "label-form" }
const _hoisted_80 = { class: "label-form" }
const _hoisted_81 = { class: "label-form" }
const _hoisted_82 = { class: "label-form" }
const _hoisted_83 = { class: "label-form" }
const _hoisted_84 = { class: "label-form" }
const _hoisted_85 = { class: "label-form" }
const _hoisted_86 = { class: "label-form" }
const _hoisted_87 = { class: "label-form" }
const _hoisted_88 = { class: "config-title" }
const _hoisted_89 = { class: "label-form" }
const _hoisted_90 = { class: "label-form" }
const _hoisted_91 = { class: "label-form" }
const _hoisted_92 = { class: "label-form" }
const _hoisted_93 = { class: "label-form" }
const _hoisted_94 = { class: "label-form" }
const _hoisted_95 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_LoginOutlined = _resolveComponent("LoginOutlined")!
  const _component_LogoutOutlined = _resolveComponent("LogoutOutlined")!
  const _component_RedoOutlined = _resolveComponent("RedoOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_check_circle_two_tone = _resolveComponent("check-circle-two-tone")!
  const _component_close_circle_two_tone = _resolveComponent("close-circle-two-tone")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_CheckCircleOutlined = _resolveComponent("CheckCircleOutlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_QuestionCircleOutlined = _resolveComponent("QuestionCircleOutlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Hardware')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _ctx.showAddDrawer
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        columns: _ctx.columns,
        "data-source": _ctx.state.data,
        pagination: false,
        class: "table-container"
      }, {
        emptyText: _withCtx(() => [
          _createVNode(_component_a_empty, {
            description: _ctx.t('Pos.NoData'),
            image: _ctx.simpleImage
          }, null, 8, ["description", "image"])
        ]),
        bodyCell: _withCtx(({ column, record }) => [
          (column.key=='actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        onClick: () => _ctx.showDisplayDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Edit')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Login')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "login-btn",
                        onClick: ($event: any) => (_ctx.showModal(record)),
                        disabled: record.type==='printer'
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_LoginOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.LogOut')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        danger: "",
                        onClick: ($event: any) => (_ctx.logout(record.id)),
                        disabled: record.type==='printer'
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_LogoutOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Refresh')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "variant-btn",
                        onClick: ($event: any) => (_ctx.refresh(record.id)),
                        disabled: record.type==='printer'
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_RedoOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick", "disabled"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key === 'start_date' || column.key === 'end_date')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatDisplayDate(record[column.key])), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key==='is_active')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                (record.is_active===1 || record.is_active===true)
                  ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                      key: 0,
                      "two-tone-color": "#52c41a"
                    }))
                  : _createCommentVNode("", true),
                (record.is_active===0 || record.is_active===false)
                  ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                      key: 1,
                      "two-tone-color": "#ff4d4f"
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.key=='type')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                (column.key === 'type')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.t(`Pos.${record.type}`)), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_input, {
              ref: "searchInput",
              placeholder: `${column.title}`,
              value: selectedKeys[0],
              style: {"width":"188px","margin-bottom":"8px","display":"block"},
              onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
              onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        default: _withCtx(() => [
          (_ctx.column.key === 'is_active')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.record[_ctx.column.dataIndex]==='true')
                  ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                      key: 0,
                      "two-tone-color": "#52c41a"
                    }))
                  : (_openBlock(), _createBlock(_component_CheckCircleOutlined, { key: 1 }))
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_pagination, {
          class: "paginationArrows",
          current: _ctx.currentPage,
          "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
          pageSize: _ctx.perPage,
          "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.perPage) = $event)),
          total: _ctx.state.totalPages,
          onChange: _ctx.handlePageChange,
          "default-page-size": _ctx.perPage,
          showSizeChanger: false
        }, null, 8, ["current", "pageSize", "total", "onChange", "default-page-size"]),
        (_ctx.state.totalPages>10)
          ? (_openBlock(), _createBlock(_component_a_select, {
              key: 0,
              class: "select-input",
              value: _ctx.selectPagination,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectPagination) = $event)),
              onChange: _ctx.handleSelectPaginationChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select_option, { value: "10" }, {
                  default: _withCtx(() => [
                    _createTextVNode("10 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "20" }, {
                  default: _withCtx(() => [
                    _createTextVNode("20 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "50" }, {
                  default: _withCtx(() => [
                    _createTextVNode("50 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "100" }, {
                  default: _withCtx(() => [
                    _createTextVNode("100 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "onChange"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterOpenChange
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.DeviceInfo')), 3)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('Pos.Name')), 1),
                        _createTextVNode(_toDisplayString(_ctx.form.name), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('Pos.Active')), 1),
                        (_ctx.form.is_active===1 || _ctx.form.is_active===true)
                          ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                              key: 0,
                              "two-tone-color": "#52c41a"
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.form.is_active===0 || _ctx.form.is_active===false)
                          ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                              key: 1,
                              "two-tone-color": "#ff4d4f"
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Pos.Type')), 1),
                        _createTextVNode(_toDisplayString(_ctx.t(`Pos.${_ctx.form.type}`)), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.CreatedAt')), 1),
                        _createTextVNode(_toDisplayString(_ctx.formatDisplayDate(_ctx.form.created_at)), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      (_ctx.form.pinged_at!==null )
                        ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.PingedAt')) + " :", 1),
                            _createTextVNode(_toDisplayString(_ctx.formatDisplayDate(_ctx.form.pinged_at)), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.form.pinged_at===null || _ctx.form.pinged_at==='')
                        ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Pos.PingedAt')), 1),
                            _createVNode(_component_a_tag, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.form.config!=='' )
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.configItemsUpdate ==='kitchenConf' || _ctx.configItemsUpdate==='menuConf' || _ctx.configItemsUpdate==='orderConf' )
                      ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t('Pos.Store')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate!=='printerConf'  )
                      ? (_openBlock(), _createBlock(_component_a_row, {
                          key: 1,
                          gutter: 16
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 8 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Pos.PrimaryColor')), 1),
                                  _withDirectives(_createElementVNode("input", {
                                    type: "color",
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.primaryColor) = $event)),
                                    disabled: ""
                                  }, null, 512), [
                                    [_vModelText, _ctx.form.primaryColor]
                                  ])
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 8 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('Pos.SecondaryColor')), 1),
                                  _withDirectives(_createElementVNode("input", {
                                    type: "color",
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.primaryLight) = $event)),
                                    disabled: ""
                                  }, null, 512), [
                                    [_vModelText, _ctx.form.primaryLight]
                                  ])
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 8 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('Pos.otherColor')), 1),
                                  _withDirectives(_createElementVNode("input", {
                                    type: "color",
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.secondaryColor) = $event)),
                                    disabled: ""
                                  }, null, 512), [
                                    [_vModelText, _ctx.form.secondaryColor]
                                  ])
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='posConf')
                      ? (_openBlock(), _createBlock(_component_a_row, {
                          key: 2,
                          gutter: 16
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.t('Pos.DefaultLanguage')), 1),
                                  (_ctx.form.defaultLanguage==='en')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.t('Pos.English')), 1))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.defaultLanguage==='fr')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.t('Pos.French')), 1))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.defaultLanguage==='ar')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.t('Pos.Arabic')), 1))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('Pos.StoreName')), 1),
                                  _createTextVNode(_toDisplayString(_ctx.form.store_name), 1)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='posConf')
                      ? (_openBlock(), _createBlock(_component_a_row, {
                          key: 3,
                          gutter: 16
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('Pos.DefaultRoute')), 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.form.defaultRoute) + " ", 1),
                                  (_ctx.form.defaultRoute==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('Pos.SafeSleepTime')), 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.form.hibernate) + " ", 1),
                                  (_ctx.form.hibernate==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate !=='printerConf')
                      ? (_openBlock(), _createBlock(_component_a_row, {
                          key: 4,
                          gutter: 16
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Pos.IpAddress')), 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.form.ip_address) + " ", 1),
                                  (_ctx.form.ip_address==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t('Pos.MacAddress')), 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.form.mac_address) + " ", 1),
                                  (_ctx.form.mac_address==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='posConf')
                      ? (_openBlock(), _createBlock(_component_a_row, { key: 5 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.t('Pos.DefaultTableFilter')) + " : ", 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.getTableLocationName(_ctx.form.config.posConfig.defaultTableFilter)) + " ", 1),
                                  (_ctx.form.config.posConfig.defaultTableFilter==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.t('Pos.Warehouse')) + " : ", 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.getWarehouseName(_ctx.form.config.store.warehouse_id)) + " ", 1),
                                  (_ctx.form.config.warehouse_id==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='posConf')
                      ? (_openBlock(), _createBlock(_component_a_row, { key: 6 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 24 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.t('Pos.Pos')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='posConf')
                      ? (_openBlock(), _createBlock(_component_a_row, {
                          key: 7,
                          gutter: 16
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 8 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  (_ctx.form.config.posConfig.show_table===1 || _ctx.form.config.posConfig.show_table===true)
                                    ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                                        key: 0,
                                        "two-tone-color": "#52c41a"
                                      }))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.config.posConfig.show_table===0 || _ctx.form.config.posConfig.show_table===false )
                                    ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                                        key: 1,
                                        "two-tone-color": "#ff4d4f"
                                      }))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.t('Pos.ShowTable')), 1)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 8 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  (_ctx.form.config.posConfig.show_cashier===1 || _ctx.form.config.posConfig.show_cashier===true)
                                    ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                                        key: 0,
                                        "two-tone-color": "#52c41a"
                                      }))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.config.posConfig.show_cashier===0 || _ctx.form.config.posConfig.show_cashier===false )
                                    ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                                        key: 1,
                                        "two-tone-color": "#ff4d4f"
                                      }))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.t('Pos.ShowCashier')), 1)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 8 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  (_ctx.form.config.posConfig.showKeyboard===1 || _ctx.form.config.posConfig.showKeyboard===true)
                                    ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                                        key: 0,
                                        "two-tone-color": "#52c41a"
                                      }))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.config.posConfig.showKeyboard===0 || _ctx.form.config.posConfig.showKeyboard===false )
                                    ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                                        key: 1,
                                        "two-tone-color": "#ff4d4f"
                                      }))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.t('Pos.ShowKeyboard')), 1)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='posConf')
                      ? (_openBlock(), _createBlock(_component_a_row, {
                          key: 8,
                          gutter: 16
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  (_ctx.form.config.posConfig.open_drawer_after_printing===1 || _ctx.form.config.posConfig.open_drawer_after_printing===true)
                                    ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                                        key: 0,
                                        "two-tone-color": "#52c41a"
                                      }))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.config.posConfig.open_drawer_after_printing===0 || _ctx.form.config.posConfig.open_drawer_after_printing===false )
                                    ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                                        key: 1,
                                        "two-tone-color": "#ff4d4f"
                                      }))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.t('Pos.openDrawerAfterPrint')), 1)
                                ])
                              ]),
                              _: 1
                            }),
                            (_ctx.configItemsUpdate==='posConf')
                              ? (_openBlock(), _createBlock(_component_a_col, {
                                  key: 0,
                                  span: 12
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", null, [
                                      (_ctx.form.config.posConfig.allow_print_before_payement===1 || _ctx.form.config.posConfig.allow_print_before_payement===true)
                                        ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                                            key: 0,
                                            "two-tone-color": "#52c41a"
                                          }))
                                        : _createCommentVNode("", true),
                                      (_ctx.form.config.posConfig.allow_print_before_payement===0 || _ctx.form.config.posConfig.allow_print_before_payement===false )
                                        ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                                            key: 1,
                                            "two-tone-color": "#ff4d4f"
                                          }))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.t('Pos.allowPrintBefore')), 1)
                                    ])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='posConf')
                      ? (_openBlock(), _createBlock(_component_a_row, {
                          key: 9,
                          gutter: 16
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  (_ctx.form.config.posConfig.show_products===1 || _ctx.form.config.posConfig.show_products===true)
                                    ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                                        key: 0,
                                        "two-tone-color": "#52c41a"
                                      }))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.config.posConfig.show_products===0 || _ctx.form.config.posConfig.show_products===false )
                                    ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                                        key: 1,
                                        "two-tone-color": "#ff4d4f"
                                      }))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.t('Pos.showProducts')), 1)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  (_ctx.form.config.posConfig.show_refundables===1 || _ctx.form.config.posConfig.show_refundables===true)
                                    ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                                        key: 0,
                                        "two-tone-color": "#52c41a"
                                      }))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.config.posConfig.show_refundables===0 || _ctx.form.config.posConfig.show_refundables===false )
                                    ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                                        key: 1,
                                        "two-tone-color": "#ff4d4f"
                                      }))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.t('Pos.showRefundables')), 1)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='posConf')
                      ? (_openBlock(), _createBlock(_component_a_row, { key: 10 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  (_ctx.form.config.posConfig.allow_print_after_payement===1 || _ctx.form.config.posConfig.allow_print_after_payement===true)
                                    ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                                        key: 0,
                                        "two-tone-color": "#52c41a"
                                      }))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.config.posConfig.allow_print_after_payement===0 || _ctx.form.config.posConfig.allow_print_after_payement===false )
                                    ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                                        key: 1,
                                        "two-tone-color": "#ff4d4f"
                                      }))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.t('Pos.allowPrintAfter')), 1)
                                ])
                              ]),
                              _: 1
                            }),
                            (_ctx.configItemsUpdate==='posConf')
                              ? (_openBlock(), _createBlock(_component_a_col, {
                                  key: 0,
                                  span: 12
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", null, [
                                      (_ctx.form.generate_sale===1 || _ctx.form.generate_sale===true)
                                        ? (_openBlock(), _createBlock(_component_check_circle_two_tone, {
                                            key: 0,
                                            "two-tone-color": "#52c41a"
                                          }))
                                        : _createCommentVNode("", true),
                                      (_ctx.form.generate_sale===0 || _ctx.form.generate_sale===false )
                                        ? (_openBlock(), _createBlock(_component_close_circle_two_tone, {
                                            key: 1,
                                            "two-tone-color": "#ff4d4f"
                                          }))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.t('Pos.generateSale')), 1)
                                    ])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='posConf')
                      ? (_openBlock(), _createBlock(_component_a_row, { key: 11 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.t('Pos.DisplayText')) + " : ", 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.form.config.posConfig.showTextLcd) + " ", 1),
                                  (_ctx.form.config.posConfig.showTextLcd==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.t('Pos.footerTxt')) + " : ", 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.form.config.posConfig.footer_text) + " ", 1),
                                  (_ctx.form.config.posConfig.footer_text==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    ( _ctx.configItemsUpdate==='posConf' || _ctx.configItemsUpdate==='orderConf' )
                      ? (_openBlock(), _createBlock(_component_a_row, { key: 12 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createVNode(_component_a_tooltip, null, {
                                    title: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Pos.decimalPercisionTxt')), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_QuestionCircleOutlined, { class: "tooltip-icon" })
                                    ]),
                                    _: 1
                                  }),
                                  _createElementVNode("span", _hoisted_39, _toDisplayString(_ctx.t('Pos.decimalPercision')) + " : ", 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.form.decimal_precision) + " ", 1),
                                  (_ctx.form.decimal_precision==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='posConf')
                      ? (_openBlock(), _createBlock(_component_a_row, { key: 13 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createVNode(_component_a_tooltip, null, {
                                    title: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Pos.RedirectAfterCartTxT')), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_QuestionCircleOutlined, { class: "tooltip-icon" })
                                    ]),
                                    _: 1
                                  }),
                                  _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.t('Pos.RedirectAfterCart')) + " : ", 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.form.redirectAfterCart) + " ", 1),
                                  (_ctx.form.redirectAfterCart==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='printerConf')
                      ? (_openBlock(), _createBlock(_component_a_row, { key: 14 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.t('Pos.IpAddress')), 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.form.config.ip_address) + " ", 1),
                                  (_ctx.form.config.ip_address==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.t('Pos.MacAddress')), 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.form.config.mac_address) + " ", 1),
                                  (_ctx.form.config.mac_address==null)
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Emptyfield')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.configItemsUpdate==='printerConf')
                      ? (_openBlock(), _createBlock(_component_a_row, {
                          key: 15,
                          gutter: 16
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.t('Pos.DefaultLanguage')), 1),
                                  (_ctx.form.config.defaultLanguage==='en')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_44, _toDisplayString(_ctx.t('Pos.English')), 1))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.config.defaultLanguage==='fr')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_45, _toDisplayString(_ctx.t('Pos.French')), 1))
                                    : _createCommentVNode("", true),
                                  (_ctx.form.config.defaultLanguage==='ar')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_46, _toDisplayString(_ctx.t('Pos.Arabic')), 1))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", null, [
                                  _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.t('Pos.ProductionPoint')), 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.getProductionPointName(_ctx.form.production_point_id)), 1)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onClose: _ctx.cancelUpdate
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.UpdateDevice')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _cache[38] || (_cache[38] = ($event: any) => (_ctx.update(_ctx.form.id)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.cancelUpdate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.name,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "created_at" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.t('Pos.CreatedAt')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_date_picker, {
                            "show-time": "",
                            placeholder: _ctx.t('Pos.CreatedAt'),
                            class: "date-picker",
                            disabled: "",
                            value: _ctx.formatDate(_ctx.form.created_at)
                          }, null, 8, ["placeholder", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "pinged_at" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.t('Pos.PingedAt')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_date_picker, {
                            "show-time": "",
                            placeholder:  _ctx.t('Pos.PingedAt'),
                            class: "date-picker",
                            disabled: "",
                            value: _ctx.formatDate(_ctx.form.updated_at)
                          }, null, 8, ["placeholder", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "is_active" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_51, _toDisplayString(_ctx.t('Pos.Active')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_switch, {
                            checked: _ctx.form.is_active,
                            "onUpdate:checked": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.is_active) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "type" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.t('Pos.Type')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.form.type,
                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.type) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, { value: "pos" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.pos')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "kitchen_display" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.kitchen_display')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "menu_display" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.menudisplay')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "order_display" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.orderdisplay')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "printer" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.printer')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.configItemsUpdate==='posConf')
                ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_53, _toDisplayString(_ctx.t('Pos.Store')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate!=='printerConf'  )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 1,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "color_primary" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_54, _toDisplayString(_ctx.t('Pos.PrimaryColor')), 1)
                            ]),
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                type: "color",
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.primaryColor) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.form.primaryColor]
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "primary_light" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_55, _toDisplayString(_ctx.t('Pos.SecondaryColor')), 1)
                            ]),
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                type: "color",
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.primaryLight) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.form.primaryLight]
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "color_secondary" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_56, _toDisplayString(_ctx.t('Pos.otherColor')), 1)
                            ]),
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                type: "color",
                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.secondaryColor) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.form.secondaryColor]
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate==='posConf' || _ctx.configItemsUpdate==='orderConf')
                ? (_openBlock(), _createBlock(_component_a_row, { key: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "store_name" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_57, _toDisplayString(_ctx.t('Pos.StoreName')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.store_name,
                                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.store_name) = $event)),
                                placeholder: _ctx.t('Pos.StoreName'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate!=='printerConf')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 3,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "defaultLanguage" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.t('Pos.DefaultLanguage')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _ctx.form.defaultLanguage,
                                "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.defaultLanguage) = $event)),
                                "show-search": ""
                              }, {
                                notFoundContent: _withCtx(() => [
                                  _createVNode(_component_a_empty, {
                                    description: _ctx.t('Pos.NoData'),
                                    image: _ctx.simpleImage
                                  }, null, 8, ["description", "image"])
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select_option, {
                                    value: "en",
                                    label: _ctx.t('Pos.English')
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Pos.English')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["label"]),
                                  _createVNode(_component_a_select_option, {
                                    value: "fr",
                                    label: _ctx.t('Pos.French')
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Pos.French')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["label"]),
                                  _createVNode(_component_a_select_option, {
                                    value: "ar",
                                    label: _ctx.t('Pos.Arabic')
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Arabic')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["label"])
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "mac_address" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.t('Pos.MacAddress')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.mac_address,
                                "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.mac_address) = $event)),
                                placeholder: _ctx.t('Pos.MacAddress'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate!=='printerConf'  )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 4,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "hibernate" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.t('Pos.SafeSleepTime')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.hibernate,
                                "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.hibernate) = $event)),
                                placeholder: _ctx.t('Pos.SafeSleepTime'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "ip_address" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_61, _toDisplayString(_ctx.t('Pos.IpAddress')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.ip_address,
                                "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.ip_address) = $event)),
                                placeholder: _ctx.t('Pos.IpAddress'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  (_ctx.configItemsUpdate==='posConf')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 0,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "defaultRoute" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_62, _toDisplayString(_ctx.t('Pos.DefaultRoute')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.defaultRoute,
                                "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.form.defaultRoute) = $event)),
                                placeholder: _ctx.t('Pos.DefaultRoute'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.configItemsUpdate==='posConf')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 1,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "defaultTableFilter" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_63, _toDisplayString(_ctx.t('Pos.DefaultTableFilter')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                "show-search": "",
                                allowClear: "",
                                value: _ctx.form.tableLocation_name,
                                "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.tableLocation_name) = $event)),
                                "option-filter-prop": 'label'
                              }, {
                                notFoundContent: _withCtx(() => [
                                  _createVNode(_component_a_empty, {
                                    description: _ctx.t('Pos.NoData'),
                                    image: _ctx.simpleImage
                                  }, null, 8, ["description", "image"])
                                ]),
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tableLocations, (location) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: location.id,
                                      value: location.id,
                                      selected: location.id === _ctx.form.defaultTableFilter ,
                                      label: location.name
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(location.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "selected", "label"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (_ctx.configItemsUpdate==='posConf' )
                ? (_openBlock(), _createBlock(_component_a_row, { key: 5 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "warehouse_id" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_64, _toDisplayString(_ctx.t('Pos.Warehouse')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                "show-search": "",
                                allowClear: "",
                                value: _ctx.form.warehouse_id,
                                "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.form.warehouse_id) = $event)),
                                "option-filter-prop": 'label'
                              }, {
                                notFoundContent: _withCtx(() => [
                                  _createVNode(_component_a_empty, {
                                    description: _ctx.t('Pos.NoData'),
                                    image: _ctx.simpleImage
                                  }, null, 8, ["description", "image"])
                                ]),
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.warehouses, (warehouse) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: warehouse.id,
                                      value: warehouse.id,
                                      label: warehouse.name,
                                      selected: warehouse.id === _ctx.form.warehouse_id 
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(warehouse.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "label", "selected"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate==='posConf')
                ? (_openBlock(), _createBlock(_component_a_row, { key: 6 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_65, _toDisplayString(_ctx.t('Pos.Pos')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate==='posConf')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 7,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "show_table" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.form.show_table,
                                "onUpdate:checked": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.form.show_table) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.ShowTable')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "show_cashier" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.form.show_cashier,
                                "onUpdate:checked": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.form.show_cashier) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.ShowCashier')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "showKeyboard" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.form.showKeyboard,
                                "onUpdate:checked": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.form.showKeyboard) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.ShowKeyboard')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate==='posConf')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 8,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "open_drawer_after_print" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.form.open_drawer_after_print,
                                "onUpdate:checked": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.form.open_drawer_after_print) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.openDrawerAfterPrint')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "allow_print_before" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.form.allow_print_before,
                                "onUpdate:checked": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.form.allow_print_before) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.allowPrintBefore')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "allow_print_after" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.form.allow_print_after,
                                "onUpdate:checked": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.form.allow_print_after) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.allowPrintAfter')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate==='posConf' )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 9,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "show_products" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.form.show_products,
                                "onUpdate:checked": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.form.show_products) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.showProducts')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "show_refundables" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.form.show_refundables,
                                "onUpdate:checked": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.form.show_refundables) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.showRefundables')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "generate_sale" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.form.generate_sale,
                                "onUpdate:checked": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.form.generate_sale) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.generateSale')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate==='posConf')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 10,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "showTextLcd" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_66, _toDisplayString(_ctx.t('Pos.DisplayText')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.showTextLcd,
                                "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.form.showTextLcd) = $event)),
                                placeholder: _ctx.t('Pos.DisplayText'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "showTextLcd" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_67, _toDisplayString(_ctx.t('Pos.footerTxt')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.footer_text,
                                "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.form.footer_text) = $event)),
                                placeholder: _ctx.t('Pos.footerTxt'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate==='posConf' || _ctx.configItemsUpdate==='orderConf' )
                ? (_openBlock(), _createBlock(_component_a_row, { key: 11 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "decimal_precision" }, {
                            label: _withCtx(() => [
                              _createVNode(_component_a_tooltip, null, {
                                title: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.decimalPercisionTxt')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_QuestionCircleOutlined, { class: "tooltip-icon" })
                                ]),
                                _: 1
                              }),
                              _createElementVNode("span", _hoisted_68, _toDisplayString(_ctx.t('Pos.decimalPercision')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.decimal_precision,
                                "onUpdate:value": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.form.decimal_precision) = $event)),
                                placeholder: _ctx.t('Pos.decimalPercision'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate==='posConf' )
                ? (_openBlock(), _createBlock(_component_a_row, { key: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "redirect_after_cart" }, {
                            label: _withCtx(() => [
                              _createVNode(_component_a_tooltip, null, {
                                title: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.RedirectAfterCartTxT')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_QuestionCircleOutlined, { class: "tooltip-icon" })
                                ]),
                                _: 1
                              }),
                              _createElementVNode("span", _hoisted_69, _toDisplayString(_ctx.t('Pos.RedirectAfterCart')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.redirectAfterCart,
                                "onUpdate:value": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.form.redirectAfterCart) = $event)),
                                placeholder: _ctx.t('Pos.RedirectAfterCart'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate==='printerConf' )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 13,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "mac_address" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_70, _toDisplayString(_ctx.t('Pos.MacAddress')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.mac_address,
                                "onUpdate:value": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.form.mac_address) = $event)),
                                placeholder: _ctx.t('Pos.MacAddress'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "ip_address" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_71, _toDisplayString(_ctx.t('Pos.IpAddress')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.ip_address,
                                "onUpdate:value": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.form.ip_address) = $event)),
                                placeholder: _ctx.t('Pos.IpAddress'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItemsUpdate==='printerConf' )
                ? (_openBlock(), _createBlock(_component_a_row, { key: 14 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "production_point_id" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_72, _toDisplayString(_ctx.t('Pos.ProductionPoint')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _ctx.form.production_point_id,
                                "onUpdate:value": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.form.production_point_id) = $event)),
                                "show-search": "",
                                allowClear: "",
                                "option-filter-prop": 'label',
                                onSelect: _ctx.selectedProductionUpdate
                              }, {
                                notFoundContent: _withCtx(() => [
                                  _createVNode(_component_a_empty, {
                                    description: _ctx.t('Pos.NoData'),
                                    image: _ctx.simpleImage
                                  }, null, 8, ["description", "image"])
                                ]),
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.productionPoints, (item) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: item.id,
                                      value: item.id,
                                      label: item.name,
                                      selected: item.id === _ctx.form.production_point_id 
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "label", "selected"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "onSelect"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onClose"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openAdd,
        "onUpdate:open": _cache[69] || (_cache[69] = ($event: any) => ((_ctx.openAdd) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onClose: _ctx.resetFormAdd
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.AddDevice')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _ctx.add
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.resetFormAdd
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "addFormRef",
            model: _ctx.addForm,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_73, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.name,
                            "onUpdate:value": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.addForm.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "is_active" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_74, _toDisplayString(_ctx.t('Pos.Active')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_switch, {
                            checked: _ctx.addForm.is_active,
                            "onUpdate:checked": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.addForm.is_active) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "type" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_75, _toDisplayString(_ctx.t('Pos.Type')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addForm.type,
                            "onUpdate:value": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.addForm.type) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, { value: "pos" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.pos')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "kitchen_display" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.kitchen_display')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "menu_display" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.menudisplay')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "order_display" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.orderdisplay')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_select_option, { value: "printer" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.printer')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.configItems==='posConf')
                ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_76, _toDisplayString(_ctx.t('Pos.Store')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItems!=='printerConf'  )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 1,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "primaryColor" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_77, _toDisplayString(_ctx.t('Pos.PrimaryColor')), 1)
                            ]),
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                type: "color",
                                "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.addForm.primaryColor) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.addForm.primaryColor]
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "primaryLight" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_78, _toDisplayString(_ctx.t('Pos.SecondaryColor')), 1)
                            ]),
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                type: "color",
                                "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.addForm.primaryLight) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.addForm.primaryLight]
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "secondaryColor" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_79, _toDisplayString(_ctx.t('Pos.otherColor')), 1)
                            ]),
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                type: "color",
                                "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.addForm.secondaryColor) = $event))
                              }, null, 512), [
                                [_vModelText, _ctx.addForm.secondaryColor]
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItems==='posConf' || _ctx.configItems === 'orderConf' )
                ? (_openBlock(), _createBlock(_component_a_row, { key: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "store_name" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_80, _toDisplayString(_ctx.t('Pos.StoreName')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.store_name,
                                "onUpdate:value": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.addForm.store_name) = $event)),
                                placeholder: _ctx.t('Pos.StoreName'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "defaultLanguage" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_81, _toDisplayString(_ctx.t('Pos.DefaultLanguage')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.addForm.defaultLanguage,
                            "onUpdate:value": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.addForm.defaultLanguage) = $event)),
                            "show-search": "",
                            "option-filter-prop": 'label'
                          }, {
                            notFoundContent: _withCtx(() => [
                              _createVNode(_component_a_empty, {
                                description: _ctx.t('Pos.NoData'),
                                image: _ctx.simpleImage
                              }, null, 8, ["description", "image"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select_option, {
                                value: "en",
                                label: _ctx.t('Pos.English')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.English')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_select_option, {
                                value: "fr",
                                label: _ctx.t('Pos.French')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.French')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_select_option, {
                                value: "ar",
                                label: _ctx.t('Pos.Arabic')
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Arabic')), 1)
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "mac_address" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_82, _toDisplayString(_ctx.t('Pos.MacAddress')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.mac_address,
                            "onUpdate:value": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.addForm.mac_address) = $event)),
                            placeholder: _ctx.t('Pos.MacAddress'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.configItems!=='printerConf'  )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 3,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "hibernate" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_83, _toDisplayString(_ctx.t('Pos.SafeSleepTime')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.hibernate,
                                "onUpdate:value": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.addForm.hibernate) = $event)),
                                placeholder: _ctx.t('Pos.SafeSleepTime'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "ip_address" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_84, _toDisplayString(_ctx.t('Pos.IpAddress')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.ip_address,
                                "onUpdate:value": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.addForm.ip_address) = $event)),
                                placeholder: _ctx.t('Pos.IpAddress'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  (_ctx.configItems==='posConf')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 0,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "defaultRoute" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_85, _toDisplayString(_ctx.t('Pos.DefaultRoute')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.defaultRoute,
                                "onUpdate:value": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.addForm.defaultRoute) = $event)),
                                placeholder: _ctx.t('Pos.DefaultRoute'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.configItems==='posConf' )
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 1,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "defaultTableFilter" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_86, _toDisplayString(_ctx.t('Pos.DefaultTableFilter')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                "show-search": "",
                                allowClear: "",
                                value: _ctx.addForm.defaultTableFilter,
                                "onUpdate:value": _cache[52] || (_cache[52] = ($event: any) => ((_ctx.addForm.defaultTableFilter) = $event)),
                                "option-filter-prop": 'label'
                              }, {
                                notFoundContent: _withCtx(() => [
                                  _createVNode(_component_a_empty, {
                                    description: _ctx.t('Pos.NoData'),
                                    image: _ctx.simpleImage
                                  }, null, 8, ["description", "image"])
                                ]),
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tableLocations, (location) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: location.id,
                                      value: location.id,
                                      selected: location.id === _ctx.addForm.defaultTableFilter ,
                                      label: location.name
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(location.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "selected", "label"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (_ctx.configItems==='posConf' )
                ? (_openBlock(), _createBlock(_component_a_row, { key: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "warehouse_id" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_87, _toDisplayString(_ctx.t('Pos.Warehouse')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                "show-search": "",
                                allowClear: "",
                                value: _ctx.addForm.warehouse_id,
                                "onUpdate:value": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.addForm.warehouse_id) = $event)),
                                "option-filter-prop": 'label'
                              }, {
                                notFoundContent: _withCtx(() => [
                                  _createVNode(_component_a_empty, {
                                    description: _ctx.t('Pos.NoData'),
                                    image: _ctx.simpleImage
                                  }, null, 8, ["description", "image"])
                                ]),
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.warehouses, (warehouse) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: warehouse.id,
                                      value: warehouse.id,
                                      label: warehouse.name,
                                      selected: warehouse.id === _ctx.addForm.warehouse_id 
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(warehouse.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "label", "selected"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItems==='posConf' )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 5,
                    span: 12
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", _hoisted_88, _toDisplayString(_ctx.t('Pos.Pos')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItems==='posConf' )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 6,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "show_table" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.addForm.show_table,
                                "onUpdate:checked": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.addForm.show_table) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.ShowTable')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "show_table" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.addForm.show_cashier,
                                "onUpdate:checked": _cache[55] || (_cache[55] = ($event: any) => ((_ctx.addForm.show_cashier) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.ShowCashier')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "show_table" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.addForm.showKeyboard,
                                "onUpdate:checked": _cache[56] || (_cache[56] = ($event: any) => ((_ctx.addForm.showKeyboard) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.ShowKeyboard')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItems==='posConf' )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 7,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "open_drawer" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.addForm.open_drawer_after_print,
                                "onUpdate:checked": _cache[57] || (_cache[57] = ($event: any) => ((_ctx.addForm.open_drawer_after_print) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.openDrawerAfterPrint')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "allow_print_before" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.addForm.allow_print_before,
                                "onUpdate:checked": _cache[58] || (_cache[58] = ($event: any) => ((_ctx.addForm.allow_print_before) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.allowPrintBefore')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "allow_print_after" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.addForm.allow_print_after,
                                "onUpdate:checked": _cache[59] || (_cache[59] = ($event: any) => ((_ctx.addForm.allow_print_after) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.allowPrintAfter')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItems==='posConf' )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 8,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "show_products" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.addForm.show_products,
                                "onUpdate:checked": _cache[60] || (_cache[60] = ($event: any) => ((_ctx.addForm.show_products) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.showProducts')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "show_refundables" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.addForm.show_refundables,
                                "onUpdate:checked": _cache[61] || (_cache[61] = ($event: any) => ((_ctx.addForm.show_refundables) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.showRefundables')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "generate_sale" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox, {
                                checked: _ctx.addForm.generate_sale,
                                "onUpdate:checked": _cache[62] || (_cache[62] = ($event: any) => ((_ctx.addForm.generate_sale) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.generateSale')), 1)
                                ]),
                                _: 1
                              }, 8, ["checked"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItems==='posConf' )
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 9,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "showTextLcd" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_89, _toDisplayString(_ctx.t('Pos.DisplayText')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.showTextLcd,
                                "onUpdate:value": _cache[63] || (_cache[63] = ($event: any) => ((_ctx.addForm.showTextLcd) = $event)),
                                placeholder: _ctx.t('Pos.DisplayText'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "footer_text" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_90, _toDisplayString(_ctx.t('Pos.footerTxt')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.footer_text,
                                "onUpdate:value": _cache[64] || (_cache[64] = ($event: any) => ((_ctx.addForm.footer_text) = $event)),
                                placeholder: _ctx.t('Pos.footerTxt'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItems==='posConf' || _ctx.configItems==='orderConf' )
                ? (_openBlock(), _createBlock(_component_a_row, { key: 10 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "decimal_precision" }, {
                            label: _withCtx(() => [
                              _createVNode(_component_a_tooltip, null, {
                                title: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.decimalPercisionTxt')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_QuestionCircleOutlined, { class: "tooltip-icon" })
                                ]),
                                _: 1
                              }),
                              _createElementVNode("span", _hoisted_91, _toDisplayString(_ctx.t('Pos.decimalPercision')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.decimal_precision,
                                "onUpdate:value": _cache[65] || (_cache[65] = ($event: any) => ((_ctx.addForm.decimal_precision) = $event)),
                                placeholder: _ctx.t('Pos.decimalPercision'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItems==='posConf')
                ? (_openBlock(), _createBlock(_component_a_row, { key: 11 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "redirect_after_cart" }, {
                            label: _withCtx(() => [
                              _createVNode(_component_a_tooltip, null, {
                                title: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('Pos.RedirectAfterCartTxT')), 1)
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_QuestionCircleOutlined, { class: "tooltip-icon" })
                                ]),
                                _: 1
                              }),
                              _createElementVNode("span", _hoisted_92, _toDisplayString(_ctx.t('Pos.RedirectAfterCart')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.redirect_after_cart,
                                "onUpdate:value": _cache[66] || (_cache[66] = ($event: any) => ((_ctx.addForm.redirect_after_cart) = $event)),
                                placeholder: _ctx.t('Pos.RedirectAfterCart'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.configItems ==='printerConf')
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 12,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "ip_address" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_93, _toDisplayString(_ctx.t('Pos.IpAddress')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.addForm.ip_address,
                                "onUpdate:value": _cache[67] || (_cache[67] = ($event: any) => ((_ctx.addForm.ip_address) = $event)),
                                placeholder: _ctx.t('Pos.IpAddress'),
                                class: "input-form"
                              }, null, 8, ["value", "placeholder"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, { name: "production_point_id" }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_94, _toDisplayString(_ctx.t('Pos.ProductionPoint')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                "show-search": "",
                                allowClear: "",
                                value: _ctx.addForm.production_point_id,
                                "onUpdate:value": _cache[68] || (_cache[68] = ($event: any) => ((_ctx.addForm.production_point_id) = $event)),
                                "option-filter-prop": 'label',
                                onSelect: _ctx.selectedProduction
                              }, {
                                notFoundContent: _withCtx(() => [
                                  _createVNode(_component_a_empty, {
                                    description: _ctx.t('Pos.NoData'),
                                    image: _ctx.simpleImage
                                  }, null, 8, ["description", "image"])
                                ]),
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.productionPoints, (item) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: item.id,
                                      value: item.id,
                                      label: item.name,
                                      selected: item.id === _ctx.addForm.production_point_id 
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "label", "selected"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "onSelect"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onClose"]),
      _createVNode(_component_a_modal, {
        open: _ctx.openModal,
        "onUpdate:open": _cache[71] || (_cache[71] = ($event: any) => ((_ctx.openModal) = $event)),
        "confirm-loading": _ctx.confirmLoading,
        onOk: _ctx.handleOk,
        onCancel: _ctx.resetFormLogin
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.Login')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            key: "submit",
            type: "primary",
            onClick: _ctx.handleOk
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Login')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            key: "back",
            onClick: _ctx.handleCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "logInFormRef",
            model: _ctx.loginForm,
            rules: _ctx.logInRules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                "has-feedback": "",
                name: "code"
              }, {
                label: _withCtx(() => [
                  _createElementVNode("span", _hoisted_95, _toDisplayString(_ctx.t('Pos.DeviceAuthCode')), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.loginForm.code,
                    "onUpdate:value": _cache[70] || (_cache[70] = ($event: any) => ((_ctx.loginForm.code) = $event)),
                    autocomplete: "off"
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "confirm-loading", "onOk", "onCancel"])
    ])
  ], 64))
}