<template>
   <a-result status="404" title="404" sub-title="Sorry, the page you visited does not exist.">
    <template #extra>
      <a-button type="primary" @click="navigatePage('/')">Back Home</a-button>
    </template>
  </a-result>
</template>

<script lang="ts">
    import { defineComponent, ref, reactive, onMounted, toRaw, watch } from 'vue';
    import { useRouter } from 'vue-router';

    export default defineComponent({
        name: 'indexNotFound',
        components: {
           
        },
        
        setup() {

            const router = useRouter();
            const navigatePage = (link: string) => {
                router.push(link); 
            };
            return{
                navigatePage
            };
        }
    })
</script>
    
<style scoped>

</style>