import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e87da7b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tbls-container" }
const _hoisted_2 = ["onMousedown"]
const _hoisted_3 = { class: "table-number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SaveOutlined = _resolveComponent("SaveOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { gutter: 16 }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, {
        xs: { span: 24},
        sm: { span: 24},
        md: {span: 24},
        lg: { span: 24 },
        xl: { span: 24 },
        xxl: { span: 24 },
        class: "card-container"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_card, {
            class: "tables-card-container",
            "tab-list": _ctx.tableLocations,
            "active-tab-key": _ctx.locationKey,
            onTabChange: _cache[0] || (_cache[0] = key => _ctx.onTabChange(key, 'locationKey'))
          }, {
            tabBarExtraContent: _withCtx(() => [
              _createVNode(_component_a_tooltip, null, {
                title: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.SaveLocation')), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_SaveOutlined, { class: "save-icon" })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tables, (table) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: table.id,
                    class: _normalizeClass(['table', table.shape === 'round' ? 'round' : 'square']),
                    style: _normalizeStyle({ left: table.x + 'px', top: table.y + 'px' }),
                    onMousedown: ($event: any) => (_ctx.startDragging($event, table))
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(['table-status', table.status.toLowerCase()])
                    }, _toDisplayString(table.status), 3),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", null, _toDisplayString(table.number), 1)
                    ])
                  ], 46, _hoisted_2))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["tab-list", "active-tab-key"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}