import {authHeader, ApiConfigs} from "../_helpers";
import { authService } from "./auth.service";

export const cartService = {
    getAll,
    show
    
};


function getAll(page : any , perPage : any) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.cart.getAll}?page=${page}&per_page=${perPage}`, requestOptions)
        .then(
            handleResponse
        );
}
function show(id: string, attribute:string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.cart.show.replace(':id', id).replace(':attribute', attribute)}`, requestOptions)
        .then(
            handleResponse
        );
}



function handleResponse(response: Response) {
    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api

                localStorage.removeItem('user');
                localStorage.removeItem("store");

                location.reload();
                authService.logout();
            }
            const error = (data && data.message) || response.status || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}