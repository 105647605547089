function server() {
    return process.env.VUE_APP_API_URL_TN;
}

export const ApiConfigs = {
    base_url: server(),
    /* -------------------------------- */
    // PUSHER
    pusher: {
        auth: "/auth",
    },
    // ACCOUNT
    accounts: {
        captcha: process.env.VUE_APP_CAPTCHA_LINK,
        login: "/auth",
        logout: "/logout",
        Register:"/register"
    },

    activitiesCategories : {

        getAll: "/activity_categories?pagination=off",

    },
    accountsList: {
        getAll: "/accounts",
    },
    
    warehouse: {
        getAll: "/warehouses",
        show: "/warehouses/:id",
        store: "/warehouses",
        update: "/warehouses/:id",
        delete: "/warehouses/:id"
    },

    customer: {
        getAll: "/customers",
        show: "/customers/:id",
        store: "/customers",
        update: "/customers/:id",
        delete: "/customers/:id",
        searchWithoutPagination: "/customers?pagination=off"

    },
    product:{
        getAll:"/products", 
        getById:"/products/:id",
        add:"/products",
        update:"/products/:id/update",
        delete:"/products/",
    },
    productVarinat:{
       
        add:"/products/:id/variants",
        getAll:"/products/:id/variants",
        show:"/products/:id/variants/:variant",
        update:"/products/:id/variants/:variant",
        delete:"/products/:id/variants/:variant"
    },
    productStock:{
        add:"/products/:id/stock",
        getAll:"/products/:id/stock",
        show:"/products/:id/variants/:stock",
        update:"/products/:id/variants/:stock",
        delete:"/products/:id/variants/:stock"
    },
    ingredients:{
        getAll:"/ingredients",
        show:"/ingredients/:id",
        add:"/ingredients",
        update:"/ingredients/:id/update",
        delete:"/ingredients/:id"
    },
    category:{
        getAll: "/categories",
        show: "/categories/:id",
        add:"/categories",
        update:"/categories/:id",
        delete: "/categories/:id"
    },
    currency:{
        getAll: "/store/currencies",
        show: "/store/currencies/:id",
        add:"/store/currencies",
        update:"/store/currencies/:id",
        delete:"/store/currencies/:id",
        getList:"/currencies?pagination=off"
    },


   

    manufacturer:{
        getAll:"/manufacturers",
        add:"/manufacturers",
        show: "/manufacturers/:id",
        update:"/manufacturers/:id",
        delete: "/manufacturers/:id"
    },
    store:{
        getAll:"/stores",
        create:"/stores",
        show: "/stores/:id",
        update:"/stores/:id",
        delete: "/stores/:id",
        userStores:"/my-stores",
        switch:"/switch-store/:id",
        MyStores:"/my-stores"
    },
    supplier:{
        getAll:"/suppliers",
        create:"/suppliers",
        show: "/suppliers/:id",
        update:"/suppliers/:id",
        delete: "/suppliers/:id"
    },
    tables:{
        getAll:"/tables",
        create:"/tables",
        show: "/tables/:id",
        update:"/tables/:id",
        delete: "/tables/:id",
        filter:"/tables/availability"
    },
    tableLocation:{
        getAll:"/table-locations",
        create:"/table-locations",
        show: "/table-locations/:id",
        update:"/table-locations/:id/update",
        delete: "/table-locations/:id" 
    },
    discount:{
        getAll:"/discounts",
        create:"/discounts",
        show: "/discounts/:id",
        update:"/discounts/:id",
        delete: "/discounts/:id"
    },
    coupon:{
        getAll:"/coupons",
        create:"/coupons",
        show: "/coupons/:id",
        update:"/coupons/:id",
        delete: "/coupons/:id"
    },
    ingredientCategories:{
        getAll:"/ingredient_categories",
        create:"/ingredient_categories",
        show: "/ingredient_categories/:id",
        update:"/ingredient_categories/:id",
        delete: "/ingredient_categories/:id"
    },
    variant:{
        getAll:"/variants",
        create:"/variants",
        show: "/variants/:id",
        update:"/variants/:id",
        delete: "/variants/:id"
    },
    attribute:{
        getAll:"/attributes",
        create:"/attributes",
        show: "/attributes/:id",
        update:"/attributes/:id",
        delete: "/attributes/:id"
    },
    attributeValues:{
        getAll:"/attributes/:id/values",
        create:"/attributes/:id/values",
        show: "/attributes/:id/values/:attribute",
        update:"/attributes/:id/values/:attribute",
        delete: "/attributes/:id/values/:attribute"
    },
    vatrate:{
        getAll:"/vat-rates",
        create:"/vat-rates",
        show: "/vat-rates/:id",
        update:"/vat-rates/:id",
        delete: "/vat-rates/:id"
    },
    role:{
        getAll:"/roles",
        create:"/roles",
        show: "/roles/:id",
        update:"/roles/:id",
        delete: "/roles/:id"
    },
    user:{
        getAll:"/users",
        create:"/users",
        show: "/users/:id",
        update:"/users/:id",
        delete: "/users/:id",
        checkEmail:"/users/verify-email"
    },
    payment:{
        getAll:"/payments",
        create:"/payments",
        show: "/payments/:id",
        update:"/payments/:id",
        delete: "/payments/:id"
    },

    invoices:{
        create:"/invoices",
        generate:"/invoices/:id/generate",
        getAll:"/invoices"

    },
    paymentMethods:{
        getAll:"/store/payment-methods",
        create:"/store/payment-methods",
        show: "/store/payment-methods/:id",
        update: "/store/payment-methods/:id",
        delete: "/store/payment-methods/:id",
        getNames:"/payment-methods?pagination=off"
    },
    loyalty:{
        getAll:"/loyalty-settings",
        create:"/loyalty-settings",
        show: "/loyalty-settings/:id",
        update: "/loyalty-settings/:id",
        delete: "/loyalty-settings/:id",
    },
    productionPoint:{
        getAll:"/production-points",
        create:"/production-points",
        show: "/production-points/:id",
        update:"/production-points/:id",
        delete: "/production-points/:id"
    },
    device:{
        getAll:"/devices",
        create:"/devices",
        show: "/devices/:id",
        update:"/devices/:id",
        delete: "/devices/:id",
        connect:"/devices/",
        logout:"/devices/:id/logout",
        refresh:"/devices/:id/refresh"
    },
    customFields:{
        getAll:"/custom-fields",
        create:"/custom-fields",
        show: "/custom-fields/:id",
        update:"/custom-fields/:id",
        delete: "/custom-fields/:id"
    },
    inventory:{
        getAll:"/inventory_transactions",
        create:"/inventory_transactions",
        show: "/inventory_transactions/:id",
        update:"/inventory_transactions/:id",
        delete: "/inventory_transactions/:id"
    },
    tableReservation:{
        getAll:"/table-reservations",
        create:"/table-reservations",
        show: "/table-reservations/:id",
        update:"/table-reservations/:id",
        delete: "/table-reservations/:id"
    },
    sales:{
        getAll:"/sales",
        show: "/sales/:id",
        exportFile:"/sales/report",

        
    },
    stock:{
        getAll:"/stock-items",
        create:"/stock-items",
        show: "/stock-items/:id",
        update:"/stock-items/:id",
        delete: "/stock-items/:id",
        transfer:"/stock-items/:id/transfer",
        export:"/stock-items-report"
    },
    statictics:{
        sales:"/statistics/sales",
        products:"/statistics/products",
        payements:"/statistics/payment-methods",
        revenu:"/statistics/revenue",
        discount:"/statistics/discount",
        revenuEff:"/statistics/revenue-efficient",
        discountEff:"/statistics/discount-efficient"
    },
    stockAdjustment:{
        create:"/stock_adjustments"
    },
    purchaseInvoice:{
        getAll:"/purchase_invoices",
        create:"/purchase_invoices",
        show: "/purchase_invoices/:id",
        update:"/purchase_invoices/:id",
        delete: "/purchase_invoices/:id",
    },
    preference:{
        getAll:"/preferences",
        create:"/preferences",
        show: "/preferences/:id",
        update:"/preferences/:id",
        delete: "/preferences/:id",
    },

    cart:{
        getAll:"/carts",
        show: "/carts/:id"
    },
    menu:{
        getAll:"/menu",
        create:"/menu",
        show: "/menu/:id",
        update:"/menu/:id",
        delete: "/menu/:id",
        attach:"/menu/:id/:cId/:pId",
        dettach:"/menu/:id/:cId/:pId"
    }


};
