import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue';

export const eventBus = createApp({});

import {createI18n} from "vue-i18n";
import { onBeforeUnmount ,ref  } from 'vue';

import {currencyService} from'@/_services';
import fr from "./locales/fr.json";
import en from "./locales/en.json";
import ar from "./locales/ar.json";

if (!localStorage.getItem('Language')) {
    localStorage.setItem('Language', 'en')
}




const i18n = createI18n({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    locale: localStorage.getItem('Language'),
    legacy: false,
    fallbackLocale: "en",
    messages: {fr, en, ar},
});

const themeTemplate = {
    primaryColor: "#fc8019",
    confirmationColor: "#09aa29",
    cart: {
        selectCartItemColor: "#09aa29"
    }
}
localStorage.setItem("themeTemplate", JSON.stringify(themeTemplate))
createApp(App).use(router).use(Antd).use(i18n).mount('#app')


const getCurrentCurrency=()=>{
    currencyService.getAllWithoutPagination().then((res)=>{
        const currencies = res.data;

        console.log('currencies', currencies);

        const defaultCurrency = currencies.find((currency:any) => currency.is_default === 1);
        if (defaultCurrency) {
            //state.currentCurrency = defaultCurrency.currency.code;
            console.log("defaultCurrency",defaultCurrency.currency)
            localStorage.setItem("currencies",JSON.stringify(defaultCurrency.currency))
        } else {
            console.error("Default currency not found.");
        }
    }).catch((error)=>{
        console.log(error);
    });
}

getCurrentCurrency();


