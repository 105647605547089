import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2daf9713"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "btn-container" }
const _hoisted_2 = { style: {"padding":"8px"} }
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = { class: "label-form" }
const _hoisted_5 = { class: "label-form" }
const _hoisted_6 = { class: "label-form" }
const _hoisted_7 = { class: "label-form" }
const _hoisted_8 = { class: "label-form" }
const _hoisted_9 = { class: "label-form" }
const _hoisted_10 = { class: "label-form" }
const _hoisted_11 = { class: "label-form" }
const _hoisted_12 = { class: "label-form" }
const _hoisted_13 = { class: "label-form" }
const _hoisted_14 = { class: "label-form" }
const _hoisted_15 = { class: "label-form" }
const _hoisted_16 = ["placeholder"]
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = { class: "info-title" }
const _hoisted_19 = { class: "info-title" }
const _hoisted_20 = { class: "info-title" }
const _hoisted_21 = { class: "label-form" }
const _hoisted_22 = { class: "label-form" }
const _hoisted_23 = { class: "label-form" }
const _hoisted_24 = { class: "label-form" }
const _hoisted_25 = { class: "label-form" }
const _hoisted_26 = { class: "label-form" }
const _hoisted_27 = { class: "label-form" }
const _hoisted_28 = { class: "label-form" }
const _hoisted_29 = { class: "label-form" }
const _hoisted_30 = { class: "label-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_AppstoreOutlined = _resolveComponent("AppstoreOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, { style: {"margin":"16px 0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Dashboard')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Categories')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        class: "add-btn",
        onClick: _ctx.showAddDrawer
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_AppstoreOutlined)
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')) + " ", 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        class: "table-container",
        columns: _ctx.columns,
        "data-source": _ctx.state.data,
        pagination: false
      }, {
        emptyText: _withCtx(() => [
          _createVNode(_component_a_empty, {
            description: _ctx.t('Pos.NoData'),
            image: _ctx.simpleImage
          }, null, 8, ["description", "image"])
        ]),
        bodyCell: _withCtx(({ column, record }) => [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        class: "view-btn",
                        onClick: () => _ctx.showDisplayDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: () => _ctx.showDrawer(record)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Delete')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        danger: "",
                        onClick: ($event: any) => (_ctx.showDeletePopup(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_DeleteOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key === 'created_at' || column.key === 'updated_at')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.formatDisplayDate(record.created_at)), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({ setSelectedKeys, selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_input, {
              ref: "searchInput",
              placeholder: `${column.title}`,
              value: selectedKeys[0],
              style: {"width":"188px","margin-bottom":"8px","display":"block"},
              onChange: e => setSelectedKeys(e.target.value ? [e.target.value] : []),
              onPressEnter: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, null, 8, ["placeholder", "value", "onChange", "onPressEnter"]),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_pagination, {
          class: "paginationArrows",
          current: _ctx.currentPage,
          "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentPage) = $event)),
          pageSize: _ctx.perPage,
          "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.perPage) = $event)),
          total: _ctx.state.totalPages,
          "default-page-size": _ctx.perPage,
          onChange: _ctx.handlePageChange,
          showSizeChanger: false
        }, null, 8, ["current", "pageSize", "total", "default-page-size", "onChange"]),
        (_ctx.state.totalPages>10)
          ? (_openBlock(), _createBlock(_component_a_select, {
              key: 0,
              value: _ctx.selectPagination,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectPagination) = $event)),
              onChange: _ctx.handleSelectPaginationChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select_option, { value: "10" }, {
                  default: _withCtx(() => [
                    _createTextVNode("10 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "20" }, {
                  default: _withCtx(() => [
                    _createTextVNode("20 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "50" }, {
                  default: _withCtx(() => [
                    _createTextVNode("50 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "100" }, {
                  default: _withCtx(() => [
                    _createTextVNode("100 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "onChange"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterOpenChange
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.UpdateCategory')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateCategory(_ctx.form.id)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Update')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.cancelUpdate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.name,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "display_rank" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('Pos.DisplayRank')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.form.display_rank,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.display_rank) = $event)),
                            placeholder:  _ctx.t('Pos.DisplayRank'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "created_at" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t('Pos.CreatedAt')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_date_picker, {
                            "show-time": "",
                            placeholder: _ctx.t('Pos.CreatedAt'),
                            class: "date-picker",
                            disabled: "",
                            value: _ctx.formatDate(_ctx.form.created_at)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" --> ")
                            ]),
                            _: 1
                          }, 8, ["placeholder", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "updated_at" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.UpdatedAt')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_date_picker, {
                            "show-time": "",
                            placeholder: _ctx.t('Pos.UpdatedAt'),
                            class: "date-picker",
                            disabled: "",
                            value: _ctx.formatDate(_ctx.form.updated_at)
                          }, null, 8, ["placeholder", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "description" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.Description')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_textarea, {
                            value: _ctx.form.description,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.description) = $event)),
                            rows: 4,
                            placeholder: _ctx.t('Pos.Description')
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.customFields, (item) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: item }, [
                  (item.type === 'text')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 0,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            rules: [item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                class: "custom-input",
                                id: 'txtUpdate_' + item.id,
                                value: item.value,
                                "onUpdate:value": ($event: any) => ((item.value) = $event)
                              }, null, 8, ["id", "value", "onUpdate:value"])
                            ]),
                            _: 2
                          }, 1032, ["rules"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  ( item.type === 'textarea')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 1,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            rules: [item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                class: "custom-input",
                                value: item.value,
                                "onUpdate:value": ($event: any) => ((item.value) = $event),
                                id: 'areaUpdate_' + item.id,
                                rows: 4
                              }, null, 8, ["value", "onUpdate:value", "id"])
                            ]),
                            _: 2
                          }, 1032, ["rules"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  ( item.type === 'number')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 2,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            rules: [item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_number, {
                                class: "custom-input",
                                value: item.value,
                                "onUpdate:value": ($event: any) => ((item.value) = $event),
                                id: 'numberUpdate_' + item.id
                              }, null, 8, ["value", "onUpdate:value", "id"])
                            ]),
                            _: 2
                          }, 1032, ["rules"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  ( item.type === 'date')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 3,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            rules: [item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_date_picker, {
                                "show-time": "",
                                class: "custom-input",
                                value: item.value,
                                "onUpdate:value": ($event: any) => ((item.value) = $event),
                                id: 'dateUpdate_' + item.id
                              }, null, 8, ["value", "onUpdate:value", "id"])
                            ]),
                            _: 2
                          }, 1032, ["rules"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  ( item.type === 'select')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 4,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            rules: [item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: item.value,
                                "onUpdate:value": ($event: any) => ((item.value) = $event),
                                id: 'selectUpdate_' + item.id,
                                onChange: _ctx.handleSelectUpdate
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: option,
                                      value: option
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(option), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["value", "onUpdate:value", "id", "onChange"])
                            ]),
                            _: 2
                          }, 1032, ["rules"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (item.type === 'checkbox')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 5,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            rules: [item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_checkbox_group, {
                                value: item.value,
                                "onUpdate:value": ($event: any) => ((item.value) = $event),
                                id: 'checkUpdate_' + item.id
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(','), (option) => {
                                    return (_openBlock(), _createBlock(_component_a_checkbox, {
                                      key: option.trim(),
                                      value: option.trim(),
                                      style: {"line-height":"32px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(option.trim()), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["value", "onUpdate:value", "id"])
                            ]),
                            _: 2
                          }, 1032, ["rules"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  ( item.type === 'radio')
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 6,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            rules: [item.is_mandatory === 1 ? { required: true, message: 'This field is required' } : {}]
                          }, {
                            label: _withCtx(() => [
                              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_radio_group, {
                                id: 'radioUpdate_' + item.id,
                                value: item.value,
                                "onUpdate:value": ($event: any) => ((item.value) = $event)
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                    return (_openBlock(), _createBlock(_component_a_radio, {
                                      key: option,
                                      value: option,
                                      style: {"line-height":"32px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(option), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["id", "value", "onUpdate:value"])
                            ]),
                            _: 2
                          }, 1032, ["rules"])
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openDisplay,
        "onUpdate:open": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.openDisplay) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onAfterOpenChange: _ctx.afterOpenShowChange
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.CategoryInfo')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.cancelUpdate
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", {
                          class: "info-title",
                          placeholder: _ctx.t('Pos.Name')
                        }, _toDisplayString(_ctx.t('Pos.Name')) + " :", 9, _hoisted_16),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.name), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", {
                          class: "info-title",
                          placeholder: _ctx.t('Pos.Description')
                        }, _toDisplayString(_ctx.t('Pos.Description')) + " :", 9, _hoisted_17),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.description), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  (_ctx.form.display_rank!==null)
                    ? (_openBlock(), _createBlock(_component_a_col, {
                        key: 0,
                        span: 12
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, [
                            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t('Pos.DisplayRank')) + ":", 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.form.display_rank), 1)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.t('Pos.CreatedAt')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.formatDateDrawer(_ctx.form.created_at)), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.form.customData!==null)
                ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.customFields, (item) => {
                        return (_openBlock(), _createBlock(_component_a_col, {
                          key: item.id,
                          span: 12
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, [
                              _createElementVNode("span", _hoisted_20, _toDisplayString(item.name) + " :", 1),
                              _createTextVNode(" " + _toDisplayString(item.value), 1)
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"]),
      _createVNode(_component_a_drawer, {
        open: _ctx.openAdd,
        "onUpdate:open": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.openAdd) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        onClose: _ctx.resetFormAdd
      }, {
        title: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, _toDisplayString(_ctx.t('Pos.AddCategory')), 3)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_space, {
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: _ctx.addCategory
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Add')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_a_button, {
                type: "primary",
                danger: "",
                onClick: _ctx.resetFormAdd
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('Pos.Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "addFormRef",
            model: _ctx.addForm,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "name" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t('Pos.Name')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.name,
                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addForm.name) = $event)),
                            placeholder: _ctx.t('Pos.Name'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "display_rank" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('Pos.DisplayRank')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.display_rank,
                            "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.addForm.display_rank) = $event)),
                            placeholder:  _ctx.t('Pos.DisplayRank'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { name: "description" }, {
                        label: _withCtx(() => [
                          _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.t('Pos.Description')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.addForm.description,
                            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.addForm.description) = $event)),
                            placeholder: _ctx.t('Pos.Description'),
                            class: "input-form"
                          }, null, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.state.customFields !== null)
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 0,
                    gutter: 16
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.customFields, (item) => {
                        return (_openBlock(), _createBlock(_component_a_col, {
                          key: item,
                          span: 12
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                (item.type==='text')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: 'customData_' + item.id,
                                      class: "dynamic-input",
                                      validateStatus: _ctx.getErrorStatus(item.id),
                                      help: _ctx.getErrorText(item.id)
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input, {
                                          class: "custom-input",
                                          id: 'txt_' + item.id,
                                          onBlur: ($event: any) => (_ctx.handleError(item))
                                        }, null, 8, ["id", "onBlur"])
                                      ]),
                                      _: 2
                                    }, 1032, ["name", "validateStatus", "help"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                (item.type==='textarea')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      class: "dynamic-input",
                                      validateStatus: _ctx.getErrorStatus(item.id),
                                      help: _ctx.getErrorText(item.id)
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_textarea, {
                                          class: "custom-input",
                                          id: 'area_' + item.id,
                                          rows: 4,
                                          onBlur: ($event: any) => (_ctx.handleError(item))
                                        }, null, 8, ["id", "onBlur"])
                                      ]),
                                      _: 2
                                    }, 1032, ["validateStatus", "help"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                (item.type==='number')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      class: "dynamic-input",
                                      validateStatus: _ctx.getErrorStatus(item.id),
                                      help: _ctx.getErrorText(item.id)
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input_number, {
                                          class: "custom-input",
                                          id: 'number_' + item.id,
                                          onBlur: ($event: any) => (_ctx.handleError(item))
                                        }, null, 8, ["id", "onBlur"])
                                      ]),
                                      _: 2
                                    }, 1032, ["validateStatus", "help"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                (item.type==='date')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      class: "dynamic-input",
                                      validateStatus: _ctx.getErrorStatus(item.id),
                                      help: _ctx.getErrorText(item.id)
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_date_picker, {
                                          "show-time": "",
                                          class: "custom-input",
                                          id: 'date_' + item.id,
                                          onBlur: ($event: any) => (_ctx.handleError(item))
                                        }, null, 8, ["id", "onBlur"])
                                      ]),
                                      _: 2
                                    }, 1032, ["validateStatus", "help"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                (item.type==='select')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      class: "dynamic-input",
                                      validateStatus: _ctx.getErrorStatus(item.id),
                                      help: _ctx.getErrorText(item.id)
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select, {
                                          id: 'select_' + item.id,
                                          onChange: _ctx.handleSelectChange
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                key: option,
                                                value: option,
                                                onBlur: ($event: any) => (_ctx.handleError(item))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(option), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value", "onBlur"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["id", "onChange"])
                                      ]),
                                      _: 2
                                    }, 1032, ["validateStatus", "help"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                (item.type==='checkbox')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      rules: [item.is_mandatory === 1 ? { required: true, message: _ctx.t('Pos.RequiredField') } : {}],
                                      validateStatus: _ctx.getErrorStatus(item.id),
                                      help: _ctx.getErrorText(item.id)
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_checkbox_group, {
                                          id: 'check_' + item.id
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                              return (_openBlock(), _createBlock(_component_a_checkbox, {
                                                key: option,
                                                value: option,
                                                style: {"line-height":"32px"},
                                                onBlur: ($event: any) => (_ctx.handleError(item))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(option), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value", "onBlur"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["id"])
                                      ]),
                                      _: 2
                                    }, 1032, ["rules", "validateStatus", "help"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_a_row, null, {
                              default: _withCtx(() => [
                                (item.type==='radio')
                                  ? (_openBlock(), _createBlock(_component_a_form_item, {
                                      key: 0,
                                      name: "customData",
                                      rules: [item.is_mandatory === 1 ? { required: true, message: _ctx.t('Pos.RequiredField') } : {}],
                                      validateStatus: _ctx.getErrorStatus(item.id),
                                      help: _ctx.getErrorText(item.id)
                                    }, {
                                      label: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.transformUpperCase(item.name)), 1)
                                      ]),
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_radio_group, {
                                          id: 'radio_' + item.id
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options.split(',').filter(option => option.trim() !== ''), (option) => {
                                              return (_openBlock(), _createBlock(_component_a_radio, {
                                                key: option,
                                                value: option,
                                                style: {"line-height":"32px"},
                                                onBlur: ($event: any) => (_ctx.handleError(item))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(option), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value", "onBlur"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["id"])
                                      ]),
                                      _: 2
                                    }, 1032, ["rules", "validateStatus", "help"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onClose"])
    ])
  ], 64))
}