import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/posys-favicon-transparent.png'
import _imports_1 from '@/assets/img/posys-logo-small.png'


const _withScopeId = n => (_pushScopeId("data-v-c2e9661e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100vh","overflow-y":"auto"} }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = { class: "icon-container" }
const _hoisted_4 = { class: "icon-container" }
const _hoisted_5 = { class: "icon-container" }
const _hoisted_6 = { class: "icon-container" }
const _hoisted_7 = { class: "icon-container" }
const _hoisted_8 = { class: "icon-container" }
const _hoisted_9 = { class: "icon-container" }
const _hoisted_10 = { class: "icon-container" }
const _hoisted_11 = { class: "icon-container" }
const _hoisted_12 = { class: "icon-container" }
const _hoisted_13 = { class: "icon-container" }
const _hoisted_14 = { class: "icon-container" }
const _hoisted_15 = { class: "icon-container" }
const _hoisted_16 = { class: "icon-container" }
const _hoisted_17 = { class: "icon-container" }
const _hoisted_18 = { class: "icon-container" }
const _hoisted_19 = { class: "icon-container" }
const _hoisted_20 = { class: "icon-container" }
const _hoisted_21 = { class: "icon-container" }
const _hoisted_22 = { class: "icon-container" }
const _hoisted_23 = { class: "icon-container" }
const _hoisted_24 = { class: "icon-container" }
const _hoisted_25 = { class: "icon-container" }
const _hoisted_26 = { class: "icon-container" }
const _hoisted_27 = { class: "icon-container" }
const _hoisted_28 = { class: "icon-container" }
const _hoisted_29 = { class: "icon-container" }
const _hoisted_30 = { class: "icon-container" }
const _hoisted_31 = { class: "icon-container" }
const _hoisted_32 = { class: "icon-container" }
const _hoisted_33 = { class: "icon-container" }
const _hoisted_34 = { class: "icon-container" }
const _hoisted_35 = { class: "icon-container" }
const _hoisted_36 = { class: "icon-container" }
const _hoisted_37 = { class: "icon-container" }
const _hoisted_38 = { class: "icon-container" }
const _hoisted_39 = {
  class: "trigger",
  style: {"margin-left":"16px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!
  const _component_HomeOutlined = _resolveComponent("HomeOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_ShopOutlined = _resolveComponent("ShopOutlined")!
  const _component_BarcodeOutlined = _resolveComponent("BarcodeOutlined")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_InboxOutlined = _resolveComponent("InboxOutlined")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_AppstoreOutlined = _resolveComponent("AppstoreOutlined")!
  const _component_TeamOutlined = _resolveComponent("TeamOutlined")!
  const _component_DesktopOutlined = _resolveComponent("DesktopOutlined")!
  const _component_PercentageOutlined = _resolveComponent("PercentageOutlined")!
  const _component_DollarOutlined = _resolveComponent("DollarOutlined")!
  const _component_ShoppingOutlined = _resolveComponent("ShoppingOutlined")!
  const _component_ShoppingCartOutlined = _resolveComponent("ShoppingCartOutlined")!
  const _component_SnippetsOutlined = _resolveComponent("SnippetsOutlined")!
  const _component_SettingOutlined = _resolveComponent("SettingOutlined")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_DownOutlined = _resolveComponent("DownOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_menu_divider = _resolveComponent("a-menu-divider")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    style: {"height":"100vh","overflow":"hidden"},
    class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"]),
      _createVNode(_component_a_config_provider, {
        theme: {
      token: {
        colorPrimary: _ctx.themeTemplate.primaryColor,
        fontFamily: 'Exo 2',
        
      },
      }
      }, null, 8, ["theme"]),
      _createVNode(_component_a_layout_sider, {
        collapsed: _ctx.collapsed,
        "onUpdate:collapsed": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.collapsed) = $event)),
        trigger: null,
        collapsible: "",
        width: _ctx.siderWidth,
        class: "layout-sider"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.collapsed)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _imports_0,
                    class: "logo-img-collapsed",
                    alt: "Posys Image",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/', '0')))
                  }))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _imports_1,
                    class: "logo-img",
                    alt: "Posys-Image",
                    width: "75%",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigatePage('/', '0')))
                  }))
            ]),
            _createVNode(_component_a_menu, {
              selectedKeys: _ctx.selectedKeys,
              "onUpdate:selectedKeys": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.selectedKeys) = $event)),
              mode: "inline",
              class: "sider-menu"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, { key: "0" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "icon-container",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigatePage('/','0')))
                    }, [
                      _createVNode(_component_HomeOutlined),
                      _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Dashboard')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, { key: "1" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "icon-container",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigatePage('/store','1')))
                    }, [
                      _createVNode(_component_ShopOutlined),
                      _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Store')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_sub_menu, { key: "2" }, {
                  title: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", null, [
                        _createVNode(_component_BarcodeOutlined),
                        _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Inventory')), 513), [
                          [_vShow, !_ctx.collapsed]
                        ])
                      ])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, {
                      key: "2-1",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigatePage('/warehouse','2-1')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('Pos.Warehouse')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "2-2",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.navigatePage('/inventory','2-2')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('Pos.Transactions')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "2-3",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.navigatePage('/stockOverview','2-3')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('Pos.StockOverview')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_sub_menu, { key: "3" }, {
                  title: _withCtx(() => [
                    _createElementVNode("span", null, [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_InboxOutlined),
                        _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Products')), 1)
                      ])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, {
                      key: "3-1",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.navigatePage('/products','3-1')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('Pos.Products')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "3-2",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.navigatePage('/category','3-2')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t('Pos.Categories')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_sub_menu, { key: "3-3" }, {
                      title: _withCtx(() => [
                        _createElementVNode("span", null, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Ingredients')), 1)
                          ])
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_menu_item, {
                          key: "3-3-1",
                          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.navigatePage('/ingredients','3-3-1'))),
                          class: "submenu-container"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('Pos.Ingredients')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_menu_item, {
                          key: "3-3-2",
                          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.navigatePage('/ingredient-categories','3-3-2'))),
                          class: "submenu-container"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('Pos.Categories')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "3-4",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.navigatePage('/manufacturer','3-4')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t('Pos.Manufacturers')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "3-5",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.navigatePage('/supplier','3-5')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.t('Pos.Suppliers')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "3-6",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.navigatePage('/preferences','3-6')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.t('Pos.Preferences')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, { key: "13" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "icon-container",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.navigatePage('/customers','13')))
                    }, [
                      _createVNode(_component_UserOutlined),
                      _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Customers')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_sub_menu, { key: "6" }, {
                  title: _withCtx(() => [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("span", null, [
                        _createVNode(_component_AppstoreOutlined),
                        _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Tables')), 1)
                      ])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, {
                      key: "6-1",
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.navigatePage('/tables','6-1'))),
                      class: "sub-menu-item"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.t('Pos.Tables')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "6-2",
                      onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.navigatePage('/tableLocation','6-2')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.t('Pos.TableLocation')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "6-3",
                      onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.navigatePage('/tables-reservation','6-3')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.t('Pos.TableReservations')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "6-4",
                      onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.navigatePage('/tablesV2','6-4'))),
                      class: "sub-menu-item"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.t('Pos.Tables')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, { key: "5" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "icon-container",
                      onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.navigatePage('/workers','5')))
                    }, [
                      _createVNode(_component_TeamOutlined),
                      _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Workers')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, { key: "7" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "icon-container",
                      onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.navigatePage('/device','7')))
                    }, [
                      _createElementVNode("span", null, [
                        _createVNode(_component_DesktopOutlined),
                        _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Hardwares')), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_sub_menu, { key: "8" }, {
                  title: _withCtx(() => [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("span", null, [
                        _createVNode(_component_PercentageOutlined),
                        _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Promotion')), 513), [
                          [_vShow, !_ctx.collapsed]
                        ])
                      ])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, {
                      key: "8-1",
                      onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.navigatePage('/discount','8-1')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.t('Pos.Discount')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "8-2",
                      onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.navigatePage('/coupon','8-2')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.t('Pos.Coupon')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "8-3",
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.navigatePage('/gift-card','8-3')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.t('Pos.giftCard')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, { key: "9" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "icon-container",
                      onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.navigatePage('/payments','9')))
                    }, [
                      _createVNode(_component_DollarOutlined),
                      _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Payments')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, { key: "10" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "icon-container",
                      onClick: _cache[25] || (_cache[25] = ($event: any) => (_ctx.navigatePage('/sales','10')))
                    }, [
                      _createVNode(_component_ShoppingOutlined),
                      _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Sales')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, { key: "11" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "icon-container",
                      onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.navigatePage('/Cart','11')))
                    }, [
                      _createVNode(_component_ShoppingCartOutlined),
                      _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Cart')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_sub_menu, { key: "12" }, {
                  title: _withCtx(() => [
                    _createElementVNode("span", null, [
                      _createElementVNode("div", _hoisted_25, [
                        _createVNode(_component_SnippetsOutlined),
                        _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Invoice')), 1)
                      ])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, {
                      key: "12-1",
                      onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.navigatePage('/invoices','12-1')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.t('Pos.Invoice')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "12-2",
                      onClick: _cache[28] || (_cache[28] = ($event: any) => (_ctx.navigatePage('/purchases','12-2')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.t('Pos.PurchaseInvloice')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_sub_menu, { key: "11" }, {
                  title: _withCtx(() => [
                    _createElementVNode("span", null, [
                      _createElementVNode("div", _hoisted_28, [
                        _createVNode(_component_SettingOutlined),
                        _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.Settings')), 1)
                      ])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, {
                      key: "11-1",
                      onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.navigatePage('/invoice-settings','11-1')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.t('Pos.Billing')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "11-2",
                      onClick: _cache[30] || (_cache[30] = ($event: any) => (_ctx.navigatePage('/users','11-2')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.t('Pos.Users')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "11-4",
                      onClick: _cache[31] || (_cache[31] = ($event: any) => (_ctx.navigatePage('/vatrate','11-4')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.t('Pos.VatRates')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "11-5",
                      onClick: _cache[32] || (_cache[32] = ($event: any) => (_ctx.navigatePage('/attributes','11-5')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.t('Pos.Attributes')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "11-6",
                      onClick: _cache[33] || (_cache[33] = ($event: any) => (_ctx.navigatePage('/variants','11-6')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.t('Pos.Variants')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "11-7",
                      onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.navigatePage('/production-points','11-7')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.t('Pos.ProductionPoints')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "11-8",
                      onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.navigatePage('/custom-filed','11-8')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.t('Pos.Customfileds')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "11-9",
                      onClick: _cache[36] || (_cache[36] = ($event: any) => (_ctx.navigatePage('/paymentsSettings','11-9')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.t('Pos.PaymentSettings')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "11-10",
                      onClick: _cache[37] || (_cache[37] = ($event: any) => (_ctx.navigatePage('/loyalty','11-10')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.t('Pos.LoyaltySettings')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_item, {
                      key: "11-11",
                      onClick: _cache[38] || (_cache[38] = ($event: any) => (_ctx.navigatePage('/currency','11-11')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.t('Pos.Currency')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["selectedKeys"])
          ])
        ]),
        _: 1
      }, 8, ["collapsed", "width"]),
      _createVNode(_component_a_layout, { style: {"overflow-y":"auto"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout_header, { class: "layout-header" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_39, [
                (_ctx.collapsed)
                  ? (_openBlock(), _createBlock(_component_menu_unfold_outlined, {
                      key: 0,
                      onClick: _cache[41] || (_cache[41] = () => (_ctx.collapsed = !_ctx.collapsed))
                    }))
                  : (_openBlock(), _createBlock(_component_menu_fold_outlined, {
                      key: 1,
                      onClick: _cache[42] || (_cache[42] = () => (_ctx.collapsed = !_ctx.collapsed))
                    }))
              ]),
              _createVNode(_component_a_dropdown, { class: "lang-drop" }, {
                overlay: _withCtx(() => [
                  _createVNode(_component_a_menu, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_menu_item, {
                        key: "1",
                        onClick: _cache[43] || (_cache[43] = ($event: any) => (_ctx.setLanguage('fr')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.French')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_menu_item, {
                        key: "2",
                        onClick: _cache[44] || (_cache[44] = ($event: any) => (_ctx.setLanguage('ar')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.Arabic')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_menu_item, {
                        key: "3",
                        onClick: _cache[45] || (_cache[45] = ($event: any) => (_ctx.setLanguage('en')))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('Pos.English')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_a_button, { class: "drop-btn" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.currentLanguageText) + " ", 1),
                      _createVNode(_component_DownOutlined)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.locale === 'ar' ? 'avatar-container-rtl' : 'avatar-container'])
              }, [
                _createVNode(_component_a_space, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_dropdown, {
                      class: "stores-drop",
                      onClick: _cache[46] || (_cache[46] = _withModifiers(() => {}, ["prevent"]))
                    }, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.stores, (store) => {
                              return (_openBlock(), _createBlock(_component_a_menu_item, {
                                key: store.id,
                                onClick: ($event: any) => (_ctx.switchStore(store.id))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(store.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, { class: "drop-btn" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.state.storeName) + " ", 1),
                            _createVNode(_component_DownOutlined)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_dropdown, { trigger: ['click'] }, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_menu_item, {
                              key: "0",
                              onClick: _cache[48] || (_cache[48] = ($event: any) => (_ctx.navigatePage('/profile')))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Profile')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_menu_divider),
                            _createVNode(_component_a_menu_item, {
                              key: "1",
                              onClick: _cache[49] || (_cache[49] = ($event: any) => (_ctx.logout()))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('Pos.Logout')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          class: "ant-dropdown-link",
                          onClick: _cache[47] || (_cache[47] = _withModifiers(() => {}, ["prevent"]))
                        }, [
                          _createVNode(_component_a_avatar, {
                            size: 35,
                            class: "avatar-icon"
                          }, {
                            icon: _withCtx(() => [
                              _createVNode(_component_UserOutlined)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ], 2)
            ]),
            _: 1
          }),
          _createVNode(_component_a_layout_content, {
            style: { margin: '24px 16px', padding: '24px', background: '#fff', minHeight: 'auto' },
            class: _normalizeClass({ 'dashboard-bg': _ctx.noBgRoute})
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_a_layout_footer, { class: "footer" }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(_ctx.t('Pos.Copyright')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}