import {authHeader, ApiConfigs } from "../_helpers";
import { authService } from "./auth.service";

export const storeService = {
    getAll,
    show,
    create,
    delete: _delete,
    update,
    getAllWithPagination,
    getUserStores,
    switchStore,
    searchWithoutPagination,
    getMyStores,
    createStore,
    updateStore,
    searchWithFullParams,
    searchMyStoresWithoutPagination
};

function getUserStores(){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.store.userStores}?pagination=off`, requestOptions)
    .then(
        handleResponse
    );
}

function searchWithFullParams(key : string , value:string , currentPage:number ) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.store.MyStores}?key=${key}&value=${value}&page=${currentPage}`, requestOptions).then(handleResponse);
}

function searchMyStoresWithoutPagination(key : string , value:string ) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.store.MyStores}?key=${key}&value=${value}`, requestOptions).then(handleResponse);
}


function getAllWithPagination(){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.store.getAll}?pagination=off`, requestOptions)
    .then(
        handleResponse
    );

}


function getMyStores(){
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.store.MyStores}`, requestOptions)
    .then(
        handleResponse
    );

}


function getAll(currentPage : number , perPage : number) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.store.getAll}?per_page=${perPage}&page=${currentPage}`, requestOptions)
        .then(
            handleResponse
        );
}

function show(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.store.show.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );
}



function create(payload:object ){
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.store.create}`, requestOptions)
        .then(
            handleResponse
        );
}

function _delete(id: string){
    const requestOptions={
        method:"DELETE",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" },

    }

    return fetch(`${ApiConfigs.base_url+ApiConfigs.store.delete.replace(':id', id)}`, requestOptions)
        .then(handleResponse);
}

function update(id: string, payload: object){
    console.log('ertert', id)
    const requestOptions={
        method:"PUT",
        credentials : 'include' as RequestCredentials,
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(payload)
    }

    return fetch(`${ApiConfigs.base_url+ ApiConfigs.store.update.replace(':id', id)}`, requestOptions)
    .then(handleResponse);
}






function switchStore(id: string) {
    const requestOptions = {
        method: "GET",
        credentials : 'include' as RequestCredentials,
        headers: { "Content-Type": "application/json" }, 
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.store.switch.replace(':id', id)}`, requestOptions)
        .then(handleResponse)
            .then((response)=>{
                localStorage.setItem('store', JSON.stringify(response.store));
                
                return response.store;
            });
}


function searchWithoutPagination(key : string , value:string ) {
    const requestOptions = {
        credentials : 'include' as RequestCredentials,
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url+ApiConfigs.store.getAll}?key=${key}&value=${value}`, requestOptions).then(handleResponse);
}


function createStore(name:string, description:string,address:string,
   email:string,phone:string, password:string,account:string,
    logo:any  ){

    const formData = new FormData();

    formData.append('name',name);
    formData.append('description',description);
    formData.append('address',address);
    formData.append('email',email);
    formData.append('phone',phone);
    formData.append('logo',logo);
    formData.append('password',password);
    formData.append('account_id',account);

    

    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Accept": "application/json" },
        body: formData
    };
    
    return fetch(`${ApiConfigs.base_url + ApiConfigs.store.create}`, requestOptions)
        .then(
            handleResponse
        );

    
}


function updateStore(id:string,name:string, description:string,address:string,
    email:string,phone:string, password:string,account:string,
    logo:any     ){

   
        const formData = new FormData();
        formData.append('name',name);
        formData.append('description',description);
        formData.append('address',address);
        formData.append('email',email);
        formData.append('phone',phone);
        if(logo!==null){
            formData.append('logo',logo );
        }
                formData.append('password',password);
        formData.append('account_id',account);
   
    const requestOptions = {
        method: "POST",
        credentials : 'include' as RequestCredentials,
        headers: { "Accept": "application/json" },
        body: formData
    };
    
    return fetch(`${ApiConfigs.base_url + ApiConfigs.store.update.replace(':id', id)}`, requestOptions)
        .then(
            handleResponse
        );

    
}







function handleResponse(response: any) {
    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem("user");
            localStorage.removeItem("store");
            location.reload();
            authService.logout();
        }
        const error = (response && response.message) || response.status || response.statusText;
        return Promise.reject(error);
    }
    return response.json().then((rep: any) => {
        return rep;
    });
}