import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18488bff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"8px"} }
const _hoisted_2 = {
  key: 0,
  class: "search-btn-radio"
}
const _hoisted_3 = { class: "pagination" }
const _hoisted_4 = {
  key: 0,
  class: "drawer-header-ar"
}
const _hoisted_5 = {
  key: 1,
  class: "drawer-header-all"
}
const _hoisted_6 = { class: "custom-header" }
const _hoisted_7 = {
  class: "info-title",
  placeholder: "amountPaid"
}
const _hoisted_8 = {
  class: "info-title",
  placeholder: "paymentMethod"
}
const _hoisted_9 = {
  class: "info-title",
  placeholder: "currency"
}
const _hoisted_10 = {
  class: "info-title",
  placeholder: "discountValue"
}
const _hoisted_11 = { class: "info-title" }
const _hoisted_12 = { class: "info-title" }
const _hoisted_13 = {
  class: "info-title",
  placeholder: "notes"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LbrxLoaderSpinner = _resolveComponent("LbrxLoaderSpinner")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_check_circle_outlined = _resolveComponent("check-circle-outlined")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_search_outlined = _resolveComponent("search-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_LbrxLoaderSpinner, {
        "is-loaded": !_ctx.loading
      }, null, 8, ["is-loaded"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_breadcrumb, {
        style: {"margin":"16px 0"},
        class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_breadcrumb_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigatePage('/'))),
            class: "breadcrumb-icon"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Home')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_breadcrumb_item, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('Pos.Payments')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_table, {
        class: "table-container",
        columns: _ctx.columns,
        "data-source": _ctx.reactiveData.data,
        pagination: false
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.key == 'actions')
            ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tooltip, null, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.View')), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: ($event: any) => (_ctx.showDrawer(record))
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EyeOutlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (column.key=='payment_method')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (record.unit==='Cash')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Milligrams')), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (record.unit==='Credit Card')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Pieces')), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (record.unit==='Ticket')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createTextVNode(_toDisplayString(_ctx.t('Pos.Milliliter')), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (column.key == 'id')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createTextVNode(_toDisplayString(_ctx.formatID(record)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key == 'currency_code')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createTextVNode(_toDisplayString(_ctx.formatcurrency_code(record)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key == 'amount_paid')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _createTextVNode(_toDisplayString(_ctx.addCodeToAmount(record)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key == 'payment_method')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                _createTextVNode(_toDisplayString(_ctx.formatpayment_method(record)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key == 'notes')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                _createTextVNode(_toDisplayString(_ctx.FormatEmptyNote(record)), 1)
              ], 64))
            : _createCommentVNode("", true),
          (column.key == 'paidStatus')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                (record.sale.is_paid=='1')
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 0,
                      bordered: false,
                      color: "success"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_check_circle_outlined)
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.t('Pos.paid')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (record.sale.is_paid=='0')
                  ? (_openBlock(), _createBlock(_component_a_tag, {
                      key: 1,
                      bordered: false,
                      color: "error"
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_check_circle_outlined)
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_ctx.t('Pos.unpaid')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        customFilterDropdown: _withCtx(({ selectedKeys, confirm, clearFilters, column }) => [
          _createElementVNode("div", _hoisted_1, [
            (column.key === 'payment_method')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_a_radio_group, {
                    value: _ctx.value,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
                    onChange: _ctx.selectedType,
                    class: "search-radio-grp"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_radio, {
                            value: "Cash",
                            class: "radio-item"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Cash")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_radio, {
                            value: "Credit Card\t",
                            class: "radio-item"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Credit Card")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_radio, {
                            value: "Ticket",
                            class: "radio-item"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Ticket")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "onChange"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_button, {
              type: "primary",
              size: "small",
              style: {"width":"90px","margin-right":"8px"},
              onClick: ($event: any) => (_ctx.handleSearch(selectedKeys, confirm, column.dataIndex))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Search')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              size: "small",
              style: {"width":"90px"},
              onClick: ($event: any) => (_ctx.handleReset(clearFilters))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('Pos.Reset')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        customFilterIcon: _withCtx(({ filtered }) => [
          _createVNode(_component_search_outlined, {
            style: _normalizeStyle({ color: filtered ? '#108ee9' : undefined })
          }, null, 8, ["style"])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_pagination, {
          class: "paginationArrows",
          current: _ctx.currentPage,
          "onUpdate:current": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPage) = $event)),
          total: _ctx.form.customLastPage,
          onChange: _ctx.handlePageChange,
          "default-page-size": _ctx.perPage,
          showSizeChanger: false
        }, null, 8, ["current", "total", "onChange", "default-page-size"]),
        (_ctx.form.customLastPage>10)
          ? (_openBlock(), _createBlock(_component_a_select, {
              key: 0,
              class: "select-input",
              value: _ctx.customPagination,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customPagination) = $event)),
              onChange: _ctx.choosePerPage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select_option, { value: "10" }, {
                  default: _withCtx(() => [
                    _createTextVNode("10 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "20" }, {
                  default: _withCtx(() => [
                    _createTextVNode("20 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "50" }, {
                  default: _withCtx(() => [
                    _createTextVNode("50 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_select_option, { value: "100" }, {
                  default: _withCtx(() => [
                    _createTextVNode("100 / " + _toDisplayString(_ctx.t('Pos.page')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value", "onChange"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_a_drawer, {
        open: _ctx.open,
        "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.open) = $event)),
        class: "custom-class",
        "root-class-name": "root-class-name",
        "root-style": { color: 'blue' },
        style: {"color":"red"},
        title: null,
        placement: _ctx.locale === 'ar' ? 'left' : 'right',
        width: _ctx.modalWidth,
        "body-style": { paddingBottom: '80px' },
        onAfterOpenChange: _ctx.afterOpenChange
      }, {
        title: _withCtx(() => [
          (_ctx.locale === 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.PaymentDetails')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.locale !== 'ar')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.t('Pos.PaymentDetails')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        footer: _withCtx(() => []),
        default: _withCtx(() => [
          _createVNode(_component_a_form, {
            ref: "formRef",
            model: _ctx.form,
            rules: _ctx.rules,
            layout: "vertical",
            class: _normalizeClass({ 'is-rtl': _ctx.textDirection==='rtl'})
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('Pos.GeneralInformations')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.t('Pos.PaidAmount')) + " :", 1),
                        _createTextVNode(_toDisplayString(_ctx.form.amount_paid), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t('Pos.PaymentMethod')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.payment_method), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('Pos.currency')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.currency), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.t('Pos.discount')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.discount), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('Pos.discountType')) + " : ", 1),
                        (_ctx.form.discount_type==='percentage')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createTextVNode(_toDisplayString(_ctx.t('Pos.Percentage')), 1)
                            ], 64))
                          : _createCommentVNode("", true),
                        (_ctx.form.discount_type==='cash')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(_ctx.t('Pos.Cash')), 1)
                            ], 64))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t('Pos.Status')) + " :", 1),
                        (_ctx.form.paid==='paid')
                          ? (_openBlock(), _createBlock(_component_a_tag, {
                              key: 0,
                              bordered: false,
                              color: "success"
                            }, {
                              icon: _withCtx(() => [
                                _createVNode(_component_check_circle_outlined)
                              ]),
                              default: _withCtx(() => [
                                _createTextVNode(" " + _toDisplayString(_ctx.t('Pos.paid')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.form.paid==='unpaid')
                          ? (_openBlock(), _createBlock(_component_a_tag, {
                              key: 1,
                              bordered: false,
                              color: "error"
                            }, {
                              icon: _withCtx(() => [
                                _createVNode(_component_check_circle_outlined)
                              ]),
                              default: _withCtx(() => [
                                _createTextVNode(" " + _toDisplayString(_ctx.t('Pos.unpaid')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.t('Pos.Notes')) + " :", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.form.notes), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules", "class"])
        ]),
        _: 1
      }, 8, ["open", "placement", "width", "onAfterOpenChange"])
    ])
  ], 64))
}