
<template>
    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{t('Pos.Dashboard')}}
        </a-breadcrumb-item>
        <a-breadcrumb-item  @click="navigatePage('/warehouse')" class="breadcrumb-icon">{{t('Pos.Warehouse')}}</a-breadcrumb-item>
        <a-breadcrumb-item>{{state.warehouseName}}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <!-- <a-flex justify="space-between">

        <p class="label-form">{{state.warehouseName}}</p>


        <a-button class="add-btn" @click="showAddDrawer(true)">
            {{ t('Pos.Add') }}
            <template #icon>
                <PlusOutlined />
            </template>
        </a-button>

    </a-flex> -->

      

    <div class="btn-container">
        
            
        <a-button class="add-btn" @click="showAddDrawer(true)">
            {{ t('Pos.Add') }}
            <template #icon>
                <PlusOutlined />
            </template>
        </a-button>
    </div>


    <div>
    <a-table
      :columns="columns"
      :data-source="state.data"
      :pagination="false"
      class="table-container"
      >

        <template #emptyText>
            <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
        </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key=='actions'">
            <a-space>
                <a-tooltip>
                    <template #title>{{ t('Pos.View') }}</template>
                    <a-button class="view-btn" @click="() => showDisplayDrawer(record)">
                    <template #icon>
                        <EyeOutlined/>
                    </template>
                    </a-button>
                </a-tooltip>

               
                

                <a-tooltip>
                    <template #title>{{ t('Pos.Delete') }}</template>
                    <a-button type="primary" danger @click="showDeletePopup(record)">
                    <template #icon>
                        <DeleteOutlined />
                    </template>
                    </a-button>
                </a-tooltip>


                <a-tooltip>
                    <template #title>{{ t('Pos.Adjustment') }}</template>
                    <a-button class="variant-btn"  @click="showTransferModal(record)">
                    <template #icon>
                        <PlusOutlined />
                    </template>
                    </a-button>
                </a-tooltip>

               

              


            </a-space>
        </template>

        <template v-if="column.key=='stockable_type'">
            {{t(`Pos.${record.stockable_type}`)}}
        </template>

        <template v-if="column.key=='stockable'">
            {{record.stockable.name}}
        </template>

        <template  v-if="column.key==='code' && record.stockable!==null">
            {{record.stockable.barcode}}
        </template>
      </template>

        <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
               <template  v-if="column.key === 'stockable' || column.key==='code'">
                    <a-input
                        ref="searchInput"
                        :placeholder="t('Pos.Search')"
                        :value="selectedKeys[0]"
                        style="width: 188px; margin-bottom: 8px; display: block"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
                    />  
               </template>

               <template  v-if="column.key === 'stockable_type'">
                    <div class="search-btn-radio">
                        <a-radio-group  v-model:value="value" @change="selectedType" class="search-radio-grp">
                            <a-radio value="product" class="radio-item">{{t('Pos.Product')}}</a-radio>
                            <a-radio value="ingredient" class="radio-item">{{t('Pos.Ingredient')}}</a-radio>
                        </a-radio-group>  
                    </div>   
               </template>

               <a-button
                    type="primary"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
                >
                   {{ t('Pos.Search') }}
                </a-button>
                <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                        {{ t('Pos.Reset') }}
                </a-button>
               
            </div>
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

          
    </a-table>

    <a-row class="pagination">
        <a-pagination class="paginationArrows" v-model:current="currentPage" v-model:pageSize="perPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage" :showSizeChanger=false />

        <a-select
            class="select-input"
            v-model:value="selectPagination"
            @change="handleSelectPaginationChange"
            v-if="state.totalPages>10"
        >
            <a-select-option value="10">10 / {{t('Pos.page')}}</a-select-option>
            <a-select-option value="20">20 / {{t('Pos.page')}}</a-select-option>
            <a-select-option value="50">50 / {{t('Pos.page')}}</a-select-option>
            <a-select-option value="100">100 / {{t('Pos.page')}}</a-select-option>
        </a-select>

     
    </a-row>



    <!-- <div class="pagination" v-if="state.totalPagesSearch===0">
      <a-pagination v-model:current="currentPage" :total="state.totalPages" @change="handlePageChange"  :default-page-size="perPage"/>
    </div>

    <div class="pagination" v-if="state.totalPagesSearch!==0">
      <a-pagination v-model:current="currentPageSearch" :total="state.totalPagesSearch" @change="handlePageChangeSearch"  :default-page-size="perPageSearch"/>
    </div> -->

    <!-- display drawer -->
    <a-drawer
        v-model:open="openDisplay"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width=modalWidth
        @after-open-change="afterDisplayOpenChange"
    >

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.StockItem')}}</p>
        </template>
        <a-form  ref="formRef" :model="form" layout="vertical"  :class="{ 'is-rtl': textDirection==='rtl'}">
            <a-row :gutter="16">

                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Item') }} :</span> 
                        {{form.stockable_name}} 
                        <a-tag v-if="form.stockable_name===null">{{ t('Pos.Emptyfield') }}</a-tag>
                    </p>
                </a-col>


                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Type') }} :</span>
                        {{t(`Pos.${form.stockable_type}`)}}
                    </p>
                </a-col>

            </a-row>

            <a-row :gutter="16">

                <a-col :span="12">
                    <p><span class="info-title" >{{ t('Pos.Unit') }} :</span> 
                        {{t(`Pos.${form.unit}`)}}
                        
                       
                        <a-tag v-if="form.unit===null">{{ t('Pos.Emptyfield') }}</a-tag>

                    </p>
                </a-col>

                <a-col :span="12">
                    <p><span class="info-title">{{ t('Pos.Quantity') }} :</span>
                        {{form.quantity}} 
                        <a-tag v-if="form.quantity===null">{{ t('Pos.Emptyfield') }}</a-tag>

                    </p>
                </a-col>

            </a-row>

            <a-row :gutter="16">
                <a-col :span=12>
                    <p><span class="info-title">{{ t('Pos.CreatedBy') }} :</span>
                        {{form.user_name}}  
                    </p>
                </a-col>
                <a-col :span=12>
                    <p><span class="info-title">{{ t('Pos.CreatedAt') }} :</span>
                        {{formatDisplayDate(form.created_at)}}  
                    </p>
                </a-col>
            </a-row>
        </a-form>
      
        
    </a-drawer>

    <!-- update drawer-->
    <a-drawer
        v-model:open="open"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width=modalWidth
        @after-open-change="afterOpenChange"
    > 

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.UpdateStockItem')}}</p>
        </template>
        <a-form  ref="formRef" :model="form" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
           

            <a-row :gutter="16">   
                <a-col :span="12">
                <a-form-item  name="unit">
                    <template #label>
                        <span class="label-form">{{ t('Pos.Unit') }}</span>
                    </template>

                    <a-select v-model:value="form.unit" show-search>
                        <a-select-option value="mg">{{ t('Pos.Milligrams') }}</a-select-option>
                        <a-select-option value="pcs">{{ t('Pos.Pieces') }}</a-select-option>
                        <a-select-option value="ml">{{ t('Pos.Milliliter') }}</a-select-option>
                        <a-select-option value="mm">{{ t('Pos.Millimeter') }}</a-select-option>
                    </a-select>
                </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item  name="quantity">
                        <template #label>
                            <span class="label-form">{{ t('Pos.Quantity') }}</span>
                        </template>
                        <a-input  v-model:value="form.quantity" :placeholder="t('Pos.Quantity')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>

          
        </a-form>
      <template #footer>
          <a-space>
          <a-button  type="primary" @click="update(form.id)">{{ t('Pos.Update') }}</a-button>
          <a-button type="primary" danger @click="cancelUpdate">{{ t('Pos.Cancel') }}</a-button>
          </a-space>
      </template>
        
    </a-drawer>

    <!-- add stock item drawer --> 
    <a-drawer
      v-model:open="openAdd"
      class="custom-class"
      root-class-name="root-class-name"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width=modalWidth

      @close="resetStockItem"

    >

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}">{{t('Pos.AddStockItem')}}</p>
        </template>

        <a-form ref="formRef" :model="addForm" :rules="rules" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">

            <a-row :gutter="16" >   
                <a-col :span="12">
                    <a-form-item   name="stockable_type" >
                    <template #label>
                        <span class="label-form">{{ t('Pos.Type') }}</span>
                    </template>
                    <a-select v-model:value="addForm.stockable_type" @change="handleStockableChange">
                        <template #notFoundContent>
                            <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                        </template>
                        <a-select-option value="product">{{t('Pos.Product')}}</a-select-option>
                        <a-select-option value="ingredient">{{t('Pos.Ingredient')}}</a-select-option>
                    </a-select>
                    </a-form-item>
                    
                </a-col>

                <a-col :span="12">
                <a-form-item  name="stockable_id">
                    <template #label>
                    <span class="label-form">{{ t('Pos.Item') }}</span>
                    </template>

                    <a-select v-model:value="addForm.stockable_id"  @select="selectedStockable" :placeholder="t('Pos.Item')" show-search  :option-filter-prop="'label'" :disabled="!addForm.stockable_type">
                        <template #notFoundContent>
                            <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                        </template>
                        <a-select-option v-for="item in state.stockables" :key="item.id" :value="item.id" :label="item.name" :selected="item.id === addForm.stockable_id ">{{ item.name }}</a-select-option>
                    </a-select>

                    
                </a-form-item>
                </a-col>
            </a-row>

            <a-row v-if="state.variants.length!==0">

                <a-col :span="24">

                    <a-form-item  name="product_variant_id">
                    <template #label>
                        <span class="label-form">{{ t('Pos.Variants') }}</span>
                    </template>

                    <a-select v-model:value="addForm.product_variant_id" >
                        <template #notFoundContent>
                            <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                        </template>
                        <a-select-option v-for="item in state.variants" :key="item.id" :value="item.id" :selected="item.id === addForm.product_variant_id ">{{ item.name }}</a-select-option>
                    </a-select>

                    </a-form-item>

                </a-col>
            
            </a-row>

            <a-row :gutter="16">   
                <a-col :span="12">
                <a-form-item  name="unit">
                <template #label>
                    <span class="label-form">{{ t('Pos.Unit') }}</span>
                </template>

                <a-select v-model:value="addForm.unit" show-search :option-filter-prop="'label'">

                    <template #notFoundContent>
                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                    </template>
                    <a-select-option value="mg" :label="t('Pos.Milligrams')">{{ t('Pos.Milligrams') }}</a-select-option>
                    <a-select-option value="pcs" :label="t('Pos.Pieces')">{{ t('Pos.Pieces') }}</a-select-option>
                    <a-select-option value="ml" :label="t('Pos.Milliliter')">{{ t('Pos.Milliliter') }}</a-select-option>
                    <a-select-option value="mm" :label="t('Pos.Millimeter')">{{ t('Pos.Millimeter') }}</a-select-option>
                </a-select>
                </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item  name="quantity">
                    <template #label>
                        <span class="label-form">{{ t('Pos.Quantity') }}</span>
                    </template>
                    <a-input  v-model:value="addForm.quantity" :placeholder="t('Pos.Quantity')" class="input-form"/>
                    </a-form-item>
                </a-col>
            </a-row>
      </a-form>
      <template #footer>
        <a-space>
          <a-button  type="primary" @click="addStockItem">{{ t('Pos.Add') }}</a-button>
          <a-button type="primary" danger  @click="resetStockItem">{{ t('Pos.Cancel') }}</a-button>
        </a-space>
      </template>
    </a-drawer>

    <!-- modal --> 

    <a-modal v-model:open="openTransferModal"  @ok="handleTransferOk"  @cancel="resetFormProd">

        <template #footer>
            <a-button key="submit" type="primary"  @click="handleTransferOk">{{ t('Pos.Ok') }}</a-button>
            <a-button key="back" @click="handleCancel">{{ t('Pos.Cancel') }}</a-button>
        </template>

        <template #title>
            <p :class="{ 'is-rtl': textDirection==='rtl'}">{{ t('Pos.AdjustmentTitle') }}</p>
        </template>


      <a-form  ref="refForm" :model="formProd" :rules="rulesProd" layout="vertical" :class="{ 'is-rtl': textDirection==='rtl'}">
        

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item name="quantity"  has-feedback :validate-status="validatingStatus" :help="validatingHelp" >
              <template #label>
                <span class="label-form">{{t('Pos.Quantity')}}</span>
              </template>

              <a-input  v-model:value="formProd.quantity" :placeholder="t('Pos.Quantity')" class="input-form" @blur="validateQuantity"/>
            </a-form-item>
          </a-col>

          <a-col :span="12">

            <a-form-item name="reason" >
              <template #label>
                <span class="label-form">{{t('Pos.Reason')}}</span>
              </template>

                <a-select v-model:value="formProd.reason" show-search>
                    <a-select-option value="lost">{{t('Pos.Lost')}}</a-select-option>
                    <a-select-option value="dammaged">{{t('Pos.Damaged')}}</a-select-option>
                    <a-select-option value="expired">{{t('Pos.Expired')}}</a-select-option>
                    <a-select-option value="other">{{t('Pos.Other')}}</a-select-option>
                </a-select>            
            </a-form-item>

          </a-col>
        </a-row>

        
      </a-form>
      
      
    </a-modal>





    </div>

</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted, computed } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import Swal from 'sweetalert2';
import {
    EyeOutlined,
    DeleteOutlined,
    PlusOutlined,
    SearchOutlined
    
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { ingredientsService, productService, stockAdjustmentService, warehouseService } from '@/_services'
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import LbrxLoaderSpinner from '../../../components/LbrxLoaderSpinner.vue';
import { Empty } from 'ant-design-vue';

export default defineComponent({

    name: 'warhouseProducts',
    components: {
        EyeOutlined,
        DeleteOutlined,
        LbrxLoaderSpinner,
        PlusOutlined,
        SearchOutlined
    },
    setup() {
        const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

        const modalWidth = ref(720);

        const { t } = useI18n();

        const loading = ref(false);



        const state = reactive({
            searchText: '',
            searchedColumn: '',
            data: [] as any[],
            stockables: [] as any[],
            variants: [] as any[],
            totalPages:0,
            selectedType:'',
            selectedItemId:'',
            beginSearch:false,
            totalPagesSearch:0,
            selectedItemQte:0,
            warehouseName:'',
            searchName:'',

        });

        const router = useRouter();

        const navigatePage = (link: string) => {
            router.push(link); 
        };
        const route = useRoute();
        const routeId = ref<string>('');


        
        //pagination
        const perPage = ref<number>(10);
        const currentPage = ref<number>(1);

        const selectPagination = ref('10');

        const handleSelectPaginationChange=(selectedItem:any)=>{
            perPage.value=Number.parseInt(selectedItem);
            currentPage.value=1;

            if(state.beginSearch===true){
                state.data=[];

                if(state.selectedType!==''){
                    let searchValue=state.selectedType+","+routeId.value;
                    loading.value=true;
                    warehouseService.filterStockItems("stockable_type,warehouse_id",searchValue, currentPage.value.toString(), perPage.value.toString()).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
                }

                

                if(state.searchName!=''){

                    loading.value = true;
                    let keys="stockable_type,warehouse_id";
                    let values="product,";
                    values += routeId.value; 

                    warehouseService.filterStockItemsName(keys,values,state.searchName, currentPage.value, perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });
               
                }

            }else{

                loading.value=true;
                warehouseService.getStockItems("warehouse_id",routeId.value, currentPage.value,perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api : get products varinats", error);
                })
                .finally(()=>{
                    loading.value = false;
                });


            }

                    
        }

        const handlePageChange = (newPage:any) => {
            currentPage.value = newPage;
            loading.value = true;

            console.log('per page', perPage.value);
            console.log('page', currentPage.value);

            if(state.beginSearch===true){
                state.data=[];

                if(state.searchName!==''){
                    
                    let keys="stockable_type,warehouse_id";
                    let values="product,";
                    values += routeId.value;

                    loading.value = true;
                    warehouseService.filterStockItemsName(keys,values,state.searchName, currentPage.value, perPage.value).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }

                if(state.selectedType!==''){
                    let searchValue=state.selectedType+","+routeId.value;
                    loading.value=true;
                    warehouseService.filterStockItems("stockable_type,warehouse_id",searchValue, currentPage.value.toString(), perPage.value.toString()).then((res)=>{
                        state.data=res.data;
                        state.totalPages=res.meta.total;
                    }).catch((err:any) => {
                        console.log(err)
                    }).finally(()=>{
                        loading.value = false;
                    });

                }


               

            }else{
                warehouseService.getStockItems("warehouse_id",routeId.value, currentPage.value,perPage.value).then((response)=>{
                    state.data=response.data;
                    state.totalPages=response.meta.total;
                }).catch((error)=>{
                    console.log("error api : get products varinats", error);
                })
                .finally(()=>{
                    loading.value = false;
                });

            }



            
        
        }




        //pagination search 
        const perPageSearch = ref<number>(10);
        const currentPageSearch = ref<number>(1);

        const searchInput = ref<HTMLInputElement | null>(null);
        const searchType = ref<HTMLInputElement | null>(null);

        const handlePageChangeSearch = (newPage:any) => {
            currentPageSearch.value = newPage;
            loading.value = true;

            console.log('per page', perPageSearch.value);
            console.log('page', currentPageSearch.value);

            let searchValue=state.selectedType+","+routeId.value;
            state.data=[];
            warehouseService.filterStockItems("stockable_type,warehouse_id", searchValue, currentPageSearch.value.toString(), perPage.value.toString())
            .then((res) => {
                console.log('res filter', res.data);
                state.data=res.data;
                console.log('state.data', state.data);
                state.totalPagesSearch = res.meta.total;
            }).catch((err:any) => {
                console.log(err)
            }).finally(()=>{
                loading.value = false;
            })

            // warehouseService.getStockItems("warehouse_id",routeId.value, currentPage.value,perPage.value).then((response)=>{
            //     console.log('data', response.data)
            //     state.data=response.data;
            // }).catch((error)=>{
            //     console.log("error api : get products varinats", error);
            // })
            // .finally(()=>{
            //     loading.value = false;
            // });
        
        }

        const columns = [
            {
                title: t('Pos.Code'),
                key:'code',
                dataIndex: 'code',
            },
            {
                title: t('Pos.Item'),
                key:'stockable',
                dataIndex: 'stockable',
                customFilterDropdown: true,
                onFilterDropdownOpenChange:  (visible: boolean) => {
                    if (visible  && !searchInput.value) {
                        setTimeout(() => {
                        const input = document.querySelector('.ant-table-filter-dropdown input');

                        if (input instanceof HTMLInputElement) {
                        searchInput.value = input;
                        searchInput.value.focus();
                        }
                        }, 100);
                    }
                },

            },
            {
                title: t('Pos.Type'),
                key:'stockable_type',
                dataIndex: 'stockable_type',
                customFilterDropdown: true,
                customRender: (text: any, record: any) => {
                    return t(`Pos.${text.text}`);
                }


               
            },
            {
                title: t('Pos.Quantity'),
                key:'quantity',
                dataIndex: 'quantity',
            },
            {
                title: t('Pos.Actions'),
                key:'actions',
                dataIndex: 'actions',
            }
        ];

        const handleSearch = (selectedKeys:any, confirm:any, dataIndex:any) => {
            confirm();

            state.searchText = selectedKeys[0];
            state.searchedColumn = dataIndex;

            console.log('searched data', state.searchText);
            console.log('seareched column', state.searchedColumn);


            
            if (dataIndex === 'stockable' && state.searchText) {
                console.log('begin filter with name');
                let keys="stockable_type,warehouse_id";
                let values="product,";
                values += routeId.value; 
                console.log('keys', keys);
                console.log('values', values);
                state.searchText=selectedKeys[0];

                state.searchName=selectedKeys[0];

                console.log('stockable name', state.searchName);

                filterWithName(keys,values,state.searchText );
            }

            if (dataIndex === 'stockable_type' && state.selectedType!=='') {
                console.log('begin filter type', state.selectedType);
                //let searchValue=state.selectedType+","+routeId.value;

                let searchValue=routeId.value+','+state.selectedType;
                console.log('search value', searchValue);
                filter("warehouse_id,stockable_type",searchValue);
            }
        };
        
        const handleReset =  (clearFilter: (arg: any) => void)=> {
            clearFilter({ confirm: true });
            state.searchText = '';
            value.value='';
            state.selectedType='';
            state.searchName='';
            state.beginSearch=false;

            currentPage.value=1;
            perPage.value=10;
            selectPagination.value='10';
            getAll();

        };

        

        const filter = (key:string,value:string) => {
            console.log('begin filter');

            currentPage.value=1;
            loading.value=true;
        
            warehouseService.filterStockItems(key, value, currentPage.value.toString(), perPage.value.toString())
            .then((res) => {
                state.data=res.data;
                state.totalPages = res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err)
            });
                
        };

        const filterWithName = (key:string,value:string, name:string) => {
           
            currentPage.value=1;
            loading.value=true;
            warehouseService.filterStockItemsName(key, value, name,currentPage.value, perPage.value)
            .then((res) => {
                state.data=res.data;
                console.log('state.data', state.data);
                state.totalPages = res.meta.total;
                state.beginSearch=true;
                loading.value=false;
            }).catch((err:any) => {
                console.log(err)
            });
                
        };



        //form  
        const formRef = ref();
        const addFormRef = ref();

        const refForm = ref();

        const formProd = reactive({
            id:'',
            quantity:'',
            reason:'',
        })


        const form = reactive({
            id: '',
            stockable_type: '',
            stockable_id:'',
            stockable_name:'',
            product_variant_id: '',
            warehouse_id:'',
            user_id:'',
            user_name:'',
            unit:'',
            quantity:'',
            created_at:'',
            addType:'',
            
        });

        const addForm = reactive({
            id: '',
            stockable_id: '',
            stockable_type: '',
            product_variant_id: '',
            warehouse_id: '',
            user_id: '',
            unit: '',
            quantity: '',
            addType:'manually',
        });

        const rules: Record<string, Rule[]> = {
            stockable_type: [{ required: true, message: t('Pos.RequiredField') }],
            stockable_id: [{ required: true, message: t('Pos.RequiredField') }],
            unit: [{ required: true, message: t('Pos.RequiredField') }],
            quantity: [
                { required: true, message: t('Pos.RequiredField') },
                { 
                validator: (_, value) => {
                    if (!isNaN(value)) {
                    return Promise.resolve();
                    }
                    return Promise.reject(t('Pos.numericField'));
                }
                }
            ],

        };

        const rulesProd: Record<string, Rule[]> = {
           
            quantity: [
                { required: true, message:  t('Pos.RequiredField') },
                { 
                validator: (_, value) => {
                    if (!isNaN(value)) {
                    return Promise.resolve();
                    }
                    return Promise.reject(t('Pos.numericField'));
                }
                }
            ],
            reason: [{ required: true, message:  t('Pos.RequiredField') }],



        };

        //notification
        const showSuccessNotification  = (message:string)=>{
            notification.success({
                message: message,
                duration: 3,
                top: '80px',
                style:{
                backgroundColor: '#bfffca8a',
                color: 'white'
                }
            });
        };




        const getEntity=(str:string)=>{
            const parts = str.split('\\');
            if (parts.length >= 3) {
                return parts.slice(2).join('\\');
            } else {
                return str;
            }
        }

        //drawer
        const open = ref<boolean>(false);
        const openAdd = ref<boolean>(false);
        const openDisplay = ref<boolean>(false);


        const showAddDrawer = (state: boolean) => {
            updateModalWidth();
            openAdd.value = true;
        };

        const afterOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };
    
        const showDrawer = (record: any) => {
            open.value = true;
            getById(record.id);
        };


        const showDisplayDrawer  = (record:any)=>{
            updateModalWidth();
            openDisplay.value = true;
            
            getById(record.id);

        }

        const afterDisplayOpenChange = (bool: boolean) => {
            console.log('open', bool);
        };

        const formatDisplayDate = (dateString: string) => {
            return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
        }

        const selectedStockable=(value:any, option:any)=>{
            console.log('selected stcokable id ', value);
            productService.show(value).then((res)=>{
                state.variants=res.data.variants?res.data.variants:[];
            });
        
        }

        const addStockItem=()=>{
            formRef.value
            .validate()
            .then(()=>{
                    
                const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).id : '';
        
                let payload = {
                    stockable_id:addForm.stockable_id,
                    stockable_type:addForm.stockable_type,
                    product_variant_id:addForm.product_variant_id,
                    warehouse_id:routeId.value,
                    user_id:userId,
                    unit:addForm.unit,
                    quantity:addForm.quantity,
                }

                loading.value=true;
                warehouseService.storeStockItem(payload)
                .then((response)=>{
                    
                    showSuccessNotification(t('Pos.AddStockItemSuccess'));
                    openAdd.value=false;
                    getAll();
                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    loading.value=false;
                   formRef.value.resetFields();
                }); 
            
            }).catch((error:any) => {
                console.error('Validation error:', error);
            });
            
        
        } 
        
        const getAll=()=>{
            loading.value = true;
            warehouseService.getStockItems("warehouse_id",routeId.value, currentPage.value,perPage.value).then((response)=>{
                state.data=response.data;
                state.totalPages=response.meta.total;
            }).catch((error)=>{
                console.log("error get stock items", error);
            }).finally(()=>{
            loading.value = false;
            });
        }

        const getById=(id:string)=>{
            loading.value=true;
            warehouseService.showStockItem(id).then((res)=>{
                form.stockable_type = getEntity(res.data.stockable_type);
                form.stockable_id=res.data.stockable_id;
                form.stockable_name=res.data.stockable.name;
                form.product_variant_id = res.data.product_variant_id;
                form.warehouse_id = res.data.warehouse_id;
                form.quantity = res.data.quantity;
                form.unit = res.data.unit;
                form.user_id = res.data.user_id;
                form.user_name = res.data.user.first_name +" "+res.data.user.last_name ;
                form.created_at = res.data.created_at;
                form.id = res.data.id;
                loading.value=false;
            });
        }


        const updateModalWidth = () => {
            modalWidth.value = window.innerWidth <=1200 ? window.innerWidth : 720;
        };

        const handleStockableChange=(selectedItem:any)=>{
            if(selectedItem==="product"){
                state.stockables=[];
                productService.getAll().then((response)=>{
                    state.stockables=response.data;
                    form.stockable_name=response.data[0].name;
                    form.stockable_id=response.data[0].id;
                }).catch((error)=>{
                console.log('error get products api', error);
                });
            }else if(selectedItem==="ingredient"){
                state.stockables=[];
                ingredientsService.getAll().then((response)=>{
                    state.stockables=response.data;
                    form.stockable_name=response.data[0].name;
                    form.stockable_id=response.data[0].id;
                }).catch((error)=>{
                console.log('error get ingredients api', error);
                });

            }

        }

        const selectedItem=(value:any, option:any)=>{
            form.stockable_id=value;
        
        }

       

        const update=(id:string)=>{
            formRef.value
            .validate()
            .then(()=>{
                const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!).id : '';

                let payload ={
                    stockable_id:form.stockable_id,
                    stockable_type:form.stockable_type,
                    warehouse_id:routeId.value,
                    user_id:userId,
                    unit:form.unit,
                    quantity:form.quantity,
                }
                /*
                warehouseService.updateStockItem(id,payload).then((response)=>{
                    open.value=false;
                    state.data = state.data.map(el => el.id !== id ? el : response.data);
                }).catch((err) => {
                console.log(err)
                }).finally(() => {
                    showSuccessNotification("Stock item updated successfully");
                    console.log('finish')
                }); 
                */
            })
        }

        const cancelUpdate=()=>{
            open.value=false;
        }

        const showDeletePopup = (record: any) => {
            Swal.fire({
                title: t('Pos.TitleDeleteStockItem'),
                text: t('Pos.TextDeleteStockItem'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#fc8019",
                confirmButtonText: t('Pos.Delete'),
                cancelButtonText: t('Pos.Cancel'),
                cancelButtonColor: "#d33",
                customClass: {
                popup: 'swal2-popup'
                }

            }).then((result) => {
                if (result.isConfirmed) {
                deleteItem(record.id);
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log("cancel delete ");
                }
            })
        };

        const deleteItem = (id: string) => {
            loading.value=true;
            warehouseService.deleteStockItem(id).then((res) => {
                state.data = state.data.filter(e => e.id !== id);
                loading.value=false;
                showSuccessNotification(t('Pos.DeleteStockItemSuccess'));
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                console.log('finish')
            })
        }; 

        const resetStockItem=()=>{
            formRef.value.resetFields();

        }

        
        onMounted(() => {
            const warehouseName=localStorage.getItem('warehouseName');
            state.warehouseName=warehouseName?warehouseName:"";
            routeId.value = route.params.id as string;
            getAll();
            searchInput.value = document.querySelector('.ant-table-filter-dropdown input');

        });

        const value = ref<string>('1');
        const selectedType =(value:any)=>{
            state.selectedType = value.target.value;
        }


        
        const openTransferModal = ref<boolean>(false);

        const showTransferModal = (record:any) => {
            openTransferModal.value = true;
            state.selectedItemQte=record.quantity;
            state.selectedType=record.stockable_type;
            state.selectedItemId=record.stockable_id;
        };

       
        //validate quantity: 
        const validatingStatus = ref('');
        const validatingHelp = ref('');
        const validateQte = ref(false);

        function isNumber(value:any) {
            return !isNaN(parseFloat(value)) && isFinite(value);
        }

        const validateQuantity = () => {
            if(formProd.quantity!=='' && isNumber(formProd.quantity)){

                if(state.selectedItemQte>=Number.parseInt(formProd.quantity)){
                    validatingStatus.value = 'success';
                    validatingHelp.value = '';
                    validateQte.value=true;
                }else{
                    validatingStatus.value = 'error';
                    validatingHelp.value = t('Pos.QuantityAboveError');
                    validateQte.value=false;
                }
            }else{
                validatingHelp.value = '';
            }
        }

        const resetFormProd=()=>{
            refForm.value.resetFields();
            validatingStatus.value = '';
            validatingHelp.value = '';
        }

        const getItemType=(str:string)=>{
            const parts = str.split('\\');
            const string= parts[parts.length - 1];
            return  string.charAt(0).toLocaleLowerCase() + string.slice(1)
        }

        const handleCancel = () => {
            openTransferModal.value = false;
            resetFormProd();
        };

        const handleTransferOk = (e: MouseEvent) => {
            
            if(validateQte.value==true){
                refForm.value
                .validate()
                .then(()=>{
                    let payload={
                        stockable_type:getItemType(state.selectedType),
                        stockable_id:state.selectedItemId,
                        quantity:formProd.quantity,
                        warehouse_id:routeId.value,
                        reason:formProd.reason,
                    }


                    stockAdjustmentService.add(payload).then((rep)=>{
                        openTransferModal.value = false;
                        
                        showSuccessNotification(t('Pos.AddStockadjustementSuccess'));
                    }).catch((error) => {
                        console.error('Error adding stock adjustement:', error); 
                    }).finally(()=>{
                        getAll();
                        resetFormProd();
                    });

                    
                   
                }).catch((error:any) => {
                    console.error('Validation error:', error);
                });
            }else if (!isNumber(formProd.quantity) && formProd.quantity!==''){
                validatingStatus.value = 'error';
                validatingHelp.value = t('Pos.numericField');
            }else if(formProd.quantity===''){
                validatingStatus.value = 'error';
                validatingHelp.value = t('Pos.RequiredField');
            }
        }

        //rtl
        const { locale } = useI18n();
        const textDirection = computed(() => {
            return locale.value === 'ar' ? 'rtl' :'ltr';}
        );




        return{
            t,
            textDirection,
            locale,
            state,
            loading,
            navigatePage,
            columns,
            perPage,
            currentPage,
            handlePageChange,
           getEntity,
           open,
           openAdd,
           openDisplay,
           showAddDrawer,
           afterOpenChange,
           showDrawer,
           showDisplayDrawer,
           afterDisplayOpenChange,
           formRef,
           addFormRef,
           refForm,
           formProd ,   
           form,
           addForm,
           rules,
           rulesProd,
           formatDisplayDate,
           handleStockableChange,
           selectedItem,
           update,
           cancelUpdate,
           showDeletePopup,
           selectedStockable,
           resetStockItem,
           addStockItem,
           handleSearch,
           handleReset,
           value,
           selectedType,
           perPageSearch,
           currentPageSearch,
           handlePageChangeSearch,
           handleTransferOk,
           openTransferModal,
           showTransferModal,
           validatingStatus,
           validatingHelp,
           validateQuantity,
           validateQte,
           handleCancel,
           resetFormProd,
           simpleImage,
           selectPagination,
           handleSelectPaginationChange,
           modalWidth,
           updateModalWidth


















        }
    },
})
</script>

<style scoped>

    .btn-container{
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-end;
    }

    .add-btn{
        color: #fc8019;
        border: 2px solid #fc8019;
        box-shadow: inset 0 0 0 0 #fc8019;
    }
    .add-btn:hover{
        color: #fff;
        box-shadow: inset 0 -100px 0 0 #fc8019;
        border-color: #fc8019;
    }

    .label-form{
        font-family: "Exo 2", sans-serif; 
        font-size: 14px;
        font-weight: 600;
    }

    .date-picker{
        width:100%
    }
    .new-location{
        display: flex;
        align-items: center;
    }
    .location-btn{
        margin-top: 7.5px;
        width: 100%;
    }
    .ant-upload-wrapper{
        color: #fc8019  ;
    }

    .view-btn{
        color: #fc8019;
        border-color: #fc8019;
        background: #fafafa;
    }

    .info-title{
        font-size: 15px !important;
        font-weight: bold;
        margin-right: 4px;
    }

    .pagination{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .variant-btn{
        color: #3b8dffdf;
        border-color: #3b8dffdf;
        background: #fafafa;
    }

    .variant-btn:hover{
        color: #3b8dffdf;
        border-color: #3b8dffdf;
        background: #fafafa; 
    }

    .breadcrumb-icon{
        cursor: pointer;
    }

    .search-btn-radio{
     display: flex;
    }

    .search-radio-grp{
        display: flex;
        flex-direction: column !important;
    }

    .radio-item{
        margin-bottom: 10px;
    }

    .warehouse-name-container{
        display: flex;
    }

    /* CSS for RTL */
    .is-rtl {
        direction:rtl;
    }
    
    .table-container {
    overflow-x: auto;
    }

    /* pagintaion */
    @media (max-width: 768px) {
        .pagination {
            flex-direction: column;
            align-items: flex-start;
        }

        .select-input {
            width: 100%;
            margin-top: 10px;
        }
    }




      



</style>
