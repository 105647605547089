<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{t('Pos.Dashboard')}}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.Settings')}}</a-breadcrumb-item>
      <a-breadcrumb-item>{{t('Pos.InvoiceSettings')}}</a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <a-row >
    <a-card
    class="card-settings"
    :tab-list="settingsTitle"
    :active-tab-key="key"
    @tabChange="key => onTabChange(key, 'key')"
  >
              
    <p v-if="key === 'general'">

      <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">


       <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item  name="decimal_seperator">
              <template #label>
                <span class="label-form">{{ t('Pos.DecimalSeparator') }}</span>
              </template>
              <a-select v-model:value="form.decimal_seperator" show-search  >
                <template #notFoundContent>
                  <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                </template>
                <a-select-option value="." >.</a-select-option>
                <a-select-option value="," >,</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item  name="thousands_seperator">
              <template #label>
                <span class="label-form">{{ t('Pos.ThousandsSeparator') }}</span>
              </template>
              <a-select v-model:value="form.thousands_seperator" show-search  >
                <template #notFoundContent>
                  <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                </template>
                <a-select-option value="." >.</a-select-option>
                <a-select-option value="," >,</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
       </a-row>

       


       
        <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="authTax">
              <a-checkbox v-model:checked="form.authTax">{{ t('Pos.showTawPeritem') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="excludeCurrencySymbol">
              <a-checkbox v-model:checked="form.excludeCurrencySymbol">{{ t('Pos.excludeCurrencySymbol') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" class="check-conatiner">
           
            <a-form-item name="roundNumbers">
              <a-checkbox v-model:checked="form.roundNumbers">{{ t('Pos.RoundNumbers') }}</a-checkbox>
              <a-tooltip>
                <template #title>{{t('Pos.roundNumbersTxt')}}</template>
                <QuestionCircleOutlined class="tooltip-icon"/>
              </a-tooltip>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" class="check-conatiner">
            
            <a-form-item name="excludeTaxName">
              <a-checkbox v-model:checked="form.excludeTaxName">{{ t('Pos.ExcludeTaxName') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">
            <a-form-item  name="tax_id">
                <template #label>
                    <span class="label-form">{{ t('Pos.defaultTax') }}</span>
                </template>

                <a-select v-model:value="form.defaultTax" show-search :option-filter-prop="'label'">

                    <template #notFoundContent>
                        <a-empty :description="t('Pos.NoData')" :image="simpleImage" />
                    </template>

                    <a-select-option v-for="item in state.vats" :label="item.name" :key="item.id" :value="item.id" :selected="item.id === form.defaultTax ">{{ item.name }}</a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
        </a-row>

       

        
       


      

       

       

        
      </a-form>

      <a-row class="card-footer">
        <a-space>
          <a-button  type="primary" @click="updateConfig()">{{ t('Pos.Update') }}    </a-button>
          <a-button type="primary" danger >{{ t('Pos.Cancel') }}</a-button>
        </a-space>
      </a-row>
    </p>

    <p v-else-if="key==='invoice'">

      <a-form ref="formInvoiceRef" :model="formInvoice" :rules="Invoicerules" layout="vertical">

        
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="invoice_number_type">
              <template #label>
                <span class="label-form">{{ t('Pos.InvoiceNumberGenerationMethod') }}</span>
              </template>
              <a-select v-model:value="formInvoice.invoice_number_type" show-search>
                <a-select-option value="inc">{{t('Pos.Incremental')}}</a-select-option>
                <a-select-option value="random">{{t('Pos.Random')}}</a-select-option>
              </a-select>

            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="number_prefix">
              <template #label>
                <span class="label-form">{{ t('Pos.InvoiceNumberPrefix') }}</span>
              </template>
              <a-input v-model:value="formInvoice.number_prefix" :placeholder="t('Pos.InvoiceNumberPrefix')" class="input-form" />
            </a-form-item>
          </a-col>
        </a-row>

        

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="nextNumber">
              <template #label>
                <span class="label-form">{{ t('Pos.InvoiceNextNumber') }}</span>
              </template>
              <a-input v-model:value="formInvoice.nextNumber" :placeholder="t('Pos.InvoiceNextNumber')" class="input-form" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="limitDate">
              <template #label>
                <span class="label-form">{{ t('Pos.limitDateInvoice') }}</span>
              </template>
              <a-input v-model:value="formInvoice.limitDate" :placeholder="t('Pos.limitDateInvoice')" class="input-form" />
            </a-form-item>
          </a-col>
        </a-row>


        <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="indicateContact">
              <a-checkbox v-model:checked="formInvoice.indicateContact">{{ t('Pos.IndicateContact') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        

        <!-- <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="requireCustomer">
              <a-checkbox v-model:checked="formInvoice.authCustomer">{{ t('Pos.requiresCustomer') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row> -->

        <!-- <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="allowInvoiceDelete">
              <a-checkbox v-model:checked="formInvoice.allowInvoiceDelete">{{ t('Pos.AllowInvoiceDelete') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row> -->

        <!-- <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="incrInvoiceNumberAfterdelete">
              <a-checkbox v-model:checked="formInvoice.incrInvoiceNumberAfterdelete">{{ t('Pos.incrementInvoiceNumberAfterDelete') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row> -->

        <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="excludeDraftInvoices">
              <a-checkbox v-model:checked="formInvoice.excludeDraftInvoices">{{ t('Pos.excludeDraftInvoices') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="anonymous_customer">
                <template #label>
                  <span class="label-form">{{ t('Pos.AnonymousCustomerDisplayName') }}</span>
                </template>
                <a-input v-model:value="formInvoice.anonymous_customer" :placeholder="t('Pos.AnonymousCustomerDisplayName')" class="input-form" />
            </a-form-item>
          </a-col>
        </a-row>


        <!-- <a-row>
          <a-col :span="24">
            <a-form-item  name="photo">
              <template #label>
                <span class="label-form">{{ t('Pos.Invoicelogo') }}</span>
              </template>

              <a-upload-dragger
                v-model:fileList="fileList"
                name="file"
                :multiple="false"
                :max-count="1"
                @change="handleChange"
                @drop="handleDrop"
                :before-upload="beforeUpload"
              >

              <p class="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p class="ant-upload-text">{{ t('Pos.dropTitle1') }}</p>
              <p class="ant-upload-hint">
                {{ t('Pos.dropTitle2') }}
              </p>

              </a-upload-dragger>

            </a-form-item>
          </a-col>
        </a-row> -->


      </a-form>

      <a-row class="card-footer">
        <a-space>
          <a-button  type="primary" @click="updateInvoice">{{ t('Pos.Update') }}    </a-button>
          <a-button type="primary" danger >{{ t('Pos.Cancel') }}</a-button>
        </a-space>
      </a-row>

    </p>


    <p  v-else-if="key==='credit'">
      <a-form ref="formCreditRef" :model="formCredit" :rules="rulesCredit" layout="vertical">
        
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="prefixNC">
              <template #label>
                <span class="label-form">{{ t('Pos.NCprefix') }}</span>
              </template>
              <a-input v-model:value="formCredit.prefixNC" :placeholder="t('Pos.NCprefix')" class="input-form" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="nextNumber">
              <template #label>
                <span class="label-form">{{ t('Pos.NcnextNumber') }}</span>
              </template>
              <a-input v-model:value="formCredit.nextNumber" :placeholder="t('Pos.NcnextNumber')" class="input-form" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <!-- <span class="label-form">{{ t('Pos.NumberFormat') }}</span> -->

          <a-col :span="24">
            <a-form-item name="formatNC">
              <template #label>
                <span class="label-form">{{ t('Pos.NumberFormat') }}</span>
              </template>
              <a-radio-group v-model:value="formCredit.formatNC"  >
                <a-radio value="baseNumber">{{ t('Pos.NumberBase') }} (000001)</a-radio>
                <a-radio value="yearAndNumber">{{ t('Pos.YearAndNumber') }} (YYYY/00001)</a-radio>
                <a-radio value="000001-YY">000001-YY</a-radio>
                <a-radio value="000001/MM/YYYY">">000001/MM/YYYY</a-radio>

              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>



        <!-- <a-row :gutter="16">
        
          <a-col :span="6" class="check-conatiner">
            <a-form-item name="format1">
              <a-checkbox v-model:checked="formCredit.format1">{{ t('Pos.NumberBase') }} (000001)</a-checkbox>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item name="format2">
              <a-checkbox v-model:checked="formCredit.format2">{{ t('Pos.YearAndNumber') }} (YYYY/00001)</a-checkbox>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item name="format3">
              <a-checkbox v-model:checked="formCredit.format3">000001-YY</a-checkbox>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item name="format4">
              <a-checkbox v-model:checked="formCredit.format4">000001/MM/YYYY</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row> -->

        <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="dcrInvoiceNumberAfterdelete">
              <a-checkbox v-model:checked="formCredit.dcrInvoiceNumberAfterdelete">{{ t('Pos.dcrNcAfterDelete') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="displayNameInNc">
              <a-checkbox v-model:checked="formCredit.displayNameInNc">{{ t('Pos.displayNameNC') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">

            <a-form-item name="notesClient">

              <template #label>
                <span class="label-form">{{ t('Pos.NotesClient') }}</span>
              </template>
              <a-textarea v-model:value="formCredit.notesClient" :rows="4" :placeholder="t('Pos.NotesClient')" />
            </a-form-item>

          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24">

            <a-form-item name="terms">

              <template #label>
                <span class="label-form">{{ t('Pos.Terms') }}</span>
              </template>
              <a-textarea v-model:value="formCredit.terms" :rows="4" :placeholder="t('Pos.Terms')" />
            </a-form-item>

          </a-col>
        </a-row>

      </a-form>

      <a-row class="card-footer">
        <a-space>
          <a-button  type="primary" @click="updateNC()">{{ t('Pos.Update') }}    </a-button>
          <a-button type="primary" danger >{{ t('Pos.Cancel') }}</a-button>
        </a-space>
      </a-row>
    </p>

    <p v-else-if="key==='devis'">

      <a-form ref="formDevisRef" :model="formDevis" :rules="rulesDevis" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="prefix">
              <template #label>
                <span class="label-form">{{ t('Pos.Quotationprefix') }}</span>
              </template>
              <a-input v-model:value="formDevis.prefix" :placeholder="t('Pos.Quotationprefix')" class="input-form" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="nextNumber">
              <template #label>
                <span class="label-form">{{ t('Pos.QuotationnextNumber') }}</span>
              </template>
              <a-input v-model:value="formDevis.nextNumber" :placeholder="t('Pos.QuotationnextNumber')" class="input-form" />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="quotationLimitDate">
              <template #label>
                <span class="label-form">{{ t('Pos.QuotationLimit') }}</span>
              </template>
              <a-input v-model:value="formDevis.quotationLimitDate" :placeholder="t('Pos.QuotationLimit')" class="input-form" />
            </a-form-item>
          </a-col>
        </a-row>

       
        <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="deleteLatest">
              <a-checkbox v-model:checked="formDevis.deleteLatest">{{ t('Pos.AllowDeleteLatest') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="dcrNumberAfterDelete">
              <a-checkbox v-model:checked="formDevis.dcrNumberAfterDelete">{{ t('Pos.dcrNumberAfterDelete') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="allowCollabs">
              <a-checkbox v-model:checked="formDevis.allowCollabs">{{ t('Pos.allowCollabsDevis') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" class="check-conatiner">
            <a-form-item name="showStoreNameQuotation">
              <a-checkbox v-model:checked="formDevis.displayNameInNc">{{ t('Pos.showStoreNameQuotation') }}</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="card-footer">
          <a-space>
            <a-button  type="primary" @click="updateDevis()">{{ t('Pos.Update') }}    </a-button>
            <a-button type="primary" danger >{{ t('Pos.Cancel') }}</a-button>
          </a-space>
        </a-row>

       

      </a-form>

    </p>
            
    </a-card>
  </a-row>
    
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, reactive,  } from 'vue';
import { useRouter } from 'vue-router';
import { authService, vatrateService } from '@/_services';
import { useI18n } from 'vue-i18n';
import {

  InboxOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { storeService  } from '@/_services';
import LbrxLoaderSpinner from '../../components/LbrxLoaderSpinner.vue';
import { Rule } from 'ant-design-vue/es/form';
import { notification, UploadChangeParam, UploadFile } from 'ant-design-vue';
import { Empty } from 'ant-design-vue';
export default defineComponent({
    name: 'HomeView',
    components: {
        
      LbrxLoaderSpinner,
      //InboxOutlined,
      QuestionCircleOutlined

        
        
    },

    setup() {
      const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

      const { t } = useI18n();
      const loading = ref(false);
      const router = useRouter();
      const navigatePage = (link: string) => {
        router.push(link); 
      };

      const state = reactive({
        vats: [] as any[],

      });


      const settingsTitle = [
       
        {
          key: 'general',
          tab: t('Pos.General'),
        },
        {
          key: 'invoice',
          tab: t('Pos.Invoice'),
        },
        // {
        //   key: 'credit',
        //   tab: t('Pos.CreditNote'),
        // },
        // {
        //   key: 'devis',
        //   tab: t('Pos.Quotation'),
        // },
        // {
        //   key: 'offers',
        //   tab: t('Pos.Offers'),
        // },
      ];
      const key = ref('general');

      const onTabChange = (value: string, type: string) => {
        console.log("value : ",value,"type: ,", type);
        
        key.value = value;
           
      };

      //form 
      const formRef = ref();
      const formInvoiceRef=ref();
      const formCreditRef=ref();

      const formDevisRef=ref();

      const formDevis=reactive({

        prefix:'',
        nextNumber:'',
        quotationLimitDate:'',
        deleteLatest:true,
        dcrNumberAfterDelete:true,
        allowCollabs:true,
        showStoreName:true,
        displayNameInNc:false,




        


      });



      const formCredit=reactive({

        prefixNC:'',
        nextNumber:'',
        dcrInvoiceNumberAfterdelete:true,
        displayNameInNc:true,
        format1:true,
        format2:false,
        format3:false,
        format4:false,
        
        notesClient:'',
        terms:'',
        formatNC:'',


      });



      const formInvoice=reactive({
        invoicePrefix:'',
        nextNumber:'',
        limitDate:'',
        authCollabs:true,
        authCustomer:false,
        allowInvoiceDelete:true,
        incrInvoiceNumberAfterdelete:true,
        excludeDraftInvoices:true,
        format1:true,
        format2:false,
        format3:false,
        format4:false,
        
        indicateContact:true,
        description:'',
        photo:'',

        invoice_number_type:'',
        number_prefix: '',
        anonymous_customer:'',

        defaultTax:'',


      });

      const form = reactive({
        id: '',
        invoice_number_type:'',
        number_prefix: '',
        start_number: '',
        dueDate:'',
        authTax:true,
        excludeCurrencySymbol:false,
        excludeTaxName:false,
       
        
        roundNumbers:false,
        defaultTax:'',

        decimal_seperator:'.',
        thousands_seperator:'.',


      
      });

      const rules: Record<string, Rule[]> = {
      };

      const Invoicerules: Record<string, Rule[]> = {
        invoice_number_type:[{ required: true, message: t('Pos.RequiredField') }],
        anonymous_customer: [{ required: true, message: t('Pos.RequiredField') }],
        number_prefix: [{ required: true, message: t('Pos.RequiredField') }],
        limitDate:[
          { 
            validator: (_, value) => {
              if (!isNaN(value)) {
              return Promise.resolve();
              }
              return Promise.reject( t('Pos.numericField'));
            }
          }
        ]
      }

      const rulesCredit :Record<string, Rule[]> = {
        prefixNC:[{ required: true, message: t('Pos.RequiredField') }],
        format: [{ required: true, message: 'Please select activity resource', trigger: 'change' }],

      }

      const rulesDevis:Record<string, Rule[]> = {
        prefix:[{ required: true, message: t('Pos.RequiredField') }],
        quotationLimitDate:[
          { 
            validator: (_, value) => {
              if (!isNaN(value)) {
              return Promise.resolve();
              }
              return Promise.reject( t('Pos.numericField'));
            }
          }
        ]
      }

      const formatNC = ref<string>('');
      const plainOptions = [t('Pos.NumberBase')+'(000001)', t('Pos.YearAndNumber')+'(YYYY/00001)', '000001-YY','000001/MM/YYYY'];




      //notification
      const showSuccessNotification  = (message:string)=>{
        notification.success({
          message: message,
          duration: 3,
          top: '80px',
          style:{
          backgroundColor: '#bfffca8a',
          color: 'white'
          }
        });
      };

      //file 
      const fileList = ref([]);
      const fileInfo = ref<UploadFile | null>(null);
      const handleChange = (info: UploadChangeParam) => {
        fileInfo.value=info.file;
      };

      function handleDrop(e: DragEvent) {
        console.log(e);
      }

      const beforeUpload = () => {
        return false;
      };

      const updateConfig=()=>{
        formRef.value.validate()
        .then(()=>{

          const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';
          const id=storeItem.id;
          let general_config={
            decimal_seperator:form.decimal_seperator,
            thousands_seperator:form.thousands_seperator,
            auth_tax:form.authTax?1:0,
            exclude_currency_symbol:form.excludeCurrencySymbol?1:0,
            exclude_tax_name:form.excludeTaxName?1:0,
            default_tax:form.defaultTax,
            rounded_numbers:form.roundNumbers?1:0,
          }
          let payload={
            id:id,
            config: JSON.stringify({
              general_config: general_config
            }),
          }
          console.log('payload', payload);
          storeService.update(id,payload).then((res)=>{
            console.log('update');
            showSuccessNotification(t('Pos.BillingSettingsUpdateSuccess'));
          }).catch((error)=>{
            console.log('error updating', error);
          });
        }).catch((error:any)=>{
          console.log('erorr validation form', error);
        })

      }

      const updateDevis=()=>{
       formDevisRef.value.validate()
        .then(()=>{

          const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';
          const id=storeItem.id;

          let devisConfig={
            prefix:formDevis.prefix,
            nextNumber:formDevis.nextNumber,
            quotation_limit_date:formDevis.quotationLimitDate,
            delete_latest:formDevis.deleteLatest?1:0,
            dcr_number_after_delete:formDevis.dcrNumberAfterDelete?1:0,
            allow_collabs:formDevis.allowCollabs,
            show_store_name_quotation:formDevis.displayNameInNc,
          }

          let payload={
            id:id,
            config: JSON.stringify({
              quotation_config: devisConfig
            }),
            
          }

          console.log('payload', payload);
          storeService.update(id,payload).then((res)=>{
            console.log('update');
            showSuccessNotification(t('Pos.BillingSettingsUpdateSuccess'));
          }).catch((error)=>{
            console.log('error updating', error);
          });
        }).catch((error:any)=>{
          console.log('erorr validation form', error);
        })
      }

      
      const getMyStore=()=>{
        const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';
        const id=storeItem.id;
        loading.value=true;
        storeService.show(id).then((res)=>{
          console.log('res',res);
          const config=JSON.parse(res.data.config);
          formInvoice.number_prefix=config.invoice_config.prefix;
          formInvoice.anonymous_customer=config.invoice_config.anonymous_customer;
          formInvoice.invoice_number_type=config.invoice_config.type;
        }).catch((error)=>{
          console.log('error', error);
        }).finally(()=>{
          loading.value=false;
        })

      }

      const getVAT=()=>{
        vatrateService.getAll().then((res)=>{
          state.vats=res.data;
        }).catch((error)=>{
          console.log(error);
        })
      }

      const updateInvoice=()=>{
        formInvoiceRef.value.validate().then(()=>{
          
          const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';
          const id=storeItem.id;
          let invoice_config={
            type:formInvoice.invoice_number_type,
            prefix:formInvoice.number_prefix,
            anonymous_customer:formInvoice.anonymous_customer,
            nextNumber:formInvoice.nextNumber,
            payement_due_date:formInvoice.limitDate,
            indicate_contact_invoice:formInvoice.indicateContact?1:0,
            authCollabs:formInvoice.authCollabs?1:0,
            authCustomers:formInvoice.authCustomer?1:0,
            allow_invoice_delete:formInvoice.allowInvoiceDelete?1:0,
            incr_invoice_number_after_delete:formInvoice.incrInvoiceNumberAfterdelete?1:0,
            exclude_draft_invoices:formInvoice.excludeDraftInvoices?1:0,
          }
          let payload={
            id:id,
            config: JSON.stringify({
              invoice_config: invoice_config
            }),
          }
          

          console.log('invoice payload', payload);
         
          storeService.update(id,payload).then((res)=>{
            console.log('update');
            showSuccessNotification(t('Pos.BillingSettingsUpdateSuccess'));
          }).catch((error)=>{
            console.log('error updating', error);
          });
        }).catch((error:any)=>{
          console.log(error);
        })
      }

      const updateNC=()=>{
        formCreditRef.value.validate().then(()=>{
          const storeItem =localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')!) : '';
          const id=storeItem.id;

          let ncConfig={
           
            prefixNC:formCredit.prefixNC,
            nextNumber:formCredit.nextNumber,
            displayName:formCredit.displayNameInNc?1:0,
            dcr_invoice_number_after_delete:formCredit.dcrInvoiceNumberAfterdelete?1:0,
            notes:formCredit.notesClient,
            terms:formCredit.terms,
            format:formCredit.formatNC,

          }

          let payload={
            id:id,
            config: JSON.stringify({
              nc_config: ncConfig
            }),
          }

          console.log('config', ncConfig);
          storeService.update(id,payload).then((res)=>{
            console.log('update');
            showSuccessNotification(t('Pos.BillingSettingsUpdateSuccess'));
          }).catch((error)=>{
            console.log('error updating', error);
          });




        }).catch((error:any)=>{
          console.log(error);
        })
      }


      onMounted(() => {
        getVAT();
        getMyStore();

            
      });

      return{
        t,
        loading,
        navigatePage,
        state,
        Invoicerules,
        settingsTitle,
        key,
        onTabChange,
        formRef,
        form,
        fileList,
        handleChange,
        handleDrop,
        beforeUpload,
        rules,
        formInvoiceRef,
        formInvoice,
        updateConfig,
        formCredit,
        formCreditRef,
        formDevisRef,
        formDevis,
        simpleImage,
        updateInvoice,
        rulesCredit,
        updateNC,
        formatNC,
        plainOptions,
        rulesDevis,
        updateDevis



        






      }

        
    },
})
</script>

<style scoped>

  .breadcrumb-icon{
    cursor: pointer;
  }

  .card-settings{
    width: 100%;
    margin-top: 15px;
  }

  .label-form {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  .check-conatiner{
    display: flex;
  }
  /* loader container */
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }


</style>
