<template>
  <div>
    <LbrxLoaderSpinner :is-loaded="!loading" />
  </div>

  <div>
    <a-breadcrumb
      style="margin: 16px 0"
      :class="{ 'is-rtl': textDirection === 'rtl' }"
    >
      <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
        {{ t("Pos.Home") }}
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ t("Pos.Settings") }}</a-breadcrumb-item>
      <a-breadcrumb-item>{{ t("Pos.LoyaltySettings") }} </a-breadcrumb-item>
    </a-breadcrumb>
  </div>

  <div class="btn-container">
    <a-button class="add-btn" @click="showAddDrawer(true)">
      {{ t("Pos.Add") }}
    </a-button>
  </div>
  <div>
    <a-table
      class="table-container"
      :columns="columns"
      :data-source="reactiveData.data"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key == 'actions'">
          <div class="btn-container">
            <a-space>
              <a-tooltip>
                <template #title> {{ t("Pos.View") }}</template>
                <a-button class="view-btn" @click="showDisplayDrawer(record)">
                  <template #icon>
                    <EyeOutlined />
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>{{ t("Pos.Edit") }}</template>

                <a-button type="primary" @click="() => showDrawer(record)">
                  <template #icon>
                    <EditOutlined />
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>{{ t("Pos.Delete") }}</template>
                <a-button
                  type="primary"
                  danger
                  @click="showDeletePopup(record)"
                >
                  <template #icon>
                    <DeleteOutlined />
                  </template>
                </a-button>
              </a-tooltip>
            </a-space>
          </div>
        </template>

        <template v-if="column.key == 'key'">
          {{ formatName(record) }}
        </template>

        <template v-if="column.key == 'type'">
          <span v-if="form.type==='per_product'">{{ t("Pos.perProduct") }}</span>
          <span v-if="form.type==='per_spent'">{{ t("Pos.perSpent") }}</span>
          <span v-if="form.type==='per_visit'">{{ t("Pos.perVisit") }}</span>
        </template>
       

        <template
          v-if="column.key === 'created_at' || column.key === 'updated_at'"
        >
          {{ formatDisplayDate(record[column.key]) }}
        </template>
      </template>

      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />

          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            {{ t("Pos.Search") }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            {{ t("Pos.Reset") }}
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
    </a-table>

    <a-drawer
      v-model:open="open"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenChange"
      @close="hideForm"
    >
      <template #title>
        <div
          class="drawer-header-ar"
          v-if="locale === 'ar' && reactiveData.action == 'add'"
        >
          <span>{{ t("Pos.AddLoyaltyDrawer") }}</span>
        </div>
        <div
          class="drawer-header-all"
          v-if="locale !== 'ar' && reactiveData.action == 'add'"
        >
          <span>{{ t("Pos.AddWarehouseDrawer") }}</span>
        </div>

        <div
          class="drawer-header-ar"
          v-if="locale === 'ar' && reactiveData.action == 'update'"
        >
          <span>{{ t("Pos.UpdateLoyaltyDrawer") }}</span>
        </div>
        <div
          class="drawer-header-all"
          v-if="locale !== 'ar' && reactiveData.action == 'update'"
        >
          <span>{{ t("Pos.UpdateLoyaltyDrawer") }}</span>
        </div>
      </template>
      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="type">
              <template #label>
                <span class="label-form"> {{ t("Pos.Type") }}</span>
              </template>
              <a-select
                v-model:value="form.type"
                class="input-form"
                :disabled="isDisabled"
              >
                <a-select-option value="per_product"
                  > {{ t("Pos.perProduct") }}</a-select-option
                >
                <a-select-option value="per_visit"> {{ t("Pos.perVisit") }}</a-select-option>
                <a-select-option value="per_spent"> {{ t("Pos.perSpent") }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16" v-if="form.type == 'per_product'">
          <a-col :span="24">
            <a-form-item name="product">
              <template #label>
                <span class="label-form">{{ t("Pos.Product") }}</span>
              </template>

              <a-select
                v-model:value="form.active"
                placeholder="Choose your product"
                class="input-form"
                show-search
                :option-filter-prop="'label'"
              >
                <a-select-option
                  v-for="product in reactiveDataForProduct.data"
                  :key="product.id"
                  :value="product.id"
                  :label="product.name"
                >
                  {{ product.name }}
                </a-select-option>
              </a-select>

              <!--   <a-select v-model:value="form.active" placeholder="Choose your product" class="input-form" show-search :option-filter-prop="'label'" mode="multiple">
  <a-select-option v-for="product in reactiveDataForProduct.data" :key="product.id" :value="product.id" :label="product.name">
    {{ product.name }}
  </a-select-option>
</a-select>

-->
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="quantityConsumption">
              <template #label>
                <span class="label-form">{{
                  t("Pos.quantityConsumption")
                }}</span>
              </template>
              <a-input
                v-model:value="form.quantityConsumption"
                placeholder="description"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item name="customerEarns">
              <template #label>
                <span class="label-form">{{ t("Pos.customerEarns") }}</span>
              </template>
              <a-input
                v-model:value="form.customerEarns"
                placeholder="name"
                class="input-form"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <template #footer>
        <div :class="{ 'is-rtl': textDirection === 'rtl' }">
          <a-space v-if="reactiveData.action == 'update'">
            <a-button type="primary" @click="onSubmitUpdate(form.id)">{{
              t("Pos.Update")
            }}</a-button>
            <a-button type="primary" danger>{{ t("Pos.Cancel") }}</a-button>
          </a-space>
          <a-space v-else>
            <a-button type="primary" @click="onSubmitAdd">{{
              t("Pos.Add")
            }}</a-button>
            <a-button type="primary" danger @click="hideForm">{{
              t("Pos.Cancel")
            }}</a-button>
          </a-space>
        </div>
      </template>
    </a-drawer>

    <!-- Details-->
    <a-drawer
      v-model:open="openDisplay"
      class="custom-class"
      root-class-name="root-class-name"
      :root-style="{ color: 'blue' }"
      style="color: red"
      :title="null"
      :placement="locale === 'ar' ? 'left' : 'right'"
      :width="modalWidth"
      :body-style="{ paddingBottom: '80px' }"
      @after-open-change="afterOpenShowChange"
      @close="hideForm"
    >
      <template #title>
        <div class="drawer-header-ar" v-if="locale === 'ar'">
          <span>{{ t("Pos.DetailsLoyaltysDrawer") }}</span>
        </div>
        <div class="drawer-header-all" v-if="locale !== 'ar'">
          <span>{{ t("Pos.DetailsLoyaltysDrawer") }}</span>
        </div>
      </template>

      <a-form
        ref="formRef"
        :model="form"
        :rules="rules"
        layout="vertical"
        :class="{ 'is-rtl': textDirection === 'rtl' }"
      >
        <a-row :gutter="16">
          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="type"
                >{{ t("Pos.Type") }} :</span
              >
              <span v-if="form.type==='per_product'">{{ t("Pos.perProduct") }}</span>
              <span v-if="form.type==='per_spent'">{{ t("Pos.perSpent") }}</span>
              <span v-if="form.type==='per_visit'">{{ t("Pos.perVisit") }}</span>
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="product"
                >{{ t("Pos.Product") }} :</span
              >
              {{ form.active }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="quantity">
                {{ t("Pos.quantityConsumption") }} :</span
              >
              {{ form.quantityConsumption }}
            </p>
          </a-col>

          <a-col :span="12">
            <p>
              <span class="info-title" placeholder="usernanme"
                >{{ t("Pos.customerEarns") }}
              </span>
              {{ form.customerEarns }}
            </p>
          </a-col>
        </a-row>
      </a-form>

      <template #footer> </template>
    </a-drawer>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRaw,
  watch,
  computed,
} from "vue";
import type { Rule } from "ant-design-vue/es/form";
import Swal from "sweetalert2";
import {
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import { notification } from "ant-design-vue";
import { loyaltyService, productService } from "@/_services";
import dayjs from "dayjs";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "indexLoyalty",
  components: {
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    LbrxLoaderSpinner,
    SearchOutlined,
  },

  setup() {
    const modalWidth = ref(720);
    const { locale } = useI18n();
    const isDisabled = ref(false);
    const loading = ref(false);
    const { t } = useI18n();
    const columns = [
      {
        title: t("Pos.Type"),
        key: "type",
        dataIndex: "type",
        customFilterDropdown: true,
        onFilter: (value: string, record: any) =>
          record.type.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible: boolean) => {
          if (visible && !searchInput.value) {
            setTimeout(() => {
              const input = document.querySelector(
                ".ant-table-filter-dropdown input"
              );

              if (input instanceof HTMLInputElement) {
                searchInput.value = input;
                searchInput.value.focus();
              }
            }, 100);
          }
        },
      },

      {
        title: t("Pos.ProductName"),
        key: "key",
        dataIndex: "key",
      },

      {
        title: t("Pos.quantityConsumption"),
        key: "quantity_consumption",
        dataIndex: "quantity_consumption",
      },

      {
        title: t("Pos.customerEarns"),
        key: "customer_earns",
        dataIndex: "customer_earns",
      },

      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      },
    ];

    //form

    const formRef = ref();
    const visible = ref<boolean>(false);

    const reactiveData = reactive({
      data: [] as any[],
      action: "",
      totalPages: 0,
      searchedColumn: "",
      searchText: "",
    });

    const reactiveDataForProduct = reactive({
      data: [] as any[],
      action: "",
    });

    const form = reactive({
      id: "",
      name: "",
      description: "",
      active: "",
      type: "per_product",
      customerEarns: "",
      quantityConsumption: "",
      produits: "",
      key: "",
      value: "",
      isDisabled: false,
    });

    const resetForm = () => {
        form.id = '';
        form.name = '';
        form.description = '';

        form.customerEarns="",
        form.quantityConsumption="",
        form.produits='',
        form.type='per_product',
        form.key='',
        form.value=''
        form.isDisabled = false,
        form.active = ''
    };

    const perPage = ref<number>(7);
    const currentPage = ref<number>(1);
    const rules: Record<string, Rule[]> = {
      type: [{ required: true, message: t("Pos.RequiredField") }],
      quantityConsumption: [
        { required: true, message: t("Pos.RequiredField") },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
            return Promise.resolve();
            }
            return Promise.reject( t('Pos.numericField'));
          }
        }
      ],

      customerEarns: [
        { required: true, message: t("Pos.RequiredField") },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
            return Promise.resolve();
            }
            return Promise.reject( t('Pos.numericField'));
          }
        }
      ],
     
    };

    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });

    const formatDate = (dateString: string) => {
      return dayjs(dateString);
    };

    const formatDisplayDate = (dateString: string) => {
      return dayjs(dateString).format("DD-MM-YYYY HH:mm:ss");
    };

    const openDisplay = ref<boolean>(false);
    const afterOpenShowChange = (bool: boolean) => {
      console.log("open", bool);
    };

    const showDisplayDrawer = (record: any) => {
      updateModalWidth();
      openDisplay.value = true;
      getLoyaltyProgramById(record.id);
      resetForm();
      reactiveData.action = "view";
    };

    const formatName = (dateString: any) => {
      if (dateString && dateString.product) {
        if (dateString.product.name) {
          return dateString.product.name;
        }
      }
      return "";
    };

    //drawer

    const handlePageChange = (newPage: any) => {
      currentPage.value = newPage;
      loading.value = true;

      console.log("per page", perPage.value);
      console.log("page", currentPage.value);

      loyaltyService
        .getAll(currentPage.value, perPage.value)
        .then((response) => {
          console.log("data", response.data);
          reactiveData.data = response.data;
        })
        .catch((error) => {
          console.log("error api : get products", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const open = ref<boolean>(false);
    const searchInput = ref<HTMLInputElement | null>(null);

    const showAddDrawer = (state: boolean) => {
      updateModalWidth();
      open.value = state;
      reactiveData.action = "add";
      resetForm();
      isDisabled.value = false;
    };

    const afterOpenChange = (bool: boolean) => {
      console.log("open", bool);
    };

    const showDrawer = (record: any) => {
      updateModalWidth();
      open.value = true;
      getLoyaltyProgramById(record.id);
      reactiveData.action = "update";
      isDisabled.value = true;
      resetForm();
    };

    const router = useRouter();
    const navigatePage = (link: string) => {
      router.push(link);
    };

    const openAdd = ref<boolean>(false);

    const afterOpenAddChange = (bool: boolean) => {
      console.log("open", bool);
    };

    const showSuccessNotification = (message: string) => {
      notification.success({
        message: message,
        duration: 3,
        top: "80px",
        style: {
          backgroundColor: "#bfffca8a",
          color: "white",
        },
      });
    };

    const onSubmitAdd = () => {
      formRef.value
        .validate()
        .then(() => {
          addLoyaltyProgram();
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    };

    const hideForm = (bool: boolean) => {
      open.value = false;
      formRef.value.resetFields();
    };

    const onSubmitUpdate = (id: string) => {
      formRef.value
        .validate()
        .then(() => {
          updateLoyalityPorogram(id);
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    };

    const getLoyaltyProgram = () => {
      loading.value = true;
      loyaltyService
        .getAll(currentPage.value, perPage.value)
        .then((res) => {
          reactiveData.data = res.data;
          reactiveData.totalPages = res.meta.total;
        })
        .catch((error) => {
          console.log("error api : get loyalty", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const getProductList = () => {
      productService
        .getProductsForLoyalty("name", "", "off")
        .then((res) => {
          reactiveDataForProduct.data = res.data;
          console.log(reactiveDataForProduct.data[0].name);
        })
        .catch((error: any) => {
          console.error("Error fetching Roles:", error);
        });
    };

    const getLoyaltyProgramById = (id: string) => {
      loyaltyService
        .show(id)
        .then((res) => {
          form.id = res.data.id;
          form.type = res.data.type;
          form.customerEarns = res.data.customer_earns;
          form.produits = res.data.product_id;
          form.quantityConsumption = res.data.quantity_consumption;
          form.active = res.data.id;
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    };

    const addLoyaltyProgram = () => {
      loading.value = true;

      let payload = {
        type: form.type,
        customer_earns: form.customerEarns,
        quantity_consumption: form.quantityConsumption,
        product_id: form.active,
      };

      loyaltyService
        .create(payload)
        .then((res) => {
          reactiveData.data.push(res.data);
          showSuccessNotification(t("Pos.AddLoyalty"));
          setTimeout(() => {
            location.reload();
          }, 400);

          location.reload();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          open.value = false;
          //location.reload();
          loading.value = false;
        });
    };

    const updateLoyalityPorogram = (id: string) => {
      loading.value = true;

      let payload = {
        type: form.type,
        customer_earns: form.customerEarns,
        quantity_consumption: form.quantityConsumption,
        product_id: form.active,
      };

      loyaltyService
        .update(id, payload)
        .then((res) => {
          reactiveData.data = reactiveData.data.map((el) =>
            el.id !== id ? el : res.data
          );
          showSuccessNotification(t("Pos.UpdateLoyalty"));
          location.reload();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          open.value = false;
          //location.reload();
          loading.value = false;
        });
    };

    const deleteLoyaltyProgram = (id: string) => {
      loading.value = true;
      loyaltyService
        .delete(id)
        .then((res) => {
          reactiveData.data = reactiveData.data.filter((e) => e.id !== id);
          showSuccessNotification(t("Pos.DeleteLoyalty"));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.log("finish");
          loading.value = false;
        });
    };

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
      confirm();
      reactiveData.searchText = selectedKeys[0];
      reactiveData.searchedColumn = dataIndex;
    };

    const handleReset = (clearFilter: (arg: any) => void) => {
      clearFilter({ confirm: true });
      reactiveData.searchText = "";
    };

    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };

    const showDeletePopup = (record: any) => {
      Swal.fire({
        title: t("Pos.TitleDeleteLoyalty"),
        text: t("Pos.TextDeleteLoyalty"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#fc8019",
        confirmButtonText: t("Pos.Delete"),
        cancelButtonColor: "#d33",
        cancelButtonText: t("Pos.Cancel"),
        customClass: {
          popup: "swal2-popup",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteLoyaltyProgram(record.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancel delete ");
        }
      });
    };

    onMounted(() => {
      getLoyaltyProgram();
      getProductList();
    });

    return {
      updateModalWidth,
      modalWidth,
      textDirection,
      locale,
      afterOpenChange,
      handleSearch,
      handleReset,
      handlePageChange,
      currentPage,
      perPage,
      t,
      navigatePage,
      rules,
      columns,
      formRef,
      form,
      formatDisplayDate,
      reactiveData,
      showAddDrawer,
      showDrawer,
      open,
      formatDate,
      onSubmitUpdate,
      onSubmitAdd,
      showDeletePopup,
      visible,
      hideForm,
      reactiveDataForProduct,
      formatName,
      isDisabled,
      openDisplay,
      afterOpenShowChange,
      showDisplayDrawer,
      loading,
    };
  },
});
</script>

<style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.breadcrumb-icon {
  cursor: pointer;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.test-btn {
  color: #bfffca8a;
}

.is-rtl {
  direction: rtl;
}

.drawer-header-ar {
  padding-right: 2%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.drawer-header-all {
  display: flex;
  justify-content: start;
  align-items: center;
}

.paginationArrows {
  direction: ltr;
}
.table-container {
  overflow-x: auto;
}
</style>