<template>

    <div>
        <LbrxLoaderSpinner :is-loaded="!loading" />
    </div>

    <div>
        <a-breadcrumb style="margin: 16px 0">
        <a-breadcrumb-item @click="navigatePage('/')" class="breadcrumb-icon">
            {{ t("Pos.Dashboard") }}
        </a-breadcrumb-item>
        <a-breadcrumb-item> {{ t("Pos.Promotion") }}</a-breadcrumb-item>
        <a-breadcrumb-item> {{ t("Pos.giftCard") }}</a-breadcrumb-item>
        </a-breadcrumb>
    </div>

    <div>
        <div class="btn-container">
            <a-button class="add-btn"  @click="showAddDrawer">
               {{ t('Pos.Add') }}
            </a-button>
        </div>
    </div>




    <a-table :columns="columns" :data-source="state.data"  :pagination="false" class="table-container">
       
        <!-- search -->
        <template
        #customFilterDropdown="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
        }"
        >
        <div style="padding: 8px">
            <template v-if="column.key === 'number'">
            <a-input
                ref="searchInput"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                handleSearch(selectedKeys, confirm, column.dataIndex)
                "
            />
            </template>

            <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
            >
            {{ t("Pos.Search") }}
            </a-button>
            <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
            >
            {{ t("Pos.Reset") }}
            </a-button>
        </div>
        </template>

        <template #customFilterIcon="{ filtered }">
            <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>

    </a-table>

    <!-- add drawer -->

    <a-drawer
        v-model:open="openAdd"
        class="custom-class"
        root-class-name="root-class-name"
        :placement="locale === 'ar' ? 'left' : 'right'"
        :width="modalWidth"
        @close="resetFormAdd"
    >
        <template #title>
        <p :class="{ 'is-rtl': textDirection === 'rtl' }">
            {{ t("Pos.AddGiftCard") }}
        </p>
        </template>

        <a-form
            ref="addFormRef"
            :model="addForm"
            :rules="rules"
            layout="vertical"
            :class="{ 'is-rtl': textDirection === 'rtl' }"
        >


        <a-row :gutter="16">
            <a-col :span="24">

                <a-form-item name="userEmail">
                    <template #label>
                        <span class="label-form">{{ t("Pos.RecipientEmail") }}</span>
                    </template> 
                    <a-input
                        v-model:value="addForm.userEmail"
                        :placeholder="t('Pos.InvoiceNumber')"
                        class="input-form"
                    />
                </a-form-item>

            </a-col> 
        </a-row>

        <a-row :gutter="16">
            <a-col :span="12">
                <a-form-item name="userFirstName">
                    <template #label>
                        <span class="label-form">{{ t("Pos.RecipientFirstName") }}</span>
                    </template> 
                    <a-input
                        v-model:value="addForm.userFirstName"
                        :placeholder="t('Pos.RecipientFirstName')"
                        class="input-form"
                    />
                </a-form-item>
            </a-col>

            <a-col :span="12">
                <a-form-item name="userLastName">
                    <template #label>
                        <span class="label-form">{{ t("Pos.RecipientLastName") }}</span>
                    </template> 
                    <a-input
                        v-model:value="addForm.userLastName"
                        :placeholder="t('Pos.RecipientLastName')"
                        class="input-form"
                    />
                </a-form-item>
            </a-col>
        </a-row>


        <a-row :gutter="16">
            <a-col :span="12">
            <a-form-item name="amount">
                <template #label>
                <span class="label-form">{{ t("Pos.Amount") }}</span>
                </template>
                <a-input
                v-model:value="addForm.amount"
                :placeholder="t('Pos.Amount')"
                class="input-form"
                />
            </a-form-item>
            </a-col>

            <a-col :span="12">
            <a-form-item name="expiry_date">
                <template #label>
                <span class="label-form">{{ t("Pos.ExpiresIn") }}</span>
                </template>
                <a-date-picker
                :placeholder="t('Pos.ExpiresIn')"
                v-model:value="addForm.expiry_date"
                class="range-picker"
                />
            </a-form-item>
            </a-col>

        </a-row>

    
        </a-form>

        <template #footer>
            <a-space>
                <a-button type="primary" @click="add">{{ t("Pos.Add") }}</a-button>
                <a-button type="primary" danger @click="resetFormAdd">{{
                t("Pos.Cancel")
                }}</a-button>
            </a-space>
        </template>
  </a-drawer>

    
</template>

<script  lang="ts">
import { useI18n } from "vue-i18n";

import {
  ref,
  defineComponent,
  reactive,
  onMounted,
  computed,
  watch,
} from "vue";
import {
  SearchOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons-vue";
import { Empty } from "ant-design-vue";
import LbrxLoaderSpinner from "../../components/LbrxLoaderSpinner.vue";
import type { Rule } from "ant-design-vue/es/form";
import { notification } from "ant-design-vue";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { customerService, productService, storeService, userService } from "@/_services";
import dayjs , { Dayjs }from 'dayjs'; 

export default defineComponent({
  name: "delivaryNoteIndex",
  components: {
    LbrxLoaderSpinner,
  },

 setup() {

    //rtl
    const { locale } = useI18n();
    const textDirection = computed(() => {
      return locale.value === "ar" ? "rtl" : "ltr";
    });
    const modalWidth = ref(720);
    const updateModalWidth = () => {
      modalWidth.value = window.innerWidth <= 1200 ? window.innerWidth : 720;
    };
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;

    const loading = ref(false);
    const { t } = useI18n();
    const searchInput = ref<HTMLInputElement | null>(null);
    const router = useRouter();

    const navigatePage = (link: string) => {
      router.push(link);
    };

    const state = reactive({

      searchText: "",
      searchedColumn: "",
      data: [] as any[],
      totalPages: 0,

    });

    const columns = [
      {
        title: t("Pos.Amount"),
        key: "amount",
        dataIndex: "amount",
      },
      {
        title: t("Pos.ExpiresIn"),
        key: "expiry_date",
        dataIndex: "expiry_date",
      },
      {
        title: t("Pos.Destination"),
        key: "destionation",
        dataIndex: "destionation",
      },
      {
        title: t("Pos.Actions"),
        key: "actions",
        dataIndex: "actions",
      },
    ];

    //form
    const addFormRef = ref();
    const addForm = reactive({
      id: "",
      userEmail:"",
      userFirstName:"",
      userLastName:"",
      amount:"",
      expiry_date:"",
    });

    
    const resetFormAdd = () => {
      addFormRef.value.resetFields();
    };

    const rules: Record<string, Rule[]> = {
      amount: [
        { required: true, message:  t('Pos.RequiredField') },
        { 
          validator: (_, value) => {
            if (!isNaN(value)) {
            return Promise.resolve();
            }
            return Promise.reject( t('Pos.numericField'));
          }
        }
      ],
      expiry_date: [{ required: true, message:  t('Pos.RequiredField') }],
      userFirstName: [{ required: true, message:  t('Pos.RequiredField') }],
      userLastName: [{ required: true, message:  t('Pos.RequiredField') }],


    }




    const openAdd = ref<boolean>(false);
    const showAddDrawer = () => {
      updateModalWidth();
      openAdd.value = true;
    };

    const add=()=>{
        addFormRef.value.resetFields().then(()=>{
            console.log('add');
        }).catch((error:any)=>{
            console.log('error validation form', error);
        })
    }



    return{
        locale,
        textDirection,
        modalWidth,
        simpleImage,
        loading,
        t,
        navigatePage,
        state,
        columns,
        openAdd,
        showAddDrawer,
        addFormRef,
        addForm,
        resetFormAdd,
        add,
        rules,










    }

 }







})


</script>

<style scoped>
.add-btn {
  color: #fc8019;
  border: 2px solid #fc8019;
  box-shadow: inset 0 0 0 0 #fc8019;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.btn-container {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.label-form {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.date-picker {
  width: 100%;
}

.breadcrumb-icon {
  cursor: pointer;
}
.input-form {
  color: black;
  background-color: #fff;
}
.view-btn {
  color: #fc8019;
  border-color: #fc8019;
  background: #fafafa;
}

.info-title {
  font-size: 14px;
  font-weight: 600;
  padding-right: 15px;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.add-btn:hover {
  color: #fff;
  box-shadow: inset 0 -100px 0 0 #fc8019;
  border-color: #fc8019;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.range-picker {
  width: 100%;
}

/* CSS for RTL */
.is-rtl {
  direction: rtl;
}

.paginationArrows {
  direction: ltr;
}

.table-container {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .pagination {
    flex-direction: column;
    align-items: flex-start;
  }

  .select-input {
    width: 100%;
    margin-top: 10px;
  }
}

</style>